import { Check, Close } from "@mui/icons-material";
import { Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";

function GrazingView(props) {
	let {lang, primary} = useContext(AppContext);
	let {data} = props;

	const [multiline, setMultiline] = useState(false);

	const operators = data.operators.map((operator, key) => {
		return <Chip style={{marginRight: '3px', marginLeft: '3px', marginBottom: '5px'}} key={key} variant="outlined" color="primary" label={operator.first_name + ' ' + operator.last_name} />
	});


  const renderAnimals = () => {
    console.log(data.animals);
    try {
      let parsed = JSON.parse(data.animals);

      return (
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell>{lang.animal_species}</TableCell>
            <TableCell>{lang.num_of_animals}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(parsed).map((obj, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{obj.species}</TableCell>
                  <TableCell>{obj.number}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )

    } catch(e) {
      return;
    }

    return
  }

	return (
		<Grid className={props.className || ''} container spacing={2}>
			{props.isJob && <Grid item xs={12} sm={4}>
				<TextField className="job-field" focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.from} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.jo_from ? moment(data.jo_from).format('DD-MMM-YYYY') : ' - '} />
			</Grid>}
			{props.isJob && <Grid item xs={12} sm={4}>
				<TextField className="job-field" focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.to} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.jo_to ? moment(data.jo_to).format('DD-MMM-YYYY') : ' - '} />
			</Grid>}
			{props.isJob && <Grid item xs={12} sm={4}>
				<Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '40px'}} variant="subtitle1">{lang.send_sms} {data.sms ? <Check style={{marginBottom: '3px'}} color={'success'} /> : <Close style={{marginBottom: '3px'}} color={'error'} />} </Typography>
			</Grid>}
			{props.isJob && <Grid item xs={12}>
				<Divider style={{width: '100%', height: '1px', margin: 0}} />
			</Grid>}
			{!props.isJob && <Grid item xs={12} sm={4}>
				<TextField focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.date} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={moment(data.date).format('DD-MMM-YYYY') || ' - '} />
			</Grid>}
			{!props.isJob && <Grid item xs={12} sm={4} style={{padding: 0}}></Grid>}	
			{!props.isJob && <Grid item xs={12} sm={4} style={{padding: 0}}></Grid>}	
			<Grid item xs={12} sm={6}>
				<TextField focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.num_of_days} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.num_of_days || ' - '} />
			</Grid>	
			
      <Grid item xs={12}>
        {renderAnimals()}
      </Grid>


			<Grid item xs={12}>
				<TextField focused multiline label={lang.comment} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.comment || ' - '} />
			</Grid>	
		</Grid>
	)
}

export default GrazingView;