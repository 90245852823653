import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import { useContext } from "react";
import Auth from "./Auth";
import Divider from "./Divider";
import { AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";
import $auth from "../../services/$auth";
import { Typography } from "@mui/material";


function ForgotPassword(props) {
  const {lang} = useContext(AppContext);

  const [state, setState] = useMergeState({email: '', success: null, loading: false, error: {}});
  const {email, success, error, loading} = state;

  const onSubmit = (evt) => {
    evt.preventDefault();

    setState({loading: true, error: {}});
    $auth.resetEmail(email)
      .then(data => { setState({success: lang.reset_sent, email: '', loading: false}); })
      .catch(err => {setState({loading: false, error: err})})
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}});
  }

  return (
    <Auth>
       <Box style={{padding: '5px 5px'}}>
        <Typography variant="h4" style={{textAlign: 'center', margin: '10px 0'}}>{lang.forgot}</Typography>
        <Divider />
        <Typography variant="subtitle1" style={{textAlign: 'center', color: 'rgba(0,0,0,.8)'}}>{lang.forgot_text}</Typography>
        <Link to="/login" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none'}}><ArrowBack />&nbsp;{lang.go_back}&nbsp;</Link>
      </Box>

      <Box style={{padding: '5px'}} component='form' sx={{'& .MuiTextField-root, & .MuiButton-root': {mb: '7px', mt: '7px' }}} onSubmit={onSubmit}>
        <div>
          <TextField size="small" error={error.email !== undefined || error.non_field_errors !== undefined} helperText={error.email} fullWidth label={lang.email}type='email' value={email} onChange={(evt) => setState({email: evt.target.value})} />
        </div>

        <div>
          <LoadingButton loading={loading} fullWidth variant="contained" type="submit">{lang.submit_res}</LoadingButton>
        </div>

      </Box>

      <Message type="success" open={success ? true : false} message={success} onClose={() => setState({success: null})} />
      <Message type="error" open={error.non_field_errors !== undefined} message={error.non_field_errors} onClose={() => onCloseMessage()} />

    </Auth>
  )
}

export default ForgotPassword;