import { Grass, History, Room, Sensors, StackedBarChart, Summarize, WbSunny, CloseOutlined, Settings, Edit, Delete, Search, Opacity, PhotoAlbum, Photo, PhotoCamera, Download } from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker, TabContext, TabPanel } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { Button, Chip, CircularProgress, Fab, FormControl, MenuItem, Paper, Select, SpeedDial, SpeedDialAction, Stack, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import colors from "../../config/colors";
import { getLayerNames } from "../../config/map";
import $data from "../../services/$data";
import $map from "../../services/$map";
import useMergeState from "../hooks/useMergeState";
import ConfirmDialog from "../ui/ConfirmDialog";
import Message from "../ui/Message";
import EditParcel from "./modals/EditParcel";
import AddCropSeason from "./views/AddCropSeason";
import Forecast from "./views/Forecast";
import Gdd from "./views/Gdd";
import Meteo from "./views/Meteo";
import PhotosPreview from "./views/PhotosPreview";
import Query from "./views/Query";
import Sensor from "./views/Sensor";
import Summary from "./views/Summary";
import SummaryCropHistory from "./views/SummaryCropHistory";
import SummaryDetails from "./views/SummaryDetails";
import SummaryFullHistory from "./views/SummaryFullHistory";
import SummaryIndicesMobile from "./views/SummaryIndicesMobile";
import SummaryParcelDetails from "./views/SummaryParcelDetails";
import DownloadData from "./modals/DownloadData";

function Datapanel(props) {
  const {lang, locale, user, action, cropTypes, primary, danger, warning, layer, photosData, season, parcels, onUpdateState, onUpdateParcels} = useContext(AppContext);
  const [tab, setTab] = useState('1');
  const {loading, error, data, meteoFilter, onSetMeteoFilter} = props;
  const mounted = useRef(false);
  const [active, setActive] = useState(0);

  const [filter, setFilter] = useState(season);
  const [dateFilter, setDateFilter] = useState({from: undefined, to: undefined});
  const [variable, setVariable] = useState(null);
  const [mode, setMode] = useState('meteo');
  const [details, setDetails] = useState(false);
  const [gddFilter, setGddFilter] = useState(0);

  const [sensorFilter, setSensorFilter] = useMergeState({
    date_from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    date_to: moment().add(1, 'days').format('YYYY-MM-DD')
  })

  const [modals, setModals] = useMergeState({deleteModal: false, editModal: false, cropRecordModal: false, historyModal: false, indicesModal: false, photosPreview: null, downloadModal: null});
  const activeParcel = $data.getParcelById(active === 0 ? props.parcelA : props.parcelB);
  const parcel = activeParcel ? activeParcel.properties : {};
  
  const getTotalArea = () => {
		let area = 0;
		parcels.features.map(f => (area += f.properties.area));
		return area;
	}

  useEffect(() => {
    mounted.current = true;
  }, []);

  useEffect(() => {
    setActive(0);
    setGddFilter(0);
  }, [action]);


  useEffect(() => {
    if(active === 0) {
      setGddFilter(0);
    }

  }, [props.parcelA]);

  useEffect(() => {
    if(active === 1) {
      setGddFilter(0);
    }

  }, [props.parcelA]);

  useEffect(() => {
    setGddFilter(0);
    if(props.parcelA && props.parcelB) {
      let cropsA = formatGddFilter($data.getParcelById(props.parcelA)?.properties?.crop_history);
      let cropsB = formatGddFilter($data.getParcelById(props.parcelB)?.properties?.crop_history)
      
      props.onCompareGdd(props.parcelA, props.parcelB, 
        cropsA.filter((obj,id) => 0 === id)[0],
        cropsB.filter((obj,id) => 0 === id)[0]);
  
    }

  }, [active])

  useEffect(() => {
    if(loading.sensors) {
      setVariable(null)
    }
  }, [loading.sensors])
  

  useEffect(() => {
    if(mounted.current) {
      setVariable(null);
      props.onFilterSensor(props.sensor, `&date_from=${sensorFilter.date_from}&date_to=${sensorFilter.date_to}`)
  
    }
  }, [props.sensor])

  useEffect(() => {
    if(props.sensor) {
      props.onFilterSensor(props.sensor, `&date_from=${sensorFilter.date_from}&date_to=${sensorFilter.date_to}`)

    } 
  }, [sensorFilter.date_from, sensorFilter.date_to])



  const onDeleteParcel = () => {
    setModals({deleteLoading: true});
    const total = getTotalArea();
    const isAgricapture = user.billing_plan === 2;
    const deletedFeatureArea = $data.getParcelById(active === 0 ? props.parcelA : props.parcelB)?.properties?.area || 0;
    $data.deleteParcel(active === 0 ? props.parcelA : props.parcelB)
      .then(result => {
        setModals({deleteLoading: false, deleteModal: false, deleteSuccess: lang.success_delete_field});
        if(total - deletedFeatureArea > 100 && !isAgricapture) {
          onUpdateState({pricingOpened: true})
        }
        props.cleanState();
        onUpdateParcels();

      })
      .catch(err => {
        console.log(err)
        // if(err.constructor === String || err.constructor === Array) {
          
        // }
        setModals({deleteLoading: false, deleteError: err.message ? err.message : null})
      });
  }

  const onPutParcelSuccess = () => {
    setModals({editModal: false, editSuccess: lang.success_edit_field});
    onUpdateParcels();

  }

  const onSetDateFilter = (name, value) => {
    setDateFilter({...dateFilter, [name]: value})
  }

  const onSetGddFilter = (key, crops) => {
    setGddFilter(key);
    if(!action) {
      props.onQueryGdd(active === 0 ? props.parcelA : props.parcelB, crops.filter((obj, id) => id === key)[0]);
    }

    if(action === 'compare') {
      let cropsA = formatGddFilter($data.getParcelById(props.parcelA)?.properties?.crop_history);
      let cropsB = formatGddFilter($data.getParcelById(props.parcelB)?.properties?.crop_history)
      props.onCompareGdd(props.parcelA, props.parcelB, 
        active === 0 ? cropsA.filter((obj,id) => key === id)[0] : cropsA.filter((obj,id) => 0 === id)[0],
        active === 1 ? cropsB.filter((obj,id) => key === id)[0] : cropsB.filter((obj,id) => 0 === id)[0]);

    }
  }

  const formatGddFilter = (crops) => {
    let filtered = crops.filter(obj => obj.crop.crop_group !== 6);
    return filtered.map((obj, key) => {
      return {
        id: key,
        date_from: {...obj}.sowing,
        date_to: {...obj}.harvest ? {...obj}.harvest : moment({...obj}.sowing).add('8', 'month').format('YYYY-MM-DD'),
        crop_id: {...obj}.crop.pk
      }
    })
  }

  const renderSensorFilter = () => {
    if(!props.sensor) return null
    let defaultVariable = variable ? variable : props.sensor.get('variables')[0]
    return (
      <Stack direction="row" spacing={1}>
        <FormControl size="small">
          <Select sx={{width: '100%', maxWidth: '180px', fontSize: '13px'}} size="small" value={defaultVariable} onChange={(evt) => setVariable(evt.target.value)} fullWidth>
            { 
              props.sensor.get('variables').map((variable, key) => {
                return <MenuItem key={key} value={variable}>{variable}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
            <MobileDatePicker sx={{mb: '10px'}} maxDate={moment().add(1, 'days')} okText={lang.ok} cancelText={lang.cancel} onClose={() => { setSensorFilter({ date_from: moment(sensorFilter.date_from).format('YYYY-MM-DD') }) }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={moment(sensorFilter.date_from)} onChange={(newValue) => setSensorFilter({ date_from: newValue.format('YYYY-MM-DD') })}
              renderInput={(params) => (<TextField className="datepicker-filter" {...params} size="small" sx={{'& .MuiOutlinedInput-root': {pl: '10px'}}}  />)} />
          </LocalizationProvider>

          <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
            <MobileDatePicker  maxDate={moment().add(1, 'days')} okText={lang.ok} cancelText={lang.cancel} onClose={() => { setSensorFilter({ date_to: moment(sensorFilter.date_to).format('YYYY-MM-DD') }) }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(sensorFilter.date_to)} onChange={(newValue) => setSensorFilter({ date_to: newValue.format('YYYY-MM-DD') })}
              renderInput={(params) => (<TextField className="datepicker-filter" {...params} size="small" sx={{'& .MuiOutlinedInput-root': {pl: '10px'}}}   />)} />
          </LocalizationProvider>
      </Stack>
    )
  }

  const renderGddFilter = () => {

    let crops = active === 0 ? 
      $data.getParcelById(props.parcelA)?.properties?.crop_history :
      $data.getParcelById(props.parcelB)?.properties?.crop_history

    if(!crops || crops?.length === 0) return null;

    let formated = formatGddFilter(crops);
    return (
      <Stack direction="row" spacing={1}>
        <FormControl size="small" fullWidth>
          <Select sx={{width: '100%', maxWidth: '250px', fontSize: '13px'}} size="small" value={gddFilter} onChange={(evt) => {onSetGddFilter(evt.target.value, formated)}} fullWidth>
            {formated.map((obj, key) => {
              return <MenuItem sx={{fontSize: '12px'}} size="small" key={key} value={obj.id}>{$data.getCropById(cropTypes, obj.crop_id)}: {moment(obj.date_from).format('DD-MMM-YYYY')} -&gt; {obj.date_to ? moment(obj.date_to).format('DD-MMM-YYYY') : 'N/A'}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Stack>
    )
  }

  const resetZoom = (chart) => {chart.resetZoom(); chart.resetPan()};

  const renderSummary = () => {
    if(loading.summary) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    if(error.summary) return (
      <Stack sx={{height: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Box className="summary-parcel-details-container">
          <SummaryParcelDetails active={active === 0 ? props.parcelA : props.parcelB} setDetails={setDetails} />
          <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_name}: <strong style={{color: primary, maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parcel.name || ' - '}</strong> </Paper>
          <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_crop}: <strong style={{color: parcel?.activeCrop?.crop !== 13 ? colors.crop[parcel?.activeCrop?.crop] : '#555', textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop) || ' - '}</strong> </Paper>
          <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_area}: <strong style={{color: primary}}>{parcel.area ? parcel.area.toFixed(2) + ' ha' : ' - '}</strong> </Paper>
          <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_sowing}: <strong style={{color: primary}}>{parcel.activeCrop ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
          <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_harvest}: <strong style={{color: primary}}>{parcel.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
        </Box>

        <Box className="only-on-mobile" sx={{paddingTop: '38px', height: '120px'}} component={Stack} alignItems="flex-start" justifyContent={'center'}>
          <Button onClick={() => setModals({'historyModal': true})} variant="contained" size="small">{lang.crop_history_short}</Button><br/>
          <Button onClick={() => setModals({'indicesModal': true})} variant="contained" size="small">{lang.indices}</Button>
        </Box>

        <div className="only-on-desktop"><SummaryCropHistory openCropRecordModal={() => {setModals({...modals, cropRecordModal: true})}} openHistoryModal={() => {setModals({...modals, historyModal: true})}} parcel={parcel} /></div>

        <Typography className="only-on-desktop" sx={{mt: '20px', width: '100%'}} variant="subtitle2" color="error">
          {error.summary?.non_field_errors}
        </Typography>
      </Stack>
      
    )

    if(!data.summary) return (
      
      <Stack sx={{height: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Typography sx={{mt: '20px', textAlign: 'center', width: '100%'}} variant="subtitle2" color="primary">
          {lang.summary_click_info}
        </Typography>
      </Stack>
    )

    if(data.summary.length === 1) {
      if(user.planet) {
        if(data.summary[0]['s2'].length === 0 && data.summary[0]['planet'].length === 0) return (
          <Stack sx={{height: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Box className="summary-parcel-details-container">
              <SummaryParcelDetails active={active === 0 ? props.parcelA : props.parcelB} setDetails={setDetails} />
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_name}: <strong style={{color: primary, maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parcel.name || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_crop}: <strong style={{color: parcel?.activeCrop?.crop !== 999 ? colors.crop[parcel?.activeCrop?.crop] : '#555', textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop) || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_area}: <strong style={{color: primary}}>{parcel.area ? parcel.area.toFixed(2) + ' ha' : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_sowing}: <strong style={{color: primary}}>{parcel.activeCrop ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_harvest}: <strong style={{color: primary}}>{parcel.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
            </Box>

            <Box className="only-on-mobile" sx={{paddingTop: '38px', height: '120px'}} component={Stack} alignItems="flex-start" justifyContent={'center'}>
              <Button onClick={() => setModals({'historyModal': true})} variant="contained" size="small">{lang.crop_history_short}</Button><br/>
              <Button onClick={() => setModals({'indicesModal': true})} variant="contained" size="small">{lang.indices}</Button>
            </Box>
            <div className="summary-short-container only-on-desktop">
            <SummaryCropHistory openCropRecordModal={() => {setModals({...modals, cropRecordModal: true})}} openHistoryModal={() => {setModals({...modals, historyModal: true})}} parcel={parcel} />
            </div>
            <Typography className="only-on-desktop" sx={{mt: '20px', width: '100%'}} variant="subtitle2" color="primary">
              {lang.summary_no_data_parcel}
            </Typography>
          </Stack>
        )
      } else {
        if(data.summary[0]['s2'].length === 0) return (
          <Stack sx={{height: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Box className="summary-parcel-details-container">
              <SummaryParcelDetails active={active === 0 ? props.parcelA : props.parcelB} setDetails={setDetails} />
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_name}: <strong style={{color: primary, maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parcel.name || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_crop}: <strong style={{color: parcel?.activeCrop?.crop !== 999 ? colors.crop[parcel?.activeCrop?.crop] : '#555', textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop) || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_area}: <strong style={{color: primary}}>{parcel.area ? parcel.area.toFixed(2) + ' ha' : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_sowing}: <strong style={{color: primary}}>{parcel.activeCrop ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_harvest}: <strong style={{color: primary}}>{parcel.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
            </Box>
            <Box className="only-on-mobile" sx={{paddingTop: '38px', height: '120px'}} component={Stack} alignItems="flex-start" justifyContent={'center'}>
              <Button onClick={() => setModals({'historyModal': true})} variant="contained" size="small">{lang.crop_history_short}</Button><br/>
              <Button onClick={() => setModals({'indicesModal': true})} variant="contained" size="small">{lang.indices}</Button>
            </Box>
            <div className="only-on-desktop summary-short-container">
            <SummaryCropHistory openCropRecordModal={() => {setModals({...modals, cropRecordModal: true})}} openHistoryModal={() => {setModals({...modals, historyModal: true})}} parcel={parcel} />
            </div>
            <Typography className="only-on-desktop" sx={{mt: '20px', width: '100%'}} variant="subtitle2" color="primary">
              {lang.summary_no_data_parcel}
            </Typography>
          </Stack>
        )
      }
    }

    if(data.summary.length === 2) {
      if(user.planet) {
        if(data.summary[0]['s2'].length === 0 && 
          data.summary[0]['planet'].length === 0 &&
          data.summary[1]['s2'].length === 0 &&
          data.summary[1]['planet'].length === 0) return (
          <Stack sx={{height: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Box className="summary-parcel-details-container">
              <SummaryParcelDetails active={active === 0 ? props.parcelA : props.parcelB} setDetails={setDetails} />
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_name}: <strong style={{color: primary, maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parcel.name || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_crop}: <strong style={{color: parcel?.activeCrop?.crop !== 999 ? colors.crop[parcel?.activeCrop?.crop] : '#555', textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop) || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_area}: <strong style={{color: primary}}>{parcel.area ? parcel.area.toFixed(2) + ' ha' : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_sowing}: <strong style={{color: primary}}>{parcel.activeCrop ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_harvest}: <strong style={{color: primary}}>{parcel.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
            </Box>
            <Box className="only-on-mobile" sx={{paddingTop: '38px', height: '120px'}} component={Stack} alignItems="flex-start" justifyContent={'center'}>
              <Button onClick={() => setModals({'historyModal': true})} variant="contained" size="small">{lang.crop_history_short}</Button><br/>
              <Button onClick={() => setModals({'indicesModal': true})} variant="contained" size="small">{lang.indices}</Button>
            </Box>
            <div className="summary-short-container only-on-desktop">
            <SummaryCropHistory openCropRecordModal={() => {setModals({...modals, cropRecordModal: true})}} openHistoryModal={() => {setModals({...modals, historyModal: true})}} parcel={parcel} />
            </div>
            <Typography className="only-on-desktop" sx={{mt: '20px', width: '100%'}} variant="subtitle2" color="primary">
              {lang.summary_no_data_parcel}
            </Typography>
          </Stack>
        )
      } else {
        if(data.summary[0]['s2'].length === 0 && 
          data.summary[1]['s2'].length === 0) return (
            <Stack sx={{height: '100%', width: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Box className="summary-parcel-details-container">
                <SummaryParcelDetails active={active === 0 ? props.parcelA : props.parcelB} setDetails={setDetails} />
                <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_name}: <strong style={{color: primary, maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parcel.name || ' - '}</strong> </Paper>
                <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_crop}: <strong style={{color: parcel?.activeCrop?.crop !== 999 ? colors.crop[parcel?.activeCrop?.crop] : '#555', textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop) || ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_area}: <strong style={{color: primary}}>{parcel.area ? parcel.area.toFixed(2) + ' ha' : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_sowing}: <strong style={{color: primary}}>{parcel.activeCrop ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
              <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_harvest}: <strong style={{color: primary}}>{parcel.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
              </Box>

              <Box className="only-on-mobile" sx={{paddingTop: '38px', height: '120px'}} component={Stack} alignItems="flex-start" justifyContent={'center'}>
          <Button onClick={() => setModals({'historyModal': true})} variant="contained" size="small">{lang.crop_history_short}</Button><br/>
          <Button onClick={() => setModals({'indicesModal': true})} variant="contained" size="small">{lang.indices}</Button>
        </Box>
              <div className="summary-short-container only-on-desktop">
            <SummaryCropHistory openCropRecordModal={() => {setModals({...modals, cropRecordModal: true})}} openHistoryModal={() => {setModals({...modals, historyModal: true})}} parcel={parcel} />
            </div>
              <Typography className="only-on-desktop" sx={{mt: '20px', width: '100%'}} variant="subtitle2" color="primary">
                {lang.summary_no_data_parcel}
              </Typography>
            </Stack>
          )
      }
      
    }

    return <Summary active={active === 0 ? props.parcelA : props.parcelB} data={data.summary[active]} onSetModals={(name, bool) => setModals({...modals, [name]: bool})} modals={modals} onSetDetails={setDetails} />
  }

  const renderQuery = () => {

    if(loading.query) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    if(error.query) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="error">
        {error.query?.non_field_errors}
      </Typography>
    )

    if(!data.query) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.query_click_info}
      </Typography>
    )

    if(data.query.length === 1) {
      if(user.planet) {
        if(data.query[0].s2[layer].length === 0 && data.query[0].planet[layer].length === 0) return (
          <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
            {lang.query_no_data_parcel}
          </Typography>
        )
      } else {
        if(data.query[0].s2[layer].length === 0) return (
          <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
            {lang.query_no_data_parcel}
          </Typography>
        )
      }
    }

    if(data.query.length === 2) {
      if(user.planet) {
        if(data.query[0].s2[layer].length === 0 && 
          data.query[0].planet[layer].length === 0 && 
          data.query[1].s2[layer].length === 0 && 
          data.query[1].planet[layer].length === 0 
          ) return (
            <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
              {lang.query_no_data_parcel}
            </Typography>
        )
      } else {
        if(data.query[0].s2[layer].length === 0 && data.query[1].s2[layer].length === 0) return (
          <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
            {lang.query_no_data_parcel}
          </Typography>
        )
      }
    }

    return <Query parcel={active === 0 ? props.parcelA : props.parcelB} dateFilter={dateFilter} filter={filter} data={data.query} layer={layer} onResetView={resetZoom}/>
  }

  const renderGdd = () => {
    if(loading.gdd) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    const crops = $data.getParcelById(active === 0 ? props.parcelA : props.parcelB)?.properties.crop_history;
    if(!crops || crops?.length === 0) {
      return (<Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.gdd_no_crops_1}{<a onClick={() => setTab('1')} className="text-link" style={{textTransform: 'uppercase'}}>{lang.gdd_summary_section}</a>}{lang.gdd_no_crops_3}{<Link to={`/dashboard/field-book/${((!props.parcelA && !props.parcelB) ? '' : (active === 0 ? props.parcelA : props.parcelB))}`} className="text-link" style={{textTransform: 'uppercase', textDecoration: 'none'}}>{lang.gdd_field_book}</Link>}{lang.gdd_no_crops_5}
    </Typography>)
    }

    if(error.gdd) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="error">
        {error.gdd}
      </Typography>
    )


  

    if(!data.gdd) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.gdd_click_info}
      </Typography>
    )

   
    if(data.gdd[active].length === 0) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.gdd_no_data}
      </Typography>
    )
      
    //CHART ZA GDD
    return <Gdd crops={crops} parcel={active === 0 ? props.parcelA : props.parcelB} data={data.gdd[active]} onResetView={resetZoom}/>
  }

  const renderMeteo = () => {
    if(loading.meteo) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    if(error.meteo) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="error">
        {error.meteo?.non_field_errors}
      </Typography>
    )

    if(!data.meteo) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.meteo_click_info}
      </Typography>
    )

    if(data.meteo[active].length === 0) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.meteo_no_data}
      </Typography>
    )
    
    return <Meteo data={data.meteo[active]} mode={mode} />
  }

  const renderForecast = () => {
    if(loading.forecast) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    if(error.forecast) return (
       <Typography sx={{mt: '20px'}} variant="subtitle2" color="error">
        {error.forecast}
      </Typography>
    )

    if(!data.forecast) {
      return <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
      {lang.forecast_click_info}
    </Typography>
    }

    return <Forecast data={data.forecast[active]} />
  }


  const renderSensors = () => {
    if(loading.sensors) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    if(error.sensors) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="error">
       {error.sensors}
      </Typography>
    )

    if(!data.sensors) {
      return <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.sensor_click_info}
      </Typography>
    }

    if(data.sensors.length === 1) {
      console.log(data.sensors[0])
      if(Object.keys(data.sensors[0]).length === 0) {
        return <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
         {lang.sensor_no_data}
        </Typography>
      }
    }

    if(data.sensors.length === 2) {

    }



    return <Sensor data={data.sensors[0]} selected={variable || props.sensor.get('variables')[0]} />
  }

  const renderPhotos = () => {
    if(loading.photos) return (
      <h6>
        <CircularProgress />
      </h6>
    )

    if(error.photos) return (
      <Typography sx={{mt: '20px'}} variant="subtitle2" color="error">
       {error.photos}
      </Typography>
    )

    if(!data.photos) {
      return <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.photos_click_info}
      </Typography>
    }

    if(data.photos.length === 0) {
      return <Typography sx={{mt: '20px'}} variant="subtitle2" color="primary">
        {lang.no_photos}
      </Typography>
    }

    return (
      <Stack sx={{overflowX: 'auto'}} direction="row" spacing={2}>
        {data.photos.map((obj, key) => {
          return (
            <Paper onClick={() => setModals({photosPreview: [obj]})} sx={{height: '150px', marginBottom: '5px', cursor: 'pointer', position: 'relative', '&:hover::after': {background: 'rgba(84, 137, 116, .6)', content: '""', position: 'absolute', top:0, left: 0, height: '100%', width: '100%'}}} key={key} elevation={2}>
              <img src={obj.image} height="150" />
            </Paper>
          )
        })}
      </Stack>
    )
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setModals({deleteError: null, deleteSuccess: null, editSuccess: null});
  }

  const tabProps = {
    summary: window.innerWidth >= 1200 ? {label: lang.summary} : {icon: <StackedBarChart />},
    query: window.innerWidth >= 1200 ? {label: lang.query} : {icon: <Room />},
    sensors: window.innerWidth >= 1200 ? {label: lang.sensors} : {icon: <Sensors />},
    photos: window.innerWidth >= 1200 ? {label: lang.photos} : {icon: <PhotoCamera />},
    gdd: window.innerWidth >= 1200 ? {label: lang.crop_stage} : {icon: <Grass />},
    meteo: window.innerWidth >= 1200 ? {label: lang.meteo} : {icon: <Stack direction="row"><WbSunny fontSize="small" /> <History fontSize="small" /></Stack>},
    forecast: window.innerWidth >= 1200 ? {label: lang.forecast} : {icon: <WbSunny />},
  }

  const crops = $data.getParcelById(active === 0 ? props.parcelA : props.parcelB)?.properties?.crop_history?.map((obj, key) => {
    return {
      id: key,
      crop_id: obj.crop.pk,
      date_from: obj.sowing,
      date_to: obj.harvest
    }
  }) || [];

  return [
    <div key={1} className={"datapanel-container datapanel-row" + (props.open ? ' visible' : '')}>
      {Boolean(modals.downloadModal) && <DownloadData key={4} open={Boolean(modals.downloadModal)} onClose={() => setModals({downloadModal: null})} parcelId={modals.downloadModal} />}
      <TabContext value={tab}>
        <Tabs className="tabs-desktop" variant="fullWidth" value={tab} onChange={(e, val) => setTab(val)}>
          <Tab className="dp-summary-row" value={"1"} {...tabProps.summary}></Tab>
          
          <Tab className="dp-query-row" value={"2"} {...tabProps.query}></Tab>

          {(user.has_sensors) && <Tab className="dp-sensors-row" value={"3"} {...tabProps.sensors}></Tab>}

           <Tab className="dp-photos-row" value={"4"} {...tabProps.photos}></Tab>

          <Tab className="dp-gdd-row" value={"5"} {...tabProps.gdd}></Tab>

          <Tab className="dp-meteo-row" value={"6"} {...tabProps.meteo}></Tab>

          <Tab className="dp-forecast-row" value={"7"} {...tabProps.forecast}></Tab>
        </Tabs>


        <TabPanel value={'1'}>
          {renderSummary()}
        </TabPanel>
        <TabPanel value={'2'}>
          {/* FILTERI */}
          {(layer !== 'productivity' && layer !== 'mzones') && <Stack direction="row" alignItems="flex-start" spacing={1}>
            {/* {seasons.map((s, key) => <Chip onClick={() => setFilter(s)} color={filter === s ? "primary" : "default"} key={key} label={`${s}/${s + 1}`} />)} */}
            {/* <Chip onClick={() => setFilter('all')} color={filter === 'all' ? "primary" : "default"} label={lang.query_all_data} /> */}
            {/* <FormControl size="small">
              <Select sx={{width: '100%', maxWidth: '180px', fontSize: '13px'}} size="small" value={filter} onChange={(evt) => setFilter(evt.target.value === 'all' ? 'all' : parseInt(evt.target.value))} fullWidth>
                <MenuItem size="small" value={'all'}>{lang.query_all_data}</MenuItem>
                {seasons.map((s, key) => {
                  return <MenuItem size="small" key={key} value={s}>{`${s}/${s + 1}`}</MenuItem>
                })}
              </Select>
            </FormControl> */}
             {/* <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
              <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onSetDateFilter('from', moment(dateFilter.from).format('YYYY-MM-DD'))}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={moment(dateFilter.from)} onChange={(newValue) => onSetDateFilter('from', newValue.format('YYYY-MM-DD'))}
                renderInput={(params) => ( <TextField className="datepicker-filter" {...params} size="small" sx={{ maxWidth: '80px'}} /> )} />
            </LocalizationProvider>
            <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
              <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onSetDateFilter('to', moment(dateFilter.to).format('YYYY-MM-DD'))}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(dateFilter.to)} onChange={(newValue) => onSetDateFilter('to', newValue.format('YYYY-MM-DD'))}
                renderInput={(params) => ( <TextField className="datepicker-filter" {...params} size="small" sx={{ maxWidth: '80px'}} /> )} />
            </LocalizationProvider> */}
            <FormControl size="small">
              <Select sx={{width: '100%', maxWidth: '180px', fontSize: '13px'}} size="small" value={layer} onChange={(evt) => onUpdateState({layer: evt.target.value})} fullWidth>
                {getLayerNames(lang).map((obj, key) => {
                  return <MenuItem size="small" key={key} value={obj.value}>{obj.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Stack>}
          {(layer === 'productivity' || layer === 'mzones') && <Stack direction="row" spacing={1}>
            <FormControl size="small" fullWidth>
              <Select sx={{width: '100%', maxWidth: '180px', fontSize: '13px'}} size="small" value={layer} onChange={(evt) => onUpdateState({layer: evt.target.value})} fullWidth>
                {getLayerNames(lang).map((obj, key) => {
                  return <MenuItem size="small" key={key} value={obj.value}>{obj.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Stack>}
          {renderQuery()}
        </TabPanel>
        {user.has_sensors && <TabPanel value={'3'}>
          {renderSensorFilter()}
          {renderSensors()}
        </TabPanel>}

        <TabPanel value={'4'}>
          {renderPhotos()}
        </TabPanel>

        <TabPanel value={'5'}>
          {renderGddFilter()}
          {renderGdd()}
        </TabPanel>
        <TabPanel value={'6'}>
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            <FormControl size="small">
              <Select sx={{width: '100%', maxWidth: '180px', fontSize: '13px'}} size="small" value={mode} onChange={(evt) => setMode(evt.target.value)} fullWidth>
                <MenuItem size="small" value={'meteo'}>{lang.meteo_mode}</MenuItem>
                <MenuItem size="small" value={'accumulated'}>{lang.accumulated_mode}</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
              <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onSetMeteoFilter({date_from: moment(meteoFilter.date_from).format('YYYY-MM-DD')})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={moment(meteoFilter.date_from)} onChange={(newValue) => onSetMeteoFilter({date_from: newValue.format('YYYY-MM-DD')})}
                renderInput={(params) => ( <TextField className="datepicker-filter" {...params} size="small" sx={{ maxWidth: '80px'}} /> )} />
            </LocalizationProvider>
            <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
              <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onSetMeteoFilter({date_to: moment(meteoFilter.date_to).format('YYYY-MM-DD')})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(meteoFilter.date_to)} onChange={(newValue) => onSetMeteoFilter({date_to: newValue.format('YYYY-MM-DD')})}
                renderInput={(params) => ( <TextField className="datepicker-filter" {...params} size="small" sx={{ maxWidth: '80px'}} /> )} />
            </LocalizationProvider>
          </Stack>
          {renderMeteo()}
        </TabPanel>
        <TabPanel value={'7'}>
          {renderForecast()}
        </TabPanel>
      </TabContext>

      {action === 'compare' && props.parcelA && props.parcelB && 
      <Stack direction="row" justifyContent="space-between" alignItems={"center"} style={{position: 'absolute', top: '-46px', left: '0', width: '100%'}}>
        <div style={{marginLeft: '2.5px', display: 'flex', width: '120px'}}>
        <Button sx={{minWidth: '20px'}} onClick={() => setActive(0)} variant="contained" key={0} size="small" color={active === 0 ? 'secondary' : 'error'}>A&nbsp;<i style={{marginBottom: '2px',fontSize: '18px', lineHeight: 'normal', color: active === 0 ? danger : '#fff'}} className="fg-polygon-pt"></i></Button>,
        <Button sx={{minWidth: '20px'}} onClick={() => setActive(1)} variant="contained" key={1} size="small" color={active === 1 ? 'secondary' : 'warning'}>B&nbsp;<i style={{marginBottom: '2px',fontSize: '18px', lineHeight: 'normal', color: active === 1 ? warning: '#fff'}} className="fg-polygon-pt"></i></Button>
        </div>
        <div style={{marginRight: '2.5px'}}>
          <Tooltip title={lang.action_edit}><Fab style={{marginLeft: '2px'}} color="primary" size="small" onClick={() => setModals({editModal: true})}><Edit /></Fab></Tooltip>
          {/* <Tooltip title={lang.action_crop_history}><Fab style={{marginLeft: '2px'}} color="primary" size="small"><History /></Fab></Tooltip> */}
          <Tooltip title={lang.action_delete}><Fab style={{marginLeft: '2px'}} color="primary" size="small" onClick={() => setModals({deleteModal: true})}><Delete /></Fab></Tooltip>
        </div>
      </Stack>}

      {props.parcelA && action !== 'compare' &&
        <Stack direction="row" justifyContent={'flex-end'} alignItems={"center"} style={{position: 'absolute', top: '-46px', right: '0', }}>
          <Tooltip title={lang.download_data_parcel}><Fab style={{marginLeft: '2px'}} color="primary" size="small" onClick={() => setModals({downloadModal: props.parcelA})}><Download /></Fab></Tooltip>
          <Tooltip title={lang.action_edit}><Fab style={{marginLeft: '2px'}} color="primary" size="small" onClick={() => setModals({editModal: true})}><Edit /></Fab></Tooltip>
          {/* <Tooltip title={lang.action_crop_history}><Fab style={{marginLeft: '2px'}} color="primary" size="small"><History /></Fab></Tooltip> */}
          <Tooltip title={lang.action_delete}><Fab style={{marginLeft: '2px'}} color="primary" size="small" onClick={() => setModals({deleteModal: true})}><Delete /></Fab></Tooltip>
        </Stack>
      }

      <SummaryFullHistory parcel={parcel} open={modals.historyModal} openCropRecordModal={() => setModals({...modals, cropRecordModal: true})} onClose={() => {setModals({...modals, historyModal: false})}} />
          
      {/* <CropRecordSteps parcel={parcel} open={cropRecordModal} onClose={() => {setCropRecordModal(false)}} /> */}
      <AddCropSeason parcel={parcel} open={modals.cropRecordModal} onClose={() => {setModals({...modals, cropRecordModal: false})}} />

      <EditParcel model={activeParcel?.properties} feature={activeParcel} open={modals.editModal} onSuccess={onPutParcelSuccess} onClose={() => setModals({editModal: false})} />

      <ConfirmDialog error={modals.deleteError} success={modals.deleteSuccess} loading={modals.deleteLoading} onCloseMessage={onCloseMessage} onSubmit={onDeleteParcel} title={lang.delete_field_title} question={lang.delete_field_question} open={modals.deleteModal} onClose={() => setModals({deleteModal: false, deleteLoading: false, deleteError: null, deleteSuccess: null})}>
        <Stack sx={{'& td': {padding: '5px'}}} spacing={2} direction="row" justifyContent="space-around">
          <div>
            {$map.getParcelThumbnail(active === 0 ? props.parcelA : props.parcelB, 'transparent',  parcel.season_crop?.crop === 13 || !parcel.season_crop ? '#555' : colors.crop[parcel.season_crop?.crop], 30)}
          </div>
          <div>
            {activeParcel ? <table>
              <tbody>
                <tr>
                  <td>{lang.parcel_name}:</td>
                  <td>{activeParcel.properties.name}</td>
                </tr>
                {/* <tr>
                  <td>{lang.parcel_crop}:</td>
                  <td>{$data.getCropById(cropTypes, activeParcel.properties.activeCrop?.crop).toUpperCase()}</td>
                </tr> */}
                <tr>
                  <td>{lang.parcel_area}:</td>
                  <td>{activeParcel.properties.area.toFixed(2)} ha</td>
                </tr>
                {/*<tr>
                   <td>{lang.parcel_sowing}:</td>
                  <td>{activeParcel.properties.sowing ? moment(activeParcel.properties.sowing).format('DD-MMM-YYYY') : ' - '}</td>
                </tr>
                <tr>
                  <td>{lang.parcel_harvest}:</td>
                  <td>{activeParcel.properties.harvest ? moment(activeParcel.properties.harvest).format('DD-MMM-YYYY') : ' - '}</td>
                </tr> */}
              </tbody>
            </table> : null}
          </div>
        </Stack>
      </ConfirmDialog>
      {data.summary && <SummaryDetails parcelA={props.parcelA} parcelB={props.parcelB} parcel={parcel} allData={data.summary} data={data.summary ? data.summary[active] : []} open={details} onClose={() => {setDetails(false)}} />}
      <SummaryIndicesMobile data={data.summary ? data.summary[active] : null} open={modals.indicesModal} onClose={() => setModals({indicesModal: false})} title={lang.indices_summary}/>
      {modals.photosPreview && <PhotosPreview photos={modals.photosPreview} onClose={() => setModals({photosPreview: null})} />}
    </div>,
    <Message key={2} type="success" open={modals.deleteSuccess ? true : false} message={modals.deleteSuccess} onClose={onCloseMessage} />,
    <Message key={3} type="success" open={modals.editSuccess ? true : false} message={modals.editSuccess} onClose={onCloseMessage} />,
    
  ]
}

export default Datapanel;