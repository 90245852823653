import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import { useContext, useEffect, useState } from "react";
import Auth from "./Auth";
import Divider from "./Divider";
import { AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import $auth from '../../services/$auth';
import Message from '../ui/Message';
import useMergeState from "../hooks/useMergeState";
import $cookies from "../../services/$cookies";
import { Stack, Typography } from "@mui/material";

import googleLogo from '../../images/btn_google_light_normal_ios.svg';
import StyledGoogleButton from "../ui/StyledGoogleButton";
import { host } from "../../config/urls";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleRegistrationForm from "./GoogleRegistrationForm";
import { Refresh } from "@mui/icons-material";
import ConfirmDialog from "../ui/ConfirmDialog";

function Login(props) {
  const {lang, resendEmail, onUpdateState} = useContext(AppContext);

  const [state, setState] = useMergeState({email: '', password: '', loading: false, success: null, error: {}});

  const [firstUse, setFirstUse] = useMergeState({auth: {}, open: false});

  const [deactivated, setDeactivated] = useMergeState({open: false, loading: false, error: null, success: null});
  const [deleted, setDeleted] = useMergeState({open: false, loading: false, error: null, success: null});

  useEffect(() => {
    if($cookies.get('verification') === 'true') {
      setState({success: lang.verification_success})
    }

    if($cookies.get('verification') === 'false') {
      setState({error: {message: lang.verification_failed}});
    }

    if($cookies.get('deleteAccount') === 'true') {
      setState({success: lang.account_deleted})
    }

    if($cookies.get('deleteAccount') === 'false') {
      setState({error: {message: lang.account_deleted}});
    }

    if($cookies.get('reset') === 'true') {
      setState({success: lang.reset_success})
    }

    if($cookies.get('reset') === 'false') {
      setState({error: {message: lang.reset_failed}})
    }

    if($cookies.get('session') === 'true') {
      setState({error: {message: lang.session_expired}})
    }

    if($cookies.get('deactivated') === 'true') {
      setState({success: lang.account_deactivated})
    }

    if($cookies.get('deleted') === 'true') {
      setState({success: lang.account_deleted})
    }


    $cookies.remove('verification');
    $cookies.remove('session');
    $cookies.remove('reset');
    $cookies.remove('deactivated');
    $cookies.remove('deleted');
    $cookies.remove('deleteAccount');

    return () => {
      onUpdateState({resendEmail: false})
    }

  }, [])

  //STANDARD LOGIN
  const onSubmit = (evt) => {
    evt.preventDefault();
    setState({loading: true, error: {}});

    $auth.login(email, password)
      .then(data => {
        setState({loading: false});
        onUpdateState({
          user: data.user,
          locale: data.user.language,
          loggedIn: true
        });
      })
      .catch(err => {
        console.log(err)
        if(err.status === 428) {
          onUpdateState({resendEmail: true})
        }

        if(err.status === 451) {
          setDeleted({open: true})
          setState({loading: false});
          return
        }

        if(err.status === 423) {
          setDeactivated({open: true})
          setState({loading: false})
          return
        }

        setState({loading: false, error: err.message})
      })
       
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}});
  }

  const onResend = (evt) => {
    $auth.resendEmail({email: state.email})
      .then(data => setState({success: lang.email_resend_success}))
      .catch(err => setState({error: err}))
  }


  //GOOGLE SIGN-IN

  //AUTHORIZATION SUCCESS
  const responseGoogle = (response) => {
    setState({loading: true, error: {}});
    $auth.loginGoogle({ code: response.code })
      .then(response => {  

        if(!response.user.first_use) {
          authorizeUser(response.user, {...response})
        } else {
          setFirstUse({auth: {...response}, open: true});
        }
        
      })
      .catch(err => {
        setState({loading: false, error: err})
      })
  }

  //AUTHORIZATION ERROR
  const errorGoogle = (err) => {
    console.log(err);
  }

  //GOOGLE SIGN-IN HOOK
  const signIn = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: errorGoogle,
    select_account: true,
    flow: 'auth-code',
    scope: 'email openid profile',
    hosted_domain: 'https://dev.croplab.gilab.rs'
  })

  //SIGN-IN USER TO CROPLAB - CALLBACK
  const authorizeUser = (newUser, model = {...firstUse.auth}) => {
    let data = $auth.authorizeUser({...model, user: {...newUser}});
    setState({loading: false});
    onUpdateState({
      user: data.user,
      locale: data.user.language,
      loggedIn: true
    });
  }

  const {email, password, success, error, loading} = state;

  return (
    <Auth>
       <Box style={{textAlign: 'center', padding: '5px 5px'}}>
        <Typography variant="h4" style={{textAlign: 'center', margin: '10px 0'}}>{lang.login}</Typography>
        <Divider />
        <Typography variant="subtitle1" style={{color: 'rgba(0,0,0,.8)'}}>{lang.welcome}</Typography>
        <Typography variant="subtitle2" style={{color: 'rgba(0,0,0,.8)'}}>{lang.no_account} <Link to="/register">{lang.free_register}</Link></Typography>
      </Box>

      <Box autoComplete="off" style={{padding: '5px'}} component='form' sx={{'& .MuiTextField-root, & .MuiButton-root': {mb: '7px', mt: '7px' }}} onSubmit={onSubmit}>
        <div>
          <TextField autoComplete="username" size="small" error={error.email !== undefined || error.non_field_errors !== undefined} helperText={error.email} fullWidth label={lang.email}type='email' value={email} onChange={(evt) => setState({email: evt.target.value})} />
        </div>

        <div>
          <TextField autoComplete="current-password" size="small" error={error.password !== undefined || error.non_field_errors !== undefined} helperText={error.password} fullWidth label={lang.password} type='password' value={password} onChange={evt => setState({password: evt.target.value})} />
        </div>

        <div>
          <LoadingButton loading={loading} fullWidth variant="contained" type="submit">{lang.submit_log}</LoadingButton>
          <Stack direction="row" justifyContent="space-between">
            {resendEmail && <a onClick={onResend} style={{fontSize: '12px', display: 'flex', textAlign: 'right', cursor: 'pointer', justifyContent: 'flex-end', alignItems: 'center'}} className="text-link"><Refresh fontSize="small" />&nbsp; Resend email</a>}
            {!resendEmail && <div></div>}
            <Link className="text-link" style={{display: 'block', textAlign: 'right', fontSize: '12px'}} to="/forgot-password">{lang.did_you_forget}</Link>
          </Stack>
          <br/>
          {process.env.REACT_APP_BRANCH === 'dev' && 
          <StyledGoogleButton onClick={signIn} text={lang.google_sign_in} />}
        </div>
      </Box>

      <GoogleRegistrationForm auth={firstUse.auth} open={firstUse.open} onClose={(evt, reason) => {if(reason !== 'backdropClick') setFirstUse({open: false})}} onSubmit={(newUser) => {setFirstUse({open:false}); authorizeUser(newUser) }} />

      <ConfirmDialog loading={deactivated.loading} open={deactivated.open} 
        confirmColor="warning"
        confirmText={lang.activate_account}
        question={lang.activation_message}
        title={lang.activate_account}
        onClose={() => setDeactivated({open: false, success: null, error: null, loading: null})}
        onSubmit={() => {
          setDeactivated({loading: true, error: null, success: null});
          $auth.activateAccount({email: state.email})
            .then(data => {
              setDeactivated({open: false, loading: false, error: null});
              setState({ success: data.success})
            })
            .catch(err => {
              console.log(err)
            })
        }}>
          <p className="text-warning"><strong>{lang.activation_note}</strong></p>
      </ConfirmDialog>

      <ConfirmDialog loading={deleted.loading} open={deleted.open} 
        confirmColor="warning"
        confirmText={lang.restore_account_btn}
        question={lang.restore_message}
        title={lang.restore_account}
        onClose={() => setDeleted({open: false, success: null, error: null, loading: null})}
        onSubmit={() => {
          setDeleted({loading: true, error: null, success: null});
          $auth.restoreAccount({email: state.email})
            .then(data => {
              setDeleted({open: false, loading: false, error: null});
              setState({ success: data.success})
            })
            .catch(err => {
              console.log(err)
            })
        }}>
      </ConfirmDialog>

      <Message type="success" open={success ? true : false} message={success} onClose={() => setState({success: null})} />
      <Message type="error" open={error.non_field_errors !== undefined} message={error.non_field_errors} onClose={() => onCloseMessage()} />
      <Message type="error" open={error.message !== undefined} message={error.message} onClose={() => onCloseMessage()} />
    </Auth>
  )
}

export default Login;