import ReactDOMServer from 'react-dom/server';
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import BingSource from 'ol/source/BingMaps';
import URL from "./urls";
import {createXYZ} from 'ol/tilegrid';
import axios from "axios";
import Overlay from "ol/Overlay";
import { Room } from "@mui/icons-material";


const tileLoadFunction = (tile, src) => {
	axios.get(src, {responseType: 'blob'})
		.then(result => {
			if(result.data !== undefined)  tile.getImage().src = window.URL.createObjectURL(result.data);
			else tile.getImage().src = '';
		})
		.catch(err => { tile.getImage().src = ''; });
}

export const defaults = {
	base: 'sat',
	layer: 'ndvi',
	opacity: 100,
	rgb: false,
	sensors: false,
	samples: false,
	photos: false,
	source: 's2',
	zoom: 4,
	center: [4.1832157, 48.1045271],
	geolocation: false
}


export const basemaps = (active) => {
	return [
		new TileLayer({
			name: 'sat',
			zIndex: 0,
			visible: active === 'sat',
			preload: Infinity,
			source: new BingSource({
				crossOrigin: 'anonymous',
				key: 'AnZGBE5znTvDpsqWaUdMP_Lx6KANn466hplI_7R1IYP38tGZ2hry2xx6xYIn2lxE',
				imagerySet: "AerialWithLabels",
				hidpi: true,
				wrapX: false,
				maxZoom: 19
			})
		}),
		new TileLayer({
			name: 'map',
			zIndex: 0,
			visible: active === 'map',
			preload: Infinity,
			source: new BingSource({
				crossOrigin: 'anonymous',
				key: 'AnZGBE5znTvDpsqWaUdMP_Lx6KANn466hplI_7R1IYP38tGZ2hry2xx6xYIn2lxE',
				imagerySet: "Road",
				hidpi: true,
				wrapX: false,
				maxZoom: 19
			})
		})
	]
}

export const imagery = (id, active, time, isPlanet) => {
	return new TileLayer({
		name: 'rgb',
		zIndex: 1,
		preload: Infinity,
		cacheSize: 0,
		visible: active,
		source: new TileWMS({
			url: URL.WMS,
			tileGrid: createXYZ({tileSize: 512}),
			tileLoadFunction: tileLoadFunction,
			params: {
				'LAYERS' : `${id}:${id}_rgb${isPlanet ? '_planet' : ''}`,
				'TILED': true,
				'TIME': time
			}
		})
	})
}

export const indices = (id, active, time, opacity, lang = {}, isPlanet, year) => {
	
	return [
		new TileLayer({
			name: 'ndvi',
			displayName: lang.ndvi_full || 'Normalized Difference Vegetation Index (NDVI)',
			zIndex: 2,
			cacheSize: 0,
			preload: Infinity,
			visible: active === 'ndvi',
			opacity: opacity/100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({tileSize: 512}),
				tileLoadFunction: tileLoadFunction,
				params:	{
					'LAYERS' : `${id}:${id}_ndvi${isPlanet ? '_planet' : ''}`,
					'TILED': true,
					'TIME': time
				}
			})
		}),
		new TileLayer({
			name: 'chl',
			displayName: lang.chl_full || 'Leaf Chlorophyll Index',
			zIndex: 2,
			cacheSize: 0,
			preload: Infinity,
			visible: active === 'chl',
			opacity: opacity/100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({tileSize: 512}),
				tileLoadFunction: tileLoadFunction,
				params:	{
					'LAYERS' : `${id}:${id}_chl${isPlanet ? '_planet' : ''}`,
					'TILED': true,
					'TIME': time
				}
			})
		}),
		new TileLayer({
			name: 'productivity',
			displayName: lang.productivity || 'Productivity',
			zIndex: 2,
			cacheSize: 0,
			preload: Infinity,
			visible: active === 'productivity',
			opacity: opacity/100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({tileSize: 512}),
				tileLoadFunction: tileLoadFunction,
				params:	{ 'LAYERS' : `${id}:${id}_mz${isPlanet ? '_planet' : ''}`, 'TILED': true, }
			})
		}),
		new TileLayer({
			name: 'mzones',
			displayName: lang.mzones || 'Management Zones',
			zIndex: 2,
			cacheSize: 0,
			preload: Infinity,
			visible: active === 'mzones',
			opacity: opacity/100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({tileSize: 512}),
				tileLoadFunction: tileLoadFunction,
				params:	{ 'LAYERS' : `${id}:${id}_mz${isPlanet ? '_planet' : ''}`, 'STYLES': 'mz_3', 'TILED': true, }
			})
		}),
		new TileLayer({
			name: 'cumndvi',
			displayName: lang.cumndvi || 'Cummulative NDVI',
			zIndex: 2,
			cacheSize: 0,
			preload: Infinity,
			visible: active === 'cumndvi',
			opacity: opacity/100,
			source: new TileWMS({
				url: URL.WMS,
				tileGrid: createXYZ({tileSize: 512}),
				tileLoadFunction: tileLoadFunction,
				params:	{
					'LAYERS' : `${id}:${id}_cumndvi${isPlanet ? '_planet' : ''}`,
					'TILED': true,
					'DIM_YEAR': year
				}
			})
		}),
	]
}

export const overlays = () => {
	let divA = document.createElement('div');
	divA.innerHTML = ReactDOMServer.renderToString(<Room fontSize='large' color="error" />);

	let divB = document.createElement('div');
	divB.innerHTML = ReactDOMServer.renderToString(<Room fontSize='large' color="warning" />);

	return [
		new Overlay({
			id: 'A',
			element: divA,
			positioning: 'bottom-center',
			stopEvent: false,
			offset: [0, 5]
		}),
		new Overlay({
			id: 'B',
			element: divB,
			positioning: 'bottom-center',
			stopEvent: false,
			offset: [0, 5]
		}),
	]
}

export const getLayerNames = (lang) => {
	return indices(null, null, null, 100, lang).map(layer => ({label: layer.get('displayName'), value: layer.get('name')}))
}
