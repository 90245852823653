import { Chip, Container, Dialog, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Close } from "@mui/icons-material";
import PlantingView from "../views/PlantingView";
import moment from "moment";
import Divider from "../../auth/Divider";
import FertigationView from "../views/FertigationView";
import HarvestingView from "../views/HarvestingView";
import TillageView from "../views/TillageView";
import NoteView from "../views/NoteView";
import PlantProtectionView from "../views/PlantProtectionView";
import $data from "../../../services/$data";


function JobOrdersPreview(props) {
	const {lang, activityTypes} = useContext(AppContext)

	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const getActivityView = (type, props = {}) => {
		switch (type) {
			case 1: return <TillageView isJob={true} {...props} />
			case 2: return <FertigationView isJob={true} {...props} />
			case 3: return <PlantingView isJob={true} {...props} />
			case 4: return <PlantProtectionView isJob={true}{...props} /> //PLANT PROTECTION
			case 5: return <HarvestingView isJob={true} {...props} />
			case 6: return <NoteView {...props} />
			default: return null
		}
	}

	const renderDetails = (jobList) => {

		return jobList.map((obj, key) => {
			return (
				<Grid style={{marginBottom: '30px'}} key={key} container spacing={2}>
					
					<Grid item xs={12}>
						<Stack direction="row" justifyContent="space-between" alignItems="center" style={{margin: 0}}>{$data.getActivityNameById(activityTypes, obj.activity_type)} {!obj.activity_status ? <Chip color={'warning'} label={lang.act_job_active} />  : <Chip color={'success'} label={lang.act_job_completed} />}</Stack>
					</Grid>
					<Grid item xs={12}>
						{getActivityView(obj.activity_type, {data: obj})}
					</Grid>
				</Grid>
			)
		})
	}

	const groupByParcel = () => {
		let group = {};
		let filtered = props.jobs.filter(obj => {
			if(obj.activity_type === 4) {
				return props.ids.indexOf(obj.id) > -1
			}	else {
				return props.ids.indexOf(obj.pk) > -1

			}
			})
			.map(obj => {
				if(group.hasOwnProperty(obj.parcel_name)) {
					group[obj.parcel_name].push(obj);
				} else {
					group[obj.parcel_name] = [obj];
				}
			})

		return group
	}

	const renderList = () => {
		let group = groupByParcel();
		return Object.keys(group).map((name, key) => {
			return (
				<Grid item xs={12} key={key}>
					<Typography variant={'p'} color="primary"><strong>{lang.parcel_name_full}:</strong> {name}</Typography>
					<Divider style={{width: '100%', height: '2px', marginTop: '5px'}} />
					{renderDetails(group[name])}
				</Grid>
			)
		})
	}

	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.act_activities} - {props.jobs[0] ? moment(props.jobs[0]['date']).format('DD-MMM-YYYY') : ''}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container>
          <Grid container className="job-orders-preview-container" spacing={2}>
            
            {renderList()}
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
	)
}

export default JobOrdersPreview;