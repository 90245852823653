const sr = {
  privacy_policy: 'Politika privatnosti',
  privacy_consent: 'Pročitao sam i pristajem na CropLab',
  privacy_consent2: 'politiku privatnosti',
  privacy_terms: 'uslove korišćenja', 
  terms_policy: 'Uslovi korišćenja',
  and: 'i',
  google_registration_form_text: 'Molimo Vas, popunite neophodne informacije ispod kako biste pristupili aplikaciji',
  uk: 'Ujedinjeno kraljevstvo',
  greece: 'Grčka',
  poland: 'Poljska',
  serbia: 'Srbija',
  portugal: 'Portugal',
  country: 'Država',
  email: 'E-pošta',
  password: 'Lozinka',
  repeat: 'Ponovite lozinku',
  language: 'Jezik',
  serbian: 'Srpski',
  english: 'Engleski',
  submit_reg: 'Registracija',
  submit_log: 'Prijava',
  submit_res: 'Potvrdi',
  email_resend_success: 'Verifikacioni link je poslat ponovo na adresu elektronske pošte, koju ste uneli',
  delete_account_confirmation_link: 'Vaš zahtev za brisanje naloga je poslat. Proverite Vašu elektronsku poštu.',
  verification_success: 'Verifikacija uspešna',
  verification_failed: 'Verifikacija neuspešna! Molimo Vas, kontaktirajte našu tehničku podršku za više informacija!',
  verification_sent: 'Verifikacioni link je poslat na Vašu adresu elektronske pošte',
  verification_title: 'Verifikacija Vašeg naloga je u toku',
  verification_wait: 'Molimo sačekajte...',
  session_expired: 'Sesija istekla! Molimo Vas, prijavite se ponovo!',
  login: 'Prijava',
  google_sign_in: 'Prijavite se preko Google naloga',
  google_sign_up: 'Registrujte se preko Google naloga',
  register: 'Registracija',
  forgot: 'Zaboravljena lozinka',
  forgot_text: 'Molimo Vas, unesite adresu elektronske pošte povezane sa vašim nalogom',
  reset_sent: 'Link za izmenu lozinke je poslat na Vašu adresu elektronske pošte',
  reset_success: 'Izmena lozinke uspešna',
  reset_failed: 'Izmena lozinke neuspešna! Molimo Vas, kontaktirajte našu tehničku podršku za više informacija!',
  go_back: 'Nazad',
  welcome: 'Dobrodošli na CropLab, Vašu ličnu digitalnu laboratoriju za sve useve',
  no_account: "Ako nemate nalog, molimo Vas",
  free_register: 'registrujte se ovde besplatno',
  did_you_forget: 'Zaboravili ste lozinku?',
  about: 'O nama',
  pricing: 'Cenovnik',
  contact: 'Kontakt',
  map_view: 'Mapa',
  vrt_view: 'Varijabilno tretiranje',
  fieldbook_view: 'Knjiga polja',
  fieldbook_item_view: 'Knjiga polja',
  alerts_view: 'Upozorenja',
  alerts_filter_region: 'Pretraga po regionu',
  alerts_all_regions: 'Svi regioni',
  alerts_error: 'Došlo je do greške prilikom učitavanja vesti/upozorenja',
  no_alerts_filter: 'Nema novih upozorenja/vesti koji odgovaraju Vašim parametrima pretrage',
  no_alerts: 'Nema novih upozorenja/vesti',
  alerts_learn_more: 'Vidi detalje',
  account_settings_view: 'Korisnički nalog i podešavanja',
  help_view: 'Pomoć',
  payment_view: 'Status pretplate',
  'job-orders_view': 'Radni nalozi',
  logout: 'Izlogujte se',
  account_settings: 'Korisnički nalog i podešavanja',
  loading: 'Učitavanje podataka...',
  account: 'Nalog',
  billing: 'Kontrola pretplate',
  personal_info: 'Lični podaci',
  first_name: 'Ime',
  last_name: 'Prezime',
  currency: 'Valuta',
  currency_eur: 'Evro (€)',
  currency_uk: 'Britanska funta (£)',
  currency_rs: 'Srpski dinar (RSD)',
  currency_pl: 'Poljski zlot (zł)',
  currency_usd: 'Američki dolar ($)',
  change_password: 'Izmena lozinke',
  old_password: 'Stara lozinka',
  new_password: 'Nova lozinka',
  repeat_new_password: 'Ponovite novu lozinku',
  confirm: 'Potvrdite',
  delete: 'Obrišite',
  close: 'Zatvorite',
  account_cancelation: 'Deaktivirajte/obrišite nalog',
  deactivate_account: 'Deaktivirajte nalog',
  deactivate_question: 'Da li ste sigurni da želite da deaktivirate Vaš nalog?',
  activate_account: 'Aktivirajte nalog',
  activation_message: 'Vaš nalog je deaktiviran. Ako želite da aktivirate nalog ponovo, pritisnite "Aktivirajte nalog".',
  activation_note: 'NAPOMENA: Aktivacijom Vašeg naloga, obrada podataka će se nastaviti prema Vašem planu pretplate.',
  account_deactivated: 'Vaš nalog je uspešno deaktiviran.',
  delete_account: 'Obrišite nalog',
  delete_question: 'Da li ste sigurni da želite da obrišete Vaš nalog?',
  account_deleted: 'Vaš nalog je uspešno obrisan.',
  deactivation_note: 'NAPOMENA: Deaktivacijom naloga nećete moći da se ulogujete na platformu i pristupite Vašoj farmi, dok ne izvršite ponovnu aktivaciju naloga. Obrada podataka i pretplatni rok će biti zamrznuti do ponovne aktivacije naloga. Da biste aktivirali nalog, potrebno je samo da se prijavite na platformu sa Vašom elektronskom poštom i lozinkom.,',
  deletion_note: 'UPOZORENJE: Brisanjem naloga, svi prikupljeni i obrađeni podaci povezani sa Vašim nalogom biće trajno obrisani. Nakon potvrde brisanja naloga, imate vremenski rok od 30 dana da se predomislite i zahtevate poništenje akcije. Ukoliko to ne uradite u predviđenom vremenskom roku, akcija brisanja naloga i pratećih podataka biće izvršena. Da biste poništili brisanje naloga, potrebno je da se prijavite na platformu sa Vašom elektronskom poštom i lozinkom i potvrdite poništenje naloga.',
  restore_account: 'Povraćaj naloga',
  restore_account_btn: 'Povratite nalog',
  restore_message: 'Vaš nalog je obrisan. Ako želite da poništite brisanje naloga, pritisnite "Povratite nalog".',
  no_employees: 'Nije dodat nijedan zaposleni',
  manager: 'Poslovođa',
  operator: 'Operater',
  operators: 'Operateri',
  no_operators: 'Nema dodatih operatera',
  fieldbook_role: 'Knjiga polja',
  job_orders_role: 'Radni nalozi',
  notifications: 'Obaveštenja',
  no_notifications: 'Trenutno nema obaveštenja za Vas',
  notification_settings: 'Odaberite gde želite da Vam stižu obaveštenja',
  in_app: 'Na aplikaciji',
  sms: 'SMS',
  employees: 'Zaposleni',
  add_employee: 'Dodajte zaposlenog',
  edit_employee: 'Izmenite zaposlenog',
  delete_employee: 'Obrišite zaposlenog',
  employee_nav_message: 'Izmena/brisanje zaposlenih se obavlja u sekciji za korisnička podešavanja',
  fullname: 'Ime i prezime',
  phone: 'Kontakt broj',
  role_type: 'Tip',
  role_perm: 'Ovlašćenje',
  success_update_profile: 'Lični podaci uspešno ažurirani',
  success_create_employee: 'Zaposleni uspešno dodat',
  success_edit_employee: 'Zaposleni uspešno ažuriran',
  success_delete_employee: 'Zaposleni uspešno obrisan',
  parcel_name: 'Naziv',
  parcel_crop: 'Kultura',
  parcel_area: 'Površina',
  parcel_status: 'Status',
  parcel_active: 'Aktivna',
  parcel_inactive: 'Neaktivna',
  parcel_sowing: 'Setva',
  parcel_harvest: 'Žetva',
  vrt_title: 'Odaberite polja iz menija za koja želite da napravite kartu varijabilnog tretiranja',
  vrt_source: 'Odaberite proračun na osnovu višegodišnjih podataka(produktivnost) ili dnevnih podataka(ndvi)',
  vrt_yearly: 'Višegodišnje',
  vrt_daily: 'Dnevno',
  vrt_input_title: 'Unesite količinu prema zoni:',
  vrt_export_title: 'Izaberite format fajla za izvoz karte:',
  export: 'Preuzmite',
  vrt_parcel_list_title: 'Odaberite polje/polja',
  no_parcels: 'Nema definisanih polja za odabranu sezonu',
  filter_parcels: 'Pretraga parcela prema nazivu ili kulturi...',
  date: 'Datum',
  vrt_no_data: 'Nema podataka',
  fld_title: 'Odaberite polje za koje želite da pregledate aktivnosti',
  fld_filters: 'Filteri',
  fld_machines: 'Mašine',
  fld_con_machines: 'Priključne mašine',
  fld_download_btn: 'Preuzmite knjigu polja',
  fld_filter_by_name: 'Pretraga po nazivu polja',
  fld_filter_by_crop: 'Pretraga po kulturi',
  fld_filter_by_cropgroup: 'Pretraga po kategoriji useva',
  fld_filter_by_activity: 'Pretraga po aktivnosti',
  fld_filter_by_parcel: 'Pretraga po polju',
  fld_filter_by_status: 'Pretraga po statusu',
  fld_select_all: 'Sve kulture',
  fld_select_all_parcels: 'Sva polja',
  fld_select_all_types: 'Sve aktivnosti',
  fld_no_filtered_parcels: 'Nema polja koja se odgovaraju vašim uslovima pretrage',
  fld_machine: 'Mašina',
  fld_con_machine: 'Priključna mašina',
  fld_machine_nav_message: "Izmena/brisanje mašina se obavlja u sekciji za korisnička podešavanja",
  fld_con_machine_nav_message: "Izmena/brisanje priključnih mašina se obavlja u sekciji za korisnička podešavanja",
  fld_add_machine: 'Dodajte mašinu',
  fld_edit_machine: 'Izmenite mašinu',
  fld_delete_machine: 'Uklonite mašinu',
  fld_add_con_machine: 'Dodajte priključnu mašinu',
  fld_edit_con_machine: 'Izmenite priključnu mašinu',
  fld_delete_con_machine: 'Uklonite priključnu mašinu',
  no_machines: 'Nema dodatih mašina',
  no_conn_machines: 'Nema dodatih priključnih mašina',
  num: 'Br.',
  success_create_machine: 'Uspešno dodata mašina',
  success_edit_machine: 'Uspešno izmenjena mašina',
  success_delete_machine: 'Uspešno uklonjena mašina',
  success_create_con_machine: 'Uspešno dodata priključna mašina',
  success_edit_con_machine: 'Uspešno izmenjena priključna mašina',
  success_delete_con_machine: 'Uspešno uklonjena priključna mašina',
  act_add_activity: 'Dodajte aktivnost',
  act_reg_practice: 'Regenerativne prakse',
  act_add_practice: 'Dodajte prakse',
  act_add_practice_success: 'Uspešno dodate prakse',
  act_delete_practices: 'Obrišite prakse',
  act_delete_practices_success: 'Uspešno obrisane prakse',
  act_practice_delete_question: 'Da li ste sigurni da želite da obrišete odabrane regenerativne prakse?',
  act_edit_practices: 'Izmenite prakse',
  act_edit_practices_success: 'Uspešno izmenjene prakse',
  act_no_practices: 'Niste dodali nijednu regenerativnu praksu',
  act_add_job_order: 'Napravite radni nalog',
  act_summary_report: 'Sumarni izveštaj',
  act_activities: 'Upisane aktivnosti',
  act_documents: 'Dokumenta',
  act_edit_delete: 'Izmena/brisanje dokumenata se obavlja u sekciji za korisnička podešavanja',
  act_document_tag_helper: 'Pritisnite ENTER kako biste potvrdili uneti tag',
  act_no_activities: 'Nema unetih aktinvosti',
  act_no_jobs: 'Nema napravljenih radnih naloga',
  act_no_jobs_filter: 'Nema napravljenih radnih naloga prema zadatim parametrima pretrage',
  act_job_active: 'U toku',
  act_job_completed: 'Završeno',
  act_job_mixed: 'Više naloga',
  act_see_jobs: 'Vidite detalje',
  act_jobs_all: 'Svi statusi',
  click_job: 'Kliknite ovde da vidite detalje',
  parcel_name_full: 'Naziv polja',
  activity_type: 'Tip aktivnosti',
  update_activity_success: 'Uspešno izmenjena aktivnost',
  delete_activity_success: 'Uspešno obrisana aktivnost',
  delete_activity: 'Obrišite aktivnost',
  delete_activity_question: 'Da li ste sigurni da želite da obrišete odabranu aktivnost?',
  act_select_type: 'Odaberite tip aktivnosti',
  act_job_order_switch: 'Radni nalog će biti napravljen za odabrani tip aktivnosti i biće dodeljen operateru/operaterima. Molimo Vas, vodite računa da odaberete odgovarajući datum za izvršavanje radnog naloga.',
  act_job_finished: 'Označite kao izvršeno',
  act_job_update: 'Ažurirajte nalog',
  //ACTIVITY FIELDS
  tillage_type: 'Tip obrade',
  fertigation_type: 'Tip đubrenja',
  fertigation_product: 'Tip đubriva',
  fertigation_product_placeholder: 'Pretražite/Unesite tip đubriva',
  comment: 'Komentar',
  crop_group: 'Kategorija useva',
  crop_type: 'Usev',
  seed_type: 'Sorta/Hibrid',
  seed_type_placeholder: 'Pretražite/Unesite sortu/hibrid',
  moisture: 'Vlažnost (%)',
  impurities: 'Primese (%)',
  hectolitre_mass: 'Hektolitarska masa (%)',
  digestion: 'Digestija (%)',
  oil_content: 'Sadržaj ulja (%)',
  protein_content: 'Sadržaj proteina (%)',
  plants_m2: 'Biljaka/m2',
  plants_ha: 'Biljaka/ha',
  plants_parcel: 'Biljaka/polje',
  yield_ha: 'Prinos/ha (kg)',
  yield_parcel: 'Prinos/polje (kg)',
  quantity_ha: 'Količina/ha',
  quantity_parcel: 'Količina/polje',
  quantity_unit: 'Jedinica količine',
  depth: 'Dubina (cm)',
  row_spacing: 'Međuredni razmak (cm)',
  product_unit_price: 'Jedinična cena repromaterijala',
  product_price_ha: 'Cena repromaterijala/ha',
  product_price_parcel: 'Cena repromaterijala/polje',
  price_ha: 'Cena operacije/ha',
  price_parcel: 'Cena operacije/polje',
  total: 'Ukupno',
  total_price_ha: 'Ukupni troškovi/ha',
  total_price_parcel: 'Ukupni troškovi/polje',
  protection_type: 'Tip nege',
  product: 'Sredstvo',
  active_substance: 'Aktivna materija',
  agrotechnical_type: 'Agrotehnička mera',
  irrigation_norm: 'Zalivna norma (mm)',
  plant_protection_no_data: 'Niste uneli nijedan zapis',
  add_protection_row: 'Dodajte zapis',
  show_form: 'Forma za unos',
  edit: 'Izmenite',
  cancel: 'Otkažite',
  from: 'Od',
  to: 'Do',
  send_sms: 'Poslati SMS',
  download_pdf: 'Preuzmite PDF',
  download_xls: 'Preuzmite XLS',
  reset_filter: 'Ukloni filtere',
  fill_form: 'Popunite formu',
  archive_job: 'Označite kao izvršeno',
  job_archive_text: 'Ukoliko želite da posao radni nalog kao izvršen i da ga sačuvate u evidenciji aktivnosti, potrebno je da pošaljete obrazac klikom na dugme "Označite kao izvršeno" koje se nalazi ispod obrasca. Pre nego što radni nalog označite kao izvršen, možete izvršiti izmene podataka prikupljenih tokom izvršavanja radnog naloga, klikom na dugme "Izmenite" i popunjavanjem obrasca.',
  see_on_map: 'Vidite na mapi',
  see_on_vra: 'Vidite na VRA',
  see_activities: 'Vidite aktivnosti',
  layers: 'Lejeri',
  my_fields: 'Moja polja',
  total_area: 'Ukupna površina',
  basemap: 'Podloga',
  source: 'Izvor',
  satellite: 'Snimak',
  index: 'Indeks',
  sensors: 'Senzori',
  sensors_view: 'Senzori',
  opacity: 'Vidljivost',
  ndvi_full: 'Vegetacija (NDVI)',
  cumndvi: 'Kumulativni NDVI',
  chl_full: 'Sadržaj hlorofila (CHL)',
  ndvi: 'Vegetacija (NDVI)',
  chl: 'Sadržaj hlorofila (CHL)',
  productivity: 'Produktivnost',
  mzones: 'Menadžment zone',
  map_filter: 'Pretraga po nazivu polja ili kulturi',
  season: 'Sezona',
  forecast_unavailable: 'Vremenska prognoza je trenutno nedostupna. Molimo Vas pokušajte kasnije.',
  sowing_date: 'Datum setve',
  summary: 'Sumarni pregled',
  query: 'Upit po tački',
  sensor: 'Upit po senzoru',
  crop_stage: 'Faza razvoja',
  meteo: 'Meteo',
  forecast: 'Vremenska prognoza',
  no_information: 'Informacija nedostupna',
  summary_details: 'Vidite detaljni prikaz',
  summary_click_info: 'Odaberite polje/polja na mapi kako biste videli sumarni prikaz',
  summary_no_data_parcel: 'Za odabrano polje/polja nije dostupan sumarni prikaz',
  summary_yield: 'Procena prinosa (t/ha)',
  detailed_summary_view: 'Detaljni sumarni pregled',
  query_click_info: 'Odaberite lokaciju/lokacije na mapi kako biste dobili informacije o aktivnom indeksu',
  query_no_data_parcel: 'Za odabranu lokaciju i aktivni indeks trenutno nisu dostupni podaci',
  query_all_data: 'Svi podaci',
  photos_click_info: 'Odaberite polje na mapi za koje želite da pregledate prikupljene fotografije',
  no_photos: 'Niste prikupili nijednu fotografiju za odabrano polje',
  sensor_click_info: 'Odaberite senzor na mapi kako biste dobili senzorska opažanja',
  sensor_details_link: 'Više informacija na Sensor Observation Service platformi',
  sensor_no_data: 'Nema senzorskih opažanja za odabrane parametre upita',
  forecast_click_info: 'Odaberite lokaciju/lokacije na mapi kako biste dobili vremensku prognozu',
  meteo_click_info: 'Odaberite polje/polja na mapi kako biste videli istorijske meteo podatke',
  meteo_no_data: 'Za odabrano polje/polja i/ili odabrani vremenski raspon, nisu dostupni istorijski meteo podaci',
  gdd_click_info: 'Odaberite polje/polja na mapi kako biste videli informacije o fazi razvoja biljke',
  gdd_no_crops_1: 'Kako biste pratili fazu razvoja kulture, potrebno je definisati bar jednu sezonu (setva/žetva). To možete učiniti u sekciji ',
  gdd_summary_section: 'SUMARNI PREGLED',
  gdd_no_crops_3: ' ("Dodajte zapis") ili možete kroz ',
  gdd_field_book: 'KNJIGU POLJA',
  gdd_no_crops_5: ' definisati setvu i žetvu za odgovarajuće polje.',
  gdd_no_data: 'Za odabrano polje/polja nisu dostupne informacije o fazi razvoja biljke',
  gdd: 'Suma aktivnih temperatura',
  reset_chart_view: 'Osvežite prikaz grafika',
  high_productivity: 'Visoka produktivnost',
  normal_productivity: 'Prosečna produktivnost',
  low_productivity: 'Niska produktivnost',
  temperature: 'Temperatura',
  precipitation: 'Padavine',
  humidity: 'Vlažnost vazduha',
  cloudiness: 'Oblačnost',
  wind_speed: 'Brzina vetra',
  pressure: 'Pritisak',
  today: 'Danas',
  no_fields: 'Nije dodato nijedno polje',
  action_compare: 'Uporedite dve lokacije',
  action_draw: 'Nacrtajte polje',
  action_upload: 'Učitajte parcele',
  action_edit: 'Izmenite podatke o polju',
  action_crop_history: 'Istorija kultura na polju',
  action_delete: 'Obrišite polje',
  action_measure_area: 'Izmerite površinu',
  action_measure_length: 'Izmerite dužinu',
  action_upload_success: 'Uspešno učitane parcele',
  select_files: 'Odaberite fajl(ove)',
  select_xls: 'Odaberite Excel fajl',
  select_documents: 'Odaberite dokument(a)',
  no_files_selected: 'Niste odabrali nijedan fajl',
  add_field: 'Dodajte novo polje',
  delete_field_question: 'Da li ste sigurni da želite da obrišete odabrano polje?',
  delete_field_title: 'Brisanje polja',
  success_add_field: 'Polje uspešno dodato',
  success_edit_field: 'Podaci o polju uspešno izmenjeni',
  success_delete_field: 'Polje uspešno obrisano',
  ok: 'OK',
  tmax: 'Temperatura (MAKS.)',
  tmin: 'Temperature (MIN.)',
  meteo_mode: 'Dnevno',
  accumulated_mode: 'Akumulirane padavine',
  Cloudy: 'Oblačno',
  main_error: 'Aplikacija je u procesu ažuriranja. Molimo Vas, pokušajte ponovo malo kasnije!',
  redirect_ac: 'CropLab je dostupan samo farmerima. Ako ste projektant/inspektor, molimo Vas da koristite CO2Agri platformu',
  next: 'Sledeći korak',
  back: 'Prethodni korak',
  skip: 'Preskočite korak (bez žetve)',
  finish: 'Završite',
  add_another_season: 'Add another crop season',
  insert_harvest: 'Unesite datum žetve',
  vrt_choose_source: 'Odaberite izvor snimaka',
  crop_history: 'Istorija useva',
  edit_crop_season: 'Izmena sezone useva',
  remove_crop_season: 'Brisanje sezone useva',
  remove_crop_season_warning: 'NAPOMENA: Ove aktivnosti će biti obrisane iz knjige polja',
  remove_crop_season_question: 'Da li ste sigurni da želite da obrišete odabranu sezonu',
  crop_history_short: 'Kulture',
  indices: 'Indeksi',
  indices_summary: 'Sumarni prikaz indeksa',

  add_record: 'Dodajte zapis',
  full_list: 'Vidite celu listu',
  no_crop_records: 'Nema unetih zapisa o istoriji useva',
  add_crop_season: 'Dodajte zapis o sezoni useva',
  crop_added_success: 'Sezona useva uspešno upisana',
  filter_job_orders_only: 'Radni nalozi',
  filter_job_completed: 'Izvršene aktivnosti',
  filter_job_in_progress: 'Aktivnosti u toku',
  error_server: 'Došlo je do greške na serveru. Molimo Vas, kontaktirajte tehničku podršku kako bismo u najkraćem roku rešili problem.',
  error_network: 'Servis je nedostupan. Molimo Vas da proverite Vašu internet konekciju. U slučaju da internet konekcija nije problem, kontaktirajte tehničku podršku.',
  error_network_status: 'Problem sa konekcijom',
  error_404: 'Adresa nije pronađena',
  btn_verify: 'Verifikacija',
  btn_edit: 'Izmena',
  phone_not_verified: 'Broj telefona nije verifikovan',
  sms_verification: 'Registracija i verifikacija mobilnog telefona',
  sms_verification_description: 'Molimo Vas, unesite validni broj mobilnog telefona i verifikujte preko SMS koda',
  sms_code_description: 'Unesite šestocifreni kod koji ste dobili putem SMS-a',
  request_sms: 'Pošaljite zahtev',
  verify_sms: 'Verifikujte SMS kod',
  request_sms_sent: 'SMS kod je poslat na broj telefona',
  phone_verification_success: 'Vaš broj telefona je uspešno verifikovan',
  document_name: 'Naziv',
  document_description: 'Opis',
  document_tags: 'Tagovi',
  doc_filter_by_name: 'Pretraga po nazivu',
  doc_filter_by_tags: 'Pretraga po tagovima',
  no_documents: 'Nijedan dokument nije dodat',
  no_documents_filter: 'Ne postoji dokument, koji odgovara zadatim parametrima pretrage',
  delete_document: 'Obrišite dokument',
  download_document: 'Preuzmite dokument',
  edit_document: 'Izmenite dokument',
  doc_select_all_tags: 'Svi tagovi',
  doc_error_delete: 'Došlo je do greške prilikom brisanja dokumenta',
  doc_success_delete: 'Dokument uspešno obrisan',
  doc_error_update: 'Došlo je do greške prilikom izmene dokumenta',
  doc_success_update: 'Dokument uspešno izmenjen',
  doc_delete_question: 'Da li ste sigurni da želite da obrišete odabrani dokument?',
  billing_request_continue: 'Formirajte pregled porudžbine',
  billing_price_parcel: 'Cena',
  billing_price_total: 'Ukupna cena',
  billing_request_submit: 'Poručite',
  billing_order: 'Pregled porudžbine',
  billing_lt_100: 'U besplatnoj verziji, možete imati jednu ili više aktivnih parcela, čija zbirna površina ne prelazi 100ha. Svako novo polje, kojim se prekoračuje dozvoljena površina, biće neaktivno i za njih je potrebno podneti zahtev za pretplatu, koji se obračunava prema planovima pretplate prikazanim ispod.',
  billing_gt_100: 'Prekoračena je površina od 100ha. Da biste aktivirali nova polja, potrebno je da nas kontaktirate i podnesete zahtev za pretplatu.',
  dont_show_again: "Ne prikazuj više ovaj prozor nakon učitavanja aplikacije",
  billing_free: 'Besplatno',
  billing_basic: 'Napredni plan',
  billing_pro: 'Napredni plan',
  billing_enterprise: 'Preduzetnički plan',
  billing_request_ask: 'Podnesite zahtev',
  billing_used_free: 'Iskorišćena besplatna površina',
  billing_used_paid: 'Prekoračena površina',
  ac_request_message: 'Dobili ste zahtev da dozvolite projektantu iz CO2Agri projekta da koristi Vaša polja za potrebe razvoja projekta karbon kredita. Možete prihvatiti ili odbiti zahtev(e). Ako prihvatite, za svako polje, koje projektant odabere, podaci će biti obrađeni besplatno i moći ćete da vidite rezultate.',
  ac_request_title: 'CO2Agri - zahtevi za razvoj projekta karbon kredita',
  ac_request_list_error: 'Došlo je do greške prilikom preuzimanja liste zahteva.',
  ac_request_status: 'Status zahteva',
  ac_request_accepted: 'PRIHVAĆEN',
  contact_us: 'Kontaktirajte nas',
  contact_name: 'Ime',
  contact_subject: 'Tema',
  contact_message: 'Poruka',
  contact_success: 'Poruka je poslata. Kontaktiraćemo Vas u najkraćem roku.',
  sensors_contact_message: 'Ukoliko imate senzore i želite da pratite merenja kroz CropLab platformu, molimo Vas da nas kontaktirate, kako bismo Vam pomogli oko integracije sa CropLab platformom.',
  no_sensors: 'Trenutno nema integrisanih senzora na platformi',
  edit_sensor: 'Izmena informacija o senzoru',
  sensor_name: 'Naziv senzora',
  sensor_type: 'Tip senzora',
  sensor_description: 'Opis',
  sensor_edit_success: 'Informacije o senzoru uspešno izmenjene',
  sensor_measurements: 'Senzorska opažanja',
  photos: 'Slike',
  soil_measurements: 'Uzorci zemljišta',
  soil_field_mapping: 'Usklađivanje naziva obaveznih kolona',
  soil_measurements_upload: 'Učitavanje uzoraka zemljišta',
  soil_latitude: 'Latituda',
  soil_longitude: 'Longituda',
  soil_datestamp: 'Datum',
  soil_name: 'Naziv',
  soil_parcel__name: 'Naziv polja',
  soil_field_mapping_description: 'Odaberite naziv kolone iz Vašeg fajla (desno) tako da odgovara budućem nazivu kolone (levo) - Obavezno je uneti ili koordinate (Latituda i Longituda) ili pripadajući naziv polja',
  no_samples: 'Nema dodatih uzoraka zemljišta',
  soil_type_error: 'Fajl koji ste pokušali da učitate nije odgovarajućeg formata. Dozvoljeni format fajla je isključivo Excel (.xls, .xlsx)',
  soil_upload_success: 'Uzorci zemljišta uspešno učitani',
  download_example: 'Preuzmite primer (po koordinatama)',
  download_example_parcels: 'Preuzmite primer (po poljima)',
  soil_samples: 'Uzorci',
  my_location: 'Moja lokacija',
  search_placeholder: 'Pronađi lokaciju/adresu...',
  go_to: 'Idi na',
  geolocation_error: 'Pronalaženje Vaše pozicije nije uspelo. Molimo Vas pokušajte kasnije.',
  delete_sample_title: 'Brisanje uzoraka',
  delete_sample_question: 'Da li ste sigurni da želite da obrišete odabrane uzorke?',
  select_all: 'Odaberite sve',
  samples_delete_success: 'Odabrani uzorci su uspešno obrisani',
  samples_delete_error: 'Došlo je do greške prilikom brisanja odabranih uzoraka',
  upload_photos: 'Učitajte slike',
  edit_photo: 'Izmenite podatke o slici',
  upload_photos_note: 'NAPOMENA: Slike moraju imati ili geo-tag/lokaciju ili pripadajuću parcelu',
  images_upload_success: 'Uspešno učitane slike',
  delete_photo: 'Obrišite sliku',
  delete_photo_question: 'Da li ste sigurni da želite da obrišete odabranu sliku?',
  irrigation: 'Navodnjavanje',
  or: 'ILI',
  ie: 'npr.',
  total_amount: 'Ukupna količina',
  area_error: 'Polje mora imate površinu veću od 0 ha',
  help: 'Pomoć',
  none: 'Nema',
  gddBase: 'Osnova',
  input_per_package: 'Unos po pakovanju',
  number_of_packages: 'Broj pakovanja',
  number_of_packages_desc: 'npr. broj vreća sa đubrivom',
  quantity_per_package: 'Količina po pakovanju',
  quantity_per_package_desc: '',
  number_of_seeds: 'Broj zrna/semena',
  price_per_package: 'Cena po pakovanju',
  calculate: 'Izračunaj i potvrdi',
  collection_name: 'Naziv kolekcije',
  year: 'Godina',
  fuel_consumption: 'Potrošnja goriva [litar]',
  syntethic: 'Sintetički',
  natural: 'Prirodni',
  natural_type: 'Tip preparata',
  unknown: 'Nedefinisano',
  num_of_days: 'Broj dana',
  start_date: 'Početni datum',
  end_date: 'Završni datum',
  add: 'Dodajte',
  animal_species: 'Tip životinje',
  num_of_animals: 'Broj životinja',
  add_animals: 'Dodajte životinje',
  date_format_note: 'Molimo Vas, koristite odgovarajući format za datum u vašoj tabeli - GGGG-MM-DD.',
  download_all_data: 'Preuzmite podatke',
  download_data_parcel: 'Preuzmite podatke za odabrano polje',
  download_error: 'Došlo je do greške prilikom preuzimanja podataka. Molimo Vas da proverite da li su definisani parametri ispravno uneti.',
  verify_delete: 'Verifikacija Vašeg zahteva za brisanjem naloga je u toku',
}

export default sr;