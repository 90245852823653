class $activities {

  getTillagePrices(name, value, area) {
    let additional = {};

    if(name === 'depth') {
      if(!isNaN(parseFloat(value))) {
        value = Number(value).toString()
      } else {
        value = 0;
      }
    }

    if(name === 'price_ha') {
			if(!isNaN(parseFloat(value))) {
				additional.price_parcel = (area * parseFloat(value)).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_parcel = 0;
        value = 0
			}
		}

		if(name === 'price_parcel') {
			if(!isNaN(parseFloat(value))) {
				additional.price_ha = (parseFloat(value)/area).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_ha = 0;
        value = 0;
			}
		}

    return {value, additional}
  }

  getPlantingPrices(name, value, model, area) {

    let additional = {};

    if(name === 'production_type') {
      additional.production_subtype = '';
      additional.seed_type = '';
    }

    if(name === 'production_subtype') {
      additional.seed_type = '';
    }

    if(name === 'depth' || name === 'row_spacing') {
      if(!isNaN(parseFloat(value))) {
        value = Number(value).toString();
      } else {
        value = 0;
      }
    }

    if(name === 'plants_m2') {
      if(!isNaN(parseFloat(value))) {
				additional.plants_parcel = (area * 10000 * parseFloat(value)).toFixed(2);
        additional.plants_ha = (parseFloat(value * 10000)).toFixed(2);
        // additional.product_price_parcel = (area * parseFloat(value) * model.product_unit_price).toFixed(2);
        // additional.product_price_ha = (parseFloat(value) * model.product_unit_price).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.plants_parcel = 0;
        additional.plants_ha = 0;
        value = 0;
			}
    }

    if(name === 'plants_ha') {
      if(!isNaN(parseFloat(value))) {
				additional.plants_parcel = (area * parseFloat(value)).toFixed(2);
        additional.plants_m2 = (parseFloat(value / 10000)).toFixed(4);
        // additional.product_price_parcel = (area * parseFloat(value) * model.product_unit_price).toFixed(2);
        // additional.product_price_ha = (parseFloat(value) * model.product_unit_price).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.plants_parcel = 0;
        additional.plants_m2 = 0;
        value = 0;
			}
    }

    if(name === 'plants_parcel') {
      if(!isNaN(parseFloat(value))) {
				additional.plants_ha = (parseFloat(value)/area).toFixed(2);
        additional.plants_m2 = (parseFloat((value/area) / 10000)).toFixed(4);
        // additional.product_price_parcel = (parseFloat(value) * model.product_unit_price).toFixed(2)
        // additional.product_price_ha = ((parseFloat(value)/area) * model.product_unit_price).toFixed(2)
        value = Number(value).toString();
			} else {
				additional.plants_ha = 0;
        additional.plants_m2 = 0;

        value = 0;
			}
    }

    // if(name === 'product_unit_price') {
    //   if(!isNaN(parseFloat(value))) {
    //     additional.product_price_parcel = (parseFloat(value) * parseFloat(model.plants_parcel)).toFixed(2)
    //     additional.product_price_ha = (parseFloat(value) * parseFloat(model.plants_ha)).toFixed(2);
    //     value = Number(value).toString();
    //   } else {
    //     value = 0;
    //     additional.product_price_parcel = 0;
    //     additional.product_price_ha = 0;
    //   }
    // }

    if(name === 'product_price_ha') {
      if(!isNaN(parseFloat(value))) {
        additional.product_price_parcel = (parseFloat(value) * area).toFixed(2)
        additional.product_unit_price = (parseFloat(value) / parseFloat(model.plants_ha)).toFixed(5);
        value = Number(value).toString();
      } else {
        value = 0;
        additional.product_price_parcel = 0;
        additional.product_unit_price = 0;
      }
    }

    if(name === 'product_price_parcel') {
      if(!isNaN(parseFloat(value))) {
        additional.product_price_ha = (parseFloat(value)/area).toFixed(2)
        additional.product_unit_price = (parseFloat(value) / parseFloat(model.plants_parcel)).toFixed(5);
        value = Number(value).toString();
      } else {
        value = 0;
        additional.product_price_ha = 0;
        additional.product_unit_price = 0;
      }
    }


		if(name === 'price_ha') {
			if(!isNaN(parseFloat(value))) {
				additional.price_parcel = (area * parseFloat(value)).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_parcel = 0;
        value = 0
			}
		}

		if(name === 'price_parcel') {
			if(!isNaN(parseFloat(value))) {
				additional.price_ha = (parseFloat(value)/area).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_ha = 0;
        value = 0;
			}
		}


    return {value, additional}

  }

  getFertigationPrices(name, value, model, area) {
    let additional = {};
    
    if(name === 'quantity_ha') {
      if(!isNaN(parseFloat(value))) {
				additional.quantity_parcel = (area * parseFloat(value)).toFixed(2);
        additional.product_price_parcel = (area * parseFloat(value) * model.product_unit_price).toFixed(2);
        additional.product_price_ha = (parseFloat(value) * model.product_unit_price).toFixed(2);
        value = Number(value).toString();
			} else {
				additional.quantity_parcel = 0;
        value = 0;
			}
    }

    if(name === 'quantity_parcel') {
      if(!isNaN(parseFloat(value))) {
				additional.quantity_ha = (parseFloat(value)/area).toFixed(2);
        additional.product_price_parcel = (parseFloat(value) * model.product_unit_price).toFixed(2);
        additional.product_price_ha = ((parseFloat(value)/area) * model.product_unit_price).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.quantity_ha = 0;
        value = 0;
			}
    }

    if(name === 'product_unit_price') {
      if(!isNaN(parseFloat(value))) {
        additional.product_price_parcel = (parseFloat(value) * parseFloat(model.quantity_parcel)).toFixed(2)
        additional.product_price_ha = (parseFloat(value) * parseFloat(model.quantity_ha)).toFixed(2);
        value = Number(value).toString()
      } else {
        value = 0;
        additional.product_price_parcel = 0;
        additional.product_price_ha = 0;
      }
    }


		if(name === 'price_ha') {
			if(!isNaN(parseFloat(value))) {
				additional.price_parcel = (area * parseFloat(value)).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_parcel = 0;
        value = 0
			}
		}

		if(name === 'price_parcel') {
			if(!isNaN(parseFloat(value))) {
				additional.price_ha = (parseFloat(value)/area).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_ha = 0;
        value = 0;
			}
		}


    return {value, additional}
  }


  getHarvestingPrices(name, value, area) {
    let additional = {};

    if(['moisture', 'impurities', 'hectolitre_mass', 'digestion', 'oil_content', 'protein_content'].indexOf(name) > -1) {
      if(parseFloat(value) < -1) value = 0;

      else if(parseFloat(value) > 100) value = 100;

      else if(isNaN(parseFloat(value))) value = 0;

      else {
        value = Number(value).toString()
      }
    }


    if(name === 'yield_ha') {
			if(!isNaN(parseFloat(value))) {
				additional.yield_parcel = (area * parseFloat(value)).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.yield_parcel = 0;
        value = 0
			}
		}

		if(name === 'yield_parcel') {
			if(!isNaN(parseFloat(value))) {
				additional.yield_ha = (parseFloat(value)/area).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.yield_ha = 0;
        value = 0;
			}
		}

    if(name === 'price_ha') {
			if(!isNaN(parseFloat(value))) {
				additional.price_parcel = (area * parseFloat(value)).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_parcel = 0;
        value = 0
			}
		}
    

		if(name === 'price_parcel') {
			if(!isNaN(parseFloat(value))) {
				additional.price_ha = (parseFloat(value)/area).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_ha = 0;
        value = 0;
			}
		}

    return {value, additional}
  }

  getPlantProtectionPrices(name, value, model, area) {
    let additional = {};

    if(name === 'irrigation_norm') {
      if(!isNaN(parseFloat(value))) {
        value = Number(value).toString()
      } else {
        value = '';
      }
    }

    if(name === 'quantity_ha') {
      if(!isNaN(parseFloat(value))) {
				additional.quantity_parcel = (area * parseFloat(value)).toFixed(2);
        additional.product_price_parcel = (area * parseFloat(value) * model.product_unit_price).toFixed(2);
        additional.product_price_ha = (parseFloat(value) * model.product_unit_price).toFixed(2);
        value = Number(value).toString();
			} else {
				additional.quantity_parcel = 0;
        value = 0;
			}
    }

    if(name === 'quantity_parcel') {
      if(!isNaN(parseFloat(value))) {
				additional.quantity_ha = (parseFloat(value)/area).toFixed(2);
        additional.product_price_parcel = (parseFloat(value) * model.product_unit_price).toFixed(2);
        additional.product_price_ha = ((parseFloat(value)/area) * model.product_unit_price).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.quantity_ha = 0;
        value = 0;
			}
    }

    if(name === 'product_unit_price') {
      if(!isNaN(parseFloat(value))) {
        additional.product_price_parcel = (parseFloat(value) * parseFloat(model.quantity_parcel)).toFixed(2)
        additional.product_price_ha = (parseFloat(value) * parseFloat(model.quantity_ha)).toFixed(2);
        value = Number(value).toString()
      } else {
        value = 0;
        additional.product_price_parcel = 0;
        additional.product_price_ha = 0;
      }
    }

    if(name === 'price_ha') {
			if(!isNaN(parseFloat(value))) {
				additional.price_parcel = (area * parseFloat(value)).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_parcel = 0;
        value = 0
			}
		}

		if(name === 'price_parcel') {
			if(!isNaN(parseFloat(value))) {
				additional.price_ha = (parseFloat(value)/area).toFixed(2);
        value = Number(value).toString()
			} else {
				additional.price_ha = 0;
        value = 0;
			}
		}

    return {value, additional}
  }
  
}

export default new $activities()