import { Fab, FormControl, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { AppContext } from "../../../AppContext";
import { formatData, formatOptions } from "../../../config/charts/gdd";
import { Refresh } from "@mui/icons-material";
import $data from "../../../services/$data";
import moment from "moment";
import useMergeState from "../../hooks/useMergeState";

function Gdd(props) {
  const chartRef = useRef(null);
  const {lang, cropTypes} = useContext(AppContext);

  const renderContent = () => {    
    return (
      <Chart
        ref={chartRef}
        type="line"
        data={formatData(props.data, lang)}
        options={formatOptions()} />
    )
  }
  
  return useMemo(() => (
    <Stack sx={{height: '150px'}}>
      <Tooltip placement="left" title={lang.reset_chart_view}><Fab style={{position: 'absolute', top: '50px', right: '5px'}} size="small" onClick={() => {chartRef.current.resetZoom();}}><Refresh fontSize="small" /></Fab></Tooltip> 
      {/* {renderFilter()} */}
		  {renderContent()}	
		</Stack>
  ), [props.data])
}

export default Gdd;