import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function JobOrderTillage({job}) {
  const {lang} = useContext(AppContext);
  if(!job) {
    return null
  }

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} elevation={2}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{lang.tillage_type}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{job.tillage_type_name || ' - '}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{lang.depth}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{job.depth ? job.depth + ' cm' : ' - '}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default JobOrderTillage;