import { ArrowRight, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useContext, useReducer } from "react";
import URL from "../../config/urls";
import Logo from "../../Logo";
import $auth from "../../services/$auth";

function RedirectToAC(props) {
  $auth.unauthorize();

  return (
    <Stack style={{height: '80%'}} direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{'& .loader-item': {mb: '10px'}}}>
      <Logo className="loader-item" style={{maxWidth: 300, maxHeight: 150}} />
      {/* <CircularProgress className="loader-item" size={'60px'} /> */}
      <h3 className="loader-item" style={{padding: '10px',color: '#548974'}}>{props.lang.redirect_ac}</h3>
      <a  onClick={() => {
        window.open(URL.AGRICAPTURE)
        window.location.reload();

      }} href={URL.AGRICAPTURE} style={{textDecoration: 'none'}} target="_blank">
        <Button color="primary" variant="contained" startIcon={<KeyboardArrowRight />}>CO2Agri</Button>
      </a>

    </Stack>
  )
}

export default RedirectToAC;