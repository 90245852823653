import { Chip, Switch, Container, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, Typography, colors, TextField, InputAdornment, Button, Tooltip, Autocomplete } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { AppContext } from "../../AppContext";
import { AttachFile, FilePresent, FileOpen, Close, DocumentScanner, Event, Folder, UploadFile } from "@mui/icons-material";
import TillageForm from './forms/TillageForm';
import { useContext, useEffect, useRef, useState } from "react";
import Divider from "../auth/Divider";
import FertigationForm from "./forms/FertigationForm";
import PlantingForm from "./forms/PlantingForm";
import HarvestingForm from "./forms/HarvestingForm";
import NoteForm from "./forms/NoteForm";
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";
import moment from "moment";
import { LocalizationProvider, MobileDatePicker, PickersDay } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import PlantProtectionForm from "./forms/PlantProtectionForm";
import AttachDocuments from "./views/AttachDocuments";
import _ from "lodash";
import AgrotechnicalForm from "./forms/AgrotechnicalForm";
import GrazingForm from "./forms/GrazingForm";


function CreateActivity(props) {
  const {lang, locale, primary, secondary, secondaryDark, activityTypes} = useContext(AppContext);
  const filesRef = useRef([]);
	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [activity, setActivity] = useState('');

  const [status, setStatus] = useState(false);

	const [job, setJob] = useMergeState({jo_from: moment().add(1, 'days').toISOString(), jo_to: moment().add(1, 'days').toISOString(), sms: false})

  const [isJob, setIsJob] = useState(false);

  const [state, setState] = useMergeState({error: {}, loading: false});

  const [files, setFiles] = useState([]);

  const [fileAttrs, setFileAttrs] = useMergeState({});

  const [attach, setAttach] = useState(false);

  const onChangeDate = (name, date) => {
    if(name === 'jo_from') {
      if(date.diff(moment(job.jo_to), 'days') > 0) {
        setJob({jo_from: date.toISOString(), jo_to: date.toISOString()})
      } else {
        setJob({jo_from: date.toISOString()})
      }
    }

    if(name === 'jo_to') {
      if(date.diff(moment(job.jo_from), 'days') < 0) {
        setJob({jo_from: date.toISOString(), jo_to: date.toISOString()})
      } else {
        setJob({jo_to: date.toISOString()})
      }
    }
  }

  const onFilesChange = (evt) => {
    // setFiles(evt.target.files);
    setFiles(Array.from(filesRef.current.files));
    let attrs = {};
    Array.from(filesRef.current.files).map((obj, key) => {
      attrs[key] = {
        name: obj.name,
        description: '',
        tags: []
      }
    })
    
    setFileAttrs(JSON.parse(JSON.stringify(attrs)))
  }

  const onFileAttributeChange = (key, name, value, opt_remove) => {
    setFileAttrs({[key]: {...fileAttrs[key], [name]: value}})
  }


  const getActivityForm = (type, props = {}) => {
    switch (type) {
      case 1: return <TillageForm onSubmit={createActivity} {...props} /> ;
      case 2: return <FertigationForm onSubmit={createActivity} {...props} />;
      case 3: return <PlantingForm onSubmit={createActivity} {...props} />;;
      case 4: return <PlantProtectionForm onSubmit={createActivity} {...props}/>;
      case 5: return <HarvestingForm onSubmit={createActivity} {...props} />;
      case 6: return <NoteForm onSubmit={createActivity} {...props} />;
      case 7: return <AgrotechnicalForm onSubmit={createActivity} {...props} />;
      case 8: return <GrazingForm onSubmit={createActivity} {...props} />;
      default: return (
        <Grid item xs={12}>
          <Typography style={{marginBottom: '20px', color: primary}} variant="h6">{lang.act_select_type}</Typography>
        </Grid>
      )
    }
  }

  const createActivity = (model) => {
    setState({loading: true})

    if(isJob && activity !== 6) {
      let formated = {
				...job,
				job_order: true,
				date: moment(job.jo_from).format('YYYY-MM-DD'),
				operators: model.operators
			}
      $data.createActivity(Object.assign({}, {...model, activity_status: status, upload_document: files.map((f,key) => {
        f.name_field = fileAttrs[key].name;
        f.description = fileAttrs[key].description;
        f.tags = fileAttrs[key].tags.length > 0 ? fileAttrs[key].tags.join('; ') : null;
        return f
      })}, formated))
        .then(data => {
          setState({loading: false, error: {}});
          props.onClose();
          props.onRefresh()
        })
        .catch(err => {
          setState({loading: false, error: err})
        });
    } else {

      $data.createActivity({...model, activity_status: status, upload_document: files.map((f,key) => {
        f.name_field = fileAttrs[key].name;
        f.description = fileAttrs[key].description;
        f.tags = fileAttrs[key].tags.length > 0 ? fileAttrs[key].tags.join('; ') : null
        return f
      })})
        .then(data => {
          setState({loading: false, error: {}});
          props.onClose();
          props.onRefresh()
        })
        .catch(err => {
          setState({loading: false, error: err})
        })
    }
    
  }

  useEffect(() => {
    if(!props.open) {
      setFiles([]);
      setFileAttrs(null);
      setState({error:{}, loading: false});
      setActivity('');
      setIsJob(false);
      setJob({jo_from: moment().add(1, 'days').toISOString(), jo_to: moment().add(1, 'days').toISOString(), sms: false})
    }
  }, [props.open]);

  useEffect(() => {
    setFiles([]);
    setFileAttrs(null);
    setState({error:{}, loading: false})
  }, [activity]);

  return (
    <Dialog fullWidth maxWidth={activity === 4 ? "xl" : "md"} open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.act_add_activity}
        
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl size="small" fullWidth>
                <InputLabel id="machine">{lang.activity_type}</InputLabel>
                <Select size="small" labelId="machine" label={lang.activity_type} value={activity} onChange={(evt) => setActivity(evt.target.value)} fullWidth>
                  {activityTypes.map((obj, key) => <MenuItem key={key} value={obj.id}>{obj.name}</MenuItem>)}
                </Select>
              </FormControl>
            
            </Grid>
            {activity !== '' && !isJob && <Grid item xs={12} sm={4}>
              <FormGroup>
                <FormControlLabel style={{margin: 0, justifyContent: 'flex-end'}} control={<Switch checked={status} onChange={() => setStatus(!status)} />} label={status ? lang.act_job_completed : lang.act_job_active}/>
              </FormGroup>
            </Grid>}
            {isJob && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>}
            {activity !== 6 && activity !== '' && <Grid item xs={12} sm={4}>
              <FormGroup>
                <FormControlLabel style={{margin: 0, justifyContent: 'flex-end'}} control={<Switch disabled={status} checked={isJob} onChange={(evt) => {setIsJob(evt.target.checked); if(evt.target.checked) {setStatus(false)}}} />} label={lang.act_add_job_order}/>
              </FormGroup>
            </Grid>}
            {isJob && activity !== 6 && <Grid item xs={12}>
              <Typography variant="caption" color={colors.orange[700]}>{lang.act_job_order_switch}</Typography>
            </Grid>}
            
            {isJob && activity !== 6 && activity !== '' &&	<Grid item xs={12} sm={4}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={moment().add(1, 'days')} onClose={() => {setJob({jo_from: job.jo_from})}} inputFormat="DD-MMM-YYYY" DialogProps={{className: 'datepicker-job-from'}} label={lang.from} value={moment(job.jo_from)} onChange={(newValue) => onChangeDate('jo_from', newValue)}
									renderDay={(day, selected, props) => {
  
                    return (
                      <PickersDay day={day} {...props} />
                    )
                  }}
                  renderInput={(params) => (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
							</LocalizationProvider>	
						</Grid>}
						{isJob &&  activity !== 6 && activity !== '' &&	<Grid item xs={12} sm={4}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={moment().add(1, 'days')}  onClose={() => {setJob({jo_to: job.jo_to})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(job.jo_to)} onChange={(newValue) => onChangeDate('jo_to', newValue)}
									renderInput={(params) => (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
							</LocalizationProvider>	
						</Grid>}
						{/* {isJob &&  activity !== 6 && activity !== '' &&	<Grid item xs={12} sm={4}>
							<FormGroup>
								<FormControlLabel style={{margin: 0, justifyContent: 'flex-start'}} control={<Switch checked={job.sms} onChange={(evt) => setJob({sms: evt.target.checked})} />} label={lang.send_sms}/>
							</FormGroup>
						</Grid>} */}
           {activity !== '' && <Grid item xs={12}>
              <Stack sx={{mb: '15px'}} spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
                {/* <Button size="small" variant="contained" onClick={() => setAttach(true)}> */}
                <Button size="small" variant="contained" component="label">
                  <AttachFile /> &nbsp; {lang.select_documents}
                  <input ref={filesRef} onChange={onFilesChange} value={""} multiple type="file" hidden />
                </Button>
                {/* </Button> */}
                {files.map((obj, key) => <Tooltip key={key} title={obj.name} placement="top" ><FilePresent fontSize="large" color="primary" /></Tooltip>)}
              </Stack>

              
                {files.map((obj, key) => {
                  return (
                    <Grid key={key} container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TextField value={fileAttrs[key].name} size="small" onChange={(evt) => onFileAttributeChange(key, 'name', evt.target.value)} fullWidth label={lang.document_name} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField size="small" value={fileAttrs[key].description} fullWidth onChange={(evt) => onFileAttributeChange(key, 'description', evt.target.value)} label={lang.document_description} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete multiple size="small" fullWidth
                          freeSolo
                          defaultValue={[]}
                          value={fileAttrs[key]['tags']}
                          onChange={(evt, val, reason) => {if(reason === 'createOption' || reason === 'removeOption') {onFileAttributeChange(key, 'tags', val, reason === 'removeOption')}}}
                          renderTags={(value, getTagProps) => value.map((option, index) => <Chip size="small" variant="filled" color="warning" label={option} {...getTagProps({index})}/>)}
                          renderInput={(params) => (
                             <TextField {...params} helperText={lang.act_document_tag_helper}size="small" fullWidth label={lang.document_tags}/>
                          )}
                          options={[]} />
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>}
            <Grid item xs={12}>
              <Divider style={{width: '100%'}} />
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}> */}
 
            {getActivityForm(activity, {parcel: props.parcel, onClose: props.onClose, error: state.error, loading: state.loading, isJob: isJob && activity !== 6, closeErrorMessage: () => setState({error: {...state.error, non_field_errors: null}})})}
          {/* </Grid> */}
          {/* <AttachDocuments open={attach} setFiles={setFiles} files={files} onClose={() => setAttach(false)} title="Attach document(s)" /> */}
        </Container>
      </DialogContent>
    </Dialog>
  )
   
}

export default CreateActivity;