import { Calculate, Close } from "@mui/icons-material";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import $activities from "../../../services/$activities";
import useMergeState from "../../hooks/useMergeState";
import InputPerPackage from "../helper/InputPerPackage";

function PlantProtectionModal(props) {
	const { lang, plantProtectionProducts, plantProtectionTypes, plantProtectionSubstances } = useContext(AppContext);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [state, setState] = useMergeState({
		protection_type: plantProtectionTypes[1].id,
		product: '',
		active_substance: '',
		quantity_ha: 0,
		quantity_parcel: 0,
		quantity_unit: 1,
		product_unit_price: 0,
		product_price_ha: 0,
		product_price_parcel: 0,
		natural: 'undefined',
	});

	const [packageModal, setPackageModal] = useState(false)

	const onChange = (name, val) => {
		let { value, additional } = $activities.getPlantProtectionPrices(name, val, state, props.parcel.area);
		setState({ [name]: value, ...additional });
	}

	const updateFromPackages = (fieldCost, quantityParcel, unit, unitPrice) => {
		setState({
			...state,
			quantity_parcel: quantityParcel,
			quantity_ha: (quantityParcel / props.parcel.area).toFixed(2),
			quantity_unit: unit,
			product_price_parcel: fieldCost,
			product_price_ha: (fieldCost / props.parcel.area).toFixed(2),
			product_unit_price: unitPrice
		})
	}

	return (
		<Dialog fullWidth maxWidth={"md"} open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
			<DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.add_record}

				<Close onClick={props.onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
			<DialogContent>
				<Grid container spacing={2} sx={{ mt: '20px' }}>
					<Grid item xs={12} sm={6} md={4}>
						<FormControl size="small" fullWidth>
							<InputLabel id="protection_type">{lang.protection_type}</InputLabel>
							<Select size="small" labelId="protection_type" label={lang.protection_type} value={state.protection_type} onChange={(evt) => onChange('protection_type', evt.target.value)} fullWidth>
								{plantProtectionTypes.filter(obj => obj.id !== 1).map((obj, key) => <MenuItem value={obj.id} key={key}>{obj.name}</MenuItem>)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Autocomplete size="small" value={state.product}
							getOptionLabel={(option) => { return option || '' }}
							onChange={(evt, value) => { onChange('product', value) }}
							onInputChange={(evt, value) => { onChange('product', value) }}
							freeSolo id="products"
							disableClearable
							ListboxProps={{ id: 'product_suggestion' }}
							options={plantProtectionProducts.filter(obj => obj.plant_protection_type.toString() === state.protection_type.toString()).map((opt, key) => opt.name)}
							renderInput={(params) => (<TextField label={lang.product} {...params} InputProps={{ ...params.InputProps, type: 'search', }} />)} />

					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Autocomplete size="small" value={state.active_substance}
							getOptionLabel={(option) => { return option || '' }}
							onChange={(evt, value) => { onChange('active_substance', value) }}
							onInputChange={(evt, value) => { onChange('active_substance', value) }}
							freeSolo id="substances"
							disableClearable
							ListboxProps={{ id: 'substance_suggestions' }}
							options={plantProtectionSubstances.map((opt, key) => opt.name)}
							renderInput={(params) => (<TextField label={lang.active_substance} {...params} InputProps={{ ...params.InputProps, type: 'search', }} />)} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button onClick={() => setPackageModal(true)} size="small" variant="outlined" startIcon={<Calculate />}>{lang.input_per_package}</Button>
					</Grid>
					<Grid item xs={12} sm={4}>
						<FormControl size="small" fullWidth>
							<InputLabel id="natural">{lang.natural_type}</InputLabel>
							<Select size="small" labelId="unit_type" label={lang.natural_type} value={state.natural} onChange={(evt) => onChange('natural', evt.target.value)} fullWidth>
								<MenuItem value={'undefined'}>{lang.unknown}</MenuItem>
								<MenuItem value={true}>{lang.natural}</MenuItem>
								<MenuItem value={false}>{lang.syntethic}</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={5} md={5}>
						<TextField type="number" label={lang.quantity_ha} fullWidth onChange={(evt) => onChange('quantity_ha', evt.target.value)} size="small" value={state.quantity_ha} />
					</Grid>
					<Grid item xs={12} sm={5} md={5}>
						<TextField type="number" label={lang.quantity_parcel} fullWidth onChange={(evt) => onChange('quantity_parcel', evt.target.value)} size="small" value={state.quantity_parcel} />
					</Grid>
					<Grid item xs={12} sm={2} md={2}>
						<FormControl size="small" fullWidth>
							<InputLabel id="unit_type">{lang.quantity_unit}</InputLabel>
							<Select size="small" labelId="unit_type" label={lang.quantity_unit} value={state.quantity_unit} onChange={(evt) => onChange('quantity_unit', evt.target.value)} fullWidth>
								<MenuItem value={1}>{'kg'}</MenuItem>
								<MenuItem value={2}>{'l'}</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<TextField type="number" label={lang.product_unit_price} fullWidth onChange={(evt) => onChange('product_unit_price', evt.target.value)} size="small" value={state.product_unit_price} />
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField sx={{ '& input': { background: 'rgba(0,0,0, 0.04)' }, '& input, & textarea': { cursor: 'not-allowed' } }} inputProps={{ readOnly: true }} focused={false} type="number" label={lang.product_price_ha} fullWidth onChange={(evt) => onChange('product_price_ha', evt.target.value)} size="small" value={state.product_price_ha} />
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField sx={{ '& input': { background: 'rgba(0,0,0, 0.04)' }, '& input, & textarea': { cursor: 'not-allowed' } }} inputProps={{ readOnly: true }} focused={false} type="number" label={lang.product_price_parcel} fullWidth onChange={(evt) => onChange('product_price_parcel', evt.target.value)} size="small" value={state.product_price_parcel} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button size="small" variant="contained" color="error" onClick={() => { props.onClose() }}>{lang.cancel}</Button>
				<Button size="small" variant="contained" color="primary" onClick={() => { props.addRecord(state); props.onClose() }}>{lang.confirm}</Button>
			</DialogActions>


			{packageModal && <InputPerPackage onUpdateModel={updateFromPackages} area={props.parcel.area} open={packageModal} onClose={() => setPackageModal(false)} />}

		</Dialog>
	)
}

export default PlantProtectionModal;
