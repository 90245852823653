import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import AppContextProvider from './AppContext';
import { getTranslation } from './config/locale/translation';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ForgotPassword from './components/auth/ForgotPassword';

import './styles/main.scss';
import Navigation from './components/navigation/Navigation';
import Content from './components/content/Content';
import Header from './components/content/Header';
import MapView from './components/map/MapView';
import Vrt from './components/vrt/Vrt';
import FieldBook from './components/fieldbook/FieldBook';
import Help from './components/navigation/Help';
import { CircularProgress, Stack } from '@mui/material';
import Logo from './Logo';
import Profile from './components/profile/Profile';
import $data from './services/$data';
import getInitialState from './config/initialStates';
import axios from 'axios';
import EmailVerification from './components/auth/EmailVerification';
import NewPassword from './components/auth/NewPassword';
import colors from './config/colors';
import Activities from './components/fieldbook/Activities';
import { defaults } from './config/map';
import $dates from './services/$dates';
import $auth from './services/$auth';
import $cookies from './services/$cookies';
import MainError from './components/ui/error/MainError';
import RedirectToAC from './components/ui/RedirectToAC';
import JobOrders from './components/job-orders/JobOrders';
import JobOrderItem from './components/job-orders/JobOrderItem';
import TourGuide from './components/ui/tour-guide/TourGuide';
import PricingPlan from './components/auth/PricingPlan';
import PrivacyPolicy from './components/auth/PrivacyPolicy';
import PrivacyPolicyENRoute from './components/PrivacyPolicyENRoute';
import Terms from './components/auth/Terms';
import AgricaptureRequests from './components/ac-requests/AgricaptureRequests';
import Alerts from './components/alerts/Alerts';
import Sensors from './components/sensors/Sensors';
import moment from 'moment';
import PrivacyPolicyEN from './components/auth/PrivacyPolicyEN';
import TermsEN from './components/auth/TermsEN';
import PrivacyPolicyRoute from './components/PrivacyPolicyRoute';
import TermsAndConditionsRoute from './components/TermsAndConditionsRoute';
import TermsAndConditionsENRoute from './components/TermsAndConditionsENRoute';
import DeleteAccount from './components/auth/DeleteAccount';
import DeleteVerification from './components/auth/DeleteVerification';

const theme = createTheme({
  palette: {
    primary: {
      main: '#548974'
    },
    secondary: {
      main: '#bbd560'
    },
  }
});

class App extends React.Component {
  static getDerivedStateFromError() {
    return {uiCrash: true}
  }

  state = {
    locale: this.props.locale,
    loggedIn: this.props.loggedIn,
    user: this.props.user,
    view: this.props.user?.isOperater ? 'job-orders' : 'map',
    pricingOpened: false,
    privacyPolicy: false,
    hasACRequests: false,
    acRequests: false,
    terms: false,
    datapanel: false,
    vrtParcels: true,
    tourGuide: false,
    action: null,
    ...defaults,
    sensorsData: [],
    samplesData: [],
    photosData: [],
    dates_planet: [],
    dates_s2: [],
    seasons: [],
    operators: [],
    machines: [],
    connectedMachines: [],
    activities: {jobs: []},
    season: null,
    parcels: $data.parcels,
    selectedParcel: null,
    loadingSensors: this.props.user?.pk === 349 ? true : false,
    loadingSamples: true,
    loadingPhotos: true,
    loadingCodeLists: true,
    loadingParcels: true,
    loadingDates_s2: true,
    loadingDates_planet: true,
    loadingOperators: true,
    loadingMachines: true,
    loadingConMachines: true,
    toUpdate: false,
    toUpdatePhotos: false,
    resendEmail: false,
    updateLoading: {
      codeLists: false,
      seasons: false,
      parcels: false,
      samples: false,
      photos: false,
      dates_s2: false,
      dates_planet: false,
      operators: false,
      machines: false,
      connectedMachines: false
    },
    ...getInitialState('codeLists')
  }



  updateState = (newState) => {
    let {source, date, dates_s2, dates_planet} = this.state;

    if(newState.source && source !== newState.source) {
      newState.date = $dates.getClosestDate(date, newState.source === 's2' ? dates_s2 : dates_planet);
    }

    if(newState.locale) {
      $cookies.set('locale', newState.locale)
      axios.defaults.headers.common['Accept-Language'] = newState.locale;
    }

    if(newState.action) {
      if(newState.action !== this.state.action) {
        newState.selectedParcel = null;
      }
    }

    this.setState(newState)
  };

  constructor(props) {
    super(props);
    this.timer = 1800;
  }

  updateCodeLists = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, codeLists: true}})
    $data.getCodeLists()
      .then(data => {
        this.updateState({...data, updateLoading: {...this.state.updateLoading, codeLists: false}})
      })
      .catch(err => {
        this.updateState({updateLoading: {...this.state.updateLoading, codeLists: false}})
      })
  }

  updateParcels = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, parcels: true}})
    axios.all([
      $data.getParcels(this.state.locale),
      $data.getSamples(),
      $data.getPhotos()
    ])
    .then(axios.spread((parcels, samples, photos) => {
      this.updateState({toUpdate: true, parcels, samplesData: samples, photosData: photos, toUpdatePhotos: true, updateLoading: {...this.state.updateLoading, parcels: false}})
    }))
    .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, parcels: false}}))
    // $data.getParcels(this.state.locale)
    //   .then(parcels => this.updateState({toUpdate: true, parcels, updateLoading: {...this.state.updateLoading, parcels: false}}))
    //   .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, parcels: false}}))
  }

  updateSensors = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, sensors: true}})
    $data.getSensors()
      .then(sensors => this.updateState({toUpdate: true, sensorsData: sensors, updateLoading: {...this.state.updateLoading, sensors: false}}))
      .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, sensors: false}}))
  }


  updateSamples = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, samples: true}})
    $data.getSamples()
      .then(sensors => this.updateState({toUpdate: true, samplesData: sensors, updateLoading: {...this.state.updateLoading, samples: false}}))
      .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, samples: false}}))
  }

  updatePhotos = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, photos: true}});
    console.log()
    $data.getPhotos()
      .then(photos => this.updateState({action: null, toUpdate: true, toUpdatePhotos: true, photosData: photos, updateLoading: {...this.state.updateLoading, photos: false}}))
      .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, photos: false}}))
  }


  updateOperators = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, operators: true}});
  
    $data.getOperators()
      .then(operators => this.updateState({operators,  updateLoading: {...this.state.updateLoading, operators: false}}))
      .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, operators: false}}));
  }

  updateMachines = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, machines: true}});
  
    $data.getMachines()
      .then(machines => this.updateState({machines,  updateLoading: {...this.state.updateLoading, machines: false}}))
      .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, machines: false}}));
  }

  updateConnectedMachines = () => {
    this.updateState({updateLoading: {...this.state.updateLoading, connectedMachines: true}});

    $data.getConnectedMachines()
      .then(connectedMachines => this.updateState({connectedMachines,  updateLoading: {...this.state.updateLoading, connectedMachines: false}}))
      .catch(err => this.updateState({updateLoading: {...this.state.updateLoading, connectedMachines: false}}));
  }

  setTimer = () => {
    if(this.interval) {clearInterval(this.interval)}
    this.timer = 1800;
    this.interval = setInterval(() => {
      this.timer -= 1;
      if(this.timer === 0) {
        $auth.refreshToken($cookies.get('refresh'))
          .then(token => {this.setTimer()})
          .catch(err => {window.location.reload()})
      }
    }, 1000)

    
  }

  componentDidMount() {
    if(this.state.loggedIn) {
      if(this.state.user.billing_plan !== 2 && !this.state.user.pricing_popup) {
 
        this.updateState({pricingOpened: true});
      }
      this.setTimer();
      document.addEventListener('visibilitychange', this.onVisibilityChange, false);
      this.getData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.loggedIn !== this.state.loggedIn) {
      if(this.state.loggedIn) {
        if(this.state.user.billing_plan !== 2 && !this.state.user.pricing_popup) {
          this.updateState({pricingOpened: true});
        }
        this.setTimer();
        document.addEventListener('visibilitychange', this.onVisibilityChange, false);
        this.getData();
      } else {
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
      }
    }
  }

  componentWillUnmount() { document.removeEventListener('visibilitychange', this.onVisibilityChange); }

  onVisibilityChange = () => {  
    $auth.refreshToken($cookies.get('refresh'))
      .then(token => {this.setTimer()})
      .catch(err => {window.location.reload()})
  }

  getData = () => {
    let {user} = this.state;

    if(user?.user_type !== 1) {
      return
    }

    if(user.isOperater) {
      this.setState({
        view: 'job-orders',
        loadingSensors: false,
        loadingParcels: false,
        loadingDates_s2: false,
        loadingDates_planet: false,
        loadingSamples: false,
        loadingPhotos: false,
        loadingActivities: true,
      })

      $data.getActivities({export_format: 'json'})
        .then(activities => this.updateState({activities, loadingActivities: false }))
        .catch(err => this.updateState({loadingActivities: false}));

      $data.getCodeLists()
        .then(data => this.updateState({...data, loadingCodeLists: false}))
        .catch(err => this.updateState({loadingCodeLists: false}));

      //GET OPERATORS/MACHINES/CONNECTED MACHINE
      $data.getOperators()
      .then(operators => this.updateState({operators, loadingOperators: false}))
      .catch(err => this.updateState({loadingOperators: false}));

      $data.getMachines()
        .then(machines => this.updateState({machines, loadingMachines: false}))
        .catch(err => this.updateState({loadingMachines: false}));

      $data.getConnectedMachines()
        .then(connectedMachines => this.updateState({connectedMachines, loadingConMachines: false}))
        .catch(err => this.updateState({loadingConMachines: false}));
      
      return
    }

    $data.getPhotos()
      .then(photosData => this.updateState({photosData, loadingPhotos: false}))
      .catch(err => this.updateState({loadingPhotos: false}))

    if(user?.pk === 349) {
      this.setState({loadingSensors: true});
      $data.getSensors()
        .then(data => this.updateState({loadingSensors: false, sensorsData: data}))
        .catch(err => this.updateState({loadingSensors: false}))
    }

    $data.getSamples()
      .then(samples => this.updateState({loadingSamples: false, samplesData: samples}))
      .catch(err => this.updateState({loadingSamples: false}))

    //GET CODE LISTS - CROP TYPES, FIELD BOOK ACTIVITIY TYPES AND ALL OPERATION SUBTYPES
    $data.getCodeLists()
      .then(data => this.updateState({...data, loadingCodeLists: false}))
      .catch(err => this.updateState({loadingCodeLists: false}));

    //GET ALL PARCELS
    $data.getParcels(this.state.locale)
      .then(parcels => {
        this.updateState({parcels, loadingParcels: false})
      })
      .catch(err => this.updateState({loadingParcels: false}))

    //GET OPERATORS/MACHINES/CONNECTED MACHINE
    $data.getOperators()
      .then(operators => this.updateState({operators, loadingOperators: false}))
      .catch(err => this.updateState({loadingOperators: false}));

    $data.getMachines()
      .then(machines => this.updateState({machines, loadingMachines: false}))
      .catch(err => this.updateState({loadingMachines: false}));

    $data.getConnectedMachines()
      .then(connectedMachines => this.updateState({connectedMachines, loadingConMachines: false}))
      .catch(err => this.updateState({loadingConMachines: false}));
  
    //GET SENTINEL DATES AND PLANET DATES (IF USER IS ALLOWED) 
    $data.getDatesS2(user.pk)
      .then(dates => {
        let all_years = [...new Set(dates.map(d => moment(d).format('YYYY')))];
        all_years.pop()
        // let years = all_years.filter((y, i) => i !== all_years.length - 1);
        this.updateState({dates_s2: dates, years: all_years, year: all_years[all_years.length - 1], date: moment().format('YYYY-MM-DD'), loadingDates_s2: false})
      })
      .catch(err => this.updateState({loadingDates_s2: false}))
      

    if(user.planet) {
      $data.getDatesPlanet(user.pk)
        .then(dates => this.updateState({dates_planet: dates, user: {...user, planet: dates.length > 0}, loadingDates_planet: false}))
        .catch(err => this.updateState({loadingDates_planet: false, user: {...user, planet: false}}));
    }
    
  }

  userCheck = () => {
    if(this.state?.user?.isOperater) {
      return 'job-orders'
    }
    return 'map'
  }

  loadingCheck = () => {
    let {loadingCodeLists, loadingDates_planet, loadingDates_s2, loadingParcels, loadingSensors, loadingSamples, loadingSeasons, loadingOperators} = this.state;
    if(this.state?.user?.planet) {
      return Object.values({loadingCodeLists, loadingDates_planet, loadingDates_s2, loadingParcels, loadingSeasons, loadingSensors, loadingSamples, loadingOperators}).indexOf(true) > -1
    }

    return Object.values({loadingCodeLists, loadingDates_s2, loadingParcels, loadingSeasons, loadingSensors, loadingSamples, loadingOperators}).indexOf(true) > -1
  }

  authCheck = (target, Fallback, props = {}) => {
    if(this.state.loggedIn) return <Redirect to={target} replace />
    
    if(Fallback.constructor === String) return <Redirect to={Fallback} replace />

    return <Fallback {...props} />
  }

  renderLoader = () => {
    return (
      <Stack style={{height: '80%'}} direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{'& .loader-item': {mb: '10px'}}}>
        <Logo className="loader-item" style={{maxWidth: 300, maxHeight: 150}} />
        <CircularProgress className="loader-item" size={'60px'} />
        <h3 className="loader-item" style={{color: '#548974', textTransform: 'uppercase'}}>{getTranslation(this.state.locale).loading}</h3>
      </Stack>
    )
  }

  render() {
    let {locale, user, view, loggedIn, updateLoading, activities, cropTypes, activityTypes, tillageTypes, fertigationTypes,
      fertigationProducts, cropGroups, plantingSeeds, plantProtectionTypes,
      plantProtectionProducts, plantProtectionSubstances, plantProtectionAgroTypes, source, parcels, sensorsData, samplesData, photosData, seasons, season, operators, machines, connectedMachines,
      selectedParcel, base, layer, opacity, rgb, sensors, samples, photos, date, dates_s2, years, year, dates_planet, geolocation, zoom, center, action, toUpdate, toUpdatePhotos, uiCrash, datapanel, vrtParcels, tourGuide, resendEmail, hasACRequests,
    } = this.state;
    
    const codeLists = {cropTypes, activityTypes, tillageTypes, fertigationTypes,
      fertigationProducts, cropGroups, plantingSeeds, plantProtectionTypes,
      plantProtectionProducts, plantProtectionSubstances, plantProtectionAgroTypes};

    if(uiCrash) {
      return (
        <MainError lang={getTranslation(this.state.locale)} />
      )
    }

    if(loggedIn && user?.user_type !== 1) {
      return <RedirectToAC lang={getTranslation(this.state.locale)} />
    }

    const filteredParcels = {...parcels, features: parcels.features.filter(f => Boolean(f.geometry))};

    return (
      <AppContextProvider value={{...codeLists, onUpdateCodeLists: this.updateCodeLists, onUpdateParcels: this.updateParcels, onUpdateSensors: this.updateSensors, onUpdateSamples: this.updateSamples, onUpdatePhotos: this.updatePhotos, onUpdateOperators: this.updateOperators, onUpdateMachines: this.updateMachines, onUpdateConnectedMachines: this.updateConnectedMachines, toUpdate: toUpdate, toUpdatePhotos, toUpdatePhotos,
         dates: source === 's2' ? dates_s2 : dates_planet , source, base, layer, opacity, rgb, samples, sensors, photos, date, colors,  geolocation,
         years, year,
         locale, user, view, activities, parcels, sensorsData, samplesData, photosData, season, seasons, operators, machines, connectedMachines, zoom, center, action, datapanel, vrtParcels, tourGuide, resendEmail, hasACRequests,
         primary: '#548974', secondary: '#bbd560', secondaryDark: '#8ca530', danger: '#D32F2F', dangerLight: '#ff777c', warningLight: '#FFC000	', warning: '#FB9801', successColor: 'green', lang: getTranslation(locale), onUpdateState: this.updateState}}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>

						  <Route exact path="/"	render={() => this.authCheck(`/dashboard/${this.userCheck(user)}`, '/login') } />

              <Route exact path="/login" render={(props) => {return this.authCheck(`/dashboard/${this.userCheck(user)}`, Login, {code: props.location.search.split('?code=')[1]})}} />

              <Route exact path="/register" render={() => this.authCheck(`/dashboard/${this.userCheck(user)}`, Register)} />

              <Route exact path="/forgot-password" render={() => this.authCheck(`/dashboard/${this.userCheck(user)}`, ForgotPassword)} />
              
              <Route exact path="/email-verification/:id" render={(props) => this.authCheck(`/dashboard/${this.userCheck(user)}`, EmailVerification, {id: props.match.params.id})} />
              
              <Route exact path="/reset-password/:uid/:token" render={(props) => this.authCheck(`/dashboard/${this.userCheck(user)}`, NewPassword, {uid: props.match.params.uid, token: props.match.params.token})} />

              <Route exact path="/privacy-policy-en" render={(props) => { return <PrivacyPolicyENRoute /> }} />

              <Route exact path="/terms-and-conditions-en" render={(props) => { return <TermsAndConditionsENRoute /> }} />

              <Route exact path="/privacy-policy-sr" render={(props) => { return <PrivacyPolicyRoute /> }} />

              <Route exact path="/delete-account" render={() => this.authCheck(`/dashboard/${this.userCheck(user)}`, DeleteAccount)} />

              <Route exact path="/delete-verification/:id" render={(props) => this.authCheck(`/dashboard/${this.userCheck(user)}`, DeleteVerification, {id: props.match.params.id})} />

<Route exact path="/terms-and-conditions-sr" render={(props) => { return <TermsAndConditionsRoute /> }} />

              <Route path="/dashboard" render={() => {
                if(!loggedIn) return <Redirect to="/login" />
                else if(window.location.pathname === '/dashboard' || window.location.pathname === '/dashboard/') {
                  return <Redirect to={`/dashboard/${this.userCheck()}`}/>;
                } else {
                  if(this.loadingCheck()) return this.renderLoader();
                  else {
                   return (
                    <div className="main-container">
                      {/* NAVIGATION */}
                      <Navigation />

                      {/* CONTENT */}
                      <Content>
                        
                        {/* HEADER */}
                        <Header loader={updateLoading} />

                        {/* ROUTE */}
                        <div className="routes-container">
                          {!user.isOperater && <Route exact path="/dashboard/map" render={(props) => {
                            return <MapView season={season} parcels={filteredParcels} zoomToParcel={selectedParcel} />
                          }}/>}

                          {!user.isOperater && <Route exact path="/dashboard/vrt" render={() => {
                            return <Vrt zoomToParcel={selectedParcel} parcels={filteredParcels} dates={this.state['dates_' + source]} />
                          }}/>}

                          {!user.isOperater && <Route exact path="/dashboard/field-book" render={() => {
                            return <FieldBook parcels={parcels}  />
                          }}/>}

                          {!user.isOperater && <Route exact path="/dashboard/field-book/:id" render={(props) => {
                            return <Activities id={props.match.params.id} />
                          }}/>}

                          <Route exact path="/dashboard/profile" render={() => {
                            return <Profile />
                          }}/>

                          {user.isOperater && <Route exact path="/dashboard/job-orders" render ={() => {
                            return <JobOrders />
                          }}/>}

                          {user.isOperater && <Route exact path="/dashboard/job-orders/:id" render ={(props) => {
                            return <JobOrderItem id={props.match.params.id} />
                          }}/>}

                          {/* <Route exact path="/dashboard/help" render={() => {
                            return <Help />
                          }}/> */}

                          <Route exact path="/dashboard/alerts" render={() => {
                            return <Alerts />
                          }}/>

                          <Route exact path="/dashboard/sensors" render={() => {
                            return <Sensors />
                          }}/>

                        </div>
                      </Content>
                      

                    


                     </div>
                   )

                  }
                }

              }}/>
                

              
              <Route render={() => this.authCheck(`/dashboard/${this.userCheck()}`, '/login')}/>

            </Switch>

            {!this.state.user?.is_add_user && this.state.loggedIn && !this.loadingCheck() && <PricingPlan parcels={this.state.parcels} open={this.state.pricingOpened} onClose={() => this.updateState({pricingOpened: false})} />}

          </Router>
          {/* {this.state.mounted && this.state.tourGuide && (view === 'map' || view === 'fieldbook' || view === 'vrt') &&<TourGuide view={this.state.view} active={this.state.tourGuide} onUpdateState={this.updateState} />}        */}
          {locale === 'sr' && <PrivacyPolicy open={this.state.privacyPolicy} onClose={() => this.setState({privacyPolicy: false})}/>}
          {locale === 'en' && <PrivacyPolicyEN open={this.state.privacyPolicy} onClose={() => this.setState({privacyPolicy: false})}/>}
          {locale === 'sr' && <Terms open={this.state.terms} onClose={() => this.setState({terms: false})}/>}
          {locale === 'en' && <TermsEN open={this.state.terms} onClose={() => this.setState({terms: false})}/>}
          {!this.state.user?.is_add_user && this.state.loggedIn && <AgricaptureRequests open={this.state.acRequests} onClose={() => this.setState({acRequests: false})}  />}

          {this.state.tourGuide && <Help open={this.state.tourGuide} onClose={() => this.setState({tourGuide: false})} />}
        </ThemeProvider>
      </AppContextProvider>
    );
  }
  
}

export default App;
