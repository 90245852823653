import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import $interceptor from './services/$interceptor';
import $cookies from './services/$cookies';
import $auth from './services/$auth';
import { checkLanguage } from './config/locale/translation';
import 'moment';
import 'moment/locale/sr';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';

$interceptor.init();

const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';

const lang = checkLanguage(getLanguage());

const locale = $cookies.get('locale') ? $cookies.get('locale') : lang;

axios.defaults.headers.common['Accept-Language'] = locale;

const render = (Component, loggedIn, locale, user) => {
  ReactDOM.render(
    <React.StrictMode>
      <GoogleOAuthProvider clientId='995249770524-pqvu8n66r174b43gb5b0jtptq3sirao6.apps.googleusercontent.com'>
        <Component loggedIn={loggedIn} locale={locale} user={user} />
      </GoogleOAuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

const auth = () => {
  if($cookies.get('refresh')) {
    $auth.refreshToken($cookies.get('refresh'))
      .then(refreshed => {
        $auth.getUser()
          .then(user => {
            render(App, true, user.language, user);
          })
          .catch(err => {
            $auth.unauthorize();
            render(App, false, locale);
          })
      })
      .catch(err => {
        render(App, false, locale);
      })
  } else {
    $auth.unauthorize();
    render(App, false, locale)
  }
}

auth();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
