import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Paper, Stack, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";
import $cookies from "../../services/$cookies";
import { Link } from "react-router-dom";
import acLogo from '../../images/agricapture-logo.webp';
import $data from "../../services/$data";
import $auth from "../../services/$auth";
import useMergeState from "../hooks/useMergeState";
import _ from "lodash";

function PricingPlan(props) {
  const {lang, parcels, user, onUpdateState} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);

  const [requests, setRequests] = useMergeState({data: [], loading: false, error: null, success: null});

  const [open, setOpen] = useState(false);

  const [firstTime, setFirstTime] = useState(true);


  useEffect(() => {
  let total = getTotalArea();

    // if(total > 100 && user.billing_plan !== 2 && !firstTime) {
    //   onUpdateState({pricingOpened: true})
    // }

  }, [props.parcels.features.length]);

  useEffect(() => {
    if(props.open) {
      setOpen(true)
   
    } else {
      setOpen(false)
    }
  }, [props.open])

  useEffect(() => {
    setRequests({loading: true, error: null, success: null})
    $data.getACRequests()
      .then(data => {
        setRequests({data, loading: false})
      })
      .catch(err => {
        setRequests({loading: false, error: 'Nesto se desilo'})
      })

  }, []);

  const onHandlePopupMessage = (evt, checked) => {
    $auth.updateProfile({...user, pricing_popup: checked})
      .then(data => {
        onUpdateState({ user: { ...user, pricing_popup: checked } });
      })
      .catch(err => {})

  }

  const getTotalArea = () => {
    let sum = 0;
    parcels.features.map(({properties}) => {
      sum += properties.area;
    });

    // return 120

    return sum.toFixed(2)
  }


  const renderDescription = () => {
    let description = '';
    let totalArea = getTotalArea();
    let difference = totalArea - 100;
  
    if(totalArea <= 100 && totalArea >= 0) {
      description = lang.billing_lt_100;
    }
  
    if(totalArea > 100) {
      description = lang.billing_gt_100;
    }

    return (
      <Grid sx={{textAlign: 'center'}} item xs={12}>
        <Typography variant="p" color={totalArea > 100 ? 'error' : 'primary'}><strong>{description}</strong></Typography>
        <Stack alignItems="center">
          <p style={{marginBottom: 0}}>{lang.billing_used_free}: <strong className="text-success">{totalArea < 100 ? totalArea : 100}ha</strong></p>
          {totalArea > 100 && <p style={{marginBottom: 0}}>{lang.billing_used_paid}: <strong className="text-danger">{difference.toFixed(2)}ha</strong></p>}
          {totalArea > 100 && <Link style={{marginTop: '20px', textDecoration: 'none'}} to="/dashboard/profile/?billing"><Button onClick={props.onClose} size="small" variant="contained" color="primary">{lang.billing_request_ask}</Button></Link>}
        </Stack>
      </Grid>
    )
  }


  const renderPricingPlan = () => {
    let exceeded = getTotalArea() - 100;
    return (
      <Grid container spacing={2} style={{marginTop: '20px', textAlign: 'center'}}>
        <Grid item xs={12} sm={6} md={3}></Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack component={Paper} sx={{padding: '10px', outline: exceeded <= 0 ? '3px solid #548974' : 'none'}} elevation={2}>
            <h3 style={{textAlign: 'center'}}>{lang.billing_free}</h3>
            <Stack justifyContent="center" alignItems="center">
              &le; 100ha
              <h2 className="text-primary">0€/ha</h2>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack component={Paper} sx={{padding: '10px', outline: exceeded > 0 && exceeded <= 1000  ? '3px solid #548974' : 'none'}} elevation={2}>
            <h3 style={{textAlign: 'center'}}>{lang.billing_basic}</h3>
            <Stack justifyContent="center" alignItems="center">
              &gt; 100ha
              <h2 className="text-primary">{lang.contact_us}</h2>
            </Stack>
          </Stack>
         
        </Grid>
        <Grid item xs={12} sm={6} md={3}></Grid>

        {/* <Grid item xs={12} sm={6} md={3}>
          <Stack component={Paper} sx={{padding: '10px', outline: exceeded > 1000 && exceeded <= 5000 ? '3px solid #548974' : 'none'}} elevation={2}>
            <h3 style={{textAlign: 'center'}}>{lang.billing_pro}</h3>
            <Stack justifyContent="center" alignItems="center">
              1000ha - 5000ha
              <h2 className="text-primary">4€/ha</h2>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack component={Paper} sx={{padding: '10px', outline: exceeded >= 5000 ? '3px solid #548974' : 'none'}} elevation={2}>
            <h3 style={{textAlign: 'center'}}>{lang.billing_enterprise}</h3>
            <Stack justifyContent="center" alignItems="center">
              &gt; 5000ha
              <h2 className="text-primary">3€/ha</h2>            
            </Stack>
          </Stack>
        </Grid> */}
      </Grid>
    )
  }

  const renderACPlan = () => {
    return (<Grid sx={{textAlign: 'center'}} item xs={12}>
      <Stack component={Paper} sx={{padding: '10px'}} elevation={3}>
        <img src={acLogo} style={{width: '100%'}} />
        <Stack justifyContent="center" alignItems="center">
          <div>
            {lang.billing_ac_description}
            <h2 className="text-success text-center">{getTotalArea()} ha ({lang.billing_free})</h2>
          </div>
        </Stack>
      </Stack>
    </Grid>)
  }



  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={props.onClose ? props.onClose : () => {}} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.welcome}<Close onClick={props.onClose ? props.onClose : () => {}} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          <Grid container spacing={2}>
          
            {user?.billing_plan !== 2  && renderDescription()}
            {user?.billing_plan !== 2  && renderPricingPlan()}
            {user?.billing_plan == 2  && renderACPlan()}
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <FormGroup>
          <FormControlLabel control={<Checkbox value={user?.pricing_popup} checked={user?.pricing_popup} onChange={onHandlePopupMessage} />} label={lang.dont_show_again} />
        </FormGroup>
        
        <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}


export default PricingPlan;