import { Button, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import moment from "moment";

function SummaryCropHistory(props) {
  const {lang, primary} = useContext(AppContext);

  if(!props.parcel) return null;

  if(props.parcel.crop_history.length === 0) {
    return (
      <Paper sx={{height: '100%'}} elevation={2}>
        <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.crop_history}</Typography>
          <table>
            <tbody>
              <tr>
                <td colSpan="3">{lang.no_crop_records}</td>
              </tr>
              <tr>
                <td colSpan="3"><Button onClick={props.openCropRecordModal} size="small">{lang.add_record}</Button></td>
              </tr>
            </tbody>
          </table>
      </Paper>
    )
  }

  let filtered = props.parcel.crop_history.filter((obj, key) => key <= 3);

  return (
    <Paper sx={{height: '100%',}} elevation={2}>
      <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.crop_history}</Typography>
      <table>
        {/* <thead>
          <tr>
            <th style={{textAlign: 'center', fontSize: '13px', color: primary}}>{lang.parcel_crop}</th>
            <th style={{textAlign: 'center', fontSize: '13px', color: primary}}>{lang.parcel_sowing}</th>
            <th style={{textAlign: 'center', fontSize: '13px', color: primary}}>{lang.parcel_harvest}</th>
          </tr>
        </thead> */}
        <tbody>
          {filtered.map((obj,key) => {
            return (
              <tr key={key}>
                <td style={{paddingBottom: '3px'}}>{obj.crop.name}</td>
                <td style={{paddingBottom: '3px'}}>{obj.sowing ? moment(obj.sowing).format('DD-MMM-YYYY') : ' - '}</td>
                <td>{obj.harvest ? moment(obj.harvest).format('DD-MMM-YYYY') : ' - '}</td>
              </tr>
            )
          })}
          {filtered.length !== props.parcel.crop_history.length && <tr><td colSpan={3} style={{textAlign:'center'}}>. . .</td></tr>}
          <tr>
            <td style={{textAlign: 'center'}}><Button onClick={props.openHistoryModal} size="small">{lang.full_list}</Button></td>
            <td></td>
            <td style={{textAlign: 'center'}}><Button onClick={props.openCropRecordModal} size="small">{lang.add_record}</Button></td>
          </tr>
          </tbody>
      </table>
    </Paper>
  )

}

export default SummaryCropHistory