import { LoadingButton } from "@mui/lab";
import { Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import useMergeState from "../hooks/useMergeState";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import Message from "../ui/Message";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import $auth from "../../services/$auth";
import countries from "../../config/countries";

function GoogleRegistrationForm(props) {
  const {lang, locale, onUpdateState} = useContext(AppContext);

  const [state, setState] = useMergeState({
    country: 1,
    currency: 'GBP',
    user_type: 1,
    language: locale
  });

  useEffect(() => {
    setState({
      country: props.auth?.user?.country,
      currency: props.auth?.user?.currency,
      user_type: 1,
      language: locale
    })
  }, [JSON.stringify(props.auth)])

  const [error, setError] = useMergeState({});
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setError({});
  }

  const updateProfile = () => {
    $auth.patchProfileGoogle({...state}, props.auth.access_token)
      .then(data => {onUpdateState({locale: data.language}); props.onSubmit(data)})
      .catch(err => {setError(err)})
  }

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.welcome}</DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">{props.question}</Typography>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">{lang.google_registration_form_text}</Typography>
						</Grid>
            <Grid item xs={12}>
              <Box autoComplete="off" style={{padding: '5px'}} component='form' sx={{'& .MuiTextField-root, & .MuiButton-root, & .MuiFormControl-root': {mb: '7px', mt: '7px' }}} onSubmit={updateProfile}>
        
                <div>
                  <FormControl size="small" error={error.language !== undefined || error.non_field_errors !== undefined} fullWidth>
                    <InputLabel id="language">{lang.language}</InputLabel>
                    <Select size="small" labelId="language" label={lang.language} value={state.language} onChange={(evt) => {setState({language: evt.target.value}); onUpdateState({locale: evt.target.value})}} fullWidth>
                      <MenuItem value="sr">{lang.serbian}</MenuItem>
                      <MenuItem value="en">{lang.english}</MenuItem>
                    </Select>
                    {error.language && <FormHelperText>{error.language}</FormHelperText>}
                  </FormControl>
                </div>

                <div>
                  <FormControl size="small" error={error.country !== undefined || error.non_field_errors !== undefined} fullWidth>
                    <InputLabel id="country">{lang.country}</InputLabel>
                    <Select size="small" labelId="country" label={lang.country} value={state.country} onChange={(evt) => setState({country: evt.target.value})} fullWidth>
                      {countries(lang).map((obj, key) => {
                        return <MenuItem key={key} value={obj.value}>{obj.label}</MenuItem>
                      })}
                      </Select>
                    {error.country && <FormHelperText>{error.country}</FormHelperText>}
                  </FormControl>
                </div>

                <div>
                  <FormControl size="small" error={error.currency !== undefined || error.non_field_errors !== undefined} fullWidth>
                    <InputLabel id="currency">{lang.currency}</InputLabel>
                    <Select size="small" labelId="currency" label={lang.currency} value={state.currency} onChange={(evt) => setState({currency: evt.target.value})} fullWidth>
                      <MenuItem value={'GBP'}>{lang.currency_uk}</MenuItem>
                      <MenuItem value={'EUR'}>{lang.currency_eur}</MenuItem>
                      <MenuItem value={'PLN'}>{lang.currency_rs}</MenuItem>
                      <MenuItem value={'RSD'}>{lang.currency_pl}</MenuItem>
                    </Select>
                    {error.currency && <FormHelperText>{error.currency}</FormHelperText>}
                  </FormControl>
                </div>

            
              </Box>
						</Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="primary" variant="contained" size="small" loading={props.loading} onClick={updateProfile}>{lang.confirm}</LoadingButton>
        {/* <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton> */}
      </DialogActions>

      <Message type="error" open={Object.keys(error).length > 0 ? true : false} message={error.non_field_errors} onClose={onCloseMessage} />

    </Dialog>
  )
}

export default GoogleRegistrationForm;