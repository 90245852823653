import { AttachFile, Close, Event } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
import { blue } from "@mui/material/colors";
import $seasons from "../../../services/$seasons";
import $data from "../../../services/$data";
import Message from "../../ui/Message";
import colors from "../../../config/colors";
import $map from "../../../services/$map";
import Divider from "../../auth/Divider";

function UploadParcels(props) {
	const {lang, cropTypes, user, parcels, onUpdateState} = useContext(AppContext);
  const filesRef = useRef([]);

	const [state, setState] = useMergeState({ file: [], error: {}, loading: false });


	useEffect(() => {
    if(props.open) {
      filesRef.current = [];
      setState({file: []});
    }
	}, [props.open]);

	const uploadParcels = () => {
		setState({loading: true, error: {}})

    let fd = new FormData();
    state.file.map(f => {
      fd.append('file', f);
    })

		$data.uploadParcels(fd)
			.then(data => {
        setState({loading: false, error: {}});
        props.onSuccess();
			})
			.catch(err => {
        setState({loading: false, error: err});
			})
	}

  const onFilesChange = (evt) => {
    setState({file: Array.from(filesRef.current.files)});    
  }

  const renderFiles = () => {
    if(state.file.length === 0) {
      return (
        <TableRow>
          <TableCell>{lang.no_files_selected}</TableCell>
        </TableRow>
      )
    }

    return state.file.map((f, key) => {
      return <TableRow key={key}>
        <TableCell>{f.name}</TableCell>
        <TableCell>{f.type}</TableCell>
        <TableCell>{Math.round(f.size/1024)} KB</TableCell>
      </TableRow>
    })
  }

	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}, success: null});
  }
	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.action_upload}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
              <Stack sx={{mb: '15px'}} spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
                {/* <Button size="small" variant="contained" onClick={() => setAttach(true)}> */}
                <Button size="small" variant="contained" component="label">
                  <AttachFile /> &nbsp; {lang.select_files}
                  <input ref={filesRef} onChange={onFilesChange} value={""} multiple type="file" hidden />
                </Button>
                {/* </Button> */}
              </Stack>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{color: 'orange', fontWeight: 'bold'}}>Only ESRI Shapefile (.shp, .dbf, .shx, .prj), GeoPackage (.gpkg) or GeoJSON (.geojson) are allowed.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{width: '100%', margin: 0}}></Divider>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableBody>
                  {renderFiles()}
                </TableBody>
              </Table>
             

            </Grid>
          </Grid>
        </Container>
      </DialogContent>
			<DialogActions>
				<LoadingButton disabled={state.file.length === 0} onClick={uploadParcels} size="small" loading={state.loading} variant="contained" color="primary">{lang.confirm}</LoadingButton>
				<LoadingButton onClick={props.onClose} size="small" loading={state.loading} variant="contained" color="error">{lang.cancel}</LoadingButton>
			</DialogActions>
			<Message type="error" open={state.error?.error !== undefined} message={state.error?.error} onClose={() => onCloseMessage()} />
    </Dialog>
	)
}

export default UploadParcels;