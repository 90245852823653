import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, useMediaQuery} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import {useTheme} from '@mui/system';
import { Add, Close, Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import EditCropSeason from "./EditCropSeason";
import RemoveCropSeason from "./RemoveCropSeason";

function SummaryFullHistory(props) {
  const {lang, primary} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);

  if(!props.parcel) return null;

  const renderCrops = () => {
    return props.parcel?.crop_history?.map((obj, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{obj.crop.name}</TableCell>
          <TableCell>{obj.sowing ? moment(obj.sowing).format('DD-MMM-YYYY') : ' - '}</TableCell>
          <TableCell>{obj.harvest ? moment(obj.harvest).format('DD-MMM-YYYY') : ' - '}</TableCell>
          <TableCell><Edit onClick={() => {setEdit(obj)}} sx={{cursor: 'pointer'}} color="warning" /> &nbsp; <Delete onClick={() => {setRemove(obj)}} sx={{cursor: 'pointer'}} color="error" /></TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.crop_history} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {props.parcel?.crop_history?.length === 0 && <Typography sx={{mt: '10px', color: primary}} variant="body1">{lang.no_crop_records}</Typography>}
              {props.parcel?.crop_history?.length !== 0 && <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{lang.parcel_crop}</TableCell>
                    <TableCell>{lang.parcel_sowing}</TableCell>
                    <TableCell>{lang.parcel_harvest}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderCrops()}
                </TableBody>
              </Table>}
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.openCropRecordModal} variant="contained" size="small">{lang.add_record} <Add fontSize="small" /></Button>
        <Button onClick={props.onClose} size="small" variant="contained" color="error">{lang.close}</Button>
      </DialogActions>

      {edit && <EditCropSeason open={Boolean(edit)} data={edit} onClose={() => setEdit(false)} />}
      {remove && <RemoveCropSeason open={Boolean(remove)} data={remove} onClose={() => setRemove(false)} />}
    </Dialog>
  )
}

export default SummaryFullHistory;