import { Search, Warning } from "@mui/icons-material";
import { Chip, colors, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import moment, { lang } from "moment";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function ScrollCalendar(props) {
  /**
   * minDate
   * maxDate
   * onSelect
   * selected
   * highlighted [{dates: '', numberOfJobs: '', color: ''}]
   * disabled
   */
  const {primary, lang, warning, secondary} = useContext(AppContext)

  const minDate = props.minDate || moment().subtract(6, 'months').format('YYYY-MM-DD')
  const maxDate = props.maxDate || moment().add(6, 'months').format('YYYY-MM-DD')

  const numberOfItems = moment(maxDate).diff(moment(minDate), 'months')

  const weekdays = moment.weekdaysMin(true);

  const renderDays = (numOfDays, startIndex, month) => {

    let preList = [];
    let list = [];
    let postList = [];
    for(let i = 0; i < startIndex; i++) {
      preList.push(<li className="day disabled" key={new Date().getMilliseconds() * Math.random()}>&nbsp;</li>)
    }

    for(let j = preList.length; j < numOfDays + preList.length; j++) {
      let date = `${month}-${(j - (preList.length -1)) < 10 ? ('0' + (j - (preList.length -1))): (j - (preList.length -1))}`;
      if(props.highlighted.hasOwnProperty(date)) {
        if(props.highlighted[date].ids.length > 0) {
          list.push(
            <Tooltip key={new Date().getMilliseconds() * Math.random()} title={lang.act_see_jobs}>
              <li onClick={() => props.onOpenDetails(props.highlighted[date].ids)} style={{background: '#c9e26f', position: 'relative'}} className="day active" >
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', position: 'absolute', zIndex: 3, top: '-12px', right: 0}}>
                  {props.highlighted[date].numberOfCompleted > 0 && <span style={{borderRadius: '100%', width: '16px', height: '16px', lineHeight: '16px', fontSize: '13px', padding: '2px', background: colors.green[500], color: '#fff', fontWeight: 'bold'}}>{props.highlighted[date].numberOfCompleted}</span>}
                  {props.highlighted[date].numberOfActive > 0 && <span style={{borderRadius: '100%', width: '16px', height: '16px', lineHeight: '16px', fontSize: '13px', padding: '2px', background: warning, color: '#fff', fontWeight: 'bold'}}>{props.highlighted[date].numberOfActive}</span>}
                </div>
                {j - (preList.length -1)}</li>
            </Tooltip>)
        } else {
          list.push(
            <Tooltip key={new Date().getMilliseconds() * Math.random()} title={lang.act_see_jobs}>
              <li onClick={() => props.onOpenDetails(props.highlighted[date].ids)} style={{background: warning}} className="day active" >{j - (preList.length -1)}</li>
            </Tooltip>)
        }
        
      } else {
        list.push(<li className="day" key={new Date().getMilliseconds() * Math.random()}>{j - (preList.length -1)}</li>)
      }
    }

    

    if(preList.length + list.length >= 35) {
      for(let p = 0; p < (42 - (preList.length + list.length)) % 7; p++) {
        postList.push(<li className="day disabled" key={new Date().getMilliseconds() * Math.random()}>&nbsp;</li>)
      }
    } else {
      for(let p = 0; p < (35 - (preList.length + list.length )) % 7; p++) {
        postList.push(<li className="day disabled" key={new Date().getMilliseconds() * Math.random()}>&nbsp;</li>)
      }
    }


   

    return _.concat(preList, list, postList)
  }

  const renderMonthAndYear = (date, key) => {
    let numberOfDays = moment(date.slice(0, 8), 'YYYY-MM').daysInMonth();
    let startOfMonth = moment(date).startOf('month').weekday();

    return (
      <div key={key} className="scroll-calendar-month-container">
        <p style={{textAlign: 'center', color: primary, fontWeight: 'normal'}}>{moment(date).format('YYYY')}</p>
        <p style={{textAlign: 'center', color: primary, fontWeight: 'bold'}}>{moment(date).format('MMMM')}</p>
        <ul className="weekdays">
          {weekdays.map((weekday, key) => (
            <li key={key}>{weekday}</li>
          ))}
        </ul>
        <ul className="weekdays">
          {renderDays(numberOfDays, startOfMonth, moment(date).format('YYYY-MM'))}
        </ul>
      </div>
    )
  }

  const renderItems = () => {
    let list = [];
    
    let min = moment(minDate)
    let i = 0;

    if(props.jobs.length === 0 || (moment(maxDate).diff(min) <= 0)) {
      return (
        <Typography variant="h6" color="primary">
          {lang.act_no_jobs}
        </Typography>
      )
    }

  
   

    do {
      list.push(
        renderMonthAndYear(moment(minDate).add(i, 'months').format('YYYY-MM-DD'), i)
      );
      i++;
      min = moment(minDate).add(i, 'months');
      

    }
    while (min.format('YYYY-MM') !== moment(maxDate).add(1, 'months').format('YYYY-MM'));
   


    return list
  }


  return renderItems();
}

export default ScrollCalendar;