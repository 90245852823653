import { Stack } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import Panel from "./Panel";
import Map from './Map';
import { basemaps, indices } from "../../config/map";
import moment from "moment";
import $map from "../../services/$map";
import useMergeState from "../hooks/useMergeState";
import $query from "../../services/$query";
import Toolbar from "./Toolbar";
import Datapanel from "./Datapanel";
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from "@mui/icons-material";
import Legend from "./Legend";
import $data from "../../services/$data";
import ErrorWrapper from "../ui/error/ErrorWrapper";
import SelectedValue from "./SelectedValue";

function MapView(props) {
  const initialRender = useRef(true);
  const {locale, layer, action, date, photosData, user, datapanel, onUpdateState, toUpdatePhotos} = useContext(AppContext);
  const layerRef = useRef(layer);
  const photosDataRef = useRef(photosData);

  const [panelVisible, setPanelVisible] = useState(window.innerWidth < 601 ? false : true);

  const [activeState, setActiveState] = useMergeState({
    pointA: null,
    pointB: null,
    parcelA: null,
    parcelB: null,
    summaryLoading: false,
    queryLoading: false,
    sensorsLoading: false,
    gddLoading: false,
    meteoLoading: false,
    forecastLoading: false,

    summaryData: null,
    queryData: null,
    sensorsData: null,
    gddData: null,
    meteoData: null,
    forecastData: null,

    summaryError: null,
    queryError: null,
    sensorsError: null,
    gddError: null,
    meteoError: null,
    forecastError: null,
  });

  const [meteoFilter, setMeteoFilter] = useMergeState({date_from: moment().subtract(3, 'years').format('YYYY-MM-DD'), date_to: moment().format('YYYY-MM-DD')});

  useEffect(() => {
    onUpdateState({view: 'map'})
  },[]);


  useEffect(() => {
    if(activeState.meteoData) {
      if(!action) {
        setActiveState({meteoLoading: true, meteoData: null, meteoError: null});
        $query.meteo(activeState.parcelA, `?date_from=${meteoFilter.date_from}&date_to=${meteoFilter.date_to}`)
          .then(data => setActiveState({meteoData: data, meteoLoading: false}))
          .catch(err => { if(err !== 'cancel') { setActiveState({meteoError: err, meteoLoading: false}) } })
      }

      if(action === 'compare') {
        setActiveState({meteoLoading: true, meteoData: null});
        $query.compareMeteos(parcelA, parcelB, `?date_from=${meteoFilter.date_from}&date_to=${meteoFilter.date_to}`)
          .then(data => setActiveState({meteoData: data, meteoLoading: false}))
          .catch(err => { if(err !== 'cancel') { setActiveState({meteoError: err, meteoLoading: false}) } })
      }
    }
  }, [meteoFilter.date_from, meteoFilter.date_to])

  useEffect(() => {
    if(props.zoomToParcel) {
      onUpdateState({selectedParcel: null})
    }
  }, [props.zoomToParcel]);

  useEffect(() => {
    onUpdateState({season: props.season})
  }, [props.season]);

  useEffect(() => {
    if(initialRender.current){
      initialRender.current = false;
    } else {
      cleanState();
    }
    
  }, [action]);

 

  useEffect(() => {

  }, [])

  useEffect(() => {
    layerRef.current = layer;
    if(action === 'compare') {
      if(activeState.queryData && (activeState?.queryData[0]['s2'][layer]?.length === 0 || activeState?.queryData[1]['s2'][layer]?.length === 0)) {
        setActiveState({queryLoading: true});

        $query.comparePoints({coordinates: activeState.pointA, parcel: activeState.parcelA}, {coordinates: activeState.pointB, parcel: activeState.parcelB}, [layer === 'productivity' || layer === 'mzones' ? 'mz' : layer])
          .then(data => {
  
            let toUpdate = activeState.queryData.map((dataset, key) => {
              dataset['s2'][layer] = data[key].s2[layer];
              dataset['planet'][layer] = data[key].planet[layer];
  
              return dataset;
            });
  
            setActiveState({queryLoading: false, queryData: toUpdate});
          })
          .catch(err => { if(err !== 'cancel') { setActiveState({queryError: err, queryLoading: false}) } })
      }
    }

    if(!action) {
      if(activeState.queryData && activeState?.queryData[0]['s2'][layer]?.length === 0) {

     
        setActiveState({queryLoading: true});
        $query.point(activeState.pointA, activeState.parcelA, false, [layer === 'productivity' || layer === 'mzones' ? 'mz' : layer])
          .then(data => {
  
            let toUpdate = activeState.queryData.map(dataset => {
              dataset['s2'][layer] = data[0].s2[layer];
              dataset['planet'][layer] = data[0].planet[layer];
  
              return dataset;
            });
  
            setActiveState({queryLoading: false, queryData: toUpdate});
          })
          .catch(err => { if(err !== 'cancel') { setActiveState({queryError: err, queryLoading: false}) } })
      }
      return
    }
   
  }, [layer]);


  const setLoading = (all, additional = {}) => {
    if(all) {
      setActiveState({...additional,
        summaryData: null, queryData: null, sensorsData: null, gddData: null, meteoData: null, forecastData: null, 
        summaryError: null, queryError: null, sensorsError: null, gddError: null, meteoError: null, forecastError: null, 
        summaryLoading: true, queryLoading: true, sensorsLoading: true, gddLoading: true, meteoLoading: true, forecastLoading: true, 
      });
    
    } else {
      setActiveState({...additional, 
        queryData: null, forecastData: null,
        queryError: null, forecastError: null, queryLoading: true, forecastLoading: true, })
    }

    onUpdateState({datapanel: true})
  }

  const cleanState = () => {
    $map.instance.getOverlays().getArray().map(o => o.setPosition(null));
    setActiveState({
      parcelA: null, parcelB: null, pointA: null, pointB: null,
      photosData: null,
      summaryData: null, queryData: null, sensorsData: null, gddData: null, meteoData: null, forecastData: null, 
      summaryError: null, queryError: null, sensorsError: null, gddError: null, meteoError: null, forecastError: null, 
      summaryLoading: false, queryLoading: false, sensorsLoading: false, gddLoading: false, meteoLoading: false, forecastLoading: false, 
    })
    setMeteoFilter({date_from: moment().subtract(3, 'years').format('YYYY-MM-DD'), date_to: moment().format('YYYY-MM-DD')})

  }

  useEffect(() => {
    if(toUpdatePhotos) {
      photosDataRef.current = photosData;
      onUpdateState({toUpdatePhotos: false});
      cleanState();


    }
  }, [toUpdatePhotos])

  const extractGddFilter = (parcel) => {
    let parcelObj = $data.getParcelById(parcel);

    let result = {};

    let filtered = parcelObj.properties.crop_history.filter(obj => obj.crop.crop_group !== 6)

    if(filtered.length > 0) {

      result.id = 0;
      result.date_from = filtered[0].sowing;
      result.date_to = filtered[0].harvest ? filtered[0].harvest : moment(filtered[0].harvest).add(8, 'month').format('YYYY-MM-DD');
      result.crop_id = filtered[0].crop.pk;
    }

    return result
  }

  const filterSensor = (sensor, filter) => {
    if(!sensor) {
      return
    }
    if(!action) {
      console.log(sensor)
      setActiveState({sensorsLoading: true, sensorsData: null, sensorsError: null});
      $query.sensor(sensor, null, filter)
        .then(data => setActiveState({sensorsData: data, sensorsLoading: false}))
        .catch(err => { if(err !== 'cancel') { setActiveState({sensorsError: err, sensorsLoading: false}) } })
    }
  }

  //DEFAULT ACTION HANDLER
  const point = (coordinates, parcel, sensor) => {
    if(!coordinates || !parcel) {
      onUpdateState({datapanel: true})
      if((user.has_sensors) && sensor) {
        setActiveState({sensor: sensor.clone(), sensorsData: null});
      } else {
        setActiveState({sensorsLoading: false, sensorsData: null, sensor: null});
      }

      return
    }

    setLoading(true, {pointA: coordinates, parcelA: parcel});
    let extracted = extractGddFilter(parcel);

    $query.summary(parcel)
      .then(data => setActiveState({summaryData: data, summaryLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({summaryError: err, summaryLoading: false}) } })

    $query.gdd(parcel, false, extracted)
      .then(data => setActiveState({gddData: data, gddLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({gddError: err, gddLoading: false}) } })

    $query.meteo(parcel, `?date_from=${meteoFilter.date_from}&date_to=${meteoFilter.date_to}`)
      .then(data => setActiveState({meteoData: data, meteoLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({meteoError: err, meteoLoading: false}) } })
    
    $query.point(coordinates, parcel, false, [layerRef.current === 'productivity' || layerRef.current === 'mzones' ? 'mz' : layerRef.current])
      .then(data => setActiveState({queryData: data, queryLoading: false}))
      .catch(err => { console.log(err); if(err !== 'cancel') { setActiveState({queryError: err, queryLoading: false}) } })
    
    $query.forecast(coordinates)
      .then(data => setActiveState({forecastData: data, forecastLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({forecastError: err, forecastLoading: false}) } })

    if((user.has_sensors) && sensor) {
      setActiveState({sensor: sensor.clone(), sensorsData: null});
    } else {
      setActiveState({sensorsLoading: false, sensorsData: null, sensor: null});
    }

    let photos = $query.photos(parcel, photosDataRef.current);
    setActiveState({photosData: photos})

  }

  const filteredGdd = (parcel, filter) => {
    setActiveState({ gddData: null, gddLoading: true, gddError: null });

    $query.gdd(parcel, false, filter)
      .then(data => setActiveState({gddData: data, gddLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({gddError: err, gddLoading: false}) } })
  }

  const compare = (pointA, pointB, parcelA, parcelB) => {
    setLoading(true, {pointA, parcelA, pointB, parcelB});
    let extractedA = extractGddFilter(parcelA);
    let extractedB = extractGddFilter(parcelB);

    $query.compareSummaries(parcelA, parcelB)
      .then(data => setActiveState({summaryData: data, summaryLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({summaryError: err, summaryLoading: false}) } })

    $query.compareGdds(parcelA, parcelB, extractedA, extractedB)
      .then(data => setActiveState({gddData: data, gddLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({gddError: err, gddLoading: false}) } })

    $query.compareMeteos(parcelA, parcelB, `?date_from=${meteoFilter.date_from}&date_to=${meteoFilter.date_to}`)
      .then(data => setActiveState({meteoData: data, meteoLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({meteoError: err, meteoLoading: false}) } })
    
    $query.comparePoints({coordinates: pointA, parcel: parcelA}, {coordinates: pointB, parcel: parcelB}, [layerRef.current === 'productivity' || layerRef.current === 'mzones' ? 'mz' : layerRef.current])
      .then(data => setActiveState({queryData: data, queryLoading: false}))
      .catch(err => {if(err !== 'cancel') {  setActiveState({queryError: err, queryLoading: false}) } })
    
    $query.compareForecasts(pointA, pointB)
      .then(data => setActiveState({forecastData: data, forecastLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({forecastError: err, forecastLoading: false}) } })

  }

  const filteredGddCompare = (parcelA, parcelB, filterA, filterB) => {
    setActiveState({ gddData: null, gddLoading: true, gddError: null });

    $query.compareGdds(parcelA, parcelB, filterA, filterB)
      .then(data => setActiveState({gddData: data, gddLoading: false}))
      .catch(err => { if(err !== 'cancel') { setActiveState({gddError: err, gddLoading: false}) } })

  }


  // let filtered = {
  //   type: 'FeatureCollection',
  //   features: parcels.features.filter(feature => {
  //     let sowing = feature.properties.season_crop.sowing;

  //     let min = `${props.season}-08-01`;
  //     let max = `${parseInt(props.season) + 1}-07-31`; 
  //     if(moment(sowing).diff(moment(min)) >= 0 && moment(sowing).diff(moment(max)) <= 0) return true;
  //     return false
  //   })
  // };
  let filtered = $data.setActiveCrop(date, props.parcels, locale);
  
  let {pointA, pointB, parcelA, parcelB, sensor} = activeState;
  let {summaryError, queryError, sensorsError, gddError, meteoError, forecastError} = activeState;
  let {summaryData, queryData, sensorsData, gddData, meteoData, forecastData} = activeState;
  let {summaryLoading, queryLoading, sensorsLoading, gddLoading, meteoLoading, forecastLoading} = activeState;

  return (
    <Stack className="map-view-container" style={{height: '100%'}} direction="row">
      
      <ErrorWrapper component="panel">
       <Panel panel={panelVisible} setPanel={setPanelVisible} parcels={filtered.features} />
      </ErrorWrapper>

      <div className={"map-wrapper" + (panelVisible ? ' opened' : '')}>
        <Map pointA={pointA} pointB={pointB} panel={panelVisible} setPanel={setPanelVisible} zoomToParcel={props.zoomToParcel} parcels={Object.assign({}, filtered)} onPointHandler={point} onCompareHandler={compare} />
        <SelectedValue data={queryData} />
        <div onClick={() => onUpdateState({datapanel: !datapanel})} className={"toggle-datapanel" + (datapanel ? ' open' : '')}>
        {!activeState.open && <KeyboardDoubleArrowUp style={{marginTop: '2px'}} fontSize="small" color="primary" />}        
        {activeState.open && <KeyboardDoubleArrowDown style={{marginTop: '2px'}} fontSize="small" color="primary" />}        
        </div>

   
          <Datapanel
            meteoFilter={meteoFilter}
            onSetMeteoFilter={setMeteoFilter}
            cleanState={cleanState}
            onFilterGdd={filteredGdd}
            onQueryGdd={filteredGdd}
            onCompareGdd={filteredGddCompare}
            onFilterSensor={filterSensor}
            sensor={sensor}
            open={datapanel}
            pointA={pointA}
            pointB={pointB}
            parcelA={parcelA}
            parcelB={parcelB} 
            data={{photos: activeState.photosData, summary: summaryData, query: queryData, sensors: sensorsData, gdd: gddData, meteo: meteoData, forecast: forecastData}} 
            error={{summary: summaryError, query: queryError, sensors: sensorsError, gdd: gddError, meteo: meteoError, forecast: forecastError}} 
            loading={{summary: summaryLoading, query: queryLoading, sensors: sensorsLoading, gdd: gddLoading, meteo: meteoLoading, forecast: forecastLoading}} />
   
        

          <Legend />
      </div>

      <Toolbar />
    </Stack>
  )
}

export default MapView;