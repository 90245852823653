import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import useMergeState from "../hooks/useMergeState";
import { Container, DialogContent, DialogTitle, Grid, TextField, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography, colors, Table, TableHead, TableRow, TableCell, TableFooter, TablePagination, TableBody, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { LoadingButton } from "@mui/lab";
import $data from "../../services/$data";
import Message from "../ui/Message";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";

function ConnectedMachinesModal(props) {
  const {lang, onUpdateState} = useContext(AppContext);

  const [data, setData] = useMergeState({model: {name: ''}, loading: false, success: null,  error: {}, });

  const [connectedMachines, setConnectedMachines] = useMergeState({data: {count: 0, results: []}, loading: false});
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getConnectedMachines()
  }, [])

  const onSubmit = (evt) => {
    evt.preventDefault();
    setData({error: {}, loading: true});
    $data.postConnectedMachine(data.model)
      .then(data => { getConnectedMachines(); setData({model: {name: ''}, success: lang.success_create_con_machine, loading: false}) })
      .catch(err => { setData({error: err, loading: false}) })
  }

  const getConnectedMachines = () => {
    setConnectedMachines({loading: true});
    $data.getConnectedMachines()
      .then(data => {onUpdateState({connectedMachines: data}); setConnectedMachines({data: {count: data.length, results: data}, loading: false})})
      .catch(err => setConnectedMachines({loading: false}))
  }

  const onHandlePageChange = (evt, pg) => {
    setPage(pg);
  }

  const renderEmptyTable = () => {
    return (
      <TableRow>
        <TableCell colSpan={6}>{lang.no_machines}</TableCell>
      </TableRow>
    )
  }

  const renderRows = (data) => {
    if(!data) return renderEmptyTable();

    if(!data.results) return renderEmptyTable();

    if(data.results.length === 0) return renderEmptyTable();

    return data.results
			.filter((obj, key) => key < (page * 10 + 10) && key >= (page - 1) * 10 + 10)
			.map((machine, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{key + 1 + page * 10}</TableCell>
          <TableCell>{machine.name !== '' && machine.name ? machine.name : ' - '}</TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.fld_con_machines} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginBottom: '20px'}}>
          <Grid component="form" onSubmit={onSubmit} container spacing={2}>
            <Grid item xs={12}>
               <Link style={{textDecoration: 'none'}} to="/dashboard/profile">
                <Typography sx={{'&:hover': {color: colors.blue[500]}}} variant="p" color={colors.orange[500]}>
                  {lang.fld_con_machine_nav_message}  
                </Typography>
              </Link>      
            </Grid>
            <Grid item xs={12}>
               <Typography variant="p" style={{fontWeight: 'bold'}} color="primary">
                {lang.fld_add_con_machine}  
              </Typography>      
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField size="small" error={data.error.name !== undefined || data.error.non_field_errors !== undefined} helperText={data.error.name} fullWidth label={lang.fld_con_machine} type='text' value={data.model.name || ''} onChange={(evt) => setData({model: {...data.model, name: evt.target.value}})} />      
            </Grid>
            <Grid style={{textAlign: 'left'}} className="employee-input" item xs={12}>
              <LoadingButton size="small" loading={data.loading} type="submit" variant="contained">{lang.confirm}</LoadingButton>
            </Grid>      
          </Grid>
        </Container>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {connectedMachines.loading && <LinearProgress />}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{lang.num}</TableCell>
                    <TableCell>{lang.fld_con_machine}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(connectedMachines.data)}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={connectedMachines.data.count}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={onHandlePageChange}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <Message type="success" open={data.success ? true : false} message={data.success} onClose={() => setData({success: null})} />
      <Message type="error" open={data.error.non_field_errors !== undefined} message={data.error.non_field_errors} onClose={() => setData({error: {}})} />

    </Dialog>
  );
}

export default ConnectedMachinesModal;