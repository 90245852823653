import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Button, Checkbox, Chip, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";
import { Delete, Event } from "@mui/icons-material";
import $activities from "../../../services/$activities";
import Message from "../../ui/Message";

function GrazingForm(props) {
	let {lang, locale, tillageTypes, operators, machines, connectedMachines} = useContext(AppContext);

	let editModel = props.model ? Object.assign({}, props.model, {operators: props.model.operators.map(obj => obj.pk), animals: JSON.parse(props.model.animals)}) : {};

  const [endDate, setEndDate] = useState(props.isJob ? moment().add(1, 'days').format('YYYY-MM-DD') : (props.model?.date ? moment(props.model.date).add(props.model.num_of_days, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')));
  
  const [animalType, setAnimalType] = useState('');

  const [numberOfSpecies, setNumberOfSpecies] = useState('0');

	const [data, setData] = useMergeState({model: Object.assign({}, {
		date: props.isJob ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
		activity_type: 8,
		num_of_days: 0,
    animals: {},
		comment: '',
		parcel: props.parcel.pk

	}, {...editModel}), error: {}, success: false, loading: false});

	const [multiline, setMultiline] = useState(false);

	const {model, error, success, loading} = data;

	useEffect(() => {
		if(props.isJob && !props.forArchive) {
			if(moment(model.date).diff(moment()) <= 0) {
				setData({model: {...model, date: moment().add(1, 'days').format('YYYY-MM-DD')}})
			}
		}
	}, [props.isJob])

  const reformatNumberOfDays = (endDate) => {
    setData({model: {...model, 'num_of_days': moment(endDate).diff(model.date, 'days').toString()}});
    setEndDate(moment(endDate).format('YYYY-MM-DD'))
  }

	const onChange = (name, val) => {

    if(name === 'num_of_days') {
      setEndDate(moment(model.date).add(parseInt(val), 'days').format('YYYY-MM-DD'))
    }

    if(name === 'date') {
      setData({model: {...model, [name]: val, num_of_days: moment(endDate).diff(val, 'days').toString()}})
    } else {
      setData({model: {...model, [name]: val}});
    }

		
	}

  const addAnimal = (species, number) => {
    setAnimalType('');
    setNumberOfSpecies('0');
    setData({model: {...model, animals: Object.assign({}, {...model.animals, [_.uniqueId(moment().format('YYYYMMDDHHmmss'))]: {species, number}})}})
  }

  const removeAnimal = (id) => {
    let newModel = {model: {...model, animals: Object.assign({}, {...model.animals})}}

    delete newModel.model.animals[id];

    setData(newModel);
  }

	const getTotalPrice = () => {
    return {
      ha: (parseFloat(model.price_ha)).toFixed(2),
      parcel: (parseFloat(model.price_parcel)).toFixed(2), 
    }
  }

  const renderAnimals = () => {
    if(Object.keys(model.animals).length === 0)  {
      return <Typography variant="body2">No animals added</Typography>
    }

    let rows = Object.keys(model.animals).map((id) => {
      return <TableRow key={id}>
        <TableCell sx={{p: '5px'}}>{model.animals[id].species}</TableCell>
        <TableCell sx={{p: '5px'}}>{model.animals[id].number}</TableCell>
        <TableCell sx={{p: '5px'}}><IconButton onClick={() => removeAnimal(id)} color="error"><Delete /></IconButton></TableCell>
      </TableRow>
    })

    return <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{lang.animal_species}</TableCell>
          <TableCell>{lang.num_of_animals}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows}
      </TableBody>
    </Table>
  }

	return (
		<Grid className={props.className || ''} component="form" onSubmit={(evt) => {evt.preventDefault(); props.onSubmit(model, true)}} container spacing={2}>
			{(!props.isJob || props.forArchive) && <Grid item xs={12} sm={4}>
				<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
					<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={props.isJob && !props.forArchive ? moment().add(1, 'days') : undefined} onClose={() => {onChange('date', model.date)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.start_date} value={moment(model.date)} onChange={(newValue) => onChange('date', newValue.format('YYYY-MM-DD'))}
						renderInput={(params) => (
							<TextField fullWidth error={props.error.date} helperText={props.error.date || ''} {...params} size="small"
								InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
						)}
					/>
				</LocalizationProvider>	
			
			</Grid>}

      {(!props.isJob || props.forArchive) && <Grid item xs={12} sm={4}>
				<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
					<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={props.isJob && !props.forArchive ? moment().add(1, 'days') : undefined} onClose={() => {setEndDate(endDate)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.end_date} value={moment(endDate)} onChange={(newValue) => reformatNumberOfDays(newValue.format('YYYY-MM-DD'))}
						renderInput={(params) => (
							<TextField fullWidth error={props.error.date} helperText={props.error.date || ''} {...params} size="small"
								InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
						)}
					/>
				</LocalizationProvider>	
			
			</Grid>}
			{/* {(!props.isJob || props.forArchive) && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>} */}
			{(!props.isJob || props.forArchive) && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>}
			

			<Grid item xs={12} sm={6}>
				<TextField error={props.error.num_of_days ? true : false} helperText={props.error.num_of_days || ''} label={lang.num_of_days} type="number" onChange={(evt) => onChange('num_of_days', evt.target.value)} fullWidth size="small" value={model.num_of_days} />
			</Grid>	

      <Grid item xs={12}>
        <fieldset style={{padding: '15px'}}>
          <legend>{lang.add_animals}</legend>

          <Grid container spacing={2}>
 
          <Grid item xs={12} sm={4}>
            <TextField label={lang.animal_species} type="text" onChange={(evt) => setAnimalType(evt.target.value)} fullWidth size="small" value={animalType} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label={lang.num_of_animals} type="number" onChange={(evt) => setNumberOfSpecies(evt.target.value)} fullWidth size="small" value={numberOfSpecies} />

          </Grid>
          <Grid item xs={12} sm={4}>
            <Button size="small" variant="contained" onClick={() => {addAnimal(animalType, numberOfSpecies)}}>{lang.add}</Button>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            {renderAnimals()}
          </Grid>

        </Grid>
        </fieldset>
        
      </Grid>
			
			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.comment ? true : false} helperText={props.error?.common_activity?.comment || ''} multiline label={lang.comment} fullWidth size="small" onChange={(evt) => onChange('comment', evt.target.value)}  value={model.comment} />
			</Grid>	

			<Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} item xs={12}>
				{!props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.confirm}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" color="warning" variant="contained" type="button" onClick={(() => props.onSubmit(model, false))} loading={props.loading}>{lang.act_job_update}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.archive_job}</LoadingButton>}

				{!props.edit && <LoadingButton size="small" onClick={props.onClose} variant="contained" color="error" loading={props.loading} type="button">{lang.close}</LoadingButton>}
			</Grid>	

			<Message type="error" open={props.error.non_field_errors || props.error.file ? true : false} message={props.error.non_field_errors || props.error.file} onClose={props.closeErrorMessage} />

		</Grid>
	)
}

export default GrazingForm;
