import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import {Stack, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Add, Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";

// import Message from "./Message";

function EditPhoto(props) {
  const { lang, parcels, onUpdatePhotos } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [error, setError] = useState({});
  const [state, setState] = useState({ id: props.data.id, tag: props.data.tag, description: props.data.description, name: props.data.name, parcel: props.data.parcel });

  const [newTag, setNewTag] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    try {
      await $data.updatePhoto(state.id, {...state, tag: state.tag === '' ? null : state.tag});
      onUpdatePhotos();
      props.onClosePreview();
      // props.onClose();

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false)
    }
  }

console.log(parcels)

  return (
    <Dialog sx={{ zIndex: 999999 }} fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.edit_photo}<Close onClick={props.onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent>
        <Container style={{ marginTop: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField autoComplete="off" size="small" error={error.name !== undefined || error.non_field_errors !== undefined} helperText={error.name} fullWidth label={lang.document_name} type='text' value={state.name} onChange={(evt) => setState((current) => ({ ...current, name: evt.target.value }))} />
            </Grid>

            <Grid item xs={12}>
              <TextField autoComplete="off" size="small" error={error.name !== undefined || error.non_field_errors !== undefined} helperText={error.name} fullWidth label={lang.document_description} type='text' value={state.description} onChange={(evt) => setState((current) => ({ ...current, description: evt.target.value }))} />
            </Grid>

            <Grid item xs={12}>
              <Select MenuProps={{sx: {'zIndex': 999999999999999}}} fullWidth size="small" value={state.parcel || 'null'} onChange={(evt) => setState((current) => ({...current, parcel: evt.target.value}))}>
                <MenuItem key={-1} value={'null'}>{lang.none}</MenuItem>
                {parcels.features.map((f, key) => {
                  return <MenuItem key={key} value={f.properties.pk}>{f.properties.name || ' - '}</MenuItem>
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Stack justifyContent={"start"} alignItems="flex-start" spacing={2}>
              <Stack direction="row" alignContent="center" spacing={2}>
              <TextField autoComplete="off" size="small" label={lang.tag} type='text' value={newTag} onChange={(evt) => setNewTag(evt.target.value)} /> <Button disabled={newTag == ''} size="small" onClick={
                () => {
                setState((current) => ({...current, tag: [...(current.tag ? current.tag.split(';') : []), newTag].join(';')}))
                setNewTag('');
              }
                } variant="contained" startIcon={<Add />}>Add tag</Button>

              </Stack>
              {state.tag !== null && state.tag !== '' ? <Stack direction={"row"} spacing={2} flexWrap="wrap">{state.tag.split(';').map((t, key) => <Chip key={key} color="warning" variant="filled" label={t} size="small" icon={<Close sx={{cursor: 'pointer'}} onClick={() => setState((current) => ({...current, tag: current.tag.split(';').filter(tag => tag !== t).join(';')}))} />}></Chip>)}</Stack> : null}
              {state.tag === null || state.tag === '' && <p>There are no tags</p>}
              </Stack>
             
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color={props.confirmColor || "primary"} variant="contained" size="small" loading={loading} onClick={onSubmit}>{props.confirmText || lang.confirm}</LoadingButton>
        <LoadingButton color={props.closeColor || "error"} variant="contained" size="small" loading={loading} onClick={props.onClose}>{props.closeText || lang.close}</LoadingButton>
      </DialogActions>

      {/* <Message type="error" open={props.error ? true : false} message={props.error} onClose={props.onCloseMessage} /> */}

    </Dialog>
  )
}

export default EditPhoto;