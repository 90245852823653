import { Fab, Stack, Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useContext, useMemo, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { AppContext } from "../../../AppContext";
import { formatData as formatMeteoData, formatOptions as formatMeteoOptions } from "../../../config/charts/meteo";
import { formatData as formatAccData, formatOptions as formatAccOptions } from "../../../config/charts/accumulated";
import { Refresh } from "@mui/icons-material";

function Meteo(props) {
  const chartRef = useRef(null);
  const {lang} = useContext(AppContext);

  const renderContent = () => {
    if(props.mode === 'meteo') {
      return (
        <Chart 
          ref={chartRef}
          type="line" 
          data={formatMeteoData(props.data, [lang.tmax, lang.tmin, lang.precipitation], ['#FF7518', 'orange', blue[500]], props.filter)}
          options={formatMeteoOptions(lang)} />		
      ) 
    }

    if(props.mode === 'accumulated') {
      return (
        <Chart 
          ref={chartRef}
          type="line" 
          data={formatAccData(props.data, [lang.tmax, lang.tmin, lang.precipitation], ['#FF7518', 'orange', blue[500]], props.filter)}
          options={formatAccOptions(lang)} />	
      )
    }
  }

  return useMemo(() => (
    <Stack sx={{height: '150px'}}>
      <Tooltip placement="left" title={lang.reset_chart_view}><Fab style={{position: 'absolute', top: '50px', right: '5px'}} size="small" onClick={() => {chartRef.current.resetZoom();}}><Refresh fontSize="small" /></Fab></Tooltip> 

		  {renderContent()}	
		</Stack>
  ), [props.mode])
}

export default Meteo;