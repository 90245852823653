import moment from "moment";

class $dates {
  getClosestDate(oldDate, list) {
    if(!oldDate) return list[list.length - 1];

    if(list.indexOf(oldDate) > -1) return oldDate;

    //VECI OD MAX
    if(moment(oldDate).diff(moment(list[list.length -1])) > 0) {
      return list[list.length - 1]
    }

    //MANJI OD MIN
    if(moment(list[0]).diff(moment(oldDate)) > 0) {
      return list[0]
    }

    //NEGDE IZMEDJU
    let oldMoment = moment(oldDate);
    let date = undefined;
    for(let i = 1; i < list.length; i++) {
      if(oldMoment.diff(list[i - 1]) > 0 && moment(list[i]).diff(oldMoment) > 0) {
        date = moment(list[i]).diff(oldMoment) > oldMoment.diff(moment(list[i - 1])) ? list[i - 1] : list[i];
        break;
      }
    }
  
    return date
  }
}

export default new $dates();