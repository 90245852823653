import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";

function SamplesPreview(props) {
  const {lang, primary} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const columnNames = props.samples ? $data.extractColumns($data.formatSamples(props.samples.map((feature) => {
    let obj = {...feature.getProperties()}
    delete obj.geometry;
    return obj
  }))) : [];


  const renderSamples = () => {
    if(!props.samples) {
      return null;
    }
    
    console.log(props.samples);

    let formated = $data.formatSamples(props.samples.map((feature) => {
      let obj = {...feature.getProperties()}
      delete obj.geometry;
      return obj
    }));



    console.log(formated)

    return formated.map((obj, key) => {

      return (
        <TableRow key={key}>
          {columnNames.map((name, j) => {
            return <TableCell sx={{whiteSpace: 'nowrap'}} key={j}>{obj[name] || ' - '}</TableCell>
          })}
        </TableRow>
      )
    })
  }

  


  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.soil_measurements}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
              <TableContainer sx={{width: '100%', overflow: 'auto'}} component={Paper} elevation={2}>
              <Table size="small">
                {columnNames.length > 0 && <TableHead>
                  <TableRow>{columnNames.map((name, key) => <TableCell sx={{color: primary}} key={key}>{lang['soil_' + name] || name}</TableCell>)}</TableRow>
                </TableHead>}
                <TableBody>
                  {renderSamples(columnNames)}
                </TableBody>
              </Table>
            </TableContainer>
						</Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color={"error"} variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SamplesPreview;