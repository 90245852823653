import { fromLonLat } from "ol/proj";
import { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../../AppContext";
import useMergeState from "./useMergeState";

export const useGeoLocation = (tracking) => {
  const {lang} = useContext(AppContext)
  const mounted = useRef(false);
  const gl = useRef(null);
  const watcher = useRef(null);

  const [position, setPosition] = useMergeState({
    coords: null,
    accuracy: null,
    heading: null,
    error: null,
  });

  const onChange = (obj) => {
    let { coords } = obj;
    setPosition({
      coords:
        coords.latitude && coords.longitude
          ? fromLonLat([coords.longitude, coords.latitude])
          : null,
      accuracy: coords.accuracy,
      // heading: coords.heading,
      heading: null,
    });
  };

  const onError = (error) => {
    setPosition({ error: lang.geolocation_error });
  };

  useEffect(() => {
    mounted.current = true;

    if (tracking) {
      initialize();
    }

    return () => {
      if (gl.current && watcher.current) {
        gl.current.clearWatch(watcher.current);
      }
    };
  }, []);

  const initialize = () => {
    gl.current = window.navigator.geolocation;

    if (!gl.current) {
      setPosition({ error: true });
    }

		watcher.current = gl.current.watchPosition(onChange, onError, {
      enableHighAccuracy: true,
			timeout: 10000,			
    });
  };

  useEffect(() => {
    if (mounted.current) {
      if (tracking) {
        initialize();
      } else {
        if (gl.current && watcher.current) {
					setPosition({coords: null, error: null})
					gl.current.clearWatch(watcher.current);
        }
      }
    }
  }, [tracking]);

  return { ...position };
};
