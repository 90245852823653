import React from "react";

function Logo(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="420"
      height="220"
      version="1.1"
      viewBox="0 0 420 220"
    >
      <defs>
        <linearGradient id="linearGradient4163">
          <stop offset="0" stopColor="#00d100" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00d100" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          x1="224.389"
          x2="505.705"
          y1="439.18"
          y2="439.18"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient4163"
        ></linearGradient>
      </defs>
      <g
        fillOpacity="1"
        display="inline"
        transform="translate(-97.952 -344.907)"
      >
        <path
          fill="#548974"
          d="M211.073 354.907c-16.748 0-30.394 6.873-37.441 18.854a36.18 36.18 0 00-1.604 3.098c4.354 2.972 7.958 6.763 10.618 11.286.412.7.8 1.417 1.166 2.15 9.932-12.644 23.715-20.86 38.776-20.86 0 0-22.132 6.845-36.06 28.04.887 3.27 1.411 6.769 1.56 10.476.205 5.136-.318 10.692-1.542 16.596 3.323.44 6.375.67 9.166.67 9.118 0 15.605-2.32 20.41-7.298 4.306-4.462 6.68-10.453 9.192-16.797 3.839-9.69 8.188-20.673 20.818-29.157.724-.486 1.19-1.365 1.245-2.344.054-.976-.312-1.913-.974-2.505-8.582-7.644-21.79-12.209-35.33-12.209z"
        ></path>
        <path
          fill="#548974"
          d="M108.932 381.872c8.583-7.644 21.79-12.208 35.33-12.208 16.748 0 30.394 6.872 37.441 18.852 3.311 5.628 5.141 12.29 5.441 19.805.268 6.691-.69 14.09-2.842 22.052-7.352-25.898-27.882-46.182-51.554-46.182 0 0 22.15 6.852 36.076 28.067.006.012.193.283.513.793a65.184 65.184 0 017.057 15.055c3.09 8.635 5.952 20.487 5.952 34.77h-9.92s1.506-11.13-1.113-23.932c-4.332.688-8.203 1.029-11.69 1.029-9.118 0-15.605-2.32-20.41-7.297-4.305-4.462-6.681-10.454-9.193-16.798-3.839-9.69-8.188-20.674-20.818-29.157-.724-.487-1.19-1.364-1.245-2.343-.054-.98.313-1.92.977-2.512z"
        ></path>
        <path
          fill="#00ae69"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M129.425 506.069l11.207-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#ffe800"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M129.425 506.069l11.207-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#ffbe00"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M141.475 518.562l11.208-11.62 10.954 11.358-11.207 11.62z"
        ></path>
        <path
          fill="#ff8100"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M153.525 531.056l11.208-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#ff4900"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M165.575 543.55l11.208-11.62 10.955 11.357-11.208 11.62z"
        ></path>
        <path
          fill="#8eeb00"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M141.753 493.287l11.208-11.62 10.955 11.357-11.208 11.62z"
        ></path>
        <path
          fill="#ffe800"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M153.803 505.78l11.208-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#ffbe00"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M165.854 518.274l11.208-11.62 10.954 11.357-11.207 11.62z"
        ></path>
        <path
          fill="#ff8100"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M177.904 530.767l11.208-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#00c322"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M154.082 480.505l11.208-11.62 10.954 11.357-11.207 11.62z"
        ></path>
        <path
          fill="#8eeb00"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M166.132 492.998l11.208-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#ffe800"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M178.182 505.492l11.208-11.62 10.955 11.357-11.208 11.62z"
        ></path>
        <path
          fill="#ffbe00"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M190.233 517.985l11.208-11.62 10.954 11.358-11.208 11.62z"
        ></path>
        <path
          fill="#548974"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M166.41 467.723l11.208-11.62 10.955 11.357-11.208 11.62z"
        ></path>
        <path
          fill="#00c322"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M178.46 480.216l11.209-11.62 10.954 11.358-11.207 11.62z"
        ></path>
        <path
          fill="#8eeb00"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M190.511 492.71l11.208-11.62 10.955 11.357-11.208 11.62z"
        ></path>
        <path
          fill="#ffe800"
          stroke="#fff"
          strokeDasharray="none"
          strokeMiterlimit="4.5"
          strokeOpacity="1"
          strokeWidth="0"
          d="M202.561 505.203l11.208-11.62 10.955 11.358-11.208 11.62z"
        ></path>
        <g
          style={{ lineHeight: "0%" }}
          fill="#548974"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          fontFamily="Roboto"
          fontSize="68.22"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          letterSpacing="0"
          transform="scale(1.10218 .90729)"
          wordSpacing="0"
        >
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M252.767 507.353q-.899 7.695-5.696 11.892-4.763 4.164-12.691 4.164-8.594 0-13.79-6.162-5.164-6.163-5.164-16.49v-4.663q0-6.762 2.398-11.892 2.432-5.13 6.862-7.861 4.43-2.765 10.26-2.765 7.728 0 12.392 4.33 4.663 4.298 5.43 11.926h-6.43q-.832-5.796-3.63-8.394-2.765-2.599-7.762-2.599-6.129 0-9.627 4.53-3.464 4.53-3.464 12.892v4.697q0 7.894 3.298 12.558 3.298 4.663 9.227 4.663 5.33 0 8.161-2.398 2.865-2.432 3.797-8.428z"
          ></path>
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M277.95 492.23q-1.399-.233-3.031-.233-6.063 0-8.228 5.163v25.583h-6.162V486.7h5.996l.1 4.163q3.03-4.83 8.594-4.83 1.799 0 2.731.467z"
          ></path>
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M281.315 504.389q0-5.297 2.065-9.527 2.098-4.23 5.796-6.53 3.73-2.298 8.494-2.298 7.362 0 11.892 5.097 4.564 5.096 4.564 13.557v.433q0 5.264-2.032 9.46-1.999 4.165-5.763 6.496-3.73 2.332-8.594 2.332-7.329 0-11.892-5.097-4.53-5.096-4.53-13.49zm6.195.732q0 5.996 2.765 9.627 2.798 3.631 7.462 3.631 4.697 0 7.461-3.664 2.765-3.698 2.765-10.326 0-5.93-2.831-9.594-2.798-3.697-7.462-3.697-4.563 0-7.361 3.63-2.799 3.631-2.799 10.393z"
          ></path>
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M352.3 505.121q0 8.228-3.764 13.258-3.764 5.03-10.193 5.03-6.563 0-10.327-4.164V536.6h-6.162v-49.9h5.63l.3 3.998q3.763-4.664 10.459-4.664 6.495 0 10.26 4.897 3.797 4.897 3.797 13.624zm-6.163-.7q0-6.095-2.598-9.626-2.598-3.53-7.128-3.53-5.597 0-8.395 4.962v17.222q2.765 4.93 8.461 4.93 4.43 0 7.029-3.498 2.631-3.53 2.631-10.46z"
          ></path>
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M367.523 517.513h22.984v5.23h-29.413v-48.5h6.429z"
          ></path>
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M419.088 522.743q-.533-1.066-.866-3.798-4.297 4.464-10.26 4.464-5.33 0-8.76-2.998-3.398-3.031-3.398-7.661 0-5.63 4.263-8.728 4.297-3.131 12.059-3.131h5.996v-2.831q0-3.232-1.932-5.13-1.932-1.932-5.696-1.932-3.298 0-5.53 1.665-2.232 1.666-2.232 4.03h-6.196q0-2.697 1.9-5.196 1.931-2.531 5.196-3.997 3.297-1.466 7.228-1.466 6.23 0 9.76 3.132 3.531 3.097 3.664 8.56v16.59q0 4.962 1.266 7.894v.533zm-10.227-4.697q2.898 0 5.497-1.499 2.598-1.499 3.764-3.897v-7.395h-4.83q-11.326 0-11.326 6.628 0 2.899 1.932 4.53 1.932 1.633 4.963 1.633z"
          ></path>
          <path
            style={{ lineHeight: "1.25", InkscapeFontSpecification: "Roboto" }}
            d="M464.457 505.121q0 8.261-3.797 13.291-3.798 4.997-10.194 4.997-6.828 0-10.559-4.83l-.3 4.164h-5.663v-51.165h6.163v19.087q3.73-4.63 10.293-4.63 6.562 0 10.293 4.963 3.764 4.963 3.764 13.59zm-6.162-.7q0-6.295-2.432-9.726-2.432-3.43-6.995-3.43-6.096 0-8.761 5.662v15.59q2.831 5.662 8.827 5.662 4.43 0 6.896-3.43 2.465-3.432 2.465-10.327z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
