import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import Message from "../ui/Message";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";
import Sensor from "../map/views/Sensor";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";

function SensorResults({ sensor, open, onClose }) {
  const { lang, locale } = useContext(AppContext);

  const [state, setState] = useMergeState({
    loading: false,
    error: null,
    data: {},
    selected: null
  });

  const [filter, setFilter] = useMergeState({
    date_from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    date_to: moment().add(1, 'days').format('YYYY-MM-DD')
  })

  useEffect(() => {
    if(open && sensor) {
      console.log('CHANGE DATE')
      setState({ loading: true });
      $data.getMeasurements(`?device_name=${sensor.name}&date_from=${filter.date_from}&date_to=${filter.date_to}`)
        .then(data => {
          let groups = {}
          data.map(obj => {
            if (groups[obj.variable]) {
              groups[obj.variable].push({ x: obj.timestamp, y: obj.value })
            } else {
              groups[obj.variable] = [{ x: obj.timestamp, y: obj.value }]
            }
          })

          setState({ loading: false, data: { ...groups }, selected: sensor.variables[0] })
        })
        .catch(err => {
          setState({ loading: false, error: { non_field_errors: "Something went wrong" } })
        })
    }

  }, [filter.date_from, filter.date_to])


  useEffect(() => {
    if (open && sensor) {
      //CALL MEASUREMENTS
      setState({ loading: true });
      $data.getMeasurements(`?device_name=${sensor.name}&date_from=${filter.date_from}&date_to=${filter.date_to}`)
        .then(data => {
          let groups = {}
          data.map(obj => {
            if (groups[obj.variable]) {
              groups[obj.variable].push({ x: obj.timestamp, y: obj.value })
            } else {
              groups[obj.variable] = [{ x: obj.timestamp, y: obj.value }]
            }
          })

          setState({ loading: false, data: { ...groups }, selected: sensor.variables[0] })
        })
        .catch(err => {
          setState({ loading: false, error: { non_field_errors: "Something went wrong" } })
        })
    }

  }, [open])

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const renderContent = () => {
    if (state.loading) return (
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Grid>
    )

    if (state.error) return (
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        {<Typography variant="body2" color="error">{state.error?.non_field_errors}</Typography>}
      </Grid>
    )

    if (!sensor) {
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        {<Typography variant="body2" color="error">Sensor not selected!</Typography>}
      </Grid>
    }

    let sensorVariables = sensor ? sensor.variables : [];
    let defaultVariable = state.selected || sensorVariables[0];

    return <>
      <Grid item xs={12}>
        <Stack direction="row" flexWrap="wrap" justifyContent={'flex-start'}>
          <FormControl sx={{mr: '10px'}} size="small">
            <Select sx={{ width: '100%', maxWidth: '180px', mb: '10px' }} size="small" value={defaultVariable} onChange={(evt) => setState({ selected: evt.target.value })} fullWidth>
              {
                sensorVariables.map((variable, key) => {
                  return <MenuItem key={key} value={variable}>{variable}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
            <MobileDatePicker sx={{mb: '10px'}} maxDate={moment().add(1, 'days')} okText={lang.ok} cancelText={lang.cancel} onClose={() => { setFilter({ date_from: moment(filter.date_from).format('YYYY-MM-DD') }) }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={moment(filter.date_from)} onChange={(newValue) => setFilter({ date_from: newValue.format('YYYY-MM-DD') })}
              renderInput={(params) => (<TextField size="small" className="datepicker-filter" {...params}  style={{marginBottom: '10px', marginRight: '10px'}}  />)} />
          </LocalizationProvider>

          <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
            <MobileDatePicker  maxDate={moment().add(1, 'days')} okText={lang.ok} cancelText={lang.cancel} onClose={() => { setFilter({ date_to: moment(filter.date_to).format('YYYY-MM-DD') }) }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(filter.date_to)} onChange={(newValue) => setFilter({ date_to: newValue.format('YYYY-MM-DD') })}
              renderInput={(params) => (<TextField className="datepicker-filter" {...params} size="small" style={{marginBottom: '10px', marginRight: '10px'}}   />)} />
          </LocalizationProvider>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {Object.keys(state.data).length === 0 && <Typography variant="subtitle2" color="warning">No data for selected query parameters</Typography>}
        {Object.keys(state.data).length > 0 && <Sensor style={{ height: '300px' }} selected={state.selected} data={state.data} />}
      </Grid>
    </>
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{sensor?.name} - {lang.sensor_measurements}<Close onClick={onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent>
        <Container style={{ marginTop: '30px' }}>
          <Grid container spacing={2}>
            {renderContent()}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        {/* <LoadingButton color={props.confirmColor || "primary"} variant="contained" size="small" loading={state.loading} onClick={()}>{props.confirmText || lang.confirm}</LoadingButton> */}
        <LoadingButton color={"error"} variant="contained" size="small" loading={state.loading} onClick={onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SensorResults;