import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";
import { Event } from "@mui/icons-material";
import $activities from "../../../services/$activities";
import Message from "../../ui/Message";
import PlantProtectionTable from "./PlantProtectionTable";
import AgrotechnicalTable from "./AgrotechnicalTable";

function PlantProtectionForm(props) {
	let {lang, locale, operators, machines, connectedMachines} = useContext(AppContext);
	let editModel = props.model ? Object.assign({}, props.model, {/*operations: props.model.agrotechnical_operation, */ protections: props.model.operations, operators: props.model.operators.map(obj => obj.pk)}) : {}

  const [data, setData] = useMergeState({model: Object.assign({}, {
		date: moment().format('YYYY-MM-DD'),
		activity_type: 4,
		protections: [],
		// operations: [],
		price_ha: 0,
		price_parcel: 0,
		fuel_consumption: 0,
		operators: [],
		machine: null,
		conn_machine: null,
		comment: '',
		parcel: props.parcel.pk


	}, {...editModel}), error: {}, success: false, loading: false});

	const {model, error, success, loading} = data;

  useEffect(() => {
		if(props.isJob && !props.forArchive) {
			if(moment(model.date).diff(moment()) <= 0) {
				setData({model: {...model, date: moment().add(1, 'days').format('YYYY-MM-DD')}})
			}
		}
	}, [props.isJob])

	const onChange = (name, val) => {
    let {value, additional} = $activities.getPlantProtectionPrices(name, val, model, props.parcel.area);

		setData({model: {...model, [name]: value, ...additional}});
	}

  const getTotalPrice = () => {
		let product_price_ha = 0;
		let product_price_parcel = 0;



		model.protections.map(obj => {
			product_price_ha = product_price_ha + (isNaN(obj.product_price_ha) ? 0 : parseFloat(obj.product_price_ha))
			product_price_parcel = product_price_parcel + (isNaN(obj.product_price_parcel) ? 0 : parseFloat(obj.product_price_parcel))
		})

		
    return {
      ha: (parseFloat(product_price_ha) + parseFloat(model.price_ha)).toFixed(2),
      parcel: (parseFloat(product_price_parcel) + parseFloat(model.price_parcel)).toFixed(2), 
    }
  }

	const addProtection = (record) => {
		let protections = model.protections.map(obj => Object.assign({}, {...obj}));
		protections.push({...record, natural: record.natural === 'undefined' ? null : record.natural});

		setData({model: {...model, protections}});
	}

	const removeProtection = (uid) => {
		setData({model: {...model, protections: model.protections.filter(obj => (obj.pk ? obj.pk : uid) !== uid)}})
	}

	const addOperation = (record) => {
		let operations = model.operations.map(obj => Object.assign({}, {...obj}));
		operations.push(record);

		setData({model: {...model, operations}});
	}

	const removeOperation = (uid) => {
		setData({model: {...model, operations: model.operations.filter(obj => (obj.pk ? obj.pk : obj.uid) !== uid)}})
	}

	return (
		<Grid className={props.className || ''} component="form" onSubmit={(evt) => {evt.preventDefault(); props.onSubmit(model, true)}} container spacing={2}>
			{(!props.isJob || props.forArchive) && <Grid item xs={12} sm={4}>
				<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
					<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={props.isJob && !props.forArchive ? moment().add(1, 'days') : undefined} onClose={() => {onChange('date', model.date)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.date} value={moment(model.date)} onChange={(newValue) => onChange('date', newValue.format('YYYY-MM-DD'))}
						renderInput={(params) => (
							<TextField fullWidth error={props.error.date} helperText={props.error.date || ''} {...params} size="small"
								InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
						)}
					/>
				</LocalizationProvider>	
			</Grid>}
			{(!props.isJob || props.forArchive) && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>}
			{(!props.isJob || props.forArchive) && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>}

			{/* <Grid item xs={12}>
				<AgrotechnicalTable parcel={props.parcel} addRecord={addOperation} removeRecord={removeOperation} operations={model.operations} />
			</Grid>	 */}
      <Grid item xs={12}>
				<PlantProtectionTable parcel={props.parcel} addRecord={addProtection} removeRecord={removeProtection} protections={model.protections} />	
			</Grid>	
			{/* <Grid item xs={12} sm={4}>
				<Autocomplete size="small" value={model.seed_type} 
					getOptionLabel={(option) => {return option || ''}} 
					onChange={(evt, value) => { onChange('seed_type', value)}} 
					onInputChange={(evt, value) => { onChange('seed_type', value)}}
					freeSolo id="seed_type" 
					disableClearable
					ListboxProps={{id: 'fertigation-product-suggestions'}} 
					options={plantingSeeds.filter(obj => obj.production_subtype.toString() === model.production_subtype.toString()).map((opt, key) => opt.name)} 
					renderInput={(params) => ( <TextField {...params} label={lang.seed_type} placeholder={lang.seed_type_placeholder} InputProps={{ ...params.InputProps, type: 'search', }} /> )} />
			</Grid> */}

			<Grid item xs={12} sm={6} md={3} >
				<TextField error={props.error?.common_activity?.price_ha ? true : false} helperText={props.error?.common_activity?.price_ha || ''} label={lang.price_ha} type="number" fullWidth onChange={(evt) => onChange('price_ha', evt.target.value)} size="small" value={model.price_ha} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField error={props.error?.common_activity?.price_parcel ? true : false} helperText={props.error?.common_activity?.price_parcel || ''} label={lang.price_parcel} type="number" fullWidth onChange={(evt) => onChange('price_parcel', evt.target.value)} size="small" value={model.price_parcel} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'not-allowed', fontWeight: 'bold'} }} focused label={lang.total_price_ha} fullWidth size="small" inputProps={{readOnly: true}} value={(getTotalPrice().ha) || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'not-allowed', fontWeight: 'bold'}}} focused label={lang.total_price_parcel} fullWidth size="small" inputProps={{readOnly: true}} value={(getTotalPrice().parcel)|| ' - '} />
			</Grid>

			<Grid item xs={12} >
				<FormControl size="small" fullWidth>
					<InputLabel id="operators">{lang.operators}</InputLabel>
					<Select
						multiple size="small" labelId="operators" 
						label={lang.operators} value={model.operators} 
						onChange={(evt) => onChange('operators', evt.target.value)}
						renderValue={(selected) => selected.map(id => $data.getOperatorById(operators, id)).join(', ')} fullWidth>
						{operators.map((obj, key) => {
							
							return (
								<MenuItem key={key} value={obj.pk}>
									<Checkbox checked={model.operators.indexOf(obj.pk) > -1} />
									<ListItemText primary={`${obj.first_name} ${obj.last_name}`} />
								</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</Grid>
			
			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.fuel_consumption ? true : false} helperText={props.error?.common_activity?.fuel_consumption || ''} label={lang.fuel_consumption} type="number" fullWidth onChange={(evt) => onChange('fuel_consumption', evt.target.value)} size="small" value={model.fuel_consumption} />
			</Grid>

			<Grid item xs={12} sm={6} >
				<FormControl size="small" error={props.error.machine !== undefined} fullWidth>
					<InputLabel id="machine">{lang.fld_machine}</InputLabel>
					<Select size="small" labelId="machine" label={lang.fld_machine} value={model.machine || ''} onChange={(evt) => onChange('machine', evt.target.value)} fullWidth>
						{machines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.machine && <FormHelperText>{props.error.machine}</FormHelperText>}
				</FormControl>		
			</Grid>
			<Grid item xs={12} sm={6} >
				<FormControl size="small" error={props.error.conn_machine !== undefined} fullWidth>
					<InputLabel id="con_machine">{lang.fld_con_machine}</InputLabel>
					<Select size="small" labelId="con_machine" label={lang.fld_con_machine} value={model.conn_machine || ''} onChange={(evt) => onChange('conn_machine', evt.target.value)} fullWidth>
						{connectedMachines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.conn_machine && <FormHelperText>{props.error.conn_machine}</FormHelperText>}
				</FormControl>		
			</Grid>
			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.comment ? true : false} helperText={props.error?.common_activity?.comment || ''} multiline label={lang.comment} fullWidth size="small" onChange={(evt) => onChange('comment', evt.target.value)}  value={model.comment} />
			</Grid>	

			<Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} item xs={12}>
				{!props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.confirm}</LoadingButton>}
								{props.forArchive && <LoadingButton size="small" color="warning" variant="contained" type="button" onClick={(() => props.onSubmit(model, false))} loading={props.loading}>{lang.act_job_update}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.archive_job}</LoadingButton>}

				{!props.edit && <LoadingButton size="small" onClick={props.onClose} variant="contained" color="error" loading={props.loading} type="button">{lang.close}</LoadingButton>}
			</Grid>	
			<Message type="error" open={props.error.non_field_errors || props.error.file ? true : false} message={props.error.non_field_errors || props.error.file} onClose={props.closeErrorMessage} />

		</Grid>
	)
}

export default PlantProtectionForm;
