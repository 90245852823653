import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import { useContext, useState } from "react";
import Auth from "./Auth";
import Divider from "./Divider";
import { AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";
import $auth from "../../services/$auth";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import $cookies from "../../services/$cookies";


function NewPassword(props) {
  const {lang} = useContext(AppContext);
  const [toLogin, setToLogin] = useState(false);
  const [state, setState] = useMergeState({new_password1: '', new_password2: '', success: null, loading: false, error: {}});
  const {new_password1, new_password2, success, error, loading} = state;

  const onSubmit = (evt) => {
    evt.preventDefault();

    setState({loading: true, error: {}});
    $auth.resetConfirm({new_password1, new_password2, uid: props.uid, token: props.token})
      .then(data => { 
        $cookies.set('reset', 'true');
        setToLogin(true);
      })
      .catch(err => {
        if(err.uid || err.token) {
          $cookies.set('reset', 'false');
          setToLogin(true);
        } else {
          setState({loading: false, error: err})
        }
      })
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}});
  }

  if(toLogin) return <Redirect to="/login" />;

  return (
    <Auth>
       <Box style={{padding: '5px 5px'}}>
        <h1 style={{textAlign: 'center'}}>{lang.new_password}</h1>
        <Divider />
        <Link to="/login" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none'}}><ArrowBack />&nbsp;{lang.go_back}&nbsp;</Link>
      </Box>

      <Box style={{padding: '5px'}} component='form' sx={{'& .MuiTextField-root, & .MuiButton-root': {mb: '7px', mt: '7px' }}} onSubmit={onSubmit}>
      <div>
          <TextField size="small" error={error.new_password1 !== undefined || error.non_field_errors !== undefined} helperText={error.new_password1} fullWidth label={lang.password}type='password' value={new_password1} onChange={evt => setState({new_password1: evt.target.value})} />
        </div>

        <div>
          <TextField size="small" error={error.new_password2 !== undefined || error.non_field_errors !== undefined} helperText={error.new_password2} fullWidth label={lang.repeat} type='password' value={new_password2} onChange={evt => setState({new_password2: evt.target.value})} />
        </div>

        <div>
          <LoadingButton loading={loading} fullWidth variant="contained" type="submit">{lang.confirm}</LoadingButton>
        </div>

      </Box>

      <Message type="success" open={success ? true : false} message={success} onClose={() => setState({success: null})} />
      <Message type="error" open={error.non_field_errors !== undefined} message={error.non_field_errors} onClose={() => onCloseMessage()} />
      <Message type="error" open={error.uid !== undefined} message={error.uid} onClose={() => onCloseMessage()} />
      <Message type="error" open={error.token !== undefined} message={error.token} onClose={() => onCloseMessage()} />

    </Auth>
  )
}

export default NewPassword;