import {Grid, TextField } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";

function NoteView(props) {
	let {lang} = useContext(AppContext);
	let {data} = props;


	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={4}>
				<TextField focused label={lang.date} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={moment(data.date).format('DD-MMM-YYYY') || ' - '} />
			</Grid>
			<Grid item xs={12} sm={4} style={{padding: 0}}></Grid>	
			<Grid item xs={12} sm={4} style={{padding: 0}}></Grid>
			<Grid item xs={12}>
				<TextField focused multiline label={lang.comment} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.comment || ' - '} />
			</Grid>	
		</Grid>
	)
}

export default NoteView;