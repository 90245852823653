export default [
  'No-till',
  'Reduced or minimum tillage',
  'Cover crops',
  'Crop rotation',
  'Mulching',
  'Agroforestry and silvopasture',
  'Hedgerows, windbreaks, flower strips, shrubs',
  'Composting',
  'Biochar',
  'Holistically managed grazing',
  'Animal integration into crop production',
  'Perennial cropping',
  'Inter-cropping',
  'Natural fertilizer',
  'Integrated Pest Management',
  'Precision farming',
  'Regenerative Livestock Management',
  'Irrigation Efficiency'
]

export const practicesEN = [
  'No-till',
  'Reduced or minimum tillage',
  'Cover crops',
  'Crop rotation',
  'Mulching',
  'Agroforestry and silvopasture',
  'Hedgerows, windbreaks, flower strips, shrubs',
  'Composting',
  'Biochar',
  'Holistically managed grazing',
  'Animal integration into crop production',
  'Perennial cropping',
  'Inter-cropping',
  'Natural fertilizer',
  'Integrated Pest Management',
  'Precision farming',
  'Regenerative Livestock Management',
  'Irrigation Efficiency'
];

// - Inter-cropping
// - Natural fertilizer
// - Integrated Pest Management 
// - Precision farming
// - Regenerative Livestock Management

export const practicesRS = [
  'Bez obrade zemljišta',
  'Smanjena ili minimalna obrada zemljišta',
  'Pokrovni usevi',
  'Plodored',
  'Malčiranje',
  'Agrošumarstvo i šumski pašnjaci',
  'Žive ograde, vetrobrani, cvetni pojasevi, žbunje',
  'Kompostiranje',
  'Biočar',
  'Holistički vođena ispaša',
  'Uključivanje životinja u biljnu proizvodnju',
  'Višegodišnji usevi',
  'Međukulture',
  'Prirodno đubrivo',
  'Integrisano upravljanje štetočinama',
  'Precizna poljoprivreda',
  'Regenerativno upravljanje stočarstvom',
  'Efikasno navodnjavanje'
];

export const detectAndMap = (practice, locale) => {
  if(locale === 'sr') {
    let index = practicesRS.indexOf(practice);

    if(index === -1) {
      index = practicesEN.indexOf(practice);
      return practicesRS[index];
    } else {
      return practice
    }

  }

  if(locale === 'en') {
    let index = practicesEN.indexOf(practice);

    if(index === -1) {
      index = practicesRS.indexOf(practice);
      return practicesEN[index];
    } else {
      return practice
    }

  }

  return practice;


}