import { MuiTelInput, isValidPhoneNumber } from "mui-tel-input";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import useMergeState from "../hooks/useMergeState";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Message from "../ui/Message";
import { getCountryPhoneCode } from "../../config/countries";
import ReactCodeInput from "react-code-input";
import { Check, Close } from "@mui/icons-material";
import $auth from "../../services/$auth";

function PhoneVerification(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {lang, onUpdateState} = useContext(AppContext);

  const [state, setState] = useMergeState({success: null, loading: false, requestSent: false, phone: props.phone || '', phone_verified: props.phone_verified, code: ''});
  const [error, setError] = useMergeState({});

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if(!props.open) {
      setState({session_token: null, loading: false, requestSent: false, phone: props.phone || '', phone_verified: props.phone_verified, code: ''})
    }

    if(props.open) {
      setState({phone: props.phone || '', phone_verified: props.phone_verified, code: ''});
      setIsValid(isValidPhoneNumber(props.phone || ''))
    }
  }, [props.open])

  const handleChange = (val) => {
    setIsValid(isValidPhoneNumber(val));

    setState({phone: val});
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setError({});
    setState({success: ''})
  }

  const submitRequest = () => {
    setState({loading: true});
    $auth.registerPhone(state.phone.split(' ').join(''))
      .then((data) => {
        setState({success: lang.request_sms_sent, requestSent: true, loading: false, session_token: data.session_token});
        $auth.getUser()
          .then(data => { onUpdateState({user: data}); }) 
          .catch(err => { setError({...err}) })

      })
      .catch(err => {
        setState({loading: false});
        setError(err.message)
      })
  }
  
  const submitCode = () => {
    setState({loading: true});
    if(state.session_token && state.code.length === 6) {
      $auth.verifySMSCode(state.phone.split(' ').join(''), state.session_token, state.code)
        .then(data => {
          $auth.getUser()
            .then(data => {
              onUpdateState({user: data});
              props.onClose(null, null, lang.phone_verification_success);
            })
            .catch(err => {
              setError({...err})
            })
          
        })
        .catch(err => {
          setState({loading: false})
          setError(err.message)
        })
    }
  }


  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.sms_verification}</DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              {!state.requestSent && <Typography variant="subtitle2" color="primary">{lang.sms_verification_description}</Typography>}
              {state.requestSent && <Typography variant="subtitle2" color="primary">{lang.sms_code_description}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Box autoComplete="off" style={{padding: '5px'}} component='form' sx={{'& .MuiTextField-root, & .MuiButton-root, & .MuiFormControl-root': {mb: '7px', mt: '7px' }}} onSubmit={() => {}}>
        
                {!state.requestSent && <div>
                  <MuiTelInput placeholder={`+${381} XX XXX XXX X`} error={!isValid} size="small"  defaultCountry={getCountryPhoneCode(props.country, lang)} value={state.phone} onChange={handleChange} InputProps={{endAdornment: <InputAdornment position="end">{isValid ? <Check color="success" /> : <Close color="error" />}</InputAdornment>}} />
                </div>}

                <div>
                  {state.requestSent && <ReactCodeInput type="text" fields={6} value={state.code} onChange={(val) => setState({code: val})} />}
                </div>

                <div>
               
                </div>

            
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        {!state.requestSent && <LoadingButton disabled={!isValid} color="primary" variant="contained" size="small" loading={state.loading} onClick={submitRequest}>{lang.request_sms}</LoadingButton>}
        {state.requestSent && <LoadingButton disabled={state.code.length !== 6 || !state.session_token} color="primary" variant="contained" size="small" loading={state.loading} onClick={submitCode}>{lang.verify_sms}</LoadingButton>}
        {/* <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton> */}
      </DialogActions>

      <Message type="error" open={Object.keys(error).length > 0 ? true : false} message={error.non_field_errors} onClose={onCloseMessage} />
      <Message type="success" open={state.success} message={state.success} onClose={onCloseMessage} />

    </Dialog>
  )
}

export default PhoneVerification;