import { Autocomplete, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import colors from "../../config/colors";
import { Add, Close, Download, Event } from "@mui/icons-material";
import ReactDOMServer from 'react-dom/server';
import { LoadingButton, LocalizationProvider, MobileDatePicker, PickersDay } from "@mui/lab";
import FieldItem from "./FieldItem";
import Divider from "../auth/Divider";
import practices, { detectAndMap } from "../../config/practices";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import Message from "../ui/Message";

function EditRegenerativePractice(props) {
  const { lang, locale } = useContext(AppContext);
  const localizedPractices = practices.map(p => detectAndMap(p, locale));

  const containerElement = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({})
  const [model, setModel] = useState({
    ...props.model
  })

  const onClose = (evt, reason) => {
    if(loading) {
      return
    } else {
      props.onClose()
    }
  }

  const onSubmit = () => {
    setLoading(true);
    $data.patchPractice(props.parcel, model)
      .then(data => {
        setLoading(current => {
          return false;
        });

        props.onSuccess();
        onClose();

      })
      .catch(err => {
        setLoading(false);
        setError(err)
      })
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={props.open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.act_edit_practices}<Close onClick={onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent ref={containerElement} >
        <Grid sx={{ mt: '20px' }} container spacing={2}>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              options={localizedPractices}
              onChange={(evt, value, reason) => {
                setModel(current => ({...current, reg_practice: value}))
              }}
              
              value={model.reg_practice}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip size="small" variant="filled" color="warning" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => {
                return <TextField label={lang.act_reg_practice} {...params} size="small" variant="outlined" />
              }} />
          </Grid>
          <Grid item xs={12} sm={6}>
          <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
            <MobileDatePicker views={['year']} toolbarTitle={lang.year} disable okText={lang.ok} cancelText={lang.cancel} maxDate={moment()} onClose={() => {}} inputFormat="YYYY" DialogProps={{className: 'datepicker-job-from'}} label={lang.year} value={moment(model.year.toString())} onChange={(newValue) => setModel(current => ({...current, year: newValue.format('YYYY')}))}
									renderDay={(day, selected, props) => {
  
                    return (
                      <PickersDay day={day} {...props} />
                    )
                  }}
                  renderInput={(params) => (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>            
              </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField multiline label={lang.comment} fullWidth  size="small" value={model.note} onChange={(evt) => setModel(current => ({...current, note: evt.target.value}))} />
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
      <LoadingButton loading={loading} endIcon={<Close />} color="error" variant="contained" size="small" onClick={onClose}>{lang.close}</LoadingButton>
      <LoadingButton loading={loading}  color="primary" variant="contained" size="small" onClick={onSubmit}>{lang.confirm}</LoadingButton>

      </DialogActions>
      <Message type="error" open={Boolean(error.non_field_errors)} message={error.non_field_errors} onClose={() => setError({error: {}})} />

    </Dialog>
  )
}

export default EditRegenerativePractice;