import { Cancel, Close, Event } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
import { blue } from "@mui/material/colors";
import $seasons from "../../../services/$seasons";
import $data from "../../../services/$data";
import Message from "../../ui/Message";
import colors from "../../../config/colors";
import $map from "../../../services/$map";
import DrawFieldMap from "./DrawFieldMap";

function DeleteField(props) {
	const {lang, cropTypes, user, parcels, onUpdateState} = useContext(AppContext);

	const [state, setState] = useMergeState({ error: {}, loading: false,});


	const deleteParcel = () => {
    setState({loading: true});
		$data.deleteParcel(props.parcel?.pk)
			.then(data => {
        setState({loading: true});

				props.onSuccess();
			})
			.catch(err => {
        
				setState({loading: false, error: {non_field_errors: lang.error_server}})
			})
	}


	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}, success: null});
  }
	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.delete_field_title}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px', padding: '10px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
              <p className="text-primary">{lang.delete_field_question}</p>
						</Grid>
            
					
          </Grid>
        </Container>
      </DialogContent>
			<DialogActions>
				<LoadingButton onClick={deleteParcel} size="small" loading={state.loading} variant="contained" color="error">{lang.delete}</LoadingButton>
				<LoadingButton onClick={props.onClose} size="small" loading={state.loading} variant="outlined" color="primary">{lang.cancel}</LoadingButton>
			</DialogActions>
			<Message type="error" open={state.error.non_field_errors !== undefined} message={state.error.non_field_errors} onClose={() => onCloseMessage()} />
    </Dialog>
	)
}

export default DeleteField;