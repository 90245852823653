import { Comment, Grain, Grass, LocalShipping, Science, VolunteerActivism } from "@mui/icons-material";
import { Badge, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Checkbox, Chip, CircularProgress, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import Divider from "../auth/Divider";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";

const activityIcons = () => ({
  1: <Grain fontSize="large" />,
  2: <Science fontSize="large" />,
  3: <Grass fontSize="large" />,
  4: <VolunteerActivism fontSize="large" />,
  5: <LocalShipping fontSize="large" />,
  6: <Comment fontSize="large" />,
});

function JobOrders(props) {
  const {lang, activityTypes, activities, onUpdateState} = useContext(AppContext);

  const [state, setState] = useMergeState({jobs: [] });

  const [filter, setFilter] = useMergeState({parcel: [], type: [], status: ''});

  useEffect(() => {
    onUpdateState({view: 'job-orders'});

    // $data.getActivities({export_format: 'json'})
    //   .then(data => {
    //     setState({jobs: data.jobs, loading: false});
    //   })
    //   .catch(err => {console.log(err)})

  }, []);

  const filterTypes = (evt) => {
    let {value} = evt.target;

    if(value.constructor === String) {
      setFilter({type: value.split(',')});
    }

    if(value.constructor === Array) {
      setFilter({type: value.indexOf('all') > -1 ? [] : value});
    }
  }

  const filterParcels = (evt) => {
    let {value} = evt.target;

    if(value.constructor === String) {
      setFilter({parcel: value.split(',')});
    }

    if(value.constructor === Array) {
      setFilter({parcel: value.indexOf('all') > -1 ? [] : value});
    }
  }


  const renderJobs = () => {
    if(activities.jobs.length === 0) {
      return <Grid item sx={{textAlign: 'center'}} xs={12}>{lang.act_no_jobs}</Grid>
    }

    let filtered = activities.jobs
      .filter(obj => {
        if(filter.type.length > 0) { return filter.type.indexOf(obj.activity_type) > -1; }
        return true
      })
      .filter(obj => {
        if(filter.parcel.length > 0) { return filter.parcel.indexOf(obj.parcel) > -1; }
        return true
      })
      // .filter(obj => {
      //   if(filter.status === true) return obj.activity_status === true;
      //   if(filter.status === false) return obj.activity_status === false;

      //   return true
      // });
    
    if(filtered.length === 0) {
      return <Grid item sx={{textAlign: 'center'}} xs={12}>{lang.act_no_jobs_filter}</Grid>

    }



    return filtered.map((obj, key) => {
      return (
        <Grid sx={{textAlign: 'center', mb: '15px'}}  key={key} item xs={12} sm={6} lg={4}>
          {/* <Stack> */}

            <Typography variant="h6" color="primary">{lang.parcel_name_full}: <strong>{obj.parcel_name}</strong></Typography>
            {renderItem(obj)}
          {/* </Stack> */}
        </Grid>
      )
    })
  }

  const renderItem = (job) => {
    return (
      <Link style={{textDecoration: 'none'}} to={`/dashboard/job-orders/${job.common_activity.id}`}>
        <Card sx={{margin: '0 auto', maxWidth: 345, textAlign: 'left'}}>

          <CardActionArea>
            <CardContent >
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} gutterBottom color="primary" variant="h6" component="div">
                {activityIcons()[job.activity_type]} &nbsp; {$data.getActivityNameById(activityTypes, job.activity_type)}
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Typography spacing={2} sx={{'& .from-to': {mb: '10px'}, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '10px'}} variant="body2" color="text.secondary">
                <span className="from-to">{lang.from}: <strong>{moment(job.common_activity.jo_from).format('DD-MMM-YYYY')}</strong></span> 
                <span className="from-to">{lang.to}: <strong>{moment(job.common_activity.jo_to).format('DD-MMM-YYYY')}</strong></span> 
                <Chip component="span" style={{cursor: 'pointer', maxWidth: '120px'}} color={job.activity_status ? 'success' : 'warning'} label={job.activity_status ? lang.act_job_completed : lang.act_job_active} /> 
              </Typography>
            </Stack>

            </CardContent>
            <CardActions>
              <Typography variant="body2" color="primary" sx={{textTransform: 'uppercase'}}>{lang.click_job}</Typography> 
            </CardActions>
          </CardActionArea>
        
        </Card>
      </Link>
    );
  }

  const renderFilters = () => {
    if(activities.jobs.length === 0) { return null }

    let parcelsAll = activities.jobs
      .map(obj => ({value: obj.parcel, label: obj.parcel_name}))
      

    let parcels = _.uniqBy(parcelsAll, 'value');

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel id="filtertype1">{lang.fld_filter_by_activity}</InputLabel>
              <Tooltip placement="top" title={filter.type.map(id => $data.getActivityNameById(activityTypes, id).toUpperCase()).join(', ')}>
                <Select 
                  multiple size="small" labelId="filtertype1" 
                  label={lang.fld_filter_by_activity} value={filter.type} 
                  onChange={filterTypes}
                  renderValue={(selected) => selected.map(id => $data.getActivityNameById(activityTypes, id).toUpperCase()).join(', ')} fullWidth>
                    <MenuItem value={'all'}>
                        <Checkbox checked={filter.type.length === 0} />
                        <ListItemText primary={lang.fld_select_all_types} />
                      </MenuItem>
                    {activityTypes.map((obj, key) => (
                      <MenuItem key={key} value={obj.id}>
                        <Checkbox checked={filter.type.indexOf(obj.id) > -1} />
                        <ListItemText primary={$data.getActivityNameById(activityTypes, obj.id)} />
                      </MenuItem>))}
                  </Select>
                </Tooltip>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel id="filtertype2">{lang.fld_filter_by_parcel}</InputLabel>
              <Tooltip placement="top" title={filter.parcel.map(id => parcels.filter(obj => obj.value === id).map(obj => obj.label)).join(', ')}>
                <Select 
                  multiple size="small" labelId="filtertype2" 
                  label={lang.fld_filter_by_parcel} value={filter.parcel} 
                  onChange={filterParcels}
                  renderValue={(selected) => selected.map(id => parcels.filter(obj => obj.value === id).map(obj => obj.label)).join(', ')} fullWidth>
                    <MenuItem value={'all'}>
                        <Checkbox checked={filter.parcel.length === 0} />
                        <ListItemText primary={lang.fld_select_all_types} />
                      </MenuItem>
                    {parcels.map((obj, key) => (
                      <MenuItem key={key} value={obj.value}>
                        <Checkbox checked={filter.parcel.indexOf(obj.value) > -1} />
                        <ListItemText primary={obj.label} />
                      </MenuItem>))}
                  </Select>
                </Tooltip>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={4} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel id="filtertype3">{lang.fld_filter_by_status}</InputLabel>
              <Select 
                size="small" labelId="filtertype3" 
                label={lang.fld_filter_by_status} value={filter.status} 
                onChange={(evt) => {setFilter({status: evt.target.value})}}
                fullWidth>
                  <MenuItem value={''}>{lang.act_jobs_all}</MenuItem>
                  <MenuItem value={true}>{lang.act_job_completed}</MenuItem>
                  <MenuItem value={false}>{lang.act_job_active}</MenuItem>
                  
                </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <Divider style={{width: '100%', marginTop: '10px'}} />
      </Grid>
    )
  }

  return (
    <Container sx={{mt: '20px'}}>
      <Grid container>
      {renderFilters()}

      {renderJobs()}
      </Grid>

      <Message type="error" open={state.error?.non_field_errors} message={state.error?.non_field_errors} onClose={() => setState({error: {}})} />
    </Container>
  )
}

export default JobOrders;