import { Close, PendingActions, PendingActionsRounded, QuestionMarkOutlined, VerifiedRounded } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Checkbox, CircularProgress, Drawer, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, LinearProgress, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import translation, { getTranslation } from "../../config/locale/translation";
import $auth from "../../services/$auth";
import $cookies from "../../services/$cookies";
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";
import ConfirmDialog from "../ui/ConfirmDialog";
import Message from "../ui/Message";
import AdditionalForm from "./AdditionalForm";
import EmployeeForm from "./EmployeeForm";
import PhoneVerification from "./PhoneVerification";
import UserPhoto from "./UserPhoto";

function Account(props) {
  const {locale, lang, user, machines, connectedMachines, onUpdateState, onUpdateCodeLists, onUpdateMachines, onUpdateConnectedMachines} = useContext(AppContext);

  const [page, setPage] = useState(0);

  const [pageM, setPageM] = useState(0);

  const [pageCM, setPageCM] = useState(0);

  const [userModel, setUserModel] = useState(user || {});
  const [passwordModel, setPasswordModel] = useState({});

  const [userError, setUserError] = useState({});
  const [passwordError, setPasswordError] = useState({});

  const [success, setSuccess] = useState(null);

  const [form, setForm] = useMergeState({type: 'add', drawer: false});
  const [model, setModel] = useState({});
  
  const [additional, setAdditional] = useMergeState({model: null, type: null, role: null});

  const [phoneModal, setPhoneModal] = useState(false);

  const [deactivateModal, setDeactivateModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [loading, setLoading] = useState({profile: false, password: false, deactivate: false, delete: false});

  const onSetLoading = (name, bool) => {
    setLoading({...loading, [name]: bool})
  } 

  const onChangeUserModel = (name, value) => {
    setUserModel({...userModel, [name]: value});
  }

  const onChangePasswordModel = (name, value) => {
    setPasswordModel({...passwordModel, [name]: value});
  }

  const onHandlePageChange = (evt, pg) => {
    props.getEmployees(pg);
    setPage(pg)
  }

  const onHandleSettings = (name, checked) => {
    $data.putNotificationSettings(Object.assign({}, {...props.settings, [name]: checked}))
      .then(data => props.getNotificationSettings())
      .catch(err => {})
  }

  const onHandleProfile = () => {
    onSetLoading('profile', true);
    setUserError({});

    $auth.updateProfile(userModel)
      .then(data => {
        if(data.language !== locale) {
          onUpdateCodeLists();
        }

        onUpdateState({user: data, locale: data.language});
        onSetLoading('profile', false);
        setSuccess(getTranslation(data.language).success_update_profile);
      })
      .catch(err => {setUserError(err);  onSetLoading('profile', false);})
  }

  const onHandlePassword = () => {
    onSetLoading('password', true);
    setPasswordError({});

    $auth.changePassword(passwordModel)
      .then(data => {
        setSuccess(data.detail);
        setPasswordModel({})
        onSetLoading('password', false)
      })
      .catch(err => { setPasswordError(err); onSetLoading('password', false);})
  }

  const onCloseMessage = (modelName) => {
    if(modelName === 'profile') setUserError({});
    if(modelName === 'password') setPasswordError({});
  }

  const onDeactivateAccount = () => {
    setLoading({deactivate: true});
    $auth.deactivateAccount({email: user.email})
      .then(data => {
        setLoading({deactivate: false});
        $cookies.set('deactivated', 'true')
        $auth.unauthorize();
        window.location.reload();
      })
      .catch(err => {
        setLoading({deactivate: false});
      })
  }

  const onDeleteAccount = () => {
    setLoading({delete: true});
    $auth.deleteAccount({email: user.email})
      .then(data => {
        setLoading({delete: false});
        $cookies.set('deleted', 'true')
        $auth.unauthorize();
        window.location.reload();
      })
      .catch(err => {
        setLoading({delete: false});
      })
  }

  const renderEmptyTable = (message, cols = 6) => {
    return (
      <TableRow>
        <TableCell colSpan={cols}>{message}</TableCell>
      </TableRow>
    )
  }
  
  const renderEmployees = (data) => {
    if(!data) return renderEmptyTable(lang.no_employees, 6);

    if(!data.results) return renderEmptyTable(lang.no_employees, 6);

    if(data.results.length === 0) return renderEmptyTable(lang.no_employees, 6);

    return data.results.map((employee, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{`${employee.first_name} ${employee.last_name}`}</TableCell>
          <TableCell>{employee.additional_user_email}</TableCell>
          <TableCell>{employee.phone || ' - '}</TableCell>
          {/* <TableCell>{employee.isManager ? lang.manager.toUpperCase() : lang.operator.toUpperCase()}</TableCell> */}
          <TableCell>{employee.isManager ? lang.fieldbook_role.toUpperCase() : lang.job_orders_role.toUpperCase()}</TableCell>
          <TableCell>
            <Tooltip arrow title={lang.edit_employee}>
              <Edit style={{cursor: 'pointer'}} onClick={() => {setModel({...employee}); setForm({type: 'edit', drawer: true})}} color="warning" />
            </Tooltip>
            <Tooltip arrow title={lang.delete_employee}>
              <Delete style={{cursor: 'pointer'}} onClick={() => {setModel({...employee}); setForm({type: 'delete', drawer: true})}} color="error" />
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  
  }

  const renderMachines = (data) => {
    if(!data) return renderEmptyTable(lang.no_machines, 3);

    if(!data.results) return renderEmptyTable(lang.no_machines, 3);

    if(data.results.length === 0) return renderEmptyTable(lang.no_machines, 3);

    return data.results
      .filter((machine, key) => {
        if(key < pageM * 10 + 10 && key >= pageM * 10) {
          return true
        }

        return false
      })
      .map((machine, key) => {
        return (
          <TableRow key={key}>
            <TableCell>{(key + 1) + pageM * 10}</TableCell>
            <TableCell>{machine.name}</TableCell>
            <TableCell>
              <Tooltip arrow title={lang.fld_edit_machine}>
                <Edit style={{cursor: 'pointer'}} onClick={() => {setAdditional({model: machine, type: 'edit', role: 'machine'})}} color="warning" />
              </Tooltip>
              <Tooltip arrow title={lang.fld_delete_machine}>
                <Delete style={{cursor: 'pointer'}} onClick={() => {setAdditional({model: machine, type: 'delete', role: 'machine'})}} color="error" />
              </Tooltip>
            </TableCell>
          </TableRow>
        )
      })
  }

  const renderConMachines = (data) => {
    if(!data) return renderEmptyTable(lang.no_conn_machines, 3);

    if(!data.results) return renderEmptyTable(lang.no_conn_machines, 3);

    if(data.results.length === 0) return renderEmptyTable(lang.no_conn_machines, 3);

    return data.results
    .filter((machine, key) => {
      if(key < pageCM * 10 + 10 && key >= pageCM * 10) {
        return true
      }

      return false
    })
    .map((machine, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{(key + 1) + pageCM * 10}</TableCell>
          <TableCell>{machine.name}</TableCell>
          <TableCell>
            <Tooltip arrow title={lang.fld_edit_con_machine}>
              <Edit style={{cursor: 'pointer'}} onClick={() => {setAdditional({model: machine, type: 'edit', role: 'conMachine'})}} color="warning" />
            </Tooltip>
            <Tooltip arrow title={lang.fld_delete_con_machine}>
              <Delete style={{cursor: 'pointer'}} onClick={() => {setAdditional({model: machine, type: 'delete', role: 'conMachine'})}} color="error" />
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Grid className="account-container" container spacing={2}>
      <Grid item xs={12} md={6}>
        <p className="account-item-title">{lang.personal_info}</p>
        <Paper component={'form'} onSubmit={(evt) => {evt.preventDefault(); onHandleProfile();}} className="account-item-box" elevation={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <UserPhoto image={userModel.image} onSetImage={(file) => onChangeUserModel('image', file)} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField size="small" error={userError.first_name !== undefined || userError.non_field_errors !== undefined} helperText={userError.first_name} fullWidth label={lang.first_name} type='text' value={userModel.first_name} onChange={(evt) => {onChangeUserModel('first_name', evt.target.value)}} />
                </Grid>
                <Grid item xs={12}>
                  <TextField size="small" error={userError.last_name !== undefined || userError.non_field_errors !== undefined} helperText={userError.last_name} fullWidth label={lang.last_name} type='text' value={userModel.last_name} onChange={(evt) => {onChangeUserModel('last_name', evt.target.value)}} />
                </Grid>

                <Grid item xs={12}>
              <FormControl size="small" error={userError.country !== undefined || userError.non_field_errors !== undefined} fullWidth>
                <InputLabel id="country">{lang.country}</InputLabel>
                <Select size="small" labelId="country" label={lang.country} value={userModel.country} onChange={(evt) => onChangeUserModel('country', evt.target.value)} fullWidth>
                  <MenuItem value={1}>{lang.uk}</MenuItem>
                  <MenuItem value={2}>{lang.greece}</MenuItem>
                  <MenuItem value={3}>{lang.poland}</MenuItem>
                  <MenuItem value={4}>{lang.serbia}</MenuItem>
                  <MenuItem value={5}>{lang.portugal}</MenuItem>
                </Select>
                {userError.country && <FormHelperText>{userError.country}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl error={userError.language !== undefined || userError.non_field_errors !== undefined} fullWidth>
                <InputLabel id="language">{lang.language}</InputLabel>
                <Select size="small" labelId="language" label={lang.language} value={userModel.language} onChange={(evt) => {onChangeUserModel('language', evt.target.value)}} fullWidth>
                  <MenuItem value="sr">{lang.serbian}</MenuItem>
                  <MenuItem value="en">{lang.english}</MenuItem>
                </Select>
                {userError.language !== undefined || userError.non_field_errors !== undefined && <FormHelperText>{userError.language}</FormHelperText>}
              </FormControl>
            </Grid>
                </Grid>
            </Grid>
            
          

            {/* <Grid item xs={12} md={6}>
              <TextField size="small" error={userError.contact !== undefined || userError.non_field_errors !== undefined} helperText={userError.contact} fullWidth label={lang.contact} type='text' value={userModel.phone || ''} onChange={(evt) => {onChangeUserModel('phone', evt.target.value)}} />
            </Grid> */}


            {/* PHONE FIELD NULL */}
            {!user.phone && <Grid item xs={12}>
              <TextField error={userError.phone ? true : false} type="text" focused={false} label={lang.phone} helperText={'Register and verify your phone number'} InputProps={{
                endAdornment: <InputAdornment onClick={() => {setPhoneModal(true)}} style={{cursor: 'pointer'}} position="end"> <Button size="small">{lang.btn_verify}</Button> <QuestionMarkOutlined /></InputAdornment>
              }} fullWidth size="small" readOnly value={user.phone || lang.phone_not_verified} />
            </Grid>}

             {/* PHONE FIELD NUMBER - NOT VERIFIED */}
             {user.phone && !user.phone_verified && <Grid item xs={12}>
              <TextField type="text" focused={false} label={lang.phone} helperText={'Please verify your phone number (SMS)'} InputProps={{
                endAdornment: <InputAdornment onClick={() => {setPhoneModal(true)}} style={{cursor: 'pointer'}} position="end"> <Button size="small">{lang.btn_verify}</Button> <Close color="error" /></InputAdornment>
              }} fullWidth size="small" readOnly value={user.phone || lang.phone_not_verified} />
            </Grid>}

             {/* PHONE FIELD NUMBER - VERIFIED */}
             {user.phone && user.phone_verified && <Grid item xs={12}>
              <TextField type="text" focused={false} label={lang.phone} InputProps={{
                endAdornment: <InputAdornment onClick={() => {setPhoneModal(true)}} style={{cursor: 'pointer'}} position="end">  <Button size="small">{lang.btn_edit}</Button> <VerifiedRounded color="success" /></InputAdornment>
              }} fullWidth size="small" readOnly value={user.phone || lang.phone_not_verified} />
            </Grid>}



            {!user.isOperater && <Grid item xs={12}>
              <FormControl style={{padding: '0 14px'}}>
                <FormLabel id="demo-radio-buttons-group-label">{lang.currency}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="eur"
                  name="radio-buttons-group">
                  <FormControlLabel value="eur" control={<Radio onChange={(evt) => {onChangeUserModel('currency', 'EUR')}} checked={userModel.currency === 'EUR'} />} label="EUR" />
                  <FormControlLabel value="rsd" control={<Radio onChange={(evt) => {onChangeUserModel('currency', 'RSD')}} checked={userModel.currency === 'RSD'} />} label="RSD" />
                  <FormControlLabel value="pln" control={<Radio onChange={(evt) => {onChangeUserModel('currency', 'PLN')}} checked={userModel.currency === 'PLN'} />} label="PLN" />
                  <FormControlLabel value="gbp" control={<Radio onChange={(evt) => {onChangeUserModel('currency', 'GBP')}} checked={userModel.currency === 'GBP'} />} label="GBP" />
                  <FormControlLabel value="usd" control={<Radio onChange={(evt) => {onChangeUserModel('currency', 'USD')}} checked={userModel.currency === 'USD'} />} label="USD" />
                </RadioGroup>
              </FormControl>
            </Grid>}

            <Grid item xs={12}>
              <LoadingButton loading={loading.profile} fullWidth variant="contained" type="submit">{lang.confirm}</LoadingButton>
            </Grid>
            
          </Grid>        
        </Paper>
      </Grid>
     
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="account-item-title">{lang.change_password}</p>
            <Paper autoComplete="off" component={'form'} onSubmit={(evt) => {evt.preventDefault(); onHandlePassword();}} className="account-item-box" elevation={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField size="small" error={passwordError.old_password !== undefined || passwordError.non_field_errors !== undefined} helperText={passwordError.old_password} autoComplete="new-password" fullWidth label={lang.old_password} type='password' value={passwordModel.old_password || ''} onChange={(evt) => {onChangePasswordModel('old_password', evt.target.value)}} />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField size="small" error={passwordError.new_password1 !== undefined || passwordError.non_field_errors !== undefined} helperText={passwordError.new_password1} autoComplete="new-password" fullWidth label={lang.new_password} type='password' value={passwordModel.new_password1 || ''} onChange={(evt) => {onChangePasswordModel('new_password1', evt.target.value)}} />
                </Grid>

                <Grid item xs={12}>
                  <TextField size="small" error={passwordError.new_password2 !== undefined || passwordError.non_field_errors !== undefined} helperText={passwordError.new_password2} autoComplete="new-password" fullWidth label={lang.repeat_new_password} type='password' value={passwordModel.new_password2 || ''} onChange={(evt) => {onChangePasswordModel('new_password2', evt.target.value)}} />
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton loading={loading.password} fullWidth variant="contained" type="submit">{lang.confirm}</LoadingButton>
                </Grid>  
              </Grid>        
            </Paper>
          </Grid>
          <Grid item xs={12}>
           <p className="account-item-title">{lang.account_cancelation}</p>
            <Paper autoComplete="off" component={'form'} onSubmit={(evt) => {evt.preventDefault(); onHandlePassword();}} className="account-item-box" elevation={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className="text-warning">{lang.deactivation_note}</p>
                  <Button onClick={() => setDeactivateModal(true)} fullWidth variant="contained" size="small" color="warning">{lang.deactivate_account}</Button>
                </Grid>
                
                <Grid item xs={12}>
                  <p className="text-danger">{lang.deletion_note}</p>
                  <Button onClick={() => setDeleteModal(true)} fullWidth variant="contained" size="small" color="error">{lang.delete_account}</Button>
                </Grid>

                <Grid item xs={12}>
                </Grid>  
              </Grid>        
            </Paper>
          </Grid>
        </Grid>
        
      </Grid>

      <Grid item xs={12}>
        <p className="account-item-title">{lang.notifications}</p>
        <Paper className="account-item-box" elevation={4}>
          <p>{lang.notification_settings}</p>
          {props.loading.settings && <CircularProgress />}
          {!props.loading.settings && <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(evt) => onHandleSettings('s2_mail', evt.target.checked)} checked={props.settings.s2_mail} />} label={lang.email} />
            <FormControlLabel control={<Checkbox onChange={(evt) => onHandleSettings('s2_notification', evt.target.checked)} checked={props.settings.s2_notification} />} label={lang.in_app} />
            <FormControlLabel control={<Checkbox onChange={(evt) => onHandleSettings('sms', evt.target.checked)} checked={props.settings.sms} />} label={lang.sms} />
          </FormGroup>}
        </Paper>
      </Grid>

      {!user.isOperater && <Grid item xs={12}>
        <p className="account-item-title">{lang.employees}</p>
        <Paper style={{overflowX: 'auto', height: props.loading.employees ? '599px' : 'auto'}} className="account-item-box" elevation={4}>
          <Button size="small" onClick={() => setForm({type: 'add', drawer: true})} style={{position: 'sticky', left: 0}} variant="contained">{lang.add_employee}</Button> <br/><br/>
          {props.loading.employees && <LinearProgress />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{lang.fullname}</TableCell>
                <TableCell>{lang.email}</TableCell>
                <TableCell>{lang.phone}</TableCell>
                {/* <TableCell>{lang.role_type}</TableCell> */}
                <TableCell>{lang.role_perm}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderEmployees(props.employees)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={props.employees.count}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={onHandlePageChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>}

      {!user.is_add_user && <Grid item xs={12}>
        <p className="account-item-title">{lang.fld_machines}</p>
        <Paper style={{overflowX: 'auto', height: props.loading.employees ? '599px' : 'auto'}} className="account-item-box" elevation={4}>
          <Button size="small" onClick={() => setAdditional({model: {}, type: 'add', role: 'machine'})} style={{position: 'sticky', left: 0}} variant="contained">{lang.fld_add_machine}</Button> <br/><br/>
          {props.loading.employees && <LinearProgress />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{lang.num}</TableCell>
                <TableCell>{lang.fld_machine}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderMachines({results: machines})}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={machines.length}
                    rowsPerPage={10}
                    page={pageM}
                    onPageChange={(evt, page) => setPageM(page)}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>}

      {!user.is_add_user && <Grid item xs={12}>
        <p className="account-item-title">{lang.fld_conn_machines}</p>
        <Paper style={{overflowX: 'auto', height: props.loading.employees ? '599px' : 'auto'}} className="account-item-box" elevation={4}>
          <Button size="small" onClick={() => setAdditional({model: {}, type: 'add', role: 'conMachine'})} style={{position: 'sticky', left: 0}} variant="contained">{lang.fld_add_con_machine}</Button> <br/><br/>
          {props.loading.employees && <LinearProgress />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{lang.num}</TableCell>
                <TableCell>{lang.fld_con_machine}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderConMachines({results: connectedMachines})}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={connectedMachines.length}
                    rowsPerPage={10}
                    page={pageCM}
                    onPageChange={(evt, page) => setPageCM(page)}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
        <br/>
        <br/>

      </Grid>}
      

      {!user.isOperater && <Drawer open={form.drawer} onClose={() => {setForm({drawer: false}); setModel({})}} anchor="right">
        <EmployeeForm onSuccess={(message) => setSuccess(message)} refresh={() => {onHandlePageChange(null, page)}} model={model} type={form.type} onClose={() => {setForm({drawer: false}); setModel({})}} />
      </Drawer>}

      {!user.is_add_user && <Drawer open={additional.model ? true : false} onClose={() => setAdditional({model: null, type: null, role: null})} anchor="right">
        <AdditionalForm onUpdateMachines={onUpdateMachines} onUpdateConnectedMachines={onUpdateConnectedMachines} onSuccess={(message) => setSuccess(message)} {...additional} onClose={() => setAdditional({model: null, type: null, role: null})} />
      </Drawer>}

      <PhoneVerification open={phoneModal} onClose={(evt, data, success) => {setPhoneModal(false); if(success) {console.log(success); setSuccess(success)}}} onSubmit={() => {/* UPDATE USER PROFILE */}} phone={user.phone} phone_verified={user.phone_verified} country={user.country} />
      
      <ConfirmDialog loading={loading.deactivate} open={deactivateModal} onClose={() => setDeactivateModal(false)} onSubmit={onDeactivateAccount} title={lang.deactivate_account} question="">
        <p className="text-primary"><strong>{lang.deactivate_question}</strong></p>
        <p className="text-warning">{lang.deactivation_note}</p>
      </ConfirmDialog>

      <ConfirmDialog loading={loading.delete} open={deleteModal} onClose={() => setDeleteModal(false)} onSubmit={onDeleteAccount} title={lang.delete_account}>
        <p className="text-danger"><strong>{lang.delete_question}</strong></p>
        <p className="text-danger">{lang.deletion_note}</p>
      </ConfirmDialog>

      <Message type="success" open={success ? true : false} message={success} onClose={() => setSuccess(null)} />
      <Message type="error" open={userError.non_field_errors !== undefined} message={userError.non_field_errors} onClose={() => onCloseMessage('profile')} />
      <Message type="error" open={passwordError.non_field_errors !== undefined} message={passwordError.non_field_errors} onClose={() => onCloseMessage('password')} />

    </Grid>
  )
}

export default Account;