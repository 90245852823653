const states = {
  user: {
    loading: false,
    success: false,
    error: {},
    model: {}
  },
  password: {
    loading: false,
    success: false,
    error: {},
    model: {}
  },
  employee: {
    loading: false,
    success: false,
    error: {},
    model: {

    }
  },
  codeLists: {
    cropTypes: [], activityTypes: [], tillageTypes: [], fertigationTypes: [],
    fertigationProducts: [], cropGroups: [], plantingSeeds: [], plantProtectionTypes: [],
    plantProtectionProducts: [], plantProtectionSubstances: [], plantProtectionAgroTypes:[]
  }
}

const getInitialState = (name) => {
  return states[name]
}

export default getInitialState;