import { blue } from "@mui/material/colors";
import moment from "moment";
import $seasons from "../../services/$seasons";
import colors from "../colors";

export const formatData = (data, lang) => {
	let formated = {}

	Object.keys(data).map(name => {
		if(name !== 'times') {
			formated[name] = data.times.map((time, key) => {
				return {x: time, y: data[name][key]}
			})
		}
	})
	
	return {
		datasets: [{
			data: formated.temperature,
			label: lang.temperature,
			borderColor: 'orange',
			borderWidth: 2,
			pointBackgroundColor: 'orange',
			pointRadius: 2,
			fill: false,
			spanGaps: true,
			yAxisID: 't'
		},{
			data: formated.humidity,
			label: lang.humidity,
			borderColor: 'transparent',
			borderWidth: 2,
			pointBackgroundColor: 'transparent',
			pointRadius: 0,
			fill: false,
			spanGaps: true,
			yAxisID: 'humidity'
		},{
			data: formated.cloudiness,
			label: lang.cloudiness,
			borderColor: 'transparent',
			borderWidth: 2,
			pointBackgroundColor: 'transparent',
			pointRadius: 0,
			fill: false,
			showLine: false,
			spanGaps: true,
			yAxisID: 'cloudiness'
		},
		{
			data: formated.pressure,
			label: lang.pressure,
			borderColor: 'transparent',
			borderWidth: 2,
			pointBackgroundColor: 'transparent',
			pointRadius: 0,
			fill: false,
			spanGaps: true,
			yAxisID: 'pressure'
		},
		{
			
			data: formated.wind,
			label: lang.wind_speed,
			borderColor: 'transparent',
			borderWidth: 2,
			pointBackgroundColor: 'transparent',
			pointRadius: 0,
			fill: false,
			spanGaps: true,
			yAxisID: 'wind'
		},
		
		{
			data: formated.temperature.map(obj => ({x: obj.x, y: obj.y + 8})),
			label: '',
			borderColor: 'transparent',
			pointStyle: formated.symbol.map(s => {
				if(s) {
					let img = new Image();
					img.src = colors.weather[s.y];
					img.width = window.innerWidth <= 600 ? '15' : "30";
					img.height =  window.innerWidth <= 600 ? '15' : "30";
					return img
				}
				return null
			}),
			fill: false,
			yAxisID: 't'
		},
		{
			data: formated.precipitation,
			label: lang.precipitation,
			borderColor: 'transparent',
			backgroundColor: blue[800],
			type: 'bar',
			borderWidth: 2,
			yAxisID: 'p'
		}]
	}
}


export const formatOptions = (lang) => {
  return Object.assign({},{
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
			tooltip: {
				callbacks: {
					title: (val) => {
						return moment(val[0].raw.x).format('HH:mm, DD-MMMM')
					},
					label: (val, data) => {
						if(val.datasetIndex === 5) return '';
						let unit = ''
						switch(val.datasetIndex) {
							case 0: unit = '°C'; break;
							case 1: unit = '%'; break;
							case 2: unit = '%'; break;
							case 3: unit = 'mb'; break;
							case 4: unit = 'm/s'; break;
							case 6: unit = 'mm'; break;
							default: unit = '';
						}
						return `${val.dataset.label}: ${val.parsed.y} ${unit}`
					}
				}
			},
      legend: {
        display: false
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        },
        pan: {
          enabled: true,
          threshold: 1,
          mode: 'x',
          modifierKey: 'shift'
        }
      },
    
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      t: {
				title: {text: `${lang.temperature} °C`, display: true, color: 'orange'},
				position: 'left',
				suggestedMin: -5,
				suggestedMax: 30,
				ticks: {
					color: 'orange',
					stepSize: 5
				}
			},
			cloudiness: {display: false, position: 'left', grid: {display: false}},
			pressure: {display: false, position: 'left', grid: {display: false}},
			humidity: {display: false, position: 'left',grid: {display: false}},
			wind: {display: false, position: 'left',grid: {display: false}},
			p: {
				title: {text: `${lang.precipitation} [mm]`, display: true, color: blue[800]},
				position: 'right',
				beginAtZero: true,
				suggestedMax: 10,
				grid: {
					display: false
				},
				ticks: {
					color: blue[800],
					stepSize: 5
				}
			},
      x: {
				grid: {display: false},
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
						hour: 'HH'
          }
        },
        ticks: {
          autoSkipPadding: 10,
          maxRotation: 0,
        }
       
      }
    },
  })
}