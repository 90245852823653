import { Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import Divider from "../auth/Divider";

function Alerts(props) {
  const {lang, primary, onUpdateState} = useContext(AppContext);

  const [state, setState] = useState({loading: true, error: false, data: [], regions: []})

  const [filter, setFilter] = useState([]);

  const onFilterRegions = (evt) => {
    if(evt.target.value.indexOf('all') > -1) {
      setFilter([])
    } else {
      setFilter(evt.target.value)
    }

  }

  useEffect(() => {
    onUpdateState({view: 'alerts', mounted: true});

    $data.getAlerts()
      .then(data => setState({data, loading: false, regions: [...new Set(data.map(item => item.region))]}))
      .catch(err => setState({error: true, loading: false}))
    
  },[]);

  const renderAlerts = () => {
    if(state.loading) return (<Grid item xs={12} sx={{textAlign: 'center'}} ><CircularProgress /></Grid>);

    if(state.error) return <Grid item xs={12} sx={{textAlign: 'center'}} color="error">{lang.alerts_error}</Grid>

    if(state.data.length === 0) return <Grid item xs={12} sx={{textAlign: 'center'}}>{lang.no_alerts}</Grid>

    let filtered = state.data.filter(item => {
      return filter.indexOf(item.region) > -1 || filter.length === 0
    });

    if(filtered.length === 0) return <Grid item xs={12} sx={{textAlign: 'center'}}>{lang.no_alerts_filter}</Grid>

    return filtered.map((item, key) => {
      return (
        <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardContent sx={{minHeight: '130px', position: 'relative', background: 'rgba(220,220,220, .1)'}}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="primary" gutterBottom>
                {item.title}
              </Typography>

              <Typography sx={{fontSize: '14px', position: 'absolute', bottom: '5px', left: '10px', fontStyle: 'italic' }} color="text.secondary">
                {item.region}
              </Typography>
              <Typography sx={{fontSize: '14px', position: 'absolute', bottom: '5px', right: '10px', fontStyle: 'italic' }} color="text.secondary">
                {item.date}
              </Typography>
            </CardContent>
            <CardActions>
              <a href={item.link} style={{textDecoration: 'none'}} target="_blank"><Button size="small" color="secondary">{lang.alerts_learn_more}</Button></a>
            </CardActions>
          </Card>
        </Grid>
      )
    })

  }

  return (
    <Container sx={{mt: '30px'}} className="fieldbook-container">
      <Grid sx={{mt: '0'}} container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="filterregion">{lang.alerts_filter_region}</InputLabel>
            <Tooltip placement="top" title={filter.join(', ')}>
              <Select 
                multiple size="small" labelId="filterregion" 
                label={lang.alerts_filter_region} value={filter} 
                onChange={onFilterRegions}
                renderValue={(selected) => selected.join(', ')} fullWidth>
                  <MenuItem value={'all'}>
                    <Checkbox checked={filter.length === 0} />
                    <ListItemText primary={lang.alerts_all_regions} />
                  </MenuItem>
                {state.regions.map((region, key) => {
                  
                  return (
                    <MenuItem key={key} value={region}>
                      <Checkbox checked={filter.indexOf(region) > -1} />
                      <ListItemText primary={region} />
                    </MenuItem>
                  )
                })}
              </Select>
            </Tooltip>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{width: '100%'}} />
        </Grid>
        {renderAlerts()}
      </Grid>

    </Container>
  )
}

export default Alerts;