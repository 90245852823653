import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import { Calculate, Close } from "@mui/icons-material";

function InputPerPackagePlanting(props) {
  const { lang, user } = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = useMergeState({
    packages: 0,
    quantity_per_package: 0,
    price_per_package: 0,
    unit: 1,
  });

  const onChange = (name, value) => {
    setState({ [name]: value });
  }

  const calculate = () => {
    let total = state.packages * state.price_per_package;
    let quantity = state.packages * state.quantity_per_package;
    let unitPrice = isNaN(state.price_per_package/state.quantity_per_package) ? 0 : (state.price_per_package/state.quantity_per_package).toFixed(2);
    if(isNaN(total)) return;

    props.onUpdateModel(total, quantity, state.unit, unitPrice);
    props.onClose();



  }


  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.input_per_package}<Close onClick={props.onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent>
        <Container sx={{ mt: '30px' }}>
          <Grid style={{ marginBottom: '20px' }} container spacing={2}>
            <Grid item xs={12}>
              <TextField label={lang.number_of_packages} type="number" onChange={(evt) => onChange('packages', evt.target.value)} fullWidth size="small" value={state.packages} />
            </Grid>
            <Grid item xs={8}>
              <TextField label={lang.quantity_per_package} helperText={lang.quantity_per_package_desc} type="number" onChange={(evt) => onChange('quantity_per_package', evt.target.value)} fullWidth size="small" value={state.quantity_per_package} />
            </Grid>
            {<Grid item xs={4}>
              <FormControl size="small" fullWidth>
                <InputLabel id="unit_type">{lang.quantity_unit}</InputLabel>
                <Select IconComponent={null} readOnly={props.isPlanting} size="small" labelId="unit_type" label={lang.quantity_unit} value={state.unit} onChange={(evt) => onChange('unit', evt.target.value)} fullWidth>
                  <MenuItem value={1}>{lang.number_of_seeds}</MenuItem>
                  <MenuItem value={2}>{'l'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>}
            <Grid item xs={12}>
              <TextField label={lang.price_per_package + ` (${user.currency})`} type="number" onChange={(evt) => onChange('price_per_package', evt.target.value)} fullWidth size="small" value={state.price_per_package} />
            </Grid>

            <Grid item xs={12}>
              <Button onClick={calculate} disabled={isNaN(state.packages * state.quantity_per_package * state.price_per_package)} size="small" variant="contained" color="primary" startIcon={<Calculate />}>{lang.calculate}</Button>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

    </Dialog>
  )
}

export default InputPerPackagePlanting;