import {Map as MapIcon, Agriculture as AgroIcon, MenuBook as BookIcon, QuestionMark, Logout, Task, PaymentOutlined, Co2, AddAlert, Warning, Crop, Agriculture, Newspaper, Announcement, Sensors} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import $auth from '../../services/$auth';


function Navigation(props) {
  const context = useContext(AppContext);
  const {lang, view, user, hasACRequests, onUpdateState} = context;
  return (
    <Stack justifyContent={'space-between'} className="navigation-container">
      {!user.isOperater && <div>
        <Tooltip  placement='right' arrow title={lang.map_view}>
          <Link className='nav-link' to="/dashboard/map"><MapIcon className={"nav-icon" + (view === 'map' ? ' active' : '')} /></Link>
        </Tooltip>

        <Tooltip arrow placement='right' title={lang.vrt_view}>
          <Link className='nav-link' to="/dashboard/vrt"><AgroIcon className={"nav-icon" + (view === 'vrt' ? ' active' : '')} /></Link>
        </Tooltip>

        <Tooltip arrow placement='right' title={lang.fieldbook_view}>
          <Link className='nav-link' to="/dashboard/field-book"><BookIcon className={"nav-icon" + (view === 'fieldbook' || view === 'fieldbook_item' ? ' active' : '')} /></Link>
        </Tooltip>

        <Tooltip arrow placement='right' title={lang.sensors_view}>
          <Link className='nav-link' to="/dashboard/sensors"><Sensors className={"nav-icon" + (view === 'sensors' ? ' active' : '')} /></Link>
        </Tooltip>

        {/* {user.has_sensors && <Tooltip arrow placement='right' title={lang.sensors_view}>
          <Link className='nav-link' to="/dashboard/sensors"><Sensors className={"nav-icon" + (view === 'sensors' ? ' active' : '')} /></Link>
        </Tooltip>} */}

        {user.country === "4" && <Tooltip arrow placement='right' title={lang.alerts_view}>
          <Link className='nav-link' to="/dashboard/alerts"><Announcement className={"nav-icon" + (view === 'alerts' ? ' active' : '')} /></Link>
        </Tooltip>}

 

      </div>}
      

      {user.isOperater && <div>
        <Tooltip arrow placement='right' title={lang['job-orders_view']}>
          <Link to="/dashboard/job-orders"><Task className={"nav-icon" + (view === 'job-orders' ? ' active' : '')} /></Link>
        </Tooltip>
      </div>} 
      <div>
        {!user.is_add_user && hasACRequests && <Tooltip arrow placement='right' title={lang.ac_request_title}>
        {/* <Link to="/dashboard/help"><QuestionMark className={"nav-icon" + (view === 'help' ? ' active' : '')} /></Link> */}
          <a className='nav-link' onClick={() => onUpdateState({acRequests: true})}><Co2 className={"nav-icon"} /></a>
        </Tooltip>}
        {!user.is_add_user && <Tooltip arrow placement='right' title={lang.payment_view}>
        {/* <Link to="/dashboard/help"><QuestionMark className={"nav-icon" + (view === 'help' ? ' active' : '')} /></Link> */}
          <a  className='nav-link' onClick={() => onUpdateState({pricingOpened: true})}><PaymentOutlined className={"nav-icon"} /></a>
        </Tooltip>}
      
      <Tooltip arrow placement='right' title={lang.help_view}>
      {/* <Link to="/dashboard/help"><QuestionMark className={"nav-icon" + (view === 'help' ? ' active' : '')} /></Link> */}
        <a  className='nav-link' onClick={() => onUpdateState({tourGuide: true})} style={{cursor: 'pointer'}}><QuestionMark className={"nav-icon" + (view === 'help' ? ' active' : '')} /></a>
      </Tooltip>
      </div>
     
    </Stack>
  )
}

export default Navigation;