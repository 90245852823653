import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward, Close, Delete, DragHandle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import Message from "../../ui/Message";
import $query from "../../../services/$query";

function SummaryIndicesMobile(props) {
  const {lang, user, season, seasons, source, primary} = useContext(AppContext);
  const {data} = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getIconForStatus = (status) => {
    if(!status) return ' - ';

    if(status === 'up') return <ArrowUpward fontSize="small" color="success" />
    if(status === 'down') return <ArrowDownward fontSize="small" color="error" />

    if(status === 'equial') return <DragHandle fontSize="small" color="warning" />
  }
  const renderContent = () => {
    if(!data) return null;

    if(!data[source]) return null;
  
    if(data[source].length === 0) {
      return (
      <Typography  sx={{mt: '20px', width: '100%'}} variant="subtitle2" color="primary">
        {lang.summary_no_data_parcel}
      </Typography>
      )
    }


    let formated = $query.formatShortSummary(data, season, seasons, source);

    return <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{height: '100%', p: '10px 10px 0 10px', '& td': {padding: '5px 10px'}}} elevation={2}>
          <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.ndvi_full}</Typography>
          <table>
            <tbody>
              {formated.ndvi.map((obj,key) => {
                return (
                  <tr key={key}>
                    <td>{obj.date}</td>
                    <td style={{color: primary, fontWeight: 'bold'}}>{obj.val}</td>
                    <td>{getIconForStatus(obj.status)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{height: '100%', p: '10px 10px 0 10px', '& td': {padding: '5px 10px'}}} elevation={2}>
          <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.chl_full}</Typography>
          <table>
            <tbody>
              {formated.chl.map((obj,key) => {
                return (
                  <tr key={key}>
                    <td>{obj.date}</td>
                    <td style={{color: primary, fontWeight: 'bold'}}>{obj.val}</td>
                    <td>{getIconForStatus(obj.status)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Paper>
      </Grid>
    </Grid>
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{props.title}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          {renderContent()}
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>

      <Message type="error" open={props.error ? true : false} message={props.error} onClose={props.onCloseMessage} />

    </Dialog>
  )
}

export default SummaryIndicesMobile;