import { Cancel, Close, Event } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
import { blue } from "@mui/material/colors";
import $seasons from "../../../services/$seasons";
import $data from "../../../services/$data";
import Message from "../../ui/Message";
import colors from "../../../config/colors";
import $map from "../../../services/$map";
import DrawFieldMap from "./DrawFieldMap";

function AddField(props) {
	const {lang, cropTypes, user, parcels, onUpdateState} = useContext(AppContext);

	const [model, setModel] = useMergeState({
		name: '',
    area: 0,
		geometry: null
	});

	const [state, setState] = useMergeState({ error: {}, loading: false, toggleMap: false, });

	useEffect(() => {
		if(props.open) {
			setModel({name: '', area: 0,  geometry: null })
			setState({loading: false, error: {}, toggleMap: false,})
		}
	}, [props.open]);

	const postParcel = () => {
    if((!model.area || model.area <= 0) && model.geometry == null) {
      setState({
        error: {area: lang.area_error}
      })
      return;
    }

		setState({loading: true, error: {}})

		let formated = {name: model.name, geometry: model.geometry};
		if(model.geometry == null) {
			formated.area = model.area;
		} else {
			formated.geometry = new WKT().writeFeature(new GeoJSON().readFeature(model.geometry))
		}

		$data.postParcel(formated)
			.then(data => {
				setState({loading: false});
				// if(total + data.properties.area > 100 && !isAgricapture) {
				// 	onUpdateState({pricingOpened: true})
				// }
				props.onSuccess();
			})
			.catch(err => {
				if(err.constructor === String || err.constructor === Array) {
					setState({error: {non_field_errors: err}, loading: false})
				} else {
					setState({error: err, loading: false})
				}
			})
	}

	const renderGeometry = () => {
		if(!model.geometry) return null;

		try {
			console.log(model.geometry);
			return $map.getParcelThumbnail(null, 'transparent',  '#aaa', 30, model.geometry);
		} catch(e) {
			return null;
		}

		
	}

	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}, success: null});
  }
	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.add_field}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px', padding: '10px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField required error={state.error.name ? true : false} helperText={state.error.name || ''} label={lang.parcel_name} type="text" onChange={(evt) => setModel({name: evt.target.value})} fullWidth size="small" value={model.name} />
						</Grid>

						<Grid item xs={12} sm={5}>
              <TextField disabled={Boolean(model.geometry)} fullWidth required error={state.error.area ? true : false} helperText={state.error.area || ''} label={lang.parcel_area + ' [ha]'} type="number" onChange={(evt) => setModel({area: evt.target.value})}  size="small" value={model.area} />
                
						</Grid>
            <Grid item xs={12} sm={2} textAlign={"center"}>
              <div style={{whiteSpace: 'nowrap', paddingTop: '10px'}}>- {lang.or} -</div>
            </Grid>
            <Grid item xs={12} sm={5} textAlign={"center"}>
            {!model.geometry && !state.toggleMap && <Button onClick={() => {
							setState({toggleMap: true});}} sx={{lineHeight: 'unset', whiteSpace: 'nowrap', minWidth: 'unset', marginTop: '5px'}} size="small" variant="contained" startIcon={<i className="toolbar-icon fg-polygon-pt"></i>}>{lang.action_draw}</Button>}
						{(state.toggleMap || model.geometry) && <Button onClick={() => {
							setState({toggleMap: false})
							setModel({geometry: null});}} color="warning" sx={{lineHeight: 'unset', whiteSpace: 'nowrap', minWidth: 'unset', marginTop: '5px'}} size="small" variant="contained" startIcon={<Cancel />}>{lang.cancel}</Button>}

            </Grid>
						{state.toggleMap && <Grid item xs={12}>
							<DrawFieldMap onSetGeometry={(feature) =>{ let f = feature.clone(); f.setProperties({name: ''});  setModel({geometry: new GeoJSON().writeFeatureObject(f)}); setState({toggleMap: false})}} />
						</Grid>}
						{!state.toggleMap && <Grid  item xs={12}>
							<Stack sx={{maxWidth: '180px', width: '100%', margin: '0 auto'}}>
								{renderGeometry()}
							</Stack>
						</Grid>}
          </Grid>
        </Container>
      </DialogContent>
			<DialogActions>
				<LoadingButton onClick={postParcel} size="small" loading={state.loading} variant="contained" color="primary">{lang.confirm}</LoadingButton>
				<LoadingButton onClick={props.onClose} size="small" loading={state.loading} variant="contained" color="error">{lang.cancel}</LoadingButton>
			</DialogActions>
			<Message type="error" open={state.error.non_field_errors !== undefined} message={state.error.non_field_errors} onClose={() => onCloseMessage()} />
    </Dialog>
	)
}

export default AddField;