import { blue, orange, green } from "@mui/material/colors";
import { i } from "mathjs";
import moment from "moment";
import $seasons from "../../services/$seasons";

export const formatData = (data, selected, color) => {
  
	return {
    datasets : [{
        label: selected ? selected.toUpperCase() : '',
        data: data,
        borderColor: color,
        borderWidth: 1,
        pointBackgroundColor: color,
        pointRadius: 1,
        tension: 0.5,}]
  } 
	
}

export const formatOptions = (color, data) => {

  let minDate = moment(data[0].x);

  let maxDate = moment(data[data.length - 1].x);

  let isDaily = maxDate.diff(minDate, 'day') > 1 ? true : false

  return Object.assign({},{
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      },
      tooltip: {
        callbacks: {
          title: (val) => {
            return moment(val[0].raw.x).format('DD-MMM-YYYY HH:mm')
          }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        },
        pan: {
          enabled: true,
          threshold: 1,
          mode: 'x',
          modifierKey: 'shift'
        }
      },
    
    },
    interaction: {
      mode: 'nearest',
			axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        position: 'left',
				ticks: {
					color: color,
				}
      },
      x: {
        type: 'time',
        time: {
          unit: isDaily ? 'day' : 'hour',
          displayFormats: {
            day: 'DD-MMM-YYYY',
            hour: 'HH:mm'
          }
        },
        ticks: {
          autoSkipPadding: 10,
          maxRotation: 0,
        }
       
      }
    },
  })
}