import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import useMergeState from "../../hooks/useMergeState";
import { CircularProgress, colors, Container, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Stack, Switch } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import { LoadingButton } from "@mui/lab";
import $data from "../../../services/$data";
import Message from "../../ui/Message";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import moment from "moment";
import ScrollCalendar from "../../ui/ScrollCalendar";
import JobOrdersPreview from './JobOrdersPreview';
import _ from "lodash";

function JobOrders(props) {
  const {lang} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [data, setData] = useMergeState({jobs: [], loading: false});

  const [filter, setFilter] = useMergeState({jobsOnly: false, completedOnly: false, inProgressOnly: false});

  const [preview, setPreview] = useMergeState({open: false, ids: []});

  useEffect(() => {
    getJobs()
  }, [])

  const getJobs = () => {
    setData({loading: true});
    $data.getActivities()
      .then(data => setData({jobs: _.concat(data.activities, data.jobs), loading: false}))
      .catch(err => {setData({loading: false})})
  }

  const getRange = (jobs) => {
    if(jobs.length === 0) return [];
    let allDates = jobs.map(obj => obj.date);
    let uniqueDates = [...new Set(allDates)];
    let sorted = uniqueDates.sort((a,b) => moment(a).diff(moment(b)));
        
    return [sorted[0], sorted[sorted.length - 1]];
  }

  const getHighlighted = (jobs) => {
    if(jobs.length === 0) return {};

    let groups = {};

    jobs.map(job => {
      if(groups.hasOwnProperty(job.date)) {
        groups[job.date].push(job);
      } else {
        groups[job.date] = [job];
      }
    });

    let highlighted = {};

    Object.keys(groups).map(date => {
      let color = colors.blue[500];

      if(groups[date].length === 1) {
        color = groups[date][0].activity_status ? colors.green[500] : colors.orange[500];
      }
      
      highlighted[date] = {ids: groups[date].map(obj => obj.activity_type === 4 ? obj.id : obj.pk), numberOfCompleted: groups[date].filter(obj => obj.activity_status).length, numberOfActive:groups[date].filter(obj => !obj.activity_status).length,  numberOfJobs: groups[date].length, color}
    });

    return highlighted
  }

  const filterItems = () => {
    return data.jobs.filter(obj => {
      let show = true;
      if(filter.jobsOnly && obj.job_order === false) {
        return false
      }

      if(filter.completedOnly) {
        return obj.activity_status === true;
      }

      if(filter.inProgressOnly) {
        return obj.activity_status === false;
      }

      return true
    })
  }

  let filtered = filterItems()

  return (
    <Dialog maxWidth="xl" open={props.open} onClose={() => {setPreview({open: false, ids: []}); props.onClose();}} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.act_activities} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container>
          <Grid container spacing={2}>
            <Grid style={{position: 'sticky', top: 0, background: '#fff', zIndex: 4}}  item xs={12} >
              <Stack>
                <Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
                  <div style={{background: colors.orange[500], width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
                  {lang.act_job_active}
                </Stack>
                <Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
                  <div style={{background: colors.green[500],width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
                  {lang.act_job_completed}
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent={'space-around'} sx={{flexWrap: 'wrap'}}>
                <FormGroup>
                  <FormControlLabel sx={{margin: 0}} control={<Switch checked={filter.jobsOnly} onChange={(evt) => setFilter({jobsOnly: evt.target.checked, inProgressOnly: true, completedOnly: evt.target.checked ? false : filter.completedOnly})} />} label={lang.filter_job_orders_only}/>
                </FormGroup>
                <FormGroup>
                  <FormControlLabel sx={{margin: 0}} control={<Switch checked={filter.completedOnly} onChange={(evt) => setFilter({completedOnly: evt.target.checked, jobsOnly: evt.target.checked ? false : filter.jobsOnly, inProgressOnly: evt.target.checked ? false : filter.inProgressOnly})} />} label={lang.filter_job_completed}/>
                </FormGroup>
                <FormGroup>
                  <FormControlLabel disabled={filter.jobsOnly} sx={{margin: 0}} control={<Switch checked={filter.inProgressOnly} onChange={(evt) => setFilter({inProgressOnly: evt.target.checked, completedOnly: evt.target.checked ? false : filter.completedOnly})} />} label={lang.filter_job_in_progress}/>
                </FormGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              {data.loading && <CircularProgress />}
              {!data.loading && <ScrollCalendar onOpenDetails={(ids) => {setPreview({open: true, ids});}} jobs={filtered} highlighted={getHighlighted(filtered)} minDate={getRange(data.jobs)[0]} maxDate={getRange(data.jobs)[1]} />}
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <JobOrdersPreview jobs={filtered} ids={preview.ids} open={preview.open} onClose={() => setPreview({open: false, ids: []})} />
    </Dialog>
  );
}

export default JobOrders;