import { Close, Download, Event, Refresh } from "@mui/icons-material";
import { Button, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import $seasons from "../../services/$seasons";
import Message from "../ui/Message";

function ExportFieldBook(props) {
  const downloadLink = useRef(null);
  const {lang, locale, operators, activityTypes, seasons} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  let min = moment().subtract(2, 'years').format('YYYY-MM-DD');
  let max = moment().format('YYYY-MM-DD')
  const [filter, setFilter] = useMergeState({date_from: min, date_to: max, parcels: [], types: [], operators: []});
  const [state, setState] = useMergeState({loading: false, progress: 0, error: {}});

  useEffect(() => {
    if(!props.open) {
      setState({progress: 0})
    }
  }, [props.open])

  const onChange = (name, value) => {
    setFilter({[name]: value});
  }

  const onDownloadProgress = (evt) => {
    setState({progress: Math.round((evt.loaded * 100)/evt.total)})
  }

  const onDownload = (isExcel) => {
    setState({loading: true, progress: 0});
    let formated = {...filter, types: filter.types.length === 0 ? activityTypes.filter(obj => obj.id !== 6).map(obj => obj.id) : filter.types}
    let flt = `?${Object.keys(formated).map(name => `${name}=${formated[name]}`).join('&')}&export_format=${isExcel ? 'excel' : 'json'}`

    $data.exportFieldBook(flt, isExcel, onDownloadProgress)
      .then(data => {
        let blob = new Blob([data],  {type: isExcel ? 'application/vnd.ms-excel' : 'application/pdf'});
        let href = window.URL.createObjectURL(blob);
        let filename = lang.fieldbook_view + (isExcel ? '.xls' : '.pdf');
        downloadLink.current.href = href;
        downloadLink.current.download = filename;
        downloadLink.current.click();
        setState({loading: false})
      })
      .catch(err => {
        console.log(err)
        setState({loading: false, progress: 0, error: err})
      })
    
  }


  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}});
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <a ref={downloadLink} style={{height: 0, width: 0}}></a>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.fld_download_btn}
        
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button color={'warning'} onClick={() => setFilter({date_from: min, date_to: max, operators: [], types: []})} variant="contained" size="small">{lang.reset_filter} &nbsp;<Refresh /></Button>
            </Grid>
            <Grid item xs={12} sm={6}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {setFilter({date_from: filter.date_from})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={filter.date_from === '' ? undefined : moment(filter.date_from)} onChange={(newValue) => setFilter({date_from: newValue.format('YYYY-MM-DD')})}
									renderInput={(params) => { params.inputProps.value = filter.date_from === '' ? '' : moment(filter.date_from).format('DD-MMM-YYYY'); return (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}}
								/>
							</LocalizationProvider>	
						</Grid>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {setFilter({date_to: filter.date_to})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={filter.date_to === '' ? undefined : moment(filter.date_to)} onChange={(newValue) => setFilter({date_to: newValue.format('YYYY-MM-DD')})}
									renderInput={(params) => (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
							</LocalizationProvider>	
						</Grid>
         
            <Grid item xs={12}>
              <FormControl size="small" fullWidth>
                <InputLabel id="operators">{lang.operators}</InputLabel>
                <Select
                  multiple size="small" labelId="operators" 
                  label={lang.operators} value={filter.operators} 
                  onChange={(evt) => onChange('operators', evt.target.value)}
                  renderValue={(selected) => selected.map(id => $data.getOperatorById(operators, id)).join(', ')} fullWidth>
                  {operators.map((obj, key) => {
                    
                    return (
                      <MenuItem key={key} value={obj.pk}>
                        <Checkbox checked={filter.operators.indexOf(obj.pk) > -1} />
                        <ListItemText primary={`${obj.first_name} ${obj.last_name}`} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl size="small" fullWidth>
                <InputLabel id="types">{lang.activity_type}</InputLabel>
                <Select
                  multiple size="small" labelId="types" 
                  label={lang.activity_type} value={filter.types} 
                  onChange={(evt) => onChange('types', evt.target.value)}
                  renderValue={(selected) => selected.map(id => $data.getActivityNameById(activityTypes, id)).join(', ')} fullWidth>
                  {activityTypes.filter(obj => obj.id !== 6).map((obj, key) => {
                    
                    return (
                      <MenuItem key={key} value={obj.id}>
                        <Checkbox checked={filter.types.indexOf(obj.id) > -1} />
                        <ListItemText primary={`${obj.name}`} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {(state.loading || state.progress === 100) && <Grid style={{padding: '20px 0 0 0'}} item xs={12}>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <LinearProgress style={{width: '100%'}} variant="determinate" value={state.progress} />
              <Chip color="primary" style={{marginTop: '10px'}} label={`${state.progress}%`} variant="filled"></Chip>
            </Stack>
          </Grid>}
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton endIcon={<Download />} color="primary" variant="contained" size="small" loading={state.loading} onClick={() => onDownload(false)}>{lang.download_pdf}</LoadingButton>
        <LoadingButton endIcon={<Download />} color="primary" variant="contained" size="small" loading={state.loading} onClick={() => onDownload(true)}>{lang.download_xls}</LoadingButton>

      </DialogActions>

      <Message type="error" open={state.error.non_field_errors !== undefined} message={state.error.non_field_errors} onClose={() => onCloseMessage()} />
    </Dialog>
  )
}

export default ExportFieldBook;

