import { Badge, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ArrowBack, Circle, Close, Delete } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { Link } from "react-router-dom";

function TermsAndConditionsRoute(props) {
  const { lang, primary } = useContext(AppContext);

  return (
    <Container sx={{height: 'calc(100% - 60px)', overflow: 'auto'}} style={{ marginTop: '30px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link to="/"><Button size="small" variant="contained" startIcon={<ArrowBack />}>{lang.go_back}</Button></Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
            <Grid sx={{textAlign: 'center', marginBottom: '10px'}} item xs={12}>
              <a href="https://gilab.rs" target="_blank"><img src="/gilab-logo.svg" width="240px" /></a>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Uslovi korišćenja za CropLab</Typography>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={1} /> &nbsp;&nbsp;Svrha, informacije o CropLab-u</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>
                (1) Kompanija GILAB DOO BEOGRAD (u daljem tekstu „GILAB”, „mi” ili „naš”) omogućava platformu „CropLab” putem veb lokacije <a href="https://app.croplab.info/" target="_blank">https://app.croplab.info/</a> (u daljem tekstu „CropLab”), gde uredno registrovani korisnici mogu da pristupe uslugama i sadržaju dostupnim na CropLab-u i koriste dodatne usluge i softver koji se povremeno pružaju putem CropLab-a (veb ili mobilne aplikacije). Dalje informacije o uslugama i sadržaju dostupnim na CropLab-u mogu se naći u <strong><Chip component={'span'} variant="filled" size="small" color="primary" label={7} /></strong>.
              </p>
              <p>
                (2)  Ovi Uslovi korišćenja se takođe primenjuju na korišćenje CropLab aplikacije i svih usluga koje aplikacija pruža. Ukoliko nije izričito drugačije regulisano, svako pozivanje na „CropLab” u ovim Uslovima korišćenja se takođe odnosi na CropLab aplikaciju i sadržaj, usluge, alate i funkcije softvera koje su stavljene na raspolaganje u aplikaciji i za web i mobilnu aplikaciju.
              </p>
              <p>
                (3) Ovi Uslovi korišćenja uređuju pružanje pristupa CropLab-u i od strane kompanije GILAB kao i vaše korišćenje istih u svojstvu uredno registrovanog korisnika.              
              </p>
              <p>
                (4) Naši kontakt podaci su:              
              </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="GILAB DOO BEOGRAD" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Dragoslava Srejovića 94" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={<>E-mail: <a href="mailto:team@gilab.rs">team@gilab.rs</a></>} />
                </ListItem>
               
              </List>
              <p>
                Dodatne informacije o kompaniji GILAB možete pronaći ovde <a href="https://gilab.rs/" target={"_blank"}>https://gilab.rs/</a> 
              </p>
              <p>
               (5) <strong>MOLIMO VAS DA PAŽLJIVO PROČITATE OVE USLOVE KORIŠĆENJA. REGISTROVANJEM ZA KORIŠĆENJE CropLab-a, STVARA SE PRAVNO OBAVEZUJUĆI UGOVOR IZMEĐU VAS I KOMPANIJE GILAB.</strong>            
              </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={2} /> &nbsp;&nbsp;Izmene Uslova korišćenja</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>
              Kompanija GILAB zadržava pravo da u bilo kom trenutku izmeni ove Uslove korišćenja. Kompanija GILAB će vas o ovim izmenama obavestiti najmanje 30 dana pre nego što one stupe na snagu. Ukoliko se ne slažete sa izmenama, možete prestati sa korišćenjem CropLab-a i zahtevati brisanje vašeg registrovanog naloga. <strong>SMATRAĆE SE DA SE SLAŽETE SA IZMENAMA UKOLIKO NASTAVITE DA KORISTITE CropLab POSLE ISTEKA PERIODA OD 30 DANA OD OBAVEŠTENJA O IZMENAMA.</strong>
              </p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={3} /> &nbsp;&nbsp;Registracija</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Korišćenje CropLab-a zahteva da se registrujete kao korisnik i prihvatite ove Uslove korišćenja. Nakon registracije zaključuje se ugovor o licenci između kompanije GILAB i korisnika. Ne postoji pravo na zaključivanje ugovora. Kompanija GILAB ima pravo da odbaci registracije korisnika bez navođenja razloga.</p>

              <p>(2) Možete se registrovati samo ako ste punoletni i potpuno pravno sposobni. Maloletnim osobama nije dozvoljena registracija.</p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={4} /> &nbsp;&nbsp;Vaša registracija sa CropLab-om</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Vaša registracija sa CropLab-om je besplatna. Kompanija GILAB zadržava pravo da ponudi određeni sadržaj ili CropLab-a uz naknadu. Detalji o važećim naknadama i drugim uslovima biće dostupni pre nego što pristupite takvom sadržaju.</p>

              <p>(2) Vaša registracija je vezana za vas kao pojedinca. Druge osobe ne smeju da koriste vaš nalog, osim ako nije u okviru usluga izričito navedeno i dozvoljeno korišćenje ili povezivanje drugih naloga.</p>

              <p>(3) U obavezi ste da navedete potpune i tačne kontakt podatke i dodatne informacije na zahtev kompanije GILAB tokom procesa registracije.</p>
              <p>(4) Ukoliko kompanija GILAB prihvati vašu registraciju, ona će aktivirati vaš nalog i obavestiti vas o tome putem e-pošte. Po prijemu e-pošte, imate pravo na korišćenje CropLab-a u okviru ovih Uslova korišćenja. Da biste to mogli učiniti, moraćete da potvrdite aktivaciju svog naloga klikom na vezu sadržanu u e-pošti. </p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={5} /> &nbsp;&nbsp;Odgovornost za podatke za prijavu</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Tokom postupka registracije, od vas će se tražiti da navedete korisničko ime i lozinku. Nakon aktiviranja vašeg naloga i vaše potvrde u skladu sa <Chip component={'span'} variant="filled" size="small" color="primary" label={4} /> (4) moći ćete da se prijavite u CropLab. Vaša je odgovornost da osigurate da vaše korisničko ime ne krši nijedno pravo trećih lica, posebno ne određena prava na imena ili robne marke, i da nije u suprotnosti sa dobrim moralom.</p>

              <p>(2) Obavezni ste da podatke za prijavu na svoj nalog zaštitite od neovlašćenog pristupa trećih lica i da osigurate da podaci za prijavu ne budu poznati trećim licima. <strong>SVE RADNJE I PRAVNE TRANSAKCIJE PREDUZETE PUTEM VAŠEG NALOGA BIĆE PRIPISANE VAMA IZUZEV UKOLIKO (I) JE KOMPANIJI GILAB UOČLJIVO DA JE POSTUPAO NEKO DRUGI A NE REGISTROVANI KORISNIK ILI (II) MOŽETE DA DOKAŽETE DA NIJE VAŠOM GREŠKOM DOŠLO DO ZLOUPOTREBE PODATAKA ZA PRIJAVU. MORATE ODMAH OBAVESTITI KOMPANIJU GILAB UKOLIKO MISLITE DA SU NEOVLAŠĆENA TREĆA LICA STEKLA ILI ĆE STEĆI PRISTUP VAŠIM PODACIMA ZA PRIJAVU.</strong></p>

              <p>(3) Obavezni ste da ažurirate svoje podatke (uključujući i svoje kontakt podatke). Ako se vaši podaci promene tokom korišćenja, morate bez odlaganja da ispravite svoje podatke u svojim ličnim podešavanjima na CropLab-u. Ukoliko niste u stanju da to učinite, bez odlaganja nas e-poštom ili faksom obavestite o izmenjenim detaljima.</p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={6} /> &nbsp;&nbsp;Prestanak korišćenja</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) U bilo kom trenutku možete da raskinete ugovor o licenci zahtevom za brisanje vašeg naloga na CropLab platformi ili preko određene funkcije u aplikaciji CropLab.</p>

              <p>(2) Kada raskid ugovora stupi na snagu, ugovorni odnos prestaje i više vam nije dozvoljeno da koristite svoj nalog. Kompanija GILAB zadržava pravo da blokira vaše korisničko ime i lozinku čim raskid stupi na snagu.</p>

              <p>(3) <strong>KOMPANIJA GILAB IMA PRAVO DA NEOPOZIVO IZBRIŠE SVE PODATKE GENERISANE U KONTEKSTU VAŠEG KORIŠĆENJA, 30 DANA NAKON STUPANJA NA SNAGU RASKIDA I NAKON ISTEKA BILO KOG ZAKONSKOG ROKA ZADRŽAVANJA.</strong> U obimu u kojem je kompanija GILAB prema zakonima o zaštiti podataka obavezna da briše vaše lične podatke, ona će osigurati brisanje vaših ličnih podataka u skladu s našim propisima o zaštiti podataka.</p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={7} /> &nbsp;&nbsp;Ponuđene usluge i dostupnost usluga</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Putem CropLab-a, kompanija GILAB nudi raznovrsne sadržaje i druge usluge koji se mogu koristiti u ograničenom vremenskom roku. Ovo, na primer, može obuhvatati pristup podacima, člancima, video i audio snimcima, informacijama i drugom sadržaju (u daljem tekstu „Sadržaj”). Vaš pristup i korišćenje usluga koje nudi CropLab podležu odredbama ovih Uslova korišćenja kao i svim dodatnim uslovima korišćenja koji se mogu primenjivati na određene usluge. Obim dostupnih usluga i funkcionalnosti ograničen je na one koje su s vremena na vreme dostupne na CropLab-u a koje kompanija GILAB može da promeni, ukloni ili učini dostupnim po vlastitom nahođenju.</p>

              <p>(2) Usluge koje se nude mogu obuhvatati usluge trećih lica kojima kompanija GILAB samo pruža pristup. Korišćenje takvih usluga, koje su u svakom pojedinačnom slučaju identifikovane kao usluge trećeg lica, može biti podložno drugačijim ili dodatnim uslovima korišćenja, o kojima će vas kompanija GILAB u svakom pojedinačnom slučaju obavestiti.</p>

              <p>(3) Kompanija GILAB ulaže komercijalno opravdane napore da osigura neprekidnu dostupnost ponuđenih usluga. Međutim, može doći do privremenih ograničenja ili prekida usled tehničkih kvarova (kao što su nestanak struje, hardverske ili softverske greške ili tehnički problemi sa kablovima za prenos podataka). <strong>KOMPANIJA GILAB NE GARANTUJE DA ĆE PONUĐENE USLUGE TE BILO KOJE USLUGE ILI DOSTUPNI SRODNI SADRŽAJ BITI BEZ PREKIDA ILI GREŠAKA.</strong></p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={8} /> &nbsp;&nbsp;Upotreba CropLab-a, dostupnost i odgovornost za rezultate proračuna</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Kompanija GILAB vam pruža razne besplatne CropLab funkcionalnosti. Nikakvi rezultati niti preporuke dobijeni korišćenjem CropLab aplikacije ne predstavljaju pravno obavezujuće izjave u ime kompanije GILAB. Korisnik snosi rizik i odgovornost za korišćenje CropLab aplikacije (i oslanjanje na bilo kakve rezultate ili preporuke). Sledeći CropLab alati su dostupni ili planirani: </p>

              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Monitoring useva" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Menadžment zone" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Faze razvoja useva" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Varijabilni tretman" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Upoređivanje" />
                </ListItem>

                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Vremenska prognoza" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Knjiga polja" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Upozorenja" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Senzori" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Uzorci zemljišta" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Slike koje sadrže podatke o lokaciji sa koje su prikupljene" />
                </ListItem>
               
              </List>

              <p>(2) Ponekad je potrebno da CropLab aplikacija radi sa određenim pretpostavkama i pojednostavljenjima jer nije moguće uzeti u obzir sve okolnosti svakog pojedinačnog slučaja.</p>

              <p>(3) Rezultati CropLab aplikacije se izračunavaju u potpunosti automatski i pružaju se korisniku bez prethodne provere od strane kompanije GILAB. Kompanija GILAB stoga ne može isključiti mogućnost da u retkim slučajevima budu dobijeni netačni rezultati, npr. zbog netačnih ulaznih podataka ili nedefinisanih stanja modela. <strong>STOGA REZULTATE TREBA SMATRATI PONUDOM DODATNIH INFORMACIJA, BEZ GARANCIJA U POGLEDU NJIHOVE TAČNOSTI. ODGOVORNOST KOMPANIJE GILAB U OVOM KONTEKSTU JE ISKLJUČENA U SKLADU SA <Chip component={'span'} variant="filled" size="small" color="primary" label={15} /></strong>.</p>

              <p>(4) <strong><Chip component={'span'} variant="filled" size="small" color="primary" label={7} />(3)&nbsp; SE PRIMENJUJE U SKLADU SA DOSTUPNOŠĆU</strong> CropLab aplikacije.</p>

              <p>(5) U nekim slučajevima, korišćenje posebnih usluga i alata može zahtevati saglasnost za određene uslove korišćenja. Korisnik će biti posebno obavešten o ovim uslovima korišćenja. Svi trenutni uslovi korišćenja su dostupni na početnoj stranici CropLab-a - <a href="https://croplab.info" target="_blank">https://croplab.info</a></p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={9} /> &nbsp;&nbsp;Izmene usluga i CropLab aplikacije</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>
              Kompanija GILAB ima pravo da u bilo kom trenutku izmeni usluge dostupne na CropLab-u koje se besplatno nude, da besplatno ili uz naknadu ponudi nove usluge ili CropLab aplikacije ili da prekine pružanje besplatnih usluga.
              </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={10} /> &nbsp;&nbsp;Zaštita Sadržaja, odgovornost za sadržaj trećeg lica</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) Sadržaj u CropLab aplikaciji koji su dostupni na CropLab-u zaštićeni su autorskim pravima ili drugim svojinskim pravima i svojina su kompanije GILAB ili trećih lica koja pružaju dati Sadržaj. Ove Sadržaje i CropLab aplikaciju možete koristiti samo u skladu sa ovim Uslovima korišćenja, dodatnim posebnim uslovima korišćenja za usluge, ako je primenljivo, kao i unutar obima propisanog na CropLab-u.</p>

              <p>(2) Sadržaj dostupan na CropLab-u delom pruža kompanija GILAB, a delom treća lica. Sadržaj koji pružaju treća lica se u daljem tekstu označava kao „Sadržaj trećeg lica”. <strong>KOMPANIJA GILAB NE PROVERAVA POTPUNOST, TAČNOST I ZAKONITOST SADRŽAJA TREĆEG LICA. KOMPANIJA GILAB STOGA NE PREUZIMA NIKAKVU ODGOVORNOST ZA POTPUNOST, TAČNOST I ZAKONITOST SADRŽAJA TREĆEG LICA.</strong> Ovo se takođe odnosi na kvalitet Sadržaja trećeg lica i njegovu podobnost za konkretnu svrhu, posebno što se tiče Sadržaja trećeg lica na povezanim spoljnim veb-lokacijama. Sadržaj koji nije izričito identifikovan kao Sadržaj trećeg lica pruža kompanija GILAB.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={11} /> &nbsp;&nbsp;Tehnički resursi, praćenje aktivnosti korisnika</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) Vi ste odgovorni za obezbeđivanje odgovarajućih tehničkih resursa za korišćenje CropLab aplikacije. Kompanija GILAB vam ne duguje nikakav savet u vezi sa tim.</p>

              <p>(2) Kompanija GILAB vas ovim obaveštava da se vaše korisničke aktivnosti mogu skladištiti i koristiti, u meri koja je zakonom dozvoljena, za poboljšanje korisničkog iskustva i dalji razvoj CropLab-a. Ovo može obuhvatati beleženje i procenu podataka o IP vezi u zakonom dozvoljenom obimu ako postoje razlozi za sumnju da je došlo do kršenja ovih Uslova korišćenja i/ili ako postoje razlozi za sumnju na bilo koju drugu nezakonitu radnju ili krivično delo.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={12} /> &nbsp;&nbsp;Pravo na korišćenje CropLab aplikacije</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) Možete pristupiti CropLab-u samo u skladu sa ovim Uslovima korišćenja, dodatnim posebnim uslovima korišćenja za usluge, ako je primenljivo, kao i unutar obima propisanog na CropLab-u. Nijedno dalje korišćenje nije dozvoljeno ukoliko se kompanija GILAB nije saglasila prema korisniku u pisanom ili tekstualnom obliku.</p>

              <p>(2) Imate pravo na preuzimanje ili štampanje Sadržaja samo ako CropLab sadrži funkcije koje omogućavaju takvo preuzimanje ili štampanje (npr. pomoću dugmeta za preuzimanje).</p>

              <p>
              Biće vam dodeljeno neograničeno i neekskluzivno pravo korišćenja u vaše lične, nekomercijalne svrhe za Sadržaj koji ste preuzeli ili štampali u skladu sa ovim Uslovima korišćenja. Originalni nosilac prava (kompanija GILAB ili odgovarajuće treće lice) zadržava sva ostala prava na Sadržaj.
              </p>

              <p>(3) U pogledu korišćenja CropLab aplikacije, takođe se primenjuje sledeće: </p>

              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="CropLab aplikacija se ne sme kopirati niti reprodukovati u celini ili delimično;" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="ne sme se menjati, uređivati ili prilagođavati u celini ili delimično;" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="obaveštenja o autorskim pravima priložena ili sadržana u CropLab aplikaciji ne smeju se uklanjati ili menjati" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="nije dozvoljeno nikakvo obrnuto inženjerstvo aplikacije." />
                </ListItem>
               
              </List>

              <p>(4) 
              CropLab aplikacija i sadržaj izveden iz CropLab aplikacije su zaštićeni autorskim pravima i/ili drugim pravima intelektualne svojine. Ovim potvrđujete da vam korišćenje aplikacije ne daje nikakva prava na softver, robne marke ili sadržaj u vezi sa CropLab aplikacijom i da je možete koristiti samo u potpunoj saglasnosti sa ovim Uslovima korišćenja. Obavezna zakonska prava ostaju nepromenjena.
              </p>

						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={13} /> &nbsp;&nbsp;Blokiranje naloga</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) Kompanija GILAB može privremeno ili trajno blokirati vaš CropLab nalog ukoliko:</p>

               <p style={{marginLeft: '10px'}}>(a) dođe do raskida ugovornog odnosa stvorenog ovim Uslovima korišćenja. U ovom slučaju, vaš nalog će biti blokiran čim raskid stupi na snagu.</p>
              <p style={{marginLeft: '10px'}}>(b) postoje specifične naznake da kršite ove Uslove korišćenja;</p>
              <p style={{marginLeft: '10px'}}>(c) kompanija GILAB inače ima legitiman interes da blokira vaš nalog, na primer, ako prekršite ili ste prekršili važeći zakon pri korišćenju CropLab-a ili ako postoje tehnički razlozi koji zahtevaju da se vaš nalog blokira.</p>


              <p>(2) Ukoliko je vaš nalog privremeno ili trajno blokiran, kompanija GILAB blokira vaše pravo pristupa. Trajno blokirano pravo pristupa ne može se ponovo uspostaviti. Trajno blokirane osobe su isključene iz korišćenja CropLab-a i ne mogu se ponovo registrovati na CropLab.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={14} /> &nbsp;&nbsp;Zaštita podataka</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>Standardi kvaliteta kompanije GILAB zahtevaju odgovorno rukovanje ličnim podacima korisnika. Prilikom obrade podataka, kompanija GILAB se pridržava svih zakonskih odredbi. Politika zaštite podataka dostupna na veb lokaciji kompanije GILAB sadrži pregled vrste podataka koji se prikupljaju, na koji se način podaci koriste i prenose i na koji način možete zahtevati otkrivanje podataka koje pruža kompanija GILAB.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={15} /> &nbsp;&nbsp;NEPRIHVATANJE I OGRANIČENJE ODGOVORNOSTI</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) IZUZEV U OBIMU IZRIČITO NAVEDENOM U OVIM USLOVIMA KORIŠĆENJA, KOMPANIJA GILAB VAM NE DAJE NIKAKVE GARANCIJE, IZJAVE, OBEĆANJA ILI DRUGA OBAVEZIVANJA U VEZI SA:</p>

               <p style={{marginLeft: '10px'}}>(a) DOSTUPNOŠĆU, FUNKCIONALNOŠĆU, UČINKOM, BRZINAMA PRENOSA, SADRŽAJEM, KAŠNJENJEM, TAČNOŠĆU I/ILI BEZBEDNOŠĆU CropLab-aplikacije ILI</p>
              <p style={{marginLeft: '10px'}}>(b) KVALITETOM I/ILI POUZDANOŠĆU BILO KOG SADRŽAJA, REZULTATA ILI PREPORUKA GENERISANIH I/ILI PREGLEDANIH POMOĆU CropLab aplikacije.</p>
              <p>(2) SVE DRUGE GARANCIJE, USLOVI, IZJAVE, OBEĆANJA I/ILI OBAVEZIVANJA (BILO PISANI ILI USMENI, IZRIČITI ILI PODRAZUMEVANI STATUTOM, OPŠTIM PRAVOM, OBIČAJEM, TRGOVINSKOM UPOTREBOM, POSLOVANJEM ILI NA NEKI DRUGI NAČIN, UKLJUČUJUĆI I ONE U POGLEDU ZADOVOLJAVAJUĆEG KVALITETA, PODOBNOSTI ZA ODREĐENU SVRHU ILI UPOTREBU, TAČNOSTI, ADEKVATNOSTI, POTPUNOSTI ILI BLAGOVREMENOSTI) OVIM SU ISKLJUČENI U NAJVEĆOJ MERI DOZVOLJENOJ VAŽEĆIM ZAKONOM.</p>

              <p>(3) NIŠTA U OVIM USLOVIMA UPOTREBE NEĆE OGRANIČITI ILI ISKLJUČITI:</p>
              <p style={{marginLeft: '10px'}}>(a) VAŠA ZAKONSKA PRAVA ILI</p>
              <p style={{marginLeft: '10px'}}>(b) VAŠU ILI ODGOVORNOST KOMPANIJE GILAB U POGLEDU BILO KAKVIH POTRAŽIVANJA: (I) VEZANIH ZA SMRT ILI TELESNE POVREDE PROUZROKOVANE NEMAROM TE STRANE (ILI NJENOG OSOBLJA); (II) PROIZAŠLIH IZ BILO KAKVE PREVARE (UKLJUČUJUĆI LAŽNO PREDSTAVLJANJE) KOJU JE UČINILA TA STRANA I (III) ZA KOJE ODGOVORNOST NE MOŽE BITI NA DRUGI NAČIN OGRANIČENA ILI ISKLJUČENA PREMA VAŽEĆIM ZAKONIMA.</p>

              <p>(4) U SKLADU SA <Chip component={'span'} variant="filled" size="small" color="primary" label={15} />(3), KOMPANIJA GILAB OVIM ISKLJUČUJE SVU ODGOVORNOST PREMA VAMA ZA BILO KAKAV GUBITAK ILI ŠTETU NASTALU U VEZI SA OVIM USLOVIMA KORIŠĆENJA I CropLab-om, BILO PO OSNOVU UGOVORA, DELIKTA (UKLJUČUJUĆI NEMAR), ZAKONA ILI NA DRUGI NAČIN. POSEBNO, KOMPANIJA GILAB ISKLJUČUJE SVU ODGOVORNOST:</p>

              <p style={{marginLeft: '10px'}}>(a) VEZANU ZA: (I) KORIŠĆENJE CropLab-a, CropLab aplikacije I DRUGOG SADRŽAJA; (II) NEDOSTUPNOST ILI NEPRISTUPAČNOST CropLab-a, CropLab aplikacije I SADRŽAJA; (III) BILO KAKVA OTKAZIVANJA MREŽE I/ILI INFRASTRUKTURE TREĆEG LICA KOJA SE KORISTI U VEZI SA CropLab-om; (IV) BILO KOJE POGREŠNE, NETAČNE, OŠTEĆENE, NEPOTPUNE, NEDOSTAVLJENE, POGREŠNO UPUĆENE ILI POGREŠNO OZNAČENE SADRŽAJE ILI DRUGE PODATKE; (V) BILO KOJI VIRUS ILI ŠTETNE KOMPONENTE ILI GUBITAK ILI OŠTEĆENJE VAŠIH SISTEMA I/ILI (VI) BILO KAKAV NEOVLAŠĆENI PRISTUP I/ILI PRODOR CropLab-a I/ILI</p>
              <p style={{marginLeft: '10px'}}>(b) ZA: (I) GUBITAK DOBITI ILI PRIHODA; (II) GUBITAK PREDVIĐENE UŠTEDE; (III) GUBITAK UGOVORA, POSLA ILI MOGUĆNOSTI; (IV) GUBITAK, UNIŠTAVANJE ILI OŠTEĆENJE PODATAKA; (V) GUBITAK UGLEDA ILI REPUTACIJE; (VI) INDIREKTNI ILI POSLEDIČNI GUBITAK ILI OŠTEĆENJE BILO KOJE PRIRODE, UKLJUČUJUĆI SVE GUBITKE OPISANE U STAVKAMA OD (I) DO (V) KOJI BI SE MOGLI SMATRATI INDIREKTNIM ILI POSLEDIČNIM, U SVAKOJ STAVCI OD (I) DO (VI) BEZ OBZIRA NA TO DA LI SU ILI NE RAZUMNO PREDVIDIVI, RAZUMNO RAZMOTRENI, STVARNO PREDVIĐENI ILI STVARNO RAZMOTRENI OD STRANE VAS ILI KOMPANIJE GILAB U VREME KADA STE PRIHVATILI OVE USLOVE KORIŠĆENJA.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={16} /> &nbsp;&nbsp;Obaveštenja</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>Ukoliko nije izričito drugačije dogovoreno u ovim Uslovima korišćenja, sva obaveštenja u kontekstu korišćenja CropLab-a biće vam poslata u pisanoj formi na fizičku adresu ili putem e-pošte.</p>
              <p>Adresa e-pošte kompanije GILAB je: <a href="mailto:team@gilab.rs">team@gilab.rs</a> Poštanska adresa kompanije GILAB je: Dragoslava Srejovića 94, Beograd, Srbija. Ovi kontakt podaci se mogu promeniti. U tom slučaju, kompanija GILAB će vas obavestiti o tim promenama.</p>
              <p>Vaša adresa e-pošte i poštanska adresa je ona koju ste uneli kada ste se registrovali za korišćenje CropLab-a. Ukoliko dođe do promene vaših kontakt podataka, molimo vas da nas obavestite o takvim promenama ILI da ažurirate svoj profil na CropLab-u.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={17} /> &nbsp;&nbsp;Celovitost ugovora i odvojivost odredbi</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>Ovi Uslovi korišćenja predstavljaju ceo ugovor između vas i kompanije GILAB u pogledu pristupa i korišćenja CropLab-a.</p>
              <p>Ako odredba ovih Uslova korišćenja jeste ili postane nevažeća, nezakonita ili neizvršiva, zakonska valjanost i izvršivost preostalih odredbi ostaju nepromenjene. Nevažeća, nezakonita ili neizvršiva odredba smatraće se izmenjenom u minimalnom obimu koji je neophodan da bi bila važeća, zakonita i izvršiva. Ukoliko takva izmena nije moguća, relevantna odredba će se smatrati izbrisanom.</p>

						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={18} /> &nbsp;&nbsp;Važeće pravo</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>Ovi Uslovi korišćenja i bilo kakvi sporovi ili zahtevi koji proizilaze iz ili u vezi sa ovim Uslovima korišćenja i/ili sa CropLab-om (uključujući vanugovorne sporove ili zahteve) uređuju se zakonima Republike Srbije o ugovorima i pružanju usluga.</p>


						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={19} /> &nbsp;&nbsp;Sporovi i nadležnost</Typography>
						</Grid>


            <Grid item xs={12}>
              <p>Isključiva mesna nadležnost za sve sporove koji proizađu iz ovih Uslova korišćenja je, koliko je to zakonom dozvoljeno.</p>

              <p>Niko osim vas ili nas nema pravo da na osnovu Zakona o ugovorima (Prava trećih lica) iz 1998. godine izvršava bilo koji od ovih Uslova korišćenja.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={20} /> &nbsp;&nbsp;Odricanje</Typography>
						</Grid>


            <Grid item xs={12}>
              <p>Odricanje od bilo kog prava, ovlašćenja, privilegije ili pravnog leka (bilo prema ovim Uslovima korišćenja, opštem pravu, pravičnosti, statutu ili na neki drugi način) stupa na snagu samo ako je dato obaveštavanjem i neće se smatrati odricanjem od bilo kog sledećeg kršenja ili neizvršenja.</p>


						</Grid>

            
					</Grid>
    </Container>
  )
}

export default TermsAndConditionsRoute;