import { Close, Delete, Download, Edit, Event, FileDownload, FilePresent, Refresh } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import Message from "../ui/Message";
import Divider from "../auth/Divider";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";
import { Box } from "@mui/system";
import _ from "lodash";
import ConfirmDialog from "../ui/ConfirmDialog";


function DocumentsModal(props) {
  const {lang} = useContext(AppContext);
  const downloadLink = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = useMergeState({documents: [], count: 0, rowsPerPage: 10, page: 0, deleteDocument: false, documentId: null });
  const [filter, setFilter] = useMergeState({name: '', tags: []})

  const [edit, setEdit] = useMergeState({})
  
  useEffect(() => {
    if(props.open) {
   
      window.history.replaceState('', '', window.location.href.replace(window.location.search, ''))
      setState({loading: true})
      $data.getDocuments()
        .then(data => {
          setState({loading: false, documents: data, count: data.length, page: 0})

        })
        .catch(err => {console.log(err); setState({loading: false})
      })
    }
    setEdit(null)
  }, [props.open])

  const onHandlePageChange = (evt, pg) => {
    setState({page: pg})
  }

  const onDeleteDocument = (id) => {
    $data.deleteDocument(id)
      .then(data => {
        setState({success: lang.doc_success_delete, loading: true});

        $data.getDocuments()
          .then(data => { setState({loading: false, documents: data, count: data.length, page: 0, deleteDocument: false, documentId: null}) })
          .catch(err => {console.log(err); setState({loading: false})
        })
      })
      .catch(err => {
        setState({error: err.message.non_field_errors || lang.doc_error_delete, loading: false})
      })
  }

  const filterDocuments = () => {
    let list = state.documents;

    if(filter.name !== '') {
      list = state.documents.filter(obj => {
        if(obj.name) {
          if(obj.name.toLowerCase().trim().indexOf(filter.name.toLowerCase().trim()) > -1) return true
        }

        return false;
      })
    }

    if(filter.tags.length > 0) {
      list = list.filter(obj => {
        if(!obj.tags) return false;
        return obj.tags.split('; ').some(r => filter.tags.indexOf(r) > -1)
      })
    }

    return list

  }

  const onChange = (editKey, name, value, isRemove) => {
    setEdit({[editKey]: {
      ...edit[editKey],
      [name]: value
    }})
  }

  const onHandleUpdate = (editKey) => {
    $data.patchDocument(edit[editKey], editKey)
      .then(data => {
        setState({success: lang.doc_success_update, loading: true});

        $data.getDocuments()
          .then(data => {setEdit(null); setState({loading: false, documents: data, count: data.length, page: 0, deleteDocument: false, documentId: null}) })
          .catch(err => {console.log(err); setState({loading: false})
        })
      })
      .catch(err => {
        setState({error: err.non_field_errors || lang.doc_error_update, loading: false})
      })
  }

  const renderDocuments = () => {
    if(state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }

    if(state.documents.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            {lang.no_documents}
          </TableCell>
       </TableRow>
      )
    }

    let filtered = filterDocuments().filter((obj, key) => {
      return key < (state.page + 1) * 10 && key >= state.page * 10
    });

    if(filtered.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            {lang.no_documents_filter}
          </TableCell>
       </TableRow>
      )
    }

    return filtered.map((obj, key) => {
      if(edit[obj.pk]) {
        return (
          <TableRow key={key}>
            <TableCell>{(key + 1) + (state.page) * 10}</TableCell>
            <TableCell>
              <TextField placeholder={lang.document_name} value={edit[obj.pk].name || ''} onChange={(evt) => onChange(obj.pk, 'name', evt.target.value)} size="small" />
            </TableCell>
            <TableCell>
              <TextField placeholder={lang.document_description} value={edit[obj.pk].description || ''} onChange={(evt) => onChange(obj.pk, 'description', evt.target.value)} size="small" />
            </TableCell>
            <TableCell>
                <Autocomplete multiple size="small" fullWidth
                  freeSolo
                  defaultValue={[]}
                  value={edit[obj.pk]['tags']}
                  onChange={(evt, val, reason) => {if(reason === 'createOption' || reason === 'removeOption') {onChange(obj.pk, 'tags', val, reason === 'removeOption')}}}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip size="small" variant="filled" color="warning" label={option} {...getTagProps({index})}/>)}
                  renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth label={lang.document_tags}/>
                  )}
                  options={[]} />
            </TableCell>
            <TableCell>
              <Stack direction="row">
                <LoadingButton onClick={() => onHandleUpdate(obj.pk)} size="small">{lang.confirm}</LoadingButton>
                <LoadingButton onClick={() => setEdit({[obj.pk]: null})} size="small" color="error">{lang.cancel}</LoadingButton>
              </Stack>
            </TableCell>
          </TableRow>
        )
      }

      return (
        <TableRow key={key}>
          <TableCell>{(key + 1) + (state.page) * 10}</TableCell>
          <TableCell>{obj.name || ' - '}</TableCell>
          <TableCell>{obj.description || ' - '}</TableCell>
          <TableCell>{obj.tags ? <Stack gap={.5} direction={'row'} flexWrap="wrap">{obj.tags.split('; ').map((val, key) => <Chip style={{marginRight: '5px'}} key={key} size="small" label={val} color="warning" variant="filled" />)}</Stack> : ' - '}</TableCell>
          <TableCell style={{width: '72px'}}>
            <Tooltip placement="top" title={lang.download_document}>
              <FileDownload onClick={() => {
                $data.downloadDocument(obj.pk)
                  .then(data => {
                    let dataUrl = window.URL.createObjectURL(data)
                    downloadLink.current.href = dataUrl;
                    downloadLink.current.download = obj.filename;
                    downloadLink.current.click();
                  })
                  .catch(err => {
                    console.log(err)
                    setState({error: err.non_field_errors})
                  })
              }} style={{cursor: 'pointer'}} color="primary" />
            </Tooltip>
            <Tooltip placement="top" title={lang.edit_document}>
              <Edit onClick={() => setEdit({[obj.pk]: {...obj, tags: obj.tags === '' ? [] : obj.tags.split('; ')}})} style={{cursor: 'pointer'}} color="warning" />
            </Tooltip>
            <Tooltip placement="top" title={lang.delete_document}>
              <Delete onClick={() => setState({deleteDocument: true, documentId: obj.pk})} style={{cursor: 'pointer'}} color="error" />
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  }

  const renderTags = () => {
    let extracted = state.documents.map(obj => obj.tags);
    let result = [];

    extracted.map(val => {

      let formated = val ? val.split('; ') : [];
      result = _.concat(result, formated);
    })
    return [...new Set(result)]
  }

  const renderDocumentsMobile = () => {
    if(state.loading) {
      return (
        <Grid className="documents-container-mobile" container spacing={2}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      )
    }

    if(state.documents.length === 0) {
      return (
        <Grid className="documents-container-mobile" container spacing={2}>
          <Grid item xs={12}>
          <Paper elevation={3} sx={{p: '10px'}}>
            {lang.no_documents}
            </Paper>
          </Grid>
        </Grid>
      )
    }

    let filtered = filterDocuments().filter((obj, key) => {
      return key < (state.page + 1) * 10 && key >= state.page * 10
    });

    if(filtered.length === 0) {
      return (
        <Grid className="documents-container-mobile" container spacing={2}>
          <Grid item xs={12}>
           <Paper elevation={3} sx={{p: '10px'}}>
            {lang.no_documents_filter}
            </Paper>
          </Grid>
        </Grid>
      )
    }

    return  (
      <Grid className="documents-table-mobile" container spacing={2}>
          <Pagination count={Math.floor(state.count / 10) + 1} onChange={(evt, pg) => onHandlePageChange(evt, pg - 1)} color="primary" page={state.page + 1} />
        {filtered.map((document, key) => {
          return (
            <Grid key={key} item xs={12}>
              <Paper elevation={3} sx={{p: '10px'}}>
            
              <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'} spacing={2}>
                <Stack spacing={2}>
                  <Typography sx={{wordBreak: 'break-all'}} color="primary" variant="body1">{document.name || ' - '}</Typography>
                  <Typography variant="body2">{document.description || ' - '}</Typography>
                  {document.tags ? <Stack gap={.5} direction={'row'} flexWrap="wrap">{document.tags.split('; ').map((val, key) => <Chip style={{marginRight: '5px'}} key={key} size="small" label={val} color="warning" variant="filled" />)}</Stack> : ' - '}
                </Stack> 
                <Stack direction="row" spacing={1}>
                  <FileDownload onClick={() => {
                    $data.downloadDocument(document.pk)
                      .then(data => {
                        let dataUrl = window.URL.createObjectURL(data)
                        downloadLink.current.href = dataUrl;
                        downloadLink.current.download = document.filename;
                        downloadLink.current.click();
                      })
                      .catch(err => {
                        console.log(err)
                        setState({error: err.non_field_errors})
                      })
                  }} style={{cursor: 'pointer'}} color="primary" />
                  <Edit onClick={() => setEdit({[document.pk]: {...document, tags: document.tags === '' ? [] : document.tags.split('; ')}})} style={{cursor: 'pointer'}} color="warning" />
                  <Delete onClick={() => setState({deleteDocument: true, documentId: document.pk})} style={{cursor: 'pointer'}} color="error" />
                </Stack> 
              </Stack>
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    )
    
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.act_documents}
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                 <TextField value={filter.name} onChange={(evt) => setFilter({name: evt.target.value})} size="small" fullWidth label={lang.doc_filter_by_name} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="filtertags">{lang.doc_filter_by_tags}</InputLabel>
                    <Tooltip placement="top" title={filter.tags.join(', ')}>
                      <Select 
                        multiple size="small" labelId="filtertags" 
                        label={lang.doc_filter_by_tags} value={filter.tags} 
                        onChange={(evt) => {
                          let {value} = evt.target;

                          if(value.constructor === String) {
                            setFilter({tags: value.split(',')});
                          }
                      
                          if(value.constructor === Array) {
                            setFilter({tags: value.indexOf('all') > -1 ? [] : value});
                          }
                        }}
                        renderValue={(selected) => (
                          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value, key) => {
                              return <Chip size="small" key={key} label={value} color="warning" />
                            })}
                          </Box>
                        )} fullWidth>
                          <MenuItem value={'all'}>
                            <Checkbox checked={filter.tags.length === 0} />
                            <ListItemText primary={lang.doc_select_all_tags} />
                          </MenuItem>
                        {renderTags().map((val, key) => {
                          
                          return (
                            <MenuItem key={key} value={val}>
                              <Checkbox checked={filter.tags.indexOf(val) > -1} />
                              <ListItemText primary={val} />
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{width: '100%'}} />
            </Grid>
            <Grid item xs={12}>
              {renderDocumentsMobile()}
              <TableContainer className="documents-table-desktop" component={Paper} elevation={2}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                    <TableCell>{lang.num}</TableCell>
                      <TableCell>{lang.document_name}</TableCell>
                      <TableCell>{lang.document_description}</TableCell>
                      <TableCell>{lang.document_tags}</TableCell>
                      <TableCell style={{width: '48px'}}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderDocuments()}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <TablePagination
                          rowsPerPageOptions={[]}
                          component="div"
                          count={state.count}
                          rowsPerPage={10}
                          page={state.page}
                          onPageChange={onHandlePageChange}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="primary" variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
      <a style={{visibility: 'hidden'}} ref={downloadLink}></a>
      <ConfirmDialog question={lang.doc_delete_question} title={lang.delete_document} onClose={() => setState({deleteDocument: false, documentId: null})} onSubmit={() => onDeleteDocument(state.documentId)} open={state.deleteDocument} />
      <Message type="error" open={state.error ? true : false} message={state.error} onClose={() => setState({error: null})} />
      <Message type="success" open={state.success ? true : false} message={state.success} onClose={() => setState({success: null})} />

    </Dialog>
  )
}

export default DocumentsModal;