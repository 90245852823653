import clearsky_day from '../images/weather/clearsky_day.svg';
import clearsky_night from '../images/weather/clearsky_night.svg';
import clearsky_polartwilight from '../images/weather/clearsky_polartwilight.svg';
import cloudy from '../images/weather/cloudy.svg';
import fair_day from '../images/weather/fair_day.svg';
import fair_night from '../images/weather/fair_night.svg';
import fair_polartwilight from '../images/weather/fair_polartwilight.svg';
import fog from '../images/weather/fog.svg';
import heavyrainandthunder from '../images/weather/heavyrainandthunder.svg';
import heavyrainshowersandthunder_day from '../images/weather/heavyrainshowersandthunder_day.svg';
import heavyrainshowersandthunder_night from '../images/weather/heavyrainshowersandthunder_night.svg';
import heavyrainshowersandthunder_polartwilight from '../images/weather/heavyrainshowersandthunder_polartwilight.svg';
import heavyrainshowers_day from '../images/weather/heavyrainshowers_day.svg';
import heavyrainshowers_night from '../images/weather/heavyrainshowers_night.svg';
import heavyrainshowers_polartwilight from '../images/weather/heavyrainshowers_polartwilight.svg';
import heavyrain from '../images/weather/heavyrain.svg';
import heavysleetandthunder from '../images/weather/heavysleetandthunder.svg';
import heavysleetshowersandthunder_day from '../images/weather/heavysleetshowersandthunder_day.svg';
import heavysleetshowersandthunder_night from '../images/weather/heavysleetshowersandthunder_night.svg';
import heavysleetshowersandthunder_polartwilight from '../images/weather/heavysleetshowersandthunder_polartwilight.svg';
import heavysleetshowers_day from '../images/weather/heavysleetshowers_day.svg';
import heavysleetshowers_night from '../images/weather/heavysleetshowers_night.svg';
import heavysleetshowers_polartwilight from '../images/weather/heavysleetshowers_polartwilight.svg';
import heavysleet from '../images/weather/heavysleet.svg';
import heavysnowandthunder from '../images/weather/heavysnowandthunder.svg';
import heavysnowshowersandthunder_day from '../images/weather/heavysnowshowersandthunder_day.svg';
import heavysnowshowersandthunder_night from '../images/weather/heavysnowshowersandthunder_night.svg';
import heavysnowshowersandthunder_polartwilight from '../images/weather/heavysnowshowersandthunder_polartwilight.svg';
import heavysnowshowers_day from '../images/weather/heavysnowshowers_day.svg';
import heavysnowshowers_night from '../images/weather/heavysnowshowers_night.svg';
import heavysnowshowers_polartwilight from '../images/weather/heavysnowshowers_polartwilight.svg';
import heavysnow from '../images/weather/heavysnow.svg';
import lightrainandthunder from '../images/weather/lightrainandthunder.svg';
import lightrainshowersandthunder_day from '../images/weather/lightrainshowersandthunder_day.svg';
import lightrainshowersandthunder_night from '../images/weather/lightrainshowersandthunder_night.svg';
import lightrainshowersandthunder_polartwilight from '../images/weather/lightrainshowersandthunder_polartwilight.svg';
import lightrainshowers_day from '../images/weather/lightrainshowers_day.svg';
import lightrainshowers_night from '../images/weather/lightrainshowers_night.svg';
import lightrainshowers_polartwilight from '../images/weather/lightrainshowers_polartwilight.svg';
import lightrain from '../images/weather/lightrain.svg';
import lightsleetandthunder from '../images/weather/lightsleetandthunder.svg';
import lightsleetshowers_day from '../images/weather/lightsleetshowers_day.svg';
import lightsleetshowers_night from '../images/weather/lightsleetshowers_night.svg';
import lightsleetshowers_polartwilight from '../images/weather/lightsleetshowers_polartwilight.svg';
import lightsleet from '../images/weather/lightsleet.svg';
import lightsnowandthunder from '../images/weather/lightsnowandthunder.svg';
import lightsnowshowers_day from '../images/weather/lightsnowshowers_day.svg';
import lightsnowshowers_night from '../images/weather/lightsnowshowers_night.svg';
import lightsnowshowers_polartwilight from '../images/weather/lightsnowshowers_polartwilight.svg';
import lightsnow from '../images/weather/lightsnow.svg';
import lightssleetshowersandthunder_day from '../images/weather/lightssleetshowersandthunder_day.svg';
import lightssleetshowersandthunder_night from '../images/weather/lightssleetshowersandthunder_night.svg';
import lightssleetshowersandthunder_polartwilight from '../images/weather/lightssleetshowersandthunder_polartwilight.svg';
import lightssnowshowersandthunder_day from '../images/weather/lightssnowshowersandthunder_day.svg';
import lightssnowshowersandthunder_night from '../images/weather/lightssnowshowersandthunder_night.svg';
import lightssnowshowersandthunder_polartwilight from '../images/weather/lightssnowshowersandthunder_polartwilight.svg';
import partlycloudy_day from '../images/weather/partlycloudy_day.svg';
import partlycloudy_night from '../images/weather/partlycloudy_night.svg';
import partlycloudy_polartwilight from '../images/weather/partlycloudy_polartwilight.svg';
import rainandthunder from '../images/weather/rainandthunder.svg';
import rainshowersandthunder_day from '../images/weather/rainshowersandthunder_day.svg';
import rainshowersandthunder_night from '../images/weather/rainshowersandthunder_night.svg';
import rainshowersandthunder_polartwilight from '../images/weather/rainshowersandthunder_polartwilight.svg';
import rainshowers_day from '../images/weather/rainshowers_day.svg';
import rainshowers_night from '../images/weather/rainshowers_night.svg';
import rainshowers_polartwilight from '../images/weather/rainshowers_polartwilight.svg';
import rain from '../images/weather/rain.svg';
import sleetandthunder from '../images/weather/sleetandthunder.svg';
import sleetshowersandthunder_day from '../images/weather/sleetshowersandthunder_day.svg';
import sleetshowersandthunder_night from '../images/weather/sleetshowersandthunder_night.svg';
import sleetshowersandthunder_polartwilight from '../images/weather/sleetshowersandthunder_polartwilight.svg';
import sleetshowers_day from '../images/weather/sleetshowers_day.svg';
import sleetshowers_night from '../images/weather/sleetshowers_night.svg';
import sleetshowers_polartwilight from '../images/weather/sleetshowers_polartwilight.svg';
import sleet from '../images/weather/sleet.svg';
import snowandthunder from '../images/weather/snowandthunder.svg';
import snowshowersandthunder_day from '../images/weather/snowshowersandthunder_day.svg';
import snowshowersandthunder_night from '../images/weather/snowshowersandthunder_night.svg';
import snowshowersandthunder_polartwilight from '../images/weather/snowshowersandthunder_polartwilight.svg';
import snowshowers_day from '../images/weather/snowshowers_day.svg';
import snowshowers_night from '../images/weather/snowshowers_night.svg';
import snowshowers_polartwilight from '../images/weather/snowshowers_polartwilight.svg';
import snow from '../images/weather/snow.svg';

import fieldcrop from '../images/groups/fieldcrop.webp';
import vegetables from '../images/groups/vegetables.webp';
import viticulture from '../images/groups/viticulture.webp';
import fruit from '../images/groups/fruit.webp';
import covercrop from '../images/groups/covercrop.webp';

const crop = {
  1: 'rgb(0,255,0)',
  2: 'rgb(255,165,0)',
  3: 'rgb(255,255,0)',
  4: 'rgb(0,0,255)',
  5: 'rgb(255,0,0)',
  6: 'rgb(165,42,42)',
  7: 'rgb(128,0,128)',
  8: 'rgb(128,128,128)',
  9: 'rgb(27, 198, 249)',
  10: 'rgb(27, 198, 249)',
  11: 'rgb(255, 123, 155)',
  12: 'rgb(123, 111, 23)',
  13: '#fff',
  14: '#808000'
}

const cropGroup = {
  1: '#ffa822', //FIELDCROP
  2: '#00b300', //VEGETABLES
  3: '#cc0022', //FRUIT
  4: '#2a6fdb', //VITICULTURE
  5: '#b5b5b5', //OTHER
  6: '#9d4e15',
  7: '#473417',//COVER CROP
  8: '#ce8b54' //COVER CROP
}

const groupImages = {
  1: fieldcrop, //FIELDCROP
  2: vegetables, //VEGETABLES
  3: fruit, //FRUIT
  4: viticulture, //VITICULTURE
  5: null, //OTHER
  6: covercrop,
  7: covercrop,
  8: covercrop, //COVER CROP
}

const queryColors = ['#D32F2F', '#ff777c','#ED6C02', '#FFC000'];

const weather = {
  clearsky_day,
  clearsky_night,
  clearsky_polartwilight,
  cloudy,
  fair_day,
  fair_night,
  fair_polartwilight,
  fog,
  heavyrainandthunder,
  heavyrainshowersandthunder_day,
  heavyrainshowersandthunder_night,
  heavyrainshowersandthunder_polartwilight,
  heavyrainshowers_day,
  heavyrainshowers_night,
  heavyrainshowers_polartwilight,
  heavyrain,
  heavysleetandthunder,
  heavysleetshowersandthunder_day,
  heavysleetshowersandthunder_night,
  heavysleetshowersandthunder_polartwilight,
  heavysleetshowers_day,
  heavysleetshowers_night,
  heavysleetshowers_polartwilight,
  heavysleet,
  heavysnowandthunder,
  heavysnowshowersandthunder_day,
  heavysnowshowersandthunder_night,
  heavysnowshowersandthunder_polartwilight,
  heavysnowshowers_day,
  heavysnowshowers_night,
  heavysnowshowers_polartwilight,
  heavysnow,
  lightrainandthunder,
  lightrainshowersandthunder_day,
  lightrainshowersandthunder_night,
  lightrainshowersandthunder_polartwilight,
  lightrainshowers_day,
  lightrainshowers_night,
  lightrainshowers_polartwilight,
  lightrain,
  lightsleetandthunder,
  lightsleetshowers_day,
  lightsleetshowers_night,
  lightsleetshowers_polartwilight,
  lightsleet,
  lightsnowandthunder,
  lightsnowshowers_day,
  lightsnowshowers_night,
  lightsnowshowers_polartwilight,
  lightsnow,
  lightssleetshowersandthunder_day,
  lightssleetshowersandthunder_night,
  lightssleetshowersandthunder_polartwilight,
  lightssnowshowersandthunder_day,
  lightssnowshowersandthunder_night,
  lightssnowshowersandthunder_polartwilight,
  partlycloudy_day,
  partlycloudy_night,
  partlycloudy_polartwilight,
  rainandthunder,
  rainshowersandthunder_day,
  rainshowersandthunder_night,
  rainshowersandthunder_polartwilight,
  rainshowers_day,
  rainshowers_night,
  rainshowers_polartwilight,
  rain,
  sleetandthunder,
  sleetshowersandthunder_day,
  sleetshowersandthunder_night,
  sleetshowersandthunder_polartwilight,
  sleetshowers_day,
  sleetshowers_night,
  sleetshowers_polartwilight,
  sleet,
  snowandthunder,
  snowshowersandthunder_day,
  snowshowersandthunder_night,
  snowshowersandthunder_polartwilight,
  snowshowers_day,
  snowshowers_night,
  snowshowers_polartwilight,
  snow
}





export default {crop, cropGroup, groupImages, weather, queryColors}