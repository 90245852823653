import { Stack } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";




function BatteryLevel({level}) {
  const bodyStyle = {
    height: '30px',
    background: 'rgba(0,0,0,.1)',
    width: '50px',
    color: 'rgba(0, 0, 0, .5)',
    textAlign: 'center',
    lineHeight: '30px',
    borderRadius: '3px'
  
  }
  
  const peakStyle = {
    height: '15px',
    width: '5px',
    background: 'rgba(0,0,0,.1)',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px'
  }
  
  const levelStyle = {
    zIndex: 2,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: '3px'
  
  }

  const {secondary} = useContext(AppContext)
  return (
    <Stack sx={{p: '10px'}} direction="row" alignItems="center">
      <div style={{...bodyStyle, position: 'relative'}}>
        {Boolean(level) && <div style={{...levelStyle, width: `${level}%`, background: secondary}}></div>}
        <span style={{fontSize: '12px', position: 'absolute', color: 'rgba(0, 0, 0, .5)', zIndex: 3, left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>{level}%</span>
      </div>
      
      <div style={{...peakStyle, background: level === 100 ? secondary : peakStyle.background}}></div>
    </Stack>
  )
}

export default BatteryLevel;