import { LoadingButton } from "@mui/lab";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";

function EmployeeForm(props) {
  const context = useContext(AppContext);
  const {lang, primary, onUpdateOperators} = context;

  let initial = props.model ? {...props.model} : {};

  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);

  const [model, setModel] = useMergeState(Object.assign({
    first_name: '',
    last_name: '',
    email: '',
    additional_user_email: '',
    phone: '',
    isManager: false,
    isOperater: true,
    production_diary: false,
    ...initial
  }, props.type === 'edit' ? {} : {password: ''}));
  
  const onHandleEmployee = () => {
    setLoading(true);
    setError({});
    if(props.type === 'add') {
      $data.postEmployee(model)
        .then(data => {
          setLoading(false);
          if(model.isOperater) {
            onUpdateOperators()
          }          
          props.onClose();
          props.refresh();
          props.onSuccess(lang.succes_create_employee)
          
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      
      return;
    }

    if(props.type === 'edit') {
      $data.putEmployee(model)
        .then(data => {
          setLoading(false);
          if(model.isOperater) {
            onUpdateOperators()
          }
          props.onClose();
          props.refresh();
          props.onSuccess(lang.succes_edit_employee)
          
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      return;
    }

    if(props.type === 'delete') {
      $data.deleteEmployee(model.pk)
        .then(data => {
          setLoading(false);
          if(model.isOperater) {
            onUpdateOperators()
          }
          props.onClose();
          props.refresh();
          props.onSuccess(lang.success_delete_employee);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      return;
    }
  }

  return (
    <Grid sx={{'& .employee-input': {mb: '14px'}}} style={{padding: '10px'}} component={'form'} onSubmit={(evt) => {evt.preventDefault(); onHandleEmployee()}}>
      <Grid className="employee-input" item xs={12}>
        {props.type === 'add' && <h3 style={{color: primary}}>{lang.add_employee}</h3>}
        {props.type === 'edit' && <h3 style={{color: primary}}>{lang.edit_employee}</h3>}
        {props.type === 'delete' && <h3 style={{color: primary}}>{lang.delete_employee}</h3>}
      </Grid>

      <Grid className="employee-input" item xs={12}>
        <TextField size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.first_name !== undefined || error.non_field_errors !== undefined} helperText={error.first_name} fullWidth label={lang.first_name} type='text' value={model.first_name} onChange={(evt) => setModel({first_name: evt.target.value})} />      
      </Grid>
      <Grid className="employee-input" item xs={12}>
        <TextField size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.last_name !== undefined || error.non_field_errors !== undefined} helperText={error.last_name} fullWidth label={lang.last_name} type='text' value={model.last_name} onChange={(evt) => setModel({last_name: evt.target.value})} />      
      </Grid>
      {props.type === 'add' && <Grid className="employee-input" item xs={12}>
        <TextField size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.email !== undefined || error.non_field_errors !== undefined} helperText={error.email} fullWidth label={lang.email} type='email' value={model.email} onChange={(evt) => setModel({email: evt.target.value})} />      
      </Grid>}
      {props.type === 'add' && <Grid className="employee-input" item xs={12}>
        <TextField size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.password !== undefined || error.non_field_errors !== undefined} helperText={error.password} fullWidth label={lang.password} type='password' value={model.password} onChange={(evt) => setModel({password: evt.target.value})} />      
      </Grid>}
      {props.type === 'edit' && <Grid className="employee-input" item xs={12}>
        <TextField size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.additional_user_email !== undefined || error.non_field_errors !== undefined} helperText={error.additional_user_email} fullWidth label={lang.email} type='email' value={model.additional_user_email} onChange={(evt) => setModel({additional_user_email: evt.target.value})} />      
      </Grid>}
      <Grid className="employee-input" item xs={12}>
        <TextField size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.phone !== undefined || error.non_field_errors !== undefined} helperText={error.phone} fullWidth label={lang.phone} type='text' value={model.phone} onChange={(evt) => setModel({phone: evt.target.value})} />      
      </Grid>
      {/* <Grid className="employee-input" item xs={12}>
        <FormControl style={{padding: '0 14px'}}>
          <FormLabel id="demo-radio-buttons-group-label">{lang.currency}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="eur"
            name="radio-buttons-group">
            <FormControlLabel value="manager" control={<Radio inputProps={{disabled: props.type === 'delete'}} onChange={(evt) => setModel({isManager: true, production_diary: true, isOperater: false})} checked={model.isManager && model.production_diary} />} label={lang.manager} />
            <FormControlLabel value="operator" control={<Radio inputProps={{disabled: props.type === 'delete'}} onChange={(evt) => setModel({isManager: false, production_diary: false, isOperater: true})} checked={model.isOperater} />} label={lang.operator} />
          </RadioGroup>
        </FormControl>
      </Grid> */}
      <Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} className="employee-input" item xs={12}>
      <LoadingButton size="small" loading={loading} type="submit" variant="contained">{props.type !== 'delete' ? lang.confirm : lang.delete}</LoadingButton>
        <LoadingButton size="small" loading={loading} onClick={props.onClose} type="button" color="error" variant="contained">{lang.close}</LoadingButton>
      </Grid>

      <Message type="error" open={error.non_field_errors !== undefined} message={error.non_field_errors} onClose={() => setError({})} />
    </Grid>
  )
}

export default EmployeeForm;