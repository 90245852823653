import { Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../../AppContext";
import { Close, Download, Event } from "@mui/icons-material";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import $data from "../../../services/$data";
import Message from "../../ui/Message";

function DownloadData({ open, onClose, parcelId }) {
  const downloadLink = useRef(null);
  const { lang, locale, parcels } = useContext(AppContext);

  const [state, setState] = useState({
    from: '2018-01-01',
    to: moment().format('YYYY-MM-DD'),
    parcels: parcelId ? [parcelId] : parcels.features.map(p => p.properties.pk)
  });


  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const close = () => {
    if (!loading) {
      onClose();
    }
  }

  const [error, setError] = useState(null);

  const onDownloadProgress = (evt) => {
    setProgress(Math.round((evt.loaded * 100)/evt.total));
  }
  const submit = async () => {
    setLoading(true);

    try {
      let data = await $data.downloadData({date_from: state.from, date_to: state.to, parcel_ids: state.parcels.join(',')}, onDownloadProgress);

      let blob = new Blob([data],  {type: 'application/zip'});
      let href = window.URL.createObjectURL(blob);
      let filename = 'downloaded_data.zip';
      downloadLink.current.href = href;
      downloadLink.current.download = filename;
      downloadLink.current.click();

    } catch(e) {
      setError(lang.download_error)
    } finally {
      setLoading(false);
      setProgress(null);
    }

  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setError(null);
  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={close}>
      <a ref={downloadLink}></a>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary"> {lang.download_all_data}<Close onClick={close} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>

      <DialogContent>
        <Container sx={{ mt: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => { }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={moment(state.from)} onChange={(newValue) => setState(current => ({ ...current, from: newValue.format('YYYY-MM-DD') }))}
                  renderInput={(params) => {
                    return (
                      <TextField fullWidth {...params} size="small"
                        InputProps={{ endAdornment: (<InputAdornment position="end"> <Event /> </InputAdornment>) }} />
                    )
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => { }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(state.to)} onChange={(newValue) => setState(current => ({ ...current, to: newValue.format('YYYY-MM-DD') }))}
                  renderInput={(params) => {
                    return (
                      <TextField fullWidth {...params} size="small"
                        InputProps={{ endAdornment: (<InputAdornment position="end"> <Event /> </InputAdornment>) }} />
                    )
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}></Grid>

            {!Boolean(parcelId) && <Grid item xs={12}>
              <FormGroup>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox value={''} checked={state.parcels.length === parcels.features.length} onChange={(evt, checked) => {

                      if (checked) {
                        setState(current => ({ ...current, parcels: parcels.features.map(p => p.properties.pk) }))
                      } else {
                        setState(current => ({ ...current, parcels: [] }))
                      }
                    }} />} label={lang.select_all} />

                  </Grid>                  {parcels.features.map((parcel, index) => {
                    let id = parcel.properties.pk;
                    return (
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <FormControlLabel control={<Checkbox value={id} checked={state.parcels.indexOf(id) > -1} onChange={(evt, checked) => {
                          console.log(id);
                          console.log(checked);
                          console.log('==================')
                          if (checked) {
                            setState(current => ({ ...current, parcels: [...state.parcels, id] }))
                          } else {
                            setState(current => ({ ...current, parcels: state.parcels.filter(p => p !== id) }))
                          }
                        }} />} label={parcel.properties.name} />
                      </Grid>
                    )

                  })}

                </Grid>
              </FormGroup>

            </Grid>}
            {(loading && progress !== null) && <Grid style={{padding: '20px 0 0 0'}} item xs={12}>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <LinearProgress style={{width: '100%'}} variant="determinate" value={progress} />
              <Chip color="primary" style={{marginTop: '10px'}} label={`Downloading ${progress}%`} variant="filled"></Chip>
            </Stack>
          </Grid>}

          {(loading && progress === null) && <Grid style={{padding: '20px 0 0 0'}} item xs={12}>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <LinearProgress style={{width: '100%'}} variant="determinate" value={0} />
              <Chip color="primary" style={{marginTop: '10px'}} label={`Preparing data`} variant="filled"></Chip>
            </Stack>
          </Grid>}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={submit} variant="contained" loading={loading} disabled={loading} startIcon={<Download />} size="small">
          {lang.confirm}
        </LoadingButton>
      </DialogActions>

      <Message type="error" open={Boolean(error)} message={error} onClose={() => onCloseMessage()} />

    </Dialog>
  )
}

export default DownloadData;