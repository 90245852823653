import { Refresh, Room } from "@mui/icons-material";
import { CircularProgress, colors, Fab, FormControl, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import {Chart as ChartJS} from 'chart.js';
import {getRelativePosition} from 'chart.js/helpers'
import { AppContext } from "../../../AppContext";
import { formatData, formatOptions } from "../../../config/charts/query";
import moment from 'moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import $data from "../../../services/$data";
ChartJS.register(annotationPlugin)

function Query(props) {
	const chartRef = useRef(null);
	const {lang, user, successColor, danger, dangerLight, warning, warningLight, source, onUpdateState} = useContext(AppContext);

	const sourceRef = useRef(source);

	useEffect(() => {
		sourceRef.current = source;
	}, [source])

	const [view, setView] = useState(false);
	const [isMain, setIsMain] = useState(true);

	const getStatusForProductivity = (val) => {
		console.log(val)
		if((!val || val === -9999) && val !== 0) return '#aaa';
		if(val < -5) return 'rgb(211, 47, 47)';
		if(val >= -5 && val < 5) return '#ffffc0';
		if(val >= 5) return 'green';
	}

	const onClick = function(evt, el, chart) {
	
		try {
			let elements = el;
			let indexes = el.map(obj => obj.datasetIndex);
			let datasetsFilter = chart.data.datasets.filter((dataset, key) => {

				if(indexes.indexOf(key) === -1) return false;

				if(dataset.source === sourceRef.current) {
					return true
				} 

				elements.splice(key, 1);
				return false
			});

			if(datasetsFilter.length !== 0)  {
				let date = datasetsFilter[0].data[elements[0].index].x;

				onUpdateState({date})
			}
		
		} catch(e) {
			console.log(e)
		}
	}

	const renderProductivityOrMzones = () => {
		if(props.data.length === 1) {
			return (
				<Stack alignItems="center" sx={{mt: '10px'}} direction="row" spacing={2}>
					<Stack>
						<Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
							<div style={{background: successColor, width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
							{lang.high_productivity}
						</Stack>
						<Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
							<div style={{background: '#ffffc0', width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
							{lang.normal_productivity}
						</Stack>
						<Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
							<div style={{background: danger, width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
							{lang.low_productivity}
						</Stack>
					</Stack>
					<div style={{position: 'relative', width: '130px'}}>
						<Stack spacing={1} alignItems={'center'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} justifyContent="center">
							<Stack direction="row" alignItems="center" style={{whiteSpace: 'nowrap'}}>Sentinel-2 <Room fontSize="small" color="error" /></Stack>						
							<strong>{props.data[0]['s2'][props.layer][0] && props.data[0]['s2'][props.layer][0] !== 0 && props.data[0]['s2'][props.layer][0] !== -9999 ? props.data[0]['s2'][props.layer][0] + ' %' : ' - '}</strong>
						</Stack>
						<CircularProgress style={{opacity: props.data[0]['s2'][props.layer][0] ? 1 : .3}} sx={{'& circle': {stroke: getStatusForProductivity(props.data[0]['s2'][props.layer][0])}}}  variant="determinate" size={130} thickness={4.7} value={100} />	
					</div>
	
					{user.planet && <div style={{position: 'relative', width: '130px'}}>
						<Stack spacing={1} alignItems={'center'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} justifyContent="center">
							<Stack direction="row" alignItems="center" style={{whiteSpace: 'nowrap'}}>PLANET <Room fontSize="small" color="error" /></Stack>						
							<strong>{props.data[0]['planet'][props.layer][0] && props.data[0]['planet'][props.layer][0] !== 0 && props.data[0]['planet'][props.layer][0] !== -9999 ? props.data[0]['planet'][props.layer][0] + ' %' : ' - '}</strong>
						</Stack>
						<CircularProgress sx={{'& circle': {stroke: getStatusForProductivity(props.data[0]['planet'][props.layer][0])}}}  variant="determinate" size={130} thickness={4.7} value={100} />	
					</div>}
				</Stack>
			)	
		}

		if(props.data.length === 2) {
			return (
				<Stack alignItems={'center'} sx={{mt: '10px'}} direction="row" spacing={2}>
					<Stack>
						<Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
							<div style={{background: successColor, width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
							{lang.high_productivity}
						</Stack>
						<Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
							<div style={{background: '#ffffc0', width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
							{lang.normal_productivity}
						</Stack>
						<Stack sx={{mb: '5px'}} direction="row" justifyContent={'flex-start'} alignItems="center">
							<div style={{background: danger, width: '30px', height: '30px', borderRadius: '100%', marginRight: '5px'}}></div>
							{lang.low_productivity}
						</Stack>
					</Stack>
					<div style={{position: 'relative', width: '130px'}}>
						<Stack spacing={1} alignItems={'center'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} justifyContent="center">
							<Stack direction="row" alignItems="center" style={{whiteSpace: 'nowrap'}}>Sentinel-2 <Room fontSize="small" color="error" /></Stack>						
							<strong>{props.data[0]['s2'][props.layer][0] && props.data[0]['s2'][props.layer][0] !== 0 && props.data[0]['s2'][props.layer][0] !== -9999 ? props.data[0]['s2'][props.layer][0] + ' %' : ' - '}</strong>
						</Stack>
						<CircularProgress sx={{'& circle': {stroke: getStatusForProductivity(props.data[0]['s2'][props.layer][0])}}} variant="determinate" size={130} thickness={4.7} value={100} />	
					</div>
	
					{user.planet && <div style={{position: 'relative', width: '130px'}}>
						<Stack spacing={1} alignItems={'center'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} justifyContent="center">
							<Stack direction="row" alignItems="center" style={{whiteSpace: 'nowrap'}}>PLANET <Room fontSize="small" color="error" /></Stack>						
							<strong>{props.data[0]['planet'][props.layer][0] && props.data[0]['planet'][props.layer][0] !== 0 && props.data[0]['planet'][props.layer][0] !== -9999 ? props.data[0]['planet'][props.layer][0] + ' %' : ' - '}</strong>
						</Stack>
						<CircularProgress sx={{'& circle': {stroke: getStatusForProductivity(props.data[0]['planet'][props.layer][0])}}} variant="determinate" size={130} thickness={4.7} value={100} />	
					</div>}

					<div style={{position: 'relative', width: '130px'}}>
						<Stack spacing={1} alignItems={'center'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} justifyContent="center">
							<Stack direction="row" alignItems="center" style={{whiteSpace: 'nowrap'}}>Sentinel-2 <Room fontSize="small" color="warning" /></Stack>						
							<strong>{props.data[1]['s2'][props.layer][0] && props.data[1]['s2'][props.layer][0] !== 0 && props.data[1]['s2'][props.layer][0] !== -9999 ? props.data[1]['s2'][props.layer][0] + ' %' : ' - '}</strong>
						</Stack>
						<CircularProgress sx={{'& circle': {stroke: getStatusForProductivity(props.data[1]['s2'][props.layer][0])}}} variant="determinate" size={130} thickness={4.7} value={100} />	
					</div>
	
					{user.planet && <div style={{position: 'relative', width: '130px'}}>
						<Stack spacing={1} alignItems={'center'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} justifyContent="center">
							<Stack direction="row" alignItems="center" style={{whiteSpace: 'nowrap'}}>PLANET <Room fontSize="small" color="warning" /></Stack>						
							<strong>{props.data[1]['planet'][props.layer][0] && props.data[1]['planet'][props.layer][0] !== 0 && props.data[1]['planet'][props.layer][0] !== -9999 ? props.data[1]['planet'][props.layer][0] + ' %' : ' - '}</strong>
						</Stack>
						<CircularProgress sx={{'& circle': {stroke: getStatusForProductivity(props.data[1]['planet'][props.layer][0])}}} variant="determinate" size={130} thickness={4.7} value={100} />	
					</div>}
				</Stack>
			)	
		}
	}


	let parcelObj = props.parcel ? $data.getParcelById(props.parcel) : null;
	let crops = [];

	if(parcelObj) {
		crops = parcelObj.properties.crop_history;
	}
	return useMemo(() => (
		<Stack sx={{height: '150px'}}>
			<FormControlLabel sx={{position: 'absolute', right: '50px', top: '55px'}} control={<Switch checked={Boolean(isMain)} onChange={(evt,checked) => {console.log(evt.target.checked); setIsMain(evt.target.checked)}} />} label={isMain ? 'Main crops' : 'Cover crops'}  />
		
			<Tooltip placement="left" title={lang.reset_chart_view}><Fab style={{position: 'absolute', top: '50px', right: '5px'}} size="small" onClick={() => {chartRef.current.resetZoom(); setTimeout(() => {setView(false)}, 500)}}><Refresh fontSize="small" /></Fab></Tooltip> 
			{(props.layer === 'ndvi' || props.layer === 'chl') && <Chart 
				ref={chartRef}
				type="line" 
				data={formatData(props.data, props.layer, lang[props.layer], [danger, dangerLight, warning, warningLight], props.dateFilter, user.planet)}
				options={formatOptions(onClick, lang, crops, () => {setView(true)}, isMain, props.layer === 'ndvi')}
			 />}
			
			{(props.layer === 'productivity' || props.layer === 'mzones') && renderProductivityOrMzones()} 
		</Stack>
	), [props.layer, isMain])
}

export default Query;