import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";
import { Calculate, Event } from "@mui/icons-material";
import $activities from "../../../services/$activities";
import Message from "../../ui/Message";
import InputPerPackage from "../helper/InputPerPackage";

function FertigationForm(props) {
	let { lang, locale, fertigationTypes, fertigationProducts, operators, machines, connectedMachines } = useContext(AppContext);
	let editModel = props.model ? Object.assign({}, props.model, { operators: props.model.operators.map(obj => obj.pk) }) : {}

	const [data, setData] = useMergeState({
		model: Object.assign({}, {
			date: moment().format('YYYY-MM-DD'),
			activity_type: 2,
			fertigation_type: fertigationTypes[0].id,
			fertigation_product: '',
			quantity_ha: 0,
			quantity_parcel: 0,
			quantity_unit: 1,
			product_unit_price: 0,
			product_price_ha: 0,
			product_price_parcel: 0,
			price_ha: 0,
			price_parcel: 0,
			fuel_consumption: 0,
			operators: [],
			machine: null,
			conn_machine: null,
			comment: '',
			parcel: props.parcel.pk,
			natural: 'undefined'


		}, { ...editModel }), error: {}, success: false, loading: false
	});

	const { model, error, success, loading } = data;

	const [packageModal, setPackageModal] = useState(false);

	useEffect(() => {
		if (props.isJob && !props.forArchive) {
			if (moment(model.date).diff(moment()) <= 0) {
				setData({ model: { ...model, date: moment().add(1, 'days').format('YYYY-MM-DD') } })
			}
		} else {
			setData({ model: { ...model, date: editModel.date || model.date } })
		}
	}, [props.isJob])

	const onChange = (name, val) => {
		let { value, additional } = $activities.getFertigationPrices(name, val, model, props.parcel.area);
		setData({ model: { ...model, [name]: value, ...additional } });
	}

	const updateFromPackages = (fieldCost, quantityParcel, unit, unitPrice) => {
		setData({
			model: {
				...model,
				quantity_parcel: quantityParcel,
				quantity_ha: (quantityParcel / props.parcel.area).toFixed(2),
				quantity_unit: unit,
				product_price_parcel: fieldCost,
				product_price_ha: (fieldCost / props.parcel.area).toFixed(2),
				product_unit_price: unitPrice
			}
		})
	}

	const getTotalPrice = () => {
		return {
			ha: (parseFloat(model.product_price_ha) + parseFloat(model.price_ha)).toFixed(2),
			parcel: (parseFloat(model.product_price_parcel) + parseFloat(model.price_parcel)).toFixed(2),
		}
	}

	return (
		<Grid className={props.className || ''} component="form" onSubmit={(evt) => { evt.preventDefault(); props.onSubmit(model, true) }} container spacing={2}>
			{(!props.isJob || props.forArchive) && <Grid item xs={12} sm={4}>
				<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
					<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={props.isJob && !props.forArchive ? moment().add(1, 'days') : undefined} onClose={() => { onChange('date', model.date) }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.date} value={moment(model.date)} onChange={(newValue) => onChange('date', newValue.format('YYYY-MM-DD'))}
						renderInput={(params) => (
							<TextField fullWidth error={error.date} helperText={error.date || ''} {...params} size="small"
								InputProps={{ endAdornment: (<InputAdornment position="end"> <Event /> </InputAdornment>) }} />
						)}
					/>
				</LocalizationProvider>
			</Grid>}
			{(!props.isJob || props.forArchive) && <Grid style={{ padding: 0 }} item xs={12} sm={4}></Grid>}
			{(!props.isJob || props.forArchive) && <Grid style={{ padding: 0 }} item xs={12} sm={4}></Grid>}

			<Grid item xs={12} sm={6}>
				<FormControl size="small" error={props.error.fertigation_type !== undefined} fullWidth>
					<InputLabel id="fertigation_type">{lang.fertigation_type}</InputLabel>
					<Select size="small" labelId="fertigation_type" label={lang.fertigation_type} value={model.fertigation_type} onChange={(evt) => onChange('fertigation_type', evt.target.value)} fullWidth>
						{fertigationTypes.map((obj, key) => <MenuItem value={obj.id} key={key}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.fertigation_type && <FormHelperText>{props.error.fertigation_type}</FormHelperText>}
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Autocomplete size="small" value={model.fertigation_product}
					getOptionLabel={(option) => { return option || '' }}
					onChange={(evt, value) => { onChange('fertigation_product', value) }}
					onInputChange={(evt, value) => { onChange('fertigation_product', value) }}
					freeSolo id="fertigation_product"
					disableClearable
					ListboxProps={{ id: 'fertigation-product-suggestions' }}
					options={fertigationProducts.map((opt, key) => opt.name)}
					renderInput={(params) => (<TextField {...params} label={lang.fertigation_product} placeholder={lang.fertigation_product_placeholder} InputProps={{ ...params.InputProps, type: 'search', }} />)} />
				{/* <TextField label={lang.depth} type="number" onChange={(evt) => onChange('depth', evt.target.value)} fullWidth size="small" value={model.depth} /> */}
			</Grid>
			<Grid item xs={12} sm={4}>
				<Button onClick={() => setPackageModal(true)} size="small" variant="outlined" startIcon={<Calculate />}>{lang.input_per_package}</Button>
			</Grid>

			<Grid item xs={12} sm={4}>
				<FormControl size="small" fullWidth>
					<InputLabel id="natural">{lang.natural_type}</InputLabel>
					<Select size="small" labelId="unit_type" label={lang.natural_type} value={model.natural} onChange={(evt) => onChange('natural', evt.target.value)} fullWidth>
						<MenuItem value={'undefined'}>{lang.unknown}</MenuItem>
						<MenuItem value={true}>{lang.natural}</MenuItem>
						<MenuItem value={false}>{lang.syntethic}</MenuItem>
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={5} >
				<TextField error={props.error.quantity_ha ? true : false} helperText={props.error.quantity_ha || ''} label={lang.quantity_ha} type="number" fullWidth onChange={(evt) => onChange('quantity_ha', evt.target.value)} size="small" value={model.quantity_ha} />
			</Grid>
			<Grid item xs={12} sm={5} >
				<TextField error={props.error.quantity_parcel ? true : false} helperText={props.error.quantity_parcel || ''} label={lang.quantity_parcel} type="number" fullWidth onChange={(evt) => onChange('quantity_parcel', evt.target.value)} size="small" value={model.quantity_parcel} />
			</Grid>

			<Grid item xs={12} sm={2} >
				<FormControl size="small" error={props.error.quantity_unit !== undefined} fullWidth>
					<InputLabel id="unit_type">{lang.unit_type}</InputLabel>
					<Select size="small" labelId="unit_type" label={lang.unit_type} value={model.quantity_unit} onChange={(evt) => onChange('quantity_unit', evt.target.value)} fullWidth>
						<MenuItem value={1}>{'kg'}</MenuItem>
						<MenuItem value={2}>{'l'}</MenuItem>
					</Select>
					{props.error.quantity_unit && <FormHelperText>{props.error.quantity_unit}</FormHelperText>}
				</FormControl>
			</Grid>

			<Grid item xs={12} sm={4} >
				<TextField error={props.error.product_unit_price ? true : false} helperText={props.error.product_unit_price || ''} label={lang.product_unit_price} type="number" fullWidth onChange={(evt) => onChange('product_unit_price', evt.target.value)} size="small" value={model.product_unit_price} />
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField error={props.error.product_price_ha ? true : false} helperText={props.error.product_price_ha || ''} focused={false} sx={{ '& input': { background: 'rgba(0,0,0, 0.04)' }, '& input, & textarea': { cursor: 'not-allowed' } }} inputProps={{ readOnly: true }} label={lang.product_price_ha} type="number" fullWidth onChange={(evt) => onChange('product_price_ha', evt.target.value)} size="small" value={model.product_price_ha} />
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField error={props.error.product_price_parcel ? true : false} helperText={props.error.product_price_parcel || ''} focused={false} sx={{ '& input': { background: 'rgba(0,0,0, 0.04)' }, '& input, & textarea': { cursor: 'not-allowed' } }} inputProps={{ readOnly: true }} label={lang.product_price_parcel} type="number" fullWidth onChange={(evt) => onChange('product_price_parcel', evt.target.value)} size="small" value={model.product_price_parcel} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField error={props.error?.common_activity?.price_ha ? true : false} helperText={props.error?.common_activity?.price_ha || ''} label={lang.price_ha} type="number" fullWidth onChange={(evt) => onChange('price_ha', evt.target.value)} size="small" value={model.price_ha} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField error={props.error?.common_activity?.price_parcel ? true : false} helperText={props.error?.common_activity?.price_parcel || ''} label={lang.price_parcel} type="number" fullWidth onChange={(evt) => onChange('price_parcel', evt.target.value)} size="small" value={model.price_parcel} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{ background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root': { fontWeight: 'bold' }, '& input, & textarea': { cursor: 'not-allowed', fontWeight: 'bold' } }} focused label={lang.total_price_ha} fullWidth size="small" inputProps={{ readOnly: true }} value={(getTotalPrice().ha) || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{ background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root': { fontWeight: 'bold' }, '& input, & textarea': { cursor: 'not-allowed', fontWeight: 'bold' } }} focused label={lang.total_price_parcel} fullWidth size="small" inputProps={{ readOnly: true }} value={(getTotalPrice().parcel) || ' - '} />
			</Grid>

			<Grid item xs={12} >
				<FormControl size="small" fullWidth>
					<InputLabel id="operators">{lang.operators}</InputLabel>
					<Select
						multiple size="small" labelId="operators"
						label={lang.operators} value={model.operators}
						onChange={(evt) => onChange('operators', evt.target.value)}
						renderValue={(selected) => selected.map(id => $data.getOperatorById(operators, id)).join(', ')} fullWidth>
						{operators.map((obj, key) => {

							return (
								<MenuItem key={key} value={obj.pk}>
									<Checkbox checked={model.operators.indexOf(obj.pk) > -1} />
									<ListItemText primary={`${obj.first_name} ${obj.last_name}`} />
								</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.fuel_consumption ? true : false} helperText={props.error?.common_activity?.fuel_consumption || ''} label={lang.fuel_consumption} type="number" fullWidth onChange={(evt) => onChange('fuel_consumption', evt.target.value)} size="small" value={model.fuel_consumption} />
			</Grid>

			<Grid item xs={12} sm={6} >
				<FormControl size="small" error={error.machine !== undefined || error.non_field_errors !== undefined} fullWidth>
					<InputLabel id="machine">{lang.fld_machine}</InputLabel>
					<Select size="small" labelId="machine" label={lang.fld_machine} value={model.machine || ''} onChange={(evt) => onChange('machine', evt.target.value)} fullWidth>
						{machines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
					</Select>
					{error.machine && <FormHelperText>{error.machine}</FormHelperText>}
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={6} >
				<FormControl size="small" error={error.conn_machine !== undefined || error.non_field_errors !== undefined} fullWidth>
					<InputLabel id="con_machine">{lang.fld_con_machine}</InputLabel>
					<Select size="small" labelId="con_machine" label={lang.fld_con_machine} value={model.conn_machine || ''} onChange={(evt) => onChange('conn_machine', evt.target.value)} fullWidth>
						{connectedMachines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
					</Select>
					{error.conn_machine && <FormHelperText>{error.conn_machine}</FormHelperText>}
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.comment ? true : false} helperText={props.error?.common_activity?.comment || ''} multiline label={lang.comment} fullWidth size="small" onChange={(evt) => onChange('comment', evt.target.value)} value={model.comment} />
			</Grid>

			<Grid sx={{ '& button': { ml: '5px' } }} style={{ textAlign: 'right' }} item xs={12}>
				{!props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.confirm}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" color="warning" variant="contained" type="button" onClick={(() => props.onSubmit(model, false))} loading={props.loading}>{lang.act_job_update}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.archive_job}</LoadingButton>}

				{!props.edit && <LoadingButton size="small" onClick={props.onClose} variant="contained" color="error" loading={props.loading} type="button">{lang.close}</LoadingButton>}
			</Grid>

			{packageModal && <InputPerPackage onUpdateModel={updateFromPackages} area={props.parcel.area} open={packageModal} onClose={() => setPackageModal(false)} />}
			<Message type="error" open={props.error.non_field_errors || props.error.file ? true : false} message={props.error.non_field_errors || props.error.file} onClose={props.closeErrorMessage} />
		</Grid>
	)
}

export default FertigationForm;
