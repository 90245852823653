import axios from 'axios';
import _, { result } from 'lodash';
import moment from 'moment';
import URL from '../config/urls';
import parser from 'xml2json-light';
import translation, { getTranslation } from '../config/locale/translation';
import { fromLonLat } from 'ol/proj';
import $map from './$map';
import { detectAndMap } from '../config/practices';

let $http = axios.create();
let quantilesSource = axios.CancelToken.source();

class $data {
  constructor() {
    this.parcels = { type: 'FeatureCollection', features: [] };
    this.operators = [];
  }

  getIrrigation(point) {
    return axios.post(URL.IRRIGATION, { point })
      .then(result => {
        let resp = result.data[0]

        try {
          let formated = {
            info: {
              sensor: resp.sensor_info.name,
              distance: resp.sensor_info.distance.toFixed(0) + 'm'
            },
            data: {}
          }

          if (Object.keys(resp.data).length > 0) {
            let delta = '';
            let irrigation = '';

            Object.keys(resp.data.client).map(name => {
              if (name.indexOf('Delta') > -1) {
                delta = name;
              }

              if (name.indexOf('IrThreshold') > -1) {
                irrigation = name;
              }
            })

            if (parseFloat(resp.data.client[delta]) <= 0) {
              resp.data.client[delta] = null
            }

            formated.data = {
              delta: resp.data.client[delta] ? parseFloat(resp.data.client[delta]).toFixed(4) + ' m3' + ` / ${(parseFloat(resp.data.client[delta]) * 1000).toFixed(1)} l` : null,
              treshold: resp.data.client[irrigation] ? resp.data.client[irrigation] : null,
              moisture: resp.data.client.moisture ? resp.data.client.moisture : null
            }
          }

          return formated
        } catch (e) {
          console.log(e)
          return null
        }

      })
      .catch(err => { throw err.message });
  }

  getSamples() {
    return axios.get(URL.SAMPLES)
      // .then((result) => this.formatSamples(result.data))
      .then((result) => result.data)
      .catch(err => { throw err.message })
  }

  postSamples(model) {
    return axios.post(URL.SAMPLES, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteSamples(ids) {
    return axios.delete(URL.SAMPLES + `bulk_delete/?pk_ids=${ids.join(',')}`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  formatSamples(data) {
    return data.map(obj => {
      let newObj = { ...obj };
      delete newObj.dataset;
      let dataset = JSON.parse(obj.dataset);

      return { ...newObj, ...dataset }
    })
  }

  patchCropSeason(model) {
    return axios.patch(URL.CROP_HISTORY + `${model.id}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteCropSeason(id) {
    return axios.delete(URL.CROP_HISTORY + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  extractColumns(samples) {
    let columns = [];

    samples.map(obj => {
      columns = _.concat(columns, Object.keys(obj).filter(name => name !== 'id' && name !== 'created_at'))
    });


    return [...new Set(columns)]
  }

  getSensors() {
    return axios.get(URL.SENSORS_CROPLAB)
      .then((result) => {
        return result.data
      })
      .catch(err => { throw err.message })
  }

  patchSensor(id, model) {
    return axios.patch(URL.SENSORS_CROPLAB + `${id}/`, { description: model.description === "" ? " " : model.description })
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getMeasurements(filter = '', lastMeasurement) {
    return axios.get(URL.SENSOR_MEASUREMENTS + filter)
      .then(result => {
        if (lastMeasurement) {
          return result.data[result.data.length - 1];
        }

        return result.data
      })
      .catch(err => { throw err.message })
  }

  extractLastPlot(sensorID, plots, locations) {
    try {
      let filtered = plots.filter(p => p.sensor === sensorID);
      if (filtered.length === 0) return null;
      if (filtered.length === 1) return locations.filter(l => l.id === filtered[0].field_plot)[0];
      return locations.filter(l => l.id === filtered[filtered.length - 1].field_plot)[0];
    } catch (e) {
      return null
    }


  }


  getLegend(layer) {
    if (layer === 'productivity') layer = 'mz';
    if (layer === 'mzones') layer = "mz_3";

    return axios.get(URL.GEOSERVER + `${layer}.sld`, {
      transformRequest: [(data, headers) => {
        delete headers.common.Authorization;
        return JSON.stringify(data)
      }]
    })
      .then(result => {
        try {
          let formated = result.data.replace(/sld:/g, '').replace(/ type="intervals"/g, '').split('<Rule>')[1].split('</Rule>')[0];
          let split = formated.slice(formated.indexOf('<ColorMap>'), formated.indexOf('</RasterSymbolizer>'));
          let parsed = parser.xml2json(split);

          return parsed.ColorMap.ColorMapEntry.reverse().map(obj => {
            let { color, label } = obj;
            return {
              color, label: label.replace('&lt;=', '<=').replace('&gt;', '>')
            }
          })
        } catch (e) {
          return []
        }

      })
      .catch(err => { throw err.message })
  }

  postParcel(model) {
    return axios.post(URL.PARCEL, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  putParcel(model) {
    return axios.patch(`${URL.PARCEL}${model.pk}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteParcel(pk) {
    return axios.delete(URL.PARCEL + pk + '/')
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  uploadParcels(files) {
    return axios.post(URL.UPLOAD_PARCELS, files,)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getParcelById(id) {
    try {
      return this.parcels.features.filter(obj => obj.properties.pk.toString() === id.toString())[0]
    } catch (e) {
      return null
    }
  }

  getParcelIdByName(name) {
    try {
      return this.parcels.features.filter(obj => obj.properties.name === name)[0]['properties']['pk']
    } catch (e) {
      return null
    }
  }

  getParcelCentroid(id) {
    let parcel = this.getParcelById(id);

    if (!parcel) return null;

    let feature = $map.geojsonToFeature(parcel);
    return feature.getGeometry().getInteriorPoint()
  }


  getActiveSeasonSowingDate(parcel) {
    let p = this.parcels.features.filter(obj => obj.properties.pk.toString() === parcel.toString())[0];
    if (p) { return p.properties.season_crop.sowing }

    return null
  }

  getMachines = () => {
    return axios.get(URL.ACTIVITY_MACHINES)
      .then(result => result.data)
      .catch(err => { throw err });
  }

  postMachine(model) {
    return axios.post(URL.ACTIVITY_MACHINES, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  putMachine(model) {
    return axios.put(URL.ACTIVITY_MACHINES + `${model.pk}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }


  deleteMachine(model) {
    return axios.delete(URL.ACTIVITY_MACHINES + `${model.pk}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }


  getConnectedMachines = () => {
    return axios.get(URL.ACTIVITY_CONN_MACHINES)
      .then(result => result.data)
      .catch(err => { throw err });
  }


  postConnectedMachine(model) {
    return axios.post(URL.ACTIVITY_CONN_MACHINES, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  putConnectedMachine(model) {
    return axios.put(URL.ACTIVITY_CONN_MACHINES + `${model.pk}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }


  deleteConnecedMachine(model) {
    return axios.delete(URL.ACTIVITY_CONN_MACHINES + `${model.pk}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getOperators() {
    return axios.get(URL.ACTIVITY_OPERATORS)
      .then(result => {
        this.operators = result.data;
        return this.operators;
      })
      .catch(err => { throw err.message });
  }

  getEmployees(page) {
    let filter = page ? `?page=${page + 1}` : ``;

    return axios.get(URL.ROLES + filter)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  postEmployee(model) {
    return axios.post(URL.ROLES, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  putEmployee(model) {
    return axios.put(URL.ROLES + `${model.pk}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  deleteEmployee(id) {
    return axios.delete(URL.ROLES + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getNotifications() {
    return axios.get(URL.NOTIFICATION)
      .then(result => result.data.sort((a, b) => moment(b.date).diff(moment(a.date))))
      .catch(err => { throw err.message })
  }

  putNotification(model, pk) {
    return axios.patch(URL.NOTIFICATION + `${pk}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getNotificationSettings() {
    return axios.get(URL.NOTIFICATION_OPTIONS)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  putNotificationSettings(model) {
    return axios.put(URL.NOTIFICATION_OPTIONS, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getBillingParcels() {
    return axios.get(URL.PARCEL)
      .then(result => result.data.features)
      .catch(err => { throw err.message });
  }

  getCodeLists() {
    let promises = [
      axios.get(URL.CROP_TYPE),
      axios.get(URL.CROP_GROUP),
      axios.get(URL.ACTIVITY_TYPES),
      axios.get(URL.ACTIVITY_TILLAGE_TYPES),
      axios.get(URL.ACTIVITY_FERTIGATION_TYPES),
      axios.get(URL.ACTIVITY_FERTIGATION_PRODUCTS),
      axios.get(URL.ACTIVITY_SEED_TYPES),
      axios.get(URL.ACTIVITY_PLANT_PROTECTION_TYPES),
      axios.get(URL.ACTIVITY_PLANT_PROTECTION_PRODUCTS),
      axios.get(URL.ACTIVITY_PLANT_PROTECTION_SUBSTANCES),
      axios.get(URL.ACTIVITY_AGROTYPES)
    ];

    return axios.all(promises)
      .then(axios.spread((cropTypes, cropGroups, activityTypes, tillageTypes, fertigationTypes,
        fertigationProducts, plantingSeeds, plantProtectionTypes,
        plantProtectionProducts, plantProtectionSubstances, plantProtectionAgroTypes) => {
        return {
          cropTypes: cropTypes.data, cropGroups: cropGroups.data, activityTypes: activityTypes.data, tillageTypes: tillageTypes.data, fertigationTypes: fertigationTypes.data,
          fertigationProducts: fertigationProducts.data, plantingSeeds: plantingSeeds.data, plantProtectionTypes: plantProtectionTypes.data,
          plantProtectionProducts: plantProtectionProducts.data, plantProtectionSubstances: plantProtectionSubstances.data, plantProtectionAgroTypes: plantProtectionAgroTypes.data
        }
      }))
      .catch(err => { throw err.message })
  }

  getDefaultSeason() {
    let today = moment();
    let higher = moment(today.format('YYYY') + '-07-31');

    if (higher.diff(today) >= 0) return parseInt(today.format('YYYY')) - 1;

    return parseInt(today.format('YYYY'))
  }

  getSeasons() {
    return axios.get(URL.PARCEL + 'seasons/')
      .then(result => result.data.length > 0 ? result.data.sort((a, b) => a - b) : [this.getDefaultSeason()])
      .catch(err => { throw err.message });
  }

  getParcels(locale) {
    // let filter = season ? `?season=${season}` : `?season=${moment().format('YYYY')}`;
    return axios.get(URL.PARCEL)
      .then(result => {
        result.data.features = result.data.features.map(f => {

          f.properties.crop_history = f.properties.crop_history.map(ch => ({ ...ch })).reverse();

          f.properties.activeCrop = {};

          if (f.properties.crop_history.length === 0) {
            f.properties.activeCrop = { sowing: moment().format('YYYY-MM-DD'), harvest: null, crop: 999, crop_name: getTranslation(locale).not_defined };
          } else {
            f.properties.activeCrop = this.cropCheck(null, f.properties.crop_history.map(c => ({ ...c })).reverse(), locale)
          }

          return Object.assign({}, { ...f })
        });



        this.parcels = result.data;
        return this.parcels;
      })
      .catch(err => { throw err.message });
  }

  getFilteredParcels(filter) {
    return axios.get(URL.PARCEL + filter)
      .then(result => result.data.features.map(obj => obj.properties.pk))
      .catch(err => { throw err })
  }

  filterParcelsBySeason(season, parcels) {
    let min = `${season}-08-01`;
    let max = `${season + 1}-07-31`;

    return {
      type: 'FeatureCollcetion',
      features: parcels.features.filter((obj) => {
        let { sowing } = obj.properties.season_crop;
        return moment(sowing).diff(min) >= 0 && moment(sowing).diff(max) <= 0
      })
    }
  }

  getDatesS2(id) {
    return axios.get(URL.DATES_S2.replace('__ID__', id))
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getDatesPlanet(id) {
    return axios.get(URL.DATES_PLANET.replace('__ID__', id))
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getCropById(list, id) {
    let cropObj = list.filter(obj => id.toString() === obj.pk.toString())[0];
    return cropObj ? cropObj.name : null
  }

  getCropGroupByID(id, list) {
    let groupObj = list.filter(obj => id.toString() === obj.id.toString())[0];
    return groupObj ? groupObj.name : null
  }

  getCropGroupIDByCrop(crop, crops) {
    let cropObj = crops.filter(obj => crop === obj.pk)[0];
    return cropObj ? cropObj.crop_group : 5
  }

  getOperatorById(list, id) {

    let operator = list.filter(obj => id.toString() === obj.pk.toString())[0];
    return operator ? `${operator.first_name} ${operator.last_name}` : ' - ';
  }

  getActivityNameById(list, id) {

    let activity = list.filter(obj => id.toString() === obj.id.toString())[0];
    return activity ? activity.name : ' - ';
  }

  getProtectionTypeById(list, id) {
    let protection = list.filter(obj => obj.id.toString() === id.toString())[0];
    return protection ? protection.name : ' - ';
  }

  getQuantiles(parcels, index, date, isPlanet) {
    let queryString = `?parcels=${parcels.join(',')}&date=${date}&index=${index}${isPlanet ? '&planet=True' : ''}`;
    quantilesSource.cancel('cancel');
    quantilesSource = axios.CancelToken.source();

    return axios.get(URL.PARCEL + 'quantiles' + queryString, { cancelToken: quantilesSource.token })
      .then(result => {
        if (queryString.indexOf('mz') > -1) {
          let formated = [];
          result.data.map(obj => {
            formated.push(obj.from)
          });
          formated.push(result.data[2].to);
          return _.concat(formated, result.data);
        }

        return result.data


      })
      .catch(err => { throw err.message })
  }

  cancelQuantiles() {
    quantilesSource.cancel('cancel');
  }

  exportVRT(parcels, date, ranges, amounts, index, format) {
    let queryString = `?parcels=${parcels.join(',')}&date=${date}&ranges=${ranges.join(',')}&amount=${amounts.join(',')}&index=${index}&frmt=${format}`

    return axios.get(URL.PARCEL + 'fertilization' + queryString, { responseType: 'blob' })
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getDocuments(filter) {
    return axios.get(URL.DOCUMENTS + (filter ? filter : ''))
      .then(result => result.data.map(obj => ({ ...obj, tags: !obj.tags ? '' : obj.tags })))
      .catch(err => { throw err.message })
  }

  patchDocument(model, id) {
    delete model.file;
    delete model.filename;
    delete model.common_activity;
    delete model.pk;
    let tags = model.tags.filter(t => t !== '');
    let formatedTags = tags.join('; ');
    if (tags.length === 1) {
      formatedTags = tags[0];
    }

    if (tags.length > 1) {
      formatedTags = tags.join('; ');
    }

    let formated = Object.assign({}, { ...model, tags: formatedTags })
    let fd = new FormData();
    Object.keys(formated).map(name => {
      fd.append(name, formated[name])
    })
    return axios.patch(URL.DOCUMENTS + `${id}/`, fd)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  downloadDocument(id) {
    return axios.get(URL.DOCUMENTS_DOWNLOAD + `${id}/`, { responseType: 'blob' })
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteDocument(id) {
    return axios.delete(URL.DOCUMENTS + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getActivities(filterObj = {}) {
    let filter = '';
    if (Object.keys(filterObj).length > 0) {

      let params = [];

      Object.keys(filterObj).map(name => {
        params.push(`${name}=${filterObj[name]}`)
      })

      filter = '?' + params.join('&');
    }

    return axios.get(URL.ACTIVITIES + filter)
      .then(result => this.splitActivities(result.data.reverse().map(obj => {
        if (obj.common_activity) {

          obj.common_activity.operators = obj.common_activity.operators.map(pk => {
            let operator = this.operators.filter(obj => pk === obj.pk)[0];
            if (operator) {
              return (({ first_name, last_name, pk }) => ({ first_name, last_name, pk }))(operator)
            }
            return { first_name: '', last_name: '- ', pk: -1 }
          })
        }

        return obj

      })))
      .catch(err => { throw err })
  }

  exportFieldBook(filter, isExcel, callback) {

    return axios.get((isExcel ? URL.ACTIVITIES : URL.ACTIVITIES_PDF) + filter, { responseType: 'blob', onDownloadProgress: callback })
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getActivitiesSummaryForParcel(parcel) {
    return axios.get(URL.ACTIVITIES + `?parcels=${parcel}&types=&operators=&export_format=summary`)
      .then(result => result.data)
      .catch(err => { throw err })
  }

  splitActivities(data) {
    try {
      let result = {
        jobs: [],
        activities: []
      }

      let protectionGroups = {}

      data.map(obj => {
        let formatedModel = this.formatModel(obj);

        if (!formatedModel.common_activity) { result.activities.push(formatedModel); return; }

        if (formatedModel.activity_type === 4) {
          if (protectionGroups.hasOwnProperty(formatedModel.common_activity.id)) {
            protectionGroups[formatedModel.common_activity.id].push(formatedModel);
          } else {
            protectionGroups[formatedModel.common_activity.id] = [formatedModel];
          }
          return;
        }

        if (formatedModel.common_activity.job_order) { result.jobs.push(Object.assign({}, formatedModel, formatedModel.common_activity)); return; }

        result.activities.push(Object.assign({}, formatedModel, formatedModel.common_activity))
      });

      console.log(JSON.parse(JSON.stringify(result.activities)))



      let protection_jobs = [];
      let protection_activities = [];

      Object.keys(protectionGroups).map((id, key) => {
        let formated = {
          ...protectionGroups[id][0].common_activity,
          activity_type: protectionGroups[id][0].activity_type,
          activity_type_name: protectionGroups[id][0].activity_type_name,
          operations: protectionGroups[id]
        }

        if (formated.job_order) {
          protection_jobs.push(formated);
        } else {
          protection_activities.push(formated);
        }


      })

      result.jobs = _.concat(result.jobs, protection_jobs);
      result.activities = _.concat(result.activities, protection_activities);



      return {
        jobs: result.jobs.sort((a, b) => moment(b.date).diff(moment(a.date))),
        activities: result.activities.sort((a, b) => moment(b.date).diff(moment(a.date)))
      }
    } catch (e) {
      console.log(e);

      return {
        jobs: [],
        activities: []
      };
    }
  }

  createAgrotechnical(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.post(URL.ACTIVITY_AGROTECH, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updateAgrotechnical(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.put(URL.ACTIVITY_AGROTECH + `${model.pk}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteAgrotechnical(id) {
    return axios.delete(URL.ACTIVITY_AGROTECH + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createGrazing(model) {
    console.log(this.mergeIntoCommonActivity({ ...model, animals: JSON.stringify(model.animals) }))
    let payload = this.formatModel(this.mergeIntoCommonActivity({ ...model, animals: JSON.stringify(model.animals) }), true);

    return axios.post(URL.ACTIVITY_GRAZING, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updateGrazing(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity({ ...model, animals: JSON.stringify(model.animals) }), true);

    return axios.put(URL.ACTIVITY_GRAZING + `${model.pk}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteGrazing(id) {
    return axios.delete(URL.ACTIVITY_GRAZING + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createTillage(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.post(URL.ACTIVITY_TILLAGE, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updateTillage(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.put(URL.ACTIVITY_TILLAGE + `${model.pk}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteTillage(id) {
    return axios.delete(URL.ACTIVITY_TILLAGE + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }


  createFertigation(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity({ ...model, natural: model.natural === 'undefined' ? null : model.natural }), true);


    // let payload = this.mergeIntoCommonActivity(model);
    return axios.post(URL.ACTIVITY_FERTIGATION, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updateFertigation(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity({ ...model, natural: model.natural === 'undefined' ? null : model.natural }), true);

    return axios.put(URL.ACTIVITY_FERTIGATION + `${model.pk}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteFertigation(id) {
    return axios.delete(URL.ACTIVITY_FERTIGATION + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createPlanting(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);
    // let payload = this.mergeIntoCommonActivity(model);

    return axios.post(URL.ACTIVITY_PLANTING, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updatePlanting(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.put(URL.ACTIVITY_PLANTING + `${model.pk}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deletePlanting(id) {
    return axios.delete(URL.ACTIVITY_PLANTING + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createPlantProtection(model) {
    let payload = this.formatPlantProtectionModel(this.mergeIntoCommonActivity(model), true);

    return axios.post(URL.ACTIVITY_PLANT_PROTECTION, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updatePlantProtection(model) {
    let payload = this.formatPlantProtectionModel(this.mergeIntoCommonActivity(model), true);

    return axios.put(URL.ACTIVITY_PLANT_PROTECTION + `${model.id}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deletePlantProtection(id) {
    return axios.delete(URL.ACTIVITY_PLANT_PROTECTION + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createHarvesting(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.post(URL.ACTIVITY_HARVESTING, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }


  updateHarvesting(model) {
    let payload = this.formatModel(this.mergeIntoCommonActivity(model), true);

    return axios.put(URL.ACTIVITY_HARVESTING + `${model.pk}/`, payload)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteHarvesting(id) {
    return axios.delete(URL.ACTIVITY_HARVESTING + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createNote(model) {
    return axios.post(URL.ACTIVITY_NOTE, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updateNote(model) {
    return axios.put(URL.ACTIVITY_NOTE + `${model.pk}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deleteNote(id) {
    return axios.delete(URL.ACTIVITY_NOTE + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createActivity(model) {

    switch (model.activity_type) {
      case 1: return this.createTillage(model);
      case 2: return this.createFertigation(model);
      case 3: return this.createPlanting(model);
      case 4: return this.createPlantProtection(model);
      case 5: return this.createHarvesting(model);
      case 6: return this.createNote(model);
      case 7: return this.createAgrotechnical(model);
      case 8: return this.createGrazing(model);
      default: return;
    }
  }

  updateActivity(model) {
    switch (model.activity_type) {
      case 1: return this.updateTillage(model);
      case 2: return this.updateFertigation(model);
      case 3: return this.updatePlanting(model);
      case 4: return this.updatePlantProtection(model);
      case 5: return this.updateHarvesting(model);
      case 6: return this.updateNote(model);
      case 7: return this.updateAgrotechnical(model);
      case 8: return this.updateGrazing(model);
      default: return;
    }
  }

  deleteActivity(model) {
    switch (model.activity_type) {
      case 1: return this.deleteTillage(model.pk);
      case 2: return this.deleteFertigation(model.pk);
      case 3: return this.deletePlanting(model.pk);
      case 4: return this.deletePlantProtection(model.id);
      case 5: return this.deleteHarvesting(model.pk);
      case 6: return this.deleteNote(model.pk);
      case 7: return this.deleteAgrotechnical(model.pk);
      case 8: return this.deleteGrazing(model.pk);

      default: return;
    }
  }

  updateToJobOrder(id, model) {
    return axios.put(URL.JOB_ORDER.replace('__ID__', id), model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  mergeIntoCommonActivity(model) {
    let newCommonActivity = (({
      comment, conn_machine, conn_machine_name, price_ha, price_parcel, operators,
      currency, currency_name, date,
      id, jo_comment, jo_from, jo_to,
      activity_status,
      job_order, machine,
      machine_name, parcel, fuel_consumption
    }) => ({
      comment, conn_machine, conn_machine_name,
      currency, currency_name, date,
      id, jo_comment, jo_from, jo_to,
      activity_status,
      job_order, machine,
      machine_name, price_ha, price_parcel, operators, parcel, fuel_consumption
    }))(model);

    return Object.assign({}, model, { common_activity: { ...model.common_activity, ...newCommonActivity } })
  }

  formatModel(model, isReverse, isPlantProtection) {
    let formated = { ...model };

    Object.keys(model).map((name) => {
      if (['yield_ha', 'yield_parcel', 'digestion', 'hectolitre_mass', 'impurities', 'moisture', 'oil_content', 'protein_content', 'plants_ha', 'plants_parcel', 'tgw', 'row_spacing', 'depth', 'plants_ha, plants_parcel', 'quantity_ha', 'quantity_parcel', 'product_unit_price', 'product_price_ha', 'product_price_parcel'].indexOf(name) > -1) {
        if (!isReverse) formated[name] = model[name] || 0;
        else formated[name] = parseFloat(model[name]) === 0 ? null : model[name];
      }

      if (name === 'irrigation_norm') {
        if (!isReverse) formated[name] = model[name] || 0;
        else formated[name] = model[name] === 0 || model[name] === '' ? null : model[name];
      }

      if (name === 'common_activity') {
        if (!isReverse) {
          formated[name] = {
            ...model[name],
            comment: model[name]['comment'] ? model[name]['comment'] : '',
            price_ha: model[name]['price_ha'] ? model[name]['price_ha'] : 0,
            price_parcel: model[name]['price_parcel'] ? model[name]['price_parcel'] : 0,
            fuel_consumption: model[name]['fuel_consumption'] ? model[name]['fuel_consumption'] : 0,
          }
        } else {

          if (!isPlantProtection) {
            formated.upload_document = model.upload_document.map(obj => ({ name: obj.name_field, tags: obj.tags, description: obj.description }));
          }
          formated[name] = {
            ...model[name],
            // upload_document: model.upload_document.map(obj => ({name: obj.name, tags: `tag${moment().format('DDMMYYYYHHmm')}` || null, description: 'Description' || ''})),
            activity_type: model.activity_type,
            comment: model[name]['comment'] === '' ? null : model[name]['comment'],
            price_ha: model[name]['price_ha'] === 0 ? null : parseFloat(model[name]['price_ha']),
            price_parcel: model[name]['price_parcel'] === 0 ? null : parseFloat(model[name]['price_parcel']),
            fuel_consumption: model[name]['fuel_consumption'] === 0 ? null : parseFloat(model[name]['fuel_consumption']),
          }

        }

      }
    });

    if (isPlantProtection) {
      return formated
    }

    if (isReverse) {
      let fd = new FormData();

      fd.append('data', JSON.stringify(formated));
      if (!model.upload_document) {
        model.upload_document = []
      }
      for (let i = 0; i < model.upload_document.length; i++) {
        fd.append('upload_document', model.upload_document[i]);
      }
      return fd
    }

    return formated;
  }

  formatPlantProtectionModel(model, isReverse) {
    let formated = { ...model, plant_protection: model.protections.map(obj => this.formatModel(obj, true, true)), /*agrotechnical_operation: model.operations.map(obj => this.formatModel(obj, true, true))*/ };
    delete formated.operations;
    delete formated.protections;

    Object.keys(model).map((name) => {
      if (['digestion', 'hectolitre_mass', 'impurities', 'moisture', 'oil_content', 'protein_content', 'plants_ha', 'plants_parcel', 'row_spacing', 'depth', 'plants_ha, plants_parcel', 'quantity_ha', 'quantity_parcel', 'product_unit_price', 'product_price_ha', 'product_price_parcel'].indexOf(name) > -1) {
        if (!isReverse) formated[name] = model[name] || 0;
        else formated[name] = model[name] === 0 ? null : model[name];
      }

      if (name === 'common_activity') {
        if (!isReverse) {
          formated[name] = {
            ...model[name],
            comment: model[name]['comment'] ? model[name]['comment'] : '',
            price_ha: model[name]['price_ha'] ? model[name]['price_ha'] : 0,
            price_parcel: model[name]['price_parcel'] ? model[name]['price_parcel'] : 0,
            fuel_consumption: model[name]['fuel_consumption'] ? model[name]['fuel_consumption'] : 0,
          }
        } else {
          console.log(model.common_activity);
          formated.upload_document = model.upload_document.map(obj => ({ name: obj.name_field, tags: obj.tags, description: obj.description }));
          formated[name] = {
            ...model[name],
            activity_type: model.activity_type,
            comment: model[name]['comment'] === '' ? null : model[name]['comment'],
            price_ha: model[name]['price_ha'] === 0 ? null : parseFloat(model[name]['price_ha']),
            price_parcel: model[name]['price_parcel'] === 0 ? null : parseFloat(model[name]['price_parcel']),
            fuel_consumption: model[name]['fuel_consumption'] === 0 || model[name]['fuel_consumption'] === "0" ? null : parseFloat(model[name]['fuel_consumption']),
          }
        }

      }
    });

    if (isReverse) {
      let fd = new FormData();

      fd.append('data', JSON.stringify(formated));

      for (let i = 0; i < model.upload_document.length; i++) {
        fd.append('upload_document', model.upload_document[i]);
      }

      return fd
    }

    return formated;
  }

  postSeasonRecord(plantingModel, harvestingModel, withHarvest) {
    let promises = [
      this.createActivity({ ...plantingModel, activity_status: true, upload_document: [] })
    ];

    if (withHarvest) {
      promises.push(this.createActivity({ ...harvestingModel, activity_status: true, upload_document: [] }))
    }

    return axios.all(promises)
      .then(axios.spread((planting, harvesting) => {
        return { planting, harvesting }
      }))
      .catch(err => { throw err })
  }

  setActiveCrop(date, geojson, locale) {
    let filtered = {
      type: 'FeatureCollection',
      features: geojson.features.map(feature => {
        let ftr = Object.assign({}, { ...feature });
        let crops = ftr.properties.crop_history.map(c => Object.assign({}, { ...c }));
        ftr.properties = Object.assign({}, { ...ftr.properties });
        ftr.properties.activeCrop = {};

        if (crops.length === 0) {
          ftr.properties.activeCrop = { sowing: moment().format('YYYY-MM-DD'), harvest: null, crop: 999, crop_name: getTranslation(locale).not_defined };
        } else {
          ftr.properties.activeCrop = this.cropCheck(date, crops.reverse(), locale, ftr.properties.pk === 3144)
        }

        return Object.assign({}, { ...ftr })
      })
    };

    return filtered;
  }

  cropCheck(date, crops, locale, toLog) {


    if (!date) {
      if (crops[crops.length - 1].harvest) {
        return { sowing: moment().format('YYYY-MM-DD'), harvest: null, crop: 999, crop_name: getTranslation(locale).not_defined };
      } else {
        return {
          sowing: crops[crops.length - 1].sowing,
          harvest: crops[crops.length - 1].harvest,
          crop: crops[crops.length - 1].crop.pk,
          crop_name: crops[crops.length - 1].crop.name
        }
      }
    }

    let filtered = crops.filter((obj, i) => {
      //IMA HARVEST
      if (obj.harvest) {

        if (this.betweenTwoDates(date, obj.sowing, obj.harvest)) {
          return true
        }

        return false
      }


      //NEMA HARVEST, IMA SLEDECI SOWING
      // if(toLog) {
      //   console.log(crops[i+1].sowing)
      // }

      if (crops[i + 1]?.sowing) {
        if (this.betweenTwoDates(date, obj.sowing, crops[i + 1].sowing)) {
          return true
        }

        return false
      }


      //NEMA HARVEST, NEMA SLEDECI SOWING,
      if (moment(date).diff(moment(obj.sowing)) >= 0) {
        return true;
      }

      return false;
    });


    if (!filtered[0]) return { sowing: moment().format('YYYY-MM-DD'), harvest: null, crop: 999, crop_name: getTranslation(locale).not_defined };



    return {
      sowing: filtered[0].sowing,
      harvest: filtered[0].harvest,
      crop: filtered[0].crop.pk,
      crop_name: filtered[0].crop.name
    }
  }

  betweenTwoDates(date, min, max, includeMax) {
    if (includeMax) {
      if (moment(max).diff(moment(date)) >= 0 && moment(date).diff(moment(min)) >= 0) return true;
      return false
    }

    if (moment(max).diff(moment(date)) > 0 && moment(date).diff(moment(min)) >= 0) return true;

    return false
  }

  getACRequests() {
    return axios.get(URL.AC_REQUESTS + '?limit=999999999')
      .then(result => result.data.results)
      .catch(err => { throw err.message })
  }

  confirmACRequest(model, id) {
    return axios.post(URL.AC_REQUESTS + `${id}/response/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  cancelACRequest(model, id) {
    return axios.post(URL.AC_REQUESTS + `${id}/response/`, { ...model, status: false })
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getAlerts() {
    return axios.get(URL.ALERTS)
      .then(result => {
        try {
          let parser = new DOMParser();
          let parsed = parser.parseFromString(result.data, 'text/html');

          let rows = Array.from(parsed.querySelectorAll('table.ms-listviewtable tr:not(.ms-alternating):not(.ms-viewheadertr)')).filter((row, key) => {
            if (row.children.length === 6) return true;
            return false
          });


          let items = [];

          rows.map(row => {

            let ID = row.children[1].querySelector('a').href.split('?')[1]

            items.push({
              title: row.children[1].innerText,
              link: 'http://pisvojvodina.com' + row.children[1].querySelector('a').pathname + '?' + ID,
              date: row.children[4].innerText,
              region: row.children[5].innerText
            })
          })

          return items;

        } catch (e) {
          console.log(e)
          return []
        }

      })
      .catch(err => { throw err })
  }

  postContactMessage(model) {
    return axios.post(URL.CONTACT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getPhotos() {
    return axios.get(URL.PHOTOS)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deletePhoto(id) {
    return axios.delete(URL.PHOTOS + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }


  uploadPhotos(model) {
    return axios.post(URL.PHOTOS, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  updatePhoto(id, model) {
    return axios.patch(URL.PHOTOS + `${id}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }


  getLocations(query) {
    if (!query) return;
    return axios.get(URL.NOMINATIM + query, {
      transformRequest: [(data, headers) => {
        delete headers.common.Authorization;

        return data;
      }]
    })
      .then((result) => {
        return result.data.map(obj => ({
          key: JSON.stringify(obj.place_id + Math.random()),
          title: obj.display_name,
          description: `${obj.lat}, ${obj.lon}`,
          coordinates: fromLonLat([parseFloat(obj.lon), parseFloat(obj.lat)])
        }))
      })
      .catch(err => { throw err })
  }

  getRegPractices(parcel, locale) {

    return axios.get(URL.PRACTICE + `?parcel_id=${parcel}`)
      .then(result => result.data.map(p => ({ ...p, reg_practice: p.reg_practice ? p.reg_practice.split(';').map(p => detectAndMap(p, locale)) : [] })))
      .catch(err => { throw err.message })

  }

  postPractice(parcelID, model) {
    let formated = {
      ...model,
      year: parseInt(model.year),
      reg_practice: model.reg_practice.join(';'),
      parcel: parcelID
    }

    return axios.post(URL.PRACTICE, formated)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  patchPractice(parcelID, model) {
    let formated = {
      ...model,
      reg_practice: model.reg_practice.join(';'),
      parcel: parcelID
    }

    return axios.patch(URL.PRACTICE + `${model.id}/`, formated)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  deletePractice(id) {
    return axios.delete(URL.PRACTICE + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  downloadData(model, callback) {
    return axios.post(URL.DOWNLOAD_DATA, model, {responseType: 'blob', onDownloadProgress: callback})
      .then(result => result.data)
      .catch(err => { throw err.message })
  }
}

export default new $data();