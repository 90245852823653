import axios from 'axios';
import { toLonLat } from 'ol/proj';
import URL from '../config/urls';
import translation, { getTranslation } from '../config/locale/translation';
import $cookies from './$cookies';
import $seasons from './$seasons';
import moment from 'moment';
import $data from './$data';
import $map from './$map';


let sensorSource = axios.CancelToken.source();
let pointSource = axios.CancelToken.source();
let summarySource = axios.CancelToken.source();
let gddSource = axios.CancelToken.source();
let meteoSource = axios.CancelToken.source();
let forecastSource = axios.CancelToken.source();

let pointController = new AbortController();

const emptyQuery = {
	s2: {
		ndvi: [],
		chl: [],
		productivity: [],
		mzones: [],
		cumndvi: []
	},
	planet: {
		ndvi: [],
		chl: [],
		productivity: [],
		mzones: [],
		cumndvi: []
	}
};

class $query {

	sensor(sensorObj, isCompare, additionalFilter = '') {
		let filter = '';
		if(!isCompare) {
			sensorSource.cancel('cancel');
			sensorSource = axios.CancelToken.source()
		}	

		filter = filter + `?device_name=${sensorObj.get('name') + additionalFilter}`;

		return axios.get(URL.SENSOR_MEASUREMENTS + filter, {
			cancelToken: sensorSource.token,})
			.then(result => {
				let groups = {}
				result.data.map(obj => {
					if(groups[obj.variable]) {
						groups[obj.variable].push({x: obj.timestamp, y: obj.value})
					} else {
						groups[obj.variable] = [{x: obj.timestamp, y: obj.value}]
					}
				})

				return [groups]
			})
			.catch(err => {throw err});
			
	}

	point(coordinates, parcel, isCompare, layersString = ["ndvi", "chl", "mz"]) {
		let wgs = toLonLat(coordinates);
		if(!isCompare) {
			pointController.abort('cancel');
			pointController = new AbortController();
			// pointSource.cancel('cancel');
			// pointSource = axios.CancelToken.source()
		}

		return axios.post(URL.QUERY.replace('__ID__', parcel), {
			lat: wgs[1],
			lon: wgs[0],
			layer: layersString

		// }, {cancelToken: pointSource.token}).then((result) => {
		}, {signal: pointController.signal}).then((result) => {
			return [this.formatIndexes(result.data, layersString)]
		}).catch(err => {
			if(isCompare) {
				throw err
			} else {
				throw err.message

			}
		})
	}

	comparePoints(first, second, layersString = ["ndvi", "chl", "mz"]) {
		pointController.abort('cancel');
		pointController = new AbortController();

		let a = this.point(first.coordinates, first.parcel, true, layersString);
    let b = this.point(second.coordinates, second.parcel, true, layersString); 

		return axios.all([a,b])
			.then(axios.spread((pointA, pointB) => {
				return [pointA[0], pointB[0]]
			}))
			.catch(err => {
				console.log(err)
				throw err.message
			})


	}

	summary(parcel, isCompare) {
		if(!isCompare) {
			summarySource.cancel('cancel');
			summarySource = axios.CancelToken.source();
		}

		return axios.get(URL.SUMMARY_BOTH.replace('__ID__', parcel), {cancelToken: summarySource.token})
			.then(result => [result.data])
			.catch(err => {throw err.message})

	}

	compareSummaries(parcelA, parcelB) {
		summarySource.cancel('cancel');
		summarySource = axios.CancelToken.source();

		let a = this.summary(parcelA, true);
    let b = this.summary(parcelB, true);

		return axios.all([a, b])
			.then(axios.spread((parcelA, parcelB) => {
				return [parcelA[0], parcelB[0]]
			}))
			.catch(err => {throw err.message})
	}

	gdd(parcel, isCompare, filter = {}) {
		if(!isCompare) {
			gddSource.cancel('cancel');
			gddSource = axios.CancelToken.source();
		}

		let filterString = Object.keys(filter).map((key, i) => {
			if(key !== 'id') {
				if(filter.date_from && filter.date_to == 'Invalid date') {
					filter.date_to = moment(filter.date_from).add(8, 'months').format('YYYY-MM-DD');
				}

				return `${key}=${filter[key]}`;
			}
		}).join('&');

		return axios.get(URL.GDD.replace('__ID__', parcel) + `?${Object.keys(filter).filter(name => name !== 'id').map(param => `${param}=${filter[param]}`).join('&')}`, {cancelToken: gddSource.token})
			.then(result => { 
				if(Array.isArray(result.data)) return [result.data];
				return [Object.keys(result.data).map(date => ({x: date, stage: result.data[date].CropStage, y: result.data[date].DegreeDays, base: result.data[date].TempBase}))]
			})
			.catch(err => {throw err.message})
	}

	compareGdds(parcelA, parcelB, filterA = {}, filterB = {}) {
		gddSource.cancel('cancel');
		gddSource = axios.CancelToken.source();

		let a = this.gdd(parcelA, true, filterA);
    let b = this.gdd(parcelB, true, filterB);

		return axios.all([a, b])
			.then(axios.spread((parcelA, parcelB) => {
				return [parcelA[0], parcelB[0]]
			}))
			.catch(err => {throw err.message})
	}

	meteo(parcel, filter = "", isCompare) {
		if(!isCompare) {
			meteoSource.cancel('cancel');
			meteoSource = axios.CancelToken.source();
		}

		return axios.get(URL.METEO.replace('__ID__', parcel) + filter, {cancelToken: meteoSource.token})
			.then(result => [result.data.map(obj => ({...obj, prcp: obj.prcp/10, tmin: obj.tmin/10, tmax: obj.tmax/10}))])
			.catch(err => {throw err.message})
	}

	compareMeteos(parcelA, parcelB, filter) {
		meteoSource.cancel('cancel');
		meteoSource = axios.CancelToken.source();

		let a = this.meteo(parcelA, filter, true);
    let b = this.meteo(parcelB, filter, true);
		return axios.all([a, b])
			.then(axios.spread((parcelA, parcelB) => {
				return [parcelA[0], parcelB[0]]
			}))
			.catch(err => {throw err.message})
	}

	forecast(coordinates, isCompare) {
		let wgs = toLonLat(coordinates);
		if(!isCompare) {
			forecastSource.cancel('cancel');
			forecastSource = axios.CancelToken.source();
		}

		return axios.get(URL.FORECAST.replace('__LAT__', wgs[1]).replace('__LON__', wgs[0]), {
			cancelToken: forecastSource.token,
			transformRequest: [(data, headers) => {
				delete headers.common.Authorization;
				return JSON.stringify(data)
			}]
		})
			.then(result => [this.formatForecast(result.data)])
			.catch(err => {throw getTranslation($cookies.get('locale')).forecast_unavailable})
	
	}

	compareForecasts(pointA, pointB) {
		forecastSource.cancel('cancel');
		forecastSource = axios.CancelToken.source();

		let a = this.forecast(pointA, true);
    let b = this.forecast(pointB, true);

		return axios.all([a, b])
			.then(axios.spread((pointA, pointB) => {
				return [pointA[0], pointB[0]]
			}))
			.catch(err => {throw err.message})
	}

	photos(parcel, photos) {
		let parcelObj = $data.getParcelById(parcel);
		
		let filtered = photos.filter(obj => $map.coordinatesInParcel(obj.latitude, obj.longitude, parcelObj));
		
		photos.map(obj => {
			if(obj.parcel === parcel) {
				filtered.push({...obj});
			}
		})
		
		return filtered;
	}

	comparePhotos(parcelA, parcelB, photos) {

	}

	formatIndexes(data) {
		try {
			return {
				s2: {
					ndvi: Object.keys(data?.ndvi ? data?.ndvi[0] : {}).map(date => ({x: date, y: data?.ndvi[0][date]})).filter(obj => obj.y > 0),
					chl: Object.keys(data?.chl ? data?.chl[0] : {}).map(date => ({x: date, y: data?.chl[0][date]})).filter(obj => obj.y > 0),
					// productivity: isNaN(data?.mz ? data?.mz[0]['mz'] : []) ? [] : [data?.mz[0]['mz']],
					productivity: data?.mz ? [data?.mz[0]?.mz] : [],
					// mzones: isNaN(data?.mz ? data?.mz[0]['mz'] : []) ? [] : [data?.mz[0]['mz']]
					mzones: data?.mz ? [data?.mz[0]?.mz] : [],
					cumndvi: []
				},
				planet: {
					ndvi: Object.keys(data?.ndvi_planet ? data.ndvi_planet[0] : {}).map(date => ({x: date, y: data.ndvi_planet[0][date]})).filter(obj => obj.y > 0),
					chl: Object.keys(data?.chl_planet ? data.chl_planet[0] : {}).map(date => ({x: date, y: data.chl_planet[0][date]})).filter(obj => obj.y > 0),
					// productivity: isNaN(data?.mz_planet ? data.mz_planet[0]['mz'] : []) ? [] : [data.mz_planet[0]['mz']],
					// mzones: isNaN(data?.mz_planet ? data.mz_planet[0]['mz'] : []) ? [] : [data.mz_planet[0]['mz']]
					productivity: data?.mz_planet ? [data?.mz_planet[0]?.mz] : [],
					mzones: data?.mz_planet ? [data?.mz_planet[0]?.mz] : [],
					cumndvi: []
				}
			}
		} catch(e) {
			console.log(e)
			return Object.assign({}, emptyQuery)
		}
		
	}

	getShortSummaryItemStatus(val, prevValue) {
		if(!val || !prevValue) return null;
		if(val > prevValue) return 'up';
		if(val < prevValue) return 'down';
		if(val === prevValue) return 'equal'
	}

	formatShortSummary(data, season, seasons, source) {
		let groups = {
			yield: null,
			ndvi: [], // {}
			chl: [], // {}
		}

		// let {min, max} = $seasons.getMinMaxFromSeasons([season]);
		
		// if(season === seasons[seasons.length - 1]) {
		// 	max = moment().format('YYYY-MM-DD');
		// }
			// .filter(obj => moment(max).diff(moment(obj.date)) >= 0 && moment(obj.date).diff(moment(min)) >= 0)

		let filtered = data[source].map(obj => ({...obj})).reverse().filter((obj, key) => key <= 5);


		filtered.map((obj, key) => {
				if(key === 0) {
					groups.yield = obj.yield_mean ? obj.yield_mean.toFixed(2) : null;
				}
				if(key < 5) {
					groups.ndvi.push({date: moment(obj.date).format('DD-MMM-YYYY'), val: obj?.ndvi_mean?.toFixed(2), status: this.getShortSummaryItemStatus(obj?.ndvi_mean, filtered[key + 1]?.ndvi_mean)})
					groups.chl.push({date: moment(obj.date).format('DD-MMM-YYYY'), val: obj?.chl_mean?.toFixed(2), status: this.getShortSummaryItemStatus(obj?.chl_mean, filtered[key + 1]?.chl_mean)})
				}
			})
		
		return groups;
	}

	formatForecast(data) {
		let formated = {};

		let {timeseries} = data.properties;
		try {
			timeseries.map((obj, key) => {
				if(key < timeseries.length - 1) {
					let day = moment(obj.time).format('YYYY-MM-DD');
					let {air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_speed} = obj.data.instant.details;
					let {precipitation_amount} = obj.data.hasOwnProperty('next_1_hours') ? obj.data.next_1_hours.details : obj.data.next_6_hours.details;
					let {symbol_code} = obj.data.hasOwnProperty('next_1_hours') ? obj.data.next_1_hours.summary : obj.data.next_6_hours.summary;
			
					if(formated[day]) {
						formated[day].pressure.push(air_pressure_at_sea_level);
						formated[day].temperature.push(air_temperature);
						formated[day].precipitation.push(precipitation_amount);
						formated[day].cloudiness.push(cloud_area_fraction);
						formated[day].humidity.push(relative_humidity);
						formated[day].wind.push(wind_speed);
						formated[day].symbol.push(symbol_code);
						formated[day].times.push(obj.time);			
					} else {
						formated[day] = {};
	
						formated[day].pressure = [air_pressure_at_sea_level];
						formated[day].temperature = [air_temperature];
						formated[day].precipitation = [precipitation_amount];
						formated[day].cloudiness = [cloud_area_fraction];
						formated[day].humidity = [relative_humidity];
						formated[day].wind = [wind_speed];
						formated[day].symbol = [symbol_code];
						formated[day].times = [obj.time];
					}
	
				}
			})

			return formated;
		} catch(e) {

			return {};
		}
	}

}

export default new $query();