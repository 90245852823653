import { LocationOn, Menu, MyLocation, Search } from "@mui/icons-material";
import { Autocomplete, Button, CircularProgress, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { fromLonLat } from "ol/proj";
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import $map from "../../services/$map";
import useMergeState from "../hooks/useMergeState";
import Coordinates from "./Coordinates";

function SearchLocation(props) {
  const {lang, panel, geolocation, onUpdateState} = useContext(AppContext);

  const [state, setState] = useMergeState({
    options: [],
    query: '',
    active: '',
    loading: false
  });

  const format = (str) => {
    let latLon = str.split(', ').map(v => parseFloat(v));

    return {
      key: -11,
      title: `${lang.go_to} ${state.query}`,
      coordinates: fromLonLat(latLon.reverse())
    }
  }

  const getLocations =  _.debounce(() => {
		if(!state.query) return;
    let isLatLon = state.query.match(/^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/);
		if(isLatLon) {
			setState({loading: false, options: [format(state.query)]})
		} else {
      $data.getLocations(state.query)
        .then(data => {
          setState({loading: false, options: data})
        })
        .catch(err => {
          console.log(err)
        })
    }
	}, 500)

  useEffect(() => {
    setState({options: []});
		if(state.query !== '') {
      setState({loading: true})
			getLocations();
		} else {
      setState({loading: false, options: []});
    }

		return getLocations.cancel
	}, [state.query]);

  
  return (
    <>
    <Autocomplete
      sx={{borderRadius: '4px', zIndex:1, position: 'absolute', left: '7px', top: '7px', background: '#fff', maxWidth: '400px', width: 'calc(100% - 56px)'}}
      id="search-location"
      options={state.options}
      getOptionLabel={(option) => option.title}
      onChange={(evt, value, reason) => {
        if(reason === 'selectOption') {
          if($map.instance) {
            $map.instance.getView().animate({center: value.coordinates, duration: 500}, {zoom: 12, duration: 500})
          }
        }
      }}
      onInputChange={(evt, value, reason) => {
        if(reason === 'input') {
          setState({query: value})
        }
      }}
      filterOptions={(options) => {return options}}
      renderOption={(params, option) => {
        return (<Box key={option.key} {...params} component="li" size="small">
          <Stack direction="row" alignItems="center" sx={{color: '#555'}}><LocationOn color="primary" />&nbsp;{option.title}</Stack>
        </Box>)
      }}
      renderInput={(params) => <TextField {...params} className="search-location-input" placeholder={lang.search_placeholder} size="small" InputProps={{
        ...params.InputProps,
        startAdornment: <InputAdornment position="start"><Menu sx={{cursor: 'pointer'}} onClick={(evt) => {evt.stopPropagation(); props.setPanel(!props.panel)}} color="primary" /></InputAdornment>,
        endAdornment: <InputAdornment position="end">{state.loading ? 
          <CircularProgress size={20} /> : <Search size="small" />}&nbsp;|&nbsp;&nbsp;&nbsp;
          <Tooltip title={lang.my_location} position="bottom"><MyLocation onClick={(evt) => {evt.stopPropagation(); onUpdateState({geolocation: !geolocation})}} sx={{color: geolocation ? '#4285F4' : 'inherit', cursor: 'pointer', marginRight: '7px'}}/></Tooltip></InputAdornment>
      }} />}
      
     />
     <Coordinates a={props.pointA} b={props.pointB} />
     </>
  )
}

export default SearchLocation;