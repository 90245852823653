import { Check, Close } from "@mui/icons-material";
import { Chip, Grid, TextField, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";

function PlantingView(props) {
	let {lang, primary} = useContext(AppContext);
	let {data} = props;
	console.log(props)
	const [multiline, setMultiline] = useState(false);
	
	const operators = data.operators.map((operator, key) => {
		return <Chip style={{marginRight: '3px', marginLeft: '3px', marginBottom: '5px'}} key={key} variant="outlined" color="primary" label={operator.first_name + ' ' + operator.last_name} />
	});

	const getTgwWeight = () => {
		return (Boolean(data.tgw) && Boolean(data.plants_ha / 10000)) ? {ha: (parseFloat(((data.plants_ha/10000) * data.tgw)/100)).toFixed(2), parcel: (parseFloat(parseFloat(data.area_ha) * (((data.plants_ha/10000) * data.tgw)/100))).toFixed(2)}  : {ha: 0, parcel: 0};
	}

	return (
		<Grid className={props.className || ''} container spacing={2}>
			{props.isJob && <Grid item xs={12} sm={4}>
				<TextField className="job-field" focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.from} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.jo_from ? moment(data.jo_from).format('DD-MMM-YYYY') : ' - '} />
			</Grid>}
			{props.isJob && <Grid item xs={12} sm={4}>
				<TextField className="job-field" focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.to} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.jo_to ? moment(data.jo_to).format('DD-MMM-YYYY') : ' - '} />
			</Grid>}
			{props.isJob && <Grid item xs={12} sm={4}>
				<Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '40px'}} variant="subtitle1">{lang.send_sms} {data.sms ? <Check style={{marginBottom: '3px'}} color={'success'} /> : <Close style={{marginBottom: '3px'}} color={'error'} />} </Typography>
			</Grid>}
			{props.isJob && <Grid item xs={12}>
				<Divider style={{width: '100%', height: '1px', margin: 0}} />
			</Grid>}
			{!props.isJob && <Grid item xs={12} sm={4}>
				<TextField focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.date} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={moment(data.date).format('DD-MMM-YYYY') || ' - '} />
			</Grid>}
			{!props.isJob && <Grid item xs={12} sm={4} style={{padding: 0}}></Grid>}	
			{!props.isJob && <Grid item xs={12} sm={4} style={{padding: 0}}></Grid>}	
			<Grid item xs={12} sm={6} md={4}>
				<TextField focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.crop_group} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.crop_group_name || ' - '} />
			</Grid>	
			<Grid item xs={12} sm={6} md={4}>
				<TextField focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.crop_type} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.crop_type_name || ' - '} />
			</Grid>	
			<Grid item xs={12} sm={6} md={4}>
				<TextField focused multiline={multiline ? true : false} onClick={() => setMultiline(!multiline)} label={lang.seed_type} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.seed_type || ' - '} />
			</Grid>	

			<Grid item xs={12} sm={6} >
				<TextField focused label={lang.depth} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={`${data.depth || ' - '}`} />
			</Grid>	
			<Grid item xs={12} sm={6} >
				<TextField focused label={lang.row_spacing} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={`${data.row_spacing || ' - '}`} />
			</Grid>	

			<Grid item xs={12} sm={4} >
				<TextField focused label={lang.plants_m2} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={`${data.plants_ha / 10000 || ' - '}`} />
			</Grid>	
			
			<Grid item xs={12} sm={4} >
				<TextField focused label={lang.plants_ha} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={`${data.plants_ha || ' - '}`} />
			</Grid>	
			<Grid item xs={12} sm={4} >
				<TextField focused label={lang.plants_parcel} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={`${data.plants_parcel || ' - '}`} />
			</Grid>	

			<Grid item xs={12} sm={4} >
				<TextField focused label={'Thousand-grain weight (g)'} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={`${data.tgw || ' - '}`} />
			</Grid>	

	<Grid item xs={12} sm={6} md={4} >
				<TextField sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer', fontWeight: 'bold'} }} focused label={'Sowing rate (kg/ha)'} fullWidth size="small" inputProps={{readOnly: true}} value={isNaN(getTgwWeight().ha) ? ' - ' : getTgwWeight().ha} />
			</Grid>
			<Grid item xs={12} sm={6} md={4} >
				<TextField sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer', fontWeight: 'bold'}}} focused label={'Sowing rate (kg/field)'} fullWidth size="small" inputProps={{readOnly: true}} value={isNaN(getTgwWeight().parcel) ? ' - ' : getTgwWeight().parcel} />
			</Grid>

			<Grid item xs={12} sm={4} >
				<TextField focused label={lang.product_unit_price} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.product_unit_price ? data.product_unit_price.toFixed(5)  : ' - '} />
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField focused label={lang.product_price_ha} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.product_price_ha || ' - '} />
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField focused label={lang.product_price_parcel} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.product_price_parcel || ' - '} />
			</Grid>

			<Grid item xs={12} sm={4} md={3} >
				<TextField focused label={lang.price_ha} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.price_ha || ' - '} />
			</Grid>
			<Grid item xs={12} sm={4} md={3} >
				<TextField focused label={lang.price_parcel} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.price_parcel || ' - '} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer', fontWeight: 'bold'} }} focused label={lang.total_price_ha} fullWidth size="small" inputProps={{readOnly: true}} value={isNaN(data.price_ha + data.product_price_ha) ? ' - ' : (data.price_ha + data.product_price_ha).toFixed(2)} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer', fontWeight: 'bold'}}} focused label={lang.total_price_parcel} fullWidth size="small" inputProps={{readOnly: true}} value={isNaN(data.price_parcel + data.product_price_parcel) ? ' - ' : (data.price_parcel + data.product_price_parcel).toFixed(2)} />
			</Grid>

			<Grid item xs={12} >
				<p style={{margin: '0 0 5px 13px', color: primary, fontWeight: 'bold', fontSize: '12px'}}>{lang.operators}</p>
				{
					operators.length === 0 ?
					<Chip variant="outlined" color="warning" label={lang.no_operators} />
					:
					operators
				}
			</Grid>

			<Grid item xs={12}>
				<TextField focused label={lang.fuel_consumption} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.fuel_consumption || ' - '} />
			</Grid>	

			<Grid item xs={12} sm={6} >
				<TextField focused label={lang.fld_machine} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.machine_name || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} >
				<TextField focused label={lang.fld_con_machine} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.conn_machine_name || ' - '} />
			</Grid>
			<Grid item xs={12}>
				<TextField focused multiline label={lang.comment} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.comment || ' - '} />
			</Grid>	
		</Grid>
	)
}

export default PlantingView;