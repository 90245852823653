import { blue } from "@mui/material/colors";
import { i } from "mathjs";
import moment from "moment";
import $seasons from "../../services/$seasons";

const getTempBase = (data, lang) => {
  if(!data) return '';

  if(data.length == 0) return '';
  console.log(data[0])
  return ` - ${lang.gddBase}: ${data[0].base} °C`
}

export const formatData = (data, lang) => {
  console.log(data)
	return {
    datasets : [{
      data: data,
			label: lang.gdd + getTempBase(data, lang) ,
			borderColor: 'red',
			borderWidth: 1,
			pointBackgroundColor: 'red',
      backgroundColor: 'red',
			pointRadius: 2,
			fill: true,
			spanGaps: true,
      yAxisID: 'y',
		}]
  } 
	
}

export const formatOptions = (lang) => {
  return Object.assign({},{
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      },
      tooltip: {
        callbacks: {
          title: (val) => {
            return moment(val[0].raw.x).format('DD-MMM-YYYY')
          },
          label: (val) => {
            let unit = '';
            if(val.datasetIndex == 0) unit = ' °C';
            if(val.datasetIndex == 1) unit = ' °C';
            if(val.datasetIndex == 2) unit = ' mm';
            
            return `${val.dataset.label}: ${val.parsed.y}${unit}`
          },
          footer: (val) => {
            return val[0].raw.stage ? val[0].raw.stage : ' - '
          }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        },
        pan: {
          enabled: true,
          threshold: 1,
          mode: 'x',
          modifierKey: 'shift'
        }
      },
    
    },
    interaction: {
      mode: 'nearest',
			axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        position: 'left',
				suggestedMin: 0,
      },
     
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD-MMM-YYYY'
          }
        },
        ticks: {
          autoSkipPadding: 10,
          maxRotation: 0,
        }
       
      }
    },
  })
}