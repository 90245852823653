import { Delete, Edit, RotateRight } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import ConfirmDialog from "../../ui/ConfirmDialog";
import EditPhoto from "../modals/EditPhoto";
import EXIF from "exif-js";

function PhotosPreview ({photos, onClose}) {
  const {lang, onUpdatePhotos} = useContext(AppContext);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [deletePhoto, setDeletePhoto] = useState(0);
  const [editPhoto, setEditPhoto] = useState(null);

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    console.log(rotation)
    let el = document.querySelector('.ril-image-current.ril__image');
    if(el) {
      el.style.transform = el.style.transform.split('rotate')[0].trim();
      el.style.transform =  `${el.style.transform} rotate(${rotation}deg)`;
      console.log(el.style.transform);
    }
  }, [rotation])

  if(!photos) return null;

  const onClosePreview = () => {
    setPhotoIndex(0);
    onClose();
  }

  const onSubmit = () => {
 

    $data.deletePhoto(deletePhoto)
      .then(data => {
        setDeletePhoto(null);
        onClosePreview();
        onUpdatePhotos();
      })
      .catch(err => {

      })

  }



  const rotateImage = (element) => {
    if(rotation == 315) {
      setRotation(0);
    } else {
      setRotation(rotation + 45);
    }
  }

  console.log(photos[photoIndex])

  return (
    <>
      <Lightbox
          mainSrc={photos[photoIndex].image}
          nextSrc={photos.length > 1 ? photos[(photoIndex + 1) % photos.length].image : null}
          prevSrc={photos.length > 1 ? photos[(photoIndex + photos.length - 1) % photos.length].image : null}
          imageTitle={photos[photoIndex].name}

          imageCaption={<Stack alignItems="center" sx={{width: '100%'}} direction="row" justifyContent="space-between"><>{photos[photoIndex].date_time && moment(photos[photoIndex].date_time).format('DD-MMM-YYYY')}</>
            <div>
              <Button onClick={() => {setEditPhoto(photos[photoIndex])}} color="warning" size="small" variant="contained" startIcon={<Edit />}>{lang.edit}</Button>&nbsp;
              <Button onClick={() => {setDeletePhoto(photos[photoIndex].id)}} color="error" size="small" startIcon={<Delete fontSize="small" />} variant="contained">{lang.delete}</Button> 
            </div>
            <RotateRight onClick={() => {
              rotateImage( document.getElementsByTagName('ril-image-current ril__image')[0]);
            }} />
          </Stack>}
          onCloseRequest={onClosePreview}
          onMovePrevRequest={() =>{
            setRotation(0);
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
          }
          onMoveNextRequest={() =>{       
            setRotation(0);

            setPhotoIndex((photoIndex + 1) % photos.length) }
          }
          />
        {editPhoto && <EditPhoto open={Boolean(editPhoto)} data={editPhoto} onClose={() => setEditPhoto(null)} onClosePreview={onClosePreview} /> }
        <ConfirmDialog open={Boolean(deletePhoto)} title={lang.delete_photo} question={lang.delete_photo_question} onClose={() => {setDeletePhoto(null); onClosePreview();}} onSubmit={onSubmit} />
    </>
  )
}

export default PhotosPreview;