const  countries = (lang = {}) => [
  {value: 1, label: lang.uk, phoneCode: 'GB'},
  {value: 2, label: lang.greece, phoneCode: 'GR'},
  {value: 3, label: lang.poland, phoneCode: 'PL'},
  {value: 4, label: lang.serbia, phoneCode: 'RS'},
  {value: 5, label: lang.portugal, phoneCode: 'PT'},
]

export const getCountryBbox = (id) => {
  switch(id) {
    case "1": return [-7.57216793459, 49.959999905, 1.68153079591, 58.6350001085];
    case "2": return [20.1500159034, 34.9199876979, 26.6041955909, 41.8269046087];
    case "3": return [14.0745211117, 49.0273953314, 24.0299857927, 54.8515359564];
    case "4": return [18.82982, 42.2452243971, 22.9860185076, 46.1717298447];
    case "5": return [-9.52657060387, 36.838268541, -6.3890876937, 42.280468655];
    default: return [-26.015625,26.902477,48.164063,71.580532]
  }
}

export const getCountryPhoneCode = (id, lang) => {
  return countries(lang).filter(obj => obj.value === id)[0]?.phoneCode || 'RS'
}

export default countries;