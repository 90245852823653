import MultiPolygon from "ol/geom/MultiPolygon";
import VectorImageLayer from "ol/layer/VectorImage";
import VectorLayer from "ol/layer/Vector";
import { unByKey } from "ol/Observable";
import { getVectorContext } from "ol/render";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import Fill from "ol/style/Fill";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import GeoJSON from "ol/format/GeoJSON";
import colors from '../config/colors';
import _ from "lodash";
import URL from "../config/urls";
import $data from "./$data";
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
import { blue } from "@mui/material/colors";
import { getArea } from "ol/sphere";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";

import IconStyle from 'ol/style/Icon';

import ReactDOMServer from 'react-dom/server';

import pattern from '../images/pattern.png';
import { Opacity } from "@mui/icons-material";
import opacity from '../images/opacity.svg';
import weather from '../images/weather.svg';
import photo from '../images/photo.svg';
import Text from "ol/style/Text";

var cnv = document.createElement('canvas');
var ctx = cnv.getContext('2d');
var img = new Image();
img.src = pattern;

var ptn = null;

img.onload = function() {
  ptn = ctx.createPattern(img, 'repeat');
};

const emptyFillStyle = new Style({
  stroke: new Stroke({
    width: 3,
    color: 'rgba(255,255,255, 0)' 
  }),
  fill: new Fill({
    color: 'rgba(255,255,255, 0)'
  })
});

class $map {
  constructor() {
    this.measureStyle = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: '#ffcc33',
        lineDash: [10, 10],
        width: 2,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: '#ffcc33',
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
      }),
    })

    this.photoStyle = (feature) =>  {
      if(feature.get('features').length > 1) {
        return new Style({
          fill: new Fill({color: 'red'}),
          stroke: new Stroke({color: '#fff', width: 2}),
          image: new CircleStyle({
            radius: 11,
            fill: new Fill({color: "#1c8adb"}),
            stroke: new Stroke({width: 1, color: '#fff'})
          }),
          text: new Text({
            font: '12px sans-serif',
            text: feature.get('features').length.toString(),
            fill: new Fill({color: '#fff'}),
            stroke: new Stroke({width: 1, color: '#fff'})
           
          })
        })
      }
      
      return new Style({
        fill: new Fill({color: 'red'}),
        stroke: new Stroke({color: '#fff', width: 2}),
        // image: new CircleStyle({
        //   radius: 5,
        //   fill: new Fill({color: "red"}),
        //   stroke: new Stroke({width: 2, color: '#fff'})
        // })
        image: new IconStyle({
          src: photo,
          scale: 0.6
         
        })
      })
    };




    this.sensorStyle = (feature) =>  new Style({
      fill: new Fill({color: 'red'}),
      stroke: new Stroke({color: '#fff', width: 2}),
      // image: new CircleStyle({
      //   radius: 5,
      //   fill: new Fill({color: "red"}),
      //   stroke: new Stroke({width: 2, color: '#fff'})
      // })
      image: new IconStyle({
        src: feature.get('type') === 'Node' ? opacity : weather,
        scale: 0.6
       
      })
    });

    this.sampleStyle = new Style({
      fill: new Fill({color: 'orange'}),
      stroke: new Stroke({color: '#fff', width: 3}),
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({color: "orange"}),
        stroke: new Stroke({width: 2, color: '#fff'})
      }),
    }) 

    this.sensorSelectedStyle = (feature) =>  new Style({
      fill: new Fill({color: 'red'}),
      stroke: new Stroke({color: '#fff', width: 2}),
      // image: new CircleStyle({
      //   radius: 5,
      //   fill: new Fill({color: "red"}),
      //   stroke: new Stroke({width: 2, color: '#fff'})
      // })
      image: new IconStyle({
        src: feature.get('type') === 'Node' ? opacity : weather,
        scale: 1,

       
      })
    });

    // this.sensorSelectedStyle =  new Style({
    //   fill: new Fill({color: 'red'}),
    //   stroke: new Stroke({color: '#fff', width: 2}),
    //   image: new CircleStyle({
    //     radius: 9,
    //     fill: new Fill({color: "green"}),
    //     stroke: new Stroke({width: 3, color: '#fff'})
    //   })
    // });
  }


  drawLayer() {
    return new VectorImageLayer({
      name: 'draw',
      zIndex: 3,
      source: new VectorSource()
    })
  }

  lineLayer() {
    return new VectorImageLayer({
      name: 'length',
      zIndex: 3,
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      })
    })
  }

  areaLayer() {
    return new VectorImageLayer({
      name: 'area',
      zIndex: 3,
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      })
    })
  }

  parcelLayer(geojson, cropTypes) {
    return new VectorImageLayer({
      name: 'parcels',
      zIndex: 3,
      style: (feature) => {
        let fid = feature.get('pk');
        let cropObj = feature.get('activeCrop')
        return new Style({
          fill: new Fill({color: 'transparent'}),
          // fill: new Fill({color: ptn}),
          stroke: new Stroke({color: cropObj.crop !== 999 ? colors.cropGroup[$data.getCropGroupIDByCrop(cropObj.crop, cropTypes)] : '#fff', width: 2})
        });

      },
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geojson)
      })
    })
  }

  setParcelStyle(layer, geojson) {
    layer.getSource().clear();
    layer.getSource().addFeatures(new GeoJSON().readFeatures(geojson))
  }

  sensorLayer(data, visible) {
    try {
      let features = []
      if(!data) {features = [];}
      else if(data.length > 0) {
        features = data.map(obj => {
          let coords = fromLonLat([parseFloat(obj.longitude), parseFloat(obj.latitude)]);

          let f = new Feature({geometry: new Point(coords)})
  
          f.setProperties({...obj});
  
          return f.clone();
        })
      }

      return new VectorLayer({
        name: 'sensors',
        zIndex: 7,
        source: new VectorSource({ features }),
        visible: visible,
        style: this.sensorStyle
        
      })

    } catch(e) {
      return null
    }
   
  }

  samplesLayer(data, visible) {
    try {
      let features = []
      if(!data) {features = [];}
      else if(data.length > 0) {

        let formated = [];

        data.map(obj => {

          formated = _.concat(formated, Object.values(obj)[0]);

        });



        features = formated.map(obj => {
          if(obj.parcel__name && (!obj.longitude || !obj.latitude)) {
            let pk = $data.getParcelIdByName(obj.parcel__name);
            if(pk) {

              let coords = $data.getParcelCentroid(pk);
              let f =  new Feature({geometry: coords});
              f.setProperties({...obj});
              return f.clone();
            }
            return null;
          }


          let coords = fromLonLat([parseFloat(obj.longitude), parseFloat(obj.latitude)]);

          let f = new Feature({geometry: new Point(coords)})
  
          f.setProperties({...obj});
  
          return f.clone();
        })
      }

      return new VectorLayer({
        name: 'samples',
        zIndex: 7,
        source: new VectorSource({ features: features.filter(f => f != null) }),
        visible: visible,
        style: this.sampleStyle
        
      })

    } catch(e) {
      return null
    }
   
  }

  photosLayer(data, visible) {
    try {
      let features = []
      if(!data) {features = [];}
      else if(data.length > 0) {
        features = data.map(obj => {
         
          if(obj.parcel && (!obj.longitude || !obj.latitude)) {
            console.log(obj.parcel)
            let coords = $data.getParcelCentroid(obj.parcel);
            if(coords) {
              let f =  new Feature({geometry: coords});
              f.setProperties({...obj});
              return f.clone();  
            }

            return null
          }


          if(obj.latitude && obj.longitude) {
         
            let coords = fromLonLat([parseFloat(obj.longitude), parseFloat(obj.latitude)]);

            let f = new Feature({geometry: new Point(coords)})
    
            f.setProperties({...obj});
    
            return f.clone();
          }

        })
      }


      return new VectorLayer({
        name: 'photos',
        zIndex: 7,
        source: new Cluster({ source: new VectorSource( {features: features.filter(f => f != null)}),  }),

        visible: visible,
        style: this.photoStyle
        
      })

    } catch(e) {
      
      return new VectorLayer({
        name: 'photos',
        zIndex: 7,
        source: new Cluster({ source: new VectorSource( {features: []}),  }),

        visible: visible,
        style: this.photoStyle
        
      })
    }
   
  }

  setBase(base, basemaps) {
    basemaps.map(b => {
      if(b.get('name') === base) b.setVisible(true);
      else b.setVisible(false)
    })
  }

  setRgb(bool, rgb) {
    rgb.setVisible(bool)
  }

  setLayer(layer, layers) {
    layers.map(l => {
      if(l.get('name') === layer) l.setVisible(true);
      else l.setVisible(false)
    })
  }

  setSensors(bool, layer) {
    layer.setVisible(bool)
  }

  setPhotos(bool, layer) {
    layer.setVisible(bool)
  }

  setSamples(bool, layer) {
    layer.setVisible(bool)
  }

  setSource(source, layers, rgbLayer) {
    rgbLayer.getSource().updateParams({
      'LAYERS': source === 'planet' ? rgbLayer.getSource().getParams()['LAYERS'] + '_planet' : rgbLayer.getSource().getParams()['LAYERS'].replace('_planet', '')
    })

    layers.map(l => l.getSource().updateParams({
      'LAYERS': source === 'planet' ? l.getSource().getParams()['LAYERS'] + '_planet' : l.getSource().getParams()['LAYERS'].replace('_planet', '')
    }))
  }

  setDate(date, layers, rgb) {
    let list = _.concat(layers, rgb);
    list.map(layer => {
      if(layer.get('name') != 'cumndvi') {
        layer.getSource().updateParams({'TIME': date});
      }
    })
  }

  setYear(year, layers) {
    layers.filter(l => l.get('name') === 'cumndvi')[0].getSource().updateParams({'DIM_YEAR': year})
  }

  setOpacity(opacity, layers) {
    layers.map(l => l.setOpacity(opacity/100))
  }

  zoomToParcel(id, parcelLayer, map) {
    let feature = parcelLayer.getSource().getFeatures().filter(feature => {
      return feature.get('pk').toString() === id.toString();
    })[0];

    if(feature) {
      map.getView().fit(feature.getGeometry().getExtent(), {duration: 250, padding: [50, 50, 50, 50]})
      return feature.getGeometry().getInteriorPoint()
    }
  }

  clipLayers(layers, parcels) {
    let polygons = parcels.getSource().getFeatures().map(feature => {
      if(feature.get('pk') === 3128) {
        $data.deleteParcel(feature.get('pk'))
      }
      let clone = feature.clone();
      return clone.getGeometry();
    })

    let multiPolygon = new MultiPolygon(polygons);
    let keys = [];

    // return []
    layers.map(layer => {
      keys.push(layer.on('prerender', (evt) => {
        let ctx = evt.context;
        let vecCtx = getVectorContext(evt);
        ctx.save();
        vecCtx.setStyle(emptyFillStyle);
        vecCtx.drawMultiPolygon(multiPolygon);
        ctx.clip();
      }))

      keys.push(layer.on('postrender', evt => {
				let ctx = evt.context;
				ctx.restore();
			}))
    })

    return keys
  }

  unclipLayers(keys) {
    keys.map(key => { unByKey(key) });

    return []
  }

  getSLDTemplate(id, layer, isPlanet) {
    let replacement = `${id}:${id}_${layer === 'ndvi' ? 'ndvi' : 'mz'}`;

    if(isPlanet) {
      replacement = `${id}:${id}_${layer === 'ndvi' ? 'ndvi_planet' : 'mz_planet'}`;
    }
    return `<?xml version="1.0" ?>
		<StyledLayerDescriptor version="1.0.0" xmlns="http://www.opengis.net/sld" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:sld="http://www.opengis.net/sld">
			<NamedLayer>
				<Name>${replacement}</Name>
				<UserStyle>
					<IsDefault>1</IsDefault>
					<Name>fertilizer</Name>
					<Title/>
					<FeatureTypeStyle>
							<Name/>
							<Rule>
									<RasterSymbolizer>
											<Geometry>
													<ogc:PropertyName>grid</ogc:PropertyName>
											</Geometry>
											<Opacity>1</Opacity>
											<ColorMap type="intervals">
													__COLOR_MAP__
													
											</ColorMap>
									</RasterSymbolizer>
							</Rule>
					</FeatureTypeStyle>
				</UserStyle>
			</NamedLayer>
			</StyledLayerDescriptor>`
  }

  getVRTSLD(id, layer, data, isPlanet) {
    let template = this.getSLDTemplate(id, layer, isPlanet);

    let colorMap = `
      <ColorMapEntry color="#7a0177" label="0.483 - 0.616" opacity="1.0" quantity="${((data[1] + 0.0001) || null) || -999999999999}"/>
      <ColorMapEntry color="#f768a1" label="&gt; 0.616" opacity="1.0" quantity="${((data[2] + 0.0001) || null) || -999999999999}"/>
      <ColorMapEntry color="#feebe2" label="&gt; 0.616" opacity="1.0" quantity="${((data[3] + 0.0001) || null) || -999999999999}"/>
    `;


    // colorMap = ``;

    // data.map((val,i) => {
    //   if(i === data.length -1) {
    //     colorMap += `<ColorMapEntry color="${VRAcolorMaps[data.length - 1][i - 1]}" label="0.483 - 0.616" opacity="1.0" quantity="${val || -999999999999}"/>`

    //     return
    //   }
    //   colorMap += `<ColorMapEntry color="${VRAcolorMaps[data.length - 1][i]}" label="0.483 - 0.616" opacity="1.0" quantity="${data[i + 1] || -999999999999}"/>`
    // })

    let final = template.replace('__COLOR_MAP__', colorMap);
    return final
  }

  getParcelThumbnail(id, stroke, fill, weight, opt_feature) {

    try { 
      let feature = opt_feature || $data.getParcelById(id);
      
      if(!feature) return null;
      // let area = getArea(new GeoJSON().readFeature(feature).getGeometry())/10000;    

      console.log(feature)

      let svgStr = geojson2svg().styles((f) => ({stroke: stroke || '#555', fill: fill || 'transparent', opacity: 1, weight: 0,})).data(feature).render();
      let blob = new Blob([svgStr], {type: 'image/svg+xml'});
      let src = window.URL.createObjectURL(blob);
      return <img style={{display: 'block', transform: 'scaleX(-1) rotate(180deg)', width: '100%', height: '100%', maxHeight: '180px'}} src={src} />
  
    } catch(e) {
      console.log(e)
      return null
    }


  }

  coordinatesInParcel(lat, lon, geojson) {
    try {
      let feature = new GeoJSON().readFeature(geojson);

      let coordinates = fromLonLat([lon, lat]);
  
      return feature.getGeometry().intersectsCoordinate(coordinates);
    } catch(e) {
      console.log(e)
      return false
    }
  

  }

  geolocationLayer = () => {
    let accuracy = new Feature();
    let position = new Feature();

    position.setStyle(new Style({
      image: new IconStyle({
        src: '/geolocation_marker.png',
      }),
    }))
    
    return new VectorLayer({
      zIndex: 8,
      name: 'geolocation',
      source: new VectorSource({
        features: [position],
      })
    })
  }

  setGLPosition(layer, coords) {
    layer.getSource().getFeatures()[0].setGeometry(coords ? new Point(coords) : null);
  }

  geojsonToFeature(geojson) {
    return new GeoJSON().readFeature(geojson);
  }
  
  setHeadingStyle = (glLayer, heading) => {
    if(heading) {
      glLayer.getSource().getFeatures()[0].setStyle(new Style({
        image: new IconStyle({
          src: '/geolocation_marker_heading.png',
        }),
      }))
    } else {
      glLayer.getSource().getFeatures()[0].setStyle(new Style({
        image: new IconStyle({
          src: '/geolocation_marker.png',
        }),
      }))
    }
  }
}

export default new $map();