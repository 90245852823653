import { useContext, useEffect, useRef, useState } from "react";
import olMap from 'ol/Map';
import { basemaps } from "../../../config/map";
import $map from "../../../services/$map";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import { AppContext } from "../../../AppContext";
import Draw from 'ol/interaction/Draw';
import {defaults as interactionDefaults} from 'ol/interaction';
import { Button, Paper } from "@mui/material";
import { unByKey } from "ol/Observable";
import { Cancel } from "@mui/icons-material";
import GeoJSON from 'ol/format/GeoJSON';


function DrawFieldMap(props) {
  const {lang} = useContext(AppContext);

  const mapElement = useRef(null);
  const parcelLayer = useRef(null);
  const drawInteraction = useRef(null);

  const listeners = useRef([]);

  const [drawStarted, setDrawStarted] = useState(null);
  
  useEffect(() => {
    parcelLayer.current =  $map.drawLayer();
    drawInteraction.current = new Draw({type: 'Polygon', source: parcelLayer.current.getSource()});
   
    listeners.current.push(
      drawInteraction.current.on('drawstart', () => {
        //SHOW CANCEL BUTTON
        parcelLayer.current.getSource().clear();
        setDrawStarted(true);
      })
    );


    listeners.current.push(
      drawInteraction.current.on('drawend', (evt) => {
        //SET FEATURE GEOM\)
       
        props.onSetGeometry(evt.feature)
      })   
    );

    let map = new olMap({
      target: mapElement.current,
      layers: [basemaps('sat')[0], parcelLayer.current],
      pixelRatio: 1,
      view: new View({
        zoom: 10,
        center: fromLonLat([20.5, 45])
      }),
      interactions: interactionDefaults({pinchRotate: false, dragRotate: false}).extend([drawInteraction.current])
    });

    return () => {
      //CANCEL
      listeners.current.map(l => unByKey(l));
    }

  }, []);

  return <Paper ref={mapElement} style={{height: '400px', position: 'relative'}}>
    {drawStarted && <Button onClick={() => {
      drawInteraction.current.abortDrawing();
      parcelLayer.current.getSource().clear();
      setDrawStarted(false);
    }} sx={{position: 'absolute', right: '10px', top: '10px', zIndex: 2}} size="small" variant="contained" startIcon={<Cancel />} color="primary">
      {lang.cancel}
    </Button> }
  </Paper>
}

export default DrawFieldMap;