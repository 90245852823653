import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Typography, useMediaQuery} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import {useTheme} from '@mui/system';
import { Add, Close, Delete, Edit, Event } from "@mui/icons-material";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import useMergeState from "../../hooks/useMergeState";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import $data from "../../../services/$data";


function EditCropSeason(props) {
  const {lang, primary, cropGroups, cropTypes, locale, onUpdateParcels} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [error, setError] = useMergeState({});

  const [loading, setLoading] = useState();

  const [state, setState] = useMergeState({
    sowing: props.data.sowing,
    harvest: props.data.harvest,
    harvesting_id: props.data.harvesting_id,
    planting_id: props.data.planting_id,
    crop_group: props.data.crop.crop_group,
    crop: props.data.crop.pk,
    name: props.data.crop.name,
    id: props.data.id,
  });

  const submit = () => {
    setLoading(true);

    const model = {
      id: state.id,
      sowing: state.sowing,
      harvest: state.harvest,
      harvesting_id: state.harvesting_id,
      planting_id: state.planting_id,
      crop: {
        pk: state.crop,
        name: cropTypes.filter(obj => obj.pk.toString() === state.crop.toString())[0].name,
        crop_group: state.crop_group
      }
    }

    console.log(model)

    $data.patchCropSeason(model)
      .then(data => {
        onUpdateParcels();
        
        setLoading(false);
        props.onClose();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);

      })

  }

  return (
    <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.edit_crop_season} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container sx={{mt: '20px'}}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid sx={{mt: '10px'}} item xs={12}>
                  <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                    <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {setState({'sowing': state.sowing})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.parcel_sowing} value={moment(state.sowing)} onChange={(newValue) => setState({'sowing': newValue.format('YYYY-MM-DD')})}
                      renderInput={(params) => (
                        <TextField fullWidth error={error.sowing} helperText={error.sowing || ''} {...params} size="small"
                          InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
                      )}
                    />
                  </LocalizationProvider>	
                </Grid>
                <Grid item xs={12}>
                  <FormControl size="small" error={error.crop_group !== undefined} fullWidth>
                    <InputLabel id="crop_group">{lang.crop_group}</InputLabel>
                    <Select  size="small" labelId="crop_group" label={lang.crop_group} value={state.crop_group || ''} onChange={(evt) => setState({'crop_group': evt.target.value, 'crop': cropTypes.filter(obj => obj.crop_group.toString() === evt.target.value.toString())[0].pk})} fullWidth>
                      {cropGroups.map((obj, key) => <MenuItem value={obj.id} key={key}>{obj.name}</MenuItem>)}
                    </Select>
                    {error.crop_group && <FormHelperText>{error.crop_group}</FormHelperText>}
                  </FormControl>			
                </Grid>	
                <Grid item xs={12}>
                  <FormControl size="small" error={error.crop_type !== undefined} fullWidth>
                    <InputLabel id="crop_type">{lang.crop_type}</InputLabel>
                    <Select size="small" labelId="crop_type" label={lang.crop_type} value={state.crop || ''} onChange={(evt) => setState({'crop': evt.target.value})} fullWidth>
                      {cropTypes.filter((obj) => obj.crop_group.toString() === state.crop_group.toString()).map((obj, key) => <MenuItem value={obj.pk} key={key}>{obj.name}</MenuItem>)}
                    </Select>
                    {error.crop_type && <FormHelperText>{error.crop_type}</FormHelperText>}
                  </FormControl>			
                </Grid>	
              </Grid>
              
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {console.log('HARVEST:', state.harvest)}
                <Grid sx={{mt: '10px'}} item xs={12}>
                  <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                    <MobileDatePicker disableCloseOnSelect={false} clearable error={false} okText={lang.ok} cancelText={lang.cancel} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.parcel_harvest} value={state.harvest ? moment(state.harvest) : ' - '} onChange={(newValue) => {setState({'harvest': newValue ? newValue.format('YYYY-MM-DD') : null})}}
                      renderInput={(params) => (
                        <TextField fullWidth  {...params} error={false} size="small"
                          InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
                      )}
                    />
                  </LocalizationProvider>	
                </Grid>
               
              </Grid>
            </Grid>
          </Grid>
          
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={submit} variant="contained" size="small">{lang.confirm}</LoadingButton>
        <LoadingButton loading={loading} onClick={props.onClose} size="small" variant="contained" color="error">{lang.cancel}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditCropSeason;