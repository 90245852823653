import { Stack } from "@mui/material";
import { blue, orange, green } from "@mui/material/colors";
import { useContext, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { AppContext } from "../../../AppContext";
import { formatData, formatOptions } from "../../../config/charts/sensor";

function Sensor(props) {
  const chartRef = useRef(null);
  const {lang} = useContext(AppContext);

  let style = props.style ? props.style : {}

  const renderContent = () => {
    return (
      <Chart 
        ref={chartRef}
        type="line" 
        data={formatData(props.data[props.selected], props.selected, blue[700])}
        options={formatOptions(blue[700], props.data[props.selected])} />		
    ) 

  
  }

  return (
    <Stack sx={{textAlign: 'center', height: '150px', ...style}}>
		  {renderContent()}	
		</Stack>
  )
}

export default Sensor;