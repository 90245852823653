import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useContext } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ArrowBack from '@mui/icons-material/ArrowBack'
import Auth from "./Auth";
import Divider from "./Divider";

import {AppContext} from '../../AppContext';
import { Link } from "react-router-dom";
import useMergeState from "../hooks/useMergeState";
import $auth from "../../services/$auth";
import Message from "../ui/Message";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import GoogleSignInButton from "../ui/StyledGoogleButton";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleRegistrationForm from "./GoogleRegistrationForm";
import countries from "../../config/countries";
import { Refresh } from "@mui/icons-material";


function Register(props) {
  const {locale, lang, resendEmail, primary, danger, onUpdateState} = useContext(AppContext);

  const [state, setState] = useMergeState({
    email: '',
    password1: '',
    password2: '',
    language: locale,
    country: 4,
    currency: 'RSD',
    user_type: 1,
    privacy: false,
    error: {},
    success: null,
    loading: false
  });

  const [firstUse, setFirstUse] = useMergeState({auth: {}, open: false})

  const {email, password1, password2, language, country, user_type, privacy, error, success, loading} = state;

  useEffect(() => {
    return () => {
      onUpdateState({resendEmail: false})
    }
  }, [])
  
  useEffect(() => { setState({language: locale}) }, [locale])

  const onChangeLanguage = (evt) => {
    setState({language: evt.target.value});
    onUpdateState({locale: evt.target.value})
  }

  const onSubmit = (evt) => {
    evt.preventDefault();
    setState({loading: true, error: {}});

    $auth.register({email, password1, password2, language, country, user_type, privacy})
      .then(data => {setState({success: lang.verification_sent, loading: false}); onUpdateState({resendEmail: true})})
      .catch(err => {setState({error: err, loading: false})})
    
  }

  const onResend = (evt) => {
    $auth.resendEmail({email})
      .then(data => setState({success: ''}))
      .catch(err => setState({error: err}))
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}});
  }

  //GOOGLE SIGN-IN

  //AUTHORIZATION SUCCESS
  const responseGoogle = (response) => {
    setState({loading: true, error: {}});
    $auth.loginGoogle({ code: response.code })
      .then(response => {  

        if(!response.user.first_use) {
          authorizeUser(response.user, {...response})
        } else {
          setFirstUse({auth: {...response}, open: true});
        }
        
      })
      .catch(err => {
        setState({loading: false, error: err})
      })
  }

  //AUTHORIZATION ERROR
  const errorGoogle = (err) => {
    console.log(err);
  }

  //GOOGLE SIGN-IN HOOK
  const signIn = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: errorGoogle,
    select_account: true,
    flow: 'auth-code',
    scope: 'email openid profile'
  })

  //SIGN-IN USER TO CROPLAB - CALLBACK
  const authorizeUser = (newUser, model = {...firstUse.auth}) => {
    let data = $auth.authorizeUser({...model, user: {...newUser}});
    setState({loading: false});
    onUpdateState({
      user: data.user,
      locale: data.user.language,
      loggedIn: true
    });
  }


  return (
    <Auth>
      <Box style={{padding: '5px', textAlign: 'center'}}>
        <Typography variant="h4" style={{textAlign: 'center', margin: '10px 0'}}>{lang.register}</Typography>
        <Divider />
        <Link to="/login" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none'}}><ArrowBack />&nbsp;{lang.go_back}&nbsp;</Link>
      </Box>

      <Box autoComplete="off" style={{padding: '5px'}} component='form' sx={{'& .MuiTextField-root, & .MuiButton-root, & .MuiFormControl-root': {mb: '7px', mt: '7px' }}} onSubmit={onSubmit}>
        <div>
          <TextField autoComplete="off" size="small" error={error.email !== undefined || error.non_field_errors !== undefined} helperText={error.email} fullWidth label={lang.email} type='email' value={email} onChange={(evt) => setState({email: evt.target.value})} />
        </div>

        <div>
          <TextField autoComplete="new-password" size="small" error={error.password1 !== undefined || error.non_field_errors !== undefined} helperText={error.password1} fullWidth label={lang.password} type='password' value={password1} onChange={evt => setState({password1: evt.target.value})} />
        </div>

        <div>
          <TextField autoComplete="new-password" size="small" error={error.password2 !== undefined || error.non_field_errors !== undefined} helperText={error.password2} fullWidth label={lang.repeat} type='password' value={password2} onChange={evt => setState({password2: evt.target.value})} />
        </div>

        <div>
          <FormControl size="small" error={error.language !== undefined || error.non_field_errors !== undefined} fullWidth>
            <InputLabel id="language">{lang.language}</InputLabel>
            <Select size="small" labelId="language" label={lang.language} value={locale} onChange={onChangeLanguage} fullWidth>
              <MenuItem value="sr">{lang.serbian}</MenuItem>
              <MenuItem value="en">{lang.english}</MenuItem>
            </Select>
            {error.language && <FormHelperText>{error.language}</FormHelperText>}
          </FormControl>
        </div>

        <div>
          <FormControl size="small" error={error.country !== undefined || error.non_field_errors !== undefined} fullWidth>
            <InputLabel id="country">{lang.country}</InputLabel>
            <Select size="small" labelId="country" label={lang.country} value={country} onChange={(evt) => setState({country: evt.target.value})} fullWidth>
              {countries(lang).map((obj, key) => {
                return <MenuItem key={key} value={obj.value}>{obj.label}</MenuItem>
              })}
            </Select>
            {error.country && <FormHelperText>{error.country}</FormHelperText>}
          </FormControl>
        </div>
        
        <div>
          <FormControl size="small" error={error.currency !== undefined || error.non_field_errors !== undefined} fullWidth>
            <InputLabel id="currency">{lang.currency}</InputLabel>
            <Select size="small" labelId="currency" label={lang.currency} value={state.currency} onChange={(evt) => setState({currency: evt.target.value})} fullWidth>
              <MenuItem value={'GBP'}>{lang.currency_uk}</MenuItem>
              <MenuItem value={'EUR'}>{lang.currency_eur}</MenuItem>
              <MenuItem value={'RSD'}>{lang.currency_rs}</MenuItem>
              <MenuItem value={'PLN'}>{lang.currency_pl}</MenuItem>
              <MenuItem value={'USD'}>{lang.currency_usa}</MenuItem>
            </Select>
            {error.currency && <FormHelperText>{error.currency}</FormHelperText>}
          </FormControl>
        </div>

        <Stack direction="row" justifyContent={'flex-start'} alignItems="center">
          <Checkbox color={error.privacy ? 'error' : 'primary'} sx={{'& path': {fill: error.hasOwnProperty('privacy') ? (error.privacy ? danger : primary) : 'rgba(0, 0, 0, .87)'}}} checked={state.privacy} onChange={(evt, checked) => setState({privacy: checked, error: {...state.error, privacy: checked ? false : true}})} />
          <label style={{fontSize: '11px', color: error.privacy ? danger : '#555'}}>{lang.privacy_consent} <span onClick={() => onUpdateState({privacyPolicy: true})} className="text-link">{lang.privacy_consent2}</span> {lang.and} <span onClick={() => onUpdateState({terms: true})} className="text-link">{lang.privacy_terms}</span></label>
        </Stack>

        <div>
          <LoadingButton loading={loading} fullWidth variant="contained" type="submit">{lang.submit_reg}</LoadingButton>
          {resendEmail && <a onClick={onResend} style={{fontSize: '12px', display: 'flex', textAlign: 'right', cursor: 'pointer', justifyContent: 'flex-end', alignItems: 'center'}} className="text-link"><Refresh fontSize="small" />&nbsp; Resend email</a>}
          <br/><br/>
          
         {process.env.REACT_APP_BRANCH === 'dev' && <GoogleSignInButton onClick={signIn} text={lang.google_sign_up} />}

        </div>
    
      </Box>

      <GoogleRegistrationForm auth={firstUse.auth} open={firstUse.open} onClose={(evt, reason) => {if(reason !== 'backdropClick') setFirstUse({open: false})}} onSubmit={(newUser) => {setFirstUse({open:false}); authorizeUser(newUser) }} />

      <Message type="success" open={success ? true : false} message={success} onClose={() => setState({success: null})} />
      <Message type="error" open={error.non_field_errors !== undefined} message={error.non_field_errors} onClose={() => onCloseMessage()} />
    </Auth>
  )
}

export default Register;