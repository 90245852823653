import { Badge, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ArrowBack, Circle, Close, Delete } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { Link } from "react-router-dom";

function TermsAndConditionsENRoute(props) {
  const { lang, primary } = useContext(AppContext);

  return (
    <Container sx={{height: 'calc(100% - 60px)', overflow: 'auto'}} style={{ marginTop: '30px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link to="/"><Button size="small" variant="contained" startIcon={<ArrowBack />}>{lang.go_back}</Button></Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
            <Grid sx={{textAlign: 'center', marginBottom: '10px'}} item xs={12}>
              <a href="https://gilab.rs" target="_blank"><img src="/gilab-logo.svg" width="240px" /></a>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Terms of Use for CropLab</Typography>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={1} /> &nbsp;&nbsp;Purpose, information about CropLab</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>
                (1) Company GILAB DOO BELGRADE (hereinafter referred to as "GILAB", "we" or "our") enables the "CropLab" platform through the website <a href="https://app.croplab.info/" target="_blank">https://app.croplab.info/</a> (hereinafter referred to as "CropLab"), where duly registered users can access the services and content available on CropLab and use additional services and software occasionally provided through CropLab (web or mobile applications). Further information about the services and content available on CropLab can be found in <strong><Chip component={'span'} variant="filled" size="small" color="primary" label={7} /></strong>.
              </p>
              <p>
                (2) These Terms of Use also apply to the use of the CropLab application and all services provided by the application. Unless expressly stated otherwise, any reference to "CropLab" in these Terms of Use also refers to the CropLab Application and the content, services, tools and software features made available in the Application and for the web and mobile applications. 
              </p>
              <p>
                (3) These Terms of Use govern the provision of access to CropLab by GILAB as well as your use of the same as a duly registered user.              
              </p>
              <p>
                (4) Our contact details are:              
              </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="GILAB DOO BELGRADE" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="94 Dragoslava Srejovića Street " />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={<>E-mail: <a href="mailto:team@gilab.rs">team@gilab.rs</a></>} />
                </ListItem>
               
              </List>
              <p>
              Additional information about GILAB can be found here <a href="https://gilab.rs/" target={"_blank"}>https://gilab.rs/</a> 
              </p>
              <p>
               (5) <strong>PLEASE READ THESE TERMS OF USE CAREFULLY. BY REGISTERING TO USE CropLab, A LEGALLY BINDING AGREEMENT IS CREATED BETWEEN YOU AND THE GILAB COMPANY.</strong>            
              </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={2} /> &nbsp;&nbsp;Changes to the Terms of Use</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>
              GILAB reserves the right to change these Terms of Use at any time. The GILAB company will inform you about these changes at least 30 days before they come into force. If you do not agree with the changes, you can stop using CropLab and request the deletion of your registered account. <strong>YOU WILL BE DEEMED TO AGREE TO THE CHANGES IF YOU CONTINUE TO USE CropLab AFTER THE EXPIRY OF THE 30-DAY PERIOD NOTIFICATION. </strong>
              </p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={3} /> &nbsp;&nbsp;Registration</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Using CropLab requires you to register as a user and accept these Terms of Use. After registration, a license agreement is concluded between GILAB and the user. There is no right to conclude a contract. GILAB has the right to reject user registrations without giving reasons.</p>

              <p>(2) You can register only if you are of legal age and have full legal capacity. Minors are not allowed to register.</p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={4} /> &nbsp;&nbsp;Your registration with CropLab </Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Your registration with CropLab is free. GILAB reserves the right to offer certain content or CropLab for a fee. Details of applicable fees and other terms will be available before you access such content.</p>

              <p>(2) Your registration is related to you as an individual. Other persons may not use your account, unless the services expressly state and allow the use or connection of other accounts.</p>

              <p>(3) You are obliged to provide complete and accurate contact details and additional information at the request of GILAB during the registration process.</p>
              <p>(4) If the GILAB company accepts your registration, it will activate your account and inform you about it by e-mail. Upon receipt of the email, you are entitled to use CropLab under these Terms of Use. In order to do this, you will need to confirm the activation of your account by clicking on the link contained in the email.  </p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={5} /> &nbsp;&nbsp;Liability for login data </Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) During the registration process, you will be asked to provide a username and password. After activating your account and your confirmation in accordance with § 4 (4) you will be able to log in to CropLab. It is your responsibility to ensure that your username does not infringe any rights of third parties, in particular certain name or trademark rights, and is not contrary to good morals. </p>

              <p>(2) You are obliged to protect the login data for your account from unauthorized access by third parties and to ensure that the login data is not known to third parties. <strong>ALL ACTIONS AND LEGAL TRANSACTIONS UNDERTAKEN THROUGH YOUR ACCOUNT WILL BE ATTRIBUTABLE TO YOU UNLESS (I) IT IS OBSERVABLE TO GILAB THAT SOMEONE OTHER THAN THE REGISTERED USER ACTED OR (II) YOU CAN PROVE THAT THE MISUSE OF LOGIN DATA WAS THROUGH NO FAULT OF YOUR OWN. YOU MUST NOTIFY GILAB IMMEDIATELY IF YOU THINK AN UNAUTHORIZED THIRD PARTY HAS OBTAINED OR IS GOING TO ACCESS YOUR LOGIN DATA. </strong></p>

              <p>(3) You are required to update your data (including your contact details). If your data changes during use, you must immediately correct your data in your personal settings on CropLab. If you are unable to do so, please notify us of the changed details by e-mail or fax without delay.</p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={6} /> &nbsp;&nbsp;Termination of use </Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) You can terminate the license agreement at any time by requesting the deletion of your account on the CropLab platform or through a specific function in the CropLab application. </p>

              <p>(2)  When the termination of the contract takes effect, the contractual relationship ends and you are no longer allowed to use your account. The GILAB Company reserves the right to block your username and password as soon as the termination becomes effective. </p>

              <p>(3) <strong>THE GILAB COMPANY HAS THE RIGHT TO IRREVOCABLY DELETE ALL DATA GENERATED IN THE CONTEXT OF YOUR USE, 30 DAYS AFTER THE TERMINATION EFFECTIVE DATE AND AFTER ANY LEGAL RETENTION PERIOD EXPIRES.</strong> To the extent that GILAB is required by data protection laws to delete your personal data, it will ensure that your personal data is deleted in accordance with our data protection regulations. </p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={7} /> &nbsp;&nbsp;Offered services and availability of services</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) Through CropLab, the GILAB company offers a variety of content and other services that can be used for a limited time. This may, for example, include access to data, articles, video and audio recordings, information and other content (hereinafter "Content").Your access to and use of the services offered by CropLab are subject to the provisions of these Terms of Use as well as any additional terms of use that may apply to certain services. The scope of available services and functionality is limited to those available on CropLab from time to time, which GILAB may change, remove or make available at its sole discretion.  </p>

              <p>(2) The services offered may include the services of third parties to which the GILAB company only provides access. The use of such services, which in each individual case are identified as third-party services, may be subject to different or additional terms of use, which GILAB will notify you of in each individual case.</p>

              <p>(3) The GILAB company makes commercially reasonable efforts to ensure the uninterrupted availability of the offered services. However, there may be temporary limitations or interruptions due to technical failures (such as power outages, hardware or software errors, or technical problems with data cables). <strong>GILAB DOES NOT WARRANT THAT THE SERVICES OFFERED OR RELATED CONTENT AVAILABLE WILL BE UNINTERRUPTED OR ERROR-FREE. </strong></p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={8} /> &nbsp;&nbsp;Use of CropLab, availability and responsibility for calculation results</Typography>
						</Grid>

            <Grid item xs={12}>

              <p>(1) The GILAB company provides you with various free CropLab functionalities. No results or recommendations obtained using the CropLab application constitute legally binding statements on behalf of GILAB. The user bears the risk and responsibility for the use of the CropLab application (and reliance on any results or recommendations). The following CropLab tools are available or planned:</p>

              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Crop monitoring" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Management zones" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Stages of crop development" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Variable treatment" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Comparing" />
                </ListItem>

                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Weather forecast" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Field book" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Warnings" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Sensors" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Soil samples" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Images that contain data about the location from which they were collected" />
                </ListItem>
               
              </List>

              <p>(2) Sometimes it is necessary for the CropLab application to work with certain assumptions and simplifications because it is not possible to take into account all the circumstances of each individual case.</p>

              <p>(3) The results of the CropLab application are calculated completely automatically and are provided to the user without prior verification by the GILAB company. The GILAB company therefore cannot rule out the possibility that in rare cases incorrect results may be obtained, e.g. due to incorrect input data or undefined model states. <strong>THEREFORE, THE RESULTS SHOULD BE CONSIDERED AN OFFER OF ADDITIONAL INFORMATION, WITH NO WARRANTIES AS TO THEIR ACCURACY. GILAB'S LIABILITY IN THIS CONTEXT IS EXCLUDED IN ACCORDANCE WITH <Chip component={'span'} variant="filled" size="small" color="primary" label={15} /></strong>.</p>

              <p>(4) <strong><Chip component={'span'} variant="filled" size="small" color="primary" label={7} />(3)&nbsp; APPLIES IN ACCORDANCE WITH THE AVAILABILITY OF</strong> the CropLab application</p>

              <p>(5) In some cases, the use of special services and tools may require consent to certain conditions of use. The user will be specifically informed about these terms of use. All current terms of use are available on the CropLab home page. <a href="https://croplab.info" target="_blank">https://croplab.info</a></p>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={9} /> &nbsp;&nbsp;Changes to services and CropLab application</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>
              The GILAB company has the right to change the services available on CropLab that are offered free of charge at any time, to offer new services or CropLab application for free or for a fee, or to stop providing free services. 
              </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={10} /> &nbsp;&nbsp;Content protection, responsibility for third-party content</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) Content in the CropLab application that is available on CropLab is protected by copyright or other proprietary rights and is the property of the GILAB company or third parties that provide the given Content. You may use the Content and the CropLab App only in accordance with these Terms of Use, additional specific terms of use for the Services, if applicable, and to the extent prescribed by CropLab.</p>

              <p>(2) The content available on CropLab is partly provided by the GILAB company, and partly by third parties. Content provided by third parties is hereinafter referred to as "Third Party Content". <strong>THE GILAB COMPANY DOES NOT VERIFY THE COMPLETENESS, ACCURACY AND LEGALITY OF THIRD-PARTY CONTENT. THE GILAB COMPANY THEREFORE ASSUMES NO RESPONSIBILITY FOR THE COMPLETENESS, ACCURACY AND LEGALITY OF THIRD-PARTY CONTENT.</strong> This also applies to the quality of Third-Party Content and its fitness for a particular purpose, particularly as regards Third-Party Content on linked external websites. Content not expressly identified as Third-Party Content is provided by the GILAB Company.   </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={11} /> &nbsp;&nbsp;Technical resources, monitoring of user activities </Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) You are responsible for providing adequate technical resources for the use of the CropLab application. GILAB does not owe you any advice in this regard.</p>

              <p>(2) The GILAB company hereby informs you that your user activities may be stored and used, to the extent permitted by law, to improve the user experience and further develop CropLab. This may include recording and evaluating IP connection data to the extent permitted by law if there are grounds to suspect that a violation of these Terms of Use has occurred and/or if there are grounds to suspect any other illegal act or criminal offense. </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={12} /> &nbsp;&nbsp;The right to use the CropLab application</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) You may access CropLab only in accordance with these Terms of Use, additional specific terms of use for the Services, if applicable, and within the scope prescribed on CropLab. No further use is allowed unless the GILAB company has agreed in written or text form.</p>

              <p>(2) You have the right to download or print the Content only if CropLab contains functions that enable such downloading or printing (e.g. using a download button).  </p>

              <p>
              You will be granted an unlimited and non-exclusive right to use for your own personal, non-commercial purposes the Content you download or print in accordance with these Terms of Use. The original rights holder (the GILAB company or appropriate third party) retains all other rights to the Content.
              </p>

              <p>(3) Regarding the use of the CropLab application, the following also applies: </p>

              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The CropLab application may not be copied or reproduced in whole or in part;" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="may not be changed, edited or adapted in whole or in part;" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="copyright notices attached to or contained in the CropLab application may not be removed or altered; " />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="no reverse engineering of the application is allowed." />
                </ListItem>
               
              </List>

              <p>(4) 
              The CropLab application and content derived from the CropLab application are protected by copyright and/or other intellectual property rights. You hereby acknowledge that your use of the Application does not grant you any rights to the software, trademarks or content associated with the CropLab application and that you may use it only in full compliance with these Terms of Use. Mandatory legal rights remain unchanged. 
              </p>

						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={13} /> &nbsp;&nbsp;Blocking of the account</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) The GILAB company can temporarily or permanently block your CropLab account if:</p>

               <p style={{marginLeft: '10px'}}>(a) there is a termination of the contractual relationship created by these Terms of Use. In this case, your account will be blocked as soon as the termination takes effect. </p>
              <p style={{marginLeft: '10px'}}>(b) there are specific indications that you are in violation of these Terms of Use; </p>
              <p style={{marginLeft: '10px'}}>(c) the GILAB Company otherwise has a legitimate interest in blocking your account, for example, if you violate or have violated applicable law when using CropLab or if there are technical reasons that require your account to be blocked.</p>


              <p>(2) If your account is temporarily or permanently blocked, the GILAB company blocks your right of access. A permanently blocked access right cannot be reinstated. Permanently blocked persons are excluded from using CropLab and cannot re-register on CropLab. </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={14} /> &nbsp;&nbsp;Data protection</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>Quality standards of the GILAB company require responsible handling of users' personal data. When processing data, the GILAB company complies with all legal provisions. The data protection policy available on GILAB's website contains an overview of the type of data that is collected, how data is used and transferred and how you can request disclosure of data provided by GILAB. </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={15} /> &nbsp;&nbsp;DISCLAIMER AND LIMITATION OF LIABILITY</Typography>
						</Grid>

            <Grid item xs={12}>
               <p>(1) EXCEPT TO THE EXTENT EXPRESSLY STATED IN THESE TERMS OF USE, GILAB MAKES NO WARRANTIES, REPRESENTATIONS, PROMISES OR OTHER OBLIGATIONS TO YOU REGARDING: </p>

               <p style={{marginLeft: '10px'}}>(a) THE AVAILABILITY, FUNCTIONALITY, PERFORMANCE, TRANSMISSION SPEEDS, CONTENT, DELAY, ACCURACY AND/OR SECURITY OF THE CropLab-Application OR</p>
              <p style={{marginLeft: '10px'}}>(b) THE QUALITY AND/OR RELIABILITY OF ANY CONTENT, RESULTS OR RECOMMENDATIONS GENERATED AND/OR VIEWED THROUGH THE CropLab application. </p>
              <p>(2) ALL OTHER WARRANTIES, CONDITIONS, REPRESENTATIONS, PROMISES AND/OR OBLIGATIONS (WHETHER WRITTEN OR ORAL, EXPRESSED OR IMPLIED BY THE STATUTE, COMMON LAW, CUSTOM, TRADE USAGE, BUSINESS OR OTHERWISE, INCLUDING THOSE RELATED TO SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, ACCURACY, ADEQUACY, COMPLETENESS OR TIMELINESS) ARE HEREBY EXCLUDED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. </p>

              <p>(3) NOTHING IN THESE TERMS OF USE SHALL LIMIT OR EXCLUDE:</p>
              <p style={{marginLeft: '10px'}}>(a) YOUR LEGAL RIGHTS OR</p>
              <p style={{marginLeft: '10px'}}>(b) YOUR OR GILAB'S LIABILITY FOR ANY CLAIMS: (I) RELATED TO DEATH OR PERSONAL INJURY CAUSED BY THE NEGLIGENCE OF THAT PARTY (OR ITS PERSONNEL); (II) ARISING FROM ANY FRAUD (INCLUDING MISREPRESENTATION) COMMITTED BY THAT PARTY AND (III) FOR WHICH LIABILITY CANNOT BE OTHERWISE LIMITED OR EXCLUDED UNDER APPLICABLE LAW.</p>

              <p>(4) IN ACCORDANCE WITH <Chip component={'span'} variant="filled" size="small" color="primary" label={15} />(3), GILAB HEREBY EXCLUDES ALL LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND ARISING IN CONNECTION WITH THESE TERMS OF USE AND CropLab, WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), LAW OR IN ANY OTHER WAY. IN PARTICULAR, THE GILAB COMPANY EXCLUDES ALL LIABILITY: </p>

              <p style={{marginLeft: '10px'}}>(a) RELATED TO: (I) USE OF CropLab, CropLab App AND OTHER CONTENT; (II) UNAVAILABILITY OR INACCESSION OF CropLab, CropLab application AND CONTENT; (III) ANY FAILURE OF THIRD-PARTY NETWORK AND/OR INFRASTRUCTURE USED IN CONNECTION WITH CropLab; (IV) ANY FAULTY, INACCURATE, DAMAGED, INCOMPLETE, NON- DELIVERED, MISDIRECTED OR MISLABELLED CONTENT OR OTHER DATA; (V) ANY VIRUSES OR HARMFUL COMPONENTS OR LOSS OF OR DAMAGE TO YOUR SYSTEMS AND/OR (VI) ANY UNAUTHORIZED ACCESS AND/OR PENETRATION OF CropLab AND/OR </p>
              <p style={{marginLeft: '10px'}}>(b) FOR: (I) LOSS OF PROFIT OR INCOME; (II) LOSS OF ANTICIPATED SAVINGS; (III) LOSS OF CONTRACT, BUSINESS OR OPPORTUNITY; (IV) LOSS, DESTRUCTION OR CORRUPTION OF DATA; (V) LOSS OF REPUTATION; (VI) INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGE OF ANY NATURE, INCLUDING ALL LOSSES DESCRIBED IN (I) THROUGH (V) THAT MAY BE DEEMED INDIRECT OR CONSEQUENTIAL, IN EACH OF (I) THROUGH (VI) NOTWITHSTANDING WHETHER OR NOT THEY WERE REASONABLY FORESEEABLE, REASONABLY CONSIDERED, ACTUALLY ANTICIPATED OR ACTUALLY CONSIDERED BY YOU OR GILAB AT THE TIME YOU ACCEPTED THESE TERMS OF USE. </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={16} /> &nbsp;&nbsp;Notices</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>Unless otherwise expressly agreed in these Terms of Use, all notices in the context of the use of CropLab will be sent to you in writing at a physical address or by e-mail. </p>
              <p>The e-mail address of the GILAB company is:  <a href="mailto:team@gilab.rs">team@gilab.rs</a>. The postal address of GILAB is: 94 Dragoslava Srejovića, Belgrade, Serbia. These contact details are subject to change. In that case, the GILAB company will inform you about those changes. </p>
              <p>Your email address and postal address is the one you entered when you registered to use CropLab. If there is a change in your contact details, please notify us of such changes OR update your profile on CropLab.</p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={17} /> &nbsp;&nbsp;Completeness of the contract and severability of provisions</Typography>
						</Grid>

            <Grid item xs={12}>
              <p>These Terms of Use constitute the entire agreement between you and GILAB with respect to your access to and use of CropLab. </p>
              <p>If a provision of these Terms of Use is or becomes invalid, illegal or unenforceable, the legal validity and enforceability of the remaining provisions shall remain unaffected. An invalid, illegal or unenforceable provision will be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such a change is not possible, the relevant provision will be considered deleted. </p>

						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={18} /> &nbsp;&nbsp;Applicable law </Typography>
						</Grid>

            <Grid item xs={12}>
              <p>These Terms of Use and any disputes or claims arising out of or in connection with these Terms of Use and/or with CropLab (including non-contractual disputes or claims) are governed by the laws of the Republic of Serbia on contracts and the provision of services. </p>


						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={19} /> &nbsp;&nbsp;Disputes and Jurisdiction</Typography>
						</Grid>


            <Grid item xs={12}>
              <p>The exclusive jurisdiction for all disputes arising from these Terms of Use shall be to the extent permitted by law. </p>

              <p>No one other than you or us has the right to enforce any of these Terms of Use under the Contracts (Rights of Third Parties) Act 1998. </p>
						</Grid>

            <Grid item xs={12}>
              <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}><Chip component={'span'} variant="filled" size="small" color="primary" label={20} /> &nbsp;&nbsp;Waiver</Typography>
						</Grid>


            <Grid item xs={12}>
              <p>A waiver of any right, power, privilege or remedy (whether under these Terms of Use, common law, equity, statute or otherwise) shall be effective only if made in writing and shall not be deemed a waiver of any subsequent breach or defaults. </p>


						</Grid>

            
					</Grid>
    </Container>
  )
}

export default TermsAndConditionsENRoute;