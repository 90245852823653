import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Paper, Stack, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";
import $cookies from "../../services/$cookies";
import { Link } from "react-router-dom";
import acLogo from '../../images/agricapture-logo.webp';
import $data from "../../services/$data";
import $auth from "../../services/$auth";
import useMergeState from "../hooks/useMergeState";
import _ from "lodash";

function AgricaptureRequests(props) {
  const {lang, user, onUpdateState} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [requests, setRequests] = useMergeState({data: [], loading: false, success: null, error: null});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRequests({loading: true, error: null, success: null})
    $data.getACRequests()
      .then(data => {
        setRequests({data, loading: false})
        if(data.length > 0) {
          onUpdateState({hasACRequests: true})
        }
      })
      .catch(err => {
        setRequests({loading: false, error: null})
      })
  }, [])

  const confirmRequest = (id) => {
    setLoading(true);
    $data.confirmACRequest({status: true}, id)
      .then(() => {
        setLoading(false)
        setRequests({loading: true, error: null, success: null});

        $auth.getUser()
          .then(user => onUpdateState({user}))
          .catch(err => {})

        $data.getACRequests()
          .then(data => {
            setRequests({data, loading: false})
          })
          .catch(err => {
            setRequests({loading: false, error: null})
          })
      })
      .catch(err => {
        setLoading(false);
        setRequests({loading: false, error: err.details || 'Error occured'})
      })
  }

  const cancelRequest = (id) => {
    setLoading(true);
    $data.cancelACRequest({status: false}, id)
      .then(() => {
        setLoading(false)
        setRequests({loading: true, error: null, success: null});

        $auth.getUser()
          .then(user => onUpdateState({user}))
          .catch(err => {})

        $data.getACRequests()
          .then(data => {
            setRequests({data, loading: false})
            if(data.length === 0) {
              onUpdateState({hasACRequests: false, acRequests: false})
            }
          })
          .catch(err => {
            setRequests({loading: false, error: null})
          })
      })
      .catch(err => {
        setLoading(false);
        setRequests({loading: false, error: err.details || 'Error occured'})
      })
  }

  const renderRequests = () => {
    if(requests.loading) {
      return (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )
    }

    if(requests.error) {
      return (
        <Grid item xs={12}>
          <p className="text-danger">{requests.error}</p>
        </Grid>
      )
    }

    if(requests.data.length === 0) {
      return null
    }

    return requests.data.map((obj, key) => {
      return (
        <Grid key={key} item xs={12}>
          <Stack component={Paper} elevation={2} sx={{position: 'relative', padding: '7px 10px'}} direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <strong className="text-primary">{obj.projectant_data?.name ? obj.projectant_data?.name : obj.projectant_data.email}</strong> {!obj.status && <span style={{marginLeft: '10px'}}>requests permission to use your farm for carbon credits project</span>}
            </Stack>
            <div>
              {!obj.status && (
                <>
                  <LoadingButton loading={loading} onClick={() => confirmRequest(obj.id)} size="small" color="warning">{lang.confirm}</LoadingButton>              
                </>
              )}
              {obj.status && (
                <>
                  <Chip size="small" label={lang.ac_request_accepted} variant="filled" color="success" />
                  {/* <LoadingButton loading={loading} onClick={() => cancelRequest(obj.id)} size="small" color="error">{lang.cancel}</LoadingButton>               */}
                </>
              )}
            </div>

          </Stack>
        </Grid>
      )
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose ? props.onClose : () => {}} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.ac_request_title}<Close onClick={props.onClose ? props.onClose : () => {}} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          {requests.data.length > 0 && <Grid sx={{mb: '20px'}} container spacing={1}>
            <Grid item xs={12}>
              {requests.data.filter(obj => !obj.status).length > 0 && <>{lang.ac_request_message} <br/><br/></>}
              {lang.ac_request_status}:
            </Grid>
            {renderRequests()}
          </Grid>}
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AgricaptureRequests;