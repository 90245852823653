import { Check, Forward, LocationOn } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { toLonLat } from "ol/proj";
import { useContext, useState } from "react";
import $map from "../../services/$map";
import Message from "../ui/Message";
import { AppContext } from "../../AppContext";
import { e } from "mathjs";

function SelectedValue({ data }) {
  const { date, source, layer } = useContext(AppContext);

  console.log(data);

  let a = null;

  let b = null;


  try {
    console.log(layer)
    if(layer === 'productivity' || layer === 'mzones') {
      a = data[0][source][layer][0];
    } else {
      a = data[0][source][layer].filter(obj => obj.x === date)[0].y;
    }

   
  } catch (e) {
  }
  try {

    if(layer === 'productivity' || layer === 'mzones') {
      b = data[1][source][layer][0];
    } else {
      b = data[1][source][layer].filter(obj => obj.x === date)[0].y;
    }
  } catch (e) {
  }

  const formatValue = (val) => {
    try {
      if(layer === 'productivity' || layer === 'mzones') {
        if(val === -9999) return ' - ';

        return `${val}%`
      }

      return val.toFixed(3)

      return 
    } catch(e) {
      return ' - '
    }
    
  }




  return (
    <Stack direction="row" justifyContent="space-between" sx={{ zIndex: 1, position: 'absolute', left: '7px', top: '95px', maxWidth: '400px', width: 'calc(100% - 56px)' }}>
      {date && data && <Button
        sx={{ fontWeight: 'bold' }} variant="contained" color="error" size="small" startIcon={<Forward />}>{formatValue(a)}</Button>}
      {date && data && data?.length > 1 && <Button  sx={{ fontWeight: 'bold' }} variant="contained" color="warning" size="small" startIcon={<Forward />}>{formatValue(b)}</Button>}

    </Stack>
  )

}

export default SelectedValue;