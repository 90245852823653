import { LoadingButton } from "@mui/lab";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";

function AdditionalForm(props) {
  const context = useContext(AppContext);
  const {lang, primary, onUpdateOperators} = context;

  let initial = props.model ? {...props.model} : {};

  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);

  const [model, setModel] = useMergeState({...initial});
  
  const onSubmitMachine = () => {
    setLoading(true);
    setError({});
    if(props.type === 'add') {
      $data.postMachine(model)
        .then(data => {
          setLoading(false);       
          props.onClose();
          props.onUpdateMachines()
          props.onSuccess(lang.success_create_machine)
          
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      
      return;
    }

    if(props.type === 'edit') {
      $data.putMachine(model)
        .then(data => {
          setLoading(false);
          props.onClose();
          props.onUpdateMachines()
          props.onSuccess(lang.success_edit_machine)
          
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      return;
    }

    if(props.type === 'delete') {
      $data.deleteMachine(model)
        .then(data => {
          setLoading(false);
          props.onClose();
          props.onUpdateMachines()

          props.onSuccess(lang.success_delete_machine);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      return;
    }
  }

  const onSubmitConMachine = () => {
    setLoading(true);
    setError({});
    if(props.type === 'add') {
      $data.postConnectedMachine(model)
        .then(data => {
          setLoading(false);       
          props.onClose();
          props.onUpdateConnectedMachines()
          props.onSuccess(lang.success_create_con_machine)
          
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      
      return;
    }

    if(props.type === 'edit') {
      $data.putConnectedMachine(model)
        .then(data => {
          setLoading(false);
          props.onClose();
          props.onUpdateConnectedMachines()
          props.onSuccess(lang.success_edit_con_machine)
          
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      return;
    }

    if(props.type === 'delete') {
      $data.deleteConnecedMachine(model)
        .then(data => {
          setLoading(false);
          props.onClose();
          props.onUpdateConnectedMachines()
          props.onSuccess(lang.success_delete_con_machine);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
      return;
    }
  }
  

  return (
    <Grid sx={{'& .employee-input': {mb: '14px'}}} style={{padding: '10px'}} component={'form'} onSubmit={(evt) => {
        evt.preventDefault(); 
        if(props.role === 'machine') {onSubmitMachine()} 
        else {onSubmitConMachine()}}}>
      <Grid className="employee-input" item xs={12}>
        {props.type === 'add' && <h3 style={{color: primary}}>{props.role === 'machine' ? lang.fld_add_machine : lang.fld_add_con_machine}</h3>}
        {props.type === 'edit' && <h3 style={{color: primary}}>{props.role === 'machine' ? lang.fld_edit_machine : lang.fld_edit_con_machine}</h3>}
        {props.type === 'delete' && <h3 style={{color: primary}}>{props.role === 'machine' ? lang.fld_delete_machine : lang.fld_delete_con_machine}</h3>}
      </Grid>

      <Grid className="employee-input" item xs={12}>
        <TextField focused={props.type === 'delete'} size="small" inputProps={{readOnly: props.type === 'delete'}} error={error.name !== undefined || error.non_field_errors !== undefined} helperText={error.name} fullWidth label={lang.fld_machine} type='text' value={model.name || ''} onChange={(evt) => setModel({name: evt.target.value})} />      
      </Grid>
     

      <Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} className="employee-input" item xs={12}>
        <LoadingButton size="small" loading={loading} type="submit" variant="contained">{props.type !== 'delete' ? lang.confirm : lang.delete}</LoadingButton>
        <LoadingButton size="small" loading={loading} onClick={props.onClose} type="button" color="error" variant="contained">{lang.close}</LoadingButton>
      </Grid>

      <Message type="error" open={error.non_field_errors !== undefined} message={error.non_field_errors} onClose={() => setError({})} />
    </Grid>
  )
}

export default AdditionalForm;