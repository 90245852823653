import { ArrowBack, AttachFile, Close, Delete, Description, Download, Edit, Event, FileDownload, FilePresent, Refresh, Upload } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import Message from "../ui/Message";
import Divider from "../auth/Divider";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";
import _ from "lodash";
import { read, utils } from 'xlsx'
import SoilMappingModal from "./SoilMappingModal";
import SoilMappingPreview from "./SoilMappingPreview";
import ConfirmDialog from "../ui/ConfirmDialog";
import axios from "axios";
import moment from "moment";

function SoilSamples(props) {
  const { lang, samplesData, onUpdateSamples, primary } = useContext(AppContext);
  const filesRef = useRef(null);
  const downloadTemplateRef = useRef(null);
  const downloadTemplateRSRef = useRef(null);
  const downloadTemplateRefP = useRef(null);
  const downloadTemplateRSRefP = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const [state, setState] = useMergeState({ samples: [], count: 0, rowsPerPage: 10, page: 0, typeError: false, success: null });
  const [filter, setFilter] = useMergeState({ name: '', tags: [] })

  const [table, setTable] = useState(null);

  const [selected, setSelected] = useState([])

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [mapping, setMapping] = useState(false);
  const [preview, setPreview] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      if(filesRef.current) {
        filesRef.current.value = '';
      }
      setState({page: 0});
      setSelected([]);


    }
  }, [props.open]);

  const downloadTemplate =  () => {
    axios.get('https://dev.croplab.gilab.rs/backend/api/sensor/soc_template/?lang=en', {responseType: 'blob'})
      .then(result => {

        downloadTemplateRef.current.href = window.URL.createObjectURL(result.data);
        downloadTemplateRef.current.click();
        return;
      })
      .catch(err => {
        return
      })

      
  }

  const downloadTemplateRS =  () => {
    axios.get('https://dev.croplab.gilab.rs/backend/api/sensor/soc_template/?lang=sr', {responseType: 'blob'})
      .then(result => {

        downloadTemplateRSRef.current.href = window.URL.createObjectURL(result.data);
        downloadTemplateRSRef.current.click();
        return;
      })
      .catch(err => {
        return
      })

      
  }

  const downloadTemplateParcels =  () => {
    axios.get('https://dev.croplab.gilab.rs/backend/api/sensor/soc_template_parcels/?lang=en', {responseType: 'blob'})
      .then(result => {

        downloadTemplateRefP.current.href = window.URL.createObjectURL(result.data);
        downloadTemplateRefP.current.click();
        return;
      })
      .catch(err => {
        return
      })

      
  }

  const downloadTemplateRSParcels =  () => {
    axios.get('https://dev.croplab.gilab.rs/backend/api/sensor/soc_template_parcels/?lang=sr', {responseType: 'blob'})
      .then(result => {

        downloadTemplateRSRefP.current.href = window.URL.createObjectURL(result.data);
        downloadTemplateRSRefP.current.click();
        return;
      })
      .catch(err => {
        return
      })

      
  }

  const deleteSamples = () => {
    setDeleteLoading(true);
    $data.deleteSamples(selected)
      .then(data => {
        setDeleteLoading(false);
        setDeleteModal(false);
        setSelected([]);
        setState({success: lang.samples_delete_success});
        onUpdateSamples();
      })
      .catch(err => {
        setDeleteLoading(false);
        setState({error: lang.samples_delete_error})
      })
  }

  const onHandlePageChange = (evt, pg) => {
    setState({ page: pg })
  }

	const onChange = (checked, val) => {
		if(checked) {
			if(val === 'all') {
				setSelected(table.map(obj => obj.id))
			} else {
				setSelected(_.concat(selected, val))
			}
		} else {
			if(val === 'all') {
			  setSelected([]);
			} else {
			  setSelected(selected.filter(s => s !== val))
			}
		}
	}
  const extractColumns = (rows) => {
    let columns = [];
    rows.map(obj => {
      columns = _.concat(columns, Object.keys(obj));
    })

    return [...new Set(columns)];
  }

  const onFilesChange = (evt) => {
    if (['application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(filesRef.current.files[0].type) === -1) {
      setState({typeError: true})
      return
    }
    setState({typeError: false});

    const reader = new FileReader();

    reader.onload = (evt) => {
      const wb = read(evt.target.result);
      const sheets = wb.SheetNames;
      // console.log(wb.Sheets[sheets[0]]);
      if (sheets.length) {
        // utils.sheet_to
        const sheetRows = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, defval: '' });
        const sheetColumns = extractColumns(sheetRows);

        setColumns(sheetRows[0])
        setRows(sheetRows.filter((val, i) => i != 0));

        setMapping(true);


      }
    }

    reader.readAsArrayBuffer(filesRef.current.files[0]);


  }

  const renderSampleGroups = () => {
    if(state.loading) {

    }

    if (samplesData.length === 0) {
      return (
        <Grid item xs={12}>
          <Typography color="primary" variant="body1" colSpan={5}>
            {lang.no_samples}
          </Typography>
        </Grid>
      )
    }

    if(samplesData.length === 0) return;

    if(table) {
      const columnNames = $data.extractColumns(table);

      return (
        <>
          <Grid item xs={12}>
            <Button size="small" startIcon={<ArrowBack />} onClick={() => {setTable(null); setState({page: 0}); setSelected([])}}>{lang.go_back}</Button>
          </Grid>
          <Grid item xs={12}>
          <Stack direction="row" justifyContent={'space-between'} alignItems="center">
              <Button sx={{mb: '15px'}} onClick={() => setDeleteModal(true)} disabled={selected.length === 0} color="error" variant="contained" size="small" startIcon={<Delete />}>
                {lang.delete}
              </Button>
              {columnNames.length > 0 && <TablePagination
                sx={{justifyContent: 'flex-start'}}
                rowsPerPageOptions={[]}
                component="div"
                count={table.length}
                rowsPerPage={10}
                page={state.page}
                onPageChange={onHandlePageChange}
              />}
              </Stack>
              
            <TableContainer sx={{width: '100%', overflow: 'auto'}} component={Paper} elevation={2}>
              <Table size="small">
         
                {columnNames.length > 0 && <TableHead>
                  <TableRow>
                    <TableCell>
                      <Tooltip title={lang.select_all} position="top"><Checkbox value={'all'} checked={selected.toString() === table.map(obj => obj.id).toString()} onChange={(evt) => onChange(evt.target.checked, 'all')} /></Tooltip>
                    </TableCell>
                    {columnNames.map((name, key) => <TableCell sx={{color: primary}} key={key}>{lang['soil_' + name] || name}</TableCell>)}
                  </TableRow>
                </TableHead>}
                <TableBody>
                  {renderSamples(columnNames)}
                </TableBody>
              </Table>
            </TableContainer>
            {columnNames.length > 0 && <TablePagination
                sx={{justifyContent: 'flex-start'}}
                rowsPerPageOptions={[]}
                component="div"
                count={table.length}
                rowsPerPage={10}
                page={state.page}
                onPageChange={onHandlePageChange}
              />}
          </Grid>
        </>
      )
    }

    return (
      <Grid container spacing={2}>
        {samplesData.map((obj, key) => {
          return <Grid onClick={() => setTable($data.formatSamples(Object.values(obj)[0]))} key={key} item xs={12} sm={4} md={3} lg={2}>
            <Paper elevation={3} sx={{p: '20px', textAlign: 'center', cursor: 'pointer', '&:hover': {background: 'rgba(0,0,0,0.1)'}}}>
              {Object.keys(obj)[0]}
            </Paper>
          </Grid>
        })}
      </Grid>
    )
  }


  const renderSamples = (columnNames) => {
    if (state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }

    if (table.length === 0 || columnNames.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            {lang.no_samples}
          </TableCell>
        </TableRow>
      )
    }

    let filtered = table.filter((obj, key) => key < (state.page + 1) * 10 && key >= state.page * 10)

    return filtered.map((obj, key) => {
      console.log(obj)
      return (
        <TableRow sx={{background: selected.indexOf(obj.id) === -1 ? '#fff' : 'rgba(200, 200, 200, .4)'}} key={key}>
          <TableCell>
            <Checkbox value={obj.id} checked={selected.indexOf(obj.id) > -1} onChange={(evt, checked) => onChange(checked, obj.id)} />
          </TableCell>
          {columnNames.map((name, j) => {
            return <TableCell sx={{whiteSpace: 'nowrap'}} key={j}>{obj[name] || ' - '}</TableCell>
          })}
        </TableRow>
      )
    })

  }



  return (
    <Dialog fullWidth maxWidth="xl" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.soil_measurements}
        <Close onClick={props.onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent>
        <Grid sx={{ mt: '30px' }} container spacing={2}>
          <Grid item xs={12}>
            <Stack sx={{ mb: '15px' }} spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
              <Button size="small" variant="contained" component="label">
                <Upload /> &nbsp; {lang.select_xls}
                <input ref={filesRef} onChange={onFilesChange} type="file" hidden />
              </Button>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Stack onClick={() => downloadTemplate()} sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { transform: 'scale(1.05)' } }} component="a" direction="row" spacing={3} alignItems="center">
                    <Description color="success" />
                    &nbsp;{lang.download_example + ' (EN)'}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                <Stack onClick={() => downloadTemplateRS()} sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { transform: 'scale(1.05)' } }} component="a" direction="row" spacing={3} alignItems="center">
                <Description color="success" />
                &nbsp;{lang.download_example + ' (RS)'}
              </Stack>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                <Stack onClick={() => downloadTemplateParcels()} sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { transform: 'scale(1.05)' } }} component="a" direction="row" spacing={3} alignItems="center">
                <Description color="success" />
                &nbsp;{lang.download_example_parcels + ' (EN)'}
              </Stack>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                <Stack onClick={() => downloadTemplateRSParcels()} sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { transform: 'scale(1.05)' } }} component="a" direction="row" spacing={3} alignItems="center">
                <Description color="success" />
                &nbsp;{lang.download_example_parcels + ' (RS)'}
              </Stack>
                </Grid>
              </Grid>
             
              <a ref={downloadTemplateRef} download="SOC_template"></a>
              
              <a ref={downloadTemplateRSRef} download="SOC_template"></a>
              
              <a ref={downloadTemplateRefP} download="SOC_template"></a>
              
              <a ref={downloadTemplateRSRefP} download="SOC_template"></a>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{fontStyle: 'italic'}} color="darkorange">{lang.date_format_note}</Typography>
          </Grid>
          {state.typeError && <Grid item xs={12}>
            <Typography variant="h6" color="error">
              {lang.soil_type_error}
            </Typography>
          </Grid>}

          <Grid item xs={12}>
            <Divider style={{margin: 0, width: '100%'}}></Divider>
          </Grid>
          <Grid item xs={12}>
            {renderSampleGroups()}
          </Grid>
          {/* <Grid item xs={12}>
              <Stack direction="row" justifyContent={'space-between'} alignItems="center">
              <Button sx={{mb: '15px'}} onClick={() => setDeleteModal(true)} disabled={selected.length === 0} color="error" variant="contained" size="small" startIcon={<Delete />}>
                {lang.delete}
              </Button>
              {columnNames.length > 0 && <TablePagination
                sx={{justifyContent: 'flex-start'}}
                rowsPerPageOptions={[]}
                component="div"
                count={samplesData.length}
                rowsPerPage={10}
                page={state.page}
                onPageChange={onHandlePageChange}
              />}
              </Stack>
              
            <TableContainer sx={{width: '100%', overflow: 'auto'}} component={Paper} elevation={2}>
              <Table size="small">
         
                {columnNames.length > 0 && <TableHead>
                  <TableRow>
                    <TableCell>
                      <Tooltip title={lang.select_all} position="top"><Checkbox value={'all'} checked={selected.toString() === samplesData.map(obj => obj.id).toString()} onChange={(evt) => onChange(evt.target.checked, 'all')} /></Tooltip>
                    </TableCell>
                    {columnNames.map((name, key) => <TableCell sx={{color: primary}} key={key}>{lang['soil_' + name] || name}</TableCell>)}
                  </TableRow>
                </TableHead>}
                <TableBody>
                  {renderSamples(columnNames)}
                </TableBody>
              </Table>
            </TableContainer>
            {columnNames.length > 0 && <TablePagination
                sx={{justifyContent: 'flex-start'}}
                rowsPerPageOptions={[]}
                component="div"
                count={samplesData.length}
                rowsPerPage={10}
                page={state.page}
                onPageChange={onHandlePageChange}
              />}
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="error" variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>

      {Boolean(mapping) && <SoilMappingModal open={Boolean(mapping)} onClose={() => { setMapping(false); setColumns([]); setRows([]); filesRef.current.value = "" }} setColumns={setColumns} setRows={setRows} columns={columns} rows={rows} onSuccess={() => { setMapping(false); setPreview(true); filesRef.current.value = "" }} />}
      {Boolean(preview) && <SoilMappingPreview open={Boolean(preview)} onClose={() => { setPreview(false); setColumns([]); setRows([]); filesRef.current.value = "" }} setColumns={setColumns} setRows={setRows} columns={columns} rows={rows} onSuccess={() => { setPreview(false); filesRef.current.value = ""; setState({success: lang.soil_upload_success}) }} />}
      <ConfirmDialog open={deleteModal} loading={deleteLoading} title={lang.delete_sample_title} question={lang.delete_sample_question} onClose={() => setDeleteModal(false)} onSubmit={deleteSamples} />
      
      <Message type="error" open={state.error ? true : false} message={state.error} onClose={() => setState({ error: null })} />
      <Message type="success" open={state.success ? true : false} message={state.success} onClose={() => setState({ success: null })} />
              
    </Dialog>
  )
}

export default SoilSamples;