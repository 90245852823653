import React from 'react';
import errors from './components';
import $auth from '../../../services/$auth';

class ErrorWrapper extends React.Component {
  
  state = {
    error: false
  }

  static getDerivedStateFromError(error) {
    return {error: true}
  }
  
  constructor(props) {super(props)}

  componentDidUpdate() {
    if(this.state.error) {
      $auth.unauthorize();
      window.location.reload();
    }
  }

  render() {
    if(this.state.error) {
      return errors[this.props.component]
    }

    return this.props.children
  }
}

export default ErrorWrapper;