import { Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import _ from "lodash";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";

function PlantProtectionView(props) {
	let {lang, primary} = useContext(AppContext);
	let {data} = props;

	const [multiline, setMultiline] = useState(false);

	const renderAgrotechnical = () => {
		let filtered = data.agrotechnical_operation

		if(filtered.length === 0) return (
			<TableRow>
				<TableCell colSpan="2">{lang.plant_protection_no_data}</TableCell>
			</TableRow>
		)

		return filtered.map((obj, key) => {
			return (
				<TableRow key={key}>
					<TableCell>{obj.agrotechnical_type_name || ' - '}</TableCell>
					<TableCell>{obj.irrigation_norm || ' - '}</TableCell>
				</TableRow>
			)
		})
	}

	const renderProtection = () => {
		let filtered = data.operations;

		if(filtered.length === 0) return (
			<TableRow>
				<TableCell colSpan="8">{lang.plant_protection_no_data}</TableCell>
			</TableRow>
		)

		return filtered.map((obj, key) => {
			return (
				<TableRow key={key}>
					<TableCell>{obj.natural !== null ? <Chip variant="filled" size="small" sx={{textTransform: 'uppercase', fontWeight: 'bold'}} label={obj.natural ? lang.natural : lang.syntethic} color={obj.natural ? "primary" : "warning"} />  : ' - '}</TableCell>
					<TableCell>{obj.protection_type_name || ' - '}</TableCell>
					<TableCell>{obj.product || ' - '}</TableCell>
					<TableCell>{obj.substance || ' - '}</TableCell>
					<TableCell>{obj.quantity_ha ? obj.quantity_ha  + ' ' + (obj.quantity_unit === 1 ? 'kg' : 'l'): ' - '}</TableCell>
					<TableCell>{obj.quantity_parcel ? obj.quantity_parcel + ' ' + (obj.quantity_unit === 1 ? 'kg' : 'l') : ' - '}</TableCell>
					<TableCell>{obj.product_unit_price || ' - '}</TableCell>
					<TableCell>{obj.product_price_ha || ' - '}</TableCell>
					<TableCell>{obj.product_price_parcel || ' - '}</TableCell>
				</TableRow>
			)
		})
	}

	const calculateTotalPrices = (price_ha, price_field) => {		
		let ha = price_ha || 0;
		let field = price_field || 0;

		let filtered = data.operations
			.map(obj => {
				if(!isNaN(obj.product_price_ha)) {
					ha += obj.product_price_ha
				}

				if(!isNaN(obj.product_price_parcel)) {
					field += obj.product_price_parcel
				}
			})

		return {ha, field}
	}

	return (
		<Grid container spacing={2}>

			{/* <Grid item xs={12}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{lang.agrotechnical_type}</TableCell>
							<TableCell>{lang.irrigation_norm}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{renderAgrotechnical()}
					</TableBody>
				</Table>
			</Grid>	 */}

			<Grid item xs={12}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{lang.natural_type}</TableCell>
							<TableCell>{lang.protection_type}</TableCell>
							<TableCell>{lang.product}</TableCell>
							<TableCell>{lang.active_substance}</TableCell>
							<TableCell>{lang.quantity_ha}</TableCell>
							<TableCell>{lang.quantity_parcel}</TableCell>
							<TableCell>{lang.product_unit_price}</TableCell>
							<TableCell>{lang.product_price_ha}</TableCell>
							<TableCell>{lang.product_price_parcel}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{renderProtection()}
					</TableBody>
				</Table>
			</Grid>	

			<Grid item xs={12} sm={6} md={3} >
				<TextField focused={false} label={lang.price_ha} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.price_ha || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField focused={false} label={lang.price_parcel} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.price_parcel || ' - '} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer', fontWeight: 'bold'} }} focused label={lang.total_price_ha} fullWidth size="small" inputProps={{readOnly: true}} value={calculateTotalPrices(data.price_ha, data.price_parcel).ha} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer', fontWeight: 'bold'}}} focused label={lang.total_price_parcel} fullWidth size="small" inputProps={{readOnly: true}} value={calculateTotalPrices(data.price_ha, data.price_parcel).field} />
			</Grid>

			<Grid item xs={12} >
				<p style={{margin: '0 0 5px 13px', color: primary, fontWeight: 'bold', fontSize: '12px'}}>{lang.operators}</p>
				{data.operators.map((operator, key) => {
					return <Chip style={{marginRight: '3px', marginLeft: '3px', marginBottom: '5px'}} key={key} variant="outlined" color="primary" label={operator.first_name + ' ' + operator.last_name} />
				})}
			</Grid>
			
			<Grid item xs={12}>
				<TextField focused label={lang.fuel_consumption} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.fuel_consumption || ' - '} />
			</Grid>	

			<Grid item xs={12} sm={6} >
				<TextField focused={false} label={lang.fld_machine} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.machine_name || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} >
				<TextField focused={false} label={lang.fld_con_machine} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.conn_machine_name || ' - '} />
			</Grid>
			<Grid item xs={12}>
				<TextField focused={false} multiline label={lang.comment} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={data.comment || ' - '} />
			</Grid>	
		</Grid>
	)
}

export default PlantProtectionView;