import { Button, Stack } from "@mui/material";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";


function TourGuide(props) {
  const {lang, view, cropGroups, datapanel, user, onUpdateState} = useContext(AppContext)
  const [state, setState] = useMergeState({active: 0});

  useEffect(() => {
    if(props.view === 'vrt') {
      document.querySelector('.vrt-container').scrollTo(0, 0)
      setState({active: 0})
    }
  }, [])

  if(!props.active) return null;

 

  const map_steps = [{
    target: '.basemaps-row',
    content: 'Toggle between bakground maps',
  }, {
    target: '.source-row',
    content: 'Toggle between Sentinel-2 and PLANET satellite imagery sources',
  }, {
    target: '.rgb-row',
    content: 'Toggle satellite layer',
  },{
    target: '.index-row',
    content: 'Toggle between different vegetation indices',
  },{
    target: '.opacity-row',
    content: 'You can control the visibility of the selected index above, by moving this range bar',
  },{
    target: '.dates-row',
    content: 'For selected index above, you can browse available information through time, by using this calendar. Visualization of field crops also depends on the selected date.',
  },{
    target: '.filter-parcels-row',
    content: 'You can use this input to filter the list of fields below by field name and/or crop type.',
  },{
    target: '.crop-groups-row',
    content: <div>
      Crop types are categorized into 6 crop groups:
        <ul>
          <li style={{fontWeight: 'bold', color: '#FBA823'}}>{cropGroups[1].name}</li>
          <li style={{fontWeight: 'bold', color: '#2AB300'}}>{cropGroups[4].name}</li>
          <li style={{fontWeight: 'bold', color: '#CC0022'}}>{cropGroups[2].name}</li>
          <li style={{fontWeight: 'bold', color: '#2A6FDB'}}>{cropGroups[5].name}</li>
          <li style={{fontWeight: 'bold', color: '#676767'}}>{cropGroups[3].name}</li>
          <li style={{fontWeight: 'bold', color: '#9D4E14'}}>{cropGroups[0].name}</li>
        </ul>
    </div>,
  }, {
    target: '.toolbar-group-row',
    content: 'You can select the tool from this toolbar in order to perform actions on the map. By default, if none of the actions are selected, you can perform point query by clicking on the location inside one of your fields.',
    placement: 'left'

  }, {
    target: '.toolbar-compare-row',
    content: 'If you want to compare two locations, you can select comparison tool and select two locations inside the field(s) on the map.',
    placement: 'left'

  }, {
    target: '.toolbar-draw-row',
    content: 'You can add parcels using this tool. Just select the tool, draw the field and enter it\'s name. After confirming, you will see it displayed on the map.',
    placement: 'left'

  }, {
    target: '.toolbar-measure-length-row',
    content: 'Tool for measuring length on the map',
    placement: 'left'
  }, {
    target: '.toolbar-measure-area-row',
    content: 'Tool for measuring area on the map',
    placement: 'left'
  }, {
    target: '.index-legend-row',
    content: 'These colors represent the visualization color scheme for selected index. By hovering over specific color, you can se the range of values it represents on the map.',
    placement: 'left'
  }, {
    target: '.datapanel-row',
    content: 'This section displays the output of your actions (point query, compare locations).',
    placement: 'top',
    action: () => {
      if(!datapanel) {
        onUpdateState({datapanel: true})
      }
    }
  }, {
    target: '.dp-summary-row',
    content: 'In this tab, you can see summary statistics for selected field on the map.',
    placement: 'top'
  }, {
    target: '.dp-query-row',
    content: 'In this tab, you can see the results of location query/comparison query.',
    placement: 'top'
  }, {
    target: '.dp-gdd-row',
    content: 'In this tab, you can see information about the crop stage for selected field on the map.',
    placement: 'top'
  }, {
    target: '.dp-meteo-row',
    content: 'In this tab, you can see historical meteo data for selected field on the map.',
    placement: 'top'
  }, {
    target: '.dp-forecast-row',
    content: 'In this tab, you can see weather forecast for selected field on the map.',
    placement: 'top'
  }].filter(obj => {
    if(obj.target === '.source-row' && !user.planet) {
      return false
    }

    if(obj.target === '.sensors-row' && !user.sensors) {
      return false
    }
    return true
  });

  const vrt_steps = [{
    target: '.vrt-source-row',
    content: 'Select DAILY if you want to generate VRA map, based on selected date, or select MULTI-YEAR of you want to generate VRA MAP based on overall productivity of the field',
  }, {
    target: '.vrt-temporal-row',
    content: 'Toggle between Sentinel-2 and PLANET satellite imagery sources',
  }, {
    target: '.vrt-date-map-row',
    content: 'Here you can see the generated VRA map for selected field(s), based on selected date or overall productivity',
    placement: 'top',
    action: () => {
      document.querySelector('.vrt-container').scrollTo(0, 0)
    }
  },{
    target: '.vrt-parcels-toggle-row',
    content: 'You can click here to view the list of fields',
    placement: 'left',
    action: () => {
      onUpdateState({vrtParcels: false})
    }
  },{
    target: '.vrt-parcels-open-row',
    content: 'Select fields from the list and the VRA map will automatically be generated (if the data for selected field(s) are available)',
    placement: 'left',
    action: () => {
      onUpdateState({vrtParcels: true})
    }
  },, {
    target: '.vrt-input-row',
    content: 'You can control the visibility of the selected index above, by moving this range bar',
    placement: 'top',
    action: () => {
      onUpdateState({vrtParcels: false})
      let rect = document.querySelector('.vrt-input-row').getBoundingClientRect()
      document.querySelector('.vrt-container').scrollTo(rect.left, rect.bottom)
    }
  }, {
    target: '.vrt-export-row',
    content: 'You can control the visibility of the selected index above, by moving this range bar',
    placement: 'top'
  }].filter(obj => {
    if(obj.target === '.vrt-source-row' && !user.planet) {
      return false
    }

    return true
  });

  const field_book_steps = [{
    target: '.fieldbook-filter-row',
    content: 'You can also export the fieldbook for the whole period or for user-defined period.',
    placement: 'top'
  },{
    target: '.fieldbook-employees-row',
    content: 'If you need to see the list of employees and/or add new ones, click here. You can also do the same operation in the account section.',
    placement: 'top'
  }, {
    target: '.fieldbook-machines-row',
    content: 'If you need to see the list of machines and/or add new ones, click here.',
    placement: 'top'
  }, {
    target: '.fieldbook-cmachines-row',
    content: 'If you need to see the list of connected machines and/or add new ones, click here.',
    placement: 'top'
  }, {
    target: '.fieldbook-activities-row',
    content: 'See the calendar with all activities categorized by status of the activity.',
    placement: 'top'
  }, {
    target: '.fieldbook-documents-row',
    content: 'See the list of all documents uploaded with search, download and delete options.',
    placement: 'top'
  }, {
    target: '.fieldbook-export-row',
    content: 'You can also export the fieldbook for the whole period or for user-defined period.',
    placement: 'left'
  },{
    target: '.fieldbook-fields-row',
    content: 'All fields you created are displayed here, with the information such as: name, current crop and area. By clicking on one of them, you will be able to see the agricultural activities.',
    placement: 'top'
  }
  // ,{
  //   target: '.vrt-parcels-toggle-row',
  //   content: 'You can click here to view the list of fields',
  //   placement: 'left',
  //   action: () => {
  //     onUpdateState({vrtParcels: false})
  //   }
  // },{
  //   target: '.vrt-parcels-open-row',
  //   content: 'Select fields from the list and the VRA map will automatically be generated (if the data for selected field(s) are available)',
  //   placement: 'left',
  //   action: () => {
  //     onUpdateState({vrtParcels: true})
  //   }
  // }, {
  //   target: '.vrt-input-row',
  //   content: 'You can control the visibility of the selected index above, by moving this range bar',
  //   placement: 'top',
  //   action: () => {
  //     onUpdateState({vrtParcels: false})
  //     let rect = document.querySelector('.vrt-input-row').getBoundingClientRect()
  //     document.querySelector('.vrt-container').scrollTo(rect.left, rect.bottom)
  //   }
  // }, {
  //   target: '.vrt-export-row',
  //   content: 'You can control the visibility of the selected index above, by moving this range bar',
  //   placement: 'top'
  // }
]

  const getSteps = (view) => {
    switch(view) {
      case 'map': return map_steps;
      case 'fieldbook': return field_book_steps;
      case 'vrt': return vrt_steps;
      default: return [];
    }
  }

  const steps = getSteps(props.view)
  if(steps.length === 0) return null;

  const getPrevious = () => {
    let previous = state.active - 1;
    let stepObj = steps[previous];

    if(stepObj.action) {
      stepObj.action();
      setTimeout(() => {
        setState({active: previous})
      }, 400)
    } else {
      setState({active: previous})
    }

    setState({active: previous})
  }

  const getNext = () => {
    let next = state.active + 1;
    let stepObj = steps[next];

    if(stepObj.action) {
      stepObj.action();
      setTimeout(() => {
        setState({active: next})
      }, 400)
    } else {
      setState({active: next})
    }

  }
  
  return (
    <div>
      <div className="overlay-mask">
        {steps.map((obj, key) => {
            let el = document.querySelector(obj.target);

            return (
              <div key={key}>
                <div key={(key + 2) * Math.random()} className={"spotlight" + (state.active === key ? ' active' : '')} style={{
                  width: el.offsetWidth,
                  height: el.offsetHeight,
                  left: el.getBoundingClientRect().left,
                  top: el.getBoundingClientRect().top
                }}></div>
            </div>        
          )})}


      </div>
        {steps.map((obj, key) => {
          let el = document.querySelector(obj.target);
          if(!el) {
            getNext()
            return null
          }
          if(obj.placement === 'left') {

            if(obj.target === '.vrt-parcels-open-row') {

              return (<div key={(key + 1) * Math.random()}  onClick={getNext}  className={"spotlight-content" + (state.active === key ? ' active' : '') + (obj.placement ? ` ${obj.placement}` : '')} style={{
                right: el.getBoundingClientRect().left - el.offsetWidth - 350,
                top: el.getBoundingClientRect().top + el.offsetHeight/2,
              }}>{obj.content}
            
              </div>)
            }

            if(obj.target === '.vrt-parcels-toggle-row') {

              return (<div key={(key + 1) * Math.random()}  onClick={getNext}  className={"spotlight-content" + (state.active === key ? ' active' : '') + (obj.placement ? ` ${obj.placement}` : '')} style={{
                left: el.getBoundingClientRect().right - el.getBoundingClientRect().left - 230,
                top: el.getBoundingClientRect().top + el.offsetHeight/2,
              }}>{obj.content}
            
              </div>)
            }

            if(obj.target === '.fieldbook-export-row') {
              return (<div key={(key + 1) * Math.random()} className={"spotlight-content" + (state.active === key ? ' active' : '') + (obj.placement ? ` ${obj.placement}` : '')} style={{
                left: el.getBoundingClientRect().left - el.offsetWidth - 270,
                top: el.getBoundingClientRect().top + el.offsetHeight/2,
              }}>
                {obj.content}
              
              </div>)
            }

            return (<div key={(key + 1) * Math.random()} className={"spotlight-content" + (state.active === key ? ' active' : '') + (obj.placement ? ` ${obj.placement}` : '')} style={{
              right: props.view === 'map' ? el.offsetWidth + 20 : 'auto',
              left: props.view === 'map' ? 'auto' : el.getBoundingClientRect().right,
              top: el.getBoundingClientRect().top + el.offsetHeight/2,
            }}>
              {obj.content}
            
            </div>)
          }

          if(obj.placement === 'top') {
            
            return (<div key={(key + 1) * Math.random()} className={"spotlight-content" + (state.active === key ? ' active' : '') + (obj.placement ? ` ${obj.placement}` : '')} style={{
              left:  el.getBoundingClientRect().left + el.offsetWidth/2,
              bottom: props.view === 'map' ? 230 : 'auto',
              top: props.view === 'map' ? 'auto' : el.getBoundingClientRect().top - 60
            }}>
              {obj.content}
            
            </div>)
          }

          return (<div key={(key + 1) * Math.random()} className={"spotlight-content" + (state.active === key ? ' active' : '')} style={{
            left: el.getBoundingClientRect().left + el.offsetWidth + 20,
            top: el.getBoundingClientRect().top + el.offsetHeight/2
          }}>
            {obj.content}
          
          </div>)
        })}

      <Stack direction="row" justifyContent="center" sx={{mt: '10px'}} spacing={2} style={{width: '100%', background: '#fff', padding: '20px 0', position: 'fixed', zIndex: 999999999, bottom: 0, left: '50%', transform: 'translateX(-50%)'}}>
        <Button disabled={state.active === 0} variant="contained" onClick={getPrevious} size="small">&lt;&lt;</Button>
        <Button disabled={state.active === steps.length - 1} variant="contained" onClick={getNext} size="small">&gt;&gt;</Button>
        <Button onClick={() => props.onUpdateState({tourGuide: false})} variant="contained" color="error" size="small">Done</Button>
      </Stack>
    </div>
  )
}

export default TourGuide;