import { Avatar, Button, Card, CardContent, CardMedia, Container, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import Divider from "../auth/Divider";

import sensorsImg from '../../images/sensors-image.webp'
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { BarChart, ContactMail, Delete, Edit, Folder, Grain, LineAxis, Mail, Opacity, Thunderstorm, WbShade, WbSunny } from "@mui/icons-material";
import ContactForm from "../ui/ContactForm";
import Message from "../ui/Message";
import SensorsMap from "./SensorsMap";
import EditSensor from "./EditSensor";
import $data from "../../services/$data";
import axios from "axios";
import useMergeState from "../hooks/useMergeState";
import BatteryLevel from "./BatteryLevel";
import { blue, brown, orange } from "@mui/material/colors";
import SensorResults from "./SensorResults";

function Sensors() {
  const { lang, sensorsData, primary, secondary, onUpdateState } = useContext(AppContext);

  const [battery, setBattery] = useMergeState({});

  const [contact, setContact] = useState(false);

  const [edit, setEdit] = useState(false);

  const [results, setResults] = useState(false);

  const [success, setSuccess] = useState(null);

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    onUpdateState({ view: 'sensors', mounted: true });

    let promises = {};

    sensorsData.map(obj => {
      if(obj.type === 'Node') {
        promises[obj.id] = $data.getMeasurements(`?device_name=${obj.name}&variable=Battery level`, true);
      }
    })

    axios.all(Object.values(promises))
      .then(result => {
        let batteryLevels = {};

        result.map(obj => {
          batteryLevels[obj.sensor] = parseInt(obj.value);
        })

        setBattery(batteryLevels);
      })
      .catch(err => {
        console.log(err)
      })

  }, [])

  const onCloseMessage = (evt, reason) => {
    if (reason === 'clickaway') return;
    setSuccess(null)
  }

  const renderHeader = () => {
    return (
      <Card sx={{ display: 'flex' }}>
        <CardContent sx={{ width: '100%', position: 'relative', padding: 0 }} className="sensors-card-content">

          <CardMedia
            component="img"
            sx={{ backgroundPosition: 'cover', maxHeight: '300px', padding: 0 }}

            image={sensorsImg}
            alt="Live from space album cover"
          />

          <p style={{ color: primary, fontWeight: 'bold', background: 'rgba(255,255,255, 0.7)', textAlign: 'center', position: 'absolute', top: '40%', transform: 'translate(-50%, -50%)', padding: '15px', left: '50%', maxWidth: '500px', width: 'calc(100%)' }}>
            {lang.sensors_contact_message}
            <br /><br />
            <a style={{ textDecoration: 'none' }} target="_blank">
              <Button onClick={() => setContact(true)} variant="contained" size="small" endIcon={<Mail />}>{lang.contact}</Button>
            </a>
          </p>

        </CardContent>
      </Card>
    )
  }

  const renderSensors = () => {
    if (sensorsData.length === 0) {
      return (
        <Grid item xs={12}>
          <Stack sx={{ textAlign: 'center' }}>
            <Typography variant="h6" color="primary">{lang.no_sensors}</Typography>
          </Stack>
        </Grid>
      )
    }

    return (
      <>
        <Grid item xs={12} md={6}>
          <List disablePadding component={'nav'} dense>
            {sensorsData.map((sensor, key) => {
              return <ListItem onClick={() => setSelected(sensor.id)} sx={{'&:hover': {background: '#F1F6F4'}, cursor: 'pointer'}} selected={sensor.id === selected} key={key} secondaryAction={
                <>
               
                <IconButton onClick={() => setResults(true)} edge="end" aria-label="delete">
                  <BarChart color="primary" />
                </IconButton>
                &nbsp;
                &nbsp;
                <IconButton onClick={() => setEdit(true)} edge="end" aria-label="edit">
                  <Edit color="warning" />
                </IconButton>
                </>
              }>
                <Stack alignItems={'center'} direction="row">
                {/* <ListItemButton onClick={() => setSelected(selected === sensor.id ? null : sensor.id)}> */}
                  <ListItemAvatar>
                    <Avatar sx={{bgcolor: sensor.type === 'Node' ? blue[300] : orange[700]}}>
                      {sensor.type === 'Node' ? <Opacity /> : <Thunderstorm />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={sensor.name}
                    secondary={sensor.type}
                  />
                  {battery[sensor.id] && <BatteryLevel level={battery[sensor.id]} />}
                  </Stack>
                  
                {/* </ListItemButton> */}
              </ListItem>
            })}

          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <SensorsMap selected={selected} onSetSelected={setSelected} />
        </Grid>
      </>
    )



    return
  }

  return (
    <Container sx={{ mt: '30px', pb: '40px', overflowY: 'auto', height: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderHeader()}
        </Grid>
        <Grid item xs={12}><Divider style={{ width: '100%' }}></Divider></Grid>

        {renderSensors()}
      </Grid>

      <ContactForm open={contact} onClose={() => setContact(false)} onSuccess={() => { setContact(false); setSuccess(lang.contact_success) }} />

      <EditSensor selected={selected} open={edit} onClose={() => setEdit(false)} onSuccess={() => {setEdit(false); setSuccess(lang.sensor_edit_success)}} />

      <SensorResults open={results} onClose={() => setResults(false)} sensor={sensorsData.filter(obj => selected === obj.id)[0]} />

      <Message type="success" open={success ? true : false} message={success} onClose={onCloseMessage} />


    </Container>
  )
}

export default Sensors;