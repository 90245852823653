import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import useMergeState from "../hooks/useMergeState";
import { Container, DialogContent, DialogTitle, Grid, TextField, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography, colors, Table, TableHead, TableRow, TableCell, TableFooter, TablePagination, TableBody, LinearProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { LoadingButton } from "@mui/lab";
import $data from "../../services/$data";
import Message from "../ui/Message";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";

function EmployeesModal(props) {
  const {lang, onUpdateOperators} = useContext(AppContext);

  const [data, setData] = useMergeState({model: {
    first_name: '',
    last_name: '',
    email: '',
    additional_user_email: '',
    phone: '',
    isManager: false,
    isOperater: true,
    production_diary: false,
    password: ''
  }, loading: false, success: null,  error: {}, });

  const [employees, setEmployees] = useMergeState({data: {count: 0, results: []}, loading: false});
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getEmployees()
  }, [])

  const onSubmit = (evt) => {
    evt.preventDefault();
    setData({error: {}, loading: true});
    $data.postEmployee(data.model)
      .then(data => { getEmployees(page); onUpdateOperators(); setData({model: {
        first_name: '',
        last_name: '',
        email: '',
        additional_user_email: '',
        phone: '',
        isManager: false,
        isOperater: true,
        production_diary: false,
        password: ''
      }, success: lang.success_create_employee, loading: false}) })
      .catch(err => { setData({error: err, loading: false}) })
  }

  const getEmployees = (page) => {
    setEmployees({loading: true});
    $data.getEmployees(page)
      .then(data => setEmployees({data, loading: false}))
      .catch(err => setEmployees({loading: false}))
  }

  const onHandlePageChange = (evt, pg) => {
    getEmployees(pg);
    setPage(pg);
  }

  const renderEmptyTable = () => {
    return (
      <TableRow>
        <TableCell colSpan={6}>{lang.no_employees}</TableCell>
      </TableRow>
    )
  }

  const renderRows = (data) => {
    if(!data) return renderEmptyTable();

    if(!data.results) return renderEmptyTable();

    if(data.results.length === 0) return renderEmptyTable();

    return data.results.map((employee, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{`${employee.first_name} ${employee.last_name}`}</TableCell>
          <TableCell>{employee.isManager ? lang.manager.toUpperCase() : lang.operator.toUpperCase()}</TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.employees} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginBottom: '20px'}}>
          <Grid component="form" onSubmit={onSubmit} container spacing={2}>
            <Grid item xs={12}>
               <Link style={{textDecoration: 'none'}} to="/dashboard/profile">
                <Typography sx={{'&:hover': {color: colors.blue[500]}}} variant="p" color={colors.orange[500]}>
                  {lang.employee_nav_message}  
                </Typography>
              </Link>      
            </Grid>
            <Grid item xs={12}>
               <Typography variant="p" style={{fontWeight: 'bold'}} color="primary">
                {lang.add_employee}  
              </Typography>      
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField size="small" error={data.error.first_name !== undefined || data.error.non_field_errors !== undefined} helperText={data.error.first_name} fullWidth label={lang.first_name} type='text' value={data.model.first_name || ''} onChange={(evt) => setData({model: {...data.model, first_name: evt.target.value}})} />      
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField size="small" error={data.error.last_name !== undefined || data.error.non_field_errors !== undefined} helperText={data.error.last_name} fullWidth label={lang.last_name} type='text' value={data.model.last_name || ''} onChange={(evt) => setData({model: {...data.model, last_name: evt.target.value}})} />      
            </Grid>
        
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextField size="small" error={data.error.phone !== undefined || data.error.non_field_errors !== undefined} helperText={data.error.phone} fullWidth label={lang.phone} type='phone' value={data.model.phone} onChange={(evt) => setData({model: {...data.model, phone: evt.target.value}})} />      
            </Grid>  
            <Grid item xs={12}>
              <TextField size="small" error={data.error.email !== undefined || data.error.non_field_errors !== undefined} helperText={data.error.email} fullWidth label={lang.email} type='email' value={data.model.email} onChange={(evt) => setData({model: {...data.model, email: evt.target.value}})} />      
            </Grid>
            <Grid item xs={12}>
              <TextField size="small" error={data.error.password !== undefined || data.error.non_field_errors !== undefined} helperText={data.error.password} fullWidth label={lang.password} type='password' value={data.model.password} onChange={(evt) => setData({model: {...data.model, password: evt.target.value}})} />      
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl style={{padding: '0 14px'}}>
                <FormLabel id="demo-radio-buttons-group-label">{lang.role_type}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="eur"
                  name="radio-buttons-group">
                  <FormControlLabel value="manager" control={<Radio onChange={(evt) => setData({model: {...data.model, isManager: true, production_diary: true, isOperater: false}})} checked={data.model.isManager && data.model.production_diary} />} label={lang.manager} />
                  <FormControlLabel value="operator" control={<Radio onChange={(evt) => setData({model: {...data.model, isManager: false, production_diary: false, isOperater: true}})} checked={data.model.isOperater} />} label={lang.operator} />
                </RadioGroup>
              </FormControl>
            </Grid>   */}
            <Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} className="employee-input" item xs={12}>
              <LoadingButton size="small" loading={data.loading} type="submit" variant="contained">{lang.confirm}</LoadingButton>
            </Grid>      
          </Grid>
        </Container>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {employees.loading && <LinearProgress />}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{lang.fullname}</TableCell>
                    <TableCell>{lang.role_type}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(employees.data)}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={employees.data.count}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={onHandlePageChange}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <Message type="success" open={data.success ? true : false} message={data.success} onClose={() => setData({success: null})} />
      <Message type="error" open={data.error.non_field_errors !== undefined} message={data.error.non_field_errors} onClose={() => setData({error: {}})} />

    </Dialog>
  );
}

export default EmployeesModal;