import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import Message from "./Message";

function ConfirmDialog(props) {
  const {lang} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog sx={{zIndex: 99999999}} fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{props.title}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">{props.question}</Typography>
						</Grid>
            <Grid item xs={12}>
              {props.children}
						</Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color={props.confirmColor || "primary"} variant="contained" size="small" loading={props.loading} onClick={props.onSubmit}>{props.confirmText || lang.confirm}</LoadingButton>
        <LoadingButton color={props.closeColor || "error"} variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{props.closeText || lang.close}</LoadingButton>
      </DialogActions>

      <Message type="error" open={props.error ? true : false} message={props.error} onClose={props.onCloseMessage} />

    </Dialog>
  )
}

export default ConfirmDialog;