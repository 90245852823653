import { Close, Delete, Download, Event, FileDownload, FilePresent, Refresh } from "@mui/icons-material";
import { Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import moment from "moment";



function Notifications(props) {
  const {lang} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));    
 
  let notifications = props.notifications || [];

  const renderNotifications = () => {
    if(notifications.length === 0) return <Grid item xs={12}>{lang.no_notifications}</Grid>;

    return notifications.map((obj, key) => {
      return <Grid key={key} item xs={12}>
        <Stack component={Paper} elevation={2} sx={{padding: '15px'}} flexDirection="row" justifyContent="space-between" alignItems="flex-start">
          <span>{obj.message}</span>
          {moment(obj.date).format('DD-MMM-YYYY')}
        </Stack>
      </Grid>
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.notifications}
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          <Grid container spacing={2}>
            {renderNotifications()}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="primary" variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>

    </Dialog>
  )
}

export default Notifications;