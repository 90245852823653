import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Button, Checkbox, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";
import { Event } from "@mui/icons-material";
import $activities from "../../../services/$activities";

function NoteForm(props) {
	let {lang, locale} = useContext(AppContext);


	let editModel = props.model ? Object.assign({}, props.model) : {}

	const [data, setData] = useMergeState({model: Object.assign({}, {
		date: moment().format('YYYY-MM-DD'),
		activity_type: 6,
		comment: '',
		parcel: props.parcel.pk

	}, {...editModel}), error: {}, success: false, loading: false});

	const {model, error, success, loading} = data;

	const onChange = (name, val) => {
		let {value, additional} = $activities.getTillagePrices(name, val, props.parcel.area);
		setData({model: {...model, [name]: value, ...additional}});
	}


	return (
		<Grid component="form" onSubmit={(evt) => {evt.preventDefault(); props.onSubmit(model) }} container spacing={2}>
			<Grid item xs={12} sm={4}>
				<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
					<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onChange('date', model.date)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.date} value={moment(model.date)} onChange={(newValue) => onChange('date', newValue.format('YYYY-MM-DD'))}
						renderInput={(params) => (
							<TextField fullWidth error={props.error.date} helperText={props.error.date || ''} {...params} size="small"
								InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
						)}
					/>
				</LocalizationProvider>	
			
			</Grid>
			<Grid style={{padding: 0}} item xs={12} sm={4}></Grid>
			<Grid style={{padding: 0}} item xs={12} sm={4}></Grid>
		
			<Grid item xs={12}>
				<TextField error={props.error.comment ? true : false} helperText={props.error.comment || ''} rows={5} multiline label={lang.comment} fullWidth size="small" onChange={(evt) => onChange('comment', evt.target.value)}  value={model.comment} />
			</Grid>	

			<Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} item xs={12}>
				{!props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.confirm}</LoadingButton>}
								{props.forArchive && <LoadingButton size="small" color="warning" variant="contained" type="button" onClick={(() => props.onSubmit(model, false))} loading={props.loading}>{lang.act_job_update}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.archive_job}</LoadingButton>}

				{!props.edit && <LoadingButton size="small" onClick={props.onClose} variant="contained" color="error" loading={props.loading} type="button">{lang.close}</LoadingButton>}
			</Grid>	
		</Grid>
	)
}

export default NoteForm;
