import { Grain, Grass, LocalShipping, Comment, Science, VolunteerActivism, AttachFile, Water, WaterDamage, WaterfallChart, Pets, } from "@mui/icons-material";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, } from "@mui/lab";
import { Badge, Chip, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import colors from "../../config/colors";
import $data from "../../services/$data";

const activityIcons = () => ({
  1: <Grain />,
  2: <Science />,
  3: <Grass />,
  4: <VolunteerActivism />,
  5: <LocalShipping />,
  6: <Comment />,
  7: <Water />,
  8: <Pets />
});

const subtitleFields = {
	1: 'tillage_type_name',
  2: 'fertigation_type_name',
  3: 'crop_type_name',
  4: 'operations',
  5: 'yield_parcel',
  6: 'comment',
  7: 'agrotechnical_type_name',
  8: 'num_of_days'
}


export default function ActivityItem(props) {
  const {lang, activityTypes} = useContext(AppContext);
	
	const statusText = {
    warning: lang.act_job_active,
    success: lang.act_job_completed,
  };

  const getPlantProtectionSubtitle = (obj) => {
    console.log(obj)
    let names = _.concat(obj.operations.map(obj => obj.protection_type_name), /*obj.agrotechnical_operation.map(obj => obj.agrotechnical_type_name)*/);
    
    return names.join(', ')
  }

  const hasSeparator = () => {
    if(props.activity !== 3) return false;

    return true
  }

  const getCropGroupColor = (date) => {
    return 'transparent'
    if(!props.parcel) return 'transparent';
    if(props.parcel.crop_history.length === 0) return 'transparent';

    let crops = props.parcel.crop_history.map(c => ({...c})).reverse();

    let filtered = crops.filter((obj, i) => {
      
      if(obj.harvest) {
        return $data.betweenTwoDates(date, obj.sowing, obj.harvest);
      } else if(crops[i + 1]) {
        return $data.betweenTwoDates(date, obj.sowing, crops[i + 1].sowing);
      } else {
        return $data.betweenTwoDates(date, obj.sowing, moment(obj.sowing).add(1, 'year').format('YYYY-MM-DD'))
      }
    })

    if(!filtered[0]) return 'transparent';

    return colors.cropGroup[filtered[0].crop.crop_group] + '95'
  } 

  return (
    <TimelineItem sx={{background: getCropGroupColor(props.date)}} onClick={props.onClick} className="activity-item">
      <TimelineOppositeContent sx={{ m: "8px 0 auto 0" }} align="right" variant="body2" color="text.secondary" >
        <Chip color={props.active || 'primary'} size="small" label={statusText[props.active]} ></Chip>
        <br />
        {<span style={{fontSize: '12px', whiteSpace: 'nowrap'}}>{props.date}</span>}
        <br />
        {props.time ? [props.time, <br />] : null}

        {/* {props.createdBy} */}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={props.active || 'primary'}>
          {activityIcons()[props.activity]}
        </TimelineDot>
        {props.length > 1 && !props.isLast ? <TimelineConnector key={1} /> : null}
        {hasSeparator() && <div style={{position: 'absolute', zIndex: 10, top: 'calc(100%)', width: '100%', left: '50%', transform: 'translateX(-50%)', border: '1px dashed #555'}} key={2}></div>} 
      </TimelineSeparator>
      <TimelineContent sx={{ m: "6px 0 auto 0" }}>
        <Typography style={{fontSize: '13px', display: 'flex'}} variant="h6" component="p">
          {props.title ? props.title.split('/').join('/ ') : props.title} {(props.activityObj?.documents && props.activityObj?.documents?.length > 0) && <AttachFile fontSize="small" />}
        </Typography>
        {props.activity != 5 && props.activity != 8 && <Typography style={{fontStyle: 'italic', fontSize: '11px'}} variant="p">{props.activity === 4 ? getPlantProtectionSubtitle(props.activityObj) : props.activityObj[subtitleFields[props.activity]]}</Typography>}
        {props.activity == 5 && <Typography style={{fontStyle: 'italic', fontSize: '11px'}} variant="p">{props.activity === 4 ? getPlantProtectionSubtitle(props.activityObj) : `${lang.yield_parcel}: ${props.activityObj[subtitleFields[props.activity]]}`}</Typography>}
        {props.activity == 8 && <Typography style={{fontStyle: 'italic', fontSize: '11px'}} variant="p">{lang.num_of_days}: {props.activityObj[subtitleFields[props.activity]]}</Typography>}
        {/* <Typography style={{fontStyle: 'italic', fontSize: '11px'}} variant="p">{subtitleFields[props.activity] === 'plant_protection' ? [...new Set(props.activityObj[subtitleFields[props.activity]].map(obj => obj.protection_type_name))] : props.activityObj[subtitleFields[props.activity]]}</Typography> */}

      </TimelineContent>
    </TimelineItem>
  );
}
