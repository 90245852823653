import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import ListAlt from "@mui/icons-material/ListAlt";
import CalendarMonthIcon from "@mui/icons-material/DateRange";
import EventIcon from "@mui/icons-material/Event";
import Download from "@mui/icons-material/Download";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import moment from 'moment';
import VrtMap from "./VrtMap";
import Drawer from "@mui/material/Drawer";
import ParcelList from "./ParcelList";
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";
import { LoadingButton } from "@mui/lab";
import { Warning } from "@mui/icons-material";
import VRAInputs from "./VRAInputs";
import { CircularProgress, colors } from "@mui/material";

function Vrt(props) {
  const {locale, lang, user, source, vrtParcels, primary, danger, tourGuide, onUpdateState} = useContext(AppContext);

  const downloadLink = useRef(null);

  // const [open, setOpen] = useState(false);
  const [type, setType] = useState('yearly');
  const [date, setDate] = useState(props.dates.length > 0 ? props.dates[props.dates.length - 1] : null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [classesLoading, setClassesLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [format, setFormat] = useState('ESRI_Shapefile');
  const [amount, setAmount] = useMergeState({1: '', 2: '', 3: ''});

  useEffect(() => {
    if(props.zoomToParcel) {
      setSelected([parseInt(props.zoomToParcel)])
      onUpdateState({selectedParcel: null});
    }
  }, [props.zoomToParcel]);

  useEffect(() => {
    onUpdateState({mounted: true})
  }, [])

  const prevDate = () => {
    if(props.dates.length > 0) {
      if(props.dates.indexOf(date) > 0) {
        setDate(props.dates[props.dates.indexOf(date) - 1]);
      }
    }
  }

  const nextDate = () => {
    if(props.dates.length > 0) {
      if(props.dates.indexOf(date) < props.dates.length - 1) {
        setDate(props.dates[props.dates.indexOf(date) + 1]);
      }
    }
  }

  const getClasses = () => {
    let index = type === 'daily' ? 'ndvi' : 'mz';
    setError(false);
    setClassesLoading(true);
    if(selected.length > 0) {
      $data.getQuantiles(selected, index, date, source === 'planet')
        .then((data) => { setData(data); setClassesLoading(false); })
        .catch(err => {
          if(err !== 'cancel') {
            setData([]); setError(true); setClassesLoading(false); 
          }
          });
    } else {
      setData(null);
      setClassesLoading(false); 
      $data.cancelQuantiles();
    }
    
  }

  const getLabel = (index) => {
    if(!data) return '';

    if(data.length === 0) return lang.vrt_no_data;

    return `${data[index - 1].toFixed(2)}${type === 'yearly' ? '%' : ''} / ${data[index].toFixed(2)}${type === 'yearly' ? '%' : ''}`
  }

  const getArea = (index) => {
    if(!data) return '';

    if(data.length === 0) return '';

    return `${data[index - 1].area.toFixed(2)} ha`
  }

  const isDisabled = () => {
    if(selected.length === 0) return true;

    if(amount[1] === '' || amount[2] === '' || amount[3] === '') return true;

    if(!data) return true;

    if(data.length === 0) return true;

    return false
  }

  const exportVRT = () => {
    setError(null);
    setLoading(true);
    $data.exportVRT(selected, date, [data[1], data[2]], [Object.values(amount)], type === 'daily' ? 'ndvi' : 'mz', format)
      .then(data => {
        setLoading(false);
        try {
          downloadLink.current.href = window.URL.createObjectURL(data);
          downloadLink.current.click();
        } catch(e) {
        }
      })
      .catch(err => {setLoading(false)})
  }

  useEffect(() => {
    onUpdateState({view: 'vrt'})
  },[]);

  useEffect(() => { getClasses();}, [date]);

  useEffect(() => { getClasses();}, [type]);

  useEffect(() => { getClasses();}, [selected.toString()]);

  useEffect(() => { setDate(props.dates.length > 0 ? props.dates[props.dates.length - 1] : null) }, [source])

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setError(null);
  }

  const calculateTotalAmount = () => {
    let totalAmount = 0;

    if(!isNaN(parseFloat(amount[1])) && data) {
      totalAmount += (parseFloat(amount[1]) * data[4].area)
    }

    if(!isNaN(parseFloat(amount[2])) && data) {
      totalAmount += (parseFloat(amount[2]) * data[5].area)
    }

    if(!isNaN(parseFloat(amount[3])) && data) {
      totalAmount += (parseFloat(amount[3]) * data[6].area)
    }


    return totalAmount.toFixed(2);
  }

  return (
    <Container className="vrt-container" style={{paddingTop: '20px' ,paddingBottom: tourGuide ? '80px' : 0}}>
      <Grid container spacing={2}>

        {/* TITLE AND PARCEL LIST TOGGLE */}
        <Grid item xs={12}>
          <Stack className="vrt-parcels-toggle-row" direction={"row"} justifyContent="space-between" alignItems="center">
            <Typography style={{color: primary, width: 'calc(100% - 60px'}} gutterBottom variant="h6" component="div">
              {lang.vrt_title}
            </Typography>
            <Fab sx={{zIndex: 1001}} className="vrt-parcels-toggle-row" variant="contained" color="primary" size="small" onClick={() => onUpdateState({vrtParcels: !vrtParcels})}>
              <ListAlt />
            </Fab>
          </Stack>
        </Grid>

        {/* DAILY/MULTIYEAR SOURCES */}
        {user.planet && <Grid item xs={12}>
          <Paper style={{padding: '10px 15px 15px 15px'}} elevation={3}>
            <div className="vrt-source-row" style={{display: 'inline-block'}}>
              <p style={{ color: primary }}>
                {lang.vrt_choose_source}
              </p>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ "& .MuiChip-root": { mr: "10px" } }} >
                <Chip onClick={() => onUpdateState({source: 's2'})} label={'Sentinel-2'} color="primary" variant={source === "s2" ? "filled" : "outlined"} />
                <Chip onClick={() => onUpdateState({source: 'planet'})} color="primary" label={'PLANET'} variant={source === "planet" ? "filled" : "outlined"} />
              </Stack>
            </div>
          </Paper>
        </Grid>}

        {/* DAILY/MULTIYEAR SOURCES */}
        <Grid item xs={12}>
          <Paper style={{padding: '10px 15px 15px 15px'}} elevation={3}>
            <div className="vrt-temporal-row" style={{display: 'inline-block'}}>
              <p style={{ color: primary }}>
                {lang.vrt_source}
              </p>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ "& .MuiChip-root": { mr: "10px" } }} >
                <Chip onClick={() => setType("yearly")} icon={<CalendarMonthIcon />} label={lang.vrt_yearly} color="primary" variant={type === "yearly" ? "filled" : "outlined"} />
                <Chip onClick={() => setType("daily")} color="primary" icon={<EventIcon />} label={lang.vrt_daily} variant={type === "daily" ? "filled" : "outlined"} />
              </Stack>
            </div>
          </Paper>
        </Grid>

        {/* DATE SELECTION AND MAP*/}
        <Grid item xs={12}>
          <Paper className="vrt-date-map-row" style={{ padding: "15px", position: 'relative' }} elevation={3} >
            {type === "daily" && (
              <Stack style={{marginBottom: '10px'}} spacing={2} direction="row" justifyContent="center" alignItems="center" >
                <ArrowBackIcon color="primary" className={'prev-next' + (props.dates.indexOf(date) === 0 ? ' disabled' : '')} onClick={prevDate} />
                <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                  <MobileDatePicker okText={lang.ok} cancelText={lang.cancel}  disabled={!date} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.date} value={moment(date)} onChange={(newValue) => setDate(newValue.format('YYYY-MM-DD'))}
                    shouldDisableDate={(date) => {

                      return props.dates.indexOf(date.format('YYYY-MM-DD')) === -1
                    }}
                    renderInput={(params) => (
                      <TextField error={error} helperText={error || ''} {...params} size="small"
                      style={{background: !date ? 'rgba(0,0,0, .1)' : 'auto'}}
                        InputProps={{endAdornment: ( <InputAdornment position="end"> <EventIcon /> </InputAdornment> ) }} />
                    )}
                  />
                </LocalizationProvider>
                <ArrowForwardIcon color="primary" className={'prev-next' + (props.dates.indexOf(date) === props.dates.length - 1 ? ' disabled' : '')} onClick={nextDate}/>
              </Stack>
            )}
            {error && <Typography variant="body2" style={{color: danger, position: 'absolute', top: '25px', left: '15px'}}><Warning style={{display: 'inline-block', verticalAlign: 'middle'}} fontSize="small" /> No data</Typography>}
            <VrtMap data={data} layer={type === 'daily' ? 0 : 1} date={date} parcels={props.parcels} selected={selected} />
          </Paper>
        </Grid>
        
        {/* INPUT AND EXPORT */}
        <Grid item xs={12}>
          <Paper style={{ padding: "15px" }} elevation={3} >
            <Grid container spacing={2}>
              
              <Grid className="vrt-input-row" item xs={12} sm={6}>
                <p>{lang.vrt_input_title}: ({lang.ie} kg/ha {lang.or.toLowerCase()} l/ha {lang.or.toLowerCase()} t/ha...)</p>
                <Box sx={{ "& .stack-item": { mb: "15px" } }}>
                  <Stack className="stack-item" alignItems={"center"} direction="row" >
                    <span style={{fontSize: '12px', marginRight: '5px', color: 'gray'}}>{getArea(5)}</span>
                    <TextField disabled={data ? data.length === 0 : false} label={getLabel(1)} onChange={(evt) => setAmount({1: evt.target.value})} value={amount[1]} type="number" size="small" 
                      InputProps={{
                        startAdornment: ( <InputAdornment position="start"> <div className="vrt-amount high"></div> </InputAdornment>),
                      }} />
                      
                  </Stack>
                  <Stack className="stack-item" alignItems={"center"} direction="row" >
                    <span style={{fontSize: '12px', marginRight: '5px', color: 'gray'}}>{getArea(6)}</span>
                    <TextField disabled={data ? data.length === 0 : false} label={getLabel(2)} onChange={(evt) => setAmount({2: evt.target.value})} value={amount[2]} type="number" size="small" 
                      InputProps={{
                        startAdornment: ( <InputAdornment position="start"> <div className="vrt-amount medium"></div> </InputAdornment>),
                      }} />
                     
                  </Stack>
                  <Stack className="stack-item" alignItems={"center"} direction="row" >
                    <span style={{fontSize: '12px', marginRight: '5px', color: 'gray'}}>{getArea(7)}</span>
                    <TextField disabled={data ? data.length === 0 : false} label={getLabel(3)} onChange={(evt) => setAmount({3: evt.target.value})} value={amount[3]} type="number" size="small"  
                      InputProps={{
                        startAdornment: ( <InputAdornment position="start"> <div className="vrt-amount low"></div> </InputAdornment>),
                      }} />
                      
                  </Stack>
                </Box>
            
                {getArea(5) ? <p>{lang.total_amount}: {calculateTotalAmount()}</p> : null}
              </Grid>


              {/* <VRAInputs setData={setData} /> */}

              <Grid className="vrt-export-row" item xs={12} sm={6}>
                <p>{lang.vrt_export_title}</p>
                <Stack direction="column">
                  <FormControl>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={format} onChange={(evt) => setFormat(evt.target.value)} name="radio-buttons-group" >
                      <FormControlLabel value="ESRI_Shapefile" control={<Radio />} label="ESRI Shapefile" />
                      <FormControlLabel value="Trimble" control={<Radio />} label="Trimble" />
                      <FormControlLabel value="Amazone" control={<Radio />} label="Amazone" />
                      <FormControlLabel value="Raven" control={<Radio />} label="Raven" />
                      <FormControlLabel value="Ag_Leader" control={<Radio />} label="Ag Leader" />
                      <FormControlLabel value="John_Deere" control={<Radio />} label="John Deere" />
                      <FormControlLabel value="Topcon" control={<Radio />} label="Topcon" />
                      <FormControlLabel value="KMZ" control={<Radio />} label="KMZ" />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <LoadingButton size="small" loading={loading} onClick={exportVRT} disabled={isDisabled()} endIcon={<Download />} variant="contained" style={{ marginTop: "10px" }} >
                  {lang.export}
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Drawer className="parcel-list-drawer-container" anchor={"right"} open={vrtParcels} variant="persistent" ModalProps={{ keepMounted: true }} >
        {/* //TODO DODAJ onChange za checkboxove */}
        <div className="vrt-parcels-open-row">
          <ParcelList selected={selected} onSelect={setSelected} parcels={props.parcels.features} onClose={() => onUpdateState({vrtParcels: false})} />
        </div>
      </Drawer>

      {/* <Message type="error" open={error ? true : false} message={error} onClose={() => onCloseMessage()} /> */}
      <a download="fertilize.zip" ref={downloadLink}></a>
      {classesLoading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        background: 'rgba(0, 0, 0, .6)'
      }}>
        <CircularProgress size={70} color="secondary" sx={{position: 'fixed', top: '40%', left: 'calc(50% - 35px)'}} />
      </div>}
    </Container>
  )
}

export default Vrt;