import { blue } from "@mui/material/colors";
import { i } from "mathjs";
import moment from "moment";
import $seasons from "../../services/$seasons";

export const formatData = (data, names, colors, filter) => {
  let filtered = data;

  if(filter !== 'all' && filter) {
    let {min, max} = $seasons.getMinMaxFromSeasons([filter]);
    let isCurrent = filter === $seasons.getSeasonFromDate(moment().format('YYYY-MM-DD'));
    filtered = data.filter(obj => {
      if(isCurrent) {
        return moment(obj.date).diff(moment(min)) >= 0
      }

      return moment(obj.date).diff(moment(min)) >= 0 && moment(max).diff(moment(obj.date)) >= 0
    })
  }
  
  let datasets = {
    prcp: [],
    tmin: [],
    tmax: []
  };

  filtered.map(obj => {
    datasets.prcp.push({x: obj.date, y: obj.prcp})
    datasets.tmin.push({x: obj.date, y: obj.tmin})
    datasets.tmax.push({x: obj.date, y: obj.tmax})
  })


	return {
    datasets : [{
      data: datasets.tmax,
			label: names[0],
			borderColor: colors[0],
			borderWidth: 1,
			pointBackgroundColor: colors[0],
			pointRadius: 0,
			fill: false,
			spanGaps: true,
      yAxisID: 'y',
		}, {
      data: datasets.tmin,
			label: names[1],
			borderColor: colors[1],
			borderWidth: 1,
			pointBackgroundColor: colors[1],
			pointRadius: 0,
			fill: false,
			spanGaps: true,
      yAxisID: 'y',
		}, {
      data: datasets.prcp,
			label: names[2],
			backgroundColor: colors[2],
      type: 'bar',
      yAxisID: 'p',
		}]
  } 
	
}

export const formatOptions = (lang) => {
  return Object.assign({},{
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      },
      tooltip: {
        callbacks: {
          title: (val) => {
            return moment(val[0].raw.x).format('DD-MMM-YYYY')
          },
          label: (val) => {
            let unit = '';
            if(val.datasetIndex == 0) unit = ' °C';
            if(val.datasetIndex == 1) unit = ' °C';
            if(val.datasetIndex == 2) unit = ' mm';
            
            return `${val.dataset.label}: ${val.parsed.y}${unit}`
          }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        },
        pan: {
          enabled: true,
          threshold: 1,
          mode: 'x',
          modifierKey: 'shift'
        }
      },
    
    },
    interaction: {
      mode: 'nearest',
			axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        title: {text: `${lang.temperature} °C`, display: true, color: 'orange'},
        position: 'left',
				suggestedMin: -30,
				suggestedMax: 50,
				ticks: {
					color: 'orange',
					stepSize: 10
				}
      },
      p: {
        title: {text: `${lang.precipitation} [mm]`, display: true, color: blue[800]},
        position: 'right',
        beginAtZero: true,
        suggestedMax: 80,
        grid: {
          display: false
        },
        ticks: {
          color: blue[800],
          stepSize: 10
        }
      },
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD-MMM-YYYY'
          }
        },
        ticks: {
          autoSkipPadding: 10,
          maxRotation: 0,
        }
       
      }
    },
  })
}