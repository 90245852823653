import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import Message from "../ui/Message";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";

function EditSensor(props) {
  const {lang, sensorsData, onUpdateSensors} = useContext(AppContext);

  const [state, setState] = useMergeState({
    loading: false,
    error: null
  });

  const [model, setModel] = useMergeState({});

  useEffect(() => {
    if(props.selected) {
      let sensorObj = sensorsData.filter(obj => obj.id === props.selected)[0];

      if(sensorObj) {
        setModel({
          id: sensorObj.id,
          name: sensorObj.name,
          description: sensorObj.description,
          type: sensorObj.type,
        })
      } else {
        setModel(null)
      }

    } else {
      setModel(null)
    }
    

  }, [props.selected])

  const onEditSensor = () => {
    setState({loading: true, error: null})

    $data.patchSensor(model.id, {description: model.description})
      .then(data => {
        setState({loading: false});
        onUpdateSensors();
        props.onSuccess();
      })
      .catch(err => {
        setState({loading: false, error: err})
      })
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: null});
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.edit_sensor}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12} md={6}>
              <TextField focused sx={{'& .MuiOutlinedInput-input': {pointerEvents: 'none', userSelect: 'none', caretColor: 'transparent' }}} readOnly fullWidth size="small" label={lang.sensor_name} value={model?.name}></TextField>
						</Grid>

            <Grid item xs={12} md={6}>
              <TextField focused sx={{'& .MuiOutlinedInput-input': {pointerEvents: 'none', userSelect: 'none', caretColor: 'transparent' }}} readOnly fullWidth size="small" label={lang.sensor_type} value={model?.type}></TextField>
						</Grid>
            <Grid item xs={12}>
              <TextField fullWidth size="small" multiline label={lang.sensor_description} value={model?.description || ''} onChange={(evt) => setModel({description: evt.target.value})}></TextField>
						</Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color={props.confirmColor || "primary"} variant="contained" size="small" loading={state.loading} onClick={onEditSensor}>{props.confirmText || lang.confirm}</LoadingButton>
        <LoadingButton color={props.closeColor || "error"} variant="contained" size="small" loading={state.loading} onClick={props.onClose}>{props.closeText || lang.close}</LoadingButton>
      </DialogActions>

      <Message type="error" open={state.error?.non_field_errors ? true : false} message={state.error?.non_field_errors} onClose={onCloseMessage} />

    </Dialog>
  )
}

export default EditSensor;