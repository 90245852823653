import { useContext, useEffect, useRef } from "react";
import olMap from 'ol/Map';
import { AppContext } from "../../AppContext";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import $map from "../../services/$map";
import { basemaps } from "../../config/map";
import { Paper, Stack } from "@mui/material";
import { unByKey } from "ol/Observable";
import { map } from "lodash";

function SensorsMap({selected, onSetSelected}) {
  const {sensorsData} = useContext(AppContext)

  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const sensorLayer = useRef(null);
  const keys = useRef([]);

  useEffect(() => {
    sensorLayer.current = $map.sensorLayer(sensorsData, true);


    mapInstance.current = new olMap({
      target: mapRef.current,
      pixelRatio: 1,
      layers: [basemaps('sat')[0], sensorLayer.current],
      view: new View({
        zoom: 4,
        center: fromLonLat([20, 40])
      })
    });

    mapInstance.current.getView().fit(sensorLayer.current.getSource().getExtent(), {padding: [20, 20, 20, 20]});

    keys.current.push(mapInstance.current.on('singleclick', (evt) => {
      let feature = evt.map.getFeaturesAtPixel(evt.pixel)[0];

      if(feature) {
        onSetSelected(feature.get('id'));
      } else {
        onSetSelected(null);
      }
    }));

    keys.current.push(mapInstance.current.on('pointermove', (evt) => {
      let feature = evt.map.getFeaturesAtPixel(evt.pixel)[0];

      if(feature) {
        mapRef.current.style.cursor = 'pointer'
      } else {
        mapRef.current.style.cursor = 'initial'
      }
    }));

    return () => {
      keys.current.map(key => {
        unByKey(key);
      })
    }
  }, [])

  useEffect(() => {
    if(mapInstance.current) {
      if(selected) {
        sensorLayer.current.getSource().getFeatures().map(f => {
          if(f.get('id') === selected) {
            f.setStyle($map.sensorSelectedStyle);
            mapInstance.current.getView().animate({center: f.getGeometry().getCoordinates(), duration: 300}, {zoom: 15, duration: 300})
          } else {
            f.setStyle($map.sensorStyle)
          }
        })
      } else {
        sensorLayer.current.getSource().getFeatures().map(f => {
          f.setStyle($map.sensorStyle)
        })
      }
    }
  }, [selected])

  if(mapInstance.current) {
    setTimeout(() => {
      mapInstance.current.updateSize();

    })
  }

  return <Stack component={Paper} elevation={3} style={{width: '100%', height: '100%', borderRadius: '4px', overflow: 'hidden', minHeight: '400px'}} sx={{'& .ol-zoom': {display: 'none'}, '& .ol-attribution ul': {display: 'block'}, '& .ol-attribution button': {display: 'none'}}} ref={mapRef}></Stack>
}

export default SensorsMap;