import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Download from "@mui/icons-material/Download";
import FilterAlt from "@mui/icons-material/FilterAlt";
import FilterAltOff from "@mui/icons-material/FilterAltOff";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import Divider from "../auth/Divider";
import FieldItem from "./FieldItem";
import colors from '../../config/colors';
import $data from "../../services/$data";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { Checkbox, ListItem, ListItemText, Paper } from "@mui/material";
import useMergeState from "../hooks/useMergeState";
import EmployeesModal from "./EmployeesModal";
import MachinesModal from "./MachinesModal";
import ConnectedMachinesModal from "./ConnectedMachinesModal";
import JobOrders from "./job-orders/JobOrders";
import ExportFieldBook from "./ExportFieldBook";
import { Add, Event, Folder, Workspaces } from "@mui/icons-material";
import DocumentsModal from "./DocumentsModal";
import SoilSamples from "./SoilSamples";
import AddField from "./field/AddField";
import Message from "../ui/Message";


function FieldBook(props) {
  const containerRef = useRef(null)

  const {lang, cropTypes, cropGroups, primary, activityTypes, onUpdateState, onUpdateParcels} = useContext(AppContext);

  const [showFilters, setShowFilters] = useState(true);

  const [filter, setFilter] = useMergeState({name: '', crop: [], type: ''});

  const [filteredParcels, setFiltered] = useState(null);

  const [multiply, setMultiply] = useState(1);
  
  const [addFieldSuccess, setAddFieldSuccess] = useState(null);

  const [modal, setModal] = useMergeState({addField: false, employees: false, machines: false, connectedMachines: false, jobOrders: false, export: false, documents: window.location.search.split('?')[1] === 'documents'});

  let counter = 1;

  useEffect(() => {
    onUpdateState({view: 'fieldbook', mounted: true})
    containerRef.current.addEventListener('scroll', handleScroll);
  },[]);

  const onAddParcel = () => {
    setModal({addField: false});
    setAddFieldSuccess(lang.success_add_field);
    onUpdateParcels();
  }


  const handleScroll = (evt) => {
    if((evt.target.scrollHeight - evt.target.scrollTop) <= containerRef.current.offsetHeight + 260) {
      if(counter * 30 < props.parcels.features.length) {
        counter += 1;
        setMultiply(counter);
      }
    }
  }


  const onFilterCrops = (evt) => {
   
    let {value} = evt.target;

    if(value.constructor === String) {
      setFilter({crop: value.split(',')});
    }

    if(value.constructor === Array) {
      setFilter({crop: value.indexOf('all') > -1 ? [] : value});
    }
  }

  const onFilterTypes = (evt) => {
    let {value} = evt.target;

    setFilter({type: value});
    
    if(value === '') {
      setFiltered(null);
      
    } else {
      $data.getFilteredParcels(`?activity=${value}`)
        .then(data => setFiltered(data))
        .catch(data => setFiltered([]));
    }

  }


  const renderParcels = () => {
    const {features} = props.parcels;

    if(features.length === 0) {
      return (
        <Grid item xs={12}>
          <p>{lang.no_fields}</p>
        </Grid>
      )
    }

    let filtered = features
      .filter(obj => {
        if(filteredParcels) {
          if(filteredParcels.indexOf(obj.properties.pk) > -1) {
            return true
          } else {
            return false
          }
        }

        return true
      })
      .filter(obj => {
        let {pk, name, activeCrop} = obj.properties;
        let field = name.toLowerCase().trim();

        if(filter.name === '' && filter.crop.length === 0) return true;

        if(filter.name !== '' && filter.crop.length === 0) {
          if(field.indexOf(filter.name.toLowerCase().trim()) > -1) {
            return true
          }
        }

        if(filter.name === '' && filter.crop.length > 0) {
          if(filter.crop.indexOf($data.getCropGroupIDByCrop(activeCrop.crop, cropTypes)) > -1) return true
        }

        if(filter.name !== '' && filter.crop.length > 0) {
          
          if(filter.crop.indexOf($data.getCropGroupIDByCrop(activeCrop.crop, cropTypes)) > -1 && field.indexOf(filter.name.toLowerCase().trim()) > -1) {
            return true
          }
        }

      
        
        return false
      })

    if(filtered.length === 0) {
      return (
        <Grid item xs={12}>
          <h4 style={{color: primary}}>{lang.fld_no_filtered_parcels}</h4>
        </Grid>
      ) 
    }

    console.log(filtered)
    
    let list = filtered
      .filter((obj, key) => {
        return key <= multiply * 30
      })
      .map((obj, key) => {
      let {properties} = obj;

      return (
        <Grid className={"fieldbook-field-" + (key + 1)} key={key} item xs={12} sm={6} md={4} lg={3}>
          <FieldItem parcel={properties.pk} geometry={obj} image={colors.groupImages[$data.getCropGroupIDByCrop(properties.activeCrop.crop, cropTypes)]} groupID={$data.getCropGroupIDByCrop(properties.activeCrop.crop, cropTypes)} name={properties.name} crop={$data.getCropById(cropTypes, properties.activeCrop.crop)} area={properties.area} />
        </Grid>
      )
    })

    

    return list
  }
  
  return (
    <Container ref={containerRef} style={{paddingTop: '20px'}} className="fieldbook-container">
      <Grid sx={{mb: '15px'}} container spacing={2}>

        {/* TITLE AND EXPORT */}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography style={{ width: "calc(100% - 60px)" }} color="primary" variant="h6" component="div" >
              {lang.fld_title}
            </Typography>
            <Tooltip title={lang.fld_download_btn} arrow>
              <Fab className="fieldbook-export-row" onClick={() => setModal({export: true})} size="small" color="primary" aria-label="export" > <Download /> </Fab>
            </Tooltip>
          </Stack>
        </Grid>

        {/* FILTERS AND MANAGEMENT */}
        <Grid className="fieldbook-button-group" item xs={12}>
          <Stack direction="row" flexWrap="wrap" sx={{'& button': {mb: '5px'}}}>
            <Button className="fieldbook-filter-row" onClick={() => setShowFilters(!showFilters)} size="small" variant="contained" endIcon={showFilters ? <FilterAltOff /> : <FilterAlt />}>{lang.fld_filters}</Button>
            <Button className="fieldbook-employees-row" onClick={() => setModal({employees: true})} size="small" variant="contained">{lang.employees}</Button>
            <Button className="fieldbook-machines-row" onClick={() => setModal({machines: true})} size="small" variant="contained">{lang.fld_machines}</Button>
            <Button className="fieldbook-cmachines-row" onClick={() => setModal({connectedMachines: true})} size="small" variant="contained">{lang.fld_con_machines}</Button>
            <Button className="fieldbook-activities-row" onClick={() => setModal({jobOrders: true})} size="small" variant="contained" endIcon={<Event />}>{lang.act_activities}</Button>
            <Button className="fieldbook-documents-row" onClick={() => setModal({documents: true})} size="small" variant="contained" endIcon={<Folder />}>{lang.act_documents}</Button>
            <Button className="fieldbook-samples-row" onClick={() => setModal({samples: true})} size="small" variant="contained" endIcon={<Workspaces />}>{lang.soil_measurements}</Button>
          </Stack>
          
        </Grid>

        {showFilters && <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <TextField value={filter.name} onChange={(evt) => setFilter({name: evt.target.value})} size="small" fullWidth label={lang.fld_filter_by_name} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} >            
              <FormControl size="small" fullWidth>
                <InputLabel id="filtercrop">{lang.fld_filter_by_cropgroup}</InputLabel>
                <Tooltip placement="top" title={filter.crop.map(id => $data.getCropGroupByID(id, cropGroups).toUpperCase()).join(', ')}>
                  <Select 
                    multiple size="small" labelId="filtercrop" 
                    label={lang.fld_filter_by_cropgroup} value={filter.crop} 
                    onChange={onFilterCrops}
                    renderValue={(selected) => selected.map(id => $data.getCropGroupByID(id, cropGroups).toUpperCase()).join(', ')} fullWidth>
                      <MenuItem value={'all'}>
                        <Checkbox checked={filter.crop.length === 0} />
                        <ListItemText primary={lang.fld_select_all} />
                      </MenuItem>
                    {cropGroups.map((obj, key) => {
                      
                      return (
                        <MenuItem key={key} value={obj.id}>
                          <Checkbox checked={filter.crop.indexOf(obj.id) > -1} />
                          <ListItemText primary={obj.name.toUpperCase()} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Tooltip>
              </FormControl>
            
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl size="small" fullWidth>
                <InputLabel id="filtertype">{lang.fld_filter_by_activity}</InputLabel>
                <Select 
                  size="small" labelId="filtertype" 
                  label={lang.fld_filter_by_activity} value={filter.type} 
                  onChange={onFilterTypes}
                  fullWidth>
                    <MenuItem value={''}>{lang.fld_select_all_types}</MenuItem>
                    {activityTypes.map((obj, key) => <MenuItem key={key} value={obj.id}>{obj.name}</MenuItem>)}
                  </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>}

        <Grid item xs={12}>
          <Divider style={{width: '100%', height: '3px'}} />
        </Grid>
      </Grid>
      
      {/* PARCELS */}
      <Grid className="fieldbook-fields-row" container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Fab onClick={() => setModal({addField: true})} size="small" children={<Add />} color="primary"/> <span className="text-primary">{lang.add_field}</span>
          </Stack>
        </Grid>
        {renderParcels()}
      </Grid>
      <AddField open={modal.addField} onClose={() => setModal({addField: false})} onSuccess={onAddParcel} />
      <EmployeesModal open={modal.employees} onClose={() => setModal({employees: false})} />
      <MachinesModal open={modal.machines} onClose={() => setModal({machines: false})} />
      <ConnectedMachinesModal open={modal.connectedMachines} onClose={() => setModal({connectedMachines: false})} />
      <JobOrders open={modal.jobOrders} onClose={() => setModal({jobOrders: false})} />
      <ExportFieldBook open={modal.export} onClose={() => setModal({export: false})} />
      <DocumentsModal open={modal.documents} onClose={() => setModal({documents: false})} />
      <SoilSamples open={Boolean(modal.samples)} onClose={() => setModal({samples: false})} />

      <Message type="success" open={addFieldSuccess ? true : false} message={addFieldSuccess} onClose={() => setAddFieldSuccess(null)} />

    </Container>
  )
}

export default FieldBook;