import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Circle, Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function PrivacyPolicy(props) {
  const {lang, primary} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.privacy_policy}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={0}>
            <Grid sx={{textAlign: 'center', marginBottom: '10px'}} item xs={12}>
              <a href="https://gilab.rs" target="_blank"><img src="/gilab-logo.svg" width="240px" /></a>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Politika privatnosti</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>
              Gilab DOO Beograd (u daljem tekstu navodi se i kao „GILAB”,  „mi”,  „naš”) ceni Vaše interesovanje za našu kompaniju i zahvaljujemo Vam na poseti naše veb aplikacije ili korišćenju naše mobilne aplikacije. GILAB veoma ozbiljno pristupa zaštiti Vaših podataka o ličnosti. Vašim podacima o ličnosti rukujemo poverljivo i u skladu sa zakonskim propisima o zaštiti podataka i Politikom privatnosti koja je data na ovom vebsajtu. U daljem tekstu, naći ćete informacije o tome koje podatke pohranjujemo i kada, kao i kako koristimo te podatke. 
              </p>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Rukovalac podacima o ličnosti</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>Rukovalac podacima, u smislu čl. 4 stav 1 tačka 8) Zakona o zaštiti podataka o ličnosti Republike Srbije, je GILAB d.o.o., Dragoslava Srejovića 94, Beograd, Srbija </p>
              <p>e-mail: <a href="mailto:privacypolicy@gilab.rs">privacypolicy@gilab.rs</a> </p>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Svrha i zakonski osnov obrade</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>
                <strong>a) Informativna poseta našem vebsajtu</strong>  
              </p>
              <p>
                U slučaju da ste isključivo informativno na vebsajtu, drugim rečima, ako se ne registrujete ili nam na drugi način ne dostavite svoje podatke, prikupićemo samo podatke o ličnosti koje Vaš pretraživač pošalje našem serveru.
              </p>
              <p>
                Ukoliko želite da pregledate vebsajt, obradićemo sledeće podatke, koji su nam tehnički neophodni kako bismo mogli da Vam prikažemo sadržaj vebsajta i obezbedimo stabilnost i bezbednost:              
              </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="IP adresa" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Datum i vreme zahteva" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Razlika u vremenskoj zoni u odnosu na srednje vreme po Griniču (GMT)" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Sadržaj zahteva (konkretan sajt)" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Status pristupa/HTTP kod statusa" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Količina podataka koja je svaki put preneta" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Vebsajt s kojeg je zahtev stigao" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pretraživač" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Operativni sistem i njegov interfejs" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Jezik i verzija softvera pretraživača." />
                </ListItem>
              </List>
              <p>
                Ove informacije se analiziraju isključivo u statističke svrhe.
              </p>
              <p>
                Pravni osnov obrade je čl. 12 stav 1 tačka 6) Zakona o zaštiti podataka o ličnosti. Legitimni interes koji nastojimo da ostvarimo jeste pružanje naših onlajn usluga i obezbeđenje bezbednosti podataka.              
              </p>
						</Grid>
            <Grid item xs={12}>
              <p>
                <strong>b) CropLab</strong>
              </p>
              <p>
              Na našoj veb aplikaciji Vam nudimo mogućnost da se registrujete na našu platformu za podršku odlučivanju i upravljanju podacima i aktivnostima o usevima, platforma „CropLab”. Možete takođe da koristite i mobilnu aplikaciju, CropLab mobilna  aplikacija. U nastavku Vas obaveštavamo o obradi vaših ličnih podataka kada koristite CropLab. 
              </p>
              <p style={{color: primary, fontWeight: '500'}}>I. Registracija na CropLab platformi ili mobilnoj aplikaciji </p>
              <p> CropLab omogućava korisnicima pristup informacijama o ključnim indikatorima koji ukazuju na stanje useva. </p>
              <p>Registracija je neophodna za pristup ovoj usluzi i informacijama.</p>
              <p>U okviru Vaše registracije kreiramo korisnički profil. U tom procesu prikupljamo podatke koje zahtevamo od Vas kako bismo dodelili i upravljali korisničkim nalogom.</p>
              <p>Pravni osnov za obradu je čl. 12 stav 1 tačka 2) Zakona o zaštiti podataka o ličnosti.</p>
              <p>Vaši podaci će biti izbrisani 30 dana nakon prestanka korisničkog odnosa i nakon isteka bilo kog zakonskog perioda zadržavanja.</p>

              <p style={{color: primary, fontWeight: '500'}}>II. Podaci za evidenciju i upravljanje farmom </p>
              <p>Ovaj servis (knjiga polja) omogućava unos i upravljanje podacima o proizvodnji (kultura, sorta, datum setve, vrsta đubriva, itd.) za svako polje u sistemu. CropLab pruža korisnicima priliku da lako dodaju i čuvaju podatke o njihovim poljima na jednom mestu, a zatim i da im lako i brzo pristupe uz dodatne analitičke funkcionalnosti koje sistem nudi.</p>
              <p>Pravni osnov za obradu je čl. 12 stav 1 tačka 2) Zakona o zaštiti podataka o ličnosti.</p>
              <p>Vaši podaci će biti izbrisani 30 dana nakon prestanka korisničkog odnosa i nakon isteka bilo kog zakonskog perioda zadržavanja.</p>

              <p style={{color: primary, fontWeight: '500'}}>III. Funkcije zasnovane na satelitskim snimcima</p>
              <p>Monitoring useva <br/> <br/>
              CropLab pruža podatke o 2 biofizička parametra: vegetacioni indeks normalizovanih razlika (NDVI) i indeks hlorofila (CHL). Na osnovu biofizičkih parametara koji omogućavaju da se stanje useva analizira, CropLab Vam pruža pravovremenu informaciju kada je potrebno reagovati. Jedan od primera je NDVI indeks koji omogućava poljoprvrednicima i agronomima da prepoznaju promene na usevima do dve nedelje pre nego što se može primetiti golim okom.
              </p>
              <p>Varijablni tretman <br/> <br/>
              Menadžment zone predstavljaju oblasti unutar parcele koje imaju različitu produktivnost. Ovaj servis podržava metode precizne poljoprivrede. To se odnosi na: optimalno uzorkovanje kod analize zemljišta, efikasnu upotrebu inputa, različite obrade unutar polja. Ovaj servis pruža  mogućnost poljoprivredniku da dobije kartu za primenu varijabilnog tretiranja (npr. đubrenja ili sejanja) kompatibilnu sa svojom mehanizacijom.              </p>
              <p>Pravni osnov za obradu je čl. 12 stav 1 tačka 2) Zakona o zaštiti podataka o ličnosti.</p>
              <p>Podaci na vašoj lokaciji će biti izbrisani 30 dana nakon prestanka korisničkog odnosa i nakon isteka bilo kog zakonskog perioda zadržavanja.</p>

              <p style={{color: primary, fontWeight: '500'}}>IV. Analiza korišćenja </p>
              <p>Ako ste nam dali svoju saglasnost, mi prikupljamo i analiziramo osnovne podatke o vašem korisničkom ponašanju kako bismo poboljšali naše usluge i učinili ih privlačnijim.</p>
              <p>Vaši podaci o korišćenju biće zabeleženi i procenjeni samo uz vašu saglasnost.</p>
              <p>Pravni osnov obrade je čl. 12 stav 1 tačka 1) Zakona o zaštiti podataka o ličnosti (tj. Vaš pristanak).</p>
              <p>Svoju saglasnost možete povući u bilo kom trenutku slanjem imejla na: <a className="text-link" href="mailto:team@gilab.rs">team@gilab.rs</a></p>
              <p>Vaši podaci će biti izbrisani 30 dana nakon prestanka korisničkog odnosa i nakon isteka bilo kog zakonskog perioda zadržavanja.</p>

              <p style={{color: primary, fontWeight: '500'}}>V. Pristup kameri radi korišćenja funkcija</p>
              <p>Aplikacija CropLab zahteva pristup kameri Vašeg uređaja radi korišćenja različitih funkcija, ako želite da koristite ove funkcije. Ovo vam na primer, omogućava da prebacujete slike direktno u aplikaciju i da ponovo dobijete dodatne informacije.   </p>
              <p>Pravni osnov za obradu je čl. 12 stav 1 tačka 2) Zakona o zaštiti podataka o ličnosti.</p>
              <p>Ako niste prijavljeni u vreme skeniranja ili ako kasnije kreirate svoj CropLab nalog, Vaša lokacija će nakon toga biti dodata na vaš CropLab nalog, pod uslovom da ste dali svoju saglasnost za lokalizaciju u API pregledaču. </p>
              <p>Pravna osnova za lokalizaciju su čl. 12 stav 1 tačka 1) Zakona o zaštiti podataka o ličnosti i čl. 12 stav 1 tačka 2) Zakona o zaštiti podataka o ličnosti, a za naknadnu vezu. </p>
              <p>Vaši podaci će biti izbrisani 30 dana nakon prestanka korisničkog odnosa i nakon isteka bilo kog zakonskog perioda zadržavanja.</p>
              <p>Ako je obrada zasnovana na vašoj saglasnosti, izbrisaćemo vaše podatke nakon povlačenja vaše saglasnosti, osim ako je dalja obrada dozvoljena u skladu s relevantnim zakonskim odredbama.</p>

            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>c) Kontaktiranje korisnika</strong>
              </p>
              <p>Zatim, mi koristimo i obrađujemo Vaše podatke za kontakt, npr. imejl adresa ili broj telefona, kako bismo Vam preneli, koristeći metode za komunikaciju koje ste odabrali (imejl ili telefon) o našim proizvodima, uslugama i specijalnim ponudama.</p>

              <p>Pravni osnov obrade je čl. 12 stav 1 tačke 1) (tj. Vaš pristanak) ili 6) Zakona o zaštiti podataka o ličnosti (tj. legitimni interes). Legitimni interes koji nastojimo da ostvarimo jeste komunikacija s našim korisnicima. </p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>d) Praćenje</strong>
              </p>
              <p>Ovaj vebsajt koristi različite tehnologije da prikupe i sačuvaju podatke za potrebe marketinga i optimizacije. Ovi podaci se mogu koristiti za kreiranje korisničkih profila pod pseudonimom.</p>
              <p>Ovaj vebsajt koristi Google Analytics, servis za internet analizu kompanije Google LLC (“Google”). Informacije koje generiše cookie (tj. “kolačić”) kad koristite ovaj vebsajt se, po pravilu, prenose na Google server u SAD i tamo se čuvaju. Kao deo anonimizacije IP adrese, Google najpre skraćuje Vašu IP adresu u državama članicama EU ili drugim zemljama koje su potpisnice Sporazuma o Evropskoj ekonomskoj zoni. Samo u izuzetnim slučajevima se cela IP adresa prenosi na Google server u SAD i onda tamo skraćuje. U ime operatera ovog vebsajta, Google će koristiti ove informacije kako bi procenio Vaše korišćenje vebsajta, kako bi sastavio izveštaje o aktivnostima na vebsajtu i kako bi operateru vebsajta pružao dalje usluge povezane s upotrebom vebsajta i Interneta. IP adresa koju prenosi Vaš pretraživač u kontekstu korišćenja Google Analytics ne kombinuje se s drugim podacima koje poseduje Google.</p>
              <p>Više informacija o uslovima korišćenja i zaštiti podataka možete pronaći:<br/> <a className="text-link" href="https://policies.google.com/terms?hl=sr" target="_blank">https://policies.google.com/terms?hl=sr</a> ili na <a className="text-link" href="https://policies.google.com/privacy?hl=sr" target="_blank">https://policies.google.com/privacy?hl=sr</a>. Napominjemo da je na ovom vebsajtu Google Analytics proširena kodom “anonymizeIp” kako bi se garantovalo anonimizirano prikupljanje IP adresa (“Maskiranje IP adresa”).</p>
              <p>Pravni osnov obrade je čl. 12 stav 1 tačka 6) Zakona o zaštiti podataka o ličnosti. Legitimni interesi koje nastojimo da ostvarimo jesu direktni marketing i unapređenje naših onlajn usluga.</p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>e) Bilten</strong>
              </p>
              <p>Imejl biltene ("e-mail newsletters") Vam šaljemo samo ako ste se izričito naručili konkretni bilten ("newsletter") na našem vebsajtu. Ako više ne želite da primate imejl biltene, možete se odjaviti pomoću linka koji je dat na kraju svakog biltena.</p>
              <p>Pravni osnov obrade je čl. 12 stav 1 tačka 1) Zakona o zaštiti podataka o ličnosti (tj. Vaš pristanak). </p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>f) Preporuke na Facebook-u, G+-u, Twitter-u, imejlu i WhatsApp-u</strong>
              </p>
              <p>Lako možete podeliti informacije o vebsajtu GILAB/CropLab na Facebook-u, G+-u, Twitter-u, imejlom ili preko WhatsApp-a pomoću relevantnih dodatnih modula ("plugins"). Ovi “Dodatni moduli sa socijalnim obeleživačima” (tj. "social bookmark pligins") su Internet obeleživači pomoću kojih korisnici ove vrste usluga mogu prikupljati linkove i novosti. Na vebsajtu GILAB se oni koriste samo kao link ka predmetnim servisima. Tek pošto kliknete na pripadajuću oznaku, prebacujete se na vebsajt odgovarajućeg provajdera, tj. tek se onda Vaše korisničke informacije prenose odgovarajućem provajderu. Informacije o rukovanju Vašim podacima o ličnosti na tim vebsajtovima možete pronaći u izjavama o zaštiti podataka o ličnosti tih provajdera.</p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">"Kolačići" (Cookies)</Typography>
              <p>
              Mi koristimo “kolačiće” na našim vebsajtovima. “Kolačići” su male tekstualne datoteke koje se mogu uneti na Vaš hard disk kad posetite neki vebsajt. Ove tekstualne datoteke se kreiraju i šalju Vam se preko servera na koji ste se povezali preko vašeg pretraživača (npr. Internet Explorer, Firefox, Google Chrome). “Kolačići” ne mogu da pokrenu nikakve programe niti da zaraze Vaš računar virusima. Njihova svrha je da ceo Internet servis bude lakši za korišćenje i efikasniji. 
              </p>
              <p>
              Naši vebsajtovi koriste sledeće vrste kolačića. Njihov obim i kako funkcionišu objašnjeni su na sledeći način: 
              </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Privremeni kolačići (a)" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Trajni kolačići (b)" />
                </ListItem>
              </List>
              <p>a) Privremeni kolačići se automatski brišu kad zatvorite pretraživač. Ovde posebno spadaju kolačići za sesiju. Oni sadrže “Oznaku sesije“ (session ID) pomoću koje se različiti zahtevi koje pošalje Vaš pretraživač mogu dodeliti celoj sesiji. To znači da će se Vaš računar prepoznati kad se vratite na naš vebsajt. Kolačići za sesiju se brišu kad se odjavite ili zatvorite pretraživač.</p>
              <p>b) Trajni kolačići se automatski brišu nakon unapred utvrđenog perioda, koji može varirati u zavisnosti od kolačića. Kolačiće možete obrisati u svakom trenutku u podešavanjima bezbednosti Vašeg pretraživača.</p>
              <p>c) Podešavanja pretraživača možete podesiti kako god želite i, na primer, možete isključiti opciju za dobijanje kolačića trećih strana ili svih kolačića. Međutim, napominjemo da Vas to može sprečiti da koristite sve funkcije ovog vebsajta.</p>
              <p>d) Fleš kolačiće koje koristimo ne registruje Vaš pretraživač, već Vaš fleš dodatni modul ("flash plugin"). Mi koristimo i HTML5 objekte za skladištenje koji se postavljaju na Vaš terminal. Ti objekti čuvaju neophodne podatke bez obzira na pretraživač koji koristite i nemaju datum automatskog isteka. Ako ne želite da se fleš kolačići obrađuju, morate instalirati dodatak za to, npr. “Ghostery” za Mozilla Firefox (https://addons.mozilla.org/de/firefox/addon/ghostery/) ili Adobe-Flash-Killer-Cookie za Google Chrome. Korišćenje HTML5 objekata za skladištenje možete sprečiti i ako pretraživač koristite u privatnom režimu.</p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Period čuvanja</Typography>
              <p>Generalno, Vaše podatke skladištimo samo onoliko koliko je neophodno da izvršimo usluge koje ste tražili ili na koje ste pristali. Ukoliko postoji zakonski rok čuvanja, Vaše podatke skladištimo u skladu s tim zakonskim zahtevima.</p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Vaša prava</Typography>
              <p>Vi imate sledeća prava u odnosu na nas u vezi s Vašim podacima o ličnosti pod uslovom da su ispunjeni odgovarajući zakonski zahtevi:</p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na pristup," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na ispravku i dopunu," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na brisanje," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na ograničenje obrade podataka," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na prenosivost podataka," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na obaveštenje o ispravci ili dopuni Vaših podataka o ličnosti, ograničenju obrade i povredi podataka o ličnosti," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Pravo na informisanost da li je davanje podataka o ličnosti zakonska ili ugovorna obaveza ili je davanje podataka neophodan uslov za zaključenje ugovora, kao i o tome da li lice na koje se podaci odnose ima obavezu da da podatke o svojoj ličnosti i o mogućim posledicama te obrade po lice na koje se ti podaci odnose" />
                </ListItem>
              </List>
              <p><strong>Pravo na prigovor</strong></p>
              <p>Pojedinačno pravo na prigovor <br/> <br/>Vi imate pravo da u bilo kom trenutku podnesete prigovor, po osnovu koji se odnosi na Vašu konkretnu situaciju, obradi podatka o ličnosti koji se odnose na Vas na osnovu čl. 12 (1) 6) Zakona o zaštiti podataka o ličnosti (tj. legitimni interes), uključujući i profilisanje na osnovu tih odredbi. U tom slučaju više ne obrađujemo podatke o ličnosti u te svrhe osim u slučaju da možemo da dokažemo uverljiv legitiman osnov za obradu koji pretežu nad Vašim interesima, pravima i slobodama ili ako obrada služi za podnošenje, ostvarivanje ili odbranu pravnog zahteva. </p>
              <p>Pravo na prigovor obradi za potrebe direktnog marketinga <br /> <br /> U nekim slučajevima Vaše podatke obrađujemo radi direktnog marketinga. U svakom trenutku imate pravo da podnesete prigovor na obradu Vaših podataka o ličnosti u te svrhe. Ovo se odnosi na profilisanje u meri u kojoj je isto povezano s tim direktnim marketingom. Ako prigovorite obradi za potrebe direktnog marketinga, Vaši podaci o ličnosti se više neće obrađivati u te svrhe.</p>
              <List> 
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Opoziv pristanka: Ako ste pristali na obradu podataka o ličnosti (uključujući za svrhe direktnog marketinga), možete opozvati pristanak u svakom trenutku, to povlačenje ima buduće dejstvo. To neće uticati na zakonitost obrade podataka o ličnosti dok niste pozvali Vaš pristanak na obradu." />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Ne koristimo automatizovao donošenje odluka, uključujući i profilisanje, na način predviđen Zakonom o zaštiti podataka o ličnosti." />
                </ListItem>
              </List>
              <p>Ako želite iskoristite navedena prava, obratite nam se pomoću gore navedenih kontakt podataka.</p>

              <p>Takođe imate i pravo da podnesete pritužbu nadzornom organu za zaštitu podataka o ličnosti, ako je, po Vašem mišljenju, obrada Vaših podataka o ličnosti nezakonita. U Srbiji, možete podneti pritužbu Povereniku za informacije od javnog značaja i zaštiti podataka o ličnosti Republike Srbije, Bulevar kralja Aleksandra 15, Beograd, Srbija, email: <a className="text-link" href="mailto:office@poverenik.rs">office@poverenik.rs</a> .</p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Eksterni linkovi</Typography>
              <p>Naš onlajn servis povremeno sadrži linkove koji vode na vebsajtove trećih lica. Ukoliko to nije lako uočljivo, naznačićemo da se radi o eksternom linku. GILAB nema uticaja na sadržaj ili formu vebsajtova eksternih provajdera. Stoga, ova Politika privatnosti nije relevantna na tim linkovima.</p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Izmene Politike privatnosti</Typography>
              <p>Zadržavamo pravo na izmenu ove Politike privatnosti s vremena na vreme. U tom pogledu, molimo Vas da pogledate važeću verziju naše Politike privatnosti.</p>
            </Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default PrivacyPolicy;