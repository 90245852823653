import { Close, Event } from "@mui/icons-material";
import { Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
import { blue } from "@mui/material/colors";
import $seasons from "../../../services/$seasons";
import $data from "../../../services/$data";
import Message from "../../ui/Message";
import colors from "../../../config/colors";
import $map from "../../../services/$map";

function AddParcel(props) {
	const {lang, cropTypes, user, parcels, onUpdateState} = useContext(AppContext);

	const [model, setModel] = useMergeState({
		name: '',
		geometry: null
	});

	const [state, setState] = useMergeState({ error: {}, loading: false });

	const getTotalArea = () => {
		let area = 0;
		parcels.features.map(f => (area += f.properties.area));
		return area;
	}

	useEffect(() => {
		if(props.open) {
			setModel({geometry: new WKT().writeFeature(props.feature)});
		}

		if(!props.open) {
			setModel({ crop: 13, sowing: null, harvest: null, name: '', geometry: null })
			setState({loading: false, error: {}})
		}
	}, [props.open]);

	const postParcel = () => {
		setState({loading: true, error: {}})
		const total = getTotalArea();
		const isAgricapture = user.billing_plan === 2;

		$data.postParcel(model)
			.then(data => {
				setState({loading: false});
				if(total + data.properties.area > 100 && !isAgricapture) {
					onUpdateState({pricingOpened: true})
				}
				props.onSuccess();
			})
			.catch(err => {
				if(err.constructor === String || err.constructor === Array) {
					setState({error: {non_field_errors: err}, loading: false})
				} else {
					setState({error: err, loading: false})
				}
			})
	}

	const renderGeometry = () => {
		if(!props.feature) return null;
		let f = new GeoJSON().writeFeatureObject(props.feature);
		f.properties = {};
		return $map.getParcelThumbnail(null, 'transparent',  '#aaa', 30, f);
	}

	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setState({error: {}, success: null});
  }
	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.add_field}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField required error={state.error.name ? true : false} helperText={state.error.name || ''} label={lang.parcel_name} type="text" onChange={(evt) => setModel({name: evt.target.value})} fullWidth size="small" value={model.name} />
						</Grid>
						{/* <Grid item xs={12} sm={6}>
							<FormControl size="small" fullWidth>
								<InputLabel id="crops">{lang.parcel_crop}</InputLabel>
								<Select
									style={{textTransform: 'uppercase'}}
									size="small" labelId="crops" 
									label={lang.parcel_crop} value={model.crop} 
									onChange={(evt) => setModel({crop: evt.target.value})}
								>
									{cropTypes.map((obj, key) => {
										return (
											<MenuItem key={key} style={{textTransform: 'uppercase'}} value={obj.pk}>{obj.name}</MenuItem>
										)
									})}
								</Select>
								{state.error.crop && <FormHelperText>{state.error.crop}</FormHelperText>}
							</FormControl>
						</Grid> */}
						{/* <Grid item xs={12} sm={6}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={moment($seasons.getCurrentSeasonDates().min)} defaultCalendarMonth={moment()} onClose={() => {setModel({sowing: model.sowing ? moment(model.sowing).format('YYYY-MM-DD') : null})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.parcel_sowing} value={model.sowing ? moment(model.sowing) : null} onChange={(newValue) => setModel({sowing: newValue ? newValue.format('YYYY-MM-DD') : null})}
									renderInput={(params) => (
										<TextField fullWidth error={state.error.sowing ? true : false} helperText={state.error.sowing || ''} {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
							</LocalizationProvider>	
						</Grid>
						<Grid item xs={12} sm={6}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={moment($seasons.getCurrentSeasonDates().min)} defaultCalendarMonth={moment()} onClose={() => {setModel({harvest: model.harvest ? moment(model.harvest).format('YYYY-MM-DD') : null})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.parcel_harvest} value={model.harvest ? moment(model.harvest) : null} onChange={(newValue) => setModel({harvest: newValue ? newValue.format('YYYY-MM-DD') : null})}
									renderInput={(params) => (
										<TextField fullWidth error={state.error.harvest ? true : false} helperText={state.error.harvest || ''} {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
								</LocalizationProvider>
						</Grid> */}
						<Grid  item xs={12}>
							<Stack sx={{maxWidth: '180px', width: '100%', margin: '0 auto'}}>
								{renderGeometry()}
							</Stack>
						</Grid>
          </Grid>
        </Container>
      </DialogContent>
			<DialogActions>
				<LoadingButton onClick={postParcel} size="small" loading={state.loading} variant="contained" color="primary">{lang.confirm}</LoadingButton>
				<LoadingButton onClick={props.onClose} size="small" loading={state.loading} variant="contained" color="error">{lang.cancel}</LoadingButton>
			</DialogActions>
			<Message type="error" open={state.error.non_field_errors !== undefined} message={state.error.non_field_errors} onClose={() => onCloseMessage()} />
    </Dialog>
	)
}

export default AddParcel;