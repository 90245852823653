import { Button, Chip, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Stack, Typography, Switch, TextField, InputAdornment, colors, DialogActions, Tooltip, Autocomplete } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { AppContext } from "../../../AppContext";
import { useContext, useEffect, useRef, useState } from "react";
import FertigationView from "./FertigationView";
import HarvestingView from "./HarvestingView";
import TillageView from "./TillageView";
import PlantingView from "./PlantingView";
import NoteView from "./NoteView";
import PlantProtectionView from "./PlantProtectionView";
import { AttachFile, Check, Close, Delete, Edit, Event, FilePresent } from "@mui/icons-material";
import TillageForm from "../forms/TillageForm";
import FertigationForm from "../forms/FertigationForm";
import PlantingForm from "../forms/PlantingForm";
import HarvestingForm from "../forms/HarvestingForm";
import NoteForm from "../forms/NoteForm";
import $data from "../../../services/$data";
import useMergeState from "../../hooks/useMergeState";
import ConfirmDialog from "../../ui/ConfirmDialog";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import Divider from "../../auth/Divider";
import PlantProtectionForm from "../forms/PlantProtectionForm";
import { Link } from "react-router-dom";

function JobView(props) {
	const {lang, locale, activityTypes} = useContext(AppContext);
	const filesRef = useRef([]);
	const [files, setFiles] = useState([]);

  const [fileAttrs, setFileAttrs] = useMergeState({});

	const [state, setState] = useMergeState({error: {}, edit: false, loading: false, remove: false});

	const [job, setJob] = useMergeState({jo_from: moment().add(1, 'days').toISOString(), jo_to: moment().add(1, 'days').toISOString(), sms: false})

	const [isJob, setIsJob] = useState(false);

	const getActivityView = (type, props = {}) => {

		switch (type) {
			case 1: return <TillageView className="activity-view-container" {...props} />
			case 2: return <FertigationView className="activity-view-container" {...props} />
			case 3: return <PlantingView className="activity-view-container" {...props} />
			case 4: return <PlantProtectionView className="activity-view-container" {...props} />
			case 5: return <HarvestingView className="activity-view-container" {...props} />
			case 6: return <NoteView className="activity-view-container" {...props} />
			default: return null
		}
	}

	const getActivityForm = (type, props = {}) => {
		switch (type) {
			case 1: return <TillageForm loading={state.loading} error={state.error} onSubmit={archiveJob} {...props} />
			case 2: return <FertigationForm loading={state.loading} error={state.error} onSubmit={archiveJob}  {...props} />
			case 3: return <PlantingForm loading={state.loading} error={state.error} onSubmit={archiveJob}  {...props} />
			case 4: return <PlantProtectionForm loading={state.loading} error={state.error}  onSubmit={archiveJob}   {...props}  />
			case 5: return <HarvestingForm loading={state.loading} error={state.error} onSubmit={archiveJob}  {...props} />
			case 6: return <NoteForm loading={state.loading} error={state.error} onSubmit={archiveJob} {...props} />
			default: return null
		}
	}

	const archiveJob = (model, toArchive) => {
		if(toArchive) {
			model.job_order = false;
			model.activity_status = true;	
		}

		if(!toArchive) {
			model.job_order = true;
			model.activity_status = false;
	
		}

		$data.updateActivity({...model, upload_document: files.map((f,key) => {
			f.name_field = fileAttrs[key].name;
			f.description = fileAttrs[key].description;
			f.tags = fileAttrs[key].tags.length > 0 ? fileAttrs[key].tags.join('; ') : null;
			return f
		})})
			.then(data => {
				onClose();
				setTimeout(() => { props.onRefresh(lang.update_activity_success); })
			})
			.catch(err => { setState({error: err, loading: false})})
	}


	const onClose = () => {
		setState({edit: false, remove: false, loading: false, error: {}}); setIsJob(false); props.onClose();
	}

	const onFilesChange = (evt) => {
    // setFiles(evt.target.files);
    setFiles(Array.from(filesRef.current.files));
    let attrs = {};
    Array.from(filesRef.current.files).map((obj, key) => {
      attrs[key] = {
        name: obj.name,
        description: '',
        tags: []
      }
    })
    
    setFileAttrs(JSON.parse(JSON.stringify(attrs)))
  }

  const onFileAttributeChange = (key, name, value, opt_remove) => {
    setFileAttrs({[key]: {...fileAttrs[key], [name]: value}})
  }

	useEffect(() => {
		if(!props.open) {
			setFiles([]);
			setFileAttrs({});
			filesRef.current = [];
		}
	}, [props.open])

	const deleteActivity = (model) => {
		setState({loading: true})
		$data.deleteActivity(model)
			.then(data => {
				onClose();
				setTimeout(() => { props.onRefresh(lang.delete_activity_success); })
			})
			.catch(err => { setState({error: err, edit: false, loading: false})})
	}

	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{props.activity ? $data.getActivityNameById(activityTypes, props.activity.activity_type) : null}<Close onClick={onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container>
          <Grid style={{marginBottom: '20px'}} container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="subtitle1" style={{color: colors.orange[700] }}>{lang.job_archive_text}					
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField className="job-field" focused label={lang.from} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={props?.activity?.jo_to ? moment(props?.activity?.jo_from).format('DD-MMM-YYYY') : ' - '} />	
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField className="job-field" focused label={lang.to} fullWidth sx={{'& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'pointer'}}} size="small" inputProps={{readOnly: true}} value={props?.activity?.jo_to ? moment(props?.activity?.jo_to).format('DD-MMM-YYYY') : ' - '} />	
						</Grid>
						{/* <Grid item xs={12} sm={4}>
							<Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '40px'}} variant="subtitle1">{lang.send_sms} {props?.activity?.sms ? <Check style={{marginBottom: '3px'}} color={'success'} /> : <Close style={{marginBottom: '3px'}} color={'error'} />} </Typography>
						</Grid> */}
						<Grid item xs={12}>
							<Stack flexWrap={'wrap'} direction="row" justifyContent={'space-between'} alignItems="center">
								<div>
									{!state.edit && <Button onClick={() => setState({edit: true})} style={{marginRight: '5px'}} size="small" color="warning" variant="contained" endIcon={<Edit />}>{lang.edit}</Button>}
									{state.edit && <Button onClick={() => setState({edit: false})} style={{marginRight: '5px'}} size="small" color="error" variant="contained" endIcon={<Close />}>{lang.cancel}</Button>}
									{!state.edit && <Button onClick={() => setState({remove: true})} size="small" color="error" variant="contained" endIcon={<Delete />}>{lang.delete}</Button>}
								</div>
								{/* {state.edit && props.activity?.activity_type !== 6 && <FormGroup>
									<FormControlLabel style={{margin: 0, justifyContent: 'flex-end'}} control={<Switch checked={isJob} onChange={() => setIsJob(!isJob)} />} label={lang.act_add_job_order}/>
								</FormGroup>} */}
							</Stack>
							
						</Grid>
						
						{props.activity && props.activity?.activity_type !== 6 && <Grid item xs={12}>
					
							<Stack spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
								{props.activity?.documents?.map((obj, key) => {
									return (
										<Tooltip key={key} placement="top" title={obj.name}>
											<FilePresent fontSize="large" color="primary" />
										</Tooltip>
									)
								})}

							</Stack>
							<Link to="/dashboard/field-book/?documents" style={{textDecoration: 'none', fontWeight: 'bold', display: 'block', marginTop: '5px'}} className="text-warning">{lang.act_edit_delete}</Link>
						</Grid>}

						{state.edit && props.activity?.activity_type !== 6 && <Grid item xs={12}>
              <Stack sx={{mb: '15px'}} spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
                {/* <Button size="small" variant="contained" onClick={() => setAttach(true)}> */}
                <Button size="small" variant="contained" component="label">
                  <AttachFile /> &nbsp; {lang.select_documents}
                  <input ref={filesRef} onChange={onFilesChange} value={""} multiple type="file" hidden />
                </Button>
                {/* </Button> */}
                {files.map((obj, key) => <Tooltip key={key} title={obj.name} placement="top" ><FilePresent fontSize="large" color="primary" /></Tooltip>)}
              </Stack>

              
                {files.map((obj, key) => {
                  return (
                    <Grid key={key} container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TextField value={fileAttrs[key].name} size="small" onChange={(evt) => onFileAttributeChange(key, 'name', evt.target.value)} fullWidth label={lang.document_name} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField size="small" value={fileAttrs[key].description} fullWidth onChange={(evt) => onFileAttributeChange(key, 'description', evt.target.value)} label={lang.document_description} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete multiple size="small" fullWidth
                          freeSolo
                          defaultValue={[]}
                          value={fileAttrs[key]['tags']}
                          onChange={(evt, val, reason) => {if(reason === 'createOption' || reason === 'removeOption') {onFileAttributeChange(key, 'tags', val, reason === 'removeOption')}}}
                          renderTags={(value, getTagProps) => value.map((option, index) => <Chip size="small" variant="filled" color="warning" label={option} {...getTagProps({index})}/>)}
                          renderInput={(params) => (
                             <TextField {...params} size="small" helperText={lang.act_document_tag_helper} fullWidth label={lang.document_tags}/>
                          )}
                          options={[]} />
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>}
						
						<Grid item xs={12}>
              <Divider style={{width: '100%', marginBottom: 0}} />
            </Grid>
					</Grid>
						{!state.edit && getActivityView(props.activity ? props.activity.activity_type : null, {data: props.activity})}
						{state.edit && getActivityForm(props.activity ? props.activity.activity_type: null, {model: props.activity, parcel: props.parcel, isJob: true, forArchive: true, edit: true, closeErrorMessage: () => setState({error: {...state.error, non_field_errors: null}})})}
        </Container>
      </DialogContent>

			<ConfirmDialog open={state.remove} onSubmit={() => deleteActivity(props.activity)} onClose={() => setState({remove: false})}  title="Delete activity" question="Are you sure that you want to delete selected activity?" />
    </Dialog>	
	)
}

export default JobView;