import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Box from "@mui/system/Box";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import Logo from "../../Logo";

import ukFlag from '../../images/uk-flag.png';
import srFlag from '../../images/sr-flag.png';
import { HelpCenter, HelpCenterRounded, HelpOutline, HelpRounded, QuestionMark, QuestionMarkOutlined, QuestionMarkRounded } from "@mui/icons-material";

const copyrightStyle = {
  position: 'sticky',
  top: 'calc(100% - 28px)',
  bottom: 0,
  textAlign: 'center',
  background: "#fff",
  padding: '5px 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '5px',
  paddingRight: '5px'
}

const containerStyle = {
  maxWidth: '400px',
  padding: '0 10px',
  margin: '0 auto'
}

let headerBox = {
  height: '80px',
  minWidth: '100px',
  padding: '0 5px',
  borderBottomLeftRadius: '5px'
}

function Auth(props) {
  const {lang, primary, locale, onUpdateState} = useContext(AppContext);
  return (
    <div className="auth-container" style={{height: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Logo style={{width: 150, height: 75, padding: '5px 0'}} className="auth-logo"/>
        <div>
          <Box className='links-container' sx={{'& .help-link': {color: primary, textDecoration: 'none', fontWeight: 'bold', lineHeight: '80px', p: '0 3px'}}} style={Object.assign({}, headerBox)}>
            <a rel="noreferrer" target="_blank" href="https://croplab.info/" className="help-link">{lang.about}</a>
            <a rel="noreferrer" target="_blank" href="https://croplab.info/#pricing" className="help-link">{lang.pricing}</a>
            <a rel="noreferrer" target="_blank" href="https://croplab.info/#contact" className="help-link">{lang.contact}</a>
            <a rel="noreferrer" onClick={() => onUpdateState({tourGuide: true})} style={{cursor: 'pointer'}} className="help-link"><HelpRounded fontSize="small" sx={{fontSize: '15px'}} /></a>
          </Box>
          <Stack style={{paddingTop: '5px', paddingRight: '5px'}} justifyContent={'flex-end'} direction="row">
            <Avatar onClick={() => onUpdateState({locale: 'sr'})} style={{width: "35px", height: '35px', cursor: 'pointer', border: `2px solid ${(locale === 'sr' ? primary : '#fff')}`}} src={srFlag} />
            <Avatar onClick={() => onUpdateState({locale: 'en'})} style={{width: "35px", height: '35px', cursor: 'pointer', border: `2px solid ${(locale === 'en' ? primary : '#fff')}`}} src={ukFlag} />
          </Stack>
        </div>
      </div>
      <div style={containerStyle}>
        {props.children}
      </div>

      <div style={copyrightStyle}><a rel="noreferrer" style={{color: primary, textDecoration: 'none'}} href="https://gilab.rs/" target="_blank">&copy; GILAB D.O.O</a> 
      <div>
       <span style={{fontWeight: 'bold', fontSize: '12px', marginRight: '10px', cursor: 'pointer'}} onClick={() => onUpdateState({privacyPolicy: true})} className="text-primary">{lang.privacy_policy}</span>  <span style={{fontWeight: 'bold', fontSize: '12px', marginRight: '10px', cursor: 'pointer'}} onClick={() => onUpdateState({terms: true})} className="text-primary">{lang.terms_policy}</span></div>
      </div>
    </div>
  )
}

export default Auth;