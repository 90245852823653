import en from "./en";
import sr from "./sr";

const languageMap = {
  'en' : 'en',
  'en-US': 'en',
  'en-UK': 'en',
  'sr': 'sr',
  'sr-RS': 'sr'
}

export const allowedLanguages = ['en', 'en-US', 'en-UK', 'sr', 'sr-RS'];

export const checkLanguage = (locale) => allowedLanguages.indexOf(locale) > -1  ? languageMap[locale] : 'en';

export const getTranslation = (locale) => {
  let lang = checkLanguage(locale);
  return translation[lang];
}

const translation = {
  'en': en,
  'en-US': en,
  'en-UK': en,
  'sr': sr,
  'sr-RS': sr
}

export default translation;

