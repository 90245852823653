import { Button, colors, TextField, InputAdornment, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, useMediaQuery, FormControl, InputLabel, Select, FormHelperText, MenuItem, FormControlLabel, FormGroup, Switch} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {useTheme} from '@mui/system';
import { Add, ArrowBack, ArrowForward, Close, Event } from "@mui/icons-material";
import moment from "moment";
import PlantingForm from "../../fieldbook/forms/PlantingForm";
import HarvestingForm from "../../fieldbook/forms/HarvestingForm";
import useMergeState from "../../hooks/useMergeState";
import $data from "../../../services/$data";
import Message from "../../ui/Message";
import $activities from "../../../services/$activities";
import { LoadingButton, MobileDatePicker } from "@mui/lab";

function AddCropSeason(props) {
  const {lang, locale, onUpdateParcels, cropGroups, cropTypes} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = useMergeState({error: {}, loading: false, success: null, knowHarvest: false});

  const [planting, setPlanting] = useMergeState({
    date: moment().format('YYYY-MM-DD'),
    activity_type: 3,
    crop_group: 5,
    crop_type: '999',
    seed_type: '',
    depth: 0,
    row_spacing: 0,
    plants_ha: 0,
    plants_parcel: 0,
    product_unit_price: 0,
		product_price_ha: 0,
		product_price_parcel: 0,
		price_ha: 0,
		price_parcel: 0,
		operators: [],
		machine: null,
		conn_machine: null,
		comment: '',
		parcel: props.parcel.pk
  });

  const [harvesting, setHarvesting] = useMergeState({
    date: moment().format('YYYY-MM-DD'),
		activity_type: 5,
    yield_ha: 1,
    yield_parcel: 1,
		moisture: 0,
    impurities: 0,
    hectolitre_mass: 0,
    digestion: 0,
    oil_content: 0,
    protein_content: 0,
		price_ha: 0,
		price_parcel: 0,
		operators: [],
		machine: null,
		conn_machine: null,
		comment: '',
		parcel: props.parcel.pk
  });

  const onPlantingChange = (name, val) => {
    let {value, additional} = $activities.getPlantingPrices(name, val);
    setPlanting({...planting, [name]: value, ...additional});
  }

  const onHarvestingChange = (name, val) => {
    let {value, additional} = $activities.getHarvestingPrices(name, val);
    setHarvesting({...harvesting, [name]: value, ...additional});
  }

  const onSubmit = () => {
    setState({loading: true, error: {}});
    planting.parcel = props.parcel.pk;
    harvesting.parcel = props.parcel.pk;
    $data.postSeasonRecord(planting, harvesting, state.knowHarvest)
      .then(data => {
        onUpdateParcels();
        setState({loading: false, success: lang.crop_added_success})
      })
      .catch(err => { setState({error: err, loading: false}) })
  }

  return (
    <Dialog component={'form'} onSubmit={(evt) => {evt.preventDefault(); onSubmit()}} fullWidth maxWidth="lg" fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.add_crop_season} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel style={{margin: 0, justifyContent: 'flex-start'}} control={<Switch checked={state.knowHarvest} onChange={(evt) => {setState({...state, knowHarvest: evt.target.checked});}} />} label={lang.insert_harvest}/>
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid sx={{mt: '10px'}} item xs={12}>
                  <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                    <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onPlantingChange('date', planting.date)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.parcel_sowing} value={moment(planting.date)} onChange={(newValue) => onPlantingChange('date', newValue.format('YYYY-MM-DD'))}
                      renderInput={(params) => (
                        <TextField fullWidth error={state.error.date} helperText={state.error.date || ''} {...params} size="small"
                          InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
                      )}
                    />
                  </LocalizationProvider>	
                </Grid>
                <Grid item xs={12}>
                  <FormControl size="small" error={state.error.crop_group !== undefined} fullWidth>
                    <InputLabel id="crop_group">{lang.crop_group}</InputLabel>
                    <Select  size="small" labelId="crop_group" label={lang.crop_group} value={planting.crop_group || ''} onChange={(evt) => onPlantingChange('crop_group', evt.target.value)} fullWidth>
                      {cropGroups.map((obj, key) => <MenuItem value={obj.id} key={key}>{obj.name}</MenuItem>)}
                    </Select>
                    {state.error.crop_group && <FormHelperText>{state.error.crop_group}</FormHelperText>}
                  </FormControl>			
                </Grid>	
                <Grid item xs={12}>
                  <FormControl size="small" error={state.error.crop_type !== undefined} fullWidth>
                    <InputLabel id="crop_type">{lang.crop_type}</InputLabel>
                    <Select size="small" labelId="crop_type" label={lang.crop_type} value={planting.crop_type || ''} onChange={(evt) => onPlantingChange('crop_type', evt.target.value)} fullWidth>
                      {cropTypes.filter((obj) => obj.crop_group.toString() === planting.crop_group.toString()).map((obj, key) => <MenuItem value={obj.pk} key={key}>{obj.name}</MenuItem>)}
                    </Select>
                    {state.error.crop_type && <FormHelperText>{state.error.crop_type}</FormHelperText>}
                  </FormControl>			
                </Grid>	
              </Grid>
            </Grid>
           {state.knowHarvest && <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid sx={{mt: '10px'}} item xs={12}>
                  <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                    <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} onClose={() => {onHarvestingChange('date', harvesting.date)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.parcel_harvest} value={moment(harvesting.date)} onChange={(newValue) => onHarvestingChange('date', newValue.format('YYYY-MM-DD'))}
                      renderInput={(params) => (
                        <TextField fullWidth error={state.error.date} helperText={state.error.date || ''} {...params} size="small"
                          InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
                      )}
                    />
                  </LocalizationProvider>	
                </Grid>
              </Grid>
            </Grid>}
            

           
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
      <LoadingButton loading={state.loading} onClick={props.onClose} type="submit" size="small" variant="contained">{lang.confirm}</LoadingButton>
      <LoadingButton loading={state.loading} onClick={props.onClose} size="small" variant="contained" color="error">{lang.close}</LoadingButton>
      </DialogActions>

			<Message type="error" open={state.error.non_field_errors ? true : false} message={state.error.non_field_errors} onClose={() => setState({error: {...state.error, non_field_errors: null}})} />
			<Message type="success" open={state.success ? true : false} message={state.success} onClose={() => setState({success: null})} />
    </Dialog>
  )
}

export default AddCropSeason;