import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import colors from "../../config/colors";
import geojson2svg, { DefaultStyles } from 'geojson-to-svg';

export default function FieldItem(props) {
  let image = props.image;
  let cardMedia = <CardMedia sx={props.mediaSx || {}} component="img" height="140" src={image} alt="crop" />;

  if (props.groupID === 5) {
    if (props.geometry.geometry) {
      let svgStr = geojson2svg().styles((f) => ({ fill: colors.cropGroup['5'], opacity: 1, weight: 4, })).data(props.geometry).render();

      let blob = new Blob([svgStr], { type: 'image/svg+xml' });
      image = window.URL.createObjectURL(blob);

      cardMedia = <div style={{ background: 'rgba(0,0,0,.1)', height: 140, textAlign: 'center' }}>
        <img style={{ margin: '0 auto', padding: 5, display: 'block', transform: 'scaleX(-1) rotate(180deg)', maxWidth: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }} src={image} />
      </div>
    } else {
      cardMedia = <div style={{ background: 'rgba(0,0,0,.1)', height: 140, textAlign: 'center' }}>
        {/* <img style={{margin: '0 auto', padding: 5,  display: 'block', transform: 'scaleX(-1) rotate(180deg)', maxWidth: 'calc(100% - 10px)', height: 'calc(100% - 10px)'}} src={image} /> */}
      </div>
    }
  }



  return (
    <Link style={{ textDecoration: 'none' }} to={"/dashboard/field-book/" + props.parcel} >
      <Card sx={props.sx || {}} className="field-item-card" elevation={4}>
        {/* <CardMedia sx={cardSx} component="img" height="140" src={image} alt="crop" /> */}
        {cardMedia}
        <CardContent>
          <Tooltip arrow title={props.name}>
            <Typography gutterBottom color="primary" variant="h6" component="div" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.name}</Typography>
          </Tooltip>
          <Typography variant="body2" color="text.secondary">
            <strong><span style={{textTransform: 'uppercase'}}>{props.crop}</span> / {props.area.toFixed(2)} ha</strong>
          </Typography>
        </CardContent>
      </Card>
    </Link>

  )
}