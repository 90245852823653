import { AttachFile, Close, Delete, Download, Edit, Event, FileDownload, FilePresent, Refresh, Upload } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";

function SoilNameConfirm(props) {
  const {lang, onUpdateSamples} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [name, setName] = useState('');

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.collection_name}
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Grid sx={{mt: '30px'}} container spacing={2}>
          <Grid item xs={12}>
            <TextField size="small" label={lang.collection_name} value={name} onChange={(evt) => setName(evt.target.value)} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={name == ''} color="primary" variant="contained" size="small" onClick={() => props.onSuccess(name)}>{lang.confirm}</LoadingButton>
        <LoadingButton  color="error" variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SoilNameConfirm;