export const host = process.env.REACT_APP_BRANCH === 'dev' ? 'https://dev.croplab.gilab.rs' : 'https://app.croplab.info';
const api = host + '/backend/api/';
const geoserver = host + '/geoserver/rest/styles/';
const sos = 'https://sosbackend.gilab.rs/backend/';

const urls = {
	AGRICAPTURE: 'https://app.co2agri.com',
	GEOSERVER: geoserver,
	WMS: api + 'parcel/wms?',
	DATES_S2: api + 'query/__ID__/dates/s2/',
	DATES_PLANET:  api + 'query/__ID__/dates/planet/',
	DATES: api + 'parcel/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=u__ID__:dates&outputFormat=application/json',
	USER: api + 'authentication/user/',
	ACCOUNT: api + 'authentication/account/',
	DELETE_ACCOUNT: api + 'authentication/delete/account/',
	LOGIN: api + 'authentication/login/',
	LOGOUT: api + 'authentication/logout/',
	RESEND: api + 'authentication/registration/resend-email/',
	GOOGLE: api + 'authentication/google/',
	USER_EXISTS: api + 'authentication/email-check',
	REGISTER: api + 'authentication/registration/',
	RESET_PASSWORD_SEND: api + 'authentication/password/reset/',
	RESET_PASSWORD_CONFIRM: api + 'authentication/password/reset/confirm/',
	CHANGE_PASSWORD: api + 'authentication/password/change/',
	VERIFICATION: api + 'authentication/registration/verify-email/',
	TOKEN_REFRESH: api + 'authentication/token/refresh/',
	TOKEN_VERIFY: api + 'authentication/token-verify/',
	PHONE_REGISTER: api + 'authentication/phone/register',
	PHONE_VERIFY: api + 'authentication/phone/verify',	
	PARCEL: api + 'parcel/',
	PARCEL_BULK: api + 'parcel/bulk_update/',
	CROP_TYPE: api + 'parcel/crop_type/',
	CROP_GROUP: api + 'parcel/crop_group/',
	QUERY: api + 'parcel/__ID__/query/',
	SUMMARY: api + 'parcel/summary/__ID__/',
	SUMMARY_ALL: api + 'parcel/summary/',
	SUMMARY_BOTH: api + 'parcel/summary_all/__ID__/',
	GDD: api + 'parcel/gdd/__ID__/',
	METEO: api + 'parcel/meteo/__ID__/',
	FERTILIZATION: api + 'parcel/fertilization',
	FERTILIZATION_RANGES: api + 'parcel/quantiles',
	GFI: api + 'parcel/gfi?',
	FORECAST: 'https://api.met.no/weatherapi/locationforecast/2.0/?lat=__LAT__&lon=__LON__',
	WEATHER_ICON: 'https://api.met.no/weatherapi/weathericon/1.1/?symbol=__S__&is_night=__N__&content_type=image/svg',
	// ACTIVITIES: api + 'activities/all/__ID__/',
	ACTIVITIES: api + 'activities/',
	ACTIVITIES_PDF: api + 'activities/fb-pdf/',
	ACTIVITY_TYPES: api + 'activities/activity_type/',
	ACTIVITY_TILLAGE_TYPES: api + 'activities/tillage_type/',
	ACTIVITY_FERTIGATION_TYPES: api + 'activities/fertigation_type/',
	ACTIVITY_FERTIGATION_PRODUCTS: api + 'activities/fertigation_product/',
	ACTIVITY_NOTE: api + 'activities/note/',
	ACTIVITY_HARVESTING: api + 'activities/harvesting/',
	ACTIVITY_TILLAGE: api + 'activities/tillage/',
	ACTIVITY_GRAZING: api + 'activities/grazing/',
	ACTIVITY_AGROTECH: api + 'activities/agrotech_operation/',
	ACTIVITY_FERTIGATION: api + 'activities/fertigation/',
	ACTIVITY_PLANTING: api + 'activities/planting/',
	ACTIVITY_PLANTING_TYPES: api + 'activities/production_type/',
	ACTIVITY_PLANTING_SUBTYPES: api + 'activities/production_subtype/',
	ACTIVITY_SEED_TYPES: api + 'activities/seed_type/',
	ACTIVITY_PLANT_PROTECTION_TYPES: api + 'activities/plant_protection_type/',
	ACTIVITY_PLANT_PROTECTION_PRODUCTS: api + 'activities/plant_protection_product/',
	ACTIVITY_PLANT_PROTECTION_SUBSTANCES: api + 'activities/plant_protection_substance/',
	ACTIVITY_AGROTYPES: api + 'activities/agrotechnical_operation_type/',
	ACTIVITY_PLANT_PROTECTION: api + 'activities/plant_protection/',
	ACTIVITY_AGROTECHNICAL: api + 'activities/agrotechnical_operation/',
	ACTIVITY_OPERATORS: api + 'activities/operater/',
	ACTIVITY_MACHINES: api + 'activities/machine/',
	ACTIVITY_CONN_MACHINES: api + 'activities/conn_machine/',
	CROP_HISTORY: api + 'activities/crop-history/',
	DOCUMENTS: api + 'activities/docs/',
	DOCUMENTS_DOWNLOAD: api + 'activities/docs/download/',
	JOB_ORDER: api + 'activities/common_activity/__ID__/job_order/',
	ROLES: api + 'authentication/roles/',
	NOTIFICATION: api + 'notifications/',
	NOTIFICATION_OPTIONS: api + 'notifications/options/',
	ALERTS: api + 'pis/',
	SENSORS: sos + 'sensors?limit=9999999999',
	PLOTS: sos + 'locationHistory/',
	LOCATIONS: sos + 'fieldPlots/?limit=99999999',
	MEASUREMENTS: sos + 'measurements/?limit=9999999999&sensor=',
	SENSOR_VARIABLES: sos + 'sensor-vars/?limit=999999',
	SENSOR_TYPES: sos + 'sensorType/',
	SENSORS_CROPLAB: api + 'sensor/',
	SENSOR_MEASUREMENTS: api + 'sensor/measurements/',
	AC_REQUESTS: api + 'authentication/projectant/farmer/',
	ALERTS: api + 'pis/',
	CONTACT: api + 'contact/',
	PHOTOS: api + 'photos/',
	UPLOAD_PARCELS: api + 'parcel/shp_import/',
	SAMPLES: api + 'sensor/collected-samples/',
	NOMINATIM: 'https://nominatim.openstreetmap.org/search?format=json&limit=4&q=',
	IRRIGATION: api + 'sensor/irrigation/',
	PRACTICE: api + 'activities/reg-agri-practice/',
	DOWNLOAD_DATA: api + 'parcel/get-data/'
}

export default urls;