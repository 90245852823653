import moment from 'moment';

class $cookies {
	
	
	get(param) {
		return localStorage.getItem(param);
	}

	set(param, value) {
		localStorage.setItem(param, value);
	}

	remove(param) {
		localStorage.removeItem(param);
	}

	removeAll() {
		
	}

	setExpiration() {
		let expiration = moment().add(30, 'minutes').format();
		localStorage.setItem('expiration', expiration);

		return expiration
	}

}

export default new $cookies();