import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";
import { ArrowForward, Calculate, Event } from "@mui/icons-material";
import $activities from "../../../services/$activities";
import Message from "../../ui/Message";
import InputPerPackage from "../helper/InputPerPackage";
import InputPerPackagePlanting from "../helper/InputPerPackagePlanting";

function PlantingForm(props) {
	let {lang, locale, cropTypes, cropGroups, plantingSeeds, operators, machines, connectedMachines} = useContext(AppContext);
	
	let editModel = props.model ? Object.assign({}, props.model, {operators: props.model.operators.map(obj => obj.pk)}) : {}

  const [data, setData] = useMergeState({model: Object.assign({}, {
		date: moment().format('YYYY-MM-DD'),
		activity_type: 3,
		crop_group: 5,
		crop_type: '',
    seed_type: '',
    depth: 0,
		tgw: 0,
    row_spacing: 0,
		plants_m2: 0,
    plants_ha: 0,
    plants_parcel: 0,
		quantity_unit: 1,
    product_unit_price: 0,
		product_price_ha: 0,
		product_price_parcel: 0,
		price_ha: 0,
		price_parcel: 0,
		fuel_consumption: 0,
		operators: [],
		machine: null,
		conn_machine: null,
		comment: '',
		parcel: props.parcel.pk


	}, {...editModel, plants_m2: editModel.plants_ha / 10000}), error: {}, success: false, loading: false});

	const {model, error, success, loading} = data;

	const [packageModal, setPackageModal] = useState(false);


  useEffect(() => {
		if(props.isJob && !props.forArchive) {
			if(moment(model.date).diff(moment()) <= 0) {
				setData({model: {...model, date: moment().add(1, 'days').format('YYYY-MM-DD')}})
			}
		}
	}, [props.isJob])

	const onChange = (name, val) => {
    let {value, additional} = $activities.getPlantingPrices(name, val, model, props.parcel.area);

		console.log(additional);

		setData({model: {...model, [name]: value, ...additional}});
	}

  const getTotalPrice = () => {
    return {
      ha: (parseFloat(model.product_price_ha) + parseFloat(model.price_ha)).toFixed(2),
      parcel: (parseFloat(model.product_price_parcel) + parseFloat(model.price_parcel)).toFixed(2), 
    }
  }

	const getTgwWeight = () => {
		console.log('PO m2:', model.plants_m2);
		console.log('TGW:', model.tgw + '(g)');

		return (Boolean(model.tgw) && Boolean(model.plants_m2)) ? {ha: (parseFloat((model.plants_m2 * model.tgw)/100)).toFixed(2), parcel: (parseFloat(props.parcel.area * ((model.plants_m2 * model.tgw)/100))).toFixed(2)}  : {ha: 0, parcel: 0};
	}

	const updateFromPackages = (fieldCost, quantityParcel, unit, unitPrice) => {
		setData({
			model: {
				...model,
				plants_parcel: quantityParcel,
				plants_ha: (quantityParcel/props.parcel.area).toFixed(2),
				quantity_unit: unit,
				product_price_parcel: fieldCost,
				product_price_ha: (fieldCost/props.parcel.area).toFixed(2),
				product_unit_price: unitPrice
			}
		})
	}

	return (
		<Grid className={props.className || ''} component="form" onSubmit={(evt) => {evt.preventDefault(); props.onSubmit(model, true)}} container spacing={2}>
			{(!props.isJob || props.forArchive) && <Grid item xs={12} sm={4}>
				<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
					<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={props.isJob && !props.forArchive ? moment().add(1, 'days') : undefined} onClose={() => {onChange('date', model.date)}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.date} value={moment(model.date)} onChange={(newValue) => onChange('date', newValue.format('YYYY-MM-DD'))}
						renderInput={(params) => (
							<TextField fullWidth error={props.error.date} helperText={props.error.date || ''} {...params} size="small"
								InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
						)}
					/>
				</LocalizationProvider>	
			</Grid>}
			{(!props.isJob || props.forArchive) && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>}
			{(!props.isJob || props.forArchive) && <Grid style={{padding: 0}} item xs={12} sm={4}></Grid>}

			<Grid item xs={12} sm={4}>
				<FormControl size="small" error={props.error.crop_group !== undefined} fullWidth>
					<InputLabel id="crop_group">{lang.crop_group}</InputLabel>
					<Select size="small" labelId="crop_group" label={lang.crop_group} value={model.crop_group || ''} onChange={(evt) => onChange('crop_group', evt.target.value)} fullWidth>
						{cropGroups.map((obj, key) => <MenuItem value={obj.id} key={key}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.crop_group && <FormHelperText>{props.error.crop_group}</FormHelperText>}
				</FormControl>			
			</Grid>	
      <Grid item xs={12} sm={4}>
				<FormControl size="small" error={props.error.crop_type !== undefined} fullWidth>
					<InputLabel id="crop_type">{lang.crop_type}</InputLabel>
					<Select size="small" labelId="crop_type" label={lang.crop_type} value={model.crop_type || ''} onChange={(evt) => onChange('crop_type', evt.target.value)} fullWidth>
						{cropTypes.filter((obj) => obj.crop_group.toString() === model.crop_group.toString()).map((obj, key) => <MenuItem value={obj.pk} key={key}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.crop_type && <FormHelperText>{props.error.crop_type}</FormHelperText>}
				</FormControl>			
			</Grid>	
			<Grid item xs={12} sm={4}>
				<Autocomplete size="small" value={model.seed_type} 
					getOptionLabel={(option) => {return option || ''}} 
					onChange={(evt, value) => { onChange('seed_type', value)}} 
					onInputChange={(evt, value) => { onChange('seed_type', value)}}
					freeSolo id="seed_type" 
					disableClearable
					ListboxProps={{id: 'fertigation-product-suggestions'}} 
					options={plantingSeeds.filter(obj => obj.crop_type.toString() === model.crop_type.toString()).map((opt, key) => opt.name)} 
					renderInput={(params) => ( <TextField {...params} label={lang.seed_type} placeholder={lang.seed_type_placeholder} InputProps={{ ...params.InputProps, type: 'search', }} /> )} />
			</Grid>

      <Grid item xs={12} sm={6} >
				<TextField error={props.error.depth ? true : false} helperText={props.error.depth || ''} label={lang.depth} type="number" fullWidth onChange={(evt) => onChange('depth', evt.target.value)} size="small" value={(model.depth)} />
			</Grid>
			<Grid item xs={12} sm={6} >
				<TextField error={props.error.row_spacing ? true : false} helperText={props.error.row_spacing || ''} label={lang.row_spacing} type="number" fullWidth onChange={(evt) => onChange('row_spacing', evt.target.value)} size="small" value={(model.row_spacing)} />
			</Grid>

			<Grid item xs={12}>
				<Button onClick={() => setPackageModal(true)} size="small" variant="outlined" startIcon={<Calculate />}>{lang.input_per_package}</Button>
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField error={props.error.plants_m2 ? true : false} helperText={props.error.plants_m2 || ''} label={lang.plants_m2} type="number" fullWidth onChange={(evt) => onChange('plants_m2', evt.target.value)} size="small" value={(model.plants_m2)} />
			</Grid>
      <Grid item xs={12} sm={4} >
				<TextField error={props.error.plants_ha ? true : false} helperText={props.error.plants_ha || ''} label={lang.plants_ha} type="number" fullWidth onChange={(evt) => onChange('plants_ha', evt.target.value)} size="small" value={(model.plants_ha)} />
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField error={props.error.plants_parcel ? true : false} helperText={props.error.plants_parcel || ''} label={lang.plants_parcel} type="number" fullWidth onChange={(evt) => onChange('plants_parcel', evt.target.value)} size="small" value={(model.plants_parcel)} />
			</Grid>

			<Grid item xs={12} sm={4} >
				<TextField error={props.error.plants_m2 ? true : false} helperText={props.error.plants_m2 || ''} label={'Thousand-grain weight (g)'} type="number" fullWidth onChange={(evt) => onChange('tgw', evt.target.value)} size="small" value={(model.tgw)} />
			</Grid>

			<Grid item xs={12} sm={6} md={4} >
				<TextField sx={{background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'not-allowed', fontWeight: 'bold'} }}  label={'Sowing rate (kg/ha)'} fullWidth size="small" inputProps={{readOnly: true}} value={(getTgwWeight().ha) || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} md={4} >
				<TextField sx={{background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'not-allowed', fontWeight: 'bold'}}}  label={'Sowing rate (kg/field)'} fullWidth size="small" inputProps={{readOnly: true}} value={(getTgwWeight().parcel) || ' - '} />
			</Grid>

     

      <Grid item xs={12} sm={4} >
				<TextField error={props.error.product_unit_price ? true : false} helperText={props.error.product_unit_price || ''} focused={false} sx={{'& input': {background: 'rgba(0,0,0, 0.04)'}, '& input, & textarea': {cursor: 'not-allowed'} }} inputProps={{readOnly: true}} label={lang.product_unit_price} type="number" fullWidth onChange={(evt) => onChange('product_unit_price', evt.target.value)} size="small" value={(model.product_unit_price)} />
			</Grid>
			<Grid item xs={12} sm={4} >
				<TextField error={props.error.product_price_ha ? true : false} helperText={props.error.product_price_ha || ''}  label={lang.product_price_ha} type="number" fullWidth onChange={(evt) => onChange('product_price_ha', evt.target.value)} size="small" value={model.product_price_ha} />
			</Grid>
      <Grid item xs={12} sm={4} >
				<TextField error={props.error.product_price_parcel ? true : false} helperText={props.error.product_price_parcel || ''}  inputProps={{readOnly: false}} label={lang.product_price_parcel} type="number" fullWidth onChange={(evt) => onChange('product_price_parcel', evt.target.value)} size="small" value={model.product_price_parcel} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField error={props.error?.common_activity?.price_ha ? true : false} helperText={props.error?.common_activity?.price_ha || ''} label={lang.price_ha} type="number" fullWidth onChange={(evt) => onChange('price_ha', evt.target.value)} size="small" value={model.price_ha} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField error={props.error?.common_activity?.price_parcel ? true : false} helperText={props.error?.common_activity?.price_parcel || ''} label={lang.price_parcel} type="number" fullWidth onChange={(evt) => onChange('price_parcel', evt.target.value)} size="small" value={model.price_parcel} />
			</Grid>

			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'not-allowed', fontWeight: 'bold'} }} focused label={lang.total_price_ha} fullWidth size="small" inputProps={{readOnly: true}} value={(getTotalPrice().ha) || ' - '} />
			</Grid>
			<Grid item xs={12} sm={6} md={3} >
				<TextField sx={{background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root' : {fontWeight: 'bold'}, '& input, & textarea': {cursor: 'not-allowed', fontWeight: 'bold'}}} focused label={lang.total_price_parcel} fullWidth size="small" inputProps={{readOnly: true}} value={(getTotalPrice().parcel)|| ' - '} />
			</Grid>

			<Grid item xs={12} >
				<FormControl size="small" fullWidth>
					<InputLabel id="operators">{lang.operators}</InputLabel>
					<Select
						multiple size="small" labelId="operators" 
						label={lang.operators} value={model.operators} 
						onChange={(evt) => onChange('operators', evt.target.value)}
						renderValue={(selected) => selected.map(id => $data.getOperatorById(operators, id)).join(', ')} fullWidth>
						{operators.map((obj, key) => {
							
							return (
								<MenuItem key={key} value={obj.pk}>
									<Checkbox checked={model.operators.indexOf(obj.pk) > -1} />
									<ListItemText primary={`${obj.first_name} ${obj.last_name}`} />
								</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.fuel_consumption ? true : false} helperText={props.error?.common_activity?.fuel_consumption || ''} label={lang.fuel_consumption} type="number" fullWidth onChange={(evt) => onChange('fuel_consumption', evt.target.value)} size="small" value={model.fuel_consumption} />
			</Grid>

			<Grid item xs={12} sm={6} >
				<FormControl size="small" error={props.error.machine !== undefined} fullWidth>
					<InputLabel id="machine">{lang.fld_machine}</InputLabel>
					<Select size="small" labelId="machine" label={lang.fld_machine} value={model.machine || ''} onChange={(evt) => onChange('machine', evt.target.value)} fullWidth>
						{machines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.machine && <FormHelperText>{props.error.machine}</FormHelperText>}
				</FormControl>		
			</Grid>
			<Grid item xs={12} sm={6} >
				<FormControl size="small" error={props.error.conn_machine !== undefined} fullWidth>
					<InputLabel id="con_machine">{lang.fld_con_machine}</InputLabel>
					<Select size="small" labelId="con_machine" label={lang.fld_con_machine} value={model.conn_machine || ''} onChange={(evt) => onChange('conn_machine', evt.target.value)} fullWidth>
						{connectedMachines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
					</Select>
					{props.error.conn_machine && <FormHelperText>{props.error.conn_machine}</FormHelperText>}
				</FormControl>		
			</Grid>
			<Grid item xs={12}>
				<TextField error={props.error?.common_activity?.comment ? true : false} helperText={props.error?.common_activity?.comment || ''} multiline label={lang.comment} fullWidth size="small" onChange={(evt) => onChange('comment', evt.target.value)}  value={model.comment} />
			</Grid>	

			<Grid sx={{'& button': {ml: '5px'}}} style={{textAlign: 'right'}} item xs={12}>
				{props.isMap && <LoadingButton onClick={() => props.setStep(1)} size="small" loading={props.loading}><ArrowForward fontSize="small" />{lang.next}</LoadingButton>}

				{!props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.confirm}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" color="warning" variant="contained" type="button" onClick={(() => props.onSubmit(model, false))} loading={props.loading}>{lang.act_job_update}</LoadingButton>}
				{props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.archive_job}</LoadingButton>}
				{!props.edit && !props.isMap && <LoadingButton size="small" onClick={props.onClose} variant="contained" color="error" loading={props.loading} type="button">{lang.close}</LoadingButton>}
			</Grid>	

			{packageModal && <InputPerPackagePlanting isPlanting onUpdateModel={updateFromPackages} area={props.parcel.area} open={packageModal} onClose={() => setPackageModal(false)} />}

			<Message type="error" open={props.error.non_field_errors || props.error.file ? true : false} message={props.error.non_field_errors || props.error.file} onClose={props.closeErrorMessage} />

		</Grid>
	)
}

export default PlantingForm;
