import { AttachFile, Close, Delete, Download, Edit, Event, FileDownload, FilePresent, Refresh } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import Message from "../ui/Message";
import Divider from "../auth/Divider";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";
import _ from "lodash";
import {read, utils} from 'xlsx'

function SoilMappingModal(props) {
  const {lang} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = useMergeState({
    longitude: null,
    latitude: null,
    name: null,
    datestamp: null,
  });

  const [error, setError] = useMergeState({});
  
  useEffect(() => {
    if(props.open) {
     setState({
      longitude: null,
      latitude: null,
      name: null,
      datestamp: null,
     })
    }
  }, [props.open])

  const extractColumns = (rows) => {
    let columns = [];
    rows.map(obj => {
      columns = _.concat(columns, Object.keys(obj));
    })

    return [...new Set(columns)];
  }

  const renderDropDown = (stateProp) => {
    
    return (
      <FormControl error={Boolean(error && stateProp !== 'name' && stateProp !== 'date' ? error[stateProp] : null)} size="small" fullWidth>
        <InputLabel id={stateProp}>{lang.column_names}</InputLabel>
        <Select size="small" labelId={stateProp} label={lang.column_names} value={state[stateProp]} onChange={(evt) => setState({[stateProp]: evt.target.value})} fullWidth>
          {props.columns.map((name,key) => {
            return <MenuItem key={key} value={name}>{name}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  const validate = () => {
    if(state.longitude && state.latitude) {
      return true
    }

    return false
  }

  const createModel = () => {
    if(!validate()) {
      setError({
        longitude: true,
        latitude: true
      })

      return 
    };
    
    let columns = props.columns.map(name => {

      let changedProp = name;
      Object.keys(state).map(prop => {
        if(name === state[prop]) {
          changedProp = prop;
        }
      })
      
      return  changedProp
    });

    let rows = props.rows.map(obj => {
      let mappings = {};
      let newObj = {...obj};

      Object.keys(state).map(prop => {
        if(state[prop]) {
          mappings[state[prop]] = prop;
        }
      })

      Object.keys(obj).map(name => {
        delete newObj[name];
        if(mappings[name]) {
          newObj[mappings[name]] = obj[name];
        } else {
          newObj[name] = obj[name];
        }
      })

      return {...newObj}

    })

    props.setRows(rows);

    props.setColumns(columns);
   
    props.onSuccess()
    
  }

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="md" open={props.open} onClose={(evt, reason) => {
      if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
        props.onClose();
      }
    }} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.soil_field_mapping}
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{fontWeight: 'bold'}} color="primary">{lang.soil_field_mapping_description}:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table size="small" >
                <TableBody>
            
                  <TableRow>
                  <TableCell sx={{color: error?.longitude ? 'red' : '#000'}}>{lang.soil_longitude} <span className="text-danger">*</span> </TableCell>
                    <TableCell>{renderDropDown('longitude')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{color: error?.latitude ? 'red' : '#000'}}>{lang.soil_latitude} <span className="text-danger">*</span> </TableCell>
                    <TableCell>{renderDropDown('latitude')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{lang.soil_name}</TableCell>
                    <TableCell>{renderDropDown('name')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{lang.soil_datestamp}</TableCell>
                    <TableCell>{renderDropDown('datestamp')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="primary" variant="contained" size="small" onClick={createModel}>{lang.confirm}</LoadingButton>
        <LoadingButton color="error" variant="contained" size="small" onClick={props.onClose}>{lang.cancel}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SoilMappingModal;