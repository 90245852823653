import { Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
import _ from "lodash";
import OrderPreview from "./OrderPreview";


function Billing(props) {
  const {lang, cropTypes, cropGroups, colors, parcels} = useContext(AppContext);

  const [selected, setSelected] = useState([]);

  const [order, setOrder] = useState(false);

  const [inactiveOnly, setInactiveOnly] = useState(false);

  const setSelectedParcels = (pk, bool) => {
    if(pk === 'all') {
      setSelected(bool ? parcels.features.filter(f => f.properties.active === false).map(f => f.properties.pk) : []);
      return
    }

    bool ? setSelected(_.concat(selected, pk)) : setSelected(selected.filter(id => id !== pk))

  }

  const renderParcels = (data) => {
    if(props.loading.parcels) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }

    if(!data) return

    if(data.length === 0) return (
      <TableRow>
        <TableCell colSpan={4}>{lang.no_fields}</TableCell>
      </TableRow>
    )

    let filtered = data;
    if(inactiveOnly) {
      filtered = data.filter(obj => !obj.properties.active);

      if(filtered.length === 0) return (
        <TableRow>
          <TableCell colSpan={4}>
            <p className="text-center">{lang.fld_no_filtered_parcels}</p>
          </TableCell>
        </TableRow>
      )
    }

    
    return filtered.filter((obj, key) => key <= props.multiply * 20).map((obj, key) => {
      let image = null;
      try {
        let svgStr = geojson2svg().styles((f) => ({fill: f.properties.activeCrop.crop === 999 ? colors.cropGroup['5'] : colors.cropGroup[$data.getCropGroupIDByCrop(f.properties.activeCrop.crop, cropTypes)], opacity: 1, weight: 4,})).data(obj).render();

        let blob = new Blob([svgStr], {type: 'image/svg+xml'});
        let src = window.URL.createObjectURL(blob);
        image = <img style={{display: 'block', transform: 'scaleX(-1) rotate(180deg)', maxWidth: '100%', height: '100%'}} src={src} />


      } catch(e) {console.log(e)}

      obj = obj.properties;

      return (
        <TableRow key={key}>
          <TableCell>{!obj.active && <Checkbox checked={selected.indexOf(obj.pk) > -1} onChange={(evt, checked) => setSelectedParcels(obj.pk, checked)} />}</TableCell>
          <TableCell>
            <Stack direction="row" alignItems={'center'}>
              <div style={{width: 40, height: 40}}>
                {image}
              </div>&nbsp;&nbsp;
            {obj.name}
              </Stack>
           
          </TableCell>
          <TableCell style={{textTransform: 'uppercase', fontWeight: 'bold', color: obj.activeCrop.crop !== 999 ? colors.cropGroup[$data.getCropGroupIDByCrop(obj.activeCrop.crop, cropTypes)] : colors.cropGroup['5']}}>{$data.getCropById(cropTypes, obj.activeCrop.crop)}</TableCell>
          <TableCell>{obj.area.toFixed(2)} ha</TableCell>
          <TableCell>{obj.active ? <strong className="text-success">{lang.parcel_active.toUpperCase()}</strong> : <strong className="text-danger">{lang.parcel_inactive.toUpperCase()}</strong>}</TableCell>
          <TableCell>{obj.active_to || ' - '}</TableCell>
        </TableRow>
      )
    })
  }

  const renderParcelsMobile = (data) => {
    if(props.loading.parcels) {
      return <Grid item xs={12}><CircularProgress /></Grid>
    }

    if(!data) return

    if(data.length === 0) return (
      <Grid item xs={12}>
        <p className="text-center">{lang.no_fields}</p>
      </Grid>
    )

    console.log(_.sum(data.map(f => f.properties.area))); 

    let filtered = data;
    if(inactiveOnly) {
      filtered = data.filter(obj => !obj.properties.active);

      if(filtered.length === 0) return (
        <Grid item xs={12}>
          <p className="text-center">{lang.fld_no_filtered_parcels}</p>
        </Grid>
      )
    }

   

    return filtered.map((obj, key) => {
      let image = null;
      try {
        let svgStr = geojson2svg().styles((f) => ({fill: f.properties.activeCrop.crop === 999 ? colors.cropGroup['5'] : colors.cropGroup[$data.getCropGroupIDByCrop(f.properties.activeCrop.crop, cropTypes)], opacity: 1, weight: 4,})).data(obj).render();

        let blob = new Blob([svgStr], {type: 'image/svg+xml'});
        let src = window.URL.createObjectURL(blob);
        image = <img style={{display: 'block', transform: 'scaleX(-1) rotate(180deg)', maxWidth: '100%', height: '100%'}} src={src} />


      } catch(e) {console.log(e)}

      return (
        <Grid key={key} item xs={12}>
          <Stack sx={{padding: '15px'}} direction="row" justifyContent="space-between" alignItems={'center'} flexWrap='wrap' spacing={2} component={Paper} elevation={3}>
            <Stack direction="row" spacing={2} alignItems="flex-start">
              <div style={{width: 40, height: 40}}>
                {image}
              </div>
              <div>
                <strong className="text-primary">{obj.properties.name}</strong>
                <p style={{fontSize: '14px', textTransform: 'uppercase', marginBottom: '7px', marginTop: '7px', color: colors.cropGroup[$data.getCropGroupIDByCrop(obj.properties.activeCrop.crop, cropTypes)], fontWeight: 'bold'}}>{$data.getCropById(cropTypes, obj.properties.activeCrop.crop)}</p>
                <p style={{marginBottom: '4px', marginTop: '4px'}}>{obj.properties.area.toFixed(2)}ha</p>
              </div>
            
            </Stack>
            <div style={{margin: '0 0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              {obj.properties.active ? <strong className="text-success">{lang.parcel_active.toUpperCase()}</strong> : <strong className="text-danger">{lang.parcel_inactive.toUpperCase()}</strong>}
              {!obj.properties.active && <Checkbox checked={selected.indexOf(obj.properties.pk) > -1} onChange={(evt, checked) => setSelectedParcels(obj.properties.pk, checked)} />}
            
            </div>
          </Stack>  
        </Grid>
      )
    })
    
  }

  const renderContentMobile = () => {
    return (
      <Grid className="account-container billing-container billing-container-mobile" container spacing={2}>
        {!parcels.features.every(f => f.properties.active) && <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <FormGroup>
              <FormControlLabel sx={{margin: 0}} control={<Switch checked={inactiveOnly} onChange={(evt, checked) => setInactiveOnly(checked)} />}label={'Inactive only'}/>
            </FormGroup>
            <FormGroup sx={{paddingRight: '25px'}}>
              <FormControlLabel labelPlacement="start" control={<Checkbox checked={selected.length === parcels.features.filter(f => f.properties.active === false).length} onChange={(evt, checked) => setSelectedParcels('all', checked)} />} label={lang.fld_select_all_parcels} />
            </FormGroup>
          </Stack>
         
        </Grid>}
        {renderParcelsMobile(parcels.features)}
        <Grid item xs={12}></Grid>
        <Grid className="order-preview-sticky" style={{position: 'sticky', zIndex: 2, bottom: 0, paddingBottom: '10px', paddingRight: '10px', background: "#fff", textAlign: 'right'}} item xs={12}>
          <Button onClick={() => setOrder(true)} variant="contained" color="primary" disabled={selected.length === 0}>{lang.billing_request_continue}</Button>
        </Grid>

        <OrderPreview open={order} onClose={() => setOrder(false)} parcels={selected.map(id => $data.getParcelById(id).properties)} />
      </Grid>
    )
  }

  return (
    <>
    {renderContentMobile()}
    <Grid className="account-container billing-container" container spacing={2}>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel sx={{margin: 0}} control={<Switch checked={inactiveOnly} onChange={(evt, checked) => setInactiveOnly(checked)} />}label={'Inactive only'}/>
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{overflowX: 'auto',marginBottom: '10px'}} elevation={4}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{!parcels.features.every(f => f.properties.active) && <Checkbox checked={selected.length === parcels.features.filter(f => f.properties.active === false).length} onChange={(evt, checked) => setSelectedParcels('all', checked)} />}</TableCell>
                <TableCell>{lang.parcel_name}</TableCell>
                <TableCell>{lang.parcel_crop}</TableCell>
                <TableCell>{lang.parcel_area}</TableCell>
                <TableCell>{lang.parcel_status}</TableCell>
                <TableCell>{lang.parcel_status_to}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {renderParcels(parcels.features)}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid className="order-preview-sticky" style={{position: 'sticky', zIndex: 2, bottom: 0, paddingBottom: '10px', paddingRight: '10px', background: "#fff", textAlign: 'right'}} item xs={12}>
        <Button onClick={() => setOrder(true)} variant="contained" color="primary" disabled={selected.length === 0}>{lang.billing_request_continue}</Button>
      </Grid>

      <OrderPreview open={order} onClose={() => setOrder(false)} parcels={selected.map(id => $data.getParcelById(id).properties)} />
    </Grid>
    </>
  )
}

export default Billing;