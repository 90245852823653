import { Container, Grid, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import Account from "./Account";
import Billing from "./Billing";
import useMergeState from '../hooks/useMergeState';

function Profile(props) {
  const containerRef = useRef(null);
  const tabRef = useRef(1);

  const context = useContext(AppContext);
  const {lang, user, onUpdateState} = context;

  let isBillingRoute = window.location.search.split('?')[1] === 'billing';

  const [tab, setTab] = useState(isBillingRoute ? 1 : 0);

  const [employees, setEmployees] = useMergeState({data: {count: 0, results: []}, loading: false});
  const [parcels, setParcels] = useMergeState({data: [], loading: false});
  const [settings, setSettings] = useMergeState({data: {s2_mail: false, s2_notification: false, sms: false}, loading: false});

  const [multiply, setMultiply] = useState(1);
  let counter = 1;


  const handleScroll = (evt) => {
    if((evt.target.scrollHeight - evt.target.scrollTop) <= containerRef.current.offsetHeight + 200) {
      if(tabRef.current === 1) {
        counter += 1;
        setMultiply(counter);  
      }
    }
  }

  const getEmployees = (page) => {
    setEmployees({loading: true});
    $data.getEmployees(page)
      .then(data => setEmployees({data, loading: false}))
      .catch(err => setEmployees({loading: false}));
  }

  const getNotificationSettings = () => {
    setSettings({loading: true});
    $data.getNotificationSettings()
      .then(data => setSettings({data, loading: false}))
      .catch(err => setSettings({loading: false}))
  }

  useEffect(() => {
    onUpdateState({view: 'account_settings'});

    containerRef.current.addEventListener('scroll', handleScroll);

    if(!user.is_add_user) {
      getEmployees(); 
    }
    
    getNotificationSettings();

  }, [])

  return (
    <Container ref={containerRef} className="profile-container">
      <Tabs value={tab} onChange={(evt, val) => {tabRef.current = val; setTab(val); if(val === 0) {counter = 1; setMultiply(1)}}} variant="fullWidth">
        <Tab label={lang.account} />
        {!user.isOperater && <Tab label={lang.billing} />}
      </Tabs>

      <div style={{paddingTop: '20px'}} role="tabpanel" hidden={tab !== 0} id={`simple-tabpanel-0`}>
        <Account 
          loading={{employees: employees.loading, settings: employees.loading}} 
          employees={employees.data}
          parcels={parcels.data}
          settings={settings.data}
          getEmployees={getEmployees}
          getNotificationSettings={getNotificationSettings}
           />
      </div>
      {!user.isOperater && <div style={{paddingTop: '20px'}} role="tabpanel" hidden={tab !== 1} id={`simple-tabpanel-1`}>
        <Billing multiply={multiply} loading={{parcels: parcels.loading}} parcels={parcels.data} />
      </div>}

    </Container>
  )
}

export default Profile;