const style = {
  width: '100px',
  margin: '0 auto 15px auto',
  border: 'none',
  height: '5px',
  background: 'linear-gradient(to left, #416959, #6ba58e)'
}

export default function Divider(props) {
  let userStyle = props.style || {};

  return <hr style={Object.assign({}, style, userStyle)} />
}