import { Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";

function PanelError(props) {
  let {lang, danger} = useContext(AppContext);

  return (
    <div style={{textAlign: 'center', padding: '10px', width: '260px'}} >
      <Typography variant="h6" style={{textAlign: 'center'}} color={danger}>Error occured during content rendering</Typography>
    </div>
  )
}

function MapError(props) {
  let {lang, danger} = useContext(AppContext);

  return (
    <div style={{textAlign: 'center', padding: '10px', width: '260px'}} >
      <Typography variant="h6" style={{textAlign: 'center'}} color={danger}>Error occured during content rendering</Typography>
    </div>
  )
}

function DataPanelError(props) {
  let {lang, danger} = useContext(AppContext);

  return (
    <div style={{textAlign: 'center', padding: '10px', width: '260px'}} >
      <Typography variant="h6" style={{textAlign: 'center'}} color={danger}>Error occured during content rendering</Typography>
    </div>
  )
}


const errors = {
  app: null,
  panel: <PanelError />,
  map: <MapError />,
  datapanel: <DataPanelError />
}

export default errors;