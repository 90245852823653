import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import {Logout, Notifications as NotificationIcon, Person as UserIcon} from '@mui/icons-material'
import { Avatar, Badge, Chip, LinearProgress, List, ListItem, ListItemText, Paper, Popover, Stack, Tooltip } from "@mui/material";
import Logo from "../../Logo";
import { Link } from "react-router-dom";
import $auth from "../../services/$auth";
import _ from "lodash";
import $data from "../../services/$data";
import moment from "moment";
import Notifications from "./Notifications";
import { useTabNotification } from "../hooks/useTabNotification";

function Header(props) {
  let context = useContext(AppContext);
  let interval = useRef(null);
  let {view, lang, primary, secondary, user, onUpdateState} = context;

  const [notifications, setNotifications] = useState([]);

  const [popover, setPopover] = useState(false);

  const [notificationsModal, setNotificationsModal] = useState(false);

  const [setMessage, clearMessage] = useTabNotification(1000);



  // let bids = useRef([]);
  // const ws = useRef(null)
  // const apiCall = {
  //   event: "bts:subscribe",
  //   data: { channel: "order_book_btcusd" },
  // };

  // const [update, setUpdate] = useState(false)

  // useEffect(() => {
  //   ws.current = new WebSocket('wss://demo.piesocket.com/v3/channel_1?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV&notify_self');

  //   ws.current.onopen = (event) => {
  //     // ws.current.send(JSON.stringify(apiCall))
  //   }

  //   ws.current.onmessage = (event) => {
      
  //     try {
  //       let json = JSON.parse(event.data);
  //       if (json) {
  //         bids.current = _.concat(bids.current, [json])
  //         setUpdate(!update)
          
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       setUpdate(!update)
  //     }
  //   };
  
  //   ws.current.onerror = (evt) => {
  //     console.log(evt)
  //   }
  
  // }, [])

  // console.log(bids.current)



  useEffect(() => {
    getNotifications();
    setNotificationInterval();

    return () => {
      clearInterval(interval.current);
    }
  }, [])

  const getNotifications = () => {
    $data.getNotifications()
      .then(data => {
        setNotifications(data);
        let unread = data.filter(obj => !obj.read).length;

        if(unread > 0) {
          setMessage(`(${unread}) ${document.title}`);
        }
      })
      .catch(err => {
        console.log(err)
      });

  }

  const setNotificationInterval = () => {
    interval.current = setInterval(() => {
      $data.getNotifications()
    }, 60000);
  }

  const setNotificationsRead = () => {
    let list = notifications.map(obj => {
      if(obj.read == false) {
        $data.putNotification({...obj, read: true}, obj.pk)
          .then(data => {
            clearMessage(document.title)
          })
          .catch(err => {
            console.log(err)
          })
      }

      return ({...obj, read: true})
    });
    setNotifications(list);

    
  }
  const renderNotifications = () => {
    let list = [];

    if(notifications.length === 0) {
      list.push(<ListItem key={1}>{lang.no_notifications}</ListItem>)
    };

    notifications.filter((obj, key) => key < 5).map((obj, key) => { list.push( <ListItem style={{borderBottom: '1px solid rgba(240,240,240, 1)', backgroundColor: obj.read ? 'transparent' : 'rgba(255, 255, 255, .9)'}} sx={{position: 'relative', padding: '7px 10px 10px 10px',}} key={key}><ListItemText>{obj.message}</ListItemText><span style={{position: 'absolute', bottom: 2, right: 7, fontStyle: 'italic', color: '#777', fontSize: '11px'}}>{moment(obj.date).format('DD-MMM-YYYY')}</span></ListItem> ) })

    {notifications.length > 5 && list.push(<ListItem style={{borderBottom: '1px solid rgba(240,240,240, 1)', backgroundColor: 'transparent'}} sx={{position: 'relative', padding: '7px 10px 10px 10px',}} key={-1}><ListItemText sx={{textAlign: 'center'}}>...</ListItemText></ListItem>)}

    return <List sx={{background: 'rgb(240,240,240)',maxWidth: '310px', width: '100%', padding: '10px'}} disablePadding>{list}</List>
  }

  let userImage = {};

  if(user?.image) {
    userImage = {
      src: user.image
    }
  }

  return [
    Object.values(props.loader).indexOf(true) > -1 ? (
      <div key={1} style={{position: "absolute", zIndex:10, top: 0, left: '50px', width: '100%'}}>
        <LinearProgress />
      </div>
    ) : null,
    <Stack key={2} direction='row' justifyContent={'space-between'} alignItems={'center'} className="header-container">
      
      <Stack direction="row" alignItems="center">
        <Logo style={{height: '45px', width: '90px',margin: '0 10px 0 2.5px'}} />
        <span className="view-title">{lang[view + '_view']}</span>
      </Stack>

      <Stack direction="row" justifyContent={'flex-end'} alignItems='center'>
          <Badge anchorOrigin={{vertical: 'top', horizontal: 'left'}} badgeContent={notifications.filter(obj => obj.read === false).length} color="error">
            <Tooltip placement="left" arrow title={lang.notifications}>
              {/* <div > */}
                <NotificationIcon onClick={() => {setNotificationsRead(); setNotificationsModal(true)}} style={{position: 'relative'}} onMouseEnter={(evt) => setPopover(evt.target)} onMouseLeave={(evt) => setPopover(null)}  sx={{cursor: 'pointer', color: primary,'&:hover': {color: secondary}}} color="primary" className="header-icon header-item" />
              {/* </div> */}
            </Tooltip>
           
          </Badge>
          <Popover sx={{
          pointerEvents: 'none',
        }} disableRestoreFocus open={Boolean(popover)} anchorEl={popover} anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
              {renderNotifications()}
            </Popover>
        <Link className="is-mobile" to="/dashboard/profile">
          <Tooltip arrow title={lang.account_settings}>
          <Avatar sx={{background: 'transparent', color: primary, '&:hover': {color: secondary}}} {...userImage} className="header-item">
              {!user?.image && <UserIcon color="primary" />}
            </Avatar>            
          </Tooltip>
        </Link>
        <Link className="is-desktop" to="/dashboard/profile">
          <Tooltip arrow title={lang.account_settings}>
            <Chip sx={{m: 'auto 10px', cursor: 'pointer', color: '#548974', background: 'transparent'}} avatar={<Avatar sx={{background: 'transparent', color: primary, '&:hover': {color: secondary}}} {...userImage} className="header-item">
              {!user?.image && <UserIcon color="primary" />}
            </Avatar>} label={(user?.first_name && user?.last_name) ? `${user?.first_name} ${user?.last_name}` : user?.email} component={Paper} elevation={3} />
            
          </Tooltip>
        </Link>
        <Tooltip  arrow placement='bottom' title={lang.logout}>
          <Logout onClick={async () => {
            try {
              await $auth.logout();
            } catch (error) {
              $auth.unauthorize();
            } finally {
              window.location.reload();
            }
          }} sx={{ml: '3px', mr: '3px', cursor: 'pointer', color: primary,'&:hover': {color: secondary}}} />
        </Tooltip>
      </Stack>
      
      <Notifications open={notificationsModal} notifications={notifications} onClose={() => setNotificationsModal(false)}  />
    </Stack>
  ]
}

export default Header;