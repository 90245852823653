import { Add, Close, Edit } from "@mui/icons-material";
import { Paper, Stack, Table, TableRow, TableContainer, TableHead, Button, FormControl, InputLabel, Select, MenuItem, TableCell, TableBody, TextField, Autocomplete, TableFooter, Grid, Chip } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import $activities from "../../../services/$activities";
import $data from "../../../services/$data";
import useMergeState from "../../hooks/useMergeState";
import PlantProtectionModal from "./PlantProtectionModal";


function PlantProtectionTable(props) {
	const {lang, plantProtectionTypes, plantProtectionProducts, plantProtectionSubstances} = useContext(AppContext);

	const rows = props.protections || [];

	const initialState = {
		protection_type: plantProtectionTypes[1].id,
		product: '',
		active_substance: '',
		quantity_ha: 0,
		quantity_parcel: 0,
		quantity_unit: 1,
		product_unit_price: 0,
		product_price_ha: 0,
		product_price_parcel: 0
	};

	const [showForm, setShowForm] = useState(false);

	const [model, setModel] = useMergeState({...initialState});

	const onChange = (name, val) => {
		let {value, additional} = $activities.getPlantProtectionPrices(name, val, model, props.parcel.area);

		setModel({[name]: value, ...additional});

	}

	const addRecord = (model) => {
		props.addRecord( Object.assign({}, {...model, uid: (new Date().getTime()) * Math.random(10)}) );
		// setModel({...initialState});
	}

	const renderRows = () => {
		let list = []
		if(rows.length === 0) {
			list.push(<TableRow key={-1}><TableCell colSpan={10}> {lang.plant_protection_no_data} </TableCell></TableRow>)
		} else {
			list = rows.map((obj, key) => (
				<TableRow sx={{'& td': {whiteSpace: 'wrap'}}} key={key}>
					<TableCell><Close style={{cursor: 'pointer'}} onClick={() => props.removeRecord(obj.pk ? obj.pk : obj.uid)} color="error" /></TableCell>
					<TableCell>{obj.natural !== null ? <Chip variant="filled" size="small" sx={{textTransform: 'uppercase', fontWeight: 'bold'}} label={obj.natural ? lang.natural : lang.syntethic} color={obj.natural ? "primary" : "warning"} />  : ' - '}</TableCell>
					<TableCell>{$data.getProtectionTypeById(plantProtectionTypes, obj.protection_type)}</TableCell>
					<TableCell>{obj.product || ' - '}</TableCell>
					<TableCell>{obj.active_substance || ' - '}</TableCell>
					<TableCell>{obj.quantity_ha ? obj.quantity_ha + ` ${(obj.quantity_unit === 1 ? 'kg' : 'l')}` : ' - '}</TableCell>
					<TableCell>{obj.quantity_parcel ? obj.quantity_parcel + ` ${(obj.quantity_unit === 1 ? 'kg' : 'l')}` : ' - '}</TableCell>
					<TableCell>{obj.product_unit_price || ' - '}</TableCell>
					<TableCell>{obj.product_price_ha}</TableCell>
					<TableCell>{obj.product_price_parcel}</TableCell>
				</TableRow>
			))
		}
		return list;
	}

	const renderForm = () => {
		return (
			<Stack direction="column" sx={{padding: '20px'}}>
				<div style={{paddingBottom: '20px'}}>
					<Button style={{marginRight: '5px'}} endIcon={<Edit />} onClick={() => setShowForm(true)} size="small" color="warning" variant="contained">{lang.show_form}</Button>
					{/* {showForm && <Button style={{marginRight: '5px'}} endIcon={<Close />} onClick={() => setShowForm(false)} size="small" color="error" variant="contained">{lang.close}</Button>}
					{showForm && <Button style={{marginRight: '5px'}} endIcon={<Add />} onClick={addRecord} size="small" variant="contained">{lang.add_protection_row}</Button>} */}
				</div>
				{showForm && <PlantProtectionModal  addRecord={addRecord} parcel={props.parcel} open={showForm} onClose={() => setShowForm(false)} />}
				

			</Stack>
		)
	}

	return (
		<TableContainer sx={{marginBottom: '20px'}} component={Paper} elevation={3}>
			<Table size="small">
				<TableHead>
					<TableRow>
					<TableCell></TableCell>
					<TableCell>{lang.natural_type}</TableCell>
						<TableCell>{lang.protection_type}</TableCell>
						<TableCell style={{minWidth: '150px'}}>{lang.product}</TableCell>
						<TableCell style={{minWidth: '150px'}}>{lang.active_substance}</TableCell>
						<TableCell>{lang.quantity_ha}</TableCell>
						<TableCell>{lang.quantity_parcel}</TableCell>
						<TableCell>{lang.product_unit_price}</TableCell>
						<TableCell>{lang.product_price_ha}</TableCell>
						<TableCell>{lang.product_price_parcel}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{renderRows()}
				</TableBody>
			</Table>
			{renderForm()}
		</TableContainer>
	)
}

export default PlantProtectionTable;