import { Autocomplete, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import colors from "../../config/colors";
import { Add, Close, Delete, Download, Event } from "@mui/icons-material";
import ReactDOMServer from 'react-dom/server';
import { LoadingButton, LocalizationProvider, MobileDatePicker, PickersDay } from "@mui/lab";
import FieldItem from "./FieldItem";
import Divider from "../auth/Divider";
import practices from "../../config/practices";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";

function DeleteRegenerativePractice(props) {
  const { lang, locale } = useContext(AppContext);
  const containerElement = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);


  const onClose = (evt, reason) => {
    if (loading) {
      return
    } else {
      props.onClose()
    }
  }

  const onSubmit = () => {
    $data.deletePractice(props.selected)
      .then(data => {
        setLoading(false);
        props.onSuccess();
        onClose();
      })
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={props.open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.act_delete_practices}<Close onClick={onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent ref={containerElement} >
        <Grid sx={{ mt: '20px', mb: '30px' }} container spacing={2}>

          <Grid item xs={12}>
            <Typography color="error" variant="body1" sx={{textAlign: 'center'}}><strong>{lang.act_practice_delete_question}</strong></Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} color="primary" variant="text" size="small" onClick={onClose}>{lang.close}</LoadingButton>
        <LoadingButton loading={loading} endIcon={<Delete />} color="error" variant="contained" size="small" onClick={onSubmit}>{lang.delete}</LoadingButton>

      </DialogActions>
    </Dialog>
  )
}

export default DeleteRegenerativePractice;