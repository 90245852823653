import moment from "moment";

class $seasons {
  getMinMaxFromSeasons(seasons) {
    let minSeason = seasons[0];
    let maxSeason = seasons[seasons.length - 1];

    return {min: minSeason + '-08-01', max: (maxSeason + 1) + '-07-31'}
  }

  getSeasonFromDate(date) {
    let year = parseInt(moment(date).format('YYYY'));
    
    if(moment(date).diff(moment(`${year}-08-01`)) >= 0) {
      return year;
    }

    return year - 1
  }

  formatSeason(season) {
    return `${season}/${parseInt(season) + 1}`
  }

  getDaysFromTo(from, to) {
    let days = [from];
    let day = from;
    while (moment(day).diff(moment(to)) < 0) {
      day = moment(day).add(1, 'days').format('YYYY-MM-DD')
      days.push(day);
    }

    return days

  }

  getCurrentSeasonDates() {
    let season = this.getSeasonFromDate(moment().format('YYYY-MM-DD'));
    
    return this.getMinMaxFromSeasons(season);
  }

  getSeasonBoundariesFromDate(date) {
    let s = this.getSeasonFromDate(date);

    return this.getMinMaxFromSeasons([s])
  }
}

export default new $seasons();