import moment from "moment";
import $seasons from "../../services/$seasons";
import colors from "../colors";

export const formatData = (data, field, name, colors, filter, isPlanetUser) => {
	let datasets;
	if (data.length === 1) {
		datasets = [{
			data: filter === 'all' ? data[0]['s2'][field] : data[0]['s2'][field],
			// .filter(obj => moment(obj.x).diff(moment(filter.from)) >= 0 && moment(filter.to).diff(moment(obj.x)) >= 0),
			label: name + (isPlanetUser ? ' - Sentinel-2' : ''),
			borderColor: colors[0],
			borderWidth: 1,
			pointBackgroundColor: colors[0],
			pointRadius: 1,
			fill: false,
			spanGaps: true,
			source: 's2'
		}];

		if (isPlanetUser) {
			datasets.push({
				data: filter === 'all' ? data[0]['planet'][field] : data[0]['planet'][field],
				// .filter(obj => moment(obj.x).diff(moment(filter.from)) >= 0 && moment(filter.to).diff(moment(obj.x)) >= 0),
				label: name + ' - PLANET',
				borderColor: colors[1],
				borderWidth: 1,
				pointBackgroundColor: colors[1],
				pointRadius: 0,
				fill: false,
				spanGaps: true,
				source: 'planet'
			})
		}

		return { datasets }
	}

	if (data.length === 2) {
		datasets = [{
			data: filter === 'all' ? data[0]['s2'][field] : data[0]['s2'][field],
			// .filter(obj => moment(obj.x).diff(moment(filter.from)) >= 0 && moment(filter.to).diff(moment(obj.x)) >= 0),
			label: name + (isPlanetUser ? ' - Sentinel-2 (A)' : ' (A)'),
			borderColor: colors[0],
			borderWidth: 1,
			pointBackgroundColor: colors[0],
			pointRadius: 1,
			fill: false,
			spanGaps: true,
			source: 's2'
		}];

		if (isPlanetUser) {
			datasets.push({
				data: filter === 'all' ? data[0]['planet'][field] : data[0]['planet'][field],
				// .filter(obj => moment(obj.x).diff(moment(filter.from)) >= 0 && moment(filter.to).diff(moment(obj.x)) >= 0),
				label: name + ' - PLANET (A)',
				borderColor: colors[1],
				borderWidth: 1,
				pointBackgroundColor: colors[1],
				pointRadius: 0,
				fill: false,
				spanGaps: true,
				source: 'planet'
			})

		}

		datasets.push({
			data: filter === 'all' ? data[1]['s2'][field] : data[1]['s2'][field],
			// .filter(obj => moment(obj.x).diff(moment(filter.from)) >= 0 && moment(filter.to).diff(moment(obj.x)) >= 0),
			label: name + (isPlanetUser ? ' - Sentinel-2 (B)' : ' (B)'),
			borderColor: colors[2],
			borderWidth: 1,
			pointBackgroundColor: colors[2],
			pointRadius: 1,
			fill: false,
			spanGaps: true,
			source: 's2'
		});

		if (isPlanetUser) {
			datasets.push({
				data: filter === 'all' ? data[1]['planet'][field] : data[1]['planet'][field],
				// .filter(obj => moment(obj.x).diff(moment(filter.from)) >= 0 && moment(filter.to).diff(moment(obj.x)) >= 0),
				label: name + ' - PLANET (B)',
				borderColor: colors[3],
				borderWidth: 1,
				pointBackgroundColor: colors[3],
				pointRadius: 0,
				fill: false,
				spanGaps: true,
				source: 'planet'
			})
		}
	}
	return { datasets }

}

const checkOverlaps = (crops = [], exclude = [], crop) => {

	let cID = null;

	crops.map(c => {
		//ISTI CROP - VRATI NULL
		if (c.id === crop.id) return;
		let rangeSowing = moment(c.sowing);
		let cropSowing = moment(crop.sowing);

		//OBA CROPA NEMAJU HARVEST
		if (!c.harvest && !crop.harvest) {
			if (c.sowing === crop.sowing) {
				cID = crop.id;
			}

			return;
		}

		//POSLATI CROP NEMA HARVEST
		if (c.harvest && !crop.harvest) {
			let rangeHarvest = moment(c.harvest);
			if (cropSowing.diff(rangeSowing) >= 0 && cropSowing.diff(rangeHarvest) <= 0) {
				cID = crop.id;
			}
			return;
		}

		//CROP IZ PETLJE NEMA HARVEST
		if (!c.harvest && crop.harvest) {
			let cropHarvest = moment(crop.harvest);
			if (rangeSowing.diff(cropSowing) >= 0 && rangeSowing.diff(cropHarvest) <= 0) {
				cID = crop.id;
			}
			return;
		}

		//OBA IMAJU HARVEST
		if (c.harvest && crop.harvest) {
			let cropHarvest = moment(crop.harvest);
			let rangeHarvest = moment(c.harvest);

			if (cropSowing.diff(rangeHarvest) < 0 && rangeSowing.diff(cropHarvest) < 0) {
				cID = crop.id;
			}

			return
		}

	})

	return cID;
}

const createLanes = (crops) => {
	let distinct = {
		'clear': [],
		'overlapped': []
	}

	let overlapped = [];

	crops.map((obj, key) => {
		let hasOverlap = checkOverlaps(crops, null, obj);
		if (Boolean(hasOverlap) && overlapped.indexOf(hasOverlap) === -1) {
			overlapped.push(hasOverlap);
			distinct['overlapped'].push(obj);
		} else {
			distinct['clear'].push(obj);
		}
	});

	let lanes = {
		1: distinct.clear,
		2: []
	}

	distinct.overlapped.map((obj, key) => {

		if(key % 2 === 0) {
			lanes[1].push(obj);
		} else {
			lanes[2].push(obj)
		}
	})


	return lanes;
}

const formatMainCrops = (crops = [], lang, isNdvi) => {
	let annotations = {};

	let lanes = createLanes(crops);


	lanes[2].map((obj, key) => {
		//SOWING
		annotations[`sowing${key + 9999}`] = {
			type: 'line',
			xMin: obj.sowing,
			xMax: obj.sowing,
			yMin: isNdvi ? 0.55 : 6.5,
			yMax: isNdvi ? 1 : 12,
			mode: 'vertical',
			borderColor: colors.cropGroup[obj.crop.crop_group],
			// scaleID: 'x',
			yScaleID: 'y',
			xScaleID: 'x',
			borderWidth: 5,
			drawTime: 'beforeDatasetsDraw',
			enter(ctx, event) {
				const chart = ctx.chart;
				const annotationOpts = chart.options.plugins.annotation.annotations[`sowing${key + 9999}`];
				annotationOpts.label.enabled = true;
				annotationOpts.label.position = (event.x);
				chart.update();
			},
			leave(ctx, event) {
				const chart = ctx.chart;
				const annotationOpts = chart.options.plugins.annotation.annotations[`sowing${key + 9999}`];
				annotationOpts.label.enabled = false;
				chart.update();
			},
			label: {
				position: 'end',
				drawTime: 'afterDatasetsDraw',
				enabled: false,
				content: obj.harvest ? [`${lang.parcel_sowing}: ${moment(obj.sowing).format('DD-MMM-YYYY')}`, obj.crop.name] : [`${lang.parcel_sowing}: ${moment(obj.sowing).format('DD-MMM-YYYY')}`, `${lang.parcel_harvest}: -`, obj.crop.name],
				backgroundColor: 'rgba(0,0,0,0.7)',
				yAdjust: -40
			}
		}

		if (obj.harvest) {

			// SEASON BOX ANNOTATION
			annotations[`harvestBox${key + 9999}`] = {
				type: 'box',
				xMin: obj.sowing,
				xMax: obj.harvest,
				yMin: isNdvi ? 0.55 : 6.5,
				yMax: isNdvi ? 1 : 12,
				borderColor: 'transparent',
				backgroundColor: colors.cropGroup[obj.crop.crop_group] + '50',
				label: {
					enabled: true,
					content: obj.crop.name,
					color: '#55555599'
				}
			}

			//HARVEST LINE
			annotations[`harvest${key + 9999}`] = {
				type: 'line',
				value: obj.harvest,
				yMin: isNdvi ? 0.55 : 6.5,
			yMax: isNdvi ? 1 : 12,
				xMin: obj.harvest,
				xMax: obj.harvest,
				borderColor: colors.cropGroup[obj.crop.crop_group],
				mode: 'vertical',
				yScaleID: 'y',
				xScaleID: 'x',
				borderWidth: 5,
				drawTime: 'beforeDatasetsDraw',
				enter(ctx, event) {
					const chart = ctx.chart;
					const annotationOpts = chart.options.plugins.annotation.annotations[`harvest${key + 9999}`];
					annotationOpts.label.enabled = true;
					annotationOpts.label.position = (event.x / ctx.chart.chartArea.width * 100) + '%';
					chart.update();
				},
				leave(ctx, event) {
					const chart = ctx.chart;
					const annotationOpts = chart.options.plugins.annotation.annotations[`harvest${key + 9999}`];
					annotationOpts.label.enabled = false;
					chart.update();
				},
				label: {
					enabled: false,
					position: 'end',
					drawTime: 'afterDatasetsDraw',
					content: [`${lang.parcel_harvest}: ${moment(obj.harvest).format('DD-MMM-YYYY')}`, obj.crop.name],
					backgroundColor: 'rgba(0,0,0,0.7)',
					yAdjust: -40
				}
			}
		}
	})


	lanes[1].map((obj, key) => {


		//SOWING
		annotations[`sowing${key}`] = {
			type: 'line',
			xMin: obj.sowing,
			xMax: obj.sowing,
			yMin: isNdvi ? 0 : 0,
			yMax: isNdvi ? 0.45 : 5.5,
			mode: 'vertical',
			borderColor: colors.cropGroup[obj.crop.crop_group],
			// scaleID: 'x',
			yScaleID: 'y',
			xScaleID: 'x',
			borderWidth: 5,
			drawTime: 'beforeDatasetsDraw',
			enter(ctx, event) {
				const chart = ctx.chart;
				const annotationOpts = chart.options.plugins.annotation.annotations[`sowing${key}`];
				annotationOpts.label.enabled = true;
				annotationOpts.label.position = (event.x);
				chart.update();
			},
			leave(ctx, event) {
				const chart = ctx.chart;
				const annotationOpts = chart.options.plugins.annotation.annotations[`sowing${key}`];
				annotationOpts.label.enabled = false;
				chart.update();
			},
			label: {
				position: 'end',
				drawTime: 'afterDatasetsDraw',
				enabled: false,
				content: obj.harvest ? [`${lang.parcel_sowing}: ${moment(obj.sowing).format('DD-MMM-YYYY')}`, obj.crop.name] : [`${lang.parcel_sowing}: ${moment(obj.sowing).format('DD-MMM-YYYY')}`, `${lang.parcel_harvest}: -`, obj.crop.name],
				backgroundColor: 'rgba(0,0,0,0.7)',
				yAdjust: -40
			}
		}

		if (obj.harvest) {

			// SEASON BOX ANNOTATION
			annotations[`harvestBox${key}`] = {
				type: 'box',
				xMin: obj.sowing,
				xMax: obj.harvest,
				yMin: isNdvi ? 0 : 0,
				yMax: isNdvi ? 0.45 : 5.5,
					borderColor: 'transparent',
				backgroundColor: colors.cropGroup[obj.crop.crop_group] + '50',
				label: {
					enabled: true,
					content: obj.crop.name,
					color: '#55555599'
				}
			}

			//HARVEST LINE
			annotations[`harvest${key}`] = {
				type: 'line',
				value: obj.harvest,
				yMin: isNdvi ? 0 : 0,
				yMax: isNdvi ? 0.45 : 5.5,
					xMin: obj.harvest,
				xMax: obj.harvest,
				borderColor: colors.cropGroup[obj.crop.crop_group],
				mode: 'vertical',
				yScaleID: 'y',
				xScaleID: 'x',
				borderWidth: 5,
				drawTime: 'beforeDatasetsDraw',
				enter(ctx, event) {
					const chart = ctx.chart;
					const annotationOpts = chart.options.plugins.annotation.annotations[`harvest${key}`];
					annotationOpts.label.enabled = true;
					annotationOpts.label.position = (event.x / ctx.chart.chartArea.width * 100) + '%';
					chart.update();
				},
				leave(ctx, event) {
					const chart = ctx.chart;
					const annotationOpts = chart.options.plugins.annotation.annotations[`harvest${key}`];
					annotationOpts.label.enabled = false;
					chart.update();
				},
				label: {
					enabled: false,
					position: 'end',
					drawTime: 'afterDatasetsDraw',
					content: [`${lang.parcel_harvest}: ${moment(obj.harvest).format('DD-MMM-YYYY')}`, obj.crop.name],
					backgroundColor: 'rgba(0,0,0,0.7)',
					yAdjust: -40
				}
			}
		}


	})

	return annotations;
}


const formatCoverCrops = (crops = [], lang, isNdvi) => {
	let annotations = {};
	let count = crops.length;
	let step = isNdvi ? 1 / count : 12 / count;
	let yMin = 0;
	let yMax = step;

	crops.map((obj, key) => {
		//SOWING
		if (key > 0) {
			yMin += step;
			yMax += step;
		}

		annotations[`csowing${key}`] = {
			type: 'line',
			// value: obj.sowing,
			xMin: obj.sowing,
			xMax: obj.sowing,
			yMin: yMin,
			yMax: yMax,
			mode: 'vertical',
			borderColor: colors.cropGroup[obj.crop.crop_group],
			// scaleID: 'x',
			borderWidth: !obj.harvest ? 10 : 5,
			drawTime: 'beforeDatasetsDraw',
			enter(ctx, event) {
				const chart = ctx.chart;
				const annotationOpts = chart.options.plugins.annotation.annotations[`csowing${key}`];
				annotationOpts.label.enabled = true;
				annotationOpts.label.position = (event.x);
				chart.update();
			},
			leave(ctx, event) {
				const chart = ctx.chart;
				const annotationOpts = chart.options.plugins.annotation.annotations[`csowing${key}`];
				annotationOpts.label.enabled = false;
				chart.update();
			},
			label: {
				position: 'end',
				drawTime: 'afterDatasetsDraw',
				enabled: false,
				content: obj.harvest ? [`${lang.parcel_sowing}: ${moment(obj.sowing).format('DD-MMM-YYYY')}`, obj.crop.name] : [`${lang.parcel_sowing}: ${moment(obj.sowing).format('DD-MMM-YYYY')}`, `${lang.parcel_harvest}: -`, obj.crop.name],
				backgroundColor: 'rgba(0,0,0,0.7)',
				yAdjust: -40
			}
		}

		if (obj.harvest) {

			// BOX ANNOTATION
			annotations[`charvestBox${key}`] = {
				type: 'box',
				xMin: obj.sowing,
				xMax: obj.harvest,
				borderColor: 'transparent',
				backgroundColor: colors.cropGroup[obj.crop.crop_group] + '50',
				label: {
					enabled: true,
					content: obj.crop.name,
					color: '#55555599'
				}
			}

			//HARVEST LINE
			annotations[`charvest${key}`] = {
				type: 'line',
				value: obj.harvest,
				borderColor: colors.cropGroup[obj.crop.crop_group],
				mode: 'vertical',
				scaleID: 'x',
				borderWidth: 5,
				drawTime: 'beforeDatasetsDraw',
				enter(ctx, event) {
					const chart = ctx.chart;
					const annotationOpts = chart.options.plugins.annotation.annotations[`charvest${key}`];
					annotationOpts.label.enabled = true;
					annotationOpts.label.position = (event.x / ctx.chart.chartArea.width * 100) + '%';
					chart.update();
				},
				leave(ctx, event) {
					const chart = ctx.chart;
					const annotationOpts = chart.options.plugins.annotation.annotations[`charvest${key}`];
					annotationOpts.label.enabled = false;
					chart.update();
				},
				label: {
					enabled: false,
					position: 'end',
					drawTime: 'afterDatasetsDraw',
					content: [`${lang.parcel_harvest}: ${moment(obj.harvest).format('DD-MMM-YYYY')}`, obj.crop.name],
					backgroundColor: 'rgba(0,0,0,0.7)',
					yAdjust: -40
				}
			}
		}


	})

	return annotations;
}

export const formatOptions = (callback, lang, crops = [], onZoomPan, isMain, isNdvi) => {
	let mainCrops = crops.filter(obj => [6, 7, 8].indexOf(obj.crop.crop_group) === -1);
	let coverCrops = crops.filter(obj => [6, 7, 8].indexOf(obj.crop.crop_group) > -1);

	let annotations = isMain ? formatMainCrops(mainCrops, lang, isNdvi) : formatMainCrops(coverCrops, lang, isNdvi);

	return Object.assign({}, {
		maintainAspectRatio: false,
		animation: {
			duration: 0
		},
		onClick: callback,
		plugins: {
			legend: {
				labels: {
					usePointStyle: true
				}
			},
			tooltip: {
				callbacks: {
					title: (val) => {
						return moment(val[0].raw.x).format('DD-MMM-YYYY')
					}
				}
			},
			annotation: {
				clip: true,
				annotations: annotations
			},
			zoom: {
				onPan: onZoomPan,
				onZoom: onZoomPan,
				zoom: {

					wheel: {
						enabled: true
					},
					pinch: {
						enabled: true
					},
					mode: 'x'
				},
				pan: {

					enabled: true,
					threshold: 1,
					mode: 'x',
					modifierKey: 'shift'
				}
			},

		},
		interaction: {
			mode: 'nearest',
			axis: 'x',
			intersect: false,
		},
		scales: {
			y: {
				beginAtZero: true,
				stepSize: isNdvi ? 0.2 : 2,
				max: isNdvi ? 1 : 12,
			},
			x: {
				type: 'time',
				time: {
					unit: 'day',
					displayFormats: {
						day: 'DD-MMM-YYYY'
					}
				},
				ticks: {
					autoSkipPadding: 10,
					maxRotation: 0,
				}

			}
		},
	})
}