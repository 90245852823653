import { Button, Chip, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Stack, Typography, Switch, TextField, InputAdornment, Tooltip, Autocomplete } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { AppContext } from "../../../AppContext";
import { useContext, useEffect, useRef, useState } from "react";
import FertigationView from "./FertigationView";
import HarvestingView from "./HarvestingView";
import TillageView from "./TillageView";
import PlantingView from "./PlantingView";
import NoteView from "./NoteView";
import PlantProtectionView from "./PlantProtectionView";
import PlantProtectionForm from '../forms/PlantProtectionForm';
import { Article, AttachFile, Close, Delete, Description, Download, Edit, Event, FilePresent, FilterFrames, Image, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import TillageForm from "../forms/TillageForm";
import FertigationForm from "../forms/FertigationForm";
import PlantingForm from "../forms/PlantingForm";
import HarvestingForm from "../forms/HarvestingForm";
import NoteForm from "../forms/NoteForm";
import $data from "../../../services/$data";
import useMergeState from "../../hooks/useMergeState";
import ConfirmDialog from "../../ui/ConfirmDialog";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import Divider from "../../auth/Divider";
import { Link } from "react-router-dom";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import AgrotechnicalForm from "../forms/AgrotechnicalForm";
import AgrotechnicalView from "./AgrotechnicalView";
import GrazingView from "./GrazingView";
import GrazingForm from "../forms/GrazingForm";

function ActivityView(props) {
	const {lang, locale, activityTypes} = useContext(AppContext);
	const filesRef = useRef([]);
	const downloadRef = useRef([]);

	const [state, setState] = useMergeState({error: {}, edit: false, loading: false, remove: false});

	const [job, setJob] = useMergeState({jo_from: moment().add(1, 'days').toISOString(), jo_to: moment().add(1, 'days').toISOString(), sms: false})

	const [isJob, setIsJob] = useState(false);

	const [status, setStatus] = useState(false);

	const [files, setFiles] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);

  const [fileAttrs, setFileAttrs] = useMergeState({});

	useEffect(() => { if(props.open) { setStatus(props.activity?.activity_status); } }, [props.open]);
	useEffect(() => { if(!state.edit) { setStatus(props.activity?.activity_status); setIsJob(false); setFiles([]); filesRef.current = []; }}, [state.edit])

	const getActivityView = (type, props = {}) => {
		switch (type) {
			case 1: return <TillageView className="activity-view-container" {...props} />
			case 2: return <FertigationView className="activity-view-container" {...props} />
			case 3: return <PlantingView className="activity-view-container" {...props} />
			case 4: return <PlantProtectionView className="activity-view-container" {...props} />
			case 5: return <HarvestingView className="activity-view-container" {...props} />
			case 6: return <NoteView className="activity-view-container" {...props} />
			case 7: return <AgrotechnicalView className="activity-view-container" {...props} />
			case 8: return <GrazingView className="activity-view-container" {...props} />
			default: return null
		}
	}

	const getActivityForm = (type, props = {}) => {
		switch (type) {
			case 1: return <TillageForm loading={state.loading} error={state.error} onSubmit={updateActivity} {...props} />
			case 2: return <FertigationForm loading={state.loading} error={state.error} onSubmit={updateActivity}  {...props} />
			case 3: return <PlantingForm loading={state.loading} error={state.error} onSubmit={updateActivity}  {...props} />
			case 4: return <PlantProtectionForm loading={state.loading} error={state.error} onSubmit={updateActivity} {...props} />
			case 5: return <HarvestingForm loading={state.loading} error={state.error} onSubmit={updateActivity}  {...props} />
			case 6: return <NoteForm loading={state.loading} error={state.error} onSubmit={updateActivity} {...props} />
      case 7: return <AgrotechnicalForm loading={state.loading}  error={state.error} onSubmit={updateActivity} {...props} />;
      case 8: return <GrazingForm loading={state.loading}  error={state.error} onSubmit={updateActivity} {...props} />;
			default: return null
		}
	}

	const updateActivity = (model) => {
		setState({loading: true})
	
		if(isJob && props?.activity?.activity_type !== 6) {
			let formated = {
				...job,
				job_order: true,
				date: moment(job.jo_from).format('YYYY-MM-DD'),
				operators: model.operators
			}

			$data.updateActivity(Object.assign({}, {...model, activity_status: status, upload_document: files.map((f,key) => {
        f.name_field = fileAttrs[key].name;
        f.description = fileAttrs[key].description;
        f.tags = fileAttrs[key].tags.length > 0 ? fileAttrs[key].tags.join('; ') : null;
        return f
      }), ...formated}))
				.then(data => {
					onClose();
					setTimeout(() => { props.onRefresh(lang.update_activity_success); })
				})
				.catch(err => { setState({error: err, loading: false})})

		} else {
			$data.updateActivity({...model, activity_status: status, upload_document: files.map((f,key) => {
        f.name_field = fileAttrs[key].name;
        f.description = fileAttrs[key].description;
        f.tags = fileAttrs[key].tags.length > 0 ? fileAttrs[key].tags.join('; ') : null;
        return f
      })})
				.then(data => {
					onClose();
					setTimeout(() => { props.onRefresh(lang.update_activity_success); })
				})
				.catch(err => { setState({error: err, loading: false})})
		}		
	}

	const deleteActivity = (model) => {
		setState({loading: true})
		$data.deleteActivity(model)
			.then(data => {
				onClose();
				setTimeout(() => { props.onRefresh(lang.delete_activity_success); })
			})
			.catch(err => { setState({error: err, edit: false, loading: false})})
	}

	const onFilesChange = (evt) => {
    // setFiles(evt.target.files);
    setFiles(Array.from(filesRef.current.files));
    let attrs = {};
    Array.from(filesRef.current.files).map((obj, key) => {
      attrs[key] = {
        name: obj.name,
        description: '',
        tags: []
      }
    })
    
    setFileAttrs(JSON.parse(JSON.stringify(attrs)))
  }

  const onFileAttributeChange = (key, name, value, opt_remove) => {
    setFileAttrs({[key]: {...fileAttrs[key], [name]: value}})
  }

	const downloadDoc = (url) => {
		axios.get(url, {responseType: 'blob'})
      .then(result => {
        console.log(result.data);
        downloadRef.current.href = window.URL.createObjectURL(result.data);
				downloadRef.current.download = url.split('/')[url.split('/').length - 1];
        downloadRef.current.click();
        return;
      })
      .catch(err => {
        return
      })
	}


	const onClose = () => {
		setState({edit: false, remove: false, loading: false, error: {}}); setIsJob(false); props.onClose();
	}

	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog fullWidth maxWidth={props?.activity?.activity_type === 4 ? 'xl' : 'md'} open={props.open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{props.activity ? $data.getActivityNameById(activityTypes, props.activity.activity_type) : null}<Close onClick={onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid style={{marginBottom: '20px'}} container spacing={2}>
						<Grid item xs={12}>
							<Stack flexWrap={'wrap'} direction="row" justifyContent={'space-between'} alignItems="center">
								<div>
									{!state.edit && <Button onClick={() => setState({edit: true})} style={{marginRight: '5px'}} size="small" color="warning" variant="contained" endIcon={<Edit />}>{lang.edit}</Button>}
									{state.edit && <Button onClick={() => setState({edit: false})} style={{marginRight: '5px'}} size="small" color="error" variant="contained" endIcon={<Close />}>{lang.cancel}</Button>}
									{!state.edit && <Button onClick={() => setState({remove: true})} size="small" color="error" variant="contained" endIcon={<Delete />}>{lang.delete}</Button>}
								</div>

								{state.edit && !isJob && props.activity?.activity_type !== 6 && <FormGroup>
                	<FormControlLabel style={{margin: 0, justifyContent: 'flex-end'}} control={<Switch checked={status} onChange={() => setStatus(!status)} />} label={status ? lang.act_job_completed : lang.act_job_active}/>
              	</FormGroup>}

								{state.edit && props.activity?.activity_type !== 6 && <FormGroup>
									<FormControlLabel style={{margin: 0, justifyContent: 'flex-end'}} control={<Switch disabled={status} checked={isJob} onChange={(evt) => {setIsJob(evt.target.checked); if(evt.target.checked) {setStatus(false)}}}/>} label={lang.act_add_job_order}/>
								</FormGroup>}
							</Stack>
							
						</Grid>
						
						{isJob && state.edit &&	<Grid item xs={12} sm={4}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={moment().add(1, 'days')} onClose={() => {setJob({jo_from: job.jo_from})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.from} value={moment(job.jo_from)} onChange={(newValue) => setJob({jo_from: newValue.toISOString()})}
									renderInput={(params) => (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
							</LocalizationProvider>	
						</Grid>}
						{isJob && state.edit && <Grid item xs={12} sm={4}>
							<LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
								<MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={moment().add(1, 'days')}  onClose={() => {setJob({jo_to: job.jo_to})}} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.to} value={moment(job.jo_to)} onChange={(newValue) => setJob({jo_to: newValue.toISOString()})}
									renderInput={(params) => (
										<TextField fullWidth {...params} size="small"
											InputProps={{endAdornment: ( <InputAdornment position="end"> <Event /> </InputAdornment> ) }} />
									)}
								/>
							</LocalizationProvider>	
						</Grid>}
						
						{props.activity && props.activity?.activity_type !== 6 && <Grid item xs={12}>
					
							<Stack spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
								{props.activity?.documents?.map((obj, key) => {
									console.log(obj);

									if(obj.file.indexOf('.pdf') > -1) {
										return (
											<Tooltip key={key} placement="top" title={obj.name}>
												<PictureAsPdf onClick={() => downloadDoc(obj.file)} fontSize="large" color="error" />
											</Tooltip>
										)
									}

									if(obj.file.indexOf('.jpg') > -1 || obj.file.indexOf('.png') > -1  || obj.file.indexOf('.jpeg') > -1 ) {
										return (
											<Tooltip key={key} placement="top" title={obj.name}>
												<Image onClick={() => setSelectedImage(obj)} fontSize="large" color="info" />
											</Tooltip>
										)
									}

									if(obj.file.indexOf('.xls') > -1 || obj.file.indexOf('.doc') > -1 || obj.file.indexOf('.docx') > -1 || obj.file.indexOf('.xlsx') > -1 || obj.file.indexOf('.csv') > -1 || obj.file.indexOf('.odt') > -1 || obj.file.indexOf('.ods') > -1) {
										return (
											<Tooltip key={key} placement="top" title={obj.name}>
												<Article onClick={() => downloadDoc(obj.file)} fontSize="large" color="primary" />
											</Tooltip>
										)
									}
									
									
									return (
										<Tooltip key={key} placement="top" title={obj.name}>
											<InsertDriveFile  onClick={() => downloadDoc(obj.file)} fontSize="large" color="primary" />
										</Tooltip>
									)
								})}

							

							</Stack>
							<Link to="/dashboard/field-book/?documents" style={{textDecoration: 'none', fontWeight: 'bold', display: 'block', marginTop: '5px'}} className="text-warning">{lang.act_edit_delete}</Link>
						</Grid>}

						{state.edit && props.activity?.activity_type !== 6 && <Grid item xs={12}>
              <Stack sx={{mb: '15px'}} spacing={2} direction="row" flexWrap={'wrap'} alignItems="center">
                {/* <Button size="small" variant="contained" onClick={() => setAttach(true)}> */}
                <Button size="small" variant="contained" component="label">
                  <AttachFile /> &nbsp; {lang.select_documents}
                  <input ref={filesRef} onChange={onFilesChange} value={""} multiple type="file" hidden />
                </Button>
                {/* </Button> */}
                {files.map((obj, key) => <Tooltip key={key} title={obj.name} placement="top" ><FilePresent fontSize="large" color="primary" /></Tooltip>)}
              </Stack>

              
                {files.map((obj, key) => {
                  return (
                    <Grid key={key} container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TextField value={fileAttrs[key].name} size="small" onChange={(evt) => onFileAttributeChange(key, 'name', evt.target.value)} fullWidth label={lang.document_name} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField size="small" value={fileAttrs[key].description} fullWidth onChange={(evt) => onFileAttributeChange(key, 'description', evt.target.value)} label={lang.document_description} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete multiple size="small" fullWidth
                          freeSolo
                          defaultValue={[]}
                          value={fileAttrs[key]['tags']}
                          onChange={(evt, val, reason) => {if(reason === 'createOption' || reason === 'removeOption') {onFileAttributeChange(key, 'tags', val, reason === 'removeOption')}}}
                          renderTags={(value, getTagProps) => value.map((option, index) => <Chip size="small" variant="filled" color="warning" label={option} {...getTagProps({index})}/>)}
                          renderInput={(params) => (
                             <TextField {...params} size="small" helperText={lang.act_document_tag_helper} fullWidth label={lang.document_tags}/>
                          )}
                          options={[]} />
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>}

						<Grid item xs={12}>
              <Divider style={{width: '100%', marginBottom: 0}} />
            </Grid>
					</Grid>
						{!state.edit && getActivityView(props.activity ? props.activity.activity_type : null, {data: props.activity})}
						{state.edit && getActivityForm(props.activity ? props.activity.activity_type: null, {model: props.activity, parcel: props.parcel, isJob: isJob, edit: true, closeErrorMessage: () => setState({error: {...state.error, non_field_errors: null}})})}
        </Container>
				<a ref={downloadRef}></a>

				{selectedImage && <Lightbox
				  
          mainSrc={selectedImage.file}
          nextSrc={null}
          prevSrc={null}
          imageTitle={selectedImage.filename}
					
          imageCaption={<Stack alignItems="center" sx={{width: '100%'}} direction="row" justifyContent="space-between">
            <Button onClick={() => {downloadDoc(selectedImage.file)}} color="primary" size="small" startIcon={<Download fontSize="small" />} variant="contained">{lang.download_document}</Button>
          </Stack>}
          onCloseRequest={() => setSelectedImage(null)}
         
          />}
      </DialogContent>
			<ConfirmDialog open={state.remove} onSubmit={() => deleteActivity(props.activity)} onClose={() => setState({remove: false})}  title={lang.delete_activity} question={lang.delete_activity_question} />
    </Dialog>	
	)
}

export default ActivityView;