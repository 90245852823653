import {Agriculture, ArrowBack, Comment, EmojiTransportation, Grain, Grass, LocalShipping, RvHookup, Science, VolunteerActivism } from "@mui/icons-material";
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import Divider from "../auth/Divider";
import JobOrderFertigation from "./JobOrderFertigation";
import JobOrderPlanting from "./JobOrderPlanting";
import JobOrderPlantProtection from "./JobOrderPlantProtection";
import JobOrderTillage from "./JobOrderTillage";

const activityIcons = () => ({
  1: <Grain fontSize="large" />,
  2: <Science fontSize="large" />,
  3: <Grass fontSize="large" />,
  4: <VolunteerActivism fontSize="large" />,
  5: <LocalShipping fontSize="large" />,
  6: <Comment fontSize="large" />,
});

const jobContent = (type, job) => {
  switch(type) {
    case 1: return <JobOrderTillage job={job} />;
    case 2: return <JobOrderFertigation job={job} />;
    case 3: return <JobOrderPlanting job={job} />;
    case 4: return <JobOrderPlantProtection job={job} />;
    case 5: return null;
    default: return null;
  }
}

function JobOrderItem(props) {
  const {lang, activities, activityTypes} = useContext(AppContext);

  const renderContent = () => {
    if(!props.id) {
      return (
        <Grid item xs={12}>
          Job not found
        </Grid>
      )
    }

    let job = activities.jobs.filter(obj => JSON.stringify(obj.common_activity.id) === props.id)[0];

    if(!job) {
      return (
        <Grid item xs={12}>
          Job not found
        </Grid>
      )
    }

    return (
      <Grid container spacing={2}>
        {/* COMMON HEADER */}
        <Grid item xs={12}>
          <Stack direction="row" flexWrap="wrap" justifyContent="space-evenly" alignItems="center">
            <div>
              <Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} gutterBottom color="primary" variant="h6" component="div">
                {lang.parcel_name}: &nbsp; {job.parcel_name}
              </Typography>
            </div>
            <div>
              <Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} gutterBottom color="primary" variant="h6" component="div">
                {activityIcons()[job.activity_type]} &nbsp; {$data.getActivityNameById(activityTypes, job.activity_type)}
              </Typography>
            </div>
            <div>
              <Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} gutterBottom color="primary" variant="h6" component="div">
                <span className="from-to">{lang.from}: <strong>{moment(job.common_activity.jo_from).format('DD-MMM-YYYY')}</strong></span> 
              </Typography>
            </div>
            <div>
              <Typography style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} gutterBottom color="primary" variant="h6" component="div">
                <span className="from-to">{lang.to}: <strong>{moment(job.common_activity.jo_to).format('DD-MMM-YYYY')}</strong></span> 
              </Typography>
            </div>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            {jobContent(job.activity_type, job)}
          </Grid>
        </Grid>

        {/* COMMON FOOTER */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding: '5px 10px'}} elevation={2}>
                <Stack direction="row" alignItems="center">
                  <Agriculture color="primary" fontSize="large" /> &nbsp;<span>{lang.fld_machine}: &nbsp;<strong>{job.common_activity.machine_name || ' - '}</strong></span>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding: '5px 10px'}} elevation={2}>
                <Stack direction="row" alignItems="center">
                  <RvHookup color="primary" fontSize="large" /> &nbsp;<span>{lang.fld_con_machine}: &nbsp;<strong>{job.common_activity.conn_machine_name || ' - '}</strong></span>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{padding: '5px 10px'}} elevation={2}>
                <p>{lang.comment}:</p>
                <Typography variant="body2" color="text.secondary">{job.common_activity.comment}</Typography>
              </Paper>
            </Grid>
          </Grid>           
        </Grid>
      </Grid>
    )
  }


  return (
    <Container sx={{mt: '20px'}}>
      <Grid container>
        <Grid item xs={12}>
          <Link style={{textDecoration: 'none'}} to="/dashboard/job-orders">
            <Typography style={{cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} variant="h6" color="primary">
              <ArrowBack /> &nbsp;{lang.go_back}
            </Typography>
          </Link>
          <Divider style={{width: '100%', marginTop: '10px'}} />
        </Grid>

        <Grid item xs={12}>
          {renderContent()}
        </Grid>
      </Grid>
    </Container>
  )
}

export default JobOrderItem;