import { Close, Event, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, MobileStepper, Paper, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useContext, useEffect, useState } from "react";
// import { AppContext } from "../../../AppContext";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";

import { AppContext } from "../../AppContext";

function Help(props) {
	const {lang} = useContext(AppContext);

	const steps = [
		{label: 'How to register/login to the platform?', description: (<Stack spacing={1}>
			<Paper sx={{p: 0, height: '400px', borderRadius: 0}}>
				<iframe width="100%" height="100%" src="https://www.youtube.com/embed/7-guCd-6ETc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Paper>
		</Stack>)},
		{label: 'How to add fields?', description: (<Stack spacing={2}>
			<Paper sx={{p: 0, height: '400px', borderRadius: 0}}>
				<iframe width="100%" height="100%" src="https://www.youtube.com/embed/5ZKdGAKb6vc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Paper>
		</Stack>)},
		{label: 'How to get information about the field and monitor crop development?', description: (<Stack spacing={2}>
			<Paper sx={{p: 0, height: '400px', borderRadius: 0}}>
				<iframe width="100%" height="100%" src="https://www.youtube.com/embed/LGGzwbntjKM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Paper>
		</Stack>)},
		{label: 'How to use variable rate application?', description: (<Stack spacing={2}>
			<Paper sx={{p: 0, height: '400px', borderRadius: 0}}>
				<iframe width="100%" height="100%" src="https://www.youtube.com/embed/F9UKdFlBDWs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Paper>
		</Stack>)},
		{label: 'How to use field book to store records about crop activities?', description: (<Stack spacing={2}>
			<Paper sx={{p: 0, height: '400px', borderRadius: 0}}>
				<iframe width="100%" height="100%" src="https://www.youtube.com/embed/lusA3QSbpuU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></Paper>
		</Stack>)}
	];

	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };






	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary"> {lang.help}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '10px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
						{steps[activeStep].label}

						</Grid>
						<Grid item xs={12}>
							{steps[activeStep].description}
							<MobileStepper variant="text" steps={steps.length} position="static" activeStep={activeStep} 
							nextButton={<Button size="small" onClick={handleNext} disabled={activeStep == steps.length - 1}>{lang.next} <KeyboardArrowRight /></Button>}
							backButton={<Button size="small" onClick={handleBack} disabled={activeStep == 0}> <KeyboardArrowLeft /> {lang.back}</Button>} />
							</Grid>
				
          </Grid>
        </Container>
      </DialogContent>
			<DialogActions>
				{/* <LoadingButton onClick={()} size="small" loading={state.loading} variant="contained" color="primary">{lang.confirm}</LoadingButton> */}
				<LoadingButton onClick={props.onClose} size="small" loading={false} variant="contained" color="error">{lang.cancel}</LoadingButton>
			</DialogActions>
    </Dialog>
	)
}

export default Help;