import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { Close, Delete, ReadMoreRounded, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect } from "react";
import Message from "../../ui/Message";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import EXIF from "exif-js";
import $data from "../../../services/$data";
import moment from "moment";

function IrrigationModal(props) {
  const {lang} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.irrigation}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        {/* <Container style={{marginTop: '30px'}}> */}
          <Grid style={{marginTop: '30px'}} container spacing={2}>
						{!props.data && <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">No irrigation information available at this moment</Typography>
						</Grid>}
            {props.data && <Grid item xs={12}>
                <p>Closest sensor: <strong className="text-primary">{props.data.info.sensor}</strong></p>
                <p>Distance from field: <strong className="text-primary">{props.data.info.distance}</strong></p>
                <p>Moisture: <strong className={props.data.data.treshold > props.data.data.moisture ? "text-danger" : "text-primary"}>{props.data.data.moisture ? props.data.data.moisture + ' %' : ' - '}</strong></p>
                <p>Irrigation treshold: <strong className={props.data.data.treshold > props.data.data.moisture ? "text-danger" : "text-primary"}>{props.data.data.treshold ? props.data.data.treshold + ' %' : ' - '}</strong></p>
                {props.data.data.treshold > props.data.data.moisture && <p>Suggested irrigation volume per olive tree: <strong className="text-warning">{props.data.data.delta ? props.data.data.delta : ' - '}</strong></p>}
						</Grid>}
					</Grid>
        {/* </Container> */}
      </DialogContent>
      <DialogActions>
        <LoadingButton color={props.closeColor || "error"} variant="contained" size="small" onClick={props.onClose}>{props.closeText || lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default IrrigationModal;