import { Agriculture, ArrowDownward, ArrowUpward, Book, DragHandle, LocalShipping, MenuBook, Search, StackedBarChart } from "@mui/icons-material";
import { Avatar, Button, CircularProgress, Fab, Hidden, Paper, Stack, Tab, Tooltip, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import { Box } from "@mui/system";
import moment from "moment";
import { getCenter } from "ol/extent";
import { toLonLat } from "ol/proj";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import colors from "../../../config/colors";
import $data from "../../../services/$data";
import $map from "../../../services/$map";
import $query from "../../../services/$query";
import AddCropSeason from "./AddCropSeason";
import CropRecordSteps from "./CropRecordSteps";
import IrrigationModal from "./IrrigationModal";
import SummaryCropHistory from "./SummaryCropHistory";
import SummaryDetails from "./SummaryDetails";
import SummaryFullHistory from "./SummaryFullHistory";
import SummaryIndicesMobile from "./SummaryIndicesMobile";
import SummaryParcelDetails from "./SummaryParcelDetails";

function Summary(props) {
  const {lang, cropTypes, primary, source, danger, successColor, season, seasons, onUpdateState, user} = useContext(AppContext);
  let feature = $data.getParcelById(props.active) || {};
  let parcel = feature.properties || {};

  const [irrigation, setIrrigation] = useState(null);
  const [irrigationLoading, setIrrigationLoading] = useState(false);
  const [irrigationModal, setIrrigationModal] = useState(false);

  useEffect(() => {
    if(user.pk === 349) {
      try {
        let ft = $map.geojsonToFeature(feature);
  
        let extent = ft.getGeometry().getExtent();
  
        let center = getCenter(extent);
  
        let point = toLonLat(center).reverse().join(',');
        setIrrigationLoading(true);
        $data.getIrrigation(point)
          .then(data => {
            setIrrigation(data)
            setIrrigationLoading(false);
          })
          .catch(err => {
            setIrrigationLoading(false);
          });
      } catch(e) {
        setIrrigationLoading(false);
      }
    }
    
  }, []);

  const getIconForStatus = (status) => {
    if(!status) return ' - ';

    if(status === 'up') return <ArrowUpward fontSize="small" color="success" />
    if(status === 'down') return <ArrowDownward fontSize="small" color="error" />

    if(status === 'equial') return <DragHandle fontSize="small" color="warning" />
  }


  let formated = $query.formatShortSummary(props.data, season, seasons, source);


  return (
    <Stack sx={{height: '100%'}} className="summary-container" spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
      
      <Box className="summary-parcel-details-container">
        <SummaryParcelDetails active={props.active} setDetails={props.onSetDetails} />
          {console.log('DETELINA JEBENA', JSON.stringify(parcel.activeCrop))}
        <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_name}: <strong style={{color: primary, maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parcel.name || ' - '}</strong> </Paper>
        <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_crop}: <strong style={{color: parcel?.activeCrop?.crop !== 999 ? colors.cropGroup[$data.getCropGroupIDByCrop(parcel?.activeCrop?.crop, cropTypes)] : '#555', textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop) || ' - '}</strong> </Paper>
        <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_area}: <strong style={{color: primary}}>{parcel.area ? parcel.area.toFixed(2) + ' ha' : ' - '}</strong> </Paper>
        <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_sowing}: <strong style={{color: primary}}>{parcel.activeCrop ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
        <Paper className="summary-parcel-item" sx={{mb: '4px',p: '3.3px 5px'}} elevation={2}> {lang.parcel_harvest}: <strong style={{color: primary}}>{parcel.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong> </Paper>
      </Box>

      <Box className="only-on-mobile" sx={{paddingTop: '38px', height: '120px'}} component={Stack} alignItems="flex-start" spacing={2} justifyContent={'center'}>
        <Button onClick={() => props.onSetModals('historyModal', true)} variant="contained" size="small">{lang.crop_history_short}</Button>
        <Button onClick={() => props.onSetModals('indicesModal', true)} variant="contained" size="small">{lang.indices}</Button>
        {!irrigationLoading && irrigation &&<Button onClick={() => setIrrigationModal(true)} variant="contained" size="small">{'irrigation'}</Button>}
        {irrigationLoading && <CircularProgress />}
      </Box>

      <SummaryIndicesMobile title={lang.indices_summary} open={props.modals.indicesModal} onClose={() => props.onSetModals('indicesModal', false)}
        ndvi={<Paper sx={{height: '100%'}} elevation={2}>
        <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.ndvi_full}</Typography>
        <table>
          <tbody>
            {formated.ndvi.map((obj,key) => {
              return (
                <tr key={key}>
                  <td>{obj.date}</td>
                  <td style={{color: primary, fontWeight: 'bold'}}>{obj.val}</td>
                  <td>{getIconForStatus(obj.status)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Paper>}
        chl={<Paper sx={{height: '100%'}} elevation={2}>
        <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.chl_full}</Typography>
        <table>
          <tbody>
            {formated.chl.map((obj,key) => {
              return (
                <tr key={key}>
                  <td>{obj.date}</td>
                  <td style={{color: primary, fontWeight: 'bold'}}>{obj.val}</td>
                  <td>{getIconForStatus(obj.status)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Paper>} />

      <Stack className="only-on-desktop" style={{height: '100%', width: 'calc(100% - 180px)', overflowX: 'auto'}}>
        <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" className="summary-short-container">
          <SummaryCropHistory openCropRecordModal={() => props.onSetModals('cropRecordModal', true)} openHistoryModal={() => props.onSetModals('historyModal', true)} parcel={parcel} />

          <Paper sx={{height: '100%'}} elevation={2}>
            <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.ndvi_full}</Typography>
            <table>
              <tbody>
                {formated.ndvi.map((obj,key) => {
                  return (
                    <tr key={key}>
                      <td>{obj.date}</td>
                      <td style={{color: primary, fontWeight: 'bold'}}>{obj.val}</td>
                      <td>{getIconForStatus(obj.status)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Paper>
          <Paper sx={{height: '100%'}} elevation={2}>
            <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.chl_full}</Typography>
            <table>
              <tbody>
                {formated.chl.map((obj,key) => {
                  return (
                    <tr key={key}>
                      <td>{obj.date}</td>
                      <td style={{color: primary, fontWeight: 'bold'}}>{obj.val}</td>
                      <td>{getIconForStatus(obj.status)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Paper>
          {irrigationLoading && <CircularProgress />}
          {irrigation && !irrigationLoading && <Paper sx={{height: '100%', minWidth: '250px'}} elevation={2}>
            <Typography style={{whiteSpace: 'nowrap'}} variant="subtitle2" color="primary">{lang.irrigation} ({irrigation.info.sensor}: {irrigation.info.distance})</Typography>
            
            <Stack alignItems={'center'}>
              <p style={{fontSize: '13px', margin: '4px auto'}}> Moisture</p>
              <strong style={{fontSize: '13px'}} className={irrigation.data.moisture > irrigation.data.treshold ? "text-primary" : 'text-danger'}>{irrigation.data.moisture ? irrigation.data.moisture + ' %' : ' - '}</strong>
            </Stack>

            <Stack alignItems={'center'}>
              <p style={{fontSize: '13px', margin: '4px auto'}}> Irrigation treshold</p>
              <strong style={{fontSize: '13px'}} className={irrigation.data.moisture > irrigation.data.treshold ? "text-primary" : 'text-danger'}>{irrigation.data.treshold ? irrigation.data.treshold + ' %' : ' - '}</strong>
            </Stack>
            
            {irrigation.data.treshold > irrigation.data.moisture && <Stack alignItems={'center'}>
              <p style={{fontSize: '13px', margin: '4px auto'}}> Suggested irrigation volume per olive tree</p>
              <strong style={{fontSize: '13px'}} className="text-warning">{irrigation.data.delta}</strong>
            </Stack>}

          
          </Paper>}

         
        </Stack>
        
      </Stack>

      <IrrigationModal data={irrigation} open={irrigationModal} onClose={() => setIrrigationModal(false)} />
    </Stack>
  )
}

export default Summary;