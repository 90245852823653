import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Circle, Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function PrivacyPolicyEN(props) {
  const {lang, primary} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.privacy_policy}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={0}>
            <Grid sx={{textAlign: 'center', marginBottom: '10px'}} item xs={12}>
              <a href="https://gilab.rs" target="_blank"><img src="/gilab-logo.svg" width="240px" /></a>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Privacy Policy</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>
              Gilab DOO Belgrade (hereinafter referred to as "GILAB", "we", "our") appreciates your interest in our company and thanks you for visiting our web application CropLab or using our mobile application. GILAB takes the protection of your personal data very seriously. We handle your personal data confidentially and in accordance with the legal regulations on data protection and the Privacy Policy provided on this website. In the following text, you will find information about what data we store and when, as well as how we use that data.               </p>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Personal data handler</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>Data handler, in the sense of Art. 4 paragraph 1 point 8) of the Law on Personal Data Protection of the Republic of Serbia, is GILAB d.o.o., 94 Dragoslava Srejovića Belgrade, Serbia </p>
              <p>e-mail: <a href="mailto:privacypolicy@gilab.rs">privacypolicy@gilab.rs</a> </p>
						</Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Purpose and legal basis of processing</Typography>
						</Grid>
            <Grid item xs={12}>
              <p>
                <strong>a) Informational visit to our website</strong>  
              </p>
              <p>
              If you are on the website for informational purposes only, in other words, if you do not register or otherwise provide us with your information, we will only collect the personal data that your browser sends to our server. 
              </p>
              <p>
              If you want to view the website, we will process the following data which is technically necessary for us to be able to show you the content of the website and ensure stability and security:              
              </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="IP address" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Date and time of request" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Difference in time zone compared to Greenwich Mean Time (GMT)" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Content of the request (specific site)" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Access status/HTTP status code" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Amount of data transferred each time" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The website from which the request arrived" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Search engine" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Operating system and its interface" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Browser software language and version." />
                </ListItem>
              </List>
              <p>
              This information is analyzed for statistical purposes only.
              </p>
              <p>
              The legal basis of processing is Art. 12 paragraph 1 point 6) of the Personal Data Protection Act. The legitimate interest we strive to achieve is the provision of our online services and data security. 
              </p>
						</Grid>
            <Grid item xs={12}>
              <p>
                <strong>b) CropLab</strong>
              </p>
              <p>
              On our web application, we offer you the possibility to register on our platform for decision support and management of crop data and activities, the "CropLab" platform. You can also use the mobile application, CropLab mobile application. Below we inform you about the processing of your personal data when using CropLab. 
              </p>
              <p style={{color: primary, fontWeight: '500'}}>I. Registration on the CropLab platform or mobile application </p>
              <p> CropLab enables users to access information on key indicators that indicate crop conditions.  </p>
              <p>Registration is required to access this service and information. </p>
              <p>As part of your registration, we create a user profile. In this process, we collect the data we require from you in order to assign and manage the user account.</p>
              <p>The legal basis for processing is Art. 12 paragraph 1 point 2) of the Personal Data Protection Act. </p>
              <p>Your data will be deleted 30 days after the end of the user relationship and after the expiry of any legal retention period. </p>

              <p style={{color: primary, fontWeight: '500'}}>II. Data for records and farm management  </p>
              <p>This service (Field Book) enables the entry and management of production data (crop, variety, sowing date, type of fertilizer, etc.) for each field in the system. CropLab provides users with the opportunity to easily add and save data about their fields in one place, and then to access them easily and quickly with additional analytical functionalities that the system offers. </p>
              <p>The legal basis for processing is Art. 12 paragraph 1 point 2) of the Personal Data Protection Act. </p>
              <p>Your data will be deleted 30 days after the end of the user relationship and after the expiry of any legal retention period. </p>

              <p style={{color: primary, fontWeight: '500'}}>III. Features based on satellite imagery</p>
              <p>Crop monitoring  <br/> <br/>
              CropLab provides data on 2 biophysical parameters: Normalized Difference Vegetation Index (NDVI) and Chlorophyll Index (CHL). Based on the biophysical parameters that allow the condition of the crop to be analyzed, CropLab provides you with timely information when it is necessary to react. One example is the NDVI index, which allows farmers and agronomists to recognize changes in crops up to two weeks before they can be seen with the naked eye. Variable treatment. 
              </p>
              <p>Variable treatment <br/> <br/>
              Management zones represent areas within a plot that have different productivity. This service supports precision farming methods. This refers to: optimal sampling in soil analysis, efficient use of inputs, different treatments within the field. This service provides the possibility for the farmer to get a ticket for the application of variable treatment (e.g. fertilization or sowing) compatible with their machinery.              </p>
              <p>The legal basis for processing is Art. 12 paragraph 1 point 2) of the Personal Data Protection Act. </p>
              <p>Your location data will be deleted 30 days after termination of the user relationship and after the expiration of any legal retention period. </p>

              <p style={{color: primary, fontWeight: '500'}}>IV. Usage analysis  </p>
              <p>If you have given us your consent, we collect and analyze basic data about your user behavior in order to improve our services and make them more attractive. </p>
              <p>Your usage data will only be recorded and evaluated with your consent.</p>
              <p>The legal basis of processing is Art. 12 paragraph 1 point 1) of the Personal Data Protection Act (ie your consent). </p>
              <p>You can withdraw your consent at any time by sending an email to: <a className="text-link" href="mailto:team@gilab.rs">team@gilab.rs</a></p>
              <p>Your data will be deleted 30 days after the end of the user relationship and after the expiry of any legal retention period. </p>

              <p style={{color: primary, fontWeight: '500'}}>V. Accessing the Camera to Use Features</p>
              <p>The CropLab app requires access to your device's camera in order to use various features, if you wish to use these features. This, for example, allows you to transfer images directly to the application and get additional information again. </p>
              <p>The legal basis for processing is Art. 12 paragraph 1 point 2) of the Personal Data Protection Act. </p>
              <p>If you are not logged in at the time of scanning or if you create your CropLab account later, your location will be added to your CropLab account after that, provided you have given your consent for localization in the API browser.  </p>
              <p>The legal basis for localization is Art. 12 paragraph 1 point 1) of the Personal Data Protection Act and Art. 12 paragraph 1 point 2) of the Personal Data Protection Act, and for the subsequent connection.</p>
              <p>Your data will be deleted 30 days after the end of the user relationship and after the expiry of any legal retention period. </p>
              <p>If the processing is based on your consent, we will delete your data after withdrawing your consent, unless further processing is permitted in accordance with the relevant legal provisions. </p>

            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>c) Contacting the user</strong>
              </p>
              <p>Then, we use and process your contact details, e.g. email address or phone number, in order to communicate to you, using the communication methods you have chosen (email or phone), about our products, services and special offers. </p>

              <p>The legal basis of processing is Art. 12 paragraph 1 item 1) (i.e. your consent) or 6) of the Personal Data Protection Act (i.e. legitimate interest). The legitimate interest we strive to achieve is communication with our users. </p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>d) Monitoring</strong>
              </p>
              <p>This website uses various technologies to collect and store data for marketing and optimization purposes. This data can be used to create pseudonymous user profiles.</p>
              <p>This website uses Google Analytics, an internet analysis service of Google LLC ("Google"). The information generated by the cookie when you use this website is, as a rule, transferred to a Google server in the USA and stored there. As part of the anonymization of the IP address, Google first shortens your IP address in EU member states or other countries that are signatories to the Agreement on the European Economic Area. Only in exceptional cases is the entire IP address transferred to a Google server in the USA and then shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of the website, to compile reports on website activity and to provide the website operator with further services related to the use of the website and the Internet. The IP address transmitted by your browser in the context of using Google Analytics is not combined with other data held by Google. </p>
              <p>More information about terms of use and data protection can be found at:<br/> <a className="text-link" href="https://policies.google.com/terms?hl=en" target="_blank">https://policies.google.com/terms?hl=en</a> or <a className="text-link" href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a>. Please note that on this website Google Analytics has been extended with the code "anonymizeIp" in order to guarantee the anonymized collection of IP addresses ("IP Address Masking").</p>
              <p>The legal basis of processing is Art. 12 paragraph 1 point 6) of the Personal Data Protection Act. The legitimate interests we strive to achieve are direct marketing and the improvement of our online services. </p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>e) Bulletin</strong>
              </p>
              <p>We only send e-mail bulletin (“e-mail newsletters”) to you if you have specifically ordered a specific bulletin (“newsletter”) on our website. If you no longer wish to receive email bulletins, you can unsubscribe using the link provided at the end of each newsletter. </p>
              <p>The legal basis of processing is Art. 12 paragraph 1 point 1) of the Personal Data Protection Act (i.e. your consent). </p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>f) Recommendations on Facebook, G+, Twitter, email and WhatsApp </strong>
              </p>
              <p>You can easily share information about the GILAB/CropLab website on Facebook, G+, Twitter, email or WhatsApp using the relevant plug-ins. These "Additional modules with social bookmarks" (i.e. "social bookmark plug-ins") are Internet bookmarks with which users of this type of service can collect links and news. On the GILAB website, they are used only as a link to the respective services. Only after you click on the corresponding label will you be transferred to the website of the corresponding provider, i.e. only then is your user information transferred to the corresponding provider. Information on the handling of your personal data on those websites can be found in the personal data protection statements of those providers. </p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Cookies </Typography>
              <p>
              We use cookies on our websites. "Cookies" are small text files that can be placed on your hard drive when you visit a website. These text files are created and sent to you via the server you have connected to through your browser (e.g. Internet Explorer, Firefox, Google Chrome). Cookies cannot run any programs or infect your computer with viruses. Their purpose is to make the entire Internet service easier to use and more efficient.               </p>
              <p>
              Our websites use the following types of cookies. Their scope and how they function are explained as follows:               </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Temporary cookies (a)" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Persistent cookies (b)" />
                </ListItem>
              </List>
              <p>a) Temporary cookies are automatically deleted when you close your browser. This particularly includes session cookies. They contain a "Session ID" with which the various requests sent by your browser can be assigned to the entire session. This means that your computer will be recognized when you return to our website. Session cookies are deleted when you log out or close your browser. </p>
              <p>b) Persistent cookies are automatically deleted after a predetermined period, which may vary depending on the cookie. You can delete cookies at any time in the security settings of your browser. </p>
              <p>c) You can adjust your browser settings as you wish and, for example, you can turn off the option to receive third-party cookies or all cookies. However, please note that this may prevent you from using all the functions of this website. </p>
              <p>d) The flash cookies we use are not registered by your browser, but by your flash plug-in. We also use HTML5 storage objects that are placed on your terminal. These objects store the necessary data regardless of the browser you use and do not have an automatic expiry date. If you do not want flash cookies to be processed, you must install a plug-in for this, e.g. “Ghostery” for Mozilla Firefox (https://addons.mozilla.org/de/firefox/addon/ghostery/) or Adobe-Flash-Killer-Cookie for Google Chrome. You can also prevent the use of HTML5 storage objects if you use the browser in private mode. </p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Storage period </Typography>
              <p>In general, we store your data only as long as it is necessary to perform the services you have requested or agreed to. If there is a legal retention period, we store your data in accordance with those legal requirements. </p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Your rights </Typography>
              <p>You have the following rights in relation to us as regards to your personal data provided that the relevant legal requirements are met: </p>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Right of access," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The right to correction and addition," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Right to erasure," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The right to limit data processing," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The right to data portability," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The right to be notified of the correction or addition of your personal data, restriction of processing and violation of personal data," />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="The right to be informed whether the provision of personal data is a legal or contractual obligation or whether the provision of data is a necessary condition for concluding a contract, as well as whether the person to whom the data refer has an obligation to provide personal data and the possible consequences of such processing for the person to whom the data refers." />
                </ListItem>
              </List>
              <p><strong>The right to object</strong></p>
              <p>Individual right to object  <br/> <br/>You have the right to submit an objection at any time, based on your specific situation, to the processing of personal data related to you based on Art. 12 (1) 6) of the Personal Data Protection Act (i.e. legitimate interest), including profiling based on those provisions. In that case, we no longer process personal data for those purposes unless we can prove a convincing legitimate basis for processing that outweighs your interests, rights and freedoms or if the processing serves to submit, exercise or defend a legal claim. </p>
              <p>The right to object to processing for direct marketing purposes <br /> <br /> In some cases, we process your data for direct marketing. You have the right to object to the processing of your personal data for these purposes at any time. This applies to profiling to the extent that it is related to that direct marketing. If you object to processing for direct marketing purposes, your personal data will no longer be processed for these purposes. </p>
              <List> 
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary=" Withdrawal of consent: If you have consented to the processing of personal data (including for direct marketing purposes), you may withdraw your consent at any time, this withdrawal having effect for the future. This will not affect the legality of the processing of personal data until you have requested your consent to the processing. " />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Circle fontSize="12" color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="We do not use automated decision-making, including profiling, in the manner provided by the Personal Data Protection Act. " />
                </ListItem>
              </List>
              <p>If you wish to exercise the aforementioned rights, please contact us using the above contact details. </p>

              <p>You also have the right to file a complaint with the supervisory authority for the protection of personal data, if, in your opinion, the processing of your personal data is illegal. In Serbia, you can submit a complaint to the Commissioner for Information of Public Importance and Protection of Personal Data of the Republic of Serbia, 15 Bulevar kralja Aleksandra, Belgrade, Serbia, email: <a className="text-link" href="mailto:office@poverenik.rs">office@poverenik.rs</a> .</p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">External links</Typography>
              <p>Our online service occasionally contains links to third party websites. If it is not easily visible, we will indicate that it is an external link. GILAB has no influence on the content or form of websites of external providers. Therefore, this Privacy Policy is not relevant to those links. </p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Changes to the Privacy Policy </Typography>
              <p>We reserve the right to change this Privacy Policy from time to time. In this regard, please refer to the current version of our Privacy Policy. </p>
            </Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="error" variant="contained" size="small" loading={props.loading} onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default PrivacyPolicyEN;