import { Close } from "@mui/icons-material";
import { Button, Card, CardContent, CardMedia, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useContext } from "react";
import { AppContext } from "../../../AppContext"; 
import { Chart as ChartJS, LinearScale, LineController, BarController, TimeScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

import { formatData, formatOptions } from "../../../config/charts/summary";
import colors from "../../../config/colors";
import $data from "../../../services/$data";
import moment from "moment";
import 'chartjs-adapter-moment';
import geojson2svg, {DefaultStyles} from 'geojson-to-svg';
ChartJS.register( LinearScale, TimeScale, LineController, BarController, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, zoomPlugin );

function SummaryDetails(props) {
  const {lang, user, primary, danger, dangerLight, warning, warningLight, cropTypes} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const renderParcelInfo = () => {
    let parcels = props.parcelA !== props.parcelB ? [props.parcelA, props.parcelB] : [props.parcelA];

    return parcels.map((id, key) => {
        let p = $data.getParcelById(id);

        if(!p) return null

        let parcel = p.properties;
        let compareStyle = {};

        if(props.allData) {
          if(props.allData.length === 2 && parcels.length === 2) {
            compareStyle = {borderTop: `5px solid ${key === 0 ? danger : warning}`}
          }
        }

        let cardMedia = <CardMedia component="img" sx={{maxWidth: 151, overflow: 'hidden', textOverflow: 'ellipsis' }} image={colors.groupImages[$data.getCropGroupIDByCrop(parcel?.activeCrop?.crop, cropTypes)]} alt="crop" />
       
        try {
          if(parcel.activeCrop.crop === 999) {
            let svgStr = geojson2svg().styles((f) => ({fill: colors.cropGroup['5'], opacity: 1, weight: 4,})).data(p).render();
        
            let blob = new Blob([svgStr], {type: 'image/svg+xml'});
            let image = window.URL.createObjectURL(blob);
        
            cardMedia =  <div style={{background: 'rgba(0,0,0,.1)', height: 180, textAlign: 'center'}}>
            <img style={{margin: '0 auto', padding: 5,  display: 'block', transform: 'scaleX(-1) rotate(180deg)', maxWidth: 'calc(100% - 10px)', height: 'calc(100% - 10px)'}} src={image} />
          </div>
          }
        } catch(e) {

        }

      

        return (
          <Grid key={key} item xs={12}>
            <Card elevation={3} sx={{...compareStyle,  display: "flex" }}>
              {cardMedia}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto", paddingRight: '5px' }}>
                  <Typography style={{ color: primary }} gutterBottom variant="h5" component="div" >
                    {parcel.name}&nbsp;          
                     <Chip sx={{fontWeight: 'bold'}} color={parcel.active ? 'success' : 'error'} variant="filled" label={parcel.active ? lang.parcel_active : lang.parcel_inactive} />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong style={{textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, parcel?.activeCrop?.crop)} / {parcel.area ? parcel.area.toFixed(2) : ' - '} <span style={{textTransform: 'lowercase'}}>ha</span></strong>
                  </Typography>
                  <Typography style={{padding: '10px 0 5px 0'}} variant="body2" color="text.secondary">
                    <strong>{lang.parcel_sowing}: {parcel?.activeCrop?.sowing ? moment(parcel.activeCrop.sowing).format('DD-MMM-YYYY') : ' - '}</strong>
                  </Typography>
                  <Typography style={{padding: '5px 0'}} variant="body2" color="text.secondary">
                    <strong>{lang.parcel_harvest}: {parcel?.activeCrop?.harvest ? moment(parcel.activeCrop.harvest).format('DD-MMM-YYYY') : ' - '}</strong>
                  </Typography>
                  
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )

    })
  }

  const renderCharts = () => {
    if(!props.allData) return null;

    if(props.allData.length === 2 && props.parcelA !== props.parcelB) {
      return [
        <Grid sx={{minHeight: '200px', m: '20px 0'}} key={1} item xs={12}>
          <Chart
           type="line"
           data={formatData(props.allData, 'ndvi_mean', lang.ndvi_full, false, user.planet)}
           options={formatOptions()} />
        </Grid>,
        <Grid sx={{minHeight: '200px'}} key={2} item xs={12}>
          <Chart
           type="line"
           data={formatData(props.allData, 'chl_mean', lang.chl_full, true, user.planet)}
           options={formatOptions()} />
        </Grid>
      ]
    }

    return [
      <Grid sx={{minHeight: '200px', m: '20px 0'}} key={1} item xs={12}>
        <Chart
         type="line"
         data={formatData([props.data], 'ndvi_mean', lang.ndvi_full, false, user.planet)}
         options={formatOptions()} />
      </Grid>,
      <Grid sx={{minHeight: '200px'}} key={2} item xs={12}>
        <Chart
         type="line"
         data={formatData([props.data], 'chl_mean', lang.chl_full, true, user.planet)}
         options={formatOptions()} />
      </Grid>
    ]

  
  }

  return (
    <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.detailed_summary_view} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container sx={{mt: '20px'}}>
          <Grid container spacing={2}>
            {renderParcelInfo()}

            {renderCharts()}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} size="small" variant="contained" color="error">{lang.close}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SummaryDetails;