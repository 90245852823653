import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Chip, Fab, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Slider, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { map } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from '../../AppContext';
import { getLayerNames } from "../../config/map";

import satImg from '../../images/sat.png';
import mapImg from '../../images/map.png';
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import { LocalizationProvider, MobileDatePicker, PickersDay } from "@mui/lab";
import { AddPhotoAlternate, ChevronLeft, ChevronRight, CloseRounded, Download, Event, Search, Upload, Workspaces } from "@mui/icons-material";
import $data from "../../services/$data";
import { Link } from "react-router-dom";
import colors from "../../config/colors";
import $seasons from "../../services/$seasons";
import ErrorWrapper from "../ui/error/ErrorWrapper";
import SearchLocation from "./SearchLocation";
import ConfirmDialog from "../ui/ConfirmDialog";
import useMergeState from "../hooks/useMergeState";
import Message from "../ui/Message";
import UploadPhotos from "./modals/UploadPhotos";
import SoilSamples from "../fieldbook/SoilSamples";
import DownloadData from "./modals/DownloadData";

function Panel(props) {
  const containerRef = useRef(null);
  const filesRef = useRef(null);
  let counter = 1;
  const [multiply, setMultiply] = useState(1);
  const { lang, cropTypes, cropGroups, primary, locale, years, year, season, seasons, user, dates, base, source, rgb, layer, sensors, photos, samples, opacity, date, onUpdateState, onUpdatePhotos } = useContext(AppContext)
  const [filter, setFilter] = useState('');

  const [images, setImages] = useState([]);
  const [samplesModal, setSamplesModal] = useState(false);
  const [downloadDataModal, setDownloadDataModal] = useState(false);

  const [uploadState, setUploadState] = useMergeState({
    success: null,
    error: null,
    loading: false,
  })

  useEffect(() => {
    onUpdateState({ mounted: true })
    containerRef.current.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (evt) => {
    if ((evt.target.scrollHeight - evt.target.scrollTop) <= containerRef.current.offsetHeight + 260) {
      if (counter * 30 < props.parcels.length) {
        counter += 1;
        setMultiply(counter);
      }
    }
  }

  const filterParcels = () => {
    return props.parcels.filter(obj => {
      let { pk, name, activeCrop } = obj.properties;
      let field = name.toLowerCase().trim();
      let crop = $data.getCropById(cropTypes, activeCrop.crop)?.toLowerCase()?.trim();
      if (filter === '') return true;

      if (field.indexOf(filter.toLowerCase().trim()) > -1 || crop.indexOf(filter.toLowerCase().trim()) > -1) {
        return true
      }

      return false
    })
  }

  const onFilesChange = (evt) => {
    if (filesRef.current.files) {
      setImages(Array.from(filesRef.current.files));
    }
  }

  const onCloseUpload = (evt, success, error) => {
    setUploadState({ loading: false, success: success || null, error: error || null });

    if (filesRef.current) {
      filesRef.current.value = '';
    }
    setImages([]);
  }

  const onUpload = () => {
    onCloseUpload(null, lang.images_upload_success);
    onUpdatePhotos()
  }

  const nextDate = () => {
    if (dates.indexOf(moment(date).format('YYYY-MM-DD')) === dates.length - 1) {
      onUpdateState({ date: moment().format('YYYY-MM-DD') });
      return;
    }

    if (date !== moment().format('YYYY-MM-DD')) {
      onUpdateState({ date: dates[dates.indexOf(date) + 1] })
    }
  }

  const prevDate = () => {
    if (date == moment().format('YYYY-MM-DD')) {
      onUpdateState({ date: dates[dates.length - 1] })
    }

    if (dates.indexOf(moment(date).format('YYYY-MM-DD')) > 0) {
      onUpdateState({ date: dates[dates.indexOf(date) - 1] });
      return;
    }
  }

  const renderParcels = () => {
    if (props.parcels.length === 0) {
      return <TableRow>
        <TableCell colSpan="4">{lang.no_fields}</TableCell>
      </TableRow>
    }
    let filtered = filterParcels();

    if (filtered.length === 0) {
      return <TableRow>
        <TableCell colSpan="4">{lang.fld_no_filtered_parcels}</TableCell>
      </TableRow>
    }

    return filtered.filter((obj, key) => key <= multiply * 30)
      .map((obj, key) => {
        let { pk, name, activeCrop, area } = obj.properties;
        return (
          <TableRow key={key}>
            <TableCell title={name} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{name}</TableCell>
            <TableCell style={{ textTransform: 'uppercase', fontWeight: 'bold', color: activeCrop.crop === 13 ? '#555' : colors.cropGroup[$data.getCropGroupIDByCrop(activeCrop.crop, cropTypes)] }}>{$data.getCropById(cropTypes, activeCrop.crop)}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{area.toFixed(2)} ha</TableCell>
            <TableCell style={{ textAlign: 'right' }}><Search sx={{ cursor: 'pointer' }} fontSize="small" onClick={() => onUpdateState({ selectedParcel: pk })} /></TableCell>
          </TableRow>
        )
      })
  }

  const getTotalArea = () => {
    let total = 0;

    props.parcels.map(({ properties }) => {
      total += properties.area
    })

    return `${total.toFixed(2)} ha`
  }


  const onCloseMessage = (evt, reason) => {
    if (reason === 'clickaway') return;
    setUploadState({ success: null, error: null })
  }

  return (
    <div ref={containerRef} className={"panel-container" + (props.panel ? ' opened' : '')}>
      {props.panel && <div style={{ position: 'absolute', right: 0, top: '12px', zIndex: 10, cursor: 'pointer' }} onClick={() => props.setPanel(false)}><CloseRounded color="primary" /></div>}
      <Accordion disableGutters defaultExpanded>
        <AccordionSummary className="panel-section-title">{lang.layers}</AccordionSummary>
        <AccordionDetails sx={{ padding: '0 8px' }}>
          <table className="layers-container" style={{ width: '100%' }}>
            <tbody>
              <tr className="basemaps-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.basemap}</Typography> {/*SELECT*/}</td>
                <td>
                  <Stack direction="row" justifyContent="flex-start" spacing={2}>
                    <Avatar onClick={() => onUpdateState({ base: 'sat' })} className={base === 'sat' ? ' active' : ''} alt="satellite" src={satImg} />
                    <Avatar onClick={() => onUpdateState({ base: 'map' })} className={base === 'map' ? ' active' : ''} alt="map" src={mapImg} />
                  </Stack>
                </td>
              </tr>

              {user.planet && <tr className="source-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.source}</Typography> {/*SELECT*/}</td>
                <td>
                  <Stack direction="row" justifyContent="flex-start" spacing={1}>
                    <Chip onClick={() => onUpdateState({ source: 's2' })} sx={{ '& span': { padding: '0 6px' }, cursor: 'pointer' }} color={source === 's2' ? "primary" : "default"} variant="filled" label="Sentinel-2" />
                    <Chip onClick={() => onUpdateState({ source: 'planet' })} sx={{ '& span': { padding: '0 6px' }, cursor: 'pointer' }} color={source === 'planet' ? "primary" : "default"} variant="filled" label="PLANET" />
                  </Stack>
                </td>
              </tr>}

              <tr className="rgb-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.satellite}</Typography> {/*SWITCH*/}</td>
                <td>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Switch checked={rgb} onChange={(evt) => onUpdateState({ rgb: evt.target.checked })} />
                    <Tooltip arrow title={lang.download_all_data}>
                      <IconButton onClick={() => setDownloadDataModal(true)} size="small">
                        <Download />
                      </IconButton>

                    </Tooltip>
                  </Stack>
                </td>
              </tr>

              <tr className="index-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.index}</Typography> {/*SELECT*/}</td>
                <td>
                  <FormControl size="small" fullWidth>
                    <Select sx={{ width: '100%', maxWidth: '180px' }} size="small" value={layer} onChange={(evt) => onUpdateState({ layer: evt.target.value })} fullWidth>
                      {getLayerNames(lang).map((obj, key) => {
                        return <MenuItem size="small" key={key} value={obj.value}>{obj.label}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr className="opacity-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.opacity}</Typography> {/*SLIDER*/}</td>
                <td><Slider size="medium" aria-label="opacity" valueLabelDisplay="auto" valueLabelFormat={(val) => { return `${val}%` }} value={opacity} onChange={(evt, value) => onUpdateState({ opacity: value })} /> </td>
              </tr>

              {layer !== 'cumndvi' && <tr className="dates-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.date}</Typography> {/*SELECT*/}</td>
                <td>
                  <Stack className="datepicker-panel-container" direction="row" justifyContent='flex-start' alignItems="center">
                    {dates.length > 0 && <ChevronLeft color="primary" onClick={prevDate} className={moment(date).diff(moment(dates[0]), 'day') == 0 ? ' disabled' : ''} />}

                    {dates.length > 0 && <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
                      <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} shouldDisableDate={(date) => {
                        // return false
                        return dates.indexOf(date.format('YYYY-MM-DD')) === -1 && date.format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')
                      }} disableCloseOnSelect={false}
                        minDate={dates[0] ? moment(dates[0]) : undefined} maxDate={moment()}
                        inputFormat="DD-MMM-YYYY" className="datepicker-root" value={moment(date)} onChange={(newValue) => onUpdateState({ date: newValue.format('YYYY-MM-DD') })}

                        renderInput={(params) => (
                          <TextField style={{ maxWidth: '130px' }} fullWidth {...params} size="small" />
                        )}

                        renderDay={(day, selected, props) => {

                          let className = '';

                          if (dates.indexOf(day.format('YYYY-MM-DD')) > -1) {
                            className = 'background-secondary-with-opacity'
                          }

                          if (day.format('YYYY-MM-DD') == date) {
                            className = 'background-primary'
                          }



                          return <PickersDay className={className} {...props} />
                        }}
                      />
                    </LocalizationProvider>}
                    {dates.length > 0 && <ChevronRight color="primary" onClick={nextDate} className={moment(date).diff(moment(), 'day') == 0 ? ' disabled' : ''} />}

                    {dates.length === 0 && <ChevronLeft color="primary" className="disabled" />}
                    {dates.length === 0 && <TextField focused={false} disabled style={{ maxWidth: '130px' }} fullWidth inputProps={{ readOnly: true }} value=" - " size="small" />}
                    {dates.length === 0 && <ChevronRight color="primary" className="disabled" />}

                  </Stack>

                </td>
              </tr>}

              {layer === 'cumndvi' && <tr className="dates-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.date}</Typography> {/*SELECT*/}</td>
                <td>
                  <Grid container spacing={1}>


                    {years.map((y, i) => {
                      return (
                        <Grid sx={{ mb: '5px' }} xs={6}>
                          <Button onClick={() => onUpdateState({ 'year': y })} key={i} size="small" variant={year === y ? 'contained' : 'outlined'}>{y}</Button>

                        </Grid>
                      )
                    })}
                  </Grid>
                </td>
              </tr>}

              <tr className="photos-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.photos}</Typography> {/*SWITCH*/}</td>
                <td><Stack direction="row" justifyContent="space-between"><Switch checked={photos} onChange={(evt) => onUpdateState({ photos: evt.target.checked })} />
                  <Tooltip title={lang.upload_photos} placement="left" arrow>
                    <Fab component="label" color="primary" variant="contained" size="small">
                      <AddPhotoAlternate />
                      <input id="geo-tagged-photo-input" ref={filesRef} onChange={onFilesChange} type="file" multiple hidden accept="image/*" />
                    </Fab>
                  </Tooltip>
                </Stack></td>
              </tr>

              {(user.sensors || user.pk === 349) && <tr className="sensors-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.sensors}</Typography> {/*SWITCH*/}</td>
                <td><Switch checked={sensors} onChange={(evt) => onUpdateState({ sensors: evt.target.checked })} /></td>
              </tr>}

              <tr className="samples-row">
                <td><Typography color={'primary'} variant="subtitle2">{lang.soil_measurements}</Typography> {/*SWITCH*/}</td>
                <td>
                  <Stack direction="row" justifyContent="space-between">
                    <Switch checked={samples} onChange={(evt) => onUpdateState({ samples: evt.target.checked })} />
                    <Tooltip title={lang.soil_measurements_upload} placement="left" arrow>
                      <Fab onClick={() => setSamplesModal(true)} component="label" color="primary" variant="contained" size="small">
                        <Workspaces />
                      </Fab>
                    </Tooltip>
                  </Stack>
                </td>

              </tr>



            </tbody>
          </table>
        </AccordionDetails>

      </Accordion>
      <Accordion disableGutters defaultExpanded>
        <AccordionSummary className="panel-section-title">{lang.my_fields}</AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1}>
            <TextField className="filter-parcels-row" label={lang.map_filter} fullWidth value={filter} onChange={(evt) => setFilter(evt.target.value)} size="small" />
            <Stack className="crop-groups-row" direction={'row'} justifyContent='space-between' alignItems="center">
              {Object.keys(colors.cropGroup).map((id, key) => {
                return <Tooltip placement="top" title={$data.getCropGroupByID(id, cropGroups) || ''} key={key}>
                  <div style={{ cursor: 'pointer', background: colors.cropGroup[id], width: 20, height: 20, borderRadius: '100%' }}></div>
                </Tooltip>
              })}
            </Stack>
          </Stack>
          <Stack sx={{ mt: '10px', textAlign: 'center', fontWeight: 'bold' }} flex flexDirection="row" justifyContent={'center'}>
            {lang.total_area}:&nbsp; <span className="text-primary">{getTotalArea()}</span>
          </Stack>
          <Stack>
            <Table sx={{ '& td, & th': { fontSize: '11px', padding: '6px' } }} size="small" style={{ width: '100%', tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '60px' }}>{lang.parcel_name}</TableCell>
                  <TableCell style={{ width: '60px' }}>{lang.parcel_crop}</TableCell>
                  <TableCell>{lang.parcel_area}</TableCell>
                  <TableCell style={{ width: '20px', padding: 0 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderParcels()}
              </TableBody>
            </Table>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {/* 
      <ConfirmDialog confirmText={<><Upload fontSize="small" /> {lang.confirm}</>} loading={uploadState.loading} error={uploadState.error} open={images.length > 0} title={lang.upload_photos} question={lang.upload_photos_note} onClose={onCloseUpload} onSubmit={onUpload}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <tbody>
          {images.map((image, key) => {
            return (
              <tr key={key}>
                <td style={{background: key % 2 === 0 ? 'rgba(200, 200, 200, .1)' : 'transparent',padding: '3px', fontWeight: 'bold'}}>{image.name}</td>
                <td style={{background: key % 2 === 0 ? 'rgba(200, 200, 200, .1)' : 'transparent',padding: '3px'}}>{image.type}</td>
                <td style={{background: key % 2 === 0 ? 'rgba(200, 200, 200, .1)' : 'transparent',padding: '3px'}}>{Math.round(image.size / 1024)} KB</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      
      </ConfirmDialog> */}

      <UploadPhotos open={images.length > 0} images={images} onClose={onCloseUpload} onSuccess={onUpload} />
      <SoilSamples open={Boolean(samplesModal)} onClose={() => setSamplesModal(false)} />
      {downloadDataModal && <DownloadData open={downloadDataModal} onClose={() => setDownloadDataModal(false)} />}
      <Message type="success" open={uploadState.success ? true : false} message={uploadState.success} onClose={() => onCloseMessage(null, null)} />
      {/* <Message type="success" open={true} message={'test'} onClose={() => onCloseMessage(null, null)} /> */}

    </div>
  )
}

export default Panel;

