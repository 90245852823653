import { Close } from "@mui/icons-material";
import { Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";

export default function ParcelList(props) {
	const containerRef = useRef(null);

	const {lang, cropTypes} = useContext(AppContext);

	const [filter, setFilter] = useState('');
	const [multiply, setMultiply] = useState(1);

	let counter = 1;

	useEffect(() => {
		containerRef.current.addEventListener('scroll', handleScroll);
	}, [])


	const handleScroll = (evt) => {
    if((evt.target.scrollHeight - evt.target.scrollTop) <= (containerRef.current.offsetHeight + 80)) {
			
      if(counter * 20 < props.parcels.length) {
        counter += 1;
        setMultiply(counter);
      }
    }
  }

	const onChange = (checked, val) => {
		if(checked) {
			if(val === 'all') {
				props.onSelect(props.parcels.map(obj => obj.properties.pk))
			} else {
				props.onSelect(_.concat(props.selected, val))
			}
		} else {
			if(val === 'all') {
				props.onSelect([]);
			} else {
				props.onSelect(props.selected.filter(s => s !== val))
			}
		}
	}

	const renderRows = (data) => {
		if(!data) return <TableRow><TableCell colSpan={3}>{lang.no_parcels}</TableCell></TableRow>
		if(data.length === 0) return <TableRow><TableCell colSpan={3}>{lang.no_parcels}</TableCell></TableRow>
		
		let list = data
			.filter(obj => {
				let {properties} = obj;
				if(filter === '' ) return true;
				let activeCrop = properties.activeCrop?.crop_name ? properties.activeCrop?.crop_name : $data.getCropById(cropTypes, properties.activeCrop.crop);
				return (properties.name.toLowerCase().trim().indexOf(filter.toLowerCase().trim()) > -1 ||
				activeCrop.toLowerCase().trim().indexOf(filter.toLowerCase().trim()) > -1)
			

			})
			.filter((obj, key) => key <= multiply * 20)
			.map((obj, key) => {
			let {properties} = obj;
			return (
				<TableRow key={key}>
					<Tooltip placement="left" arrow title={properties.name}><TableCell style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{properties.name}</TableCell></Tooltip>
					<TableCell style={{textTransform: 'uppercase'}}>{$data.getCropById(cropTypes, properties.activeCrop.crop)}</TableCell>
					<TableCell>
						<Checkbox value={properties.pk} checked={props.selected.indexOf(properties.pk) > -1} onChange={(evt) => onChange(evt.target.checked, properties.pk)} />
					</TableCell>
				</TableRow>
			)
		});

		return list
	
	}

	return (
		<Stack spacing={2} className="vrt-parcel-list-container vrt-parcel-open-row">
			<Stack className="vrt-parcel-list-header" direction="row" justifyContent="space-between" alignItems="center">
				{lang.vrt_parcel_list_title}
				<Close onClick={props.onClose} />
			</Stack>

			<Stack style={{padding: '0 10px'}}>
				<TextField value={filter} onChange={(evt) => setFilter(evt.target.value)} label={lang.filter_parcels} size="small" />
			</Stack>

			<Stack ref={containerRef} style={{overflowY: 'auto', height: 'calc(100% - 100px)', padding: '0 10px 20px 0'}}>
				<Table style={{tableLayout: 'fixed', width: '100%'}} size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{lang.parcel_name}</TableCell>
							<TableCell>{lang.parcel_crop}</TableCell>
							<Tooltip title={lang.fld_select_all_parcels} placement="top"><TableCell>
								<Checkbox value={'all'} checked={props.selected.toString() === props.parcels.map(obj => obj.properties.pk).toString()} onChange={(evt) => onChange(evt.target.checked, 'all')} />
							</TableCell></Tooltip>
						</TableRow>
					</TableHead>
					<TableBody>
						{renderRows(props.parcels)}
					</TableBody>
				</Table>
			</Stack>

		</Stack>
	)
}