import {  Edit, HdrStrong, History, Upload, UploadFile } from "@mui/icons-material";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import Message from "../ui/Message";
import UploadParcels from "./modals/UploadParcels";

function Toolbar(props) {
  const {lang, action, onUpdateState, onUpdateParcels} = useContext(AppContext);

  const [upload, setUpload] = useState(false);
  const [success, setSuccess] = useState(null);

  const cancelAction = (evt) => {
    if(evt.keyCode === 27) {
      onUpdateState({action: null});
    }
  }
  
  
  useEffect(() => {
    document.addEventListener('keyup', cancelAction);
    
    return () => {
      document.removeEventListener('keyup', cancelAction);
    }
  }, [])

  const onChange = (nextAction) => {
    onUpdateState({action: nextAction === action ? null : nextAction})
  }

  const onSuccess = () => {
    setSuccess(lang.action_upload_success);
    onUpdateParcels();
  }

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setSuccess(null);
  }

  return (
    <ButtonGroup className="toolbar-group-row" sx={{'& .MuiButtonGroup-grouped': {padding: '6px 6px 6px 6px', minWidth: 'unset', height: '42px', width: '42px'}, position: 'absolute', top: '7px', right: 0}} orientation="vertical">
      <Tooltip placement="left" title={lang.action_compare}>
        <Button className="toolbar-compare-row" size="small" onClick={() => onChange('compare')} variant="contained" color={action === 'compare' ? 'warning' : 'primary' }>
          <i className="toolbar-icon fg-pois"></i>
        </Button>
      </Tooltip>
      
      <Tooltip placement="left" title={lang.action_draw}>
        <Button  className="toolbar-draw-row" size="small" onClick={() => onChange('draw')} variant="contained" color={action === 'draw' ? 'warning' : 'primary' }>
          <i className="toolbar-icon fg-polygon-pt"></i>
        </Button>
      </Tooltip>

      <Tooltip placement="left" title={lang.action_upload}>
        <Button size="small" onClick={() => setUpload(true)} variant="contained" color={action === 'upload_parcels' ? 'warning' : 'primary' }>
          <Upload />
        </Button>
      </Tooltip>

      <Tooltip placement="left" title={lang.action_measure_length}>
        <Button  className="toolbar-measure-length-row"  size="small" onClick={() => onChange('measure_length')} variant="contained" color={action === 'measure_length' ? 'warning' : 'primary' }>
          <i className="toolbar-icon fg-measure-line"></i>
        </Button>
      </Tooltip>

      <Tooltip placement="left" title={lang.action_measure_area}>
        <Button  className="toolbar-measure-area-row" size="small" onClick={() => onChange('measure_area')} variant="contained" color={action === 'measure_area' ? 'warning' : 'primary' }>
          <i className="toolbar-icon fg-measure-area"></i>
        </Button>
      </Tooltip>

      <UploadParcels open={upload} onClose={() => setUpload(false)} onSuccess={() => {setUpload(false); onSuccess();}} />
      <Message type="success" open={Boolean(success)} message={success} onClose={() => onCloseMessage()} />
    </ButtonGroup>
  )
}

export default Toolbar;