import { AttachFile, Close, Delete, Download, Edit, Event, FileDownload, FilePresent, Refresh, Upload } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import Message from "../ui/Message";
import Divider from "../auth/Divider";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";
import _ from "lodash";
import {read, utils} from 'xlsx'
import SoilMappingModal from "./SoilMappingModal";
import SoilNameConfirm from "./SoilNameConfirm";

function SoilMappingPreview(props) {
  const {lang, onUpdateSamples} = useContext(AppContext);
  const filesRef = useRef([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const [state, setState] = useMergeState({samples: [], count: 0, rowsPerPage: 10, page: 0, loading: false, error: false});

  const [nameModal, setNameModal] = useState(false);

  useEffect(() => {
    if(props.open) {
      filesRef.current = [];
    }
  }, [props.open])

  const onHandlePageChange = (evt, pg) => {
    setState({page: pg})
  }

  const renderSamples = () => {
    if(state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }

    if(props.rows.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            {lang.no_documents}
          </TableCell>
       </TableRow>
      )
    }

    return props.rows.map((obj, key) => {
      return (
        <TableRow key={key}>
          {props.columns.map((name, j) => {
            return <TableCell key={j}>{obj[j] || ' - '}</TableCell>
          })}
        </TableRow>
      )
    })
    
  }

  const uploadSamples = (collection) => {
    let model = [];

    let formated = props.rows.map((obj, i) => {
      let record = {};

      Object.values(obj).map((val, j) => {
        record[props.columns[j]] = val;
      })

      return record;

    });

    // return
    formated.map((obj) => {
      let newObj = {
        longitude: obj.longitude || '',
        latitude: obj.latitude || '',
        parcel: obj.parcel || "",
        datestamp: obj.datestamp || '',
        name: obj.name || ''
      };

      let data = {...obj};
      delete data.name;
      delete data.longitude;
      delete data.latitude;
      delete data.datestamp;
      delete data.parcel;


      newObj.dataset = JSON.stringify(data);
      // newObj.dataset = data;

      model.push(newObj)
    });

    setState({loading: true});
    // let fd = new FormData();
    // fd.append('data', JSON.stringify(model));
    $data.postSamples({data: model, filename: collection})
      .then(data => {
        setState({loading: false});
        onUpdateSamples();
        props.onSuccess();
      })
      .catch(err => {
        setState({loading: false, error: err.error})
      })
  }


  return (
    <Dialog fullWidth maxWidth="xl" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.soil_measurements_upload}
        <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Grid sx={{mt: '30px'}} container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper} elevation={2} sx={{width: '100%', maxHeight: '500px', overflow: 'auto'}}>
            <Table size="small">
            {props.columns.length > 0 && <TableHead><TableRow sx={{position: 'sticky', top: 0, background: 'white'}}>{props.columns.map((name, key) => <TableCell key={key}>{['name', 'datestamp', 'longitude', 'latitude'].indexOf(name) > -1 ? lang['soil_' + name] : name}</TableCell>)}</TableRow></TableHead>}
              <TableBody>
                {renderSamples()}
              </TableBody>
            </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={state.loading} startIcon={<Upload />} color="primary" variant="contained" size="small" onClick={() => setNameModal(true)}>{lang.confirm}</LoadingButton>
        <LoadingButton loading={state.loading} color="error" variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>
      </DialogActions>

      {nameModal && <SoilNameConfirm open={nameModal} onClose={() => setNameModal(false)} onSuccess={(name) => {setNameModal(false); uploadSamples(name);}} />}
      
      
      <Message type="error" open={state.error ? true : false} message={state.error} onClose={() => setState({error: null})} />
      <Message type="success" open={state.success ? true : false} message={state.success} onClose={() => setState({success: null})} />

    </Dialog>
  )
}

export default SoilMappingPreview;