import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import Message from "./Message";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";

function ContactForm(props) {
  const {lang, user} = useContext(AppContext);

  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(null);

  const [loading, setLoading] = useState(false);

  const [model, setModel] = useMergeState({
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    subject: '',
    message: ''
  })

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setModel({
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      subject: '',
      message: ''
    })
  }, [props.open])

  const onCloseMessage = (evt, reason) => {
    if(reason === 'clickaway') return;
    setError(null);
    setSuccess(null)
  }

  const onChange = (name, value) => {
    setModel({[name]: value})
  }

  const onPost = () => {
    setLoading(true);
    setError(null);

    $data.postContactMessage(model)
      .then(() => {
        setSuccess(lang.contact_success);
        setLoading(false);

        props.onSuccess();
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })

  }

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.contact_us}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
						<Grid item xs={12}>
              <TextField error={error?.name ? true : false} helperText={error?.name || ''} label={lang.contact_name} fullWidth onChange={(evt) => onChange('name', evt.target.value)} size="small" value={(model.name)} />
						</Grid>
            <Grid item xs={12}>
              <TextField error={error?.subject ? true : false} helperText={error?.subject || ''} label={lang.contact_subject} fullWidth onChange={(evt) => onChange('subject', evt.target.value)} size="small" value={(model.subject)} />
						</Grid>
            <Grid item xs={12}>
              <TextField error={error?.message ? true : false} helperText={error?.message || ''} rows={5} multiline label={lang.contact_message} fullWidth size="small" onChange={(evt) => onChange('message', evt.target.value)}  value={model.message} />
						</Grid>
					</Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color={"primary"} variant="contained" size="small" loading={loading} onClick={onPost}>{props.confirmText || lang.confirm}</LoadingButton>
        <LoadingButton color={"error"} variant="contained" size="small" loading={loading} onClick={props.onClose}>{props.closeText || lang.close}</LoadingButton>
      </DialogActions>

      <Message type="error" open={error?.non_field_errors ? true : false} message={error} onClose={onCloseMessage} />

    </Dialog>
  )
}

export default ContactForm;