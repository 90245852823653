import { Check, LocationOn } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { toLonLat } from "ol/proj";
import { useState } from "react";
import $map from "../../services/$map";
import Message from "../ui/Message";

function Coordinates({a, b}) {
	const [copied, setCopied] = useState(false);

	const format = (arr, opt_full) => {
		let lonLat = toLonLat(arr);
		if(opt_full) {
			return `${lonLat.reverse().join(', ')}`;
		}

		return `${lonLat.reverse().map(v => v.toFixed(4)).join(', ')}`;

	}

	

	return (
		<Stack direction="row" justifyContent="space-between" sx={{zIndex:1, position: 'absolute', left: '7px', top: '54px', maxWidth: '400px', width: 'calc(100% - 56px)'}}>
        {a && <Button onClick={() => {
					if(window.navigator) {
						navigator.clipboard.writeText(format(a, true));
						setCopied(true);
					}
					$map.instance.getView().animate({center: a, duration: 500})}} sx={{fontWeight: 'bold'}} variant="contained" color="error" size="small" startIcon={<LocationOn />}>{format(a)}</Button>}
        {b && <Button onClick={() => {
					if(window.navigator) {
						navigator.clipboard.writeText(format(b, true));
						setCopied(true);
					}
					$map.instance.getView().animate({center: b, duration: 500})}} sx={{fontWeight: 'bold'}} variant="contained" color="warning" size="small" startIcon={<LocationOn />}>{format(b)}</Button>}

				<Message type="info" open={copied} message={'Copied to clipboard'} autoHide={3000} onClose={() => setCopied(false)} />
		 </Stack>
	)

}

export default Coordinates;