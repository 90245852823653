import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";

import olMap from 'ol/Map';
import View from "ol/View";
import { fromLonLat, toLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import OSM from 'ol/source/OSM';
import _, { map } from "lodash";
import $map from "../../services/$map";
import useMergeState from "../hooks/useMergeState";
import { basemaps, imagery, indices, overlays } from "../../config/map";
import Overlay from 'ol/Overlay';
import { unByKey } from "ol/Observable";
import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import {defaults as interactionDefaults, DragPan} from 'ol/interaction';

import $query from "../../services/$query";
import AddParcel from "./modals/AddParcel";
import Message from "../ui/Message";
import { LineString, Polygon } from "ol/geom";
import {getLength as getSphereLength, getArea as getSphereArea} from 'ol/sphere'
import { Add, Remove, Settings, ZoomIn, ZoomOut } from "@mui/icons-material";
import { Button, Fab, Stack, Tooltip } from "@mui/material";
import { getCountryBbox } from "../../config/countries";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import SamplesPreview from "./modals/SamplesPreview";
import SearchLocation from "./SearchLocation";
import Geolocation from "ol/Geolocation";
import Point from "ol/geom/Point";
import { useGeoLocation } from "../hooks/useGeoLocation";
import PhotosPreview from "./views/PhotosPreview";


const elementResizeDetectorMaker = require('element-resize-detector');
const erd = elementResizeDetectorMaker();

let listeners = [];
let mapListeners = [];
let actionListeners = [];
let interactions = [];

let sketch = null;
let helpTooltipElement, helpTooltip, measureTooltipElement, measureTooltip;

function createMeasureTooltip(map) {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
    stopEvent: false,
    insertFirst: false,
  });
  map.addOverlay(measureTooltip);
}

function createHelpTooltip(map) {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement('div');
  helpTooltipElement.className = 'ol-tooltip hidden';
  helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: 'center-left',
  });
  map.addOverlay(helpTooltip);
}

function Map(props) {
  const {lang, user, toUpdate, season, action, zoom, center, date, year, base, rgb, source, layer, opacity, sensorsData, sensors, samplesData, samples, photosData, photos, geolocation, cropTypes, onUpdateState, onUpdateParcels} = useContext(AppContext);

  const compareState = useRef({pointA: null, pointB: null, parcelA: null, parcelB: null});
  const setCompareState = (data) => compareState.current = Object.assign({}, compareState.current, {...data});

  const resizeMonitor = useRef(null);
  const mapElement = useRef(null);
  const [mapState, setMapState] = useMergeState({});
  const [modals, setModals] = useMergeState({addModal: false, addFeature: null, photoPreview: null});
  const [photoIndex, setPhotoIndex] = useState(0);
  const [triggerState, setTriggerState] = useState(false);

  const geolocationObj = useGeoLocation(geolocation);

  const cleanup = () => {     
    onUpdateState({action: null}); mapListeners.map(key => unByKey(key)); 
    listeners.map(key => unByKey(key)); 
    // erd.removeListener(mapElement.current, resizeMonitor.current);
  };

  const init = (noZoom) => {
    //INIT DATA
    let baseMaps = basemaps(base);
    let layers = indices(user.pk, layer, date, opacity, lang, source === 'planet', year);
    let rgbLayer = imagery(user.pk, rgb, date, source === 'planet')
    let parcels = $map.parcelLayer(props.parcels, cropTypes);
    let sensorLayer = $map.sensorLayer(sensorsData, sensors);
    let samplesLayer = $map.samplesLayer(samplesData, samples);
    let photosLayer = $map.photosLayer(photosData, photos);
    let geolocationLayer = $map.geolocationLayer();
    let drawLayer = $map.drawLayer();
    let lineLayer = $map.lineLayer();
    let areaLayer = $map.areaLayer();
    listeners = _.concat(listeners, $map.clipLayers(layers, parcels));
    let markers = overlays();   

    

    interactions = [
      new Draw({ source: drawLayer.getSource(), type: 'Polygon' }),
      new Snap({ source: parcels.getSource()}),
      new Draw({ style: $map.measureStyle, source: lineLayer.getSource(), type: 'LineString'}),
      new Draw({ style: $map.measureStyle, source: areaLayer.getSource(), type: 'Polygon'})
    ]

    //INIT MAP
    let mapInstance = new olMap({
      target: mapElement.current,
      pixelRatio: 1,
      layers: _.concat(baseMaps, rgbLayer, layers, parcels, sensorLayer, samplesLayer, photosLayer, geolocationLayer, drawLayer, lineLayer, areaLayer),
      overlays: markers,
      interactions: interactionDefaults({pinchRotate: false, dragRotate: false}).extend([...interactions, new DragPan({condition: (evt) => evt.originalEvent.isPrimary})]),
      view: new View({ center: fromLonLat(center), zoom })
    });

    createMeasureTooltip(mapInstance);
    createHelpTooltip(mapInstance);

    if(parcels.getSource().getFeatures().length > 0 && !noZoom) {
      mapInstance.getView().fit(parcels.getSource().getExtent());
    } else {
      let bbox = getCountryBbox(user.country);
      let min = fromLonLat([bbox[0], bbox[1]]);
      let max = fromLonLat([bbox[2], bbox[3]])

      mapInstance.getView().fit([min[0], min[1], max[0], max[1]], {padding: [20, 20, 20, 20]}); 
    }

    setMoveEvents(mapInstance);

    setAction(mapInstance, parcels, markers, lineLayer, areaLayer);

    if(props.zoomToParcel) {
      handleZoomToParcel(props.zoomToParcel, parcels, markers[0], mapInstance);
    }

    $map.instance = mapInstance;
    setMapState({map: mapInstance, baseMaps, rgbLayer, geolocationLayer, layers, parcels, markers, sensorLayer, samplesLayer, photosLayer, drawLayer, lineLayer, areaLayer});
  };

  const update = () => {
    let {map, layers, parcels, photosLayer, samplesLayer} = mapState;
    //CLEAR
    layers.map(l => map.removeLayer(l));
    parcels.getSource().clear();
    photosLayer.getSource().getSource().clear();
    samplesLayer.getSource().clear();
    listeners = $map.unclipLayers(listeners);
    
    //ADD NEW
    parcels.getSource().addFeatures($map.parcelLayer(props.parcels).getSource().getFeatures());
    photosLayer.getSource().getSource().addFeatures($map.photosLayer(photosData, photos).getSource().getSource().getFeatures());
    samplesLayer.getSource().addFeatures($map.samplesLayer(samplesData, samples).getSource().getFeatures());
    listeners = _.concat(listeners, $map.clipLayers(layers, parcels));
    layers.map(l => map.addLayer(l));
  }

  const setMoveEvents = (instance) => {
    mapListeners.push(instance.on('moveend', (evt) => { onUpdateState({zoom: evt.map.getView().getZoom(), center: toLonLat(evt.map.getView().getCenter())}) }))
    mapListeners.push(instance.on('pointermove', (evt) => {
      if(instance.getFeaturesAtPixel(evt.pixel, {layerFilter: (l) => l.get('name') === 'sensors' || l.get('name') === 'photos'})[0]) {
        mapElement.current.style.cursor = 'pointer';
      } else {
        mapElement.current.style.cursor = 'initial';        
      }
    }))
  }

  const cancelActions = (markers, lineLayer, areaLayer) => {
    lineLayer.getSource().clear();
    areaLayer.getSource().clear();
    markers.map(m => m.setPosition(null));
    actionListeners.map(l => unByKey(l));
    interactions.map(i => i.setActive(false));
  }

  const setAction = (mapInstance, parcels, markers, lineLayer, areaLayer) => {
    cancelActions(markers, lineLayer, areaLayer);

    switch(action) {
      case 'compare': compareAction(mapInstance, parcels, markers); break;
      case 'draw': drawAction(); break;
      case 'delete': break;
      case 'edit': break;
      case 'measure_length': lengthAction(); break;
      case 'measure_area': areaAction(); break;
      default: defaultAction(mapInstance, parcels, markers[0]);
      
    }
  }

  //MOUNT
  useEffect(() => {
    init();

    resizeMonitor.current = (element) => {
      $map.instance.updateSize();
    }


    setTimeout(() => {
      erd.listenTo(document.querySelector('.map'), resizeMonitor.current);
    }, 500)
   

    return cleanup
  }, [])

  //UX CHANGE
  // useEffect(() => {
  //   if(mapState.map) {
  //     setTimeout(()=> {
  //       mapState.map.render();
  //       mapState.map.updateSize();
  //     }, 250)
  //   }
  // }, [props.panel])

  //ONCHANGE
  useEffect(() => {
    if(mapState.map) {update()}
  }, [season]);

  useEffect(() => {
    onUpdateState({toUpdate: false});
    if(mapState.map) { if(toUpdate) { update(); } }
  }, [toUpdate])

  useEffect(() => {
    if(mapState.map) { setAction(mapState.map, mapState.parcels, mapState.markers, mapState.lineLayer, mapState.areaLayer) }
  }, [action]);

  useEffect(() => {
    if(mapState.map) { $map.setSource(source, mapState.layers, mapState.rgbLayer) }
  }, [source]);

  useEffect(() => {
    if(mapState.map) { $map.setBase(base, mapState.baseMaps) }
  }, [base]);

  useEffect(() => {
    if(mapState.map) { $map.setRgb(rgb, mapState.rgbLayer) }
  }, [rgb])

  useEffect(() => {
    if(mapState.map) { $map.setLayer(layer, mapState.layers) }
  }, [layer]);

  useEffect(() => {
    if(mapState.map) { $map.setSensors(sensors, mapState.sensorLayer) }
  }, [sensors]);

  useEffect(() => {
    if(mapState.map) { $map.setPhotos(photos, mapState.photosLayer) }
  }, [photos]);

  useEffect(() => {
    if(mapState.map) { $map.setSamples(samples, mapState.samplesLayer) }
  }, [samples]);

  useEffect(() => {
    if(mapState.map) { 
      $map.setParcelStyle(mapState.parcels, props.parcels)
      $map.setDate(date, mapState.layers, mapState.rgbLayer); 
    }
  }, [date]);

  useEffect(() => {
    if(mapState.map) { 
      $map.setParcelStyle(mapState.parcels, props.parcels)
      $map.setYear(year, mapState.layers); 
    }
  }, [year]);

  useEffect(() => {
    if(mapState.map) { $map.setOpacity(opacity, mapState.layers) }
  }, [opacity]);

  useEffect(() => {
    if(mapState.map) { if(!geolocation) {$map.setGLPosition(mapState.geolocationLayer, null); $map.instance.getView().animate({rotation: 0, duration: 500})} }
  }, [geolocation]);

  useEffect(() => {
    if(mapState.map) {
      if(geolocation) {
        $map.setGLPosition(mapState.geolocationLayer, geolocationObj.coords);
        if(geolocationObj.coords) {
          $map.instance.getView().animate({center: geolocationObj.coords, duration: 500}, {zoom: 18, duration: 500});
        }
      }
    }
  }, [JSON.stringify(geolocationObj.coords)])

  useEffect(() => {
    if(mapState.map) {
      if(geolocation) {
        $map.setHeadingStyle(mapState.geolocationLayer, geolocationObj.heading);

        if(geolocationObj.heading) {
          $map.instance.getView().animate({rotation: (geolocationObj.heading * Math.PI)/180, duration: 500});
        }
      }
    }
  }, [geolocationObj.heading])

  useEffect(() => {
    if(mapState.map && props.zoomToParcel) { handleZoomToParcel(props.zoomToParcel, mapState.parcels, mapState.markers[0], mapState.map) }
  }, [props.zoomToParcel])

  const handleZoomToParcel = (parcel, parcelLayer, marker, mapInstance) => {

    onUpdateState({action: null, selectedParcel: null});
    setTimeout(() => {
      let centroid = $map.zoomToParcel(parcel, parcelLayer, mapInstance);
      //SET POSITION
      if(centroid) {
        props.onPointHandler(centroid.getCoordinates().filter((v, i) => i <= 1), parcel);
        setTimeout(() => marker.setPosition(centroid.getCoordinates()))
      }
    })
    
  }

  //ACTIONS
  const defaultAction = (mapInstance, parcels, marker) => {
    actionListeners.push(mapInstance.on('singleclick', (evt) => {

      let sensorClicked = mapInstance.getFeaturesAtPixel(evt.pixel, {layerFilter: l => l.get('name') === 'sensors'})[0];

      let samplesClicked = mapInstance.getFeaturesAtPixel(evt.pixel, {layerFilter: l => l.get('name') === 'samples'});

      if(samplesClicked.length > 0) {
        setModals({samplesPreview: samplesClicked});

        return
      }
      
      let groupClicked = mapInstance.getFeaturesAtPixel(evt.pixel, {layerFilter: l => l.get('name') === 'photos'})[0];
      let photosClicked = [];
      
      if(groupClicked) {
        photosClicked = groupClicked.get('features')
      }

      
      if(photosClicked.length > 0) {
        setModals({photoPreview: photosClicked.map(f => f.getProperties())})
        return
      }

      let featureClicked = parcels.getSource().getFeaturesAtCoordinate(evt.coordinate)[0];
      if(featureClicked) {
        marker.setPosition(evt.coordinate); 
        props.onPointHandler(evt.coordinate, featureClicked.get('pk'), sensorClicked);
        return 
      }

      if(sensorClicked) {
        marker.setPosition(evt.coordinate); 
        props.onPointHandler(null, null, sensorClicked);
      }
    }));  

     

  };

  const compareAction = (mapInstance, parcels, markers) => {
    actionListeners.push(mapInstance.on('singleclick', (evt) => {
      let {pointA, pointB, parcelA, parcelB} = compareState.current;
      let featureClicked = parcels.getSource().getFeaturesAtCoordinate(evt.coordinate)[0];
      if(featureClicked) {

        //FIRST
        if(!pointA) {
          markers[0].setPosition(evt.coordinate);
          setCompareState({pointA: evt.coordinate, parcelA: featureClicked.get('pk')});
        }
       
        //CALL
        if(pointA && !pointB) {
          markers[1].setPosition(evt.coordinate);
          setCompareState({pointB: evt.coordinate, parcelB: featureClicked.get('pk')});
          props.onCompareHandler(pointA, evt.coordinate, parcelA, featureClicked.get('pk'));
        }

        //RESET
        if(pointA && pointB) {
          markers[0].setPosition(evt.coordinate);
          markers[1].setPosition(null);
          setCompareState({pointA: evt.coordinate, pointB: null, parcelA: featureClicked.get('pk'), parcelB: null});
        }
      }
    }));
  };

  const drawAction = () => {
    interactions[0].setActive(true);
    interactions[1].setActive(true);

    actionListeners.push(interactions[0].on('drawend', (evt) => {
      setModals({addModal: true, addFeature: evt.feature});
    }))
  }

  const drawSuccess = () => {
    setModals({addModal: false, addFeature: null, addSuccess: lang.success_add_field});
    mapState.drawLayer.getSource().clear();
    onUpdateParcels();
  }

  const lengthAction = () => {
    interactions[2].setActive(true);
    let listener;

    actionListeners.push(interactions[2].on('drawstart', (evt) => {
      sketch = evt.feature;

      listener = sketch.getGeometry().on('change', (e) => {
        const geom = e.target;
        measureTooltip.setPosition(geom.getLastCoordinate());
        measureTooltipElement.innerHTML = getSphereLength(geom).toFixed(0) + ' m';
      });
    }))

    actionListeners.push(interactions[2].on('drawend', (evt) => {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      measureTooltip.setOffset([0, -7]);
      sketch = null;
      // unset tooltip so that a new one can be created
      measureTooltipElement = null;
      createMeasureTooltip(mapState.map);
      unByKey(listener);
    }))
  }

  const areaAction = () => {
    interactions[3].setActive(true);
    let listener;

    actionListeners.push(interactions[3].on('drawstart', (evt) => {
      sketch = evt.feature;

      listener = sketch.getGeometry().on('change', (e) => {
        const geom = e.target;
        measureTooltip.setPosition(geom.getInteriorPoint().getCoordinates());
        measureTooltipElement.innerHTML = (getSphereArea(geom)/10000).toFixed(2) + ' ha';
      })

      actionListeners.push(interactions[3].on('drawend', (evt) => {
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
        measureTooltip.setOffset([0, -7]);
        // unset sketch
        sketch = null;
        // unset tooltip so that a new one can be created
        measureTooltipElement = null;
        createMeasureTooltip(mapState.map);
        unByKey(listener);
      }));
    }))
  }


  return (
    <div ref={mapElement} className={"map"}>
      <AddParcel feature={modals.addFeature} open={modals.addModal} onSuccess={drawSuccess} onClose={() => {
        mapState.drawLayer.getSource().clear();
        setModals({addFeature: null, addModal: false});
      }} />

      <Stack direction="column" justifyContent={'start'} alignItems={"center"} style={{position: 'absolute', top: '143px', left: '7px', zIndex: 1 }}>
        <Tooltip title={'Zoom in'}><Fab style={{marginBottom: '2px'}} color="primary" size="small" onClick={() => mapState.map.getView().animate({zoom: mapState.map.getView().getZoom() + 0.5, duration: 300})}><Add /></Fab></Tooltip>
        <Tooltip title={'Zoom out'}><Fab style={{marginTop: '2px'}} color="primary" size="small" onClick={() => mapState.map.getView().animate({zoom: mapState.map.getView().getZoom() - 0.5, duration: 300})}><Remove /></Fab></Tooltip>
      </Stack>

      {/* {!props.panel && <Fab style={{position: 'absolute', top: '10px', left: '10px'}} color="primary" size="small" onClick={() => props.setPanel(true)}><Settings /></Fab>} */}

      {modals.photoPreview && <PhotosPreview photos={modals.photoPreview} onClose={() => setModals({photoPreview: null})} />}

      <SamplesPreview open={Boolean(modals.samplesPreview)} samples={modals.samplesPreview} onClose={() => setModals({samplesPreview: null})} />

      <Message type="success" open={modals.addSuccess ? true : false} message={modals.addSuccess} onClose={() => setModals({addSuccess: null})} />

      <Message type="error" open={Boolean(geolocationObj.error)} message={geolocationObj.error} onClose={() => onUpdateState({geolocation: false})} />
      
      <SearchLocation pointA={props.pointA} pointB={props.pointB} setPanel={props.setPanel} panel={props.panel} />
    </div>
  )
}

export default Map;