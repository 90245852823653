import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { Close, Delete, ReadMoreRounded, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import Message from "../../ui/Message";
import { AppContext } from "../../../AppContext";
import useMergeState from "../../hooks/useMergeState";
import EXIF from "exif-js";
import $data from "../../../services/$data";
import moment from "moment";

function UploadPhotos(props) {
  const { lang, parcels } = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [model, setModel] = useMergeState({});

  const [errorImport, setErrorImport] = useState(null);

  const [state, setState] = useMergeState({
    loading: false,
    error: null,
  });

  const [hasNonPhotos, setHasNonPhotos] = useState(false);

  const onChange = (id, name, value) => {
    console.log(id, name, value);
    setModel({
      ...model,
      [id]: {
        ...model[id],
        [name]: value
      }
    })
  }

  const calculateDecimal = (deg, min, sec) => {
    return deg + min / 60 + sec / 3600
  }

  const extractExifInformation = () => {

    if (!props.images.map(img => img.type).every(val => val == 'image/jpeg' || val == 'image/png')) {
      setHasNonPhotos(true);
    }

    props.images.filter(img => img.type == 'image/jpeg' || img.type == 'image/png').map((img, key) => {
      console.log(img)
      let reader = new FileReader();
      reader.onload = () => {
        let meta = EXIF.readFromBinaryFile(reader.result);
        console.log(meta);
        
       try {
        let date = meta.DateTime?.split(' ')[0].split(':').filter((v, i) => i < 3).join('-') || moment(new Date(img.lastModified).toISOString()).format('YYYY-MM-DD');
        console.log(date)
        setModel({
            [key]: {
              latitude: calculateDecimal(meta.GPSLatitude[0].valueOf(), meta.GPSLatitude[1].valueOf(), meta.GPSLatitude[2].valueOf()),
              longitude: calculateDecimal(meta.GPSLongitude[0].valueOf(), meta.GPSLongitude[1].valueOf(), meta.GPSLongitude[2].valueOf()),
              name: img.name,
              epsg: 4326,
              image: img,
              parcel: null,
              date_time: date,
              tag: null

            }
          })
       } catch(e) {
        setModel({
          [key]: {
            latitude: undefined,
            longitude: undefined,
            name: img.name,
            epsg: 4326,
            image: img,
            parcel: null,
            date_time: moment(new Date(img.lastModified).toISOString()).format('YYYY-MM-DD'),
            tag: null

          }
        })
       }
        

      }

      reader.readAsArrayBuffer(img);

    })
  }

  const uploadPhotos = () => {

  
    let fd = new FormData();

    let formated = Object.values(model).map(obj => {
      let newObj = { ...obj };
      delete newObj.image;

      fd.append('image', obj.image)

      return newObj;
    });

    if(!formated.every((obj) => obj.parcel || (obj.latitude && obj.longitude))) {
      return
    };
    
    setState({ loading: true });

    fd.append('data', JSON.stringify(formated));

    $data.uploadPhotos(fd)
      .then((data) => {
        setState({ loading: false })
        
        if(data.import_failed.length > 0) {
          setErrorImport(true);
          
        } else {

          props.onSuccess()
        }
  
;
      })
      .catch(err => {
        console.log(err)
        setState({ error: err.error, loading: false })
      })
  }



  useEffect(() => {
    if (props.open) {
      setErrorImport(null);
      setHasNonPhotos(false);
      extractExifInformation()
    } else {
      setModel(null)
    }
  }, [props.open])

  const onCloseMessage = (evt, reason) => {
    if (reason === 'clickaway') return;
    setState({ success: null, error: null })
  }


  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.upload_photos}<Close onClick={props.onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent>
        {/* <Container style={{marginTop: '30px'}}> */}
        <Grid style={{ marginTop: '30px' }} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary">{lang.upload_photos_note}</Typography>
          </Grid>
          <Grid item xs={12}>
            <table className="upload-images-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>

                {hasNonPhotos && <tr>
                  <td colSpan={3}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="error">Only files of type .PNG or .JPEG are allowed.</Typography>
                  </td>
                </tr>}

                {Object.keys(model).length > 10 &&
                  <tr>
                    <td colSpan={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="error">Maximum number of images per upload is 10. Please remove {Object.keys(model).length - 10} from the list.</Typography>
                    </td>
                  </tr>
                }

                {Object.keys(model).map((id, key) => {
                  let image = model[id];

                  return (
                    <tr key={key}>
                      <Tooltip title={image.name} placement="top" arrow>
                        <td style={{ padding: '7px 3px', textOverflow: 'ellipsis', fontWeight: 'bold', maxWidth: '150px', overflow: 'hidden' }}>{image.name}</td>
                      </Tooltip>
                      <td style={{ padding: '7px 3px' }}>
                        <FormControl size="small" fullWidth>
                          <InputLabel id="language">{lang.parcel_name_full}</InputLabel>
                          <Select size="small" labelId="language" label={lang.parcel_name_full} value={model[id].parcel ?? 'null'} onChange={(evt) => onChange(id, 'parcel', evt.target.value == 'null' ? null : evt.target.value)} fullWidth>
                            <MenuItem key={-1} value={'null'}>{lang.none}</MenuItem>
                            {parcels.features.map((f, key) => {
                              return <MenuItem key={key} value={f.properties.pk}>{f.properties.name || ' - '}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ padding: '7px 3px' }}>
                        <Autocomplete 
                        multiple
                        freeSolo
                        options={[]}
                        onChange={(evt, value, reason) => {
                          console.log(value)
                          onChange(id, 'tag', value.length === 0 ? null : value.join(';'))
                        }}
                        value={model[id].tag ? model[id].tag.split(';') : []}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="filled" color="warning" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => {
                          return <TextField {...params} size="small" variant="outlined" />
                        }} />
                      </td>

                      <td style={{ padding: '7px 3px' }}>
                        <TextField fullWidth size="small" label={lang.soil_latitude} type="number" value={image.latitude} onChange={(evt) => onChange(id, 'latitude', evt.target.value)} />
                      </td>
                      <td style={{ padding: '7px 3px' }}>
                        <TextField fullWidth size="small" label={lang.soil_longitude} type="number" value={image.longitude} onChange={(evt) => onChange(id, 'longitude', evt.target.value)} />
                      </td>
                      <td>
                        <Delete sx={{ cursor: 'pointer' }} color="error" onClick={() => {
                          delete model[id];

                          setModel(model);
                        }} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

          </Grid>
          {errorImport && <Grid item xs={12}>
            <Typography color="error"><strong>Import not successful: </strong>Please check if longitude/latitude were set correctly for each of the photos</Typography>
          </Grid>}
        </Grid>
        {/* </Container> */}
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={Object.keys(model).length === 0 || Object.keys(model).length > 10} color={props.confirmColor || "primary"} variant="contained" size="small" loading={state.loading} onClick={uploadPhotos}><Upload fontSize="small" /> {lang.confirm}</LoadingButton>
        <LoadingButton color={props.closeColor || "error"} variant="contained" size="small" loading={state.loading} onClick={props.onClose}>{props.closeText || lang.close}</LoadingButton>
      </DialogActions>

      <Message type="error" open={state.error ? true : false} message={state.error} onClose={onCloseMessage} />

    </Dialog>
  )
}

export default UploadPhotos;