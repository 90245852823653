import { Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import colors from "../../config/colors";
import { Add, Close, Delete, Download, Edit } from "@mui/icons-material";
import ReactDOMServer from 'react-dom/server';
import { LoadingButton } from "@mui/lab";
import FieldItem from "./FieldItem";
import Divider from "../auth/Divider";
import AddRegenerativePractice from "./AddRegenerativePractice";
import EditRegenerativePractice from "./EditRegenerativePractice";
import DeleteRegenerativePractice from "./DeleteRegenerativePractice";
import Message from "../ui/Message";

function RegenerativePractices(props) {
  
  const { locale, lang, cropTypes, primary, secondary } = useContext(AppContext);
  const containerElement = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [practices, setPractices] = useState([]);
  const [loading, setLoading] = useState(true);

  const [addPractice, setAddPractice] = useState(false);
  const [editPractice, setEditPractice] = useState(null);
  const [deletePractice, setDeletePractice] = useState(false);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {

    getPractices();

    return () => {

    }
  }, []);

  const getPractices = () => {
    setLoading(true);
    $data.getRegPractices(props.parcel, locale)
      .then(data => {
        setPractices(data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      })
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Grid sx={{ textAlign: 'center' }} item xs={12}>
          <CircularProgress />
        </Grid>
      )
    }

    if (practices.length === 0) {
      return (
        <Grid sx={{ textAlign: 'center' }} item xs={12}>
          <Typography variant="body1" color="primary" sx={{ textAlign: 'center' }}>
            {lang.act_no_practices}
          </Typography>
        </Grid>
      )
    }

    return (
      <Grid item xs={12}>
        <List>
          {practices.map((p, key) => {
            return (
              <ListItem key={key} secondaryAction={<><IconButton  onClick={() => setEditPractice(p)} size="small" children={<Edit sx={{cursor: 'pointer'}} color="warning" />}/> <IconButton onClick={() => setDeletePractice(p.id)} size="small" children={<Delete sx={{cursor: 'pointer'}} color="error" />} /> </>} sx={{mb: '10px'}} component={Paper} elevation={3}>
                <ListItemText  secondary={p.note} primary={<>{p.year} {p.reg_practice.map((pr, j) => <Chip key={j} sx={{ m: '0 5px 0 5px' }} size="small" color="warning" label={pr} />)}</>}></ListItemText>
              </ListItem>
            )
          })}
        </List>
      </Grid>

    )
  }


  return (
    <Dialog fullWidth maxWidth="lg" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} color="primary">{lang.act_reg_practice}<Close onClick={props.onClose} sx={{ '&:hover': { transform: 'scale(1.1)' } }} style={{ cursor: 'pointer' }} /></DialogTitle>
      <DialogContent ref={containerElement} >
        <Grid sx={{ mt: '20px' }} container spacing={2}>
          <Grid item xs={12}>
            <Button onClick={() => setAddPractice(true)} size="small" variant="contained" endIcon={<Add />}>
              {lang.act_add_practice}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          {renderContent()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton endIcon={<Close />} color="error" variant="contained" size="small" onClick={props.onClose}>{lang.close}</LoadingButton>

      </DialogActions>

      {addPractice && <AddRegenerativePractice parcel={props.parcel} open={addPractice} onClose={() => setAddPractice(false)} onSuccess={() => {getPractices(); setSuccess(lang.act_add_practice_success)}} />}
      {editPractice && <EditRegenerativePractice parcel={props.parcel} open={Boolean(editPractice)} model={editPractice} onClose={() => setEditPractice(false)} onSuccess={() => {getPractices(); setSuccess(lang.act_edit_practice_success)}} />}
      {deletePractice && <DeleteRegenerativePractice parcel={props.parcel} open={Boolean(deletePractice)} selected={deletePractice} onClose={() => setDeletePractice(false)} onSuccess={() => {getPractices(); setSuccess(lang.act_delete_practices_success)}}  />}

      {success && <Message type="success" open={Boolean(success)} message={success} onClose={() => setSuccess(null)} />}
      <Message type="error" open={Boolean(error.non_field_errors)} message={error.non_field_errors} onClose={() => setError({error: {}})} />

    </Dialog>
  )
}

export default RegenerativePractices;