import { Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../AppContext";
import $data from "../../services/$data";
import colors from "../../config/colors";
import { Close, Download } from "@mui/icons-material";
import ReactDOMServer from 'react-dom/server';
import { LoadingButton } from "@mui/lab";
import FieldItem from "./FieldItem";

function ActivitySummary(props) {
	const {lang, cropTypes, primary, secondary} = useContext(AppContext);
	const containerElement = useRef(null);
	const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const downloadReport = () => {

	}

	const renderParcel = () => {
		const {parcel} = props;
		return (
			<FieldItem sx={{display: 'flex'}} mediaSx={{ maxWidth: 151, overflow: 'hidden', textOverflow: 'ellipsis' }} parcel={parcel.pk} geometry={props.feature} image={colors.groupImages[$data.getCropGroupIDByCrop(parcel.activeCrop.crop, cropTypes)]} groupID={$data.getCropGroupIDByCrop(parcel.activeCrop.crop, cropTypes)} name={parcel.name} crop={$data.getCropById(cropTypes, parcel.activeCrop.crop)} area={parcel.area} />
		)
	}
	
	const renderTables = () => {
		if(props.loading) {
			return <Grid item xs={12} style={{textAlign: 'center'}}><CircularProgress /></Grid>
		}

		return props.data.filter(obj => obj.activity_type !== 100).map((obj, key) => {
			return (
				<Grid key={key} item xs={12}>
						<br/>
						<Typography variant="subtitle1" color={'primary'}>{obj.activity_type_name}</Typography>
						<Grid container spacing={2}>
							{obj.hasOwnProperty('quantity_ha_l__avg') && (
								<Grid item xs={12} sm={6} md={4}>
									<Paper style={{padding: '10px'}} elevation={3}>
										<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
											<span>{lang.quantity_ha + ' (l)'}<strong style={{marginLeft: '7px'}}>{obj.quantity_ha_l__avg || ' - '}</strong></span>
											<span>{lang.quantity_parcel + ' (l)'}<strong style={{marginLeft: '7px'}}>{obj.quantity_parcel_l__sum || ' - '}</strong></span>
										</Stack>
									</Paper>
								</Grid>
							)}
							{obj.hasOwnProperty('quantity_ha_kg__avg') && (
								<Grid item xs={12} sm={6} md={4}>
									<Paper style={{padding: '10px'}} elevation={3}>
										<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
											<span>{lang.quantity_ha + ' (kg)'}<strong style={{marginLeft: '7px'}}>{obj.quantity_ha_kg__avg || ' - '}</strong></span>
											<span>{lang.quantity_parcel + ' (kg)'}<strong style={{marginLeft: '7px'}}>{obj.quantity_parcel_kg__sum || ' - '}</strong></span>
										</Stack>
									</Paper>
								</Grid>
							)}
							{obj.hasOwnProperty('plants_ha__avg') && (
								<Grid item xs={12} sm={6} md={4}>
									<Paper style={{padding: '10px'}} elevation={3}>
										<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
											<span>{lang.plants_ha}<strong style={{marginLeft: '7px'}}>{obj.plants_ha__avg || ' - '}</strong></span>
											<span>{lang.plants_parcel}<strong style={{marginLeft: '7px'}}>{obj.plants_parcel__sum || ' - '}</strong></span>
										</Stack>
									</Paper>
								</Grid>
							)}
							{obj.hasOwnProperty('yield_ha__avg') && (
								<Grid item xs={12} sm={6} md={4}>
									<Paper style={{padding: '10px'}} elevation={3}>
										<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
											<span>{lang.yield_ha}<strong style={{marginLeft: '7px'}}>{obj.yield_ha__avg || ' - '}</strong></span>
											<span>{lang.yield_parcel}<strong style={{marginLeft: '7px'}}>{obj.yield_parcel__sum || ' - '}</strong></span>
										</Stack>
									</Paper>
								</Grid>
							)}

							{obj.hasOwnProperty('product_price_ha__avg') && (
								<Grid item xs={12} sm={6} md={4}>
									<Paper style={{padding: '10px'}} elevation={3}>
										<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
											<span>{lang.product_price_ha}<strong style={{marginLeft: '7px'}}>{obj.product_price_ha__avg || ' - '}</strong></span>
											<span>{lang.product_price_parcel}<strong style={{marginLeft: '7px'}}>{obj.product_price_parcel__sum || ' - '}</strong></span>
										</Stack>
									</Paper>
								</Grid>
							)}
							{obj.hasOwnProperty('price_ha__avg') && (
								<Grid item xs={12} sm={6} md={4}>
									<Paper style={{padding: '10px'}} elevation={3}>
										<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
											<span>{lang.price_ha}<strong style={{marginLeft: '7px'}}>{obj.price_ha__avg || ' - '}</strong></span>
											<span>{lang.price_parcel}<strong style={{marginLeft: '7px'}}>{obj.price_parcel__sum || ' - '}</strong></span>
										</Stack>
									</Paper>
								</Grid>
							)}			
						</Grid>
				</Grid>
			)
		})
	}

	const renderTotal = () => {
		if(props.loading) return null;

		return props.data.filter(obj => obj.activity_type == 100).map((obj, key) => {
			return (
				<Grid key={key} item xs={12}>
					<Typography variant="h6" color={'primary'} style={{marginBottom: '10px'}}>{lang.total}</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: 'rgba(0, 0, 0, .03)'}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span>{lang.yield_ha}<strong style={{marginLeft: '7px'}}>{obj.yield_ha__avg}</strong></span>
									<span>{lang.yield_parcel}<strong style={{marginLeft: '7px'}}>{obj.yield_parcel__sum}</strong></span>
								</Stack>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: 'rgba(0, 0, 0, .03)'}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span>{lang.quantity_ha} (l)<strong style={{marginLeft: '7px'}}>{obj.quantity_ha_l__avg}</strong></span>
									<span>{lang.quantity_parcel} (l)<strong style={{marginLeft: '7px'}}>{obj.quantity_parcel_l__sum}</strong></span>
								</Stack>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: 'rgba(0, 0, 0, .03)'}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span>{lang.quantity_ha} (kg)<strong style={{marginLeft: '7px'}}>{obj.quantity_ha_kg__avg}</strong></span>
									<span>{lang.quantity_parcel} (kg)<strong style={{marginLeft: '7px'}}>{obj.quantity_parcel_kg__sum}</strong></span>
								</Stack>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: 'rgba(0, 0, 0, .03)'}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span>{lang.plants_ha}<strong style={{marginLeft: '7px'}}>{obj.plants_ha__avg}</strong></span>
									<span>{lang.plants_parcel}<strong style={{marginLeft: '7px'}}>{obj.plants_parcel__sum}</strong></span>
								</Stack>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: 'rgba(0, 0, 0, .03)'}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span>{lang.product_price_ha}<strong style={{marginLeft: '7px'}}>{obj.product_price_ha__avg}</strong></span>
									<span>{lang.product_price_parcel}<strong style={{marginLeft: '7px'}}>{obj.product_price_parcel__sum}</strong></span>
								</Stack>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: 'rgba(0, 0, 0, .03)'}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span>{lang.price_ha}<strong style={{marginLeft: '7px'}}>{obj.price_ha__avg}</strong></span>
									<span>{lang.price_parcel}<strong style={{marginLeft: '7px'}}>{obj.price_parcel__sum}</strong></span>
								</Stack>
							</Paper>
						</Grid>
						{/* <Grid item xs={12} sm={6} md={4}>
							<Paper style={{padding: '10px', background: primary}} elevation={3}>
								<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
									<span style={{fontWeight: 'bold', color: '#fff'}}>{lang.total_price_ha}<strong style={{marginLeft: '7px', color: secondary}}>{(obj.price_ha__avg + obj.product_price_ha__avg).toFixed(2)}</strong></span>
									<span style={{fontWeight: 'bold', color: '#fff'}}>{lang.total_price_parcel}<strong style={{marginLeft: '7px', color: secondary}}>{(obj.price_parcel__sum + obj.product_price_parcel__sum).toFixed(2)}</strong></span>
								</Stack>
							</Paper>
						</Grid> */}
						
					</Grid>
				</Grid>
			)
		})
	}
	
	const renderContent = () => {
		return (
			<Container style={{marginTop: '10px'}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{renderParcel()}
					</Grid>

					<Grid item xs={12}>
						<br/>
						{renderTotal()}
					</Grid>

					{renderTables()}
				
					
				</Grid>
			</Container>
		)
	}
	let total = props.data.filter(obj => obj.activity_type == 100)[0];
	return (
		<Dialog fullWidth maxWidth="lg" open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.act_summary_report}<Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent ref={containerElement} >
				{renderContent()}
      </DialogContent>
			<DialogActions style={{flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px'}}>
				<Paper sx={{[theme.breakpoints.down('sm')]: {width: '100%', marginBottom: '10px'}}} style={{padding: '10px', background: primary}} elevation={3}>
					<Stack sx={{'& span': {mb: '3px', mt: '3px'}}}>										
						<span style={{fontWeight: 'bold', color: '#fff'}}>{lang.total_price_ha}<strong style={{marginLeft: '7px', color: secondary}}>{total ?(total?.price_ha__avg + total?.product_price_ha__avg).toFixed(2) : ' - '}</strong></span>
						<span style={{fontWeight: 'bold', color: '#fff'}}>{lang.total_price_parcel}<strong style={{marginLeft: '7px', color: secondary}}>{total ? (total?.price_parcel__sum + total?.product_price_parcel__sum).toFixed(2): ' - '}</strong></span>
					</Stack>
				</Paper>
				{/* <LoadingButton sx={{[theme.breakpoints.down('sm')]: {width: '100%'}}} onClick={downloadReport} variant="contained" size="small" endIcon={<Download />}>{lang.download_pdf}</LoadingButton>			 */}
			</DialogActions>
    </Dialog>	
	)
}

export default ActivitySummary;