import { CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "../../AppContext";
import Logo from "../../Logo";
import $auth from "../../services/$auth";
import $cookies from "../../services/$cookies";

function EmailVerification(props) {
  const {lang, primary} = useContext(AppContext);
  
  const [toLogin, setToLogin] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      $auth.verification(props.id)
        .then(data => onHandleVerification(true))
        .catch(err => onHandleVerification(false))
    }, 3000)
  }, []);

  const onHandleVerification = (bool) => {
    $cookies.set('verification', bool.toString());
    setToLogin(true)
  }

  if(toLogin) return <Redirect to="/login" />

  return (
    <Stack sx={{'& h3, & p': {color: primary}}} direction={'column'} justifyContent="center" alignItems={'center'}>
      <Logo style={{width: '80%', height: 'auto', maxWidth: '300px',padding: '10px'}} />
      <h3>{lang.verification_title}</h3>
      <p>{lang.verification_wait}</p>
      <CircularProgress size={50} style={{marginTop: '10px'}}/>
    </Stack>
  )
}

export default EmailVerification;