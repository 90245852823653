import { blue } from "@mui/material/colors";
import _ from "lodash";
import moment from "moment";
import $seasons from "../../services/$seasons";

// const yearColors = [ '#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a',]
const yearColors = ['rgb(2,63,165)','rgb(125,135,185)','rgb(190,193,212)','rgb(214,188,192)','rgb(187,119,132)','rgb(142,6,59)','rgb(74,111,227)','rgb(133,149,225)','rgb(181,187,227)','rgb(230,175,185)','rgb(224,123,145)','rgb(211,63,106)','rgb(17,198,56)','rgb(141,213,147)','rgb(198,222,199)','rgb(234,211,198)','rgb(240,185,141)','rgb(239,151,8)','rgb(15,207,192)','rgb(156,222,214)','rgb(213,234,231)','rgb(243,225,235)','rgb(246,196,225)','rgb(247,156,212)'];
export const formatData = (data, names, colors, filter) => {
  let groups = accumulatePrecipitation(data);
  let labels = [];

  try {
    labels = Object.keys(Object.values(groups)[0]).sort((a,b) => parseInt(a) - parseInt(b));
  } catch(e) {
    labels = [];
  }
  console.log(labels)
  return {
    labels: labels.map(m => moment(`2022-${m}-22`).format('MMMM')),
    datasets: Object.keys(groups).map((year, i) => {
      return {
        label: year,
        data: labels.map(month => _.sum(groups[year][month]).toFixed(0)),
        // data: Object.keys(groups[year]).sort((a,b) => parseInt(a) - parseInt(b)).map(month => ({
        //   x: month,
        //   y: _.sum(groups[year][month])
        // })).sort((a,b) => parseInt(a.x) - parseInt(b.x)),
        backgroundColor: yearColors[i],
        type: 'bar'
      }
    }) 
  } 
	
}

const accumulatePrecipitation = (data) => {
  let datasets = [...new Set(data.map(obj => moment(obj.date).format('YYYY')))];
  
  let groups = {};

  datasets.map(year => {
    groups[year] = {
      '01': [],
      '02': [],
      '03': [],
      '04': [],
      '05': [],
      '06': [],
      '07': [],
      '08': [],
      '09': [],
      '10': [],
      '11': [],
      '12': [],
    }
    
  });

  data.map(obj => {
    let m = moment(obj.date).format('MM');
    let year = moment(obj.date).format('YYYY');
   
    groups[year][m].push(obj.prcp);
  })

  return groups
}

export const formatOptions = (lang) => {
  return Object.assign({},{
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        position: 'left',
        labels: {
          usePointStyle: true
        }
      },
      tooltip: {
        itemSort: (a, b) => {

          return -1
        },
        callbacks: {
          title: (val, t) => {
            console.log(t)
            console.log(val[0].label)
            return val[0].label
          },
          label: (val) => {
            return `${val.dataset.label}: ${val.parsed.y} mm`
          }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        },
        pan: {
          enabled: true,
          threshold: 1,
          mode: 'x',
          modifierKey: 'shift'
        }
      },
    
    },
    interaction: {
      mode: 'index',
			axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        title: {text: `${lang.precipitation} [mm]`, display: true, color: blue[800]},
        position: 'right',
        beginAtZero: true,
        suggestedMax: 200,
       
        ticks: {
          color: blue[800],
        }
      },
      x: {
        // type: 'time',
        grid: {
          display: false
        },
        time: {
          unit: 'month',
          displayFormats: {
            day: 'DD-MMM-YYYY',
            month: 'MMMM'
          }
        },
        ticks: {
          autoSkipPadding: 10,
          maxRotation: 0,
        }
       
      }
    },
  })
}