import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Button, Checkbox, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import $data from "../../../services/$data";
import Divider from "../../auth/Divider";
import useMergeState from "../../hooks/useMergeState";
import { Event } from "@mui/icons-material";
import $activities from "../../../services/$activities";
import Message from "../../ui/Message";

function AgrotechnicalForm(props) {
  let { lang, locale, plantProtectionAgroTypes, operators, machines, connectedMachines } = useContext(AppContext);

  let editModel = props.model ? Object.assign({}, props.model, { operators: props.model.operators.map(obj => obj.pk) }) : {}
  const [data, setData] = useMergeState({
    model: Object.assign({}, {
      date: props.isJob ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      activity_type: 7,
      agrotechnical_type: plantProtectionAgroTypes[0].id,
      irrigation_norm: null,
      price_ha: 0,
      price_parcel: 0,
      fuel_consumption: 0,
      operators: [],
      machine: null,
      conn_machine: null,
      comment: '',
      parcel: props.parcel.pk

    }, { ...editModel }), error: {}, success: false, loading: false
  });

  const [multiline, setMultiline] = useState(false);

  const { model, error, success, loading } = data;

  useEffect(() => {
    if (props.isJob && !props.forArchive) {
      if (moment(model.date).diff(moment()) <= 0) {
        setData({ model: { ...model, date: moment().add(1, 'days').format('YYYY-MM-DD') } })
      }
    }
  }, [props.isJob])

  const onChange = (name, val) => {
    console.log(Object.assign(model, {...model, [name]: val}))

    let { value, additional } = $activities.getTillagePrices(name, val, props.parcel.area);
    
    let newModel = Object.assign(model, {model: {...model, [name]: value, ...additional }})

    setData(newModel);
    // console.log(model);

  }

  const getTotalPrice = () => {
    return {
      ha: (parseFloat(model.price_ha)).toFixed(2),
      parcel: (parseFloat(model.price_parcel)).toFixed(2),
    }
  }

  return (
    <Grid className={props.className || ''} component="form" onSubmit={(evt) => { evt.preventDefault(); props.onSubmit(model, true) }} container spacing={2}>
      {(!props.isJob || props.forArchive) && <Grid item xs={12} sm={4}>
        <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
          <MobileDatePicker okText={lang.ok} cancelText={lang.cancel} minDate={props.isJob && !props.forArchive ? moment().add(1, 'days') : undefined} onClose={() => { onChange('date', model.date) }} inputFormat="DD-MMM-YYYY" className="datepicker-root" label={lang.date} value={moment(model.date)} onChange={(newValue) => onChange('date', newValue.format('YYYY-MM-DD'))}
            renderInput={(params) => (
              <TextField fullWidth error={props.error.date} helperText={props.error.date || ''} {...params} size="small"
                InputProps={{ endAdornment: (<InputAdornment position="end"> <Event /> </InputAdornment>) }} />
            )}
          />
        </LocalizationProvider>

      </Grid>}
      {(!props.isJob || props.forArchive) && <Grid style={{ padding: 0 }} item xs={12} sm={4}></Grid>}
      {(!props.isJob || props.forArchive) && <Grid style={{ padding: 0 }} item xs={12} sm={4}></Grid>}

      <Grid item xs={12} sm={6}>
        <FormControl size="small" fullWidth>
          <InputLabel id="agro_type">{lang.agrotechnical_type}</InputLabel>
          <Select size="small" labelId="agro_type" label={lang.agrotechnical_type} value={model.agrotechnical_type} onChange={(evt) => { onChange('agrotechnical_type', evt.target.value); onChange('irrigation_norm', evt.target.value !== 2 ? '' : model.irrigation_norm) }} fullWidth>
            {plantProtectionAgroTypes.map((obj, key) => <MenuItem value={obj.id} key={key}>{obj.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField disabled={model.agrotechnical_type !== 2} type="number" label={lang.irrigation_norm} fullWidth onChange={(evt) => onChange('irrigation_norm', evt.target.value)} size="small" value={model.irrigation_norm || ''} />
      </Grid>
      <Grid item xs={12} sm={4} md={3} >
        <TextField error={props.error?.common_activity?.price_ha ? true : false} helperText={props.error?.common_activity?.price_ha || ''} label={lang.price_ha} type="number" fullWidth onChange={(evt) => onChange('price_ha', evt.target.value)} size="small" value={model.price_ha} />
      </Grid>
      <Grid item xs={12} sm={4} md={3} >
        <TextField error={props.error?.common_activity?.price_parcel ? true : false} helperText={props.error?.common_activity?.price_parcel || ''} label={lang.price_parcel} type="number" fullWidth onChange={(evt) => onChange('price_parcel', evt.target.value)} size="small" value={model.price_parcel} />
      </Grid>

      <Grid item xs={12} sm={6} md={3} >
        <TextField sx={{ background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root': { fontWeight: 'bold' }, '& input, & textarea': { cursor: 'not-allowed', fontWeight: 'bold' } }} focused label={lang.total_price_ha} fullWidth size="small" inputProps={{ readOnly: true }} value={getTotalPrice().ha || ' - '} />
      </Grid>
      <Grid item xs={12} sm={6} md={3} >
        <TextField sx={{ background: 'rgba(0,0,0, 0.04)', '& .MuiInputLabel-root': { fontWeight: 'bold' }, '& input, & textarea': { cursor: 'not-allowed', fontWeight: 'bold' } }} focused label={lang.total_price_parcel} fullWidth size="small" inputProps={{ readOnly: true }} value={getTotalPrice().parcel || ' - '} />
      </Grid>

      <Grid item xs={12} >
        <FormControl size="small" fullWidth>
          <InputLabel id="operators">{lang.operators}</InputLabel>
          <Select
            multiple size="small" labelId="operators"
            label={lang.operators} value={model.operators}
            onChange={(evt) => onChange('operators', evt.target.value)}
            renderValue={(selected) => selected.map(id => $data.getOperatorById(operators, id)).join(', ')} fullWidth>
            {operators.map((obj, key) => {

              return (
                <MenuItem key={key} value={obj.pk}>
                  <Checkbox checked={model.operators.indexOf(obj.pk) > -1} />
                  <ListItemText primary={`${obj.first_name} ${obj.last_name}`} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField error={props.error?.common_activity?.fuel_consumption ? true : false} helperText={props.error?.common_activity?.fuel_consumption || ''} label={lang.fuel_consumption} type="number" fullWidth onChange={(evt) => onChange('fuel_consumption', evt.target.value)} size="small" value={model.fuel_consumption} />
      </Grid>

      <Grid item xs={12} sm={6} >
        <FormControl size="small" error={props.error.machine ? true : false} fullWidth>
          <InputLabel id="machine">{lang.fld_machine}</InputLabel>
          <Select size="small" labelId="machine" label={lang.fld_machine} value={model.machine || ''} onChange={(evt) => onChange('machine', evt.target.value)} fullWidth>
            {machines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
          </Select>
          {props.error.machine && <FormHelperText>{props.error.machine}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} >
        <FormControl size="small" error={props.error.conn_machine ? true : false} fullWidth>
          <InputLabel id="con_machine">{lang.fld_con_machine}</InputLabel>
          <Select size="small" labelId="con_machine" label={lang.fld_con_machine} value={model.conn_machine || ''} onChange={(evt) => onChange('conn_machine', evt.target.value)} fullWidth>
            {connectedMachines.map((obj, key) => <MenuItem key={key} value={obj.pk}>{obj.name}</MenuItem>)}
          </Select>
          {props.error.conn_machine && <FormHelperText>{props.error.conn_machine}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField error={props.error?.common_activity?.comment ? true : false} helperText={props.error?.common_activity?.comment || ''} multiline label={lang.comment} fullWidth size="small" onChange={(evt) => onChange('comment', evt.target.value)} value={model.comment} />
      </Grid>

      <Grid sx={{ '& button': { ml: '5px' } }} style={{ textAlign: 'right' }} item xs={12}>
        {!props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.confirm}</LoadingButton>}
        {props.forArchive && <LoadingButton size="small" color="warning" variant="contained" type="button" onClick={(() => props.onSubmit(model, false))} loading={props.loading}>{lang.act_job_update}</LoadingButton>}
        {props.forArchive && <LoadingButton size="small" variant="contained" type="submit" loading={props.loading}>{lang.archive_job}</LoadingButton>}

        {!props.edit && <LoadingButton size="small" onClick={props.onClose} variant="contained" color="error" loading={props.loading} type="button">{lang.close}</LoadingButton>}
      </Grid>

      <Message type="error" open={props.error.non_field_errors || props.error.file ? true : false} message={props.error.non_field_errors || props.error.file} onClose={props.closeErrorMessage} />

    </Grid>
  )
}

export default AgrotechnicalForm;
