const en = {
  privacy_policy: 'Privacy Policy',
  privacy_consent: 'I have read and consent to CropLab',
  privacy_consent2: 'Personal Data Privacy Policy',
  privacy_terms: 'Terms and Conditions', 
  terms_policy: 'Terms and Conditions',
  and: 'and',
  google_registration_form_text: 'Please fill in mandatory fields below in order to use the application',
  uk: 'United Kingdom',
  greece: 'Greece',
  poland: 'Poland',
  serbia: 'Serbia',
  portugal: 'Portugal',
  country: 'Country',
  email: 'Email',
  password: 'Password',
  repeat: 'Repeat password',
  language: 'Language',
  serbian: 'Serbian',
  english: 'English',
  submit_reg: 'Register',
  submit_log: 'Login',
  submit_res: 'Reset',
  email_resend_success: 'Verification link has been sent again to provided email address',
  delete_account_confirmation_link: 'Your request for account removal has been submitted. Check your email address.',
  verification_success: 'Verification successful',
  verification_failed: 'Verification failed! Please contact our technical support for more information!',
  verification_sent: 'Verification link is sent to provided email address',
  verification_title: 'Verifying your account',
  verification_wait: 'Please wait...',
  session_expired: 'Session expired! Please sign in again!',
  login: 'Login',
  google_sign_in: 'Sign In with Google',
  google_sign_up: 'Sign Up with Google',
  register: 'Register',
  forgot: 'Reset password',
  forgot_text: 'Please provide an email address associated with your account',
  reset_sent: 'Reset password link is sent to provided email address',
  reset_success: 'Password change successful',
  reset_failed: 'Password change failed! Please contact our technical support for more information!',
  go_back: 'Go back',
  welcome: 'Welcome to CropLab, your personal digitized crop laboratory',
  no_account: "If you don't have an account, please",
  free_register: 'register here for free',
  did_you_forget: 'Did you forget your password?',
  about: 'About',
  pricing: 'Pricing',
  contact: 'Contact',
  map_view: 'Map',
  vrt_view: 'Variable rate application',
  fieldbook_view: 'Field book',
  fieldbook_item_view: 'Field book',
  alerts_view: 'Alerts - pathogen risks',
  alerts_filter_region: 'Filter by region',
  alerts_all_regions: 'All regions',
  alerts_error: 'Error occured while trying to fetch news/alerts.',
  no_alerts: 'There are no news/alerts.',
  no_alerts_filter: 'There are no news/alerts that matches your search query.',
  alerts_learn_more: 'View details',
  account_settings_view: 'Account settings',
  help_view: 'Help',
  payment_view: 'Billing status',
  'job-orders_view': 'Job orders',
  logout: 'Sign out',
  account_settings: 'Account settings',
  loading: 'Loading data...',
  account: 'Account',
  billing: 'Billing',
  personal_info: 'Personal information',
  first_name: 'Name',
  last_name: 'Last name',
  currency: 'Currency',
  currency_eur: 'Euro (€)',
  currency_uk: 'Pounds sterling (£)',
  currency_rs: 'Serbian dinar (RSD)',
  currency_pl: 'Polish zloty (zł)',
  currency_pl: 'United States Dollar ($)',
  change_password: 'Change password',
  old_password: 'Old password',
  new_password: 'New password',
  repeat_new_password: 'Repeat new password',
  confirm: 'Confirm',
  delete: 'Delete',
  close: 'Close',
  account_cancelation: 'Deactivate/delete account',
  deactivate_account: 'Deactivate account',
  activate_account: 'Activate account',
  account_deactivated: 'Your account was successfully deactivated.',
  activation_message: 'Your account has been deactivated. If you wish to activate your account, press "Activate account".',
  activation_note: 'NOTE: By activating your account, data processing will continue according to your billing plan.',
  deactivate_question: 'Are you sure that you want to deactivate your account?',
  delete_account: 'Delete account',
  account_deleted: 'Your account was successfully deleted.',
  delete_question: 'Are you sure that you want to delete your account?',
  deactivation_note: 'NOTE: By deactivating account, you will be unable to login to the application and access your farm, until you activate the account again. Processing of the data and billing plan expiration will be freezed until reactivation of the account. To activate your account, you just need to sign in with your credentials.',
  deletion_note: "WARNING: By deleting your account, you will lose all the collected and processed information attached to your account. After you confirm deletion, you will have 30 days grace period to restore your account. If you don't, deletion will be executed and this action cannot be undonde. To activate your account, you just need to sign in with your credentials and confirm account restoration.",
  restore_account: 'Restore account',
  restore_account_btn: 'Restore',
  restore_message: 'Your account has been deleted. If you wish to restore your account press "Restore".',
  no_employees: 'No employees assigned',
  manager: 'Manager',
  operator: 'Operator',
  operators: 'Operators',
  no_operators: 'No operators',
  fieldbook_role: 'Field book',
  job_orders_role: 'Job orders',
  notifications: 'Notifications',
  no_notifications: 'There are no notifications',
  notification_settings: 'Choose if and where do you want to receive notifications',
  in_app: 'In-app',
  sms: 'SMS',
  employees: 'Employees',
  add_employee: 'Add employee',
  edit_employee: 'Edit employee',
  delete_employee: 'Delete employee',
  employee_nav_message: "Changing employee's information or employee removal is possible only in the Account section",
  fullname: 'Full name',
  phone: 'Phone number',
  role_type: 'Type',
  role_perm: 'Role',
  success_update_profile: 'Profile updated successfully',
  success_create_employee: 'Employee added successfully',
  success_edit_employee: 'Employee updated successfully',
  success_delete_employee: 'Employe deleted successfully',
  parcel_name: 'Name',
  parcel_crop: 'Crop',
  parcel_area: 'Area',
  parcel_status: 'Status',
  parcel_active: 'Active',
  parcel_inactive: 'Inactive',
  parcel_sowing: 'Sowing',
  parcel_harvest: 'Harvest',
  vrt_title: 'Select fields from the sidebar, for which you want to create a map of variable rate application',
  vrt_source: 'Choose calculation output based on multi-year (productivity) data or daily (NDVI) data:',
  vrt_yearly: 'Multi-year',
  vrt_daily: 'Daily',
  vrt_input_title: 'Insert the amount per zone',
  vrt_export_title: 'Choose file format for export:',
  export: 'Export',
  vrt_parcel_list_title: 'Choose field(s)',
  no_parcels: 'There are no created fields for selected season',
  filter_parcels: 'Filter parcels by name or crop...',
  date: 'Date',
  vrt_no_data: 'No data',
  fld_title: 'Select field to see its activities',
  fld_filters: 'Filters',
  fld_machines: 'Machines',
  fld_con_machines: 'Connected machines',
  fld_download_btn: 'Export field book',
  fld_filter_by_name: 'Filter by name',
  fld_filter_by_crop: 'Filter by crop',
  fld_filter_by_cropgroup: 'Filter by crop group',
  fld_filter_by_activity: 'Filter by activity',
  fld_filter_by_parcel: 'Filter by field',
  fld_filter_by_status: 'Filter by status',
  fld_select_all: 'All crops',
  fld_select_all_parcels: 'All fields',
  fld_select_all_types: 'All activities',
  fld_no_filtered_parcels: 'There are no fields that match selected filters',
  fld_machine: 'Machine',
  fld_con_machine: 'Connected machine',
  fld_machine_nav_message: "Changing machines or machine removal is possible only in the Account section",
  fld_con_machine_nav_message: "Changing connected machines or connected machine removal is possible only in the Account section",
  fld_add_machine: 'Add machine',
  fld_edit_machine: 'Edit machine',
  fld_delete_machine: 'Delete machine',
  fld_add_con_machine: 'Add connected machine',
  fld_edit_con_machine: 'Edit connected machine',
  fld_delete_con_machine: 'Delete connected machine',
  no_machines: 'There are no created machines',
  no_conn_machines: 'There are no created connected machines',
  num: 'No.',
  success_create_machine: 'Machine created successfully',
  success_edit_machine: 'Machine changed successfully',
  success_delete_machine: 'Machine deleted successfully',
  success_create_con_machine: 'Connected machine created successfully',
  success_edit_con_machine: 'Connected machine changed successfully',
  success_delete_con_machine: 'Connected machine deleted successfully',
  act_add_activity: 'Add activity',
  act_reg_practice: 'Regenerative practices',
  act_add_practice: 'Add practices',
  act_add_practice_success: 'Successfully added',
  act_delete_practices: 'Delete practices',
  act_delete_practices_success: 'Successfullz deleted',
  act_practice_delete_question: 'Are you sure that you want to delete selected practices?',
  act_edit_practices: 'Edit practices',
  act_edit_practices_success: 'Successfully updated',
  act_no_practices: 'No regenerative practices added',
  act_add_job_order: 'Create job order',
  act_summary_report: 'Summary report',
  act_activities: 'Activities',
  act_documents: 'Documents',
  act_edit_delete: 'Changing/Deleting existing documents is possible only in the Documents section',
  act_document_tag_helper: 'Press ENTER to confirm entered tag',
  act_no_activities: 'There are no recorded activities',
  act_no_jobs: 'There are no created job orders',
  act_no_jobs_filter: 'There are no created job orders for selected search parameters',
  act_job_active: 'In progress',
  act_job_completed: 'Completed',
  act_job_mixed: 'Multiple job orders',
  act_see_jobs: 'See job order details',
  act_jobs_all: 'All statuses',
  click_job: 'Click here to see details',
  parcel_name_full: 'Field name',
  activity_type: 'Activity type',
  update_activity_success: 'Activity updated successfully',
  delete_activity_success: 'Activity removed successfully',
  delete_activity: 'Delete activity',
  delete_activity_question: 'Are you sure that you want to delete the selected activity?',
  act_select_type: 'Please, select the type of activity',
  act_job_order_switch: 'Job order will be created instead of activity and will be assigned to the operator(s). Please make sure that the appropriate date is selected.',
  act_job_finished: 'Mark as completed',
  act_job_update: 'Update job',
  //ACTIVITY FIELDS
  tillage_type: 'Tillage type',
  fertigation_type: 'Fertilization type',
  fertigation_product: 'Fertilization product',
  fertigation_product_placeholder: 'Search/Enter fertilization product',
  comment: 'Comment',
  crop_group: 'Crop group',
  crop_type: 'Crop',
  seed_type: 'Type/Hybrid',
  seed_type_placeholder: 'Search/Enter seed type/hybrid',
  moisture: 'Moisture (%)',
  impurities: 'Impurities (%)',
  hectolitre_mass: 'Hectolitre mass (%)',
  digestion: 'Digestion (%)',
  oil_content: 'Oil content (%)',
  protein_content: 'Protein content (%)',
  plants_m2: 'Plants/m2',
  plants_ha: 'Plants/ha',
  plants_parcel: 'Plants/field',
  yield_ha: 'Yield/ha (kg)',
  yield_parcel: 'Yield/field (kg)',
  quantity_ha: 'Quantity/ha',
  quantity_parcel: 'Quantity/field',
  quantity_unit: 'Quantity Unit',
  depth: 'Depth (cm)',
  row_spacing: 'Row spacing (cm)',
  product_unit_price: 'Product unit price',
  product_price_ha: 'Product price/ha',
  product_price_parcel: 'Product price/field',
  price_ha: 'Operation price/ha',
  price_parcel: 'Operation price/field',
  total: 'Total',
  total_price_ha: 'Total cost/ha',
  total_price_parcel: 'Total cost/parcel',
  protection_type: 'Protection type',
  product: 'Product',
  active_substance: 'Active substance',
  agrotechnical_type: 'Agrotechnical operation',
  irrigation_norm: 'Irrigation norm (mm)',
  plant_protection_no_data: 'No records',
  add_protection_row: 'Add record',
  show_form: 'Show form',
  edit: 'Edit',
  cancel: 'Cancel',
  from: 'From',
  to: 'To',
  send_sms: 'Send SMS',
  download_pdf: 'Download PDF',
  download_xls: 'Download XLS',
  reset_filter: 'Reset filters',
  fill_form: 'Fill form',
  archive_job: 'Mark as completed',
  job_archive_text: 'If you want to mark the job as completed and store it in the activities record, you need to switch to edit mode (click button "Edit") and click on the button for marking the job order as complete (below the form). Optionally you can make changes to the information collected on the job, before marking it as completed.',
  see_on_map: 'See on map',
  see_on_vra: 'See on VRA',
  see_activities: 'See activities',
  layers: 'Layers',
  my_fields: 'My fields',
  total_area: 'Total area',
  basemap: 'Base',
  source: 'Source',
  satellite: 'Satellite',
  index: 'Index',
  sensors: 'Sensors',
  sensors_view: 'Sensors',
  opacity: 'Opacity',
  ndvi_full: 'Vegetation (NDVI)',
  cumndvi: 'Cummulative NDVI',
  chl_full: 'Chlorophyll Content (CHL)',
  ndvi: 'Vegetation (NDVI)',
  chl: 'Chlorophyll Content (CHL)',
  productivity: 'Productivity',
  mzones: 'Management zones',
  map_filter: 'Filter by field name or crop',
  season: 'Season',
  forecast_unavailable: 'Forecast service is currently unavailable. Please try again later.',
  sowing_date: 'Sowing date',
  summary: 'Summary',
  query: 'Query',
  sensor: 'Sensor',
  crop_stage: 'Crop stage',
  meteo: 'Meteo',
  forecast: 'Forecast',
  no_information: 'Information not available',
  summary_details: 'See details',
  summary_click_info: 'Select field(s) on the map to see the summary information',
  summary_no_data_parcel: 'There is no summary information for selected field(s)',
  summary_yield: 'Yield estimate (t/ha)',
  detailed_summary_view: 'Detailed summary view',
  query_click_info: 'Select location(s) on the map to see the index information',
  query_no_data_parcel: 'There is no index information for selected location',
  query_all_data: 'All data',
  photos_click_info: 'Select field(s) on the map for which you wish to preview the collected photos',
  no_photos: 'No photos collected on selected field',
  sensor_click_info: 'Select sensor on the map to see recorded observations',
  sensor_details_link: 'See more details on Sensor OBservation Service platform',
  sensor_no_data: 'There are no measurements for selected query parameters',
  forecast_click_info: 'Select location(s) on the map to see the weather forecast',
  meteo_click_info: 'Select field(s) on the map to see the historical meteo data',
  meteo_no_data: 'There is no historical meteo data for selected field(s) and/or selected timeframe',
  gdd_click_info: 'Select field(s) on the map to see the information about the crop growth stage',
  gdd_no_crops_1: 'In order to monitor crop stage, you need define at least one sowing/harvest season. You can do that in a ',
  gdd_summary_section: 'FIELD SUMMARY SECTION',
  gdd_no_crops_3: ' ("Add record") or or you can navigate to ',
  gdd_field_book: 'FIELD BOOK',
  gdd_no_crops_5: ' and add planting and harvesting activities in more detail.',
  gdd_no_data: 'There is no crop growth stage information for selected field(s)',
  gdd: 'Growing degree days',
  reset_chart_view: 'Reset chart view',
  high_productivity: 'High productivity',
  normal_productivity: 'Average productivity',
  low_productivity: 'Low productivity',
  temperature: 'Temperature',
  precipitation: 'Precipitation',
  humidity: 'Humidity',
  cloudiness: 'Cloudiness',
  wind_speed: 'Wind speed',
  pressure: 'Pressure',
  today: 'Today',
  no_fields: 'No fields created',
  action_compare: 'Compare two locations',
  action_draw: 'Draw field',
  action_upload: 'Upload fields',
  action_edit: 'Edit field',
  action_crop_history: 'Manage crop history',
  action_delete: 'Delete field',
  action_measure_area: 'Measure area',
  action_measure_length: 'Measure length',
  action_upload_success: 'Successfully uploaded parcels',
  select_files: 'Select file(s)',
  select_xls: 'Select Excel spreadsheet',
  select_documents: 'Select document(s)',
  no_files_selected: 'There are no files selected',
  add_field: 'Add new field',
  delete_field_question: 'Are you sure that you want to delete the selected field?',
  delete_field_title: 'Delete field',
  success_add_field: 'Field successfully created',
  success_edit_field: 'Field information successfully changed',
  success_delete_field: 'Field successfully removed',
  ok: 'OK',
  tmax: 'Temperature (MAX)',
  tmin: 'Temperature (MIN)',
  meteo_mode: 'Daily',
  accumulated_mode: 'Accumulated precipitation',
  Cloudy: 'Cloudy',
  main_error: 'Application is currently being maintained. Please, come back later!',
  redirect_ac: 'CropLab services are available to farmers only. If you are a project developer/auditor please use CO2Agri platform',
  next: 'Next',
  back: 'Back',
  skip: 'Skip (without harvesting)',
  finish: 'Finish',
  add_another_season: 'Add another crop season',
  insert_harvest: 'Insert harvest date',
  vrt_choose_source: 'Choose imagery source',
  crop_history: 'Crop history',
  edit_crop_season: 'Edit crop season',
  remove_crop_season: 'Remove crop season',
  remove_crop_season_warning: 'WARNING: These activities will be removed from the field book',
  remove_crop_season_question: 'Are you sure that you want to remove selected crop season',
  crop_history_short: 'Crops',
  indices: 'Indices',
  indices_summary: 'Indices summary',
  add_record: 'Add record',
  full_list: 'See full list',
  no_crop_records: 'No crop records entered',
  add_crop_season: 'Add crop season',
  crop_added_success: 'Crop season successfully added',
  filter_job_orders_only: 'Job orders',
  filter_job_completed: 'Completed',
  filter_job_in_progress: 'In progress',
  error_server: 'Error occured on the server. Please contact our technical support.',
  error_network: 'Service is unavailable. Please check your internet connection. If connection is not the problem, please contact our technical support.',
  error_network_status: 'Network Error',
  error_404: 'URL not found',
  btn_verify: 'Verify',
  btn_edit: 'Edit',
  phone_not_verified: 'Phone number not verified yet',
  sms_verification: 'Phone number registration and verification',
  sms_verification_description: 'Please register a valid phone number and verify via SMS code',
  sms_code_description: 'Enter 6-digit code that you received via SMS',
  request_sms: 'Submit',
  verify_sms: 'Verify SMS code',
  request_sms_sent: 'SMS code was sent to provided phone number',
  phone_verification_success: 'Your phone number was successfully verified',
  document_name: 'Name',
  document_description: 'Description',
  document_tags: 'Tags',
  doc_filter_by_name: 'Filter by name',
  doc_filter_by_tags: 'Filter by tags',
  no_documents: 'No documents attached',
  no_documents_filter: 'No documents matching your search query',
  delete_document: 'Delete document',
  download_document: 'Download document',
  edit_document: 'Edit document',
  doc_select_all_tags: 'All tags',
  doc_error_delete: 'Error occured while trying to delete the document',
  doc_success_delete: 'Document deleted successfully',
  doc_error_update: 'Error occured while trying to update the document',
  doc_success_update: 'Document updated successfully',
  doc_delete_question: 'Are you sure that you want to delete the selected document?',
  billing_request_continue: 'Order preview',
  billing_price_parcel: 'Price',
  billing_price_total: 'Total price',
  billing_request_submit: 'Order',
  billing_order: 'Order preview',
  billing_lt_100: 'Free plan allows you to have one or more fields, whose total area is less than 100ha. For each new field or group of fields, whose total area exceeds the area defined in Free plan, will be inactive and for those fields you must subscripe to a paid plan, which will be automatically determined based on area usage as dsiplayed below.',
  billing_gt_100: 'The total area of your fields exceeds total area allowed within the free plan (100ha). To activate new field(s), you need to contact us and subscribe to paid plan. ',
  dont_show_again: "Don't show this popup again on startup",
  billing_free: 'Free',
  billing_basic: 'Premium',
  billing_pro: 'Pro',
  billing_enterprise: 'Enterprise',
  billing_request_ask: 'Subscribe',
  billing_used_free: 'Free area usage',
  billing_used_paid: 'Exceeded area usage',
  billing_ac_description: 'Your farm is a part of the CO2Agri project',
  ac_request_message: 'You have been requested to allow project developer(s) from CO2Agri project to use your fields for the purpose of carbon credits project development. You can accept or reject the request(s). If you accept, each field that project developer chooses will be processed for free, regardles of the total area usage and you will be able to see the results.',
  ac_request_title: 'CO2Agri - requests for carbon credits project development',
  ac_request_list_error: 'Something went wrong during the CO2Agri requests retrieval',
  ac_request_status: 'Request status',
  ac_request_accepted: 'ACCEPTED',
  contact_us: 'Contact us',
  contact_name: 'Name',
  contact_subject: 'Subject',
  contact_message: 'Message',
  contact_success: 'Message has been sent. We will contact you as soon as possible.',
  sensors_contact_message: 'If you wish to import your sensors and track measurements through CropLab, please contact us, so that we can help you with the integration process!',
  no_sensors: 'There are no integrated sensors on the platform',
  edit_sensor: 'Edit sensor',
  sensor_name: 'Sensor name',
  sensor_type: 'Sensor type',
  sensor_description: 'Description',
  sensor_edit_success: 'Sensor information updated!',
  sensor_measurements: 'Sensor measurements',
  photos: 'Photos',
  soil_measurements: 'Soil measurements',
  soil_field_mapping: 'Mandatory column names mapping',
  soil_measurements_upload: 'Upload soil measurements',
  soil_latitude: 'Latitude',
  soil_longitude: 'Longitude',
  soil_datestamp: 'Date',
  soil_name: 'Name',
  soil_parcel__name: 'Field name',
  soil_field_mapping_description: 'Please choose column names from your file (right) that will match the corresponding column names on your left (you must connect either Logintude and Latitude or a field)',
  no_samples: 'There are no soil measurements uploaded',
  soil_type_error: 'The file you tried to import is not an Excel spreadsheet (.xls, .xlsx)',
  soil_upload_success: 'Soil measurements uploaded successfully',
  download_example: 'Download example (per lat/lon)',
  download_example_parcels: 'Download example (per field)',
  soil_samples: 'Soil samples',
  my_location: 'My Location',
  search_placeholder: 'Search location...',
  go_to: 'Go to',
  geolocation_error: 'Unable to get your location! Please, try later!',
  delete_sample_title: 'Delete soil measurements',
  delete_sample_question: 'Are you sure that you want to delete selected soil measurements?',
  select_all: 'Select all',
  samples_delete_success: 'Selected samples removed successfully',
  samples_delete_error: 'Something went wrong while trying to remove selected samples',
  upload_photos: 'Upload photos',
  edit_photo: 'Edit photo metadata',
  upload_photos_note: 'NOTE: Photos must have either geo-tag/location or field',
  images_upload_success: 'Successfully uploaded images',
  delete_photo: 'Delete photo',
  delete_photo_question: 'Are your sure that you want to delete the selected photo?',
  irrigation: 'Irrigation info',
  or: 'OR',
  ie: 'i.e.',
  total_amount: 'Total amount',
  area_error: 'Field area must be bigger than 0 ha',
  help: 'Help',
  none: 'None',
  gddBase: 'Base temperature',
  input_per_package: 'Input per package',
  number_of_packages: 'Number of packages',
  number_of_packages_desc: 'i.e. number of bags of fertilizer',
  quantity_per_package: 'Quantity per package',
  quantity_per_package_desc: 'i.e. How much product is in one package?',
  price_per_package: 'Price per package',
  number_of_seeds: 'Number of seeds',
  calculate: 'Calculate',
  collection_name: 'Collection name',
  year: 'Year',
  fuel_consumption: 'Fuel consumption [litre]',
  syntethic: 'Synthetic',
  natural: 'Natural',
  natural_type: 'Natural/Synthetic',
  unknown: 'Unknown',
  num_of_days: 'Number of days',
  start_date: 'Start date',
  end_date: 'End date',
  add: 'Add',
  animal_species: 'Animal species',
  num_of_animals: 'Number of animals',
  add_animals: 'Add animals',
  date_format_note: 'Please use YYYY-MM-DD date format in your spreadsheet, otherwise it will not be recognized by the service.',
  download_all_data: 'Download data',
  download_data_parcel: 'Download data for field',
  download_error: 'Something went wrong while trying to download the data. Please check if input parameters are correctly defined.',
  verify_delete: 'We are verifying the request to delete your account',
}

export default en;