import { Agriculture, MenuBook, StackedBarChart } from "@mui/icons-material";
import { Fab, Stack, Tooltip } from "@mui/material";
import { useContext } from "react"
import { Link } from "react-router-dom";
import { AppContext } from "../../../AppContext"

function SummaryParcelDetails(props) {
  const {lang, onUpdateState} = useContext(AppContext);

  return (
    <Stack sx={{mb: '5px'}} direction="row" justifyContent={'space-between'} spacing={1}>
      <Tooltip title={lang.see_activities}>
      <Link style={{textDecoration: 'none'}} to={`/dashboard/field-book/${props.active}`}>
        <Fab className="summary-redirect-button" color="primary" variant="contained" size="small"><MenuBook fontSize="small" /></Fab>
      </Link>
      </Tooltip>
      <Tooltip title={lang.see_on_vra}>
      <Link style={{textDecoration: 'none'}} to={`/dashboard/vrt`}>
        <Fab className="summary-redirect-button" color="primary" onClick={() => onUpdateState({selectedParcel: props.active})} variant="contained" size="small"><Agriculture fontSize="small" /></Fab>
      </Link>
      </Tooltip>
      <Tooltip title={lang.summary_details}>
        <Fab onClick={() => props.setDetails(true)} color="primary" className="summary-redirect-button" variant="contained" size="small"><StackedBarChart fontSize="small" /></Fab>
      </Tooltip>
    </Stack>
  )
}

export default SummaryParcelDetails