import moment from "moment";
import $seasons from "../../services/$seasons";
import colors from "../colors";

export const formatData = (data, field, name, isSecondary, isPlanetUser) => {
  if(!data) return {datasets: []};

  let datasets = []
  if(data.length === 1) {
    datasets.push({
      data: data[0]['s2'].map(obj => ({x: obj.date, y: obj[field]})),
      label: name + (isPlanetUser ? ' - Sentinel-2' : ''),
      borderColor: isSecondary ? colors.queryColors[2] : colors.queryColors[0],
      borderWidth: 1,
      pointBackgroundColor: isSecondary ? colors.queryColors[2] : colors.queryColors[0],
      pointRadius: 1,
      fill: false,
      spanGaps: true
    });
  
    if(isPlanetUser) {
      datasets.push({
        data: data[0]['planet'].map(obj => ({x: obj.date, y: obj[field]})),
        label: name + ' - PLANET',
        borderColor: isSecondary ? colors.queryColors[3] : colors.queryColors[1],
        borderWidth: 1,
        pointBackgroundColor: isSecondary ? colors.queryColors[3] : colors.queryColors[1],
        pointRadius: 0,
        fill: false,
        spanGaps: true
      })
    }
  }

  if(data.length === 2) {
    datasets.push({
      data: data[0]['s2'].map(obj => ({x: obj.date, y: obj[field]})),
      label: name + (isPlanetUser ? ' - Sentinel-2 (A)' : ' (A)'),
      borderColor: colors.queryColors[0],
      borderWidth: 1,
      pointBackgroundColor: colors.queryColors[0],
      pointRadius: 1,
      fill: false,
      spanGaps: true
    });

   
  
    if(isPlanetUser) {
      datasets.push({
        data: data[0]['planet'].map(obj => ({x: obj.date, y: obj[field]})),
        label: name + ' - PLANET (A)',
        borderColor: colors.queryColors[1],
        borderWidth: 1,
        pointBackgroundColor: colors.queryColors[1],
        pointRadius: 0,
        fill: false,
        spanGaps: true
      })
    }

    datasets.push({
      data: data[1]['s2'].map(obj => ({x: obj.date, y: obj[field]})),
      label: name + (isPlanetUser ? ' - Sentinel-2 (B)' : ' (B)'),
      borderColor: colors.queryColors[2],
      borderWidth: 1,
      pointBackgroundColor: colors.queryColors[2],
      pointRadius: 1,
      fill: false,
      spanGaps: true
    });

    if(isPlanetUser) {
      datasets.push({
        data: data[1]['planet'].map(obj => ({x: obj.date, y: obj[field]})),
        label: name + ' - PLANET (B)',
        borderColor: colors.queryColors[3],
        borderWidth: 1,
        pointBackgroundColor: colors.queryColors[3],
        pointRadius: 0,
        fill: false,
        spanGaps: true
      })
    }

   
    }
  

  return {datasets}
}

export const formatOptions = () => {
  return Object.assign({},{
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      },
      tooltip: {
        callbacks: {
          title: (val) => {
            return moment(val[0].raw.x).format('DD-MMM-YYYY')
          }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        },
        pan: {
          enabled: true,
          threshold: 1,
          mode: 'x',
          modifierKey: 'shift'
        }
      },
    
    },
    interaction: {
      mode: 'nearest',
			axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD-MMM-YYYY'
          }
        },
        ticks: {
          autoSkipPadding: 10,
          maxRotation: 0,
        }
       
      }
    },
  })
}