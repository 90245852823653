import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Typography, useMediaQuery} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import {useTheme} from '@mui/system';
import { Add, Close, Delete, Edit, Event } from "@mui/icons-material";
import { LoadingButton, LocalizationProvider, MobileDatePicker } from "@mui/lab";
import useMergeState from "../../hooks/useMergeState";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import $data from "../../../services/$data";


function RemoveCropSeason(props) {
  const {lang, primary, cropGroups, cropTypes, locale, onUpdateParcels} = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [error, setError] = useMergeState({});

  const [loading, setLoading] = useState();

  

  const submit = () => {
    setLoading(true);

    $data.deleteCropSeason(props.data.id)
      .then(data => {
        onUpdateParcels();
        
        setLoading(false);
        props.onClose();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);

      })

  }

  return (
    <Dialog fullWidth maxWidth="lg" fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.remove_crop_season} <Close onClick={props.onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container sx={{mt: '20px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">{lang.remove_crop_season_question}?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{lang.remove_crop_season_warning}</Typography>
              
            </Grid>
          </Grid>
          
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={submit} variant="contained" size="small">{lang.delete}</LoadingButton>
        <LoadingButton loading={loading} onClick={props.onClose} size="small" variant="contained" color="error">{lang.cancel}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveCropSeason;