import {Alert as MuiAlert, Snackbar } from "@mui/material";
import React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Message(props) {
  return (
    <Snackbar sx={{position: 'fixed', zIndex: 99999999999999, top: 84}} anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={props.open} autoHideDuration={props.autoHide || (props.type !== 'error' ? 6000 : null)} onClose={props.onClose}>
      <Alert onClose={props.onClose} severity={props.type} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

export default Message;