import React from 'react';

export const AppContext = React.createContext();

const AppContextProvider = (props) => {
  return (
    <AppContext.Provider value={props.value}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider