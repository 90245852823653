import { Close, Delete, Download, Event, FileDownload, FilePresent, Refresh } from "@mui/icons-material";
import { Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, ListItemText, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import Message from "../ui/Message";
import Divider from "../auth/Divider";
import useMergeState from "../hooks/useMergeState";
import $data from "../../services/$data";
import { Box } from "@mui/system";
import _ from "lodash";
import ConfirmDialog from "../ui/ConfirmDialog";


function OrderPreview({parcels, open, onClose}) {
  const {lang, user} = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = useMergeState({documents: [], count: 0, rowsPerPage: 10, page: 0, deleteDocument: false, documentId: null });
  const [filter, setFilter] = useMergeState({name: '', tags: []})
  
  const calculatePrice = (area, pricePerHa) => {
    return `${(area * pricePerHa).toFixed(2)} EUR`
  }

  const totalPrice = (pricePerHa) => {
    let total = 0;

    parcels.map(obj => {
      total = total + (obj.area * pricePerHa) 
    });

    if(total === 0) return ' - ';

    return `${total.toFixed(2)} EUR`
  }

  const getPricePerHa = () => {
    if(parcels.length === 0) return 0;

    let area = _.sum(parcels.map(obj => obj.area));
    
    if(area > 0 && area <= 1000) return 5;

    if(area > 1000 && area <= 5000) return 4;

    if(area > 5000) return 3;
  }

  const renderParcels = () => {
    

    return parcels.map((obj, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{(key + 1)}</TableCell>
          <TableCell>{obj.name || ' - '}</TableCell>
          <TableCell>{obj.area.toFixed(2) + 'ha' || ' - '}</TableCell>
          <TableCell sx={{textAlign: 'right', whiteSpace: 'nowrap'}}>{calculatePrice(obj.area, getPricePerHa())}</TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} color="primary">{lang.billing_order}
        <Close onClick={onClose} sx={{'&:hover': {transform: 'scale(1.1)'}}} style={{cursor: 'pointer'}} /></DialogTitle>
      <DialogContent>
        <Container style={{marginTop: '30px'}}>
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <Divider style={{width: '100%'}} />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} elevation={2}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{lang.num}</TableCell>
                      <TableCell>{lang.parcel_name}</TableCell>
                      <TableCell>{lang.parcel_area}</TableCell>
                      <TableCell sx={{textAlign: 'right'}}>{lang.billing_price_parcel}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderParcels()}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {/* <TableCell style={{padding: '20px'}} colSpan={3}></TableCell> */}
                      <TableCell style={{padding: '20px'}} colSpan={4} sx={{textAlign: 'right', fontSize: '16px', whiteSpace: 'nowrap'}}>
                          {lang.billing_price_total}: <strong>{totalPrice(getPricePerHa())}</strong>
                       </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="error" variant="contained" size="small" onClick={onClose}>{lang.close}</LoadingButton>
      {/* <LoadingButton color="primary" variant="contained" size="small" onClick={onClose}>{lang.billing_request_submit}</LoadingButton> */}
      </DialogActions>
      {/* <ConfirmDialog question={lang.doc_delete_question} title={lang.delete_document} onClose={() => setState({deleteDocument: false, documentId: null})} onSubmit={() => {}} /> */}
      <Message type="error" open={state.error ? true : false} message={state.error} onClose={() => setState({error: null})} />
      <Message type="success" open={state.success ? true : false} message={state.success} onClose={() => setState({success: null})} />

    </Dialog>
  )
}

export default OrderPreview;