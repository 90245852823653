import { Edit } from "@mui/icons-material";
import { Button, Card, CardActionArea, CardActions, CardMedia } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import avatar from '../../images/avatar.png';

function UserPhoto(props) {
  const imgRef = useRef(null);
  const {lang, primary} = useContext(AppContext);

  const [imgSource, setImgSource] = useState(props.image);

  const onFileChange = (evt) => {
    let blob = new Blob([evt.target.files[0]]);

    let src = window.URL.createObjectURL(blob);

    setImgSource(src);
    props.onSetImage(evt.target.files[0]);
  }

  useEffect(() => {
    return () => {

    }
  }, [])

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          sx={{objectFit: 'contain', minHeight: 208, maxHeight: 295}}
          component="img"
          // height="140"
          image={imgSource || avatar}
          alt="Profile image"
        />
        <Button sx={{opacity: 0, '&:hover': {transition: '.2s opacity linear', opacity: 1} ,'&:hover::after': {
          position: 'absolute',
          content: '"EDIT PROFILE PICTURE"',
          color: "#fff",
          fontWeight: 'bold',
          background: `rgba(84, 137, 116, .7)`,
          opacity: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} component="label" size="small" color="primary">
          <input ref={imgRef} onChange={onFileChange} value={""} type="file" hidden />
        </Button>
      </CardActionArea>
    </Card>
  )
}

export default UserPhoto;