import { CircularProgress, Paper, Stack, Tooltip } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../AppContext";
import translation from "../../config/locale/translation";
import $data from "../../services/$data";
import useMergeState from "../hooks/useMergeState";

function Legend(props) {
  const initialRender = useRef(true)
  const {lang, layer} = useContext(AppContext);

  const [state, setState] = useMergeState({data: [], loading: false})

  useEffect(() => {
    getLegend(layer)
  }, []);

  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else {
      getLegend(layer)
    }

  }, [layer]);

  const getLegend = (layer) => {
    setState({loading: true});

    $data.getLegend(layer)
      .then(data => setState({loading: false, data}))
      .catch(data => setState({loading: false, data: []}))
  }

  if(state.loading) return (
    <Paper className="index-legend-row" elevation={3} style={{width: '30px', alignItems: 'stretch', height: '150px', display: 'flex', flexDirection: 'column', background: '#fff',position: 'absolute', right: 0, top: '220px', padding: '5px', borderRadius: '4px'}}>
      <CircularProgress size="small" />
    </Paper>
  );
  
  if(state.data.length === 0) return (
    <Paper className="index-legend-row" elevation={3} style={{width: '30px', alignItems: 'stretch', height: '150px', display: 'flex', flexDirection: 'column', background: '#fff',position: 'absolute', right: 0, top: '220px', padding: '5px', borderRadius: '4px'}}>
      <div className="legend-color" style={{width: '30px', height: '100%', flexGrow: 1, textAlign: 'center'}}> - </div>

    </Paper>
  );


  return (
    <Paper className="index-legend-row" elevation={3} style={{width: '30px', alignItems: 'stretch', height: '150px', display: 'flex', flexDirection: 'column', background: '#fff',position: 'absolute', right: 0, top: '220px', padding: '5px', borderRadius: '4px'}}>
      {state.data.map((obj, key) => {
        return (
          <Tooltip arrow placement="left" title={lang[obj.label] ? lang[obj.label] : obj.label} key={key}>
            <div className="legend-color" style={{background: obj.color, width: '30px', flexGrow: 1}}></div>
          </Tooltip>
        )
      })}
    </Paper>
  )

}

export default Legend;