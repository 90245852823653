import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function JobOrderPlantProtection({job}) {
  const {lang} = useContext(AppContext);
  if(!job) {
    return null
  }
  return (
    <Grid item xs={12}>
      {job.agrotechnical_operation.length > 0 && <TableContainer sx={{mb: '15px'}} component={Paper} elevation={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{lang.agrotechnical_type}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {job.agrotechnical_operation.map((obj, key) => {
              return (
                <TableRow key={key}>
                  <TableCell colSpan={obj.agrotechnical_type === 2 ? 1 : 2}>{obj.agrotechnical_type_name}</TableCell>
                  {obj.agrotechnical_type === 2 && <TableCell>{lang.irrigation_norm}: {obj.irrigation_norm}mm</TableCell>}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>}

      {job.plant_protection.length > 0 && <TableContainer component={Paper} elevation={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{lang.protection_type}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {job.plant_protection.map((obj, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{obj.protection_type_name}</TableCell>
                  <TableCell>{obj.product}</TableCell>
                  <TableCell>{obj.active_substance}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>}
    </Grid>
  )
}

export default JobOrderPlantProtection;