import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Tooltip from "@mui/material/Tooltip";
import Download from "@mui/icons-material/Download";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Add from "@mui/icons-material/Add";
import Assessment from "@mui/icons-material/Assessment";

import $data from "../../services/$data";
import colors from "../../config/colors";
import { Link, Redirect } from "react-router-dom";
import { Timeline } from "@mui/lab";
import useMergeState from "../hooks/useMergeState";
import { CircularProgress } from "@mui/material";
import ActivityItem from "./ActivityItem";
import moment from "moment";
import ActivityView from "./views/ActivityView";
import CreateActivity from "./CreateActivity";
import ActivitySummary from "./ActivitySummary";
import Message from "../ui/Message";
import JobView from "./views/JobView";
import { AddModerator, Agriculture, Delete, LocalShipping, Map as MapIcon, Search } from "@mui/icons-material";
import $seasons from "../../services/$seasons";
import FieldItem from "./FieldItem";
import ConfirmDialog from "../ui/ConfirmDialog";
import DeleteField from "./field/DeleteField";
import RegenerativePractices from "./RegenerativePractices";

function Activities(props) {
	const {lang, primary, cropTypes, activityTypes, onUpdateState, onUpdateParcels} = useContext(AppContext);

	const [redirectToFieldBook, setRedirectToFieldbook] = useState(false);

	const [data, setData] = useMergeState({jobs: [], activities: [], success: null, error: false, loading: true});

	const [summary, setSummary] = useMergeState({data: [], loading: true, error: false});

	const [modals, setModals] = useMergeState({activityView: false, activityModel: null, jobView: false, jobModel: null, createActivity: false, summary: false, deleteParcel: false, regAgri: false });

	useEffect(() => {
		onUpdateState({view: 'fieldbook_item'});
		setData({error: false});

		getData();
		
	}, []);

	const getData = (successMessage) => {
		setData({loading: true});
		setSummary({loading: true});

		$data.getActivities({parcels: [props.id].join(','), export_format: 'json'})
			.then(data => {
				let d = {...data, loading: false};	
				if(successMessage) {
					d.success = successMessage;
				}		
				setData(d)
			})
			.catch(err => { setData({error: true,  loading: false}); });

		$data.getActivitiesSummaryForParcel(props.id)
			.then(data => {
				setSummary({data, loading: false});
			})
			.catch(err => {setSummary({error: true, loading: false})})
	}


	const onRefreshActivities = (successMessage) => {
		onUpdateParcels();
		getData(successMessage);
	}

	const onDeleteParcel = () => {
    setModals({deleteParcel: false});
    onUpdateParcels();
		setRedirectToFieldbook(true);

  }

	const renderActivities = (parcel) => {
		if(data.activities.length === 0) {
			return <p style={{fontSize: '13px', textAlign: 'center', color: '#555'}}>{lang.act_no_activities}</p>
		}
		
		return (
			<Timeline>
				{data.activities.map((obj, key) => {
					return (
						<ActivityItem key={key}
							parcel={parcel}
							active={obj.activity_status ? 'success' : 'warning'}
							onClick={() => {setModals({activityView: true, activityModel: obj})}}
							date={moment(obj.date).format('DD-MMM-YYYY')}
							color={'primary'}
							activity={obj.activity_type}
							activityObj={obj}
							length={data.activities.length}
							isLast={key === data.activities.length -1}
							title={$data.getActivityNameById(activityTypes, obj.activity_type)}
							createdBy={obj.operators ? obj.operators.join(', ') : `${obj.author.first_name} ${obj.author.last_name}`} />
					)
				})}
			</Timeline>
		)
	}

	const renderJobs = () => {
		if(data.jobs.length === 0) {
			return <p style={{fontSize: '13px', textAlign: 'center', color: '#555'}}>{lang.act_no_jobs}</p>
		}

		return (
			<Timeline>
					{data.jobs.map((obj, key) => {
						return (
							<ActivityItem key={key}
								parcel={parcel}
								onClick={() => {setModals({jobView: true, jobModel: obj})}}
								date={moment(obj.date).format('DD-MMM-YYYY')}
								color={'primary'}
								activity={obj.activity_type}
								activityObj={obj}
								length={data.activities.length}
								isLast={key === data.jobs.length -1}
								title={$data.getActivityNameById(activityTypes, obj.activity_type)}
								isJob
								active={obj.activity_status ? 'success' : 'warning'}
								createdBy={obj.operators ? obj.operators.join(', ') : `${obj.author.first_name} ${obj.author.last_name}`}
							/>
						)
					})}
			</Timeline>
		)
	}
	
	const feature = $data.getParcelById(props.id);
	const parcel = feature ? feature.properties : {};

	if(redirectToFieldBook) {
		return <Redirect to="/dashboard/field-book" />
	}

	return (
		<Container className="fieldbook-container">
			{/* ACTIVITIES HEADER */}
			<Grid style={{ position: "sticky", top: 0, background: "#fff", zIndex: 1, padding: '10px 0', marginBottom: '10px' }} container spacing={2} >
				
				{/* BACK TO FIELDBOOK */}
				<Grid item xs={12}>
					<Stack justifyContent="space-between" alignItems="center" direction="row" >
          <Link style={{textDecoration: 'none'}} to="/dashboard/field-book">
						<Button size="small" variant="contained" startIcon={<ArrowBack />}> {lang.go_back} </Button>
					</Link>

					<Button onClick={() => setModals({deleteParcel: parcel})} size="small" variant="contained" color="error" startIcon={<Delete />}>{lang.delete}</Button>

					</Stack>
				</Grid>
			</Grid>

			
				{/* PARCEL CONTAINER, ADD ACTIVITY, SUMMARY, EXPORT */}
			<Grid container spacing={2} style={{marginTop: 0, marginBottom: '10px'}}>	
				<Grid item xs={12}>
					<FieldItem sx={{display: 'flex'}} mediaSx={{ maxWidth: 151, overflow: 'hidden', textOverflow: 'ellipsis' }} parcel={parcel.pk} geometry={feature} image={colors.groupImages[$data.getCropGroupIDByCrop(parcel.activeCrop.crop, cropTypes)]} groupID={$data.getCropGroupIDByCrop(parcel.activeCrop.crop, cropTypes)} name={parcel.name} crop={$data.getCropById(cropTypes, parcel.activeCrop.crop)} area={parcel.area} />
				</Grid>

				<Grid className="fieldbook-button-group" item xs={12}>
					<Button onClick={() => setModals({createActivity: true})} size="small" variant="contained" endIcon={<Add />}>
						{lang.act_add_activity}
					</Button>
					<Button onClick={() => setModals({regAgri: true})} size="small" variant="contained" endIcon={<AddModerator />}>
						{lang.act_reg_practice}
					</Button>
					{/* <Button size="small" variant="contained" endIcon={<Add />}>
						{lang.act_add_job_order}
					</Button> */}
					<Button onClick={() => setModals({summary: true})} size="small" variant="contained" endIcon={<Assessment />}>
						{lang.act_summary_report}
					</Button>
					<Link  to={feature.geometry ? '/dashboard/map' : window.location.pathname} style={{textDecoration: 'none'}}><Button disabled={!Boolean(feature.geometry)} onClick={() => onUpdateState({selectedParcel: props.id, season: $seasons.getSeasonFromDate($data.getActiveSeasonSowingDate(props.id))})} size="small" variant="contained" endIcon={<MapIcon />}>
						{lang.see_on_map}
					</Button></Link>

					<Link to={feature.geometry ? '/dashboard/vrt' : window.location.pathname} style={{textDecoration: 'none'}}><Button disabled={!Boolean(feature.geometry)} onClick={() => onUpdateState({selectedParcel: props.id, season: $seasons.getSeasonFromDate($data.getActiveSeasonSowingDate(props.id))})} size="small" variant="contained" endIcon={<Agriculture />}>
						{lang.see_on_vra}
					</Button></Link>
				</Grid>
			</Grid>

			{/* ACTIVITIES AND JOB ORDERS */}
			<Grid container spacing={2}>
				{data.loading && (
					<Grid style={{textAlign: 'center', marginTop: '30px'}} item xs={12}>
						<CircularProgress />
					</Grid>
				)}
				{!data.loading && <Grid item xs={12} sm={6}>
					<p style={{ textAlign: "center", color: primary, fontWeight: 'bold' }}>{lang.act_activities}</p>
					{renderActivities(parcel)}
				</Grid>}
				
				{!data.loading && <Grid item xs={12} sm={6}>
					<p style={{ textAlign: "center", color: primary, fontWeight: 'bold' }}>{lang.job_orders_role}</p>
					{renderJobs()}
				</Grid>}
			</Grid>
			
			<CreateActivity parcel={parcel} open={modals.createActivity} onRefresh={onRefreshActivities} onClose={() => setModals({createActivity: false})} />
			<ActivityView parcel={parcel} activity={modals.activityModel} onRefresh={onRefreshActivities} open={modals.activityView} onClose={() => setModals({activityView: false, activityModel: null})} />
			<JobView parcel={parcel} activity={modals.jobModel} onRefresh={onRefreshActivities} open={modals.jobView} onClose={() => setModals({jobView: false, jobModel: null})} />
			<ActivitySummary parcel={parcel} feature={feature} data={summary.data} loading={summary.loading} open={modals.summary} onClose={() => setModals({summary: false})} />
				
			<DeleteField open={Boolean(modals.deleteParcel)} onClose={() => setModals({deleteParcel: false})} parcel={modals.deleteParcel} onSuccess={onDeleteParcel} />

			{modals.regAgri && <RegenerativePractices parcel={props.id} open={modals.regAgri} onClose={() => setModals({regAgri: false})} />}
			<Message type="success" open={data.success ? true : false} message={data.success} onClose={() => setData({success: null})} />

		</Container>
	)
}

export default Activities;