import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function JobOrderPlanting({job}) {
  const {lang} = useContext(AppContext);
  if(!job) {
    return null
  }
  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} elevation={2}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{lang.crop_group}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{job.crop_group_name || ' - '}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{lang.crop_type}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{job.crop_type_name || ' - '}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{lang.seed_type}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{job.seed_type || ' - '}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{lang.row_spacing}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{job.row_spacing ? job.row_spacing + ' cm' : ' - '}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default JobOrderPlanting;