import Carousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useContext, useState} from "react";
import { AppContext } from "../../../AppContext";
import { Box } from "@mui/system";
import { Button, Stack } from '@mui/material';
import moment from 'moment';

import colors from '../../../config/colors';
import {max, min, sum, mean, median} from 'mathjs';
import { ArrowBack, ArrowLeft, Info, Opacity } from '@mui/icons-material';
import {Chart} from 'react-chartjs-2';
import { formatData, formatOptions } from '../../../config/charts/forecast';

function Forecast(props) {
	const {lang} = useContext(AppContext);

	const [day, setDay] = useState(null)


	const renderHours = () => {
		return (
		<Stack style={{height: '130px'}} alignItems="flex-start">
			<Stack sx={{width: '100%'}} alignItems={'center'} justifyContent={'space-between'} direction="row">
				<Button variant="contained" onClick={() => setDay(null)} size="small"><ArrowBack fontSize="small" />{lang.go_back}</Button>
				<div>{moment(day.times[0]).format('dddd, DD. MMMM')}</div>
				<div></div>
			</Stack>
			<Chart 
				type="line"
				data={formatData(day, lang)}
				options={formatOptions(lang)} />
		</Stack>
		)
	}

	const getDaySymbol = (symbols) => {
		let onlyDays = symbols.filter(val => val.indexOf('night') === -1);
		if(onlyDays.length === 0) return symbols[0];

		if(onlyDays.length === 1) return symbols[0];

		return onlyDays.length % 2 === 0 ? onlyDays[onlyDays.length / 2] : onlyDays[Math.round(onlyDays.length / 2)]

	}

	const renderDays = () => {
		const responsive = {
			0: { items: 1 },
			768: { items: 2 },
			1024: { items: 3 },
			1444: {items: 5}
		};

		let items = Object.keys(props.data).map((day, key) => {
			let data = props.data[day];

			return (
				<div onClick={() => setDay(data)} className="daily-container" key={key}>
					<div className="daily-day">
						{moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ?  lang.today : moment(day).format('dddd, DD. MMMM')}
					</div>
					<div className="daily-box">
						<div>
							<div className="daily-icon">
								<img src={colors.weather[getDaySymbol(data.symbol)]} width="50" />
								<span className="higher">{Math.round(max(data.temperature))}&deg;/</span>
								<span className="lower">{Math.round(min(data.temperature))}&deg;</span>
							</div>
							<div className="daily-precipitation">
								<Opacity fontSize="small" /> {Math.round(sum(data.precipitation))}mm
							</div>
										<div className="daily-item">
								<div className="daily-item-title">{lang.humidity}</div>
								<div className="daily-item-value">{Math.round(mean(data.humidity))}%</div>
							</div>
						</div>
						<div>
							<div className="daily-item">
								<div className="daily-item-title">{lang.wind_speed}</div>
								<div className="daily-item-value">{Math.round(mean(data.wind))}m/s</div>
							</div>
							<div className="daily-item">
								<div className="daily-item-title">{lang.pressure}</div>
								<div className="daily-item-value">{Math.round(mean(data.pressure))}mb</div>
							</div>
				
							<div className="daily-item">
								<div className="daily-item-title">{lang.cloudiness}</div>
								<div className="daily-item-value">{Math.round(mean(data.cloudiness))}%</div>
							</div>
							
						</div>
					</div>

				</div>
			)
		});
		return (
			<Carousel responsive={responsive} items={items} controlsStrategy="responsive"/>
		) 
	}
	
	return (
		<Stack className="forecast-container" justifyContent={'space-between'} sx={{width: '100%', height: '180px'}}>
			{!day && renderDays()}

			{day && renderHours()}

			<a style={{fontSize: '11px'}} href="https://www.met.no/en" target="_blank"><Info sx={{fontSize: '11px'}} /> Data from The Norwegian Meteorological Institute</a>
		</Stack>
	)
}

export default Forecast;