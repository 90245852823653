import axios from 'axios';

import $cookies from './$cookies';

import URL from '../config/urls';
import moment from 'moment';

class $auth {
	
	login(email, password) {
		return axios.post(URL.LOGIN, {email, password})
			.then(result => {
				
				let modified = Object.assign(result.data.user, result.data.user.is_add_user ? {	isManager: result.data.user.add_user_permissions.isManager,
					isOperater: result.data.user.add_user_permissions.isOperater} : {});
				moment.locale(result.data.user.language);

				if(modified.pk === 349) {
					modified.has_sensors = true;
				}
				$cookies.set('token', result.data.access_token);
				$cookies.set('refresh', result.data.refresh_token)
				$cookies.set('user', JSON.stringify(modified));
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.access_token;
				axios.defaults.headers.common['Accept-Language'] = result.data.user.language;
				return result.data
			})
			.catch(err => {
				console.log(err);
				
				throw err
			})
	}
	

	logout() {	
		return axios.post(URL.LOGOUT, {refresh: $cookies.get('refresh')})
			.then(result => this.unauthorize())
			.catch(err => {this.unauthorize(); throw err.message})
	}

	loginGoogle(model) {
		return axios.post(URL.GOOGLE, model) 
			.then(result => {
				return result.data
			})
			.catch(err => {throw err.message})
	}

	authorizeUser(model) {
		let modified = Object.assign(model.user, model.user.is_add_user ? {	isManager: model.user.add_user_permissions.isManager,
			isOperater: model.user.add_user_permissions.isOperater} : {});
			moment.locale(model.user.language);
			$cookies.set('token', model.access_token);
			$cookies.set('refresh', model.refresh_token)
			$cookies.set('user', JSON.stringify(modified));
			$cookies.set('locale', model.user.language)
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + model.access_token;
			axios.defaults.headers.common['Accept-Language'] = model.user.language;
		return model
	}

	getUser() {
		return axios.get(URL.USER)
			.then(result => {
				moment.locale(result.data.language);
				$cookies.set('locale', result.data.language);
				axios.defaults.headers.common['Accept-Language'] = result.data.language;
				let modified = Object.assign(result.data, result.data.is_add_user ? {	isManager: result.data.add_user_permissions.isManager,
					isOperater: result.data.add_user_permissions.isOperater} : {});
				if(modified.pk === 349) {
					modified.has_sensors = true;
				}
				return modified
			
			})
			.catch(err => {throw err.message})
	}

	async updateProfile(model) {
		delete model.username;
		delete model.phone;
		if(typeof model.image !== 'object' || model.image === null) {
			delete model.image;
		}

		let fd = new FormData()
		Object.keys(model).map(key => {
			fd.append(key, model[key])
		})

		return axios.patch(URL.USER, fd)
			.then(result => {
				$cookies.set('locale', model.language);
				moment.locale(model.language);
				axios.defaults.headers.common['Accept-Language'] = model.language;
				return result.data
			})
			.catch(err => {throw err.message})
	}

	patchProfileGoogle(model, token) {
		delete model.username;
		return axios.patch(URL.USER, model, {headers: {'Authorization': 'Bearer ' + token}})
			.then(result => {
				$cookies.set('locale', model.language);
				moment.locale(model.language);
				axios.defaults.headers.common['Accept-Language'] = model.language;
				return result.data
			})
			.catch(err => {throw err.message})
	}

	unauthorize() {
		$cookies.remove('user');
		$cookies.remove('token');
		$cookies.remove('refresh');
		delete axios.defaults.headers.common['Authorization'];
	}
	
	register(model) {
		return axios.post(URL.REGISTER, model)
			.then(result => result.data)
			.catch(err => { throw err.message })
	}

	resendEmail(model) {
		return axios.post(URL.RESEND, model)
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	resetEmail(email) {
		return axios.post(URL.RESET_PASSWORD_SEND, {email})
			.then(result => {

			})
			.catch(err => {
				throw err.message
			})
	}

	resetConfirm(model) {
		return axios.post(URL.RESET_PASSWORD_CONFIRM, model)
			.then(result => {

			})
			.catch(err => {	
				throw err.message
			})
	}

	verification(id) {
		return axios.post(URL.VERIFICATION, {key: id})
			.then(result => result.data)
			.catch(err => {
				throw err.message
			})
	}

	refreshToken(token) {
		return axios.post(URL.TOKEN_REFRESH, {refresh: token})
			.then(result => {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.access;
				$cookies.set('token', result.data.access);
				$cookies.set('refresh', result.data.refresh);
				return result.data.access
			})
			.catch(err => {
				this.unauthorize();
				throw err.message
			})
	}

	isLoggedIn(token) {
		return axios.post(URL.TOKEN_VERIFY, {token: token})		
			.then(result => result.data.access_token)
			.catch(err => {
				this.unauthorize();
				throw err.message
			})	
	} 

	getLoggedOutState() {
		return {
			loggedIn: false, 
			user: null,
			parcels: null,
			cropTypes: null,
			dates: null,
			mapInit: true,
			loading: true,
			point: {
				coordinates: null,
				parcel: null
			},
			forecast: {
				toUpdate: false,
				data: null
			},
			meteo: {
				toUpdate: false,
				data: null
			},
			index: {
				toUpdate: false,
				data: null
			}
		
		}
	}

	changePassword(model) {
		return axios.post(URL.CHANGE_PASSWORD, model)
			.then(result => {
				return result.data
			})
			.catch(err => {
				throw err.message;
			})
	}

	registerPhone(phone_number) {
		return axios.post(URL.PHONE_REGISTER, {phone_number})
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	verifySMSCode(phone_number, session_token, security_code) {
		return axios.post(URL.PHONE_VERIFY, {phone_number, session_token, security_code})
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	deactivateAccount(model) {
		return axios.post(URL.ACCOUNT + 'deactivate/', model)
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	activateAccount(model) {
		return axios.post(URL.ACCOUNT + 'activate/', model)
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	deleteAccount(model) {
		return axios.post(URL.ACCOUNT + 'delete/', model)
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	deleteAccountV2(email) {
		return axios.post(URL.DELETE_ACCOUNT, {email})
			.then(result => result.data)
			.catch(err => {throw err.message})
	}

	restoreAccount(model) {
		return axios.post(URL.ACCOUNT + 'restore/', model)
			.then(result => result.data)
			.catch(err => {throw err.message})
	}


}

export default new $auth();