import { Button, Stack } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import Logo from "../../../Logo";

function MainError(props) {

  return (
    <Stack style={{height: '80%'}} direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{'& .loader-item': {mb: '10px'}}}>
      <Logo className="loader-item" style={{maxWidth: 300, maxHeight: 150}} />
      {/* <CircularProgress className="loader-item" size={'60px'} /> */}
      <h3 className="loader-item" style={{padding: '10px',color: '#548974', textTransform: 'uppercase'}}>{props.lang.main_error}</h3>

    </Stack>
  )
}

export default MainError;