import _ from "lodash";
import View from 'ol/View';
import olMap from 'ol/Map';
import { useContext, useEffect, useState } from "react";
import { basemaps, indices } from "../../config/map";
import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";
import GeoJSON from 'ol/format/GeoJSON';
import { Paper } from "@mui/material";
import VectorImageLayer from "ol/layer/VectorImage";
import { AppContext } from "../../AppContext";
import $map from "../../services/$map";

function VrtMap(props) {
	const {user, source} = useContext(AppContext)
	const [map, setMap] = useState(null);
	const [parcels, setParcels] = useState(null);
	const [layers, setLayers] = useState(null);
	const [keys, setKeys] = useState([]);

	const parcelsToFeatures = (selected) => {
		let formated = {type: 'FeatureCollection', features: props.parcels.features.filter(f => selected.indexOf(f.properties.pk) > -1)};

		let features = new GeoJSON().readFeatures(formated);
		// features.map(f => f.getGeometry().transform('EPSG:3857', 'EPSG:4326'));

		return features;

	}



	useEffect(() => {
		let extent = new VectorSource({features: new GeoJSON().readFeatures(props.parcels)}).getExtent();

		let emptyLayer = new VectorImageLayer({source: new VectorSource()});

		let layers = indices(user.pk, null, props.date).filter(l => l.get('name') === 'ndvi' || l.get('name') === 'productivity');

		let map = new olMap({
			target: 'map',
			pixelRatio: 1,
			layers: _.concat(basemaps('sat'), layers, emptyLayer),
			view: new View({
				projection: 'EPSG:3857',
				zoom: 5,
				center: [20.5, 45]
			})
		});

		if(extent[0] !== Infinity) {
			map.getView().fit(extent);
		}

		setMap(map);
		setLayers(layers);
		setParcels(emptyLayer);
	}, []);

	useEffect(() => {
		if(parcels) {
			parcels.getSource().clear();
			setKeys($map.unclipLayers(keys));

			if(props.selected.length > 0) {
				parcels.getSource().addFeatures(parcelsToFeatures(props.selected));
				map.getView().fit(parcels.getSource().getExtent(), {duration: 500, padding: [50, 50, 50, 50]});

				setKeys($map.clipLayers(layers, parcels));
			}
		}

	}, [props.selected.toString()]);

	useEffect(() => {
		if(parcels) {
			layers.map(l => l.setVisible(false));
			if(props.data) {
				let layerName = layers[props.layer].get('name') === 'ndvi' ? (source === 'planet' ? 'ndvi_planet' : 'ndvi') : (source ==='planet' ? 'mz_planet' : 'mz')

				layers[props.layer].getSource().updateParams({
					'LAYERS': `${user.pk}:${user.pk}_${layerName}`,
					'TIME': props.date,
					'SLD_BODY': $map.getVRTSLD(user.pk, layers[props.layer].get('name'), props.data, source === 'planet')
				});
				layers[props.layer].setVisible(true)
			}
		}

	}, [JSON.stringify(props.data)])

	useEffect(() => {
		if(parcels) {
			layers.map(l => l.setVisible(false));
			if(props.data) {
				
				let layerName = layers[props.layer].get('name') === 'ndvi' ? (source === 'planet' ? 'ndvi_planet' : 'ndvi') : (source ==='planet' ? 'mz_planet' : 'mz')

				layers[props.layer].getSource().updateParams({
					'LAYERS': `${user.pk}:${user.pk}_${layerName}`,
					'TIME': props.date,
					'SLD_BODY': $map.getVRTSLD(user.pk, layers[props.layer].get('name'), props.data, source === 'planet')
				});
				layers[props.layer].setVisible(true)
			}
		}
	}, [source])



	return (
		<Paper elevation={2} style={{width: '100%', height: '400px'}} id="map"></Paper>
	)
}

export default VrtMap;